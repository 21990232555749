import fill from 'lodash/fill';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';

import {useSafeContext} from 'utils';
import {GroupQuestStatsItem, GroupQuestStatsItemSkeleton} from 'modules/groups/components/GroupStatsListItem';
import {useQuestPointStats} from 'modules/groups/components/GroupStatsListItem/hooks';
import {GroupContext} from 'modules/groups/context';

const GroupQuestStatsSkeleton = () => (
  <Box>
    <Stack p={2} spacing={3}>
      <Skeleton variant="text" height={22} width="40%" />
      <Skeleton variant="text" height={12} width="90%" />
    </Stack>
    <Stack>
      {fill(Array(12), '').map((_, i) => (
        <GroupQuestStatsItemSkeleton key={i} />
      ))}
    </Stack>
  </Box>
);

export const GroupStats = ({defaultCollapse}: {defaultCollapse?: boolean}) => {
  const {info} = useSafeContext(GroupContext);
  if (!info.stream?.defaultAdventureId) return null;
  return <GroupQuestStats questId={info.stream.defaultAdventureId} defaultCollapse={defaultCollapse} />;
};

const GroupQuestStats = ({questId, defaultCollapse}: {questId: string; defaultCollapse?: boolean}) => {
  const {quest, questPointsStats, isLoading} = useQuestPointStats({questId, isMainQuest: true});

  if (isLoading) return <GroupQuestStatsSkeleton />;
  if (!quest || !questPointsStats) return null;
  return (
    <Box borderRadius={2} sx={{boxShadow: theme => theme.customShadows.default}}>
      <Stack p={2} spacing={3}>
        <Typography variant="h4" color="grey.300">
          {quest.name}
        </Typography>
        <Typography variant="subtitle1" color="grey.200">
          Cредний прогресс квестов среди всех участников десятки
        </Typography>
      </Stack>
      <Stack>
        {questPointsStats.map(item => (
          <GroupQuestStatsItem key={item.questPoint._id} questPointData={item} defaultCollapse={defaultCollapse} />
        ))}
      </Stack>
    </Box>
  );
};
