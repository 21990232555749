import _ from 'lodash/fp';
import {memo} from 'react';
import {Button, Typography, Box, Skeleton, Stack} from '@mui/material';

import {filesApi} from 'api';
import {ReactComponent as AttachIcon} from 'svg/icons/fileAttachIcon.svg';
// import {ReactComponent as AttachFileIcon} from 'svg/icons/attachFileIcon.svg';

// import {ReactComponent as FileUploadIcon} from 'svg/icons/fileUploadIcon.svg';
// import {ReactComponent as AddPhotoIcon} from 'svg/icons/addPhoto.svg';

import {FileFieldProps} from '../types';
import {useField} from 'formik';
import {isArray, last} from 'lodash';
import {useDropzone} from 'react-dropzone';

type FieldProps = {
  name: string;
  type: string;
} & FileFieldProps['inputProps'];

const FilePreview = ({fileId}: {fileId: string}) => {
  const {data, isLoading} = filesApi.endpoints.fileInfo.useQuery({fileId});
  if (isLoading) return <Skeleton variant="rectangular" width={44} height={44} />;
  if (!data) return null;
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      width={48}
      height={44}
      sx={{backgroundColor: 'secondary.main', borderRadius: 4}}
    >
      <Typography variant="subtitle2">{`.${last(data.name.split('.'))}`}</Typography>
    </Box>
  );
};

export const FileField = ({multiple, accept, name}: FieldProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [{value}, meta, {setValue}] = useField(name);
  const arrValue = isArray(value) ? value : [value];

  const [uploadFile] = filesApi.endpoints.rootUpload.useMutation();
  const {getRootProps, getInputProps} = useDropzone({
    accept: accept || ['.pdf', 'image/*', '.doc', '.docx', '.xls', '.xlsx'],
    onDrop: async files => {
      const body = new FormData();
      files.forEach(file => body.append('file', file));
      const result = await uploadFile({body: body as any});
      if ('data' in result) {
        const newIds = result.data.map(_.get('_id'));
        if (multiple) setValue(_.compose(_.uniq, _.concat(value))(newIds));
        else setValue(_.head(newIds));
      }
    },
  });

  return (
    <Stack spacing={1} direction="row">
      {arrValue.map(item => (
        <FilePreview fileId={item} key={item} />
      ))}
      <div {...getRootProps()}>
        <Button variant="contained" color="secondary">
          <AttachIcon />
        </Button>
        <input {...getInputProps()} />
      </div>
    </Stack>
  );
};

export default memo(FileField);
