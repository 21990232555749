import {Skeleton, Stack} from '@mui/material';

export const CommentSkeleton = () => (
  <Stack direction="row" spacing={1}>
    <Skeleton variant="circular" animation="wave" width={32} height={32} />
    <Stack spacing={1} flex={1}>
      <Skeleton variant="text" animation="wave" width={150} height={16} sx={{transform: 'none'}} />
      <Skeleton variant="text" animation="wave" width="75%" height={16} sx={{transform: 'none'}} />
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Stack direction="row" alignItems="center" spacing={1}>
          <Skeleton variant="text" animation="wave" width={100} height={12} sx={{transform: 'none'}} />
          <Skeleton variant="text" animation="wave" width={66} height={16} sx={{transform: 'none'}} />
        </Stack>
        <Skeleton variant="rectangular" animation="wave" width={42} height={16} sx={{borderRadius: 12}} />
      </Stack>
    </Stack>
  </Stack>
);
