import {ReactNode} from 'react';
import {isUndefined} from 'lodash';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';

import {api} from 'api';
import {CardLinkHeader} from 'components/CardLinkHeader';
import {GoalSliderContentSkeleton} from 'components/Skeleton/Goal';
import {GoalGridItem, GoalCompleteCounter} from 'modules/goals/components';
import {HideScrollbar} from 'components/HideScrollbar';

import {GoalListProps} from './types';

type GoalSliderProps = Omit<GoalListProps, 'display' | 'action'> & {
  title: string;
  link: string;
  EmptyList?: ReactNode;
};

export const GoalSliderList = ({params, title, link, EmptyList}: GoalSliderProps) => {
  const {data, isLoading} = api.endpoints.goalsIndex.useQuery({...params, limit: 10, sort: 'created_date'});
  const noGoals = !isUndefined(data?.total) && data?.total === 0;
  const total = data?.total || 0;

  return (
    <Card>
      <CardLinkHeader
        title={title}
        to={link}
        subTitle={!noGoals && <GoalCompleteCounter total={total} completed={data?.completed} />}
      />
      {noGoals && EmptyList}
      {!noGoals && (
        <HideScrollbar>
          <Stack spacing="2px" flexWrap="nowrap" direction="row" width="fit-content">
            {isLoading && <GoalSliderContentSkeleton />}
            {(data?.data || []).map(item => (
              <Box key={item._id} width={80}>
                <GoalGridItem goal={item} noTimeBadge noMenu noPrivacyBadge />
              </Box>
            ))}
          </Stack>
        </HideScrollbar>
      )}
    </Card>
  );
};
