import pick from 'lodash/pick';
import {useCallback} from 'react';
import {useAtomCallback} from 'jotai/utils';
import {skipToken} from '@reduxjs/toolkit/dist/query';

import {api} from 'api';
import {useErrorShackbar} from 'utils';
import {StagePointData} from 'api/generated/users-api';

import {QuestPointProps} from '../types';
import {selectStagePointCustomOptions} from '../utils';
import {useGetStagePointByIdWithSelector} from './getStagePointsByContainerName.hook';
import {refreshStages, STAGE_REFRESH_SCOPE} from '../utils/refreshStages';
import {useRefreshStage} from './refreshStage.hook';
import {useIsMe} from 'modules/auth';

const selectSetReferenceData = (point: StagePointData) => {
  const questPoint = point.questPointData;
  return {
    stagePoint: pick(point.stagePoint, ['_id', 'status', 'referenceId', 'stageId', 'referenceName', 'userId']),
    questPoint: {
      options: selectStagePointCustomOptions(point),
      name: questPoint.name,
      originId: questPoint.originId,
      type: questPoint.questPoint.type,
      timelineId: questPoint.timelineId,
      isAvailable: Boolean(questPoint.timelineCheck.available),
      triggerExists: Boolean(questPoint.questPoint.options.triggers),
      triggerRequiredStagePointStatus: questPoint.questPoint.options.triggers?.requiredStagePointStatus,
    },
  };
};

export const useSetPointReference = ({stagePointId, stageId}: QuestPointProps) => {
  const openErrorShackbar = useErrorShackbar();
  const data = useGetStagePointByIdWithSelector({stagePointId, stageId}, selectSetReferenceData);
  const {questPoint, stagePoint} = data;

  const {triggerExists, triggerRequiredStagePointStatus} = questPoint;

  const isMe = useIsMe(stagePoint.userId);
  const [setRef, {isLoading: referenceProccessing}] = api.endpoints.stagePointSetReference.useMutation();
  const cannotSetReference = !stagePoint || stagePoint.status !== 'new' || !questPoint.isAvailable;

  const setReference = useAtomCallback(
    useCallback(
      async (get, set, referenceId?: string) => {
        if (cannotSetReference || !stagePoint._id) return;
        const result = await setRef({pointId: stagePoint._id, setReferenceDto: {referenceId}});
        if ('error' in result) openErrorShackbar(result.error);
        else if ('data' in result) {
          if (stagePoint.stageId) Promise.all(get(refreshStages).map(item => item()));
          // || (triggerExists && stagePoint.status === triggerRequiredStagePointStatus)
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [setRef, stagePoint, cannotSetReference, triggerExists, triggerRequiredStagePointStatus]
    ),
    STAGE_REFRESH_SCOPE
  );

  return {
    questPoint,
    stagePoint,
    setReference: cannotSetReference ? undefined : setReference,
    referenceProccessing: referenceProccessing,
    canPress: isMe && (Boolean(stagePoint?.referenceId) || !cannotSetReference),
  };
};

export const useExternalSetPointReference = (props: QuestPointProps | undefined) => {
  const {onRefresh} = useRefreshStage({stageId: props?.stageId});
  const openErrorShackbar = useErrorShackbar();
  const {data} = api.endpoints.stagePointIndex.useQuery(
    props?.stagePointId ? {pointId: props.stagePointId, partial: true} : skipToken,
    {
      selectFromResult: ({data: point}) => ({
        data: point ? selectSetReferenceData(point) : undefined,
      }),
    }
  );

  const triggerExists = data?.questPoint.triggerExists || false;
  const triggerRequiredStagePointStatus = data?.questPoint.triggerRequiredStagePointStatus || undefined;

  const [setRef, {isLoading: referenceProccessing}] = api.endpoints.stagePointSetReference.useMutation();
  const cannotSetReference = !data?.stagePoint || data?.stagePoint.status !== 'new' || !data?.questPoint.isAvailable;

  const setReference = useAtomCallback(
    useCallback(
      async (get, set, referenceId?: string) => {
        if (cannotSetReference || !data?.stagePoint || !data?.stagePoint._id) return;
        const result = await setRef({pointId: data.stagePoint._id, setReferenceDto: {referenceId}});
        if ('error' in result) openErrorShackbar(result.error);
        else if ('data' in result) {
          if (data.stagePoint.stageId || (triggerExists && data.stagePoint.status === triggerRequiredStagePointStatus))
            Promise.all(get(refreshStages).map(item => item()));
          else if (onRefresh) onRefresh();
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [setRef, data?.stagePoint, cannotSetReference, triggerExists, triggerRequiredStagePointStatus]
    ),
    STAGE_REFRESH_SCOPE
  );

  return {
    questPoint: data?.questPoint,
    stagePoint: data?.stagePoint,
    setReference: cannotSetReference ? undefined : setReference,
    referenceProccessing: referenceProccessing,
    canPress: Boolean(data?.stagePoint?.referenceId) || !cannotSetReference,
  };
};
