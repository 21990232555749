import {configureStore} from '@reduxjs/toolkit';
import {setupListeners} from '@reduxjs/toolkit/dist/query';

import {api, authApi, filesApi} from '../api';
import {catchUnauthorizedResponseMiddleware} from './middlewares';

import {authReducer} from 'modules/auth';
import {uiReducer} from './uiModule';
import {blockerReducer} from './blockerState';

export const store = configureStore({
  reducer: {
    [api.reducerPath]: api.reducer,
    [authApi.reducerPath]: authApi.reducer,
    [filesApi.reducerPath]: filesApi.reducer,
    ...authReducer,
    ...uiReducer,
    ...blockerReducer,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware()
      .concat(api.middleware)
      .concat(authApi.middleware)
      .concat(filesApi.middleware)
      .concat(catchUnauthorizedResponseMiddleware),
});

setupListeners(store.dispatch);
// export const initStore = (initialState?: any) => {
//   const store = createStore(
//     {
//       initialState,
//       extensions: [getDefaultModule()],
//       advancedCombineReducers: reducers =>
//         combineReducers({
//           [api.reducerPath]: api.reducer,
//           [authApi.reducerPath]: authApi.reducer,
//           [filesApi.reducerPath]: filesApi.reducer,
//           ...reducers,
//         }) as any,
//     },
//     getUiModule(),
//     getBlockerModule()
//   );

//   return store;
// };
