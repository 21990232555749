import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';

import {filesApi} from 'api';
import {filePath} from 'utils';
import {ReactComponent as CloseIcon} from 'svg/icons/closeIcon.svg';

export const FileAnswer = ({fileId, onRemove}: {fileId: string; onRemove?: () => void}) => {
  const {data: file} = filesApi.endpoints.fileInfo.useQuery({fileId});
  const fileName = file?.name.substring(0, file?.name.lastIndexOf('.'));
  const fileType = file?.name.substring(file?.name.lastIndexOf('.'));

  if (!file) return null;
  return (
    <Box
      width={64}
      height={64}
      borderRadius={2}
      alignItems="center"
      position="relative"
      justifyContent="center"
      sx={{
        border: '1px solid',
        borderColor: 'grey.25',
        bgcolor: 'secondary.main',
      }}
    >
      <Stack
        p={0.75}
        width="100%"
        height="100%"
        component="a"
        target="_blank"
        justifyContent="space-between"
        href={filePath(fileId, 'download')}
      >
        <Typography component="div" variant="subtitle1" color="grey.200">
          {fileType}
        </Typography>
        <Box>
          <Typography
            mb={0.25}
            height={10}
            fontWeight={500}
            color="grey.700"
            overflow="hidden"
            whiteSpace="nowrap"
            variant="subtitle2"
            textOverflow="ellipsis"
          >
            {fileName}
          </Typography>

          <Typography
            height={10}
            color="grey.200"
            overflow="hidden"
            whiteSpace="nowrap"
            variant="subtitle2"
            textOverflow="ellipsis"
          >
            {Math.round(file.size / 1000)} KB
          </Typography>
        </Box>
      </Stack>
      {onRemove && (
        <Box position="absolute" top={-10} right={-6}>
          <IconButton
            onClick={() => onRemove()}
            sx={{
              p: 0,
              width: 16,
              height: 16,
              bgcolor: 'primary.500',
              '&:hover': {bgcolor: 'primary.400'},
            }}
          >
            <Box component={CloseIcon} color="white" width={16} height={16} />
          </IconButton>
        </Box>
      )}
    </Box>
  );
};
