import * as yup from 'yup';
import hash from 'object-hash';
import {isUndefined, merge} from 'lodash';

import {FormArrayItem, FormItem, FormItemWithChilds, FormObject} from '../types';

export const getHash = (field: FormItem<any>) => hash({name: field.name, ...(field.inputProps || {})});

/*

На вход дается структура полей форма – массив. Типы элементов массива:
1. Поле формы – обычное поле
2. Блок полей формы – объект с childs, где childs – поля формы
3. Массив полей – объект с childs и name, где childs – либо поля формы, либо блок полей формы

Нв выходе нужно получить структура вида
{
  name: value,
  block: {
    name2: value,
    name3: value
  },
  array: [
    {
      name4: value,
      block2: {
        name5: value,
        name6: value,
      },
    },
  ],
}

Алгоритм действий:
1. Проитерировать входной массив
2. Каждый элемент массива передать в функцию-экстрактор дефолтных значений
  1. Элемент типа "поле" – getFieldValue – принимает на вход поле, 
      возаращает объект {[field.name]: field.initialValue}
  2. Элемент типа блок полей – getBlockValues - принимает на вход блок, 
      возвращает результат работы getFieldValue относительно списка полей childs
  3. Элемент типа массив полей – getArrayValues – принимает на вход обхект массива полей,
      внутри вызывает основную функцию-экстрактор
*/
const getFieldValue = ({name, initialValue}: FormItem<any>) => ({
  [name]: isUndefined(initialValue) ? '' : initialValue,
});
const getBlockValue = ({childs, name}: FormItemWithChilds<any>) => {
  if (name) return {[name]: merge({}, ...childs.map(getFieldValue))};
  return merge({}, ...childs.map(getFieldValue));
};
const getArrayValues = ({childs, name, min}: FormArrayItem<any>) => ({
  [name]: new Array(min).fill(getInitialValues(childs)),
});

export const getInitialValues = <Values extends object>(fields: FormObject<Values>[]): Values =>
  merge(
    {},
    ...fields.map(field => {
      if ('childs' in field) {
        if ('min' in field) return getArrayValues(field);
        else return getBlockValue(field);
      } else return getFieldValue(field);
    })
  );

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const getValidation = <Values extends object>(fields: FormObject<Values>[]) => {
  return yup.object().shape({});
};
