import {useMount} from 'react-use';
import {atom} from 'jotai';
import {useAtomCallback} from 'jotai/utils';
import {ReactNode, useMemo, useCallback} from 'react';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

import {api} from 'api';
import {Contents} from 'api/generated/users-api';
import IsExistsContainer from 'components/IsExistsContainer';
import {ContentContentSkeleton} from 'components/Skeleton';
import {ContentsStructure} from 'modules/contents/types';

import {NotionContent} from '..';

export const ContentsContent = ({content = [], onLoaded}: {content?: ContentsStructure; onLoaded?: () => void}) => {
  const contentStateAtom = useMemo(
    () => atom(content.reduce((acc, {type, value}) => ({...acc, [`${type}.${value}`]: false}), {})),
    [content]
  );

  useMount(() => {
    if (!content?.filter(i => i.type)?.length) onLoaded?.();
  });
  const setContentState = useAtomCallback(
    useCallback(
      (get, set, key: string) => {
        const newState = {...get(contentStateAtom), [key]: true};
        const isAllTrue = Object.values(newState).reduce((acc, item) => (!item ? false : acc), true);
        if (isAllTrue && onLoaded) onLoaded();
        set(contentStateAtom, newState);
      },
      [contentStateAtom, onLoaded]
    )
  );

  const result = content.map(item => {
    let part: ReactNode = null;
    if (item.type === 'notionPage') part = <NotionContent notionPageId={item.value} onLoaded={setContentState} />;
    return <CardContent key={item.value}>{part}</CardContent>;
  });
  return <Card>{result}</Card>;
};

export const RenderContent = ({contentId, onLoaded}: {contentId: string; onLoaded?: () => void}) => {
  return (
    <IsExistsContainer<Contents>
      hideError
      useFetch={() => api.endpoints.contentIndex.useQuery({contentId})}
      LoadingComponent={<ContentContentSkeleton />}
    >
      {data => <ContentsContent content={data.structure as ContentsStructure | undefined} onLoaded={onLoaded} />}
    </IsExistsContainer>
  );
};
