import {Box} from '@mui/material';

import {NotificationIndication} from 'components/Layout/NotificationIndication';

import {ReactComponent as CheckIcon} from 'svg/quest/questCheck.svg';
import {ReactComponent as CrossIcon} from 'svg/quest/crossIcon.svg';
import {ReactComponent as TaskStatusIconThird} from 'svg/icons/taskStatusIconThird.svg';

import {StatusProps} from './types';

export const Status = ({status, top = 0, right = 0}: StatusProps) =>
  !status ? null : (
    <Box
      sx={{
        width: 20,
        height: 20,
        top,
        right,
        position: 'absolute',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'white',
        p: status === 'in_progress' ? 0 : 0.25,
        borderRadius: '50%',
      }}
    >
      {status === 'new' && <NotificationIndication isSeen={false} />}
      {status === 'completed' && (
        <Box
          sx={{
            height: 16,
            width: 16,
            borderRadius: 2,
            backgroundColor: 'success.main',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Box component={CheckIcon} sx={{width: 10, height: 10, color: 'white'}} />
        </Box>
      )}
      {status === 'failed' && (
        <Box
          sx={{
            height: 16,
            width: 16,
            borderRadius: 2,
            backgroundColor: 'error.main',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Box component={CrossIcon} sx={{width: 16, height: 16, color: 'white'}} />
        </Box>
      )}
      {status === 'in_progress' && <Box component={TaskStatusIconThird} sx={{width: 20, height: 20}} />}
    </Box>
  );
