import {GlobalStyles as MUIGlobalStyles} from '@mui/material';
import {GREY} from './palette';

export const GlobalStyles = () => (
  <MUIGlobalStyles
    styles={{
      '*': {
        margin: 0,
        padding: 0,
        boxSizing: 'border-box',
      },
      html: {
        width: '100%',
        height: '100%',
        WebkitOverflowScrolling: 'touch',
      },
      body: {
        width: '100%',
        height: '100%',
        fontFamily: 'EuclidCircularA',
        backgroundColor: GREY[10],
      },
      '#root': {
        width: '100%',
        height: '100%',
      },
      img: {
        display: 'block',
        maxWidth: '100%',
      },
      '.blur': {
        WebkitFilter: 'blur(5px)',
        filter: 'blur(5px)',
        transition: 'filter 400ms, -webkit-filter 400ms',
      },
      '.blur.lazyloaded ': {
        WebkitFilter: 'blur(0)',
        filter: 'blur(0)',
      },
      a: {
        textDecoration: 'none',
      },
      button: {
        font: 'inherit',
      },
    }}
  />
);

export default GlobalStyles;
