import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Skeleton from '@mui/material/Skeleton';
import Collapse from '@mui/material/Collapse';

import {api} from 'api';
import {Jobs} from 'api/generated/users-api';

import {JobAnswers} from 'modules/tasks/containers';
import {EntityPreview} from 'components/EntityWidgets';
import {IsExistsContainer} from 'components/IsExistsContainer';
import {useCollapseScrollBack} from 'modules/feed/containers/PublicationAttachments/hook';

const JobsSkeleton = () => (
  <Stack direction="row" spacing={1.5} p={2} borderRadius={2} boxShadow={theme => theme.customShadows.default}>
    <Skeleton variant="rectangular" width={48} height={48} sx={{borderRadius: 2}} animation="wave" />
    <Stack spacing={0.5} flexGrow={1}>
      <Box>
        <Skeleton variant="text" width="30%" animation="wave" />
        <Skeleton variant="text" width="100%" animation="wave" />
      </Box>
    </Stack>
  </Stack>
);

export const JobsAttachment = ({entryId: jobId, expanded}: {entryId: string; expanded?: boolean}) => {
  const [ref, callback] = useCollapseScrollBack(expanded);

  return (
    <IsExistsContainer<Jobs>
      hideError
      useFetch={() => {
        const result = api.endpoints.jobIndex.useQuery({jobId});
        api.endpoints.jobAnswersIndex.useQuerySubscription({jobId}, {skip: !result.isSuccess});
        return result;
      }}
      LoadingComponent={<JobsSkeleton />}
    >
      {(data: Jobs) => (
        <>
          <Box p={1.5} ref={ref}>
            <EntityPreview
              caption="Задание завершено"
              title={data.task?.name || 'Задание'}
              statusProps={{status: data.status}}
              imageProps={{fileId: data.task?.filesId[0], alt: data.task?.name || 'Задание', size: 'small'}}
            />
          </Box>
          <Collapse in={expanded} mountOnEnter unmountOnExit addEndListener={callback}>
            <Box px={2} py={1.5} borderTop="1px solid" borderColor="grey.10">
              <JobAnswers jobId={jobId} mode="post" />
            </Box>
          </Collapse>
        </>
      )}
    </IsExistsContainer>
  );
};
