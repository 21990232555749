import {omit} from 'lodash';
import {Box} from '@mui/system';
import {slugify} from 'transliteration';
import {useState, useCallback, ReactNode} from 'react';
import {useQueryParam, ArrayParam} from 'use-query-params';
import {useDebounce, useDeepCompareEffect} from 'react-use';
import {IconButton, CardContent, Card, Stack, Autocomplete} from '@mui/material';

import {ReactComponent as ClearIcon} from 'svg/icons/clearIcon.svg';
import {ReactComponent as SearchIcon} from 'svg/icons/searchIcon.svg';
import * as Styled from './styles';

export type SearchFieldProps = {
  placeholder?: string;
  action?: ReactNode;
};

export type AutocompleteFieldProps = {
  placeholder?: string;
  action?: ReactNode;
  options: {label: string; value: string}[];
  paramName: string;
  openText?: string;
  clearText?: string;
  closeText?: string;
  noOptionsText?: string;
  getLimitTagsText?: (more: number) => string;
};

export const SearchField = ({
  action,
  setValue,
  placeholder = 'Поиск',
}: {setValue: (value: string) => void} & SearchFieldProps) => {
  const [search, setSearch] = useState('');
  useDebounce(() => setValue(search), 200, [search]);
  return (
    <Card>
      <CardContent>
        <Stack spacing={1} direction="row" justifyContent="space-between">
          <Styled.SearchBox flexGrow={1} sx={{pr: 1}}>
            <IconButton>
              <SearchIcon />
            </IconButton>
            <Styled.SearchField
              fullWidth
              size="medium"
              variant="standard"
              value={search}
              placeholder={placeholder}
              onChange={e => setSearch(e.currentTarget.value)}
            />
            {search && (
              <Styled.ClearButton onClick={() => setSearch('')}>
                <ClearIcon />
              </Styled.ClearButton>
            )}
          </Styled.SearchBox>
          {action}
        </Stack>
      </CardContent>
    </Card>
  );
};

const AutocompleteField = ({
  action,
  options,
  placeholder,
  paramName,
  setValue,
  ...params
}: AutocompleteFieldProps & {setValue: (arr: string[]) => void}) => {
  const [tags, setTags] = useQueryParam(paramName, ArrayParam);
  const defaultValues = options.filter(item => (tags || []).includes(slugify(item.label)));
  useDeepCompareEffect(() => {
    setValue(defaultValues.map(item => item.value));
  }, [defaultValues]);

  return (
    <Card>
      <CardContent>
        <Autocomplete
          value={defaultValues}
          options={options}
          ChipProps={{size: 'small'}}
          onChange={(e, values) => {
            setTags(values.map(item => slugify(item.label)));
          }}
          multiple
          fullWidth
          {...params}
          renderInput={params => (
            <Stack spacing={1} direction="row" justifyContent="space-between">
              <Styled.SearchBox flexGrow={1} sx={{py: 0.25}}>
                <IconButton>
                  <SearchIcon />
                </IconButton>
                <Styled.SearchField
                  variant="standard"
                  {...params}
                  InputProps={{...omit(params.InputProps, ['endAdornment'])}}
                  placeholder={placeholder}
                />
                {defaultValues && defaultValues.length > 0 && (
                  <Styled.ClearButton
                    onClick={() => {
                      setValue([]);
                      setTags(undefined);
                    }}
                  >
                    <ClearIcon />
                  </Styled.ClearButton>
                )}
              </Styled.SearchBox>
              {action && <Box flexShrink={0}>{action}</Box>}
            </Stack>
          )}
        />
      </CardContent>
    </Card>
  );
};

export const useAutocompleteField = (): [(props: AutocompleteFieldProps) => JSX.Element, string[]] => {
  const [displayValue, setDisplayValue] = useState<string[]>([]);

  const Search = useCallback(
    (props: AutocompleteFieldProps) => <AutocompleteField {...props} setValue={setDisplayValue} />,
    [setDisplayValue]
  );
  return [Search, displayValue];
};

export const useSearchField = (): [(props: SearchFieldProps) => JSX.Element, string] => {
  const [debouncedValue, setDebouncedValue] = useState('');
  const Search = useCallback(
    (props: SearchFieldProps) => <SearchField {...props} setValue={setDebouncedValue} />,
    [setDebouncedValue]
  );
  return [Search, debouncedValue];
};
