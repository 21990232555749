import {Goals} from 'api/generated/users-api';
import {useIsMe, useMyId} from 'modules/auth';

import {createContext, useContext} from 'react';
import {ReactComponent as GoalIcon} from 'svg/icons/goalIcon.svg';
import {ReactComponent as EditIcon} from 'svg/icons/editIcon.svg';
import {ReactComponent as CopyIcon} from 'svg/icons/copyIcon.svg';
import {Button} from '@mui/material';

export const GoalFormContext = createContext<((goalId?: string) => void) | undefined>(undefined);
export const useGoalForm = () => useContext(GoalFormContext);
export const useGoalFormButton = () => {
  const openFn = useContext(GoalFormContext);
  const myId = useMyId();
  if (!myId || !openFn) return null;
  return (
    <Button onClick={() => openFn()} variant="contained" size="small" sx={{px: 3}}>
      Поставить цель
    </Button>
  );
};
export const useGoalCreateMenuItem = (goal?: Goals) => {
  const myId = useMyId();
  const fn = useContext(GoalFormContext);
  if (!myId || !fn || (goal && goal.user?._id !== myId)) return null;
  if (goal) return {label: 'Редактировать', icon: <EditIcon />, onClick: () => fn(goal._id)};
  return {label: 'Поставить цель', icon: <GoalIcon />, onClick: () => fn()};
};

export const useCloneGoalMenuItem = (goal: Goals, isMe: boolean) => {
  const fn = useContext(GoalFormContext);
  if (isMe || !fn) return null;

  return {label: 'Скопировать цель себе', icon: <CopyIcon />, onClick: () => fn(goal._id)};
};

export const useCloneGoalForm = (goal: Goals) => {
  const fn = useContext(GoalFormContext);
  const isMe = useIsMe(goal.user);

  if (!fn || isMe) return null;

  return () => fn(goal._id);
};
