import {Stack} from '@mui/material';
import {fill} from 'lodash';
import {QuestItemListSkeleton} from './QuestItemListSkeleton';

export const QuestListSkeleton = () => (
  <Stack>
    {fill(Array(13), '').map((_, i) => (
      <QuestItemListSkeleton key={i} />
    ))}
  </Stack>
);
