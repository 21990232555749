import {ReactNode} from 'react';
import {LoadingButton} from '@mui/lab';
import {useQueryParam, StringParam} from 'use-query-params';

import {api} from 'api';
import ButtonMenu from 'components/ButtonMenu';
import {ReactComponent as FilterIcon} from 'svg/icons/filter.svg';

export const useContentsFilterMenu = (): [ReactNode, string | undefined] => {
  const [categoryId, setCategoryId] = useQueryParam('categoryId', StringParam);
  const {data: categories = [], isLoading} = api.endpoints.contentsCategories.useQuery();

  const Menu = isLoading ? (
    <LoadingButton loading={isLoading} />
  ) : (
    categories.length > 0 && (
      <ButtonMenu
        buttonProps={{
          icon: <FilterIcon />,
          variant: 'contained',
          color: 'secondary',
          size: 'small',
          sx: {height: 44},
        }}
        items={categories.map(item => ({
          label: item.name,
          selected: item._id === categoryId,
          onClick: () => setCategoryId(item._id),
        }))}
      />
    )
  );

  return [Menu, categoryId || undefined];
};
