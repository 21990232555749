import {ReactNode, useMemo} from 'react';
import {LoadingButton} from '@mui/lab';
import {useQueryParam, StringParam} from 'use-query-params';
import {slugify} from 'transliteration';

import {api} from 'api';
import ButtonMenu from 'components/ButtonMenu';
import {ReactComponent as FilterIcon} from 'svg/icons/filter.svg';

export const useTasksFilterMenu = (): [ReactNode, string | undefined] => {
  const [categoryName, setCategoryId] = useQueryParam('category', StringParam);
  const {data: categories = [], isLoading} = api.endpoints.tasksCategories.useQuery();
  const categoryId = useMemo(
    () => categories.find(item => slugify(item.name) === categoryName)?._id,
    [categoryName, categories]
  );
  const items = [{label: 'Все', selected: !categoryName, onClick: () => setCategoryId(undefined)}].concat(
    categories.map(item => {
      const slug = slugify(item.name);
      return {
        label: item.name,
        selected: slug === slugify(categoryName || ''),
        onClick: () => setCategoryId(slug),
      };
    })
  );

  const Menu = isLoading ? (
    <LoadingButton loading={isLoading} />
  ) : (
    <ButtonMenu
      buttonProps={{
        icon: <FilterIcon />,
        variant: 'contained',
        color: 'secondary',
        size: 'small',
      }}
      items={items}
    />
  );

  return [Menu, categoryId || undefined];
};
