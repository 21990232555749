import {Box, IconButton, TextField} from '@mui/material';
import {ReactComponent as SearchIcon} from 'svg/icons/searchIcon.svg';
import {ReactComponent as ClearIcon} from 'svg/icons/clearIcon.svg';
import {styled} from '@mui/system';
import {PRIMARY, GREY} from 'components/theme/palette';

const SearchBox = styled(Box)(() => ({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  height: '40px',
  paddingRight: '16px',
  border: `1px solid ${GREY[25]}`,
  borderRadius: '500px',
  '&:hover': {
    borderColor: PRIMARY[500],
    '& .MuiIconButton-root path': {
      stroke: PRIMARY[500],
    },
  },
  '& .MuiIconButton-root': {
    width: '38px',
    height: '38px',
    flexShrink: 0,
    padding: 0,

    '&:hover': {
      backgroundColor: 'none',
      '& path': {
        stroke: PRIMARY[500],
      },
    },
  },
}));

const StyledSearchField = styled(TextField)(() => ({
  flexGrow: 1,
  padding: 0,
  '& .MuiInputBase-root': {
    height: '100%',
    '&::before, &::after': {
      display: 'none',
      border: 'none',
    },
    '&:hover:not(.Mui-disabled)::before': {
      border: 'none',
    },
  },
  '& .MuiInputBase-input::placeholder': {
    color: GREY[200],
  },
}));

const StyledClearButton = styled(IconButton)(() => ({
  position: 'absolute',
  right: '11px',
  '&.MuiIconButton-root': {
    width: 16,
    height: 16,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
}));

export const SearchBlock = ({value}: {value?: string}) => {
  return (
    <SearchBox>
      <IconButton>
        <SearchIcon />
      </IconButton>
      <StyledSearchField variant="standard" size="medium" placeholder="Поиск" defaultValue={value} />
      {value && (
        <StyledClearButton>
          <ClearIcon />
        </StyledClearButton>
      )}
    </SearchBox>
  );
};

export default SearchBlock;
