import {ceil} from 'lodash';
import {useCallback, useEffect, useRef} from 'react';

import stackBlurImage from 'utils/blur';

export const BlurredImage = ({path, width}: {path: string; width?: number}) => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const img = useRef<HTMLImageElement>(new Image());

  const onLoad = useCallback(() => {
    if (img.current && canvasRef.current && width) {
      stackBlurImage(img.current, canvasRef.current, 75, ceil(width), ceil(width), () => {});
    }
  }, [width]);

  useEffect(() => {
    if (width && !img.current.src) {
      img.current.src = path;
      img.current.crossOrigin = 'Anonymous';
      img.current.onload = onLoad;
    }
  }, [path, onLoad, width]);

  return <canvas ref={canvasRef} />;
};
