import {memo} from 'react';
import {Box, Typography} from '@mui/material';

import {Stage} from '../../containers/Stage';
import {StagePointComponent} from '../../types';
import {useGetStagePointByIdWithSelector} from '../../hooks';
import {DefaultStagePoint} from './DefaultStagePoint';

export const StageInlinePreviewStagePoint: StagePointComponent = memo(({stageId, stagePointId, ...props}) => {
  const {
    questPoint: {name},
    stagePoint: {referenceId, referenceName},
  } = useGetStagePointByIdWithSelector({stagePointId, stageId}, point => ({
    stagePoint: {
      referenceId: point.stagePoint?.referenceId,
      referenceName: point.stagePoint?.referenceName,
    },
    questPoint: {
      name:
        point.questPointData.questPoint.options.design?.title ||
        point.questPointData.questPoint.name ||
        point.questPointData.name ||
        '',
    },
  }));

  if (!referenceId) return <DefaultStagePoint stageId={stageId} stagePointId={stagePointId} {...props} />;
  if (!referenceId || referenceName !== 'Stages') return null;
  return (
    <Box mx={2} py={2} borderRadius={4} bgcolor="appBg">
      <Typography variant="body2" fontWeight="medium" px={2}>
        {name}
      </Typography>
      <Box mt={1.5}>
        <Stage stageId={referenceId} hideHeader componentName="HorizontalPreviewStageVisual" />
      </Box>
    </Box>
  );
});
