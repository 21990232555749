import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import {GREY} from 'components/theme/palette';

import {ReactComponent as MessageIcon} from 'svg/icons/messageIcon.svg';

export const Disclaimer = ({disclaimer}: {disclaimer?: string}) =>
  disclaimer ? (
    <Stack direction="row" alignItems="top" spacing={0.25}>
      <Box component={MessageIcon} sx={{width: 12, height: 12, position: 'relative', top: -1}} />
      <Typography variant="subtitle2" sx={{color: GREY[200]}}>
        {disclaimer.slice(0, 31)}
      </Typography>
    </Stack>
  ) : null;
