import {Link} from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {NotificationIndication} from 'components/Layout/NotificationIndication';

import {ReactComponent as CheckIcon} from 'svg/quest/questCheck.svg';
import {ReactComponent as TaskStatusIconThird} from 'svg/icons/taskStatusIconThird.svg';

export const TaskPreviewHeader = ({
  link,
  category,
  status,
  name,
  nameSize = 'h3',
}: {
  link?: string;
  category?: string;
  name: string;
  status?: 'new' | 'completed' | 'in_progress';
  nameSize?: 'body2' | 'h3' | 'h2';
}) => {
  let addProps: any = {};
  if (link) addProps = {component: Link, to: link};
  return (
    <Box {...addProps} sx={{position: 'relative', display: 'block'}}>
      {category && (
        <Typography variant="caption" sx={{color: 'grey.200', mb: 0.5, display: 'block', textTransform: 'uppercase'}}>
          {category}
        </Typography>
      )}
      <Typography variant={nameSize} sx={{fontWeight: 500, lineHeight: '20px'}}>
        {name}
      </Typography>
      {status && (
        <Box
          sx={{
            width: status === 'in_progress' ? 20 : 16,
            height: status === 'in_progress' ? 20 : 16,
            right: 0,
            top: 0,
            position: 'absolute',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {status === 'new' && <NotificationIndication isSeen={false} />}
          {status === 'completed' && (
            <Box
              sx={{
                height: 16,
                width: 16,
                borderRadius: 2,
                backgroundColor: 'success.main',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Box component={CheckIcon} sx={{width: 8, height: 8, color: 'white'}} />
            </Box>
          )}
          {status === 'in_progress' && <Box component={TaskStatusIconThird} sx={{width: 20, height: 20}} />}
        </Box>
      )}
    </Box>
  );
};
