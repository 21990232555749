import {Box} from '@mui/material';

import {useMyId} from 'modules/auth';
import {PublicationList} from 'modules/feed/containers';

import {StageHeader} from '../StageHeader';
import {SubscriptionsList} from '../SubscriptionsContainers';
import {BottomButtonsContainer, StagePointList, StagePointMenuList} from '../StagePointsContainers';

import {StageComponent} from '../../types';

export const GoalStageVisual: StageComponent = ({stageId, hideHeader = false, inline}) => {
  const userId = useMyId();

  return (
    <>
      {!hideHeader && <StageHeader stageId={stageId} titleProps={{variant: 'body1', align: 'center'}} gradient />}
      <Box py={1} sx={{borderRadius: 4}} bgcolor="white" position="relative" zIndex={10} mt={-2}>
        <StagePointList withDivider stageId={stageId} componentName="LessonStagePoint" containerName="default" />

        <SubscriptionsList stageId={stageId} containerName="default" />
        <StagePointList
          withDivider
          stageId={stageId}
          componentName="ButtonStagePoint"
          containerName="goalReportContainer"
        />
        <PublicationList params={{userId: userId, stageId, withStageSubscriptions: true, sort: 'created_date'}} />
      </Box>

      <BottomButtonsContainer stageId={stageId} componentName="ButtonStagePoint" inline={inline} />
      <StagePointMenuList stageId={stageId} />
    </>
  );
};
