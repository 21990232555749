import * as _ from 'lodash/fp';
import uniqBy from 'lodash/uniqBy';
import {formatDate} from 'utils/dates';
import {QuestsPoints, StageProgress, StagesPoints, TimelineCheckReason, Timelines} from 'api/generated/users-api';

import {StageIdProp, StagePointIdProp} from '../types';
export * from './stagePointSelector';

export const getStagePath = (stageId?: string, postfix: string = '') => `/modules/${stageId}${postfix}`;

export const requiredStageProgress = (progress?: StageProgress) =>
  progress && progress.requiredPointsTotal
    ? (((progress.requiredPointsCompleted || 0) / progress.requiredPointsTotal) * 100).toFixed(0)
    : null;

export const optionalStageProgress = (progress?: StageProgress) =>
  progress && progress.optionalPointsTotal
    ? (((progress.optionalPointsCompleted || 0) / progress.optionalPointsTotal) * 100).toFixed(0)
    : null;

export const getLockedReason = (reasons?: TimelineCheckReason[], defaultReason?: string) => {
  if (!reasons || !reasons.length) return defaultReason;
  return uniqBy([...reasons].reverse(), item => item.requirement)
    .map(item => {
      if (item.requirement === 'dateStart') return `Станет доступно ${formatDate(item.value, 'dd MMMM')}`;
      if (item.requirement === 'quests') return `Станет доступно после выполнения блока «${item.value}»`;
      if (item.requirement === 'questPoints') return `Станет доступно после выполнения задания «${item.value}»`;
      return null;
    })
    .filter(Boolean)
    .join(', ');
};

export const isCompletedStatus = (status?: StagesPoints['status']) =>
  !!status && ['ready', 'completed'].includes(status);
export const isFailedStatus = (status?: StagesPoints['status']) => !!status && ['failed', 'rejected'].includes(status);

export const isQuestStagePoint = (type?: QuestsPoints['type']) =>
  !!type && ['quest', 'timeline', 'shortcut'].includes(type);

export const questStatusTexts: {[K in StagesPoints['status']]: string} = {
  new: 'Новое',
  in_progress: 'Выполняется',
  checking: 'Проверяется',
  ready: 'Готово',
  completed: 'Выполнено',
  deadline: 'Дедлайн',
  failed: 'Не выполнено',
  rejected: 'Отклонено',
};

export const getDeadline = (settings?: Timelines[], param?: 'dateFinish' | 'dateStart'): string | undefined =>
  _.compose(_.last, _.sortBy(_.identity), _.filter(Boolean), _.map(_.get(param || 'dateFinish')))(settings || []);

export const isStagePointEquals = (prev: StagePointIdProp & StageIdProp, next: StagePointIdProp & StageIdProp) =>
  prev.stageId === next.stageId && prev.stagePointId === next.stagePointId;
export const isStageEquals = (prev: StagePointIdProp & StageIdProp, next: StagePointIdProp & StageIdProp) =>
  prev.stageId === next.stageId;
