import {Box, styled} from '@mui/system';
import {GREY} from 'components/theme/palette';

const Container = styled('div')`
  height: 20px;
  width: 20px;
  border-radius: 10px;
  line-height: 12px;
  text-align: center;
  font-size: 12px;
  color: ${GREY[200]};
  border: 1px solid ${GREY[25]};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ListPosition = ({index}: {index: number}) => {
  return (
    <Container>
      <Box>{index + 1}</Box>
    </Container>
  );
};
