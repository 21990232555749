import Box from '@mui/material/Box';
import {StageComponent} from '../../types';
import {StagePointList} from '../StagePointsContainers';

export const GoalReportStageVisual: StageComponent = ({stageId}) => {
  return (
    <Box>
      <StagePointList stageId={stageId} containerName="lessonContent" componentName="LessonStagePoint" withDivider />
      <Box px={2} pt={2}>
        <StagePointList stageId={stageId} containerName="bottomButtonsContainer" componentName="ButtonStagePoint" />
      </Box>
    </Box>
  );
};
