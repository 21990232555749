import {memo} from 'react';
import {Box} from '@mui/material';
import {StagePointData} from 'api/generated/users-api';

import {WidgetVisual, Widget} from 'modules/widgets';

import {StagePointComponent} from '../../types';
import {useGetStagePointByIdWithSelector} from '../../hooks';

const selectWidgetStagePointData = (point: StagePointData) => ({
  stagePoint: {
    userId: point.stagePoint?.userId,
  },
  questPoint: {
    widgetId: point?.questPointData.questPoint.options.widgets?.widgetId,
    visualsId: point?.questPointData.questPoint.options.widgets?.visualsId,
  },
});

export const WidgetStagePoint: StagePointComponent = memo(({stagePointId, stageId}) => {
  const {
    stagePoint: {userId},
    questPoint: {visualsId, widgetId},
  } = useGetStagePointByIdWithSelector({stageId, stagePointId}, selectWidgetStagePointData);

  return (
    <Box p={2}>
      {widgetId && <Widget widgetId={widgetId} />}
      {visualsId?.map(visualId => (
        <Box m={2} key={visualId}>
          <WidgetVisual
            visualId={visualId}
            stagesId={[stageId]}
            stagesPointsId={[stagePointId]}
            usersId={userId ? [userId] : []}
          />
        </Box>
      ))}
    </Box>
  );
});
