import {isRejectedWithValue, Middleware, MiddlewareAPI} from '@reduxjs/toolkit';
import {setBlocker} from './blockerState';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const catchUnauthorizedResponseMiddleware: Middleware = (api: MiddlewareAPI) => next => action => {
  if (isRejectedWithValue(action)) {
    // eslint-disable-next-line no-console
    if (process.env.NODE_ENV === 'development') console.log('rejected', action);
    // если в ответе пришел 307, то надо перегрузиться
    if (action.payload.status === 307) window.location.reload();
    else if (action.payload.status === 403 && action.payload.data?.data?.blocker) {
      const {name, message} = action.payload.data.data.blocker;
      api.dispatch(setBlocker({name, message}));
    }
  }
  return next(action);
};
