import {Card, CardContent, Stack, Typography} from '@mui/material';
import {HeaderSetup, InfoBlock} from 'components/Layout';
import {SearchBlock, SearchTabs, Divider, TeamItem} from 'modules/search/components';
import PostItem from 'modules/search/components/PostItem';
import {styled} from '@mui/system';
import GoalItem from 'modules/search/components/GoalItem';

const HighlightedText = styled('span')(() => ({
  backgroundColor: '#FEF0C7',
  borderRadius: '4px',
  fontSize: '13px',
  lineHeight: '18px',
}));

export const SearchAllPageMarkup = () => {
  return (
    <>
      <HeaderSetup title="" />
      <Stack spacing={0.5}>
        <Card>
          <CardContent>
            <SearchBlock value={'благо'} />
          </CardContent>
          <SearchTabs />
        </Card>

        <Card sx={{flexShrink: 0}}>
          <CardContent>
            <Divider title={'Цели'} />
            <Stack gap={0.25}>
              <GoalItem
                userName="ivan_ovsi"
                goalName="Заработать на дом"
                score={76.19}
                goalInfo={
                  <>
                    <InfoBlock size="small" label="Завершена за" value={'2 дня'} />
                    <InfoBlock size="small" label="Цель" value={'Месяц'} />
                    <InfoBlock size="small" label="Денежный экв." value={'200 000₽'} />
                  </>
                }
                isMoneyGoal
              />

              <GoalItem
                userName="pdll"
                goalName="Заработать 1 000 000₽ за месяц"
                score={98.24}
                type="bronze"
                goalInfo={
                  <>
                    <InfoBlock size="small" label="Завершена за" value={'2 дня'} />
                    <InfoBlock size="small" label="Цель" value={'Месяц'} />
                    <InfoBlock size="small" label="Денежный экв." value={'200 000₽'} />
                  </>
                }
              />

              <GoalItem
                userName="ivanklabukov"
                goalName="Научится летать на вертолете"
                score={81.2}
                type="bronze"
                goalInfo={
                  <>
                    <InfoBlock size="small" label="Осталось" value={'4 месяца'} />
                    <InfoBlock size="small" label="Цель" value={'1 год'} />
                    <InfoBlock size="small" label="Денежный экв." value={'200 000₽'} />
                  </>
                }
              />
            </Stack>

            <Divider title={'Пользователи'} />
            <Stack gap={0.25}>
              {/* <UserItem title={'ivanderbilov'} followers={'435 подписчиков'} type="gold" score={99.13} />
              <UserItem title={'ksenia.garaeva'} followers={'108 подписчиков'} score={75.13} />
              <UserItem title={'pdll'} followers={'108 подписчиков'} type="bronze" score={98.24} /> */}
            </Stack>

            <Divider title={'Команды'} />
            <Stack gap={0.25}>
              <TeamItem
              // title={'Фемида Team'}
              // members={'10 участников'}
              // comment={'Команда лучших юристов'}
              // type="gold"
              // score={99.67}
              />
              <TeamItem
              // title={'FortuneClub'}
              // members={'9 участников'}
              // comment={`Набираем персон с доходом\nот $50к/мес`}
              // score={89.99}
              />
            </Stack>

            <Divider title={'Посты'} />
            <Stack gap={0.25}>
              <PostItem
                userName={'ksenia.garaeva'}
                description={
                  <Typography variant="body2" display="inline-block">
                    ...
                    <HighlightedText>Благо</HighlightedText>
                    дарности пост 🙏 <br />
                    Последний месяц я работаю почти без выходных, что сильно выделяет ...
                  </Typography>
                }
                score={75.13}
              />
              <PostItem
                userName={'ksenia.garaeva'}
                description={
                  <Typography variant="body2" display="inline-block">
                    ...
                    <HighlightedText>благо</HighlightedText>
                    даря своему упорству я осуществила свою давнюю цель – я позавтракала в Кении в сафаре ...
                  </Typography>
                }
                score={75.13}
                isCompleted
              />
              <PostItem
                userName={'ivanderbilov'}
                description={
                  <Typography variant="body2" display="inline-block">
                    ... В свое время, <HighlightedText>благо</HighlightedText>даря себя, я упустил момент, когда все
                    алгоритмы показа постов поменялись. ...
                  </Typography>
                }
                score={93.2}
              />
            </Stack>
          </CardContent>
        </Card>
      </Stack>
    </>
  );
};

export default SearchAllPageMarkup;
