import {Box, Typography} from '@mui/material';
import {NotionContent} from 'modules/contents/containers';

import {useGetStagePointsLengthByContainerName, useStageData} from '../../hooks';
import {StageHeader} from '../StageHeader';
import {StageComponent} from '../../types';
import {StagePointList} from '../StagePointsContainers';
import {BottomButtonsContainer} from '../StagePointsContainers';

export const LessonStageVisual: StageComponent = ({stageId, inline, hideHeader = false}) => {
  const {
    data: {data},
  } = useStageData(stageId);
  const contentLength = useGetStagePointsLengthByContainerName({stageId, containerName: 'lessonContent'});

  return (
    <Box flexGrow={1} bgcolor="white" height="100%" position="relative" sx={{borderRadius: 2}}>
      {!hideHeader && (
        <StageHeader
          stageId={stageId}
          showDeadline
          showStatus
          titleProps={{variant: 'h3'}}
          subTitle={
            data?.stage.quest?.design?.estimatedText
              ? `Время выполнения: ${data?.stage.quest?.design?.estimatedText}`
              : undefined
          }
        />
      )}
      <Box pb={1} pt={2} sx={{borderRadius: 4}} bgcolor="white" position="relative" zIndex={10} mt={-2}>
        {data?.stage.quest?.design?.notionPageId && (
          <Box p={2}>
            <NotionContent notionPageId={data.stage.quest.design.notionPageId} />
          </Box>
        )}
        <StagePointList withDivider stageId={stageId} containerName="lessonWidget" componentName="WidgetStagePoint" />
        {contentLength > 0 && (
          <>
            <Box p={2} bgcolor="divider">
              <Typography variant="h3" fontWeight="bold">
                Задания:
              </Typography>
            </Box>
            <Box pb={2}>
              <StagePointList
                withDivider
                stageId={stageId}
                containerName="lessonContent"
                componentName="LessonStagePoint"
              />
            </Box>
          </>
        )}
      </Box>

      <BottomButtonsContainer stageId={stageId} componentName="ButtonStagePoint" inline={inline} />
    </Box>
  );
};
