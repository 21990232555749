import {isEmpty, isUndefined} from 'lodash';
import {PropsWithChildren, useContext, useEffect, useReducer} from 'react';
import {
  HeaderActionsContext,
  HeaderContext,
  HeaderContextAction,
  HeaderContextState,
  initialHeaderState,
} from './context';

const reducer = (state: HeaderContextState, action: HeaderContextAction) => {
  if (action.type === 'set') return {...action.payload};
  if (action.type === 'reset') return {...initialHeaderState};
  return state;
};

export const HeaderProvider = ({children}: PropsWithChildren<{}>) => {
  const [state, dispatch] = useReducer(reducer, initialHeaderState);

  return (
    <HeaderContext.Provider value={state}>
      <HeaderActionsContext.Provider value={dispatch}>{children}</HeaderActionsContext.Provider>
    </HeaderContext.Provider>
  );
};

export const HeaderSetup = ({children, ...props}: PropsWithChildren<Omit<HeaderContextState, 'action'>>) => {
  const dispatch = useContext(HeaderActionsContext);

  useEffect(() => {
    if (dispatch && !isEmpty(props)) dispatch({type: 'set', payload: props});
  }, [props, dispatch]);

  useEffect(() => {
    if (dispatch && !isUndefined(children)) dispatch({type: 'set', payload: {actions: children}});
  }, [children, dispatch]);

  return null;
};
