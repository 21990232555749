import _ from 'lodash/fp';
import {atomFamily} from 'jotai/utils';
import {atom, WritableAtom, Atom} from 'jotai';
import {shallowEqual} from 'react-redux';
import {AnswerDto, Answers, TemplatesOptions} from 'api/generated/users-api';

import {AnswerProps} from './types';

export type PartialAnswer = Omit<AnswerDto, 'scriptActionId'>;
export const scriptAnswersAtom = atom<{[K: string]: PartialAnswer[]}>({});

export const flatAnswersAtom = atom(get => {
  const data = get(scriptAnswersAtom);
  return _.flatten(
    Object.keys(data).map(scriptActionId => data[scriptActionId].map(item => ({...item, scriptActionId})))
  ).filter(item => !!item.value);
});

export const answersForTemplateByIndex = atomFamily<string, Atom<Record<string, PartialAnswer[]>>>((actionId: string) =>
  atom(
    get =>
      _.compose(
        _.groupBy('index'),
        _.map((item: PartialAnswer) => ({...item, index: item.index || 0}))
      )(get(scriptAnswersAtom)[actionId]) as any
  )
);

export const setScriptAnswers = atom(null, (get, set, arg: Answers[]) => {
  const data = _.compose(
    _.fromPairs,
    _.map(item => [item[0], _.map(_.pick(['templateOptionId', 'index', 'value']), item[1])]),
    _.toPairs,
    _.groupBy('scriptActionId')
  )(arg);
  set(scriptAnswersAtom, data);
});

export const scriptActionExactValue = atomFamily<AnswerProps, WritableAtom<string, string>>(
  ({scriptActionId, ...params}) =>
    atom(
      get => {
        const answers = get(scriptAnswersAtom)[scriptActionId];
        return _.find({...params}, answers)?.value || '';
      },
      (get, set, value) => {
        const answers = get(scriptAnswersAtom)[scriptActionId] || [];
        const index = _.findIndex({...params}, answers);
        if (index > -1) answers[index].value = value;
        else answers.push({...params, value});
        set(scriptAnswersAtom, {...get(scriptAnswersAtom), [scriptActionId]: answers});
      }
    ),
  shallowEqual
);

export const scriptActionGroupedValue = atomFamily<
  Omit<AnswerProps, 'templateOptionId'>,
  WritableAtom<string, string | string[]>
>(
  ({scriptActionId, ...params}) =>
    atom(
      get => {
        const answers = get(scriptAnswersAtom)[scriptActionId] || [];
        return _.filter({...params}, answers)
          .map(_.get('templateOptionId'))
          .join(',');
      },
      (get, set, arg) => {
        const value = typeof arg === 'string' ? [arg] : arg;
        if (_.isArray(value)) {
          const answers = get(scriptAnswersAtom)[scriptActionId] || [];
          const filtereedAnswers = _.filter(item => item.index !== params.index, answers);
          const newItems = value.map(templateOptionId => ({
            ...params,
            scriptActionId,
            templateOptionId,
            value: templateOptionId,
          }));

          set(scriptAnswersAtom, {...get(scriptAnswersAtom), [scriptActionId]: _.concat(filtereedAnswers, newItems)});
        }
      }
    ),
  shallowEqual
);

export const scriptActionArrayValue = atomFamily<
  {actionId: string; min: number; max?: number; options: string[]},
  Atom<Record<string, any>[]>
>(
  ({actionId, min, options}) =>
    atom(get => {
      const answers = _.groupBy('index', get(scriptAnswersAtom)[actionId]);
      const keys = _.compose(
        _.map((item: string) => _.parseInt(10, item) + 1),
        _.keys
      )(answers);
      const maxKey = _.max([...keys, min || 1]) || 1;
      const result = new Array(maxKey).fill(0).map((key, index) => {
        if (!answers[index])
          return options.reduce(
            (acc, item) => ({...acc, [`${actionId}.${index}.${item}`]: ''}),
            {} as Record<string, any>
          );
        return answers[index].reduce(
          (acc, item) => ({...acc, [`${actionId}.${index}.${item.templateOptionId}`]: item.value}),
          {} as Record<string, any>
        );
      });
      return result;
    }),
  shallowEqual
);

export const patchAnswersAtom = atom<((dto: AnswerDto) => void) | undefined>(undefined);
export const optionsAton = atom<TemplatesOptions[]>([]);
export const getOptionsAtonm = atomFamily<string | string[], Atom<TemplatesOptions[]>>(param =>
  atom(get => {
    const data = get(optionsAton);
    if (typeof param === 'string') {
      const option = data.find(item => item._id === param);
      return option ? [option] : [];
    }
    return data.filter(item => param.includes(`${item._id}`));
  })
);
export const getOptionsByTemplateIdAtom = atomFamily<string, Atom<TemplatesOptions[]>>(param =>
  atom(get => {
    const data = get(optionsAton);
    return data.filter(item => item.templateId === param);
  })
);
