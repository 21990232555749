import {useMatch, useNavigate} from 'react-router';
import {Box, Card, Typography, CardContent} from '@mui/material';

import {HeaderSetup} from 'components/Layout';
import {useSearchField} from 'components/SearchField';
import SegmentedControl from 'components/SegmentedControl';

import {UsersList} from 'modules/search';
import {useMyStreamId} from 'modules/auth';
import {GroupsList} from 'modules/search/containers/GroupsList';

export const RatingPage = ({path}: {path: string}) => {
  const navigate = useNavigate();
  const match = useMatch(path);
  const streamId = useMyStreamId();
  const [Search, searchValue] = useSearchField();

  const tab = match?.params.tab || '';
  return (
    <Box width={1}>
      <HeaderSetup showSettings={false} title="Рейтинг" backLink="/discover" />
      <Search />
      <Card>
        <CardContent>
          <SegmentedControl
            fullWidth
            buttons={[
              {value: 'users', content: 'Пользователи'},
              {value: 'groups', content: 'Группы'},
            ]}
            value={tab}
            onChange={newValue => newValue && newValue !== tab && navigate(`../${newValue}`)}
          />
        </CardContent>
        <CardContent>
          <Typography variant="h3">{tab === 'users' ? 'Топ 100 пользователей' : 'Рейтинг команд'}</Typography>
        </CardContent>
      </Card>
      <Box mt={0.5}>
        {tab === 'users' && (
          <UsersList
            spacing={0.25}
            showPosition
            showSubscribeButton
            params={{sort: 'rating', username: searchValue, streamId}}
          />
        )}
        {tab === 'groups' && (
          <GroupsList spacing={0.25} showPosition params={{sort: 'rating', streamId, name: searchValue || undefined}} />
        )}
      </Box>
    </Box>
  );
};
