import {memo} from 'react';
import plural from 'plural-ru';
import {Box, ButtonBase, Typography} from '@mui/material';

import {StagePointData} from 'api/generated/users-api';
// import {Deadline} from '../Deadline';
// import {useNavigate} from 'react-router';
// import {ReactComponent as AwardIcon} from 'svg/icons/awardIcon.svg';
import {ReactComponent as ChevronRight} from 'svg/chevrons/chevronRight.svg';

import {StagePointImage} from '../StagePointImage';

import {StagePointComponent} from '../../types';
import {useGetStagePointByIdWithSelector} from '../../hooks';
import {
  getLockedReason,
  // getStagePath,
  // isQuestStagePoint,
  isCompletedStatus,
  questStatusTexts,
  selectStagePointCustomOptions,
} from '../../utils';

export const selectDefaultStagePointData = (point: StagePointData) => ({
  stagePoint: {
    status: point.stagePoint?.status,
    referenceId: point.stagePoint?.referenceId,
    isCompleted: isCompletedStatus(point.stagePoint?.status),
  },
  questPoint: {
    ...selectStagePointCustomOptions(point),
    pointsLength: point.questPointData.points?.length,
    available: point.questPointData.timelineCheck.available,
    lockedResons: getLockedReason(point.questPointData.timelineCheck.reasons, 'Пока недоступно'),
    name:
      point.questPointData.questPoint.type === 'goal'
        ? point.questPointData.name
        : point.questPointData.questPoint.options.design?.title ||
          point.questPointData.questPoint.name ||
          point.questPointData.name ||
          '',
    imageFileId: point.questPointData.questPoint.options.design?.fileId || point.questPointData.fileId,
  },
});

export const DefaultStagePoint: StagePointComponent = memo(({stagePointId, stageId, usePressOptions, type}) => {
  // const navigate = useNavigate();
  // const {data} = useStageData(stageId);

  const {isLoading, canPress, onPress} = usePressOptions({stagePointId, stageId});
  const {
    questPoint: {available, imageFileId, lockedResons, pointsLength, name, hidePointOnDone},
    stagePoint: {status, isCompleted},
  } = useGetStagePointByIdWithSelector({stagePointId, stageId}, selectDefaultStagePointData);

  if (['payment', 'widget'].includes(type)) return null;
  const textColor = available ? 'grey.500' : 'grey.200';

  if (isCompleted && hidePointOnDone) return null;
  return (
    <ButtonBase onClick={!canPress ? undefined : onPress} disabled={!canPress || isLoading} sx={{width: 1}}>
      <Box px={2} py={1.5} display="flex" flexDirection="row" alignItems="flex-start" width={1}>
        <Box mr={2} sx={{opacity: !available ? 0.3 : 1}}>
          <StagePointImage isLoading={isLoading} status={status} available={available} fileId={imageFileId} />
        </Box>
        <Box flexGrow={1} minHeight="100%" alignSelf="center">
          <Typography variant="body1" textAlign="left" fontWeight="medium" color={available ? 'black' : 'grey.200'}>
            {name}
          </Typography>

          <Box mt={0.5}>
            {/* {data?.timeline && (
              <Deadline timeline={data.timeline} textAlign="left" variant="subtitle1" color={textColor} />
            )} */}
            <Typography variant="subtitle1" textAlign="left" color={textColor}>
              {pointsLength && `${pointsLength} ${plural(pointsLength, 'задание', 'задания', 'заданий')}`}
              {!pointsLength && (canPress ? (status ? questStatusTexts[status] : '') : lockedResons)}
            </Typography>
          </Box>
        </Box>
        <Box display="flex" flexDirection="row" alignItems="center" alignSelf="center">
          {/* {isQuestStagePoint(type) && isCompleted && !!referenceId && (
            <IconButton
              onClick={e => {
                e.stopPropagation();
                navigate(getStagePath(referenceId, '/done'));
              }}
            >
              <AwardIcon width={20} height={20} color="grey.200" />
            </IconButton>
          )} */}
          {available && <ChevronRight />}
        </Box>
      </Box>
    </ButtonBase>
  );
});
