import {GREY} from '../palette';

// ----------------------------------------------------------------------

export default function Link() {
  return {
    MuiLink: {
      styleOverrides: {
        root: {
          color: GREY[200],
          cursor: 'pointer',
        },
      },
    },
  };
}
