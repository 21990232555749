import {Outlet} from 'react-router';
import {ReactNode} from 'react';
import {QueryDefinition} from '@reduxjs/toolkit/dist/query';
import {UseQueryStateResult} from '@reduxjs/toolkit/dist/query/react/buildHooks';
import {isFunction} from 'lodash';
import {ErrorPage} from 'components/Layout';

type IsExistsContainerProps<Result extends object> = {
  useFetch: () => UseQueryStateResult<QueryDefinition<any, any, any, Result>, any>;
  context?: React.Context<any>;
  debug?: true;
  ErrorComponent?: ReactNode;
  LoadingComponent?: ReactNode;
  children?: ReactNode | ((data: Result) => JSX.Element);
  checkLoadingFlag?: boolean;
  passUninitialized?: boolean;
  errorMessage?: Record<number, string>;
  hideError?: boolean;
};

export function IsExistsContainer<Result extends object>({
  debug,
  useFetch,
  children,
  errorMessage,
  ErrorComponent,
  context: Context,
  LoadingComponent,
  hideError = false,
  checkLoadingFlag = false,
  passUninitialized = false,
}: IsExistsContainerProps<Result>) {
  const {isFetching, isError, isUninitialized, isLoading, data, error} = useFetch();
  // eslint-disable-next-line no-console
  if (debug && process.env.NODE_ENV === 'development') console.log({data, isFetching});
  if (isLoading || (checkLoadingFlag && isFetching)) return LoadingComponent ? <>{LoadingComponent}</> : <div />;
  if (passUninitialized && isUninitialized)
    return children ? <>{isFunction(children) ? children(data) : children}</> : <Outlet />;

  if ((!isFetching && isError) || !data) {
    if (hideError) return null;
    const status = error?.status || 500;
    return ErrorComponent ? <>{ErrorComponent}</> : <ErrorPage message={errorMessage?.[status]} status={status} />;
  }

  const Child = children ? <>{isFunction(children) ? children(data) : children}</> : <Outlet />;
  if (!Context) return Child;
  return <Context.Provider value={data}>{Child}</Context.Provider>;
}

export default IsExistsContainer;
