import * as yup from 'yup';
import {useRef} from 'react';
import {useSnackbar} from 'notistack';
import {merge, omit, pick} from 'lodash';
import {ErrorMessage, Form, FormikProvider, useFormik} from 'formik';

import ru from 'date-fns/esm/locale/ru';
import {addHours, addYears, formatISO, getDate, getMonth, getYear, parse, parseISO} from 'date-fns';

import {TextField} from 'formik-mui';
import {AlternateEmail} from '@mui/icons-material';
import {Box, Button, Stack, Typography} from '@mui/material';

import {api} from 'api';
import {URL_REGEX, yupStringRequired} from 'utils';

import {GREY} from 'components/theme/palette';
import {UserProfileDto} from 'api/generated/users-api';
import {StyledFastField} from 'components/FormFactory';
import SegmentedControl from 'components/SegmentedControl';
import {InputCharCounter} from 'components/InputCharCounter';
import {useRelationStatusOptions} from 'modules/profile/utils';
import SelectField from 'components/FormFactory/Field/SelectField';

import {UploadUserPhoto} from '..';

const today = addYears(new Date(), -10);
const year = getYear(today);
const years = new Array(100).fill(0).map((_, index) => ({value: year - index, name: year - index}));
const months = new Array(12).fill(0).map((_, index) => {
  const name = ru.localize?.month(index);
  return {value: index + 1, name};
});
const days = new Array(31).fill(0).map((_, index) => {
  const value = (index + 1).toString();
  return {value, name: value};
});

function cacheTest<T>(asyncValidate: (value: T) => Promise<boolean>) {
  let isValid = false;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  let _value = '';
  return async (value: any) => {
    if (value !== _value) {
      const response = await asyncValidate(value);
      _value = value;
      isValid = response;
      return response;
    }
    return isValid;
  };
}

export const UserSettings = ({onSkip, onSuccess}: {onSuccess?: () => void; onSkip?: () => void}) => {
  const {enqueueSnackbar, closeSnackbar} = useSnackbar();
  const [checkLogin] = api.endpoints.profileCheckLogin.useMutation();
  const [update] = api.endpoints.profileUpdate.useMutation();
  const checkLoginTest = useRef(
    cacheTest(async (login: string) => {
      const result = await checkLogin({userLoginDto: {username: login}});
      if ('data' in result) return true;
      return false;
    })
  );
  const {info} = api.endpoints.profileIndex.useQuery(undefined, {
    selectFromResult: ({data}) => {
      if (!data) return {info: null};
      return {
        info: merge(
          {username: data.username || ''},
          pick(data.account, [
            'name',
            'disclaimer',
            'username',
            'middlename',
            'gender',
            'surname',
            'birthDate',
            'externalLink',
            'description',
            'relationStatus',
            'city',
          ])
        ),
      };
    },
  });

  const relationOptions = useRelationStatusOptions(info?.gender);
  const birhDate = info?.birthDate ? parseISO(info.birthDate) : null;

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    initialValues: {
      name: info?.name || '',
      gender: info?.gender || '',
      surname: info?.surname || '',
      username: info?.username || '',
      middlename: info?.middlename || '',
      date: {
        day: birhDate ? getDate(birhDate) : '',
        month: birhDate ? getMonth(birhDate) + 1 : '',
        year: birhDate ? getYear(birhDate) : '',
      },
      city: info?.city || '',
      disclaimer: info?.disclaimer || '',
      description: info?.description || '',
      externalLink: info?.externalLink || '',
      relationStatus: info?.relationStatus || '',
    },
    onSubmit: async (data, {setSubmitting}) => {
      const dto = {...omit(data, ['date', 'gender'])} as UserProfileDto;
      if (data.date)
        dto.birthDate = formatISO(
          addHours(parse(`${data.date.day}.${data.date.month}.${data.date.year}`, 'dd.MM.yyyy', new Date()), 10)
        );
      else dto.birthDate = undefined;
      if (data.gender) dto.gender = data.gender as any;
      if (!data.gender) dto.gender = 'not_sure';
      if (!data.surname) dto.surname = undefined;
      if (!data.username) dto.username = undefined;
      await update({userProfileDto: dto});
      setSubmitting(false);
      if (onSuccess) onSuccess();
      else {
        const key = enqueueSnackbar('Данные обновлены!');
        setTimeout(() => closeSnackbar(key), 2000);
      }
    },
    validationSchema: yup.object().shape({
      name: yupStringRequired
        .matches(/^([\wа-яё]+)([\s-][\wа-яё]+)?$/gi, 'Только буквы русского или латинского алфвафита, тире и пробел')
        .min(2)
        .trim()
        .max(30),
      middlename: yup
        .string()
        .trim()
        .matches(/^([\wа-яё]+)([\s-][\wа-яё]+)?$/gi, 'Только буквы русского или латинского алфвафита, тире и пробел')
        .min(2)
        .max(30),
      surname: yupStringRequired
        .trim()
        .matches(/^([\wа-яё]+)([\s-][\wа-яё]+)?$/gi, 'Только буквы русского или латинского алфвафита, тире и пробел')
        .min(2)
        .max(30),
      username: yup
        .string()
        .trim()
        .matches(
          /(?!^[_,.,0-9]+$)(?!^\.+(.*))(?!(.*)\.+$)^[a-z,A-Z,0-9,_,.]+$/,
          'Логин может состоять только из латинских букв, цифр и знаков _ , .'
        )
        .test('checkUsername', 'Такое имя пользователя занято', checkLoginTest.current)
        .min(3),
      gender: yup.string().oneOf(['male', 'female'], 'Укажите свой пол'),
      relationStatus: yup
        .string()
        .notRequired()
        .oneOf(
          ['single', 'in_relation', 'engaged', 'married', 'civil_union', 'in_love', 'complicated', 'in_search'],
          'Укажите свой статус отношений'
        ),
      city: yup.string().notRequired(),
      description: yup.string().notRequired(),
      externalLink: yup.string().matches(URL_REGEX, 'Введите корректный url'),
      date: yup.object().shape({
        day: yup.number().min(0).max(31).required(),
        month: yup.number().min(0).max(12).required(),
        year: yup.number().required(),
      }),
    }),
  });

  const {isValid, isSubmitting, values, setFieldValue} = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off">
        <Stack spacing={2}>
          <UploadUserPhoto />
          <StyledFastField
            name="name"
            component={TextField}
            type="text"
            variant="standard"
            size="small"
            label="Имя"
            required
            inputProps={{
              maxLength: 30,
            }}
          />
          <StyledFastField
            name="middlename"
            component={TextField}
            type="text"
            variant="standard"
            size="small"
            label="Отчество"
            inputProps={{
              maxLength: 30,
            }}
          />
          <StyledFastField
            name="surname"
            component={TextField}
            type="text"
            variant="standard"
            size="small"
            label="Фамилия"
            inputProps={{
              maxLength: 30,
            }}
          />
          <StyledFastField
            name="username"
            component={TextField}
            type="text"
            variant="standard"
            size="small"
            autoCapitalize="off"
            label="username"
            InputProps={{
              startAdornment: <AlternateEmail fontSize="small" sx={{width: 15, height: 15}} htmlColor={GREY[200]} />,
              maxLength: 15,
            }}
          />
          <Stack spacing={1}>
            <SegmentedControl
              fullWidth
              value={values.gender}
              onChange={newValue => setFieldValue('gender', newValue, true)}
              buttons={[
                {value: 'male', content: 'Мужской'},
                {value: 'female', content: 'Женский'},
              ]}
            />
            <ErrorMessage
              name="gender"
              render={message => (
                <Typography variant="subtitle1" color="error.main">
                  {message}
                </Typography>
              )}
            />
          </Stack>
          <div>
            <Typography variant="subtitle1" sx={{color: GREY[200]}}>
              Дата рождения *
            </Typography>
            <Stack direction="row" justifyContent="stretch" gap={1}>
              <Box width="100%">
                <SelectField options={days} name="date.day" type="select" dense label="" />
              </Box>
              <Box width="100%">
                <SelectField options={months} name="date.month" type="select" dense label="" />
              </Box>
              <Box width="100%">
                <SelectField options={years as any} name="date.year" type="select" dense label="" />
              </Box>
            </Stack>
          </div>
          <Box>
            <StyledFastField
              fullWidth
              multiline
              minRows={4}
              type="textarea"
              name="description"
              component={TextField}
              variant="standard"
              placeholder="Пять фактов о себе"
              inputProps={{
                maxLength: 200,
              }}
            />
            <InputCharCounter name="description" maxValue={200} />
          </Box>
          <StyledFastField
            fullWidth
            component={TextField}
            type="text"
            size="small"
            variant="standard"
            name="externalLink"
            label="Активная ссылка"
          />
          <StyledFastField
            fullWidth
            component={TextField}
            type="text"
            size="small"
            variant="standard"
            name="city"
            label="Город"
          />
          <SelectField options={relationOptions} name="relationStatus" type="select" dense label="Статус отношений" />
          <Box>
            <StyledFastField
              fullWidth
              component={TextField}
              type="text"
              size="small"
              name="disclaimer"
              variant="standard"
              label="Коротко напишите, чем вы занимаетесь"
              inputProps={{
                maxLength: 31,
              }}
            />
            <InputCharCounter name="disclaimer" maxValue={31} />
          </Box>
        </Stack>
        <Stack mt={4} spacing={2}>
          <Button type="submit" variant="contained" disabled={!isValid || isSubmitting}>
            Сохранить изменения
          </Button>
          {onSkip && (
            <Button variant="text" onClick={onSkip}>
              Заполнить позже
            </Button>
          )}
        </Stack>
      </Form>
    </FormikProvider>
  );
};
