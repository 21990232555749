import {useSnackbar} from 'notistack';
import {useNavigate} from 'react-router';
import {skipToken} from '@reduxjs/toolkit/dist/query';

import {api} from 'api';
import {StagePointData} from 'api/generated/users-api';

import {QuestHeader} from 'modules/oldQuests2/components';
import {getActiveQuestPath, getLockedReason} from 'modules/oldQuests2/utils';
import {WidgetHoverBox} from 'components/EntityWidgets';

export const ShortcutQuestPoint: React.VFC<StagePointData> = ({questPointData, stagePoint}) => {
  const navigate = useNavigate();
  const {enqueueSnackbar} = useSnackbar();
  const [accept] = api.endpoints.questAccept.useMutation();
  const {data, isLoading} = api.endpoints.stagePointShortcutData.useQuery(
    stagePoint?._id ? {pointId: stagePoint?._id} : skipToken
  );

  const reason = getLockedReason(questPointData.timelineCheck.reasons);
  const questTimelinesExists = data?.quest?.timelines && data.quest.timelines.length > 0;
  const locked = !data?.stage && (!questPointData.timelineCheck.available || !questTimelinesExists);

  const navigateToStage = async () => {
    if (
      !data?.stage &&
      stagePoint?._id &&
      data?.quest?._id &&
      data?.quest?.timelines &&
      data.quest.timelines[0] &&
      data.quest.timelines[0]._id
    ) {
      const result = await accept({
        questId: data.quest._id,
        questActionDto: {timelineId: data.quest.timelines[0]._id},
      });
      if ('data' in result && result.data.stage._id) navigate(getActiveQuestPath(result.data.stage._id));
      if ('error' in result)
        enqueueSnackbar('Не получилось взять квест в работу', {variant: 'error', autoHideDuration: 2000});
    }
    if (data?.stage?._id) navigate(getActiveQuestPath(data.stage._id));
  };

  return (
    <WidgetHoverBox
      py={2}
      mx={1}
      onClick={!locked ? navigateToStage : undefined}
      sx={{cursor: !locked ? 'pointer' : undefined}}
    >
      <QuestHeader
        isLoading={isLoading}
        subtitle={reason || undefined}
        fileId={questPointData.fileId}
        name={questPointData.name || ''}
        timeline={data?.stage?.timeline}
        progress={data?.stage?.totalProgress}
        status={(locked ? 'locked' : data.stage?.status) as any}
      />
    </WidgetHoverBox>
  );
};
