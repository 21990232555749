import {useIsTablet} from 'utils';
// import {ReactComponent as PrivacyIcon} from 'svg/icons/privacyIcon.svg';

import {styled} from '@mui/system';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import {HeaderSetup} from '..';
import {SideNavigation} from '../BottomNavigation';

export type ErrorPageProps = {
  message?: string;
  status?: 404 | 401 | 403 | 500;
  statusIcon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
};

const StatusTitle = styled('h1')`
  font-size: 80px;
  line-height: 80px;
  text-align: center;
`;

const defaultMessages = {
  404: 'Такой страницы не существует',
  401: 'Нужно авторизоваться, чтобы посмотреть эту страницу',
  403: 'Доступ к этой странице запрещен',
  500: 'Ошибка на стороне системы, обратитесь в службу технической поддержки',
};

export const ErrorPage = ({status = 404, statusIcon, message}: ErrorPageProps) => {
  const isTablet = useIsTablet();
  return (
    <Box mt={5}>
      <HeaderSetup showSettings={false} title="" />
      {!statusIcon && <StatusTitle color="primary.main">{status}</StatusTitle>}
      {statusIcon && (
        <Box display="flex" justifyContent="center">
          <Box component={statusIcon} width={160} height={160} color="primary.main" />
        </Box>
      )}
      <Typography
        variant="h3"
        align="center"
        sx={{mt: 1, color: 'grey.200', maxWidth: '90%', width: '100%', marginLeft: 'auto', marginRight: 'auto'}}
      >
        {message || defaultMessages[status] || 'Ошибка'}
      </Typography>
      <Box mt={5}>
        <SideNavigation mode="page" withProfileImage={isTablet} />
      </Box>
    </Box>
  );
};
