import {Theme} from '@mui/material/styles';
import {GREY} from '../palette';

// ----------------------------------------------------------------------

export default function Tabs(theme: Theme) {
  return {
    MuiTabs: {
      styleOverrides: {
        root: {
          minHeight: 40,
        },
        flexContainer: {
          paddingLeft: theme.spacing(2),
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          minHeight: 40,
          minWidth: 'auto',
          padding: theme.spacing(1),
          fontSize: 16,
          lineHeight: '20px',
          color: GREY[200],
        },
      },
    },
  };
}
