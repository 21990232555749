import {Stack} from '@mui/material';
import {AdventureQuestSkeleton} from '../../Quest';
import {ProfileHeaderSkeleton} from '../components';

export const ProfileQuestsPageSkeleton = () => (
  <Stack>
    <ProfileHeaderSkeleton short={true} />
    <AdventureQuestSkeleton />
  </Stack>
);
