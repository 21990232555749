import sumBy from 'lodash/sumBy';
import Box from '@mui/material/Box';
import {styled} from '@mui/material';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import {Publications} from 'api/generated/users-api';

import {getUserName} from 'modules/profile/utils';
import {ProfileImage} from 'modules/profile/components/ProfileImage';

import {useDialogView} from 'components/Dialog/context';
import {SocialButtonsGroup} from 'components/SocialButton';
import {WidgetImage} from 'components/EntityWidgets/WidgetImage';

const Content = styled(Typography)`
  height: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-clamp: 3;
  box-orient: vertical;
  word-break: break-word;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
`;

export const GroupFeedWidgetItem = ({
  item: {_id, user, filesId, content, reactions, comments, score},
}: {
  item: Publications;
}) => {
  const openPost = useDialogView('feed', _id);
  const likesCount = reactions ? sumBy(reactions.rewardValues, i => i.value) : 0;
  return (
    <Stack
      p={1.5}
      width={311}
      height={126}
      borderRadius={2}
      direction="row"
      bgcolor="white"
      flex="0 0 311px"
      overflow="hidden"
      spacing={1.5}
      onClick={openPost ? () => openPost() : undefined}
      boxShadow={theme => theme.customShadows.default}
      sx={{
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: 'secondary.main',
        },
      }}
    >
      <Box position="relative">
        {user && (
          <ProfileImage
            user={user}
            size="xsmall"
            sx={{
              position: 'absolute',
              top: -4,
              right: -4,
              zIndex: 1,
              border: '2px solid white',
              boxShadow: theme => theme.customShadows.default,
            }}
            noLink
          />
        )}
        <WidgetImage alt="Публикация" fileId={filesId?.[0]} size="small" />
      </Box>

      <Stack spacing={1.5}>
        <Stack spacing={0.5} height="100%">
          <Typography variant="subtitle1" color="grey.200">
            {user && getUserName(user)}
          </Typography>
          <Content variant="body2" height="100%">
            {content}
          </Content>
        </Stack>
        <SocialButtonsGroup likesCount={likesCount} commentsCount={comments?.count || 0} rating={score?.NPS} />
      </Stack>
    </Stack>
  );
};
