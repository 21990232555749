import {Box} from '@mui/system';
import {GoalListItem} from 'modules/goals/components';
import {GoalListCommonProps} from 'modules/goals/types';
import {InfiniteList} from 'components/InfiniteLoader';
import {GoalListSkeleton} from 'components/Skeleton';

import {Goals} from 'api/generated/users-api';
import {ReactComponent as GoalPic} from 'svg/pics/goalPic.svg';
import {useGetGoalsList} from './hook';

export const GoalsListView = ({params, onGoalClick, emptyListProps, showContentIfDeleted}: GoalListCommonProps) => {
  const {getGoalsItems, result} = useGetGoalsList(params);
  return (
    <Box sx={{backgroundColor: 'grey.10', mt: 0.25}}>
      <InfiniteList<Goals>
        limit={20}
        result={result}
        fetchFn={getGoalsItems}
        LoadingComponent={fetchItemsCount => <GoalListSkeleton limit={fetchItemsCount} />}
        emptyListProps={{
          pic: <GoalPic />,
          text: 'Целей не найдено',
          ...emptyListProps,
        }}
        spacing={0.5}
      >
        {goal => <GoalListItem goal={goal} onClick={onGoalClick} showContentIfDeleted={showContentIfDeleted} />}
      </InfiniteList>
    </Box>
  );
};
