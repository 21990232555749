import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import {ReactComponent as QuestFailIcon} from 'svg/quest/questFail.svg';
import {ReactComponent as QuestSuccessIcon} from 'svg/quest/questSuccess.svg';

export const QuestCompleteBlock: React.VFC<{status: 'completed' | 'failed'}> = ({status}) => {
  const Icon = status === 'completed' ? QuestSuccessIcon : QuestFailIcon;
  const text = status === 'completed' ? 'Квест успешно завершен!' : 'Квест завершен';
  return (
    <Stack
      p={2}
      mx={2}
      spacing={1.5}
      borderRadius={2}
      direction="row"
      alignItems="center"
      sx={{boxShadow: theme => theme.customShadows.default}}
    >
      <Box component={Icon} width={48} height={48} />
      <Box>
        <Typography variant="h3">{text}</Typography>
        {status === 'failed' && <Typography variant="body2">Не удалось выполнить все цели</Typography>}
      </Box>
    </Stack>
  );
};
