import {Card, CardContent, Stack, Paper, Typography} from '@mui/material';
import {SearchBlock, SearchTabs} from 'modules/search/components';
import {HeaderSetup} from 'components/Layout';
import {GREY} from 'components/theme/palette';

export const SearchNotFoundPageMarkup = () => {
  return (
    <>
      <HeaderSetup title="" />
      <Stack spacing={0.5}>
        <Card>
          <CardContent>
            <SearchBlock />
          </CardContent>
          <SearchTabs />
        </Card>

        <Paper elevation={0} sx={{padding: 2}}>
          <Typography variant="body2" textAlign="center" sx={{color: GREY[200]}}>
            По запросу «
            <Typography variant="body2" component="span" sx={{color: GREY[700]}}>
              xyz123
            </Typography>
            » ничего не найдено
          </Typography>
        </Paper>
      </Stack>
    </>
  );
};

export default SearchNotFoundPageMarkup;
