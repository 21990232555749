import {Link} from 'react-router-dom';
import {SxProps} from '@mui/system';
import {Theme, Stack} from '@mui/material';

import {Users} from 'api/generated/users-api';
import {getUserLink} from 'modules/profile/utils';
import {useGetCurrentUser} from 'modules/auth/hooks';

import {useOpenLightbox} from 'components/Dialog';
import {Avatar, AvatarProps} from 'components/ProfileImage';

import {UserName} from '..';

type ProfileImageProps = {
  user: Users;
  noLink?: boolean;
  sx?: SxProps<Theme>;
} & Pick<AvatarProps, 'size' | 'inline'>;

type ProfilePageImageProps = {
  user: Users;
} & Pick<AvatarProps, 'size' | 'inline'>;

export const ProfileImage = ({user, noLink = false, ...props}: ProfileImageProps) => {
  const imageProps = {type: user.gender, fileId: user.fileId, position: user.ratingPosition?.byNPS, ...props};
  const Content = <Avatar {...imageProps} />;
  if (noLink) return Content;
  return <Link to={getUserLink(user)}>{Content}</Link>;
};

export const ProfilePageImage = ({user, ...props}: ProfilePageImageProps) => {
  const openLightbox = useOpenLightbox();
  const toggleModal = () => {
    if (typeof user.fileId !== 'string') return;
    openLightbox(user.fileId || null);
  };

  const imageProps = {type: user.gender, fileId: user.fileId, position: user.ratingPosition?.byNPS, ...props};
  const Content = <Avatar {...imageProps} />;
  if (!user.fileId) return Content;
  return (
    <div onClick={toggleModal} style={{cursor: 'pointer'}}>
      {Content}
    </div>
  );
};

export const MyProfileImage = ({
  size = 'medium',
  noLink,
  withName,
  noScore,
  noRatingPosition,
}: Omit<ProfileImageProps, 'user'> & {withName?: boolean; noScore?: boolean; noRatingPosition?: boolean}) => {
  const user = useGetCurrentUser();
  if (!user) return null;
  if (!withName) return <ProfileImage user={user} size={size} noLink={noLink} />;
  return (
    <Stack direction="row" spacing={1} alignItems="center" component={Link} to={getUserLink(user)}>
      <ProfileImage user={user} size={size} noLink />
      <UserName user={user} noScore={noScore} noLink noLeaderBadge noRatingPosition={noRatingPosition} />
    </Stack>
  );
};

export default ProfileImage;
