import {Users} from 'api/generated/users-api';
import {getUserLink, getUserName} from 'modules/profile/utils';
import {ProfileName} from 'components/ProfileName';

export const UserName = ({
  user,
  noScore,
  noLink = false,
  noLeaderBadge = false,
  noRatingPosition = false,
  showPreferName = false,
}: {
  user: Users;
  noLeaderBadge?: boolean;
  noScore?: boolean;
  noRatingPosition?: boolean;
  noLink?: boolean;
  showPreferName?: boolean;
}) => {
  const teamLeader = !noLeaderBadge ? user.groups?.find(item => item.isLeader) : undefined;
  return (
    <ProfileName
      name={getUserName(user, showPreferName)}
      link={!noLink ? getUserLink(user) : undefined}
      nps={noScore ? undefined : user.stats?.NPS}
      teamLeader={teamLeader}
      ratingPosition={noRatingPosition ? undefined : user.ratingPosition?.byNPS}
    />
  );
};

export default UserName;
