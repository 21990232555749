import {Card, CardHeader, CardContent, Stack, Typography, IconButton, Box} from '@mui/material';

import {useGetUsersSymbols} from '../../hooks';
import {useReactionsAction, useReactionsContext} from '../../hooks';

type ReactionsBlockType = {
  title?: string;
};

const CountBadge = ({count}: {count: number}) => (
  <Box
    px={0.75}
    height={20}
    bottom={-2}
    minWidth={20}
    display="flex"
    borderRadius="50%"
    border="1px solid"
    alignItems="center"
    position="absolute"
    borderColor="grey.25"
    justifyContent="center"
    bgcolor="white"
  >
    <Typography component="div" variant="subtitle2">
      {count}
    </Typography>
  </Box>
);

export const ReactionsBlock = ({title}: ReactionsBlockType) => {
  const {reactionBlock, myReaction, reactions} = useReactionsContext();
  const {userRewards} = useGetUsersSymbols(reactions, reactionBlock);
  const action = useReactionsAction();

  if (!reactionBlock) return null;
  return (
    <Card sx={{pb: 1}}>
      <CardHeader title={title} titleTypographyProps={{variant: 'h3', textAlign: 'center'}} />
      <CardContent>
        <Stack
          py={1}
          px={2.5}
          spacing={2}
          borderRadius={2}
          margin="auto"
          direction="row"
          flexWrap="wrap"
          border="1px solid"
          borderColor="grey.10"
          width="fit-content"
          justifyContent="center"
        >
          {(reactionBlock?.rewards || []).map(reward => (
            <IconButton
              key={reward._id}
              onClick={() => action(reward._id as string)}
              sx={{
                position: 'relative',
                transition: 'opacity .2s, background-color .2s',
                opacity: !myReaction || myReaction?.rewardValue.rewardId === reward._id ? 1 : 0.3,
                '&:hover:not(.Mui-disabled)': {opacity: 1},
              }}
            >
              <Box height={45} width={45} fontSize={32} lineHeight="45px">
                {reward.symbol}
              </Box>
              {!!myReaction && <CountBadge count={userRewards[reward._id as string].length} />}
            </IconButton>
          ))}
        </Stack>
      </CardContent>
    </Card>
  );
};
