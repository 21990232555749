import {useContext} from 'react';
import {ReactComponent as ClaimIcon} from 'svg/icons/claimIcon.svg';

import {entitiesNames} from './utils';
import {ClaimDataProps} from './types';
import {ClaimContext} from './context';

export const useClaimMenuItem = (params: ClaimDataProps, isMe?: boolean) => {
  const openClaim = useContext(ClaimContext);
  if (!openClaim || isMe) return null;
  return {
    label: `Пожаловаться на ${entitiesNames[params.referenceName]}`,
    icon: <ClaimIcon />,
    onClick: () => openClaim(params),
  };
};
