import {Link} from 'react-router-dom';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import {GroupsUsers} from 'api/generated/users-api';
import {NPSBadge} from 'components/Layout';

import {ReactComponent as TeamLeaderIcon} from 'svg/rating/teamLeader.svg';
import {ReactComponent as RatingAvatarCrownGold} from 'svg/rating/goldCrown.svg';
import {ReactComponent as RatingAvatarCrownSilver} from 'svg/rating/silverCrown.svg';
import {ReactComponent as RatingAvatarCrownBronze} from 'svg/rating/bronzeCrown.svg';

const RatingIcon = [undefined, RatingAvatarCrownGold, RatingAvatarCrownSilver, RatingAvatarCrownBronze];

export const ProfileName = ({
  nps,
  name,
  link,
  teamLeader,
  ratingPosition,
  fontWeight = 500,
  color,
  variant = 'body1',
}: {
  name: string;
  nps?: number;
  link?: string;
  variant?: 'body1' | 'h2';
  fontWeight?: number;
  ratingPosition?: number;
  teamLeader?: GroupsUsers;
  color?: string;
}) => {
  const addProps = link ? {component: Link, to: link} : {};
  return (
    <Box alignItems="center" display="inline-flex" {...addProps} gap={0.5}>
      <Typography variant={variant} fontWeight={fontWeight} color={color}>
        {name}
      </Typography>
      {!!teamLeader && (
        <Tooltip
          arrow
          placement="bottom"
          enterTouchDelay={100}
          leaveTouchDelay={200}
          title={`Лидер команды${teamLeader.group?.name ? ` «${teamLeader.group?.name}»` : ''}`}
        >
          <Box component={TeamLeaderIcon} sx={{width: 12, height: 12}} />
        </Tooltip>
      )}
      {!!ratingPosition && ratingPosition < 4 && (
        <Tooltip
          arrow
          placement="bottom"
          enterTouchDelay={100}
          leaveTouchDelay={200}
          title={`№${ratingPosition} в рейтинге`}
        >
          <Box component={RatingIcon[ratingPosition]} sx={{width: 12, height: 12}} />
        </Tooltip>
      )}
      {!!nps && nps > 0 && <NPSBadge value={nps} />}
    </Box>
  );
};

export default ProfileName;
