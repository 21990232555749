import {Skeleton, Stack} from '@mui/material';
import {UserItemSkeleton} from '../../UserList';

export const GroupRequestSkeleton = () => (
  <Stack spacing={1} borderRadius={1} overflow="hidden">
    <Skeleton variant="text" animation="wave" width="30%" height={12} sx={{transform: 'none'}} />
    <UserItemSkeleton />
    <Stack
      direction="row"
      justifyContent="space-around"
      p={0.5}
      border="1px solid"
      borderColor="grey.10"
      borderRadius={1}
    >
      <Skeleton variant="rectangular" animation="wave" width={36} height={22} sx={{borderRadius: 9}} />
      <Skeleton variant="rectangular" animation="wave" width={36} height={22} sx={{borderRadius: 9}} />
    </Stack>
  </Stack>
);
