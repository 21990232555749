import {api} from 'api';
import {useEffect} from 'react';
import {useNavigate} from 'react-router';
import {Groups} from 'api/generated/users-api';

import ButtonMenu from 'components/ButtonMenu';
import {ReactComponent as MoreDotsIcon} from 'svg/moreDots.svg';
import {ReactComponent as LeaveGroupIcon} from 'svg/groupIcons/leaveGroup.svg';
import {useConfirmDialog} from 'components/Dialog';

export const GroupMemberMenu = ({group}: {group: Groups}) => {
  const navigate = useNavigate();
  const {data: settings} = api.endpoints.settingsIndex.useQuery();

  const [leave, {isSuccess}] = api.endpoints.groupLeaveGroup.useMutation();
  const [ConfirmLeaveDialog, onLeaveClick] = useConfirmDialog({onConfirm: () => leave({groupId: `${group._id}`})});
  useEffect(() => {
    if (isSuccess) navigate(`/teams/${group._id}`);
  }, [isSuccess, navigate, group._id]);

  return settings ? (
    <>
      {settings.groups.allowLeave ? (
        <>
          <ConfirmLeaveDialog
            title="Выйти из команды?"
            text="Если вы покинете команду, обратно вас могут и не взять."
            btnTitle="Да, выйти"
          />
          <ButtonMenu
            buttonProps={{
              icon: <MoreDotsIcon />,
              size: 'small',
              sx: {px: 0},
            }}
            items={[{label: 'Выйти из команды', icon: <LeaveGroupIcon />, onClick: onLeaveClick}]}
          />
        </>
      ) : null}
    </>
  ) : null;
};
