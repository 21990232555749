import {atom} from 'jotai';
import {reverse} from 'lodash';
import {focusAtom} from 'jotai/optics';
import {useAtomValue} from 'jotai/utils';

import {memo, useMemo} from 'react';
import {Link} from 'react-router-dom';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import {useMyId} from 'modules/auth';
import {commentsPlural} from 'modules/social/comments/utils';
import {commentsStatsAtom, scopeAtom} from 'modules/social/store';
import {CommonCommentsProps} from 'modules/social/comments/types';

import {CommentInput} from '../CommentInput';
import {CommentEntry} from '../../components';

type CommentsBlockProps = CommonCommentsProps & {
  total?: number;
  noInput?: boolean;
  moreLink?: string;
  moreClick?: () => void;
};

export const CommentsBlock = memo(
  ({noInput, moreLink = '/', referenceAuthorId, moreClick, referenceId, referenceName}: CommentsBlockProps) => {
    const isAuth = Boolean(useMyId());
    const scope = useAtomValue(scopeAtom, Symbol.for('social-keys'));
    const commentsAtom = useMemo(
      () =>
        atom(get => {
          const data = get(focusAtom(commentsStatsAtom, o => o.prop(scope)));
          if (!data) return {items: [], total: 0};
          return {items: reverse([...(data.lastComments || [])]), total: data.count || 0};
        }),
      [scope]
    );
    const {total, items} = useAtomValue(commentsAtom);

    return (
      <div>
        <Stack spacing={1}>
          {items.length > 0 && (
            <Box
              to={moreLink}
              component={Link}
              onClick={(e: React.MouseEvent) => {
                if (moreClick) {
                  e.preventDefault();
                  moreClick();
                }
              }}
              sx={{
                opacity: 1,
                display: 'block',
                transition: 'opacity .25s',
                '&:active': {
                  opacity: 0.8,
                },
              }}
            >
              <Stack spacing={0.5}>
                {items.map(item => (
                  <CommentEntry key={item._id} comment={item} />
                ))}
              </Stack>
              {total > items.length && (
                <Typography variant="body2" sx={{color: 'grey.200', mt: 1, '&:hover': {color: 'grey.400'}}}>
                  Смотреть все {commentsPlural(total)}
                </Typography>
              )}
            </Box>
          )}
          {isAuth && !noInput && (
            <CommentInput
              referenceId={referenceId}
              referenceName={referenceName}
              referenceAuthorId={referenceAuthorId}
            />
          )}
        </Stack>
      </div>
    );
  }
);

export default CommentsBlock;
