import numeral from 'numeral';
import styled from '@emotion/styled';
import {LinearProgress, Stack, Typography} from '@mui/material';

import {GoalsProgress} from 'api/generated/users-api';
import {GREY, PRIMARY} from 'components/theme/palette';
import {Box} from '@mui/system';

const DeterminateProgress = styled(LinearProgress)`
  height: 2px;
  & .MuiLinearProgress-bar1Determinate {
    background-color: ${PRIMARY[100]};
  }
  & .MuiLinearProgress-dashed {
    animation: none;
    background-image: none;
    background-color: ${GREY[10]};
  }
`;

const GreyText = styled('span')`
  color: ${GREY[200]};
`;

type GoalMoneyProgressProps = {
  progress?: GoalsProgress;
  increment?: number;
  symbol?: string;
  size?: 'small' | 'medium';
  goal?: number;
  newReward?: number;
};

export const GoalMoneyProgress = ({
  progress,
  goal,
  increment = 0,
  symbol = '₽',
  size = 'medium',
  newReward,
}: GoalMoneyProgressProps) => {
  if (!progress || !goal) return null;
  const value = (progress.percentage - increment / goal) * 100;
  const buffer = value + ((newReward ?? increment) / goal) * 100;

  return (
    <Stack gap={size === 'medium' ? 0.5 : 0} sx={{width: '100%', minWidth: 200}}>
      <DeterminateProgress
        value={value > 100 ? 100 : value}
        variant="buffer"
        valueBuffer={buffer > 100 ? 100 : buffer}
        color={value >= 100 || buffer >= 100 ? 'success' : 'primary'}
      />
      <Box display="flex" mt={0.5}>
        <Typography display="inline-flex" component="span" variant={size === 'medium' ? 'body2' : 'subtitle1'}>
          <GreyText>{numeral(newReward ? progress.total - increment : progress.total).format('0,0')}</GreyText>
          &nbsp;/&nbsp;
          {numeral(goal).format('0,0')}
          &nbsp;{symbol}
        </Typography>
      </Box>
    </Stack>
  );
};
export default GoalMoneyProgress;
