import {Stack, Collapse, Box, Skeleton} from '@mui/material';

import {api} from 'api';
import {StageIndexApiResponse} from 'api/generated/users-api';

import {IsExistsContainer} from 'components/IsExistsContainer';
import {ViewStage} from 'modules/oldQuests2/containers';

import {QuestHeader} from 'modules/oldQuests2/components';
import {useCollapseScrollBack} from 'modules/feed/containers/PublicationAttachments/hook';

const QuestReportSkeleton = () => (
  <Stack
    direction="row"
    spacing={1.5}
    sx={{borderRadius: 2, boxShadow: '0px 0px 2px rgba(16, 24, 40, 0.08), 0px 1px 2px rgba(16, 24, 40, 0.1)', p: 2}}
  >
    <Skeleton variant="rectangular" sx={{height: 48, width: 48, borderRadius: 2}} animation="wave" />
    <Stack spacing={0.5} flexGrow={1}>
      <Box>
        <Skeleton variant="text" width="30%" animation="wave" />
        <Skeleton variant="text" width="100%" animation="wave" />
      </Box>
    </Stack>
  </Stack>
);

export const QuestReportAttachment = ({entryId, expanded}: {entryId: string; expanded?: boolean}) => {
  const [ref, callback] = useCollapseScrollBack(expanded);
  return (
    <IsExistsContainer<StageIndexApiResponse>
      hideError
      useFetch={() => api.endpoints.stageIndex.useQuery({stageId: entryId})}
      LoadingComponent={<QuestReportSkeleton />}
    >
      {data => (
        <>
          <Box p={1.5} ref={ref}>
            <QuestHeader
              imageSize="small"
              imagePosition="start"
              alignItems="flex-start"
              caption="Квест завершен"
              status={data.stage.status}
              name={data.stage.quest?.name || ''}
              progress={data.stage.totalProgress}
              fileId={data.stage.quest?.design?.fileId}
            />
          </Box>
          <Collapse in={expanded} mountOnEnter unmountOnExit addEndListener={callback}>
            <Box borderTop="1px solid" borderColor="grey.10">
              <ViewStage stageId={`${data.stage._id}`} />
            </Box>
          </Collapse>
        </>
      )}
    </IsExistsContainer>
  );
};

export default QuestReportAttachment;
