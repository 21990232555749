import {omit} from 'lodash';
import {Box, IconButton, Stack, Typography} from '@mui/material';

import {QuestPointsProgressData} from 'api/generated/users-api';

import {QuestOverallProgress} from 'modules/oldQuests2/components/QuestOverallProgress';
import {UserSmallItem} from 'components/UserSmallItem';
import {ReactComponent as ArrowRight} from 'svg/icons/arrowRightIcon.svg';

import {useProgress, useUsersProgress} from './hooks';

export const GroupMembersStats = ({questPointsStats}: {questPointsStats: QuestPointsProgressData[]}) => {
  const {requiredPointsTotal, optionalPointsTotal} = useProgress(questPointsStats);
  const usersProgress = useUsersProgress(questPointsStats);

  return (
    <Stack spacing={2} pt={2}>
      {usersProgress.map(userProgress => (
        <Stack key={userProgress.user._id} spacing={0.75}>
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <UserSmallItem user={userProgress.user} />
            <Stack direction="row" alignItems="center" spacing={1}>
              <Box>
                <Typography component="span" variant="body1" fontWeight="500">
                  {userProgress.requiredPointsCompleted}
                  {!!userProgress.optionalPointsCompleted && `+${userProgress.optionalPointsCompleted}`}
                </Typography>
                <Typography component="span" variant="body2" color="grey.300">
                  {' '}
                  из {requiredPointsTotal}
                </Typography>
              </Box>
              <IconButton sx={{p: 0}}>
                <Box component={ArrowRight} width="19px" height="19px" color="grey.200" />
              </IconButton>
            </Stack>
          </Stack>
          <QuestOverallProgress
            progress={{...omit(userProgress, ['user']), requiredPointsTotal, optionalPointsTotal}}
            withAdditional={true}
          />
        </Stack>
      ))}
    </Stack>
  );
};
