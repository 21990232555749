import {Helmet} from 'react-helmet';
import {Provider} from 'react-redux';
import {SnackbarProvider} from 'notistack';
import {BrowserRouter} from 'react-router-dom';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

import {store} from 'store';
import {AppRouter} from 'router/AppRouter';
import {Lightbox} from 'components/Dialog';
import ThemeProvider from 'components/theme';

import {AuthProvider} from 'modules/auth';
import Blocker from 'modules/blocker/Blocker';
import {GoalSelector} from 'modules/goals/containers';
import {ClaimsProvider} from 'modules/claims/containers';
import {PublicationFormProvider} from 'modules/feed/containers';
import {NotificationPreviewDialog} from 'modules/notifications';
import {Agreement, PrivacyPolicy} from 'modules/auth/containers';
import {JobDialogProvider} from 'modules/tasks/containers';

function App() {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Provider store={store}>
        <Helmet defaultTitle="THE GAME" titleTemplate="THE GAME | %s" />
        <ThemeProvider>
          <BrowserRouter>
            <Blocker>
              <SnackbarProvider>
                <AuthProvider>
                  <ClaimsProvider>
                    <AppRouter />
                  </ClaimsProvider>
                  <PublicationFormProvider />
                  <JobDialogProvider />
                  <GoalSelector />
                  <PrivacyPolicy />
                  <Agreement />
                  <NotificationPreviewDialog />
                  <Lightbox />
                </AuthProvider>
              </SnackbarProvider>
            </Blocker>
          </BrowserRouter>
        </ThemeProvider>
      </Provider>
    </LocalizationProvider>
  );
}

export default App;
