import {memo} from 'react';
import {useToggle} from 'react-use';
import {shallowEqual} from 'react-redux';

import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import ButtonBase from '@mui/material/ButtonBase';
import CardContent from '@mui/material/CardContent';
import ImageListItem from '@mui/material/ImageListItem';

import {api} from 'api';
import {timeAgo} from 'utils/dates';
import {Publications} from 'api/generated/users-api';

import {useIsMe} from 'modules/auth';
import {getPostPath} from 'modules/feed/utils';
import {SocialStateProvider} from 'modules/social';
import {CommentsBlock} from 'modules/social/comments';
import {PublicationDescription} from 'modules/feed/containers';

import {UserCardHeader} from 'components/Layout';
import {SocialBlock} from 'components/SocialBlock';
import {ImageGallery, LazyImage} from 'components/ImageGallery';
import {useDialogView} from 'components/Dialog/context';
import {RemovedEntryWithRestore} from 'components/RestoreEntry';

import {PublicationMenu} from '../PublicationMenu';
import {PublicationAttachments} from '../PublicationAttachments';
import {getUserName} from 'modules/profile/utils';
import {WidgetVisualsContainer} from 'modules/widgets';

type PublicationProps = {post: Publications; showContentIfDeleted?: boolean};
type PublicationImageProps = {cols: number; rows: number} & PublicationProps;

export const PublicationImage = ({post, cols, rows}: PublicationImageProps) => {
  const openPost = useDialogView('feed', `${post._id}`);

  if (!post || !openPost || !post.filesId?.[0]) return null;
  return (
    <ImageListItem cols={cols} rows={rows} component={ButtonBase} onClick={() => openPost()}>
      {post.filesId && (
        <LazyImage
          ratio="1:1"
          width={300}
          fileId={post.filesId[0]}
          alt={`Публикация ${post.user && getUserName(post.user)}`}
        />
      )}
    </ImageListItem>
  );
};

export const Publication = memo(({post: initialData, showContentIfDeleted}: PublicationProps) => {
  const postId = `${initialData._id}`;
  const {data: updatedData} = api.endpoints.publicationIndex.useQueryState({postId});
  const post = updatedData || initialData;
  const isMy = useIsMe(post?.user);
  const openPost = useDialogView('feed', postId);
  const [expanded, toggleExpanded] = useToggle(false);
  const [expandable, toggleExpandedable] = useToggle(
    (post.attachments || []).filter(item => item.referenceName === 'Jobs').length > 0
  );

  if (post.deletedAt && !showContentIfDeleted)
    return (
      <RemovedEntryWithRestore params={{postId}} authorId={`${post.user?._id}`} mutationName="publicationSafeRestore" />
    );

  const stagesId = post?.attachments?.filter(item => item.referenceName === 'Stages').map(item => item.referenceId);
  return (
    <Card>
      {showContentIfDeleted && (
        <RemovedEntryWithRestore
          isRestored={!Boolean(post.deletedAt)}
          params={{postId}}
          authorId={`${post.user?._id}`}
          mutationName="publicationSafeRestore"
        />
      )}
      {post.user && (
        <UserCardHeader
          user={post.user}
          subTitle={`${timeAgo(post.createdAt)}`}
          action={
            <PublicationMenu
              entryId={`${post._id}`}
              isMy={isMy}
              jobId={post.attachments?.find(item => item.referenceName === 'Jobs')?.referenceId}
            />
          }
        />
      )}
      {post.filesId && <ImageGallery filesId={post.filesId} ratio={post.filesAspectRatio} width={1200} />}

      <SocialStateProvider
        score={post.score}
        comments={post.comments}
        reactions={post.reactions}
        scope={`Publications.${post._id}`}
      >
        <CardContent>
          <Stack spacing={2}>
            {post.filesId && post.filesId?.length > 0 && (
              <SocialBlock
                commentsCount={post.comments?.count}
                referenceId={postId}
                referenceName="Publications"
                commentButtonTo={{pathname: getPostPath(postId), hash: 'comments'}}
              />
            )}
            <Stack
              spacing={2}
              onClick={expandable ? () => toggleExpanded(true) : undefined}
              sx={{cursor: !expandable || expanded ? undefined : 'pointer'}}
            >
              <PublicationDescription
                truncate={!expanded}
                content={post.content || ''}
                toggleExpandable={() => toggleExpandedable(true)}
              />
              {stagesId && stagesId.length > 0 && (
                <WidgetVisualsContainer widgetContainerName="PostWidgetContainer" stagesId={stagesId} />
              )}
              <PublicationAttachments data={post.attachments} />
              {!post.filesId?.length && (
                <SocialBlock
                  commentsCount={post.comments?.count}
                  referenceId={postId}
                  referenceName="Publications"
                  commentButtonTo={{pathname: getPostPath(postId), hash: 'comments'}}
                />
              )}
            </Stack>
            <CommentsBlock
              referenceName="Publications"
              referenceId={postId}
              moreClick={openPost}
              moreLink={getPostPath(post._id)}
              referenceAuthorId={post.userId}
            />
          </Stack>
        </CardContent>
      </SocialStateProvider>
    </Card>
  );
});

export default memo(Publication, (n, p) => {
  return (
    p.post._id === n.post._id &&
    n.post.enabled === p.post.enabled &&
    n.post.content === p.post.content &&
    shallowEqual(n.post.filesId, p.post.filesId) &&
    n.post.filesAspectRatio === p.post.filesAspectRatio
  );
});
