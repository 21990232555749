import {skipToken} from '@reduxjs/toolkit/dist/query';
import {Typography} from '@mui/material';

import {api} from 'api';
import {GroupsRequests} from 'api/generated/users-api';

import {useInviteRequestActions} from 'modules/groups/hooks';

import {VotePanel} from 'components/VotePanel';

type GroupRequestVotePanelProps = {
  request: GroupsRequests;
};

export const GroupRequestVotePanel = ({request}: GroupRequestVotePanelProps) => {
  const {data: groupData} = api.endpoints.groupIndex.useQuery(
    request.group?._id ? {groupId: request.group?._id} : skipToken
  );
  const {approveAction, declineAction} = useInviteRequestActions(groupData?.isLeader);
  if (!groupData || !request._id) return null;

  const {isLeader} = groupData;
  const makeContent = (text: string, count: number) => {
    if (!isLeader) return;
    return (
      <Typography component="span" variant="body2" sx={{whiteSpace: 'nowrap', color: 'grey.200'}}>
        {text}{' '}
        <Typography component="span" variant="body2" sx={{color: 'grey.700'}}>
          {count}
        </Typography>
      </Typography>
    );
  };

  return (
    <VotePanel
      approvedUsersId={request.approvedUsersId}
      declinedUsersId={request.declinedUsersId}
      approveContent={makeContent('Принять', request.approvedUsersId.length)}
      declineContent={makeContent('Отклонить', request.declinedUsersId.length)}
      approveAction={e => {
        e.stopPropagation();
        approveAction(request._id as string);
      }}
      declineAction={e => {
        e.stopPropagation();
        declineAction(request._id as string);
      }}
    />
  );
};
