import {Box, CardContent, Stack, Typography} from '@mui/material';

import {api} from 'api';
import {useSafeContext} from 'utils';

import {GroupContext} from 'modules/groups/context';
import {CardLinkHeader} from 'components/CardLinkHeader';
import {ReactComponent as GoalIcon} from 'svg/quest/goalIcon.svg';

export const GroupGoalsWidget = () => {
  const {groupId} = useSafeContext(GroupContext);
  const {data} = api.endpoints.goalsIndex.useQuery({sort: 'finish_date', groupId: [groupId], limit: 1});

  // TODO: сделать скелетон на загрузку
  if (!data) return <></>;
  const completedGoalsCount = data.completed;
  const totalGoalsCount = data.total;

  return (
    <Box
      flex={1}
      borderRadius={2}
      display="flex"
      bgcolor="white"
      flexDirection="column"
      justifyContent="space-between"
      boxShadow={theme => theme.customShadows.default}
    >
      <CardLinkHeader title="Выполнено целей десяткой" to="./goals" color="grey.300" fontWeight={400} />
      <CardContent>
        <Stack direction="row" alignItems="center" spacing={1}>
          <Box
            component={GoalIcon}
            borderRadius="50%"
            bgcolor="primary.400"
            p={0.25}
            height={24}
            width={24}
            fill="white"
          />
          <Typography variant="h1">
            {completedGoalsCount} из {totalGoalsCount}
          </Typography>
        </Stack>
      </CardContent>
    </Box>
  );
};
