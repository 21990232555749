import Box from '@mui/system/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import {tabClasses} from '@mui/material';
import {PropsWithChildren} from 'react';

import {Provider, useAtom} from 'jotai';
import {useAtomValue} from 'jotai/utils';

import {useIsMobile} from 'utils';
import {StatusIcon} from 'components/StatusIcon';
import {hashStore, currentHashAtom} from './atom';

type HashNavigationContainerProps = PropsWithChildren<{}>;

const HashNavigation = ({children}: HashNavigationContainerProps) => {
  const isMobile = useIsMobile();
  const tabs = useAtomValue(hashStore, Symbol.for('hash-block'));
  const [currentHash, setHash] = useAtom(currentHashAtom, Symbol.for('hash-block'));

  return (
    <div>
      <Box sx={{position: 'sticky', top: isMobile ? 64 : 0, zIndex: '500'}} width={1}>
        <Tabs
          value={tabs.find(item => item.hash === currentHash) ? currentHash : false}
          variant="scrollable"
          scrollButtons={false}
          allowScrollButtonsMobile
          sx={{backgroundColor: 'white'}}
          onChange={(_, newHash: string) => setHash(newHash)}
        >
          {tabs.map(item => (
            <Tab
              key={item.hash}
              label={item.label}
              value={item.hash}
              disabled={item.locked}
              onClick={() => {
                setTimeout(() => item.scrollToRef && item.scrollToRef(), 300);
              }}
              iconPosition="end"
              icon={
                item.locked || item.completed ? (
                  <StatusIcon status={item.locked ? 'locked' : 'completed'} sx={{ml: 0.5}} />
                ) : undefined
              }
              sx={{
                [`&.${tabClasses.disabled}`]: {
                  color: 'grey.100',
                },
              }}
            />
          ))}
        </Tabs>
      </Box>
      {children}
    </div>
  );
};

export const HashNavigationContainer = (props: HashNavigationContainerProps) => {
  return (
    <Provider
      initialValues={[
        [hashStore, []],
        [currentHashAtom, undefined],
      ]}
      scope={Symbol.for('hash-block')}
    >
      <HashNavigation {...props} />
    </Provider>
  );
};
