import {Outlet} from 'react-router';
import {PropsWithChildren, useCallback} from 'react';
import {StringParam, useQueryParams, QueryParamConfig} from 'use-query-params';

import {GoalDialog} from 'modules/goals/pages/GoalPage';
import {PublicationDialog} from 'modules/feed/pages/Publication';
import {TaskCardDialog} from 'modules/tasks/pages/TaskCardDialog';
import {ContentDialog} from 'modules/contents/pages/ContentPage/ContentPageDialog';

import {useDialog} from '.';
import {DialogViewTypes} from './types';
import {DialogViewContext} from './context';

const titles = {
  feed: '',
  task: '',
  goals: '',
  contents: '',
  job: 'Задание',
};

export const DialogViewProvider = ({children}: PropsWithChildren<{}>) => {
  const [query, setQuery] = useQueryParams({
    view: StringParam as QueryParamConfig<DialogViewTypes | null>,
    entryId: StringParam,
  });

  const openView = useCallback(
    (view: DialogViewTypes, entryId: string) => {
      setQuery({view, entryId}, 'replace');
      window.history.replaceState(null, 'Запись', `/${view}/${entryId}`);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [query.entryId, query.view]
  );
  const [Dialog] = useDialog();
  const onClose = () => setQuery({view: undefined, entryId: undefined});
  const isOpen = Boolean(query.view) && Boolean(query.entryId);

  return (
    <DialogViewContext.Provider value={openView}>
      <Dialog title={query.view ? titles[query.view] : ''} open={isOpen} onClose={onClose}>
        {query.view === 'goals' && <GoalDialog entryId={`${query.entryId}`} />}
        {query.view === 'task' && <TaskCardDialog entryId={`${query.entryId}`} />}
        {query.view === 'feed' && <PublicationDialog entryId={`${query.entryId}`} />}
        {query.view === 'contents' && <ContentDialog entryId={`${query.entryId}`} />}
      </Dialog>
      {children || <Outlet />}
    </DialogViewContext.Provider>
  );
};
