import {pick} from 'lodash';
import {useRef} from 'react';
import {FormikHelpers} from 'formik';
import {useUnmount} from 'react-use';
import {useSnackbar} from 'notistack';
import {useDispatch} from 'react-redux';
import {useNavigate} from 'react-router';
import {skipToken} from '@reduxjs/toolkit/dist/query';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import CardContent from '@mui/material/CardContent';

import {api} from 'api';
import {PublicationDto, Publications} from 'api/generated/users-api';

import {Form} from 'components/FormFactory';
import {useConfirmDialog, useDialog} from 'components/Dialog';
import {IsExistsContainer} from 'components/IsExistsContainer';

import {useMyId} from 'modules/auth';
import useTypedSelector from 'store/hooks';
import {closePostForm} from 'store/uiModule';
import {getPostPath} from 'modules/feed/utils';

import {fields, initialValues, validationSchema} from './fields';
import {StageIdProp, StagePointIdProp} from 'modules/quests/types';
import {useExternalSetPointReference} from 'modules/quests/hooks/setReference.hook';

const PublicationForm = ({
  data: post,
  onClose,
  onPostCreated,
  stageId,
  stagePointId,
}: {
  data?: Publications;
  onClose: () => void;
  onPostCreated?: (entryId: string) => void;
} & Partial<StagePointIdProp & StageIdProp>) => {
  const navigate = useNavigate();
  const {enqueueSnackbar, closeSnackbar} = useSnackbar();
  const [update] = api.endpoints.publicationUpdate.useMutation();
  const [create, {data}] = api.endpoints.publicationsAdd.useMutation();
  const lastMutation = useRef<ReturnType<typeof create | typeof update>>();
  const {setReference} = useExternalSetPointReference(stageId && stagePointId ? {stageId, stagePointId} : undefined);

  const [SuccessActions, openSuccessActions] = useConfirmDialog<Publications>({
    onCancel: () => onClose(),
    onConfirm: post => {
      navigate(getPostPath(data ? data._id : post?._id));
      onClose();
    },
    title: 'Готово',
    align: 'center',
    btnType: 'primary',
    cancelBtnTitle: 'Закрыть',
    text: 'Пост успешно создан',
    btnTitle: 'Перейти к посту',
  });

  const onSubmit = async (input: PublicationDto, {setSubmitting}: FormikHelpers<any>) => {
    if (post) lastMutation.current = update({postId: `${post._id}`, addPublicationDto: {publication: input}});
    else lastMutation.current = create({addPublicationDto: {publication: input}});
    const result = await lastMutation.current;
    setSubmitting(false);
    if ('data' in result) {
      if (setReference) setReference(result.data._id);
      if (onPostCreated) onPostCreated(`${result.data._id}`);
      else openSuccessActions(result.data);
    } else if ('error' in result) {
      const key = enqueueSnackbar((result.error as any)?.data?.message || 'Произошла ошибка при сохранении даных', {
        variant: 'error',
        persist: false,
      });
      setTimeout(() => closeSnackbar(key), 2000);
    }
  };
  useUnmount(() => {
    lastMutation.current?.unsubscribe();
  });

  return (
    <>
      <SuccessActions />
      <Card sx={{pb: 1, minHeight: '100%'}}>
        <CardContent>
          <Form
            fields={fields}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
            initialValues={post ? (pick(post, Object.keys(initialValues)) as PublicationDto) : initialValues}
          >
            {({isValid, isSubmitting, dirty}) => (
              <Box
                sx={{
                  py: 1,
                  width: '100%',
                  backgroundColor: '#fff',
                }}
              >
                <Button
                  type="submit"
                  color="primary"
                  fullWidth
                  variant="contained"
                  disabled={!dirty || !isValid || isSubmitting}
                >
                  {!post ? `Отправить пост` : 'Сохранить изменения'}
                </Button>
              </Box>
            )}
          </Form>
        </CardContent>
      </Card>
    </>
  );
};

export const PublicationFormDialog = ({
  entryId,
  onClose,
  onPostCreated,
  stageId,
  stagePointId,
}: {
  entryId?: string;
  onClose: () => void;
  onPostCreated?: (entryId: string) => void;
} & Partial<StagePointIdProp & StageIdProp>) => {
  return (
    <IsExistsContainer
      hideError
      passUninitialized
      useFetch={() =>
        api.endpoints.publicationIndex.useQuery(entryId ? {postId: entryId} : skipToken, {
          refetchOnMountOrArgChange: true,
        })
      }
    >
      {(data?: Publications) => (
        <PublicationForm
          data={data}
          onClose={onClose}
          stageId={stageId}
          stagePointId={stagePointId}
          onPostCreated={onPostCreated}
        />
      )}
    </IsExistsContainer>
  );
};

export const PublicationFormProvider = () => {
  const dispatch = useDispatch();
  const isAuth = Boolean(useMyId());
  const onClose = () => dispatch(closePostForm());
  const [Dialog] = useDialog();
  const {open, ...props} = useTypedSelector(
    state => state.uiForms.post,
    (n, p) => n.entryId === p.entryId && n.open === p.open
  );

  if (!isAuth) return null;
  return (
    <Dialog open={open} onClose={onClose}>
      <PublicationFormDialog {...props} onClose={onClose} />
    </Dialog>
  );
};
