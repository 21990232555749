import {api} from 'api';
import {useState} from 'react';
import {Provider} from 'jotai';
import {useUpdateEffect} from 'react-use';

import Collapse from '@mui/material/Collapse';
import Typography from '@mui/material/Typography';
import {skipToken} from '@reduxjs/toolkit/dist/query';

import {getLockedReason} from 'modules/oldQuests2/utils';
import {SubQuestPointView} from 'modules/oldQuests2/components';
import {StagePointWithChildsProps} from 'modules/oldQuests2/types';

import {QuestPointContent} from '.';
import {isSubQuestPointAtom, rootStageIdAtom, stageStatusAtom} from '../Quest/atom';
import {useAcceptSubQuestAndSetReference} from './hook';

export const SubQuestPoint = ({
  points = [],
  children,
  stagePoint,
  lockedReason,
  questPoint: point,
  collapsedAll,
}: StagePointWithChildsProps) => {
  const [collapsed, setCollapsed] = useState(collapsedAll ?? true);
  useUpdateEffect(() => {
    if (collapsedAll != null) setCollapsed(collapsedAll);
  }, [collapsedAll]);
  const lockedReasons = getLockedReason(lockedReason);
  useAcceptSubQuestAndSetReference({stagePoint, questPointData: point});
  const {localProgress, status, isLoading} = api.endpoints.stageIndex.useQuery(
    stagePoint?.referenceId ? {stageId: stagePoint?.referenceId} : skipToken,
    {
      selectFromResult: ({data, isLoading}) => ({
        isLoading,
        status: data?.stage.status,
        localProgress: data?.stage.localProgress,
      }),
    }
  );
  const {data: updated} = api.endpoints.stagePointsIndex.useQueryState(
    stagePoint?.referenceId ? {stageId: stagePoint?.referenceId} : skipToken
  );
  const subPoints = points.length || updated?.length ? updated || points : point.points || [];

  return (
    <>
      {children({collapsed, onClick: () => setCollapsed(!collapsed), progress: localProgress})}
      {subPoints.length > 0 && (
        <Provider initialValues={[[rootStageIdAtom, stagePoint?.referenceId]]} scope={Symbol.for('parent-stage')}>
          <Collapse in={collapsed} timeout={150}>
            {!point.timelineCheck.available && (
              <Typography variant="subtitle1" color="grey.200" mb={1} px={2}>
                {lockedReasons || 'Недостуно'}
              </Typography>
            )}
            {!isLoading &&
              subPoints.map(item => (
                <Provider
                  initialValues={[
                    [stageStatusAtom, status],
                    [isSubQuestPointAtom, true],
                  ]}
                  key={'questPointData' in item ? item.questPointData.questPoint._id : item.questPoint._id}
                >
                  <QuestPointContent
                    {...('questPointData' in item ? item : {questPointData: item})}
                    Component={SubQuestPointView}
                    lockedReason={point.timelineCheck.reasons}
                  />
                </Provider>
              ))}
          </Collapse>
        </Provider>
      )}
    </>
  );
};
