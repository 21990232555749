import {Box} from '@mui/material';
import {FunctionComponent, PropsWithChildren} from 'react';

export const FieldContainer: FunctionComponent<PropsWithChildren<{}>> = ({children}) => (
  <Box width="100%" my={2} sx={{position: 'relative', '&:first-of-type': {marginTop: 0}}}>
    {children}
  </Box>
);

export default FieldContainer;
