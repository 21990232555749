import {useEffect, useRef, useState} from 'react';
import {useNavigate} from 'react-router';
import {useLocation} from 'react-router-dom';

import IconButton from '@mui/material/IconButton';
import Box from '@mui/system/Box';

import {useMyProfileInfo} from 'modules/auth';

import {ReactComponent as BackButton} from '../../../svg/icons/chevronLeftIcon.svg';
import BottomNavigationConfig from '../BottomNavigation/BottomNavigationConfig';

type BacklinkProps = {
  backLink?: string | (() => void);
};

const additionalMainLinks = ['/tasks'];
const exceptionsLinks = ['/discover/rating/groups', '/discover/rating/users'];

export const Backlink = ({backLink}: BacklinkProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [locationHistory, setLocationHistory] = useState<string[]>([location.pathname]);
  const pathname = useRef<string>(location.pathname);
  const {link} = useMyProfileInfo();
  const mainRoutes = [...BottomNavigationConfig({}).map(i => i.to), ...additionalMainLinks, link];

  useEffect(() => {
    if (pathname.current !== location.pathname) {
      if (exceptionsLinks.includes(location.pathname) && exceptionsLinks.includes(pathname.current)) {
        setLocationHistory(p => [...p.slice(0, p.length - 1), location.pathname]);
      } else if (mainRoutes.includes(location.pathname)) {
        setLocationHistory([location.pathname]);
      } else {
        setLocationHistory(prev => [...prev, location.pathname]);
      }
      pathname.current = location.pathname;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const goBack = () => {
    if (locationHistory.length > 1) {
      navigate(locationHistory[locationHistory.length - 2]);
      setLocationHistory(prev => prev.slice(0, locationHistory.length - 2));
    } else if (backLink != null) {
      setLocationHistory([]);
      return typeof backLink === 'string' ? navigate(backLink) : backLink();
    }
  };

  if (locationHistory.length < 2 && backLink == null) return null;

  return (
    <IconButton
      size="small"
      onClick={goBack}
      sx={{'&:hover': {backgroundColor: 'transparent', '& > *': {color: 'primary.light'}}}}
    >
      <Box component={BackButton} sx={{color: 'primary.main', transition: 'color 0.25s'}} />
    </IconButton>
  );
};
