import {api} from 'api';
import {useCallback} from 'react';

import {useErrorShackbar} from 'utils';

import {QuestPointProps} from '../types';
import {useSetPointReference} from './setReference.hook';
import {useJobDialog} from 'modules/tasks/containers';

export const useTaskPointPress = (props: QuestPointProps) => {
  const {stagePointId} = props;
  const openErrorSnackbar = useErrorShackbar();
  const openJob = useJobDialog();

  const {
    canPress,
    stagePoint,
    setReference,
    referenceProccessing,
    questPoint: {originId: taskId, timelineId, type},
  } = useSetPointReference(props);

  const [accept, {isLoading: taskAccepting}] = api.endpoints.taskAccept.useMutation();
  const [makeEditable, {isLoading: jobBecomingEditable}] = api.endpoints.jobMakeEditable.useMutation();

  const navigateToTask = async () => {
    if (stagePoint?.referenceId) {
      await makeEditable({jobId: stagePoint.referenceId});
      openJob({stageId: props.stageId, jobId: stagePoint.referenceId});
    }
  };

  const setTaskReference = useCallback(async () => {
    if (setReference && taskId && timelineId) {
      const result = await accept({taskId, taskActionDto: {timelineId, stagePointId}});
      if ('data' in result) {
        await setReference(`${result.data._id}`);
        openJob({stageId: props.stageId, jobId: result.data._id as string});
      } else if ('error' in result) openErrorSnackbar(result.error, 'Не получилось взять задачу в работу');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskId, setReference, stagePointId, timelineId, accept, stagePoint?.stageId]);

  return {
    canPress: type === 'task' && canPress,
    isLoading: referenceProccessing || taskAccepting || jobBecomingEditable,
    onPress: stagePoint?.referenceId ? navigateToTask : setTaskReference,
  };
};
