import Box from '@mui/system/Box';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import {api} from 'api';
import {QuestPreview} from './QuestPreview';

export const QuestPreviews = () => {
  const {data} = api.endpoints.questsIndex.useQuery(
    {type: 'adventure'},
    {selectFromResult: ({data, ...rest}) => ({...rest, data: data?.data || []})}
  );

  if (!data.length) return null;
  return (
    <Box
      sx={{
        overflow: 'hidden',
        '& .slick-dots': {
          bottom: 12,
          li: {
            m: 0,
            '& button:before': {color: 'white'},
            '&.slick-active button:before': {color: 'white'},
          },
        },
      }}
    >
      <Slider infinite={false} slidesToShow={1} slidesToScroll={1} dots={true}>
        {data.map(item => (
          <QuestPreview quest={item} key={item._id} />
        ))}
      </Slider>
    </Box>
  );
};
