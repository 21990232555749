import {Skeleton, Stack} from '@mui/material';
import {QuestPointContentSkeleton} from './QuestPointContentSkeleton';

export const QuestPointItemSkeleton = () => (
  <Stack bgcolor="white">
    <Stack direction="row" alignItems="center" spacing={1} mb={2}>
      <Skeleton variant="circular" width={24} height={24} />
      <Skeleton variant="text" animation="wave" width="75%" height={16} sx={{transform: 'none'}} />
    </Stack>
    <QuestPointContentSkeleton />
  </Stack>
);
