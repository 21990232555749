import isNull from 'lodash/isNull';
import {api} from 'api';
import {GoalIdProp} from 'modules/goals/types';
import {SocialButtonsGroup} from 'components/SocialButton';
import {EntityStatusProps} from 'components/EntityWidgets/types';
import {EntityPreview, WidgetBox} from 'components/EntityWidgets';
import {Box} from '@mui/material';

export const GoalWidget = ({
  goalId,
  status,
  showBox = true,
  hideImage = false,
}: GoalIdProp & {status?: EntityStatusProps['status']; hideImage?: boolean; showBox?: boolean}) => {
  const {data: goal, isLoading, isError, error} = api.endpoints.goalIndex.useQuery({goalId});
  if (isLoading) return null;
  if (isError && (error as any)?.status === 403) return <>Цель скрыта</>;
  if (!goal) return null;

  const isPrivate = goal && isNull(goal?.name);
  const Content = (
    <EntityPreview
      caption="Цель"
      title={isPrivate ? 'Цель скрыта' : goal?.name || ''}
      statusProps={status ? {status} : undefined}
      imageProps={!hideImage ? {fileId: goal?.filesId[0], alt: `Цель ${goal?.name}`, size: 'small'} : undefined}
    >
      <Box mt={1}>
        <SocialButtonsGroup
          rating={goal?.score?.average}
          likesCount={goal?.likes?.count}
          commentsCount={goal?.comments?.count}
        />
      </Box>
    </EntityPreview>
  );

  if (!showBox) return Content;
  return <WidgetBox>{Content}</WidgetBox>;
};
