import {Skeleton, Stack} from '@mui/material';

export const GoalItemSkeleton = () => (
  <Stack direction="row" p={2} spacing={1.5} bgcolor="white">
    <Skeleton variant="circular" width={48} height={48} />
    <Stack spacing={1} flex="1 1 auto">
      <Skeleton variant="text" animation="wave" width="50%" height={18} sx={{transform: 'none'}} />
      <Skeleton variant="text" animation="wave" width="100%" height={48} sx={{transform: 'none'}} />
      <Skeleton variant="rectangular" animation="wave" width={80} height={18} sx={{borderRadius: 12}} />
    </Stack>
  </Stack>
);
