import keyBy from 'lodash/keyBy';
import pick from 'lodash/fp/pick';
import sortBy from 'lodash/sortBy';
import groupBy from 'lodash/groupBy';
import flatten from 'lodash/flatten';

import React, {FC, memo} from 'react';
import {skipToken} from '@reduxjs/toolkit/dist/query';
import {WidgetsVisualsDesigns} from 'api/generated/users-api';

import {api} from 'api';

import {visualComponents} from '../../components';
import {getWidgetVisualDesignOptions} from '../../utils';
import {WidgetVisualComponentProps, WidgetVisualsDesignCustomOptions} from '../../types';
import {ContainerComponent} from '../../components/VisualComponents/ContainerComponent';

type VisualComponentData = WidgetsVisualsDesigns & {
  options: WidgetVisualsDesignCustomOptions;
  containerId: string;
  widgetAttrId?: string;
};

export const WidgetVisual: FC<WidgetVisualComponentProps> = memo(
  ({visualId, stagesId, usersId}: WidgetVisualComponentProps) => {
    const {props, widgetsId, components} = api.endpoints.widgetsVisualsIndex.useQuery(
      {stagesId, _id: [visualId], populates: ['designs', 'props']},
      {
        selectFromResult: ({data = []}) => {
          const visual = data[0];
          const props = visual?.props?.map(pick(['widgetAttrId', '_id'])) || [];
          const propsById = keyBy(props, '_id');

          const components = sortBy(
            (visual?.designs || []).map(item => {
              const options = getWidgetVisualDesignOptions(item);
              return {
                ...item,
                options,
                containerId: options.containerId || 'root',
                widgetAttrId: item?.visualPropId ? propsById[item?.visualPropId].widgetAttrId : undefined,
              } as VisualComponentData;
            }),
            'positionNumber'
          );

          return {
            props,
            widgetsId: visual?.widgetsId || [],
            components: groupBy(components, 'containerId'),
          };
        },
      }
    );

    const {widgetsData} = api.endpoints.widgetsInfo.useQuery(
      widgetsId.length ? {_id: widgetsId, stagesId, usersId} : skipToken,
      {
        selectFromResult: ({data = []}) => ({
          widgetsData: data
            .map(item => {
              const attrs = pick(
                props.map(item => item.widgetAttrId),
                keyBy(item.attrs, '_id')
              );
              const [values] = item.values
                .filter(item => !stagesId || stagesId?.includes(item.stageId))
                .map(item =>
                  groupBy(
                    flatten(
                      item.values
                        .filter(valueItem => !!attrs[valueItem.widgetAttrId])
                        .map(value =>
                          value.values.map(valueItem => ({
                            type: value.type,
                            value: valueItem.value,
                            widgetAttrId: value.widgetAttrId,
                            name: attrs[value.widgetAttrId].name,
                            positionNumber: attrs[value.widgetAttrId].positionNumber,
                          }))
                        )
                    ),
                    'widgetAttrId'
                  )
                );
              return {attrs, values};
            })
            .reduce((acc, item) => ({attrs: {...acc.attrs, ...item.attrs}, values: {...acc.values, ...item.values}}), {
              attrs: {},
              values: {},
            }),
        }),
      }
    );

    const renderComponents = (components: VisualComponentData[]) => (
      <>
        {components.map(component => {
          if (component.options.elementType === 'container') return renderContainer(component);
          const Component = visualComponents[component.options.elementType];
          if (!Component) return null;

          const attrId = component.widgetAttrId;
          const widgetData = attrId ? widgetsData.attrs[attrId] : undefined;
          const widgetValue = attrId ? widgetsData.values[attrId] : undefined;
          let Content = null;

          if (widgetValue) {
            Content = widgetValue?.map(valueItem => (
              <Component
                name={widgetData?.name}
                type={valueItem.type}
                value={valueItem.value || component.options.value}
                key={`${valueItem.widgetAttrId}.${valueItem.value}`}
                options={component.options[`${component.options.elementType}Options` as const] as any}
              />
            ));
          } else if (component.options.value) {
            Content = (
              <Component
                name={widgetData?.name}
                value={component.options.value}
                options={component.options[`${component.options.elementType}Options` as const] as any}
              />
            );
          }

          if (!component.options.containerOptions)
            return <React.Fragment key={component._id}>{Content}</React.Fragment>;
          return (
            <ContainerComponent options={component.options.containerOptions} key={component._id}>
              {Content}
            </ContainerComponent>
          );
        })}
      </>
    );

    const renderContainer = (data: VisualComponentData) => {
      if (data.options.elementType !== 'container') return null;
      if (!data.options.containerOptions?.id || !components[data.options.containerOptions?.id]) return null;
      return (
        <ContainerComponent key={data._id} options={data.options.containerOptions}>
          {renderComponents(components[data.options.containerOptions?.id] || [])}
        </ContainerComponent>
      );
    };

    return <>{renderComponents(components.root || [])}</>;
  },
  (prev, next) => prev.visualId !== next.visualId
);
