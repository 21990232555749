import {TextField} from 'formik-mui';
import {Form, FormikProvider, useFormik} from 'formik';
import {Button, Card, CardContent, FormControlLabel, Stack, Switch} from '@mui/material';

import {ReactionsBlock} from 'modules/social/reactions';
import {NotionContent} from 'modules/contents/containers';
import {PublicationPreview} from 'modules/feed/containers/PublicationPreview';

import {PrivacyField} from 'components/FormFactory';
import {PhotoField} from 'components/FormFactory/Field/PhotoField';
import {StyledFastField} from 'components/FormFactory/Field/styled';

import {useCompleteTask} from './hook';
import {validationSchema} from './utils';
import {TaskFinalStepProps} from './types';

export const TaskCompletion = ({
  jobId,
  onEdit,
  postId,
  status,
  stageId,
  publicationRequired,
  lastStepNotionPageId,
  publicationPlaceholder,
}: TaskFinalStepProps) => {
  const {onSubmit, taskInitials} = useCompleteTask({
    jobId,
    postId,
    status,
    publicationRequired,
    stageId,
  });
  const formik = useFormik({
    onSubmit,
    enableReinitialize: true,
    initialValues: taskInitials,
    validationSchema: validationSchema(publicationRequired),
  });
  const {values, getFieldProps, isValid, isSubmitting} = formik;

  if (status === 'completed') {
    if (!postId) return null;
    return (
      <>
        <PublicationPreview postId={postId} />
        {onEdit && (
          <Card sx={{p: 2}}>
            <ReactionsBlock title="Оцените задание" />
            <Button
              fullWidth
              variant="contained"
              onClick={onEdit || formik.submitForm}
              disabled={!onEdit && (!isValid || isSubmitting)}
            >
              {'Редактировать'}
            </Button>
          </Card>
        )}
      </>
    );
  }
  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off">
        {lastStepNotionPageId && (
          <Card>
            <CardContent>
              <NotionContent notionPageId={lastStepNotionPageId} />
            </CardContent>
          </Card>
        )}
        <Card>
          <CardContent>
            <Stack spacing={2}>
              {/*{!publicationRequired && (*/}
              {/*  <FormControlLabel*/}
              {/*    labelPlacement="start"*/}
              {/*    label="Написать пост с этим отчетом"*/}
              {/*    sx={{mx: 0, width: '100%', justifyContent: 'space-between'}}*/}
              {/*    control={<Switch {...getFieldProps('createPublication')} checked={values.createPublication} />}*/}
              {/*  />*/}
              {/*)}*/}
              {(publicationRequired || values.createPublication) && (
                <>
                  <PhotoField
                    multiple
                    type="photo"
                    label="Картинка"
                    name="publication.filesId"
                    ratioName="publication.filesAspectRatio"
                  />
                  <StyledFastField
                    multiline
                    fullWidth
                    minRows={2}
                    maxRows={20}
                    size="medium"
                    type="textarea"
                    variant="standard"
                    name="publication.content"
                    component={TextField}
                    InputLabelProps={{shrink: true}}
                    placeholder={publicationPlaceholder}
                  />
                  <PrivacyField name="publication.privacy" />
                </>
              )}
            </Stack>
          </CardContent>
        </Card>
        {/* {!publicationId && (
          <Card>
            <CardContent>
              <Stack spacing={1}>
                <HowDoesItGo title="Оцените задание" />
                <RatingBarWithLabel
                  size="medium"
                  label="Оцените это задание"
                  value={values.scoreComment?.score}
                  onChange={score => setFieldValue('scoreComment.score', score, true)}
                />
                {values.scoreComment?.score && (
                  <StyledFastField
                    component={TextField}
                    type="textarea"
                    name="scoreComment.comment"
                    variant="standard"
                    placeholder="Расскажите почему"
                    multiline
                    minRows={4}
                  />
                )}
              </Stack>
            </CardContent>
          </Card>
        )} */}

        <ReactionsBlock title="Оцените задание" />

        <Card sx={{p: 2}}>
          <Button fullWidth variant="contained" onClick={formik.submitForm} disabled={!isValid || isSubmitting}>
            Завершить выполнение
          </Button>
        </Card>
      </Form>
    </FormikProvider>
  );
};

export default TaskCompletion;
