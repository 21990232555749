import {memo} from 'react';
import {Field} from 'formik';
import {isFunction} from 'lodash';
import {RadioGroup} from 'formik-mui';
import {FormControlLabel, Radio} from '@mui/material';

import {SelectFieldProps, SelectOption} from '../types';

type FieldProps = {name: string; type: string} & SelectFieldProps['inputProps'];

const CommonField = ({options, name}: FieldProps & {options: SelectOption[]}) => (
  <Field component={RadioGroup} name={name}>
    {options.map(item => (
      <FormControlLabel value={item.value} key={item.value} label={item.name} control={<Radio />} />
    ))}
  </Field>
);

const HookField = ({options: useOptions, ...props}: FieldProps & {options: () => SelectOption[] | undefined}) => {
  const options = useOptions();
  if (!options) return null;
  return <CommonField {...props} options={options} />;
};

export const RadioField = (props: FieldProps) => {
  if (!props.options) return null;
  if (isFunction(props.options)) return <HookField {...(props as any)} />;
  return <CommonField {...(props as any)} />;
};

export default memo(RadioField);
