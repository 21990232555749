import {Box, Button, Tab} from '@mui/material';
import {slugify} from 'transliteration';
import {TabContext, TabList, TabPanel} from '@mui/lab';

import _ from 'lodash/fp';
import {useQueryParam, StringParam} from 'use-query-params';

import {api} from 'api';
import {WidgetsIndexApiArg} from 'api/generated/users-api';
import {JobAnswers, TaskWidget} from 'modules/tasks/containers';
import {useIsMe} from 'modules/auth';
import {EmptyList} from 'components/EmptyList';
import {LoadingBox} from 'components/LoadingBox';

export type WidgetTypePageProps = WidgetsIndexApiArg;

export const WidgetTypePage = (params: WidgetTypePageProps) => {
  const isMe = useIsMe(params.userId);
  const {data, tabs, isLoading, refetch} = api.endpoints.widgetsIndex.useQuery(params, {
    selectFromResult: ({data, ...rest}) => ({
      ...rest,
      tabs: _.map(_.compose((item: {name: string}) => ({name: item.name, slug: slugify(item.name)}), _.pick(['name'])))(
        data || []
      ),
      data: (data || []).reduce(
        (acc, {name, collectorId, jobId, taskId}) => ({...acc, [slugify(name)]: {collectorId, jobId, taskId}}),
        {} as {[K: string]: {collectorId?: string; jobId?: string; taskId?: string}}
      ),
    }),
  });
  const [tab, setTab] = useQueryParam('tab', StringParam);

  if (!isLoading && !tabs.length)
    return (
      <EmptyList
        sx={{mt: 0.5}}
        text="Этот раздел пока пуст, скоро здесь появится ваш контракт"
        action={
          <Button onClick={refetch} size="small" variant="contained">
            Обновить
          </Button>
        }
      />
    );
  if (isLoading) return <LoadingBox sx={{mt: 0.5}} />;
  return (
    <TabContext value={tab || tabs[0]?.slug || ''}>
      <Box sx={{backgroundColor: 'white'}} mb={0.5}>
        <TabList onChange={(e, newTab) => setTab(newTab)} variant="scrollable">
          {tabs.map(item => (
            <Tab key={item.slug} label={item.name} value={item.slug} />
          ))}
        </TabList>
      </Box>
      {Object.keys(data).map((slug, i) => (
        <TabPanel value={slug} key={`widget-${slug}.${i}`} sx={{p: 0}}>
          {!!data[slug].taskId && <TaskWidget taskId={`${data[slug].taskId}`} isMe={isMe} />}
          {!!data[slug].jobId && <JobAnswers jobId={`${data[slug].jobId}`} mode="page" />}
        </TabPanel>
      ))}
    </TabContext>
  );
};
