import {ImageComponent} from './ImageComponent';
import {ContainerComponent} from './ContainerComponent';
import {DelimeterComponent} from './DelimeterComponent';
import {TypographyComponent} from './TypographyComponent';

export const visualComponents = {
  image: ImageComponent,
  container: ContainerComponent,
  delimeter: DelimeterComponent,
  typography: TypographyComponent,
};
