import {api} from 'api';
import {useAtom} from 'jotai';
import pick from 'lodash/pick';
import {FormikHelpers} from 'formik';
import {useSnackbar} from 'notistack';
import {useNavigate} from 'react-router';
import {skipToken} from '@reduxjs/toolkit/dist/query';
import {PublicationDto} from 'api/generated/users-api';

import {getTaskJobPath} from 'modules/tasks/utils';
import {useRefreshStage} from 'modules/quests/hooks';

import {initialValues} from './utils';
import {TaskFinalStepHook} from './types';
import {jobDialogState} from '../JobDialog';

export const useCompleteTask = ({
  postId,
  publicationRequired,
  jobId,
  status: jobStatus,
  stageId,
}: TaskFinalStepHook) => {
  const navigate = useNavigate();
  const {onRefresh} = useRefreshStage({stageId});
  const {enqueueSnackbar} = useSnackbar();
  const [complete] = api.endpoints.jobUniversalComplete.useMutation();
  const {currentPost} = api.endpoints.publicationIndex.useQuery(postId ? {postId} : skipToken, {
    selectFromResult: ({data}) => ({
      currentPost: !data
        ? undefined
        : (pick(data, ['content', 'filesId', 'filesAspectRatio', 'privacy', 'enabled']) as PublicationDto),
    }),
  });

  const [{open}, setState] = useAtom(jobDialogState);
  const taskInitials = {...initialValues, publication: currentPost || initialValues.publication};

  const onSubmit = async (data: typeof taskInitials, {setSubmitting}: FormikHelpers<typeof taskInitials>) => {
    if (!data.publication?.filesId) data.publication = undefined;
    if (!data.scoreComment?.comment) data.scoreComment = undefined;
    if (publicationRequired) data.createPublication = true;
    const result = await complete({jobId, jobCompleteDto: data as any});
    setSubmitting(false);
    if (stageId) onRefresh();
    if ('data' in result && jobStatus === 'new') {
      if (!open) navigate(getTaskJobPath(jobId, '/done'), {state: result.data});
      else setState({open: false});
    }
    if ('error' in result)
      enqueueSnackbar((result.error as any)?.data?.message || 'Не получилось завершить выполнение задания');
  };

  return {taskInitials, onSubmit};
};
