import {lazy, Suspense} from 'react';
import {RouteObject, useParams} from 'react-router';
import {skipToken} from '@reduxjs/toolkit/dist/query';

import {api} from 'api';
import IsExistsContainer from 'components/IsExistsContainer';
import {ContentListPageSkeleton, ContentPageSkeleton} from 'components/Skeleton';

import {ContentContext} from './pages/ContentPage/context';

const ContentPage = lazy(() => import('./pages/ContentPage'));
const ContentsListPage = lazy(() => import('./pages/ContentsList'));

const useGetContentPage = () => {
  const {contentId} = useParams<'contentId'>();
  return api.endpoints.contentIndex.useQuery(contentId ? {contentId} : skipToken);
};

export const router: RouteObject = {
  path: 'contents',
  children: [
    {
      index: true,
      element: (
        <Suspense fallback={<ContentListPageSkeleton />}>
          <ContentsListPage />
        </Suspense>
      ),
    },
    {
      path: ':contentId',
      element: (
        <IsExistsContainer
          context={ContentContext}
          useFetch={useGetContentPage}
          LoadingComponent={<ContentPageSkeleton />}
        />
      ),
      children: [
        {
          index: true,
          element: (
            <Suspense fallback={<ContentPageSkeleton />}>
              <ContentPage />
            </Suspense>
          ),
        },
      ],
    },
  ],
};

export default router;
