/* eslint-disable @typescript-eslint/no-unused-vars */
import {pathOr} from 'ramda';
import {isArray, isUndefined} from 'lodash';
import {Box, Button, Stack, Typography} from '@mui/material';
import {ErrorMessage, FieldArray, useField, useFormikContext} from 'formik';
import {Fragment, memo, PropsWithChildren, useMemo} from 'react';

import Field from '../Field';
import {FormObject} from '..';
import {FormArrayItem} from '../types';
import {TFormBlock} from '../Form/types';
import {getInitialValues} from '../Form/utils';
import {FiledNameContext} from '../context';
import FieldContainer from '../FieldContainer';
import {ERROR} from 'components/theme/palette';

const FormArrayRow = memo(({childs, remove, ...item}: FormArrayItem<any> & {remove?: () => void}) => {
  return (
    <>
      <FormBlock fields={childs} {...item} />
      {remove && (
        <Button variant="outlined" size="small" sx={{px: 3}} onClick={remove}>
          Удалить блок
        </Button>
      )}
    </>
  );
});

function FormArrayBlock<Values extends Record<string, any>>({name, childs, min, max, ...item}: FormArrayItem<Values>) {
  const [{value: values}] = useField(name);
  const arr = useMemo(() => (isArray(values) ? new Array(values.length).fill(0) : null), [values]);
  if (!arr) return null;
  return (
    <FieldArray name={name}>
      {({push, remove}) => (
        <Box mb={3}>
          {item.label && (
            <Typography variant="h4" sx={{mb: 1}}>
              {item.label}
            </Typography>
          )}
          {arr.map((row, index) => (
            <FiledNameContext.Provider value={`${name}.${index}`} key={`${name}.${index}`}>
              <FormArrayRow
                {...item}
                name={name}
                childs={childs}
                min={min}
                max={max}
                remove={arr.length > min && index > 0 && index > min - 1 ? () => remove(index) : undefined}
              />
            </FiledNameContext.Provider>
          ))}
          <div>
            {((!!max && arr.length < max) || isUndefined(max)) && (
              <Button variant="contained" size="small" sx={{px: 3}} onClick={() => push(getInitialValues(childs))}>
                Добавить ответ
              </Button>
            )}
          </div>
          <ErrorMessage name={name}>
            {message => (
              <Box mt={1}>
                <Typography variant="body1" color={ERROR.main}>
                  {message}
                </Typography>
              </Box>
            )}
          </ErrorMessage>
        </Box>
      )}
    </FieldArray>
  );
}

function ConditionedField({condition, children}: PropsWithChildren<{condition: FormObject<any>['showIf']}>) {
  let show = true;
  const {values} = useFormikContext();
  if (condition) {
    if (typeof condition === 'string') show = Boolean(pathOr('', condition.split('.'), values));
    else show = condition(values);
  }
  if (!show) return null;
  return <>{children}</>;
}

function RendererField({render, name}: Required<Pick<FormObject<any>, 'name' | 'render'>>) {
  const data = useField(name as string);
  return <>{render(...data)}</>;
}

const renderField = (field: FormObject<any>) => {
  if ('type' in field) {
    if (field.render) return <RendererField render={field.render} name={field.name} />;
    return (
      <FieldContainer>
        <Field {...field} />
      </FieldContainer>
    );
  }
  if ('min' in field) return <FormArrayBlock {...field} />;
  return (
    <FiledNameContext.Provider value={field.name || ''}>
      <FormBlock display={field.display} fields={field.childs} title={field.label} />
      {field.name && (
        <ErrorMessage
          name={field.name}
          render={props =>
            typeof props === 'string' || isArray(props) ? (
              <Typography variant="subtitle2" color="error.main">
                {isArray(props) ? props.join(' ') : props}
              </Typography>
            ) : (
              <span />
            )
          }
        />
      )}
    </FiledNameContext.Provider>
  );
};

function renderFields<Values extends object>(fields: TFormBlock<Values>['fields']) {
  return fields
    .map((field, i) => {
      if (!field.showIf) return <Fragment key={i}>{renderField(field)}</Fragment>;
      return (
        <ConditionedField key={i} condition={field.showIf}>
          {renderField(field)}
        </ConditionedField>
      );
    })
    .filter(Boolean);
}

export function FormBlock<Values extends object>({fields, title, display}: TFormBlock<Values>) {
  const Content = renderFields(fields);
  if (!Content.length) return null;

  const renderedContent = !display ? (
    <Box>{Content}</Box>
  ) : (
    <Stack spacing={1} direction="row">
      {Content}
    </Stack>
  );
  if (!title) return renderedContent;
  return (
    <>
      <Box>{title}</Box>
      {renderedContent}
    </>
  );
}

export default FormBlock;
