import {FC, useCallback} from 'react';
import {atom, useAtom} from 'jotai';
import {skipToken} from '@reduxjs/toolkit/dist/query';

import {api} from 'api';
import {Jobs} from 'api/generated/users-api';

import {JobEdit} from 'modules/tasks/pages/Job/JobEdit';
import {useDialog} from 'components/Dialog';
import {JobIdProp} from 'modules/tasks/types';
import {StageIdProp} from 'modules/quests/types';
import {RenderContent} from 'modules/contents/containers';
import IsExistsContainer from 'components/IsExistsContainer';
import {useAtomCallback} from 'jotai/utils';

const useGetJobById = (jobId?: string) => () => api.endpoints.jobIndex.useQuery(jobId ? {jobId} : skipToken);

export const JobDialog: FC<JobIdProp & Partial<StageIdProp>> = ({jobId, stageId}) => (
  <IsExistsContainer
    useFetch={useGetJobById(jobId)}
    errorMessage={{403: 'Доступ к заданию ограничен настройками приватности'}}
  >
    {(job: Jobs) => (
      <>
        {job.task?.contentsId && job.task?.contentsId.map(item => <RenderContent contentId={item} key={item} />)}
        <JobEdit job={job} stageId={stageId} />
      </>
    )}
  </IsExistsContainer>
);

export const jobDialogState = atom<{open: boolean} & Partial<JobIdProp & StageIdProp>>({open: false});

export const useJobDialog = () =>
  useAtomCallback(
    useCallback(
      (get, set, update: JobIdProp & Partial<StageIdProp>) => set(jobDialogState, {open: true, ...update}),
      []
    )
  );

export const JobDialogProvider = () => {
  const [Dialog] = useDialog();
  const [{open, jobId, stageId}, setState] = useAtom(jobDialogState);
  const close = () => setState({open: false});

  return (
    <Dialog title="Задание" open={open && !!jobId} onClose={close}>
      {jobId && <JobDialog jobId={jobId} stageId={stageId} />}
    </Dialog>
  );
};
