import {Card, CardContent, Stack, Box, Typography} from '@mui/material';
import {HeaderSetup} from 'components/Layout';
import {SearchBlock, SearchTabs} from 'modules/search/components';
import {GREY} from 'components/theme/palette';

export const SearchFocusEmptyPageMarkup = () => {
  return (
    <>
      <HeaderSetup title="" />
      <Stack spacing={0.5}>
        <Card>
          <CardContent>
            <SearchBlock />
          </CardContent>
          <SearchTabs />
        </Card>

        <Card>
          <Box display="flex" alignItems="center" justifyContent="center" sx={{padding: 2}}>
            <Typography variant="body2" textAlign="center" sx={{color: GREY[200]}}>
              Введите имя, ник латиницей, название команды или&nbsp;фразу
            </Typography>
          </Box>
        </Card>
      </Stack>
    </>
  );
};

export default SearchFocusEmptyPageMarkup;
