import {HeaderSetup} from 'components/Layout';
import {useSearchField} from 'components/SearchField';

import {GoalList} from 'modules/goals';
import {useMyStreamId} from 'modules/auth';
import {Box} from '@mui/material';

export const CompletedGoals = () => {
  const [Search, value] = useSearchField();
  const streamId = useMyStreamId();

  return (
    <Box width={1}>
      <HeaderSetup showSettings={false} title="Выполненные цели" backLink="/discover" />
      <Search />
      <GoalList
        params={{status: 'completed', streamId, name: value || undefined}}
        disableStatusSelect
        emptyListProps={{text: 'В игре еще нет выполненных целей. Будь первым, кто выполнит цель!'}}
      />
    </Box>
  );
};
