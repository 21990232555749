import {useState, useMemo} from 'react';
import {ButtonBase, Card, CardContent, CardHeader, Stack, Typography} from '@mui/material';

import {useMyStreamId} from 'modules/auth';
import {GREY} from 'components/theme/palette';
import {useSearchField} from 'components/SearchField';
import {PublicationList} from 'modules/feed/containers';

import {PublicationsIndexApiArg} from 'api/generated/users-api';

export const TaskFeed = ({
  taskId,
  title,
  withSearch = false,
}: {
  taskId: string;
  withSearch?: boolean;
  title?: string;
}) => {
  const streamId = useMyStreamId();
  const [Search, searchValue] = useSearchField();
  const [sort, setSort] = useState<PublicationsIndexApiArg['sort']>('rating');
  const params = useMemo(
    () => ({streamId, sort, taskId, username: searchValue || undefined} as PublicationsIndexApiArg),
    [streamId, sort, searchValue, taskId]
  );
  return (
    <Stack spacing={0.5}>
      {withSearch && <Search placeholder="username или имя пользователя" />}
      <Card>
        {title && <CardHeader title={title} />}
        <CardContent>
          <Stack spacing={2} direction="row">
            <Typography
              component={ButtonBase}
              variant="body2"
              onClick={() => setSort('created_date')}
              color={GREY[sort === 'created_date' ? 400 : 200]}
              fontWeight={sort === 'created_date' ? 500 : undefined}
            >
              По времени
            </Typography>
            <Typography
              component={ButtonBase}
              variant="body2"
              onClick={() => setSort('rating')}
              color={GREY[sort === 'rating' ? 400 : 200]}
              fontWeight={sort === 'rating' ? 500 : undefined}
            >
              По рейтингу
            </Typography>
          </Stack>
        </CardContent>
      </Card>

      <PublicationList params={params} emptyListMessage="Это задание еще никто не выполнил" />
    </Stack>
  );
};
