import {Stack} from '@mui/material';
import {fill} from 'lodash';

import {ContentPreviewSkeleton} from '../components/ContentPreviewSkeleton';

export const ContentsListSkeleton = ({limit = 7}: {limit?: number}) => (
  <Stack spacing={1}>
    {fill(Array(limit), '').map((_, i) => (
      <ContentPreviewSkeleton key={i} />
    ))}
  </Stack>
);
