import SelectField from '../Field/SelectField';

export const privacyOptions = (gender?: 'masculine' | 'feminine') => [
  {value: 'public', name: !gender || gender === 'feminine' ? 'Видна всем' : 'Виден всем'},
  {value: 'private', name: 'Только мне'},
  {value: 'groups', name: 'Только команде'},
  {value: 'subscribers', name: 'Только подписчикам'},
  {value: 'groups_subscribers', name: 'Команде и подписчикам'},
];

export const PrivacyField = ({
  name,
  gender = 'feminine',
  label = 'Видимость поста',
}: {
  name: string;
  label?: string;
  gender?: 'masculine' | 'feminine';
}) => <SelectField name={name} type="select" label={label} options={privacyOptions(gender)} />;
