import {api} from 'api';
import {useSnackbar} from 'notistack';

import {useIsMe} from 'modules/auth';
import {useClaimMenuItem} from 'modules/claims';
import ButtonMenu from 'components/ButtonMenu';

import {ReactComponent as MoreDotsIcon} from 'svg/moreDots.svg';
import {ReactComponent as RemoveGroupIcon} from 'svg/groupIcons/removeGroup.svg';

export const CommentContextMenu = ({entryId: referenceId, authorId}: {entryId: string; authorId: string}) => {
  const isMe = useIsMe(authorId);
  const {enqueueSnackbar} = useSnackbar();
  const claimiItem = useClaimMenuItem({referenceName: 'Comments', referenceId});
  const [removeComment] = api.endpoints.commentSafeDelete.useMutation();
  const onCommentRemove = async () => {
    if (isMe) {
      const result = await removeComment({commentId: referenceId});
      if ('error' in result)
        enqueueSnackbar((result.error as any)?.data?.message || 'Не получилось удалить комментарий', {
          variant: 'error',
          autoHideDuration: 2000,
        });
    }
  };

  const items = [
    !isMe ? claimiItem : null,
    isMe
      ? {
          label: 'Удалить комментарий',
          onClick: onCommentRemove,
          icon: <RemoveGroupIcon />,
        }
      : null,
  ].filter(Boolean);

  if (!items.filter(Boolean).length) return null;
  return (
    <ButtonMenu
      buttonProps={{
        icon: <MoreDotsIcon height={4} />,
        size: 'small',
        sx: {px: 0, py: 0, height: 20},
      }}
      items={items}
    />
  );
};
