import {Box, Stack, Typography} from '@mui/material';
import {SxProps} from '@mui/system';

import {QuestPointProgress} from 'api/generated/users-api';
import {useSafeContext} from 'utils';
import {GroupContext} from 'modules/groups/context';
import {ReactComponent as UsersIcon} from 'svg/icons/userIconFilled.svg';

type GroupProgressBadgeProps = {
  progress: QuestPointProgress[];
  sx?: SxProps;
};

export const GroupProgressBadge = ({progress, sx}: GroupProgressBadgeProps) => {
  const {users} = useSafeContext(GroupContext);
  const completedCount =
    progress.find(progressData => ['completed', 'ready'].includes(progressData.status))?.count || 0;
  const notCompletedCount = users.length - completedCount;

  if (!completedCount && !notCompletedCount) return null;
  return (
    <Box width="fit-content" sx={{transition: 'all .3s', ...sx}}>
      <Stack direction="row" borderRadius={14} width="fit-content" overflow="hidden">
        {Boolean(completedCount) && (
          <Stack p={0.25} pr={0.75} spacing={0.5} direction="row" alignItems="center" bgcolor="success.light">
            <Box component={UsersIcon} borderRadius="50%" bgcolor="success.main" />
            <Typography variant="subtitle1" color="success.darker">
              {completedCount}
            </Typography>
          </Stack>
        )}
        {Boolean(notCompletedCount) && (
          <Stack p={0.25} pr={0.75} spacing={0.5} direction="row" alignItems="center" bgcolor="error.light">
            <Box component={UsersIcon} borderRadius="50%" bgcolor="error.main" />
            <Typography variant="subtitle1" color="error.darker">
              {notCompletedCount}
            </Typography>
          </Stack>
        )}
      </Stack>
    </Box>
  );
};
