/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {memo, useEffect} from 'react';
import {isArray} from 'lodash';
import {useField} from 'formik';
import {Box} from '@mui/system';
import {styled} from '@mui/system';
import {useMeasure} from 'react-use';
import {useSnackbar} from 'notistack';
import {useDropzone} from 'react-dropzone';
import {IconButton, ButtonGroup, Stack, Typography} from '@mui/material';
import {compose, concat, get, head, reverse, uniq, without} from 'lodash/fp';

import {filesApi} from 'api';
import {FileCropApiArg} from 'api/generated/files-api';
import {getRatioMultiplier} from 'utils';

import {ImageGallery} from 'components/ImageGallery';
import {ReactComponent as TrashIcon} from 'svg/icons/trashIcon.svg';
import {ReactComponent as PhotoIcon} from 'svg/icons/photoIcon.svg';

import ToggleButtonField from 'components/FormFactory/Field/ToggleButtonField';

import * as Styled from './styles';
import {PhotoFieldProps} from '../types';
import {LoadingBox} from '../../../LoadingBox';

const StyledButtonGroup = styled(ButtonGroup)(() => ({
  position: 'absolute',
  top: '16px',
  right: '16px',
}));

const StyledIconButton = styled(IconButton)(() => ({
  width: '40px',
  height: '40px',
}));

const StyledTrashIcon = styled(TrashIcon)(() => ({
  '& path': {
    fill: '#fff',
    opacity: 0.8,
  },
}));

type FieldProps = {
  name: string;
  type: string;
} & PhotoFieldProps['inputProps'];

const PhotoUpload = ({
  ratio = '1:1',
  setRatio,
  name,
  multiple,
}: {ratio?: FileCropApiArg['ratio']; setRatio?: (value: FileCropApiArg['ratio']) => void} & FieldProps) => {
  const [ref, {width}] = useMeasure<HTMLDivElement>();
  const [{value}, meta, {setValue}] = useField(name);
  const {enqueueSnackbar} = useSnackbar();
  const [uploadFile, {isLoading}] = filesApi.endpoints.rootUpload.useMutation();

  const {getRootProps, getInputProps, open} = useDropzone({
    accept: 'image/*',
    noClick: false,
    onDrop: async files => {
      if (files.length) {
        const body = new FormData();
        files.forEach(file => body.append('file', file));
        const result = await uploadFile({body: body as any});
        if ('data' in result) {
          const newIds = result.data.map(get('_id'));
          if (multiple) setValue(compose(uniq, concat(value))(newIds));
          else setValue(head(newIds));
        } else if ('error' in result) {
          enqueueSnackbar((result.error as any).data.message || 'Не удалось загрузить изображение', {
            variant: 'error',
            autoHideDuration: 2000,
          });
        }
      }
    },
  });

  const removeFile = (fileId: string) => {
    if (!isArray(value)) setValue('');
    else setValue(without([fileId], value));
  };

  const isEmpty = !value || (isArray(value) && !value.length);

  return (
    <div ref={ref}>
      <Box
        sx={{
          width,
          borderRadius: 2,
          overflow: 'hidden',
          position: 'relative',
          backgroundColor: 'grey.10',
          height: isEmpty ? 240 : width / getRatioMultiplier(ratio),
        }}
      >
        <Box
          {...getRootProps()}
          width={1}
          height={1}
          position="relative"
          onClick={open}
          sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}
        >
          <Stack alignItems="center" spacing={1}>
            {!isLoading && (
              <>
                <Box
                  component={PhotoIcon}
                  width={40}
                  height={40}
                  sx={{borderRadius: '50%', backgroundColor: 'primary.500', p: 0.5, boxSizing: 'content-box'}}
                />
                <Typography variant="subtitle1" sx={{px: 3, color: 'grey.200'}} align="center">
                  Нажмите сюда,
                  <br />
                  чтобы добавить фото
                </Typography>
              </>
            )}
          </Stack>
          <input {...getInputProps()} />
        </Box>
        {!isEmpty && (
          <Styled.ImageContainer>
            <ImageGallery
              dotsOutside
              withPreview
              ratio={ratio}
              filesId={isArray(value) ? reverse(value) : [value]}
              action={fileId => (
                <StyledButtonGroup orientation="vertical">
                  <StyledIconButton
                    sx={{backgroundColor: 'rgba(0, 0, 0, 0.4)', marginBottom: '8px'}}
                    onClick={() => removeFile(fileId)}
                  >
                    <StyledTrashIcon width="32" height="32" />
                  </StyledIconButton>
                  <StyledIconButton sx={{backgroundColor: 'primary.500'}} onClick={open}>
                    <PhotoIcon width="32" height="32" />
                  </StyledIconButton>
                </StyledButtonGroup>
              )}
            />
          </Styled.ImageContainer>
        )}
        {isLoading && (
          <LoadingBox
            sx={{
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              margin: 'auto',
              position: 'absolute',
              backgroundColor: 'rgba(0, 0, 0, 0)',
            }}
          />
        )}
        {setRatio && <ToggleButtonField ratio={ratio} setRatio={setRatio} />}
      </Box>
    </div>
  );
};

const PhotoFieldWithRatio = ({ratioName, ...props}: FieldProps & {ratioName: string}) => {
  const [{value: ratioValue}, meta, {setValue: setRatioValue}] = useField(ratioName);
  useEffect(() => {
    if (!ratioValue) setRatioValue('1:1');
  }, [ratioValue]);

  return <PhotoUpload {...props} ratio={ratioValue} setRatio={setRatioValue} />;
};

export const PhotoField = ({ratioName, ...props}: FieldProps) => {
  if (ratioName) return <PhotoFieldWithRatio ratioName={ratioName} {...props} />;
  return <PhotoUpload {...props} />;
};

export default memo(PhotoField);
