import {MouseEventHandler, PropsWithChildren} from 'react';
import {Box, Button as MuiButton, Stack, Typography} from '@mui/material';

import {useMyId} from 'modules/auth';

import {ReactComponent as LikeFilledIcon} from 'svg/icons/likeFilledIcon.svg';
import {ReactComponent as LikeIcon} from 'svg/icons/likeIcon.svg';

type VotePanelProps = {
  approveAction: MouseEventHandler<HTMLButtonElement>;
  declineAction: MouseEventHandler<HTMLButtonElement>;
  approvedUsersId: string[];
  declinedUsersId: string[];
  approveContent?: string | JSX.Element;
  declineContent?: string | JSX.Element;
};

const Icon = ({filled, variant}: {filled?: boolean; variant: 'approve' | 'decline'}) => (
  <Box
    component="span"
    sx={{
      mr: 0.6,
      '& svg': {
        color: variant === 'approve' ? 'success.main' : 'error.main',
      },
    }}
  >
    {filled ? <LikeFilledIcon /> : <LikeIcon />}
  </Box>
);

const Button = ({
  variant,
  filled,
  onClick,
  children,
}: PropsWithChildren<{
  variant: 'approve' | 'decline';
  filled: boolean;
  onClick: MouseEventHandler<HTMLButtonElement>;
}>) => (
  <MuiButton
    variant="outlined"
    onClick={onClick}
    disabled={filled}
    sx={{
      width: '100%',
      height: '32px',
      border: '1px solid',
      borderBottom: 'none',
      borderTop: 'none',
      borderColor: 'grey.10',
      backgroundColor: 'white',
      borderRadius: '0',
      color: 'grey.200',
      '&.Mui-disabled': {
        borderColor: 'grey.10',
        borderBottom: 'none',
        borderTop: 'none',
        backgroundColor: 'white',
      },
      '&:hover': {
        borderColor: 'grey.10',
        borderBottom: 'none',
        borderTop: 'none',
        backgroundColor: variant === 'approve' ? 'success.light' : 'error.lighter',
      },
      '&:first-of-type': {
        borderLeft: 'none',
        borderRight: 'none',
      },
      '&:last-of-type': {
        borderRight: 'none',
      },
    }}
  >
    <Icon variant={variant} filled={filled} />
    <Typography variant="body2">{children}</Typography>
  </MuiButton>
);

export const VotePanel = ({
  approveAction,
  declineAction,
  approvedUsersId,
  declinedUsersId,
  approveContent,
  declineContent,
}: VotePanelProps) => {
  const myId = useMyId();
  const myApprove = Boolean(approvedUsersId.find(i => i === myId));
  const myDecline = Boolean(declinedUsersId.find(i => i === myId));
  const myVote = myApprove ? 'accept' : myDecline ? 'decline' : null;
  return (
    <Stack
      direction="row"
      sx={{
        height: '32px',
        '& svg': {
          width: '24px',
          height: '24px',
        },
      }}
    >
      <Button variant="approve" onClick={approveAction} filled={myApprove}>
        {approveContent || (myVote ? approvedUsersId.length : 'За')}
      </Button>
      <Button variant="decline" onClick={declineAction} filled={myDecline}>
        {declineContent || (myVote ? declinedUsersId.length : 'Против')}
      </Button>
    </Stack>
  );
};
