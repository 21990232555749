import removeMD from 'remove-markdown';
import {useEffectOnce} from 'react-use';
import TextTruncate from 'react-text-truncate';
import Typography from '@mui/material/Typography';
import {MarkdownRender} from 'components/MarkdownRender';

export type PublicationDescriptionProps = {
  content?: string;
  toggleExpandable?: () => void;
  truncate?: boolean;
};

const TruncateChild = ({toggleExpandable}: Pick<PublicationDescriptionProps, 'toggleExpandable'>) => {
  useEffectOnce(() => toggleExpandable && toggleExpandable());
  return (
    <Typography sx={{color: 'grey.200'}} fontWeight={500} component="span">
      еще
    </Typography>
  );
};

export const PublicationDescription = ({content, truncate, toggleExpandable}: PublicationDescriptionProps) => {
  if (!content) return null;
  if (!truncate) return <MarkdownRender content={content} />;
  const plainContent = removeMD(content);
  return (
    <Typography variant="body1">
      <TextTruncate
        line={6}
        truncateText="..."
        element="span"
        textElement="span"
        text={plainContent}
        textTruncateChild={<TruncateChild toggleExpandable={toggleExpandable} />}
      />
    </Typography>
  );
};

export default PublicationDescription;
