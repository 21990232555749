import {useCallback} from 'react';
import Box from '@mui/material/Box';
import LoadingButton from '@mui/lab/LoadingButton';

import {api} from 'api';
import {Quests, Stages, StagesIndexApiArg} from 'api/generated/users-api';

import {Quest} from 'modules/oldQuests2/containers';
import {InfiniteList, InfiniteListHeader} from 'components/InfiniteLoader';

export const StagesList = ({
  params,
  title,
  limit = 5,
}: {
  title: string;
  limit?: number;
  params: Pick<StagesIndexApiArg, 'status' | 'questType'>;
}) => {
  const [getStages, result] = api.endpoints.stagesIndex.useLazyQuery();
  const load = useCallback(
    ({limit, offset}: {limit: number; offset: number}) => {
      getStages({...params, limit, offset, populates: ['quest']});
    },
    [params, getStages]
  );

  return (
    <InfiniteList<Stages>
      mx={1}
      limit={limit}
      spacing={-0.25}
      fetchFn={load}
      result={result}
      fetchOnMount
      disableWaypoint
      disableEmptyList
      Header={({total}) => (!!total ? <InfiniteListHeader title={title} count={total} /> : null)}
      Footer={({total, currentCount, limit, loadMore, isFetching, itemsLeft}) => {
        if (!total || !itemsLeft) return null;
        return (
          <Box display="flex" py={1} justifyContent="center" sx={{backgroundColor: 'white'}}>
            <LoadingButton
              sx={{mb: 2}}
              size="small"
              color="secondary"
              variant="contained"
              loading={isFetching}
              disabled={isFetching}
              onClick={loadMore}
            >
              Показать еще {total - currentCount > limit ? limit : total - currentCount}
            </LoadingButton>
          </Box>
        );
      }}
    >
      {(stage: Stages) => <Quest stage={stage} quest={stage.quest as Quests} />}
    </InfiniteList>
  );
};
