import {Stack, Skeleton, Box} from '@mui/material';

export const SettingsPageSkeleton = () => (
  <Stack spacing={0.5}>
    <Stack bgcolor="white">
      <Box py={1} px={2}>
        <Skeleton variant="text" animation="wave" width={120} height={24} sx={{transform: 'none'}} />
      </Box>
      <Stack px={2} py={1}>
        <Stack direction="row" alignItems="center" spacing={2} mb={2}>
          <Skeleton variant="circular" width={64} height={64} animation="wave" sx={{minWidth: 64}} />
          <Skeleton variant="rectangular" width="100%" height={32} sx={{borderRadius: 2}} animation="wave" />
        </Stack>
        <Stack spacing={4} mb={5.5}>
          <Skeleton variant="rectangular" width="100%" height={30} sx={{borderRadius: 2, mt: 2}} animation="wave" />
          <Skeleton variant="rectangular" width="100%" height={30} sx={{borderRadius: 2}} animation="wave" />
          <Skeleton variant="rectangular" width="100%" height={30} sx={{borderRadius: 2}} animation="wave" />
          <Skeleton variant="rectangular" width="100%" height={30} sx={{borderRadius: 2}} animation="wave" />
        </Stack>
        <Skeleton variant="rectangular" width="100%" height={28} sx={{borderRadius: 1.5}} animation="wave" />
        <Stack pt={2} mb={1}>
          <Skeleton variant="text" animation="wave" width={100} height={12} sx={{transform: 'none'}} />
          <Stack direction="row" justifyContent="space-between" spacing={1} my={2}>
            <Skeleton variant="rectangular" width={150} height={28} sx={{borderRadius: 2}} animation="wave" />
            <Skeleton variant="rectangular" width={150} height={28} sx={{borderRadius: 2}} animation="wave" />
            <Skeleton variant="rectangular" width={150} height={28} sx={{borderRadius: 2}} animation="wave" />
          </Stack>
        </Stack>
        <Skeleton variant="rectangular" width="100%" height={124} sx={{borderRadius: 2}} animation="wave" />
        <Stack spacing={4} mb={5.5}>
          <Skeleton variant="rectangular" width="100%" height={30} sx={{borderRadius: 2, mt: 2}} animation="wave" />
          <Skeleton variant="rectangular" width="100%" height={30} sx={{borderRadius: 2}} animation="wave" />
        </Stack>
        <Skeleton variant="rectangular" width="100%" height={44} sx={{borderRadius: 2}} animation="wave" />
      </Stack>
    </Stack>
    <Stack bgcolor="white" px={2} py={1}>
      <Skeleton variant="text" animation="wave" width={80} height={24} sx={{transform: 'none', mb: 1}} />
      <Stack pt={1}>
        <Box py={1}>
          <Skeleton variant="text" animation="wave" width={220} height={20} sx={{transform: 'none'}} />
        </Box>
      </Stack>
    </Stack>
    <Stack bgcolor="white" px={2} py={1}>
      <Skeleton variant="text" animation="wave" width={80} height={24} sx={{transform: 'none', mb: 1}} />
      <Stack pt={1}>
        <Box py={1}>
          <Skeleton variant="text" animation="wave" width={300} height={20} sx={{transform: 'none'}} />
        </Box>
        <Box py={1}>
          <Skeleton variant="text" animation="wave" width={200} height={20} sx={{transform: 'none'}} />
        </Box>
      </Stack>
    </Stack>
    <Stack bgcolor="white" px={2} py={1}>
      <Skeleton variant="rectangular" width="100%" height={44} sx={{borderRadius: 2}} animation="wave" />
    </Stack>
  </Stack>
);
