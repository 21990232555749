import {PropsWithChildren} from 'react';
import {Link} from 'react-router-dom';
import plural from 'plural-ru';
import {ceil} from 'lodash';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import MUILink from '@mui/material/Link';
import Typography from '@mui/material/Typography';

import {GroupData} from 'api/generated/users-api';
import {GroupProfileImage} from 'modules/groups';
// import {getGroupPath} from 'modules/groups/utils';
import {ProfileName} from 'components/ProfileName';
// import {ProfileHeader} from 'components/ProfileHeader';

import {ReactComponent as UsersIcon} from 'svg/icons/userIconFilled.svg';
import {ReactComponent as RatingIcon} from 'svg/social/ratingIcon.svg';

const Counter = ({children, link, p = '2px 8px 2px 2px'}: PropsWithChildren<{link?: string; p?: string | number}>) => {
  const Element = (
    <Stack
      bgcolor="rgba(255, 255, 255, 0.2)"
      direction="row"
      alignItems="center"
      overflow="hidden"
      color="white"
      p={p}
      borderRadius={14}
      height="fit-content"
      width="fit-content"
      sx={{
        transition: 'opacity .2s',
        '&:hover': link ? {opacity: 0.8} : null,
      }}
    >
      {children}
    </Stack>
  );
  if (link) return <Link to={link}>{Element}</Link>;
  return Element;
};

export const GroupHeader = ({group: {info, users}}: {group: GroupData}) => {
  const NPS = info.stats?.NPS ? ceil(info.stats.NPS, 2) : 'N/A';
  const background = `
      center/cover no-repeat url(${info.file?.location}),
      rgba(0, 0, 0, 0.5)`;

  return (
    <Box borderRadius={2} sx={{background}}>
      <Stack
        p={2}
        position="relative"
        spacing={2}
        borderRadius={2}
        bgcolor="rgba(0, 0, 0, 0.5)"
        sx={{backdropFilter: 'blur(50px)'}}
      >
        <Stack direction="row" alignItems="center" spacing={1.5}>
          <GroupProfileImage group={info} size="large" />
          <ProfileName name={info.name} color="white" variant="h2" fontWeight={600} />
        </Stack>
        <Stack spacing={1.5}>
          <Typography variant="body2" color="white" sx={{opacity: 0.9}}>
            {info.description}
          </Typography>
          {info.externalLink && (
            <MUILink
              variant="body2"
              target="_blank"
              underline="hover"
              sx={{color: 'white', typography: 'subtitle1', overflow: 'hidden', textOverflow: 'ellipsis'}}
              href={!/^https?:\/\//i.test(info.externalLink) ? `https://${info.externalLink}` : info.externalLink}
            >
              {info.externalLink}
            </MUILink>
          )}
        </Stack>
        <Stack direction="row" spacing={0.5}>
          <Counter link="./members">
            <Box height={16} bgcolor="primary.400" borderRadius="50%" mr={0.5}>
              <Box component={UsersIcon} width={16} height={16} />
            </Box>
            <Typography variant="subtitle1" color="white">
              {users.length} {plural(users.length, 'участник', 'участника', 'участников')}
            </Typography>
          </Counter>
          <Counter link="/discover/rating/groups" p={0}>
            <Stack direction="row" alignItems="center" p={0.25} pr={0.75}>
              <Box component={RatingIcon} width={16} height={16} mr={0.5} />
              <Typography variant="subtitle1" color="white">
                {NPS}
              </Typography>
            </Stack>
            <Stack justifyContent="center" height={20} py={0.25} pl={0.75} pr={1} bgcolor="white">
              <Typography variant="subtitle1" color="grey.700">
                {info.ratingPosition?.byNPS || 'N/A'} место
              </Typography>
            </Stack>
          </Counter>
        </Stack>
      </Stack>
    </Box>
  );
};

// const GroupHeaderOLD = ({group: {info, users, groupId}}: {group: GroupData}) => {
//   const navigate = useNavigate();
//   return (
//     <>
//       <ProfileHeader
//         image={<GroupProfileImage group={info} size="large" />}
//         name={<ProfileName name={info.name} nps={info.stats?.NPS} />}
//         counters={[
//           {
//             label: plural(users.length, 'участник', 'участника', 'участников'),
//             count: users.length,
//             link: `/teams/${groupId}/members`,
//           },
//           {label: 'В рейтинге', count: info.ratingPosition?.byNPS || 'N/A', link: '/discover/rating/groups'},
//         ]}
//       />
//       {info.description && (
//         <Typography variant="body2" sx={{whiteSpace: 'pre-wrap'}}>
//           {info.description}
//         </Typography>
//       )}
//       {info.externalLink && (
//         <Link
//           variant="body2"
//           underline="hover"
//           sx={{color: 'primary.500', height: 20, overflow: 'hidden', textOverflow: 'ellipsis'}}
//           href={!/^https?:\/\//i.test(info.externalLink) ? `https://${info.externalLink}` : info.externalLink}
//         >
//           {info.externalLink}
//         </Link>
//       )}
//       <Button
//         size="small"
//         color="secondary"
//         variant="contained"
//         onClick={() => navigate(getGroupPath(groupId, 'stats'))}
//       >
//         Статистика команды
//       </Button>
//     </>
//   );
// };
