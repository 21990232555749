import {Stack} from '@mui/material';

import {GoalSliderSkeleton} from '../../Goal';
import {ProfileHeaderSkeleton, ProfilePublicationListSkeleton} from '../components';

export const ProfilePageSkeleton = () => (
  <Stack>
    <ProfileHeaderSkeleton />
    <GoalSliderSkeleton />
    <ProfilePublicationListSkeleton />
  </Stack>
);
