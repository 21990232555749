import {styled} from '@mui/system';
import {Box, IconButton, TextField} from '@mui/material';
import {PRIMARY, GREY} from 'components/theme/palette';

import {OutlinedInput} from '@mui/material';

export const Field = styled(OutlinedInput)`
  height: 40px;
  border-radius: 20px;
  padding-left: 0;

  &.MuiInputBase-adornedStart {
    svg {
      width: 40px;
      height: 40px;
    }
  }

  & .MuiOutlinedInput-input {
    font-size: 15px;
    line-height: 20px;
    padding: ${({theme}) => theme.spacing(1, 0)};
    &::placeholder {
      color: ${GREY[200]};
    }
  }
  &-notchedOutline {
    border: 1px solid #d9dee8;
  }
`;

export const SearchBox = styled(Box)(() => ({
  position: 'relative',
  display: 'flex',
  alignItems: 'top',
  // height: '40px',
  overflow: 'hidden',
  paddingRight: '8px',
  border: `1px solid ${GREY[25]}`,
  borderRadius: '500px',
  '&:hover': {
    borderColor: PRIMARY[500],
    '& .MuiIconButton-root path': {
      stroke: PRIMARY[500],
    },
  },
  '& .MuiIconButton-root': {
    width: '38px',
    height: '38px',
    flexShrink: 0,
    padding: 0,

    '&:hover': {
      backgroundColor: 'none',
      '& path': {
        stroke: PRIMARY[500],
      },
    },
  },
}));

export const SearchField = styled(TextField)(() => ({
  flexGrow: 1,
  padding: 0,
  '& .MuiInputBase-root': {
    height: '100%',
    '&::before, &::after': {
      display: 'none',
      border: 'none',
    },
    '&:hover:not(.Mui-disabled)::before': {
      border: 'none',
    },
  },
  '& .MuiInputBase-input::placeholder': {
    color: GREY[200],
  },
}));

export const ClearButton = styled(IconButton)`
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(0, -50%);

  &.MuiIconButton-root {
    width: 16;
    height: 16;
    &:hover: {
      background-color: 'transparent';
    }
  }
`;
