import {createReducer} from '@reduxjs/toolkit';
import {BlockerState} from './types';
import {setBlocker} from './actions';

const initialState: BlockerState = {
  isBlocked: false,
  name: undefined,
  message: undefined,
};

export default createReducer(initialState, b =>
  b.addCase(setBlocker, (state, {payload}) => ({...state, isBlocked: true, ...payload}))
);
