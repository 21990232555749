import {useMemo, useState} from 'react';
import {useLocalStorage} from 'react-use';

import {Card, CardContent, Stack, Tab, Tabs} from '@mui/material';

import {GoalsIndexApiArg} from 'api/generated/users-api';
import {GoalFiltersMenu} from 'modules/goals/components';
import {GridListControl} from 'components/SegmentedControl';

import {GoalsGridView} from './GoalsGridView';
import {GoalsListView} from './GoalsListView';
import {GoalListProps} from './types';

export const GoalList = ({
  action,
  params,
  onGoalClick,
  display = 'grid',
  disableSort,
  disableViewSelect,
  disableStatusSelect,
  showContentIfDeleted,
  emptyListProps,
}: GoalListProps) => {
  const [sort, setSort] = useState<GoalsIndexApiArg['sort']>('finish_date');
  const [status, setStatus] = useState<GoalsIndexApiArg['status']>(params.status || 'new');
  const [displayModeLS, setDisplayModeLS] = useLocalStorage('goalListDisplay', display);
  const Filters = <GoalFiltersMenu onChange={setSort} selected={sort} />;

  const finalParams = useMemo(() => ({sort, status, ...params}), [sort, status, params]);
  const showFilters = action || !disableViewSelect || !disableSort;
  const displayMode = disableViewSelect ? display : displayModeLS;

  return (
    <div>
      <Card>
        {showFilters && (
          <CardContent sx={{py: 1}}>
            <Stack direction="row" justifyContent="space-between">
              <Stack direction="row" spacing={1}>
                {!disableViewSelect && <GridListControl onChange={setDisplayModeLS as any} value={displayModeLS} />}
                {action && !disableSort && Filters}
              </Stack>
              {action || (!disableSort && Filters)}
            </Stack>
          </CardContent>
        )}
        {!disableStatusSelect && (
          <Tabs
            value={status}
            onChange={(e, value) => setStatus(value)}
            scrollButtons={false}
            variant="scrollable"
            sx={{
              '& .MuiTab-root': {'&:not(:last-child)': {mr: 2}, px: 0, minWidth: 24},
            }}
          >
            <Tab label="Активные" value="new" />
            <Tab label="Выполненные" value="completed" />
            <Tab label="Архивные" value="archived" />
          </Tabs>
        )}
      </Card>
      {displayMode === 'grid' && (
        <GoalsGridView params={finalParams} emptyListProps={emptyListProps} onGoalClick={onGoalClick} />
      )}
      {displayMode === 'list' && (
        <GoalsListView
          params={finalParams}
          emptyListProps={emptyListProps}
          onGoalClick={onGoalClick}
          showContentIfDeleted={showContentIfDeleted}
        />
      )}
    </div>
  );
};

export default GoalList;
