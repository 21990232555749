import {useToggle} from 'react-use';
import {PropsWithChildren, useEffect} from 'react';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Collapse from '@mui/material/Collapse';
import Typography from '@mui/material/Typography';

import {QuestPointStatus} from 'modules/oldQuests2/components';
import {ReactComponent as ChevronRight} from 'svg/chevrons/chevronRight.svg';
import {CollapseBlockProps} from './types';

export const CollapseBlock = ({
  children,
  name,
  index,
  locked,
  completed,
  initialState = false,
  state,
}: PropsWithChildren<CollapseBlockProps>) => {
  const [collapsed, setCollapsed] = useToggle(!initialState);
  const clickHandler = !locked ? setCollapsed : undefined;
  useEffect(() => {
    if (typeof state !== 'undefined' && state) setCollapsed(false);
  }, [state, setCollapsed]);

  return (
    <>
      <Box onClick={clickHandler} sx={{p: 2, backgroundColor: 'white', cursor: !locked ? 'pointer' : 'not-allowed'}}>
        <Stack spacing={2} direction="row" sx={{justifyContent: 'space-between'}}>
          <Stack spacing={2} direction="row">
            <QuestPointStatus index={index + 1} locked={locked} status={completed ? 'completed' : undefined} />
            <Typography variant="h3" component="h2">
              {name}
            </Typography>
          </Stack>
          <Box
            component={ChevronRight}
            sx={{
              width: 24,
              height: 24,
              flexShrink: 0,
              transform: `rotate(${(collapsed ? -1 : 1) * 90}deg)`,
            }}
          />
        </Stack>
      </Box>
      <Collapse in={!collapsed}>{children}</Collapse>
    </>
  );
};
