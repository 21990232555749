import {useCallback} from 'react';
import Box from '@mui/material/Box';
import LoadingButton from '@mui/lab/LoadingButton';

import {api} from 'api';
import {Quests, QuestsIndexApiArg} from 'api/generated/users-api';

import {Quest} from 'modules/oldQuests2/containers';
import {InfiniteList, InfiniteListHeader} from 'components/InfiniteLoader';

export const QuestsList = ({params, title}: {params: Pick<QuestsIndexApiArg, 'type'>; title: string}) => {
  const [getQuests, result] = api.endpoints.questsIndex.useLazyQuery();
  const load = useCallback(
    ({limit, offset}: {limit: number; offset: number}) => {
      getQuests({...params, limit, offset});
    },
    [params, getQuests]
  );

  return (
    <InfiniteList<Quests>
      mx={1}
      spacing={-0.25}
      fetchFn={load}
      limit={10}
      result={result}
      disableWaypoint
      disableEmptyList
      fetchOnMount
      Header={({total}) => (!!total ? <InfiniteListHeader title={title} count={total} /> : null)}
      Footer={({total, currentCount, limit, loadMore, isFetching, itemsLeft}) => {
        if (!total || !itemsLeft) return null;
        return (
          <Box display="flex" py={1} justifyContent="center" sx={{backgroundColor: 'white'}}>
            <LoadingButton
              sx={{mb: 2}}
              size="small"
              color="secondary"
              variant="contained"
              loading={isFetching}
              disabled={isFetching}
              onClick={loadMore}
            >
              Показать еще {total - currentCount > limit ? limit : total - currentCount}
            </LoadingButton>
          </Box>
        );
      }}
    >
      {(quest: Quests) => <Quest key={quest._id} quest={quest} />}
    </InfiniteList>
  );
};
