import {Stack} from '@mui/material';
import {GoalListBlockSkeleton} from '../../Goal/components';
import {GroupHeaderSkeleton} from '../components';

export const GroupGoalsPageSkeleton = () => (
  <Stack spacing={0.5}>
    <GroupHeaderSkeleton />
    <GoalListBlockSkeleton />
  </Stack>
);
