import {useEffect, useState} from 'react';
import {LazyLoadImage} from 'react-lazy-load-image-component';
import {skipToken} from '@reduxjs/toolkit/query';

import {Stack, Button, Box} from '@mui/material';

import {api, Payments} from 'api/generated/users-api';
import {croppedImagePath} from 'utils';

import {StagePointProps} from 'modules/oldQuests2/types';
import {WidgetBoxWithLoader} from 'components/EntityWidgets';

import {ReactComponent as CheckIcon} from 'svg/check.svg';

import {useClearReference, usePaymentCreateAndRegisterAction, useRegisterPaymentAction} from './hooks';
import {StyledFormControlLabel, StyledSwitch} from 'modules/goals/containers/GoalReportForm/styles';

export const ChosePaymentService = ({
  stagePoint,
  questPoint: point,
  payment,
}: Omit<StagePointProps, 'children' | 'lockedReason'> & {payment?: Payments}) => {
  const {payments} = point.questPoint.options;
  const [processing, setProcessing] = useState<boolean>(false);
  const [savePaymentData, setSavePaymentData] = useState<boolean>(false);
  const [selected, setSelected] = useState<string | undefined>(undefined);
  const paymentFinished = payment && ['paid', 'returned'].includes(payment?.status);

  const setPaymentDataValue = !payments?.savePaymentData ? savePaymentData : payments.savePaymentData;
  const {clearReference, isLoading: referenceClearing} = useClearReference(stagePoint?._id, stagePoint?.referenceId);
  const registerAction = useRegisterPaymentAction(stagePoint?._id, setPaymentDataValue);
  const createAndRegisterAction = usePaymentCreateAndRegisterAction(stagePoint?._id, setPaymentDataValue);

  const {isLoading: paymentRefreshing} = api.endpoints.paymentRefresh.useQueryState(
    payment?._id ? {paymentId: payment._id} : skipToken
  );
  const {data: paymentsServices, isLoading: paymentsListLoading} = api.endpoints.paymentsServicesIndex.useQuery(
    payments?.paymentServicesTypes ? {type: payments.paymentServicesTypes} : skipToken,
    {
      selectFromResult: ({data, ...rest}) => {
        const result = data?.data || [];
        return {
          ...rest,
          data: payment ? result.filter(item => item._id === payment?.paymentServiceId) : result,
        };
      },
    }
  );

  useEffect(() => {
    setSelected(payment?.paymentServiceId);
  }, [payment?.paymentServiceId, paymentsServices]);

  const onClick = async () => {
    setProcessing(true);
    if (paymentsServices?.length) {
      if (!payment) {
        if (selected) await createAndRegisterAction(selected);
      } else {
        if (selected && (selected !== payment.paymentServiceId || ['declined', 'error'].includes(payment.status))) {
          if (clearReference) await clearReference();
          await createAndRegisterAction(selected);
        } else if (payment?.result?.paymentUrl) window.open(payment.result.paymentUrl, '_self');
        else await registerAction(`${payment._id}`);
      }
    }
    setProcessing(false);
  };

  const isLoading = referenceClearing || paymentsListLoading || processing || paymentRefreshing;
  const buttonDisabled = (!selected && !payment) || isLoading;

  return (
    <WidgetBoxWithLoader p={0} width="100%" overflow="hidden" component={Stack} loading={isLoading}>
      {!paymentsListLoading &&
        (!payment || !paymentFinished) &&
        paymentsServices.map(i => {
          const isSelected = selected === i._id;
          return (
            <Stack
              p={1.5}
              key={i._id}
              height={48}
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              sx={{
                cursor: 'pointer',
                transition: 'background-color .15s',
                '&:hover': {bgcolor: 'primary.10'},
                '&:not(:last-child)': {
                  borderBottom: 'solid 1px',
                  borderBottomColor: 'grey.10',
                },
              }}
              onClick={() => setSelected(`${i._id}`)}
            >
              <Stack justifyContent="center">
                {!i.fileId ? (
                  i.name
                ) : (
                  <LazyLoadImage
                    height={24}
                    alt={i.name}
                    effect="blur"
                    src={croppedImagePath(i.fileId, {ratio: '1:1', maxWidth: 150})}
                  />
                )}
              </Stack>
              <Box
                width={24}
                height={24}
                borderRadius="50%"
                border="1px solid"
                component={CheckIcon}
                sx={{transition: 'all .3s'}}
                stroke={isSelected ? 'white' : 'transparent'}
                borderColor={isSelected ? 'transparent' : 'grey.25'}
                bgcolor={isSelected ? 'primary.500' : 'transparent'}
              />
            </Stack>
          );
        })}
      {!paymentsListLoading && !payments?.savePaymentData && (
        <StyledFormControlLabel
          label="Сохранить платежные данные"
          sx={{ml: 1.5, gap: 1.5}}
          control={
            <StyledSwitch
              checked={savePaymentData}
              inputProps={{'aria-label': 'controlled'}}
              onChange={e => setSavePaymentData(e.target.checked)}
            />
          }
        />
      )}
      {!paymentsListLoading && clearReference && !paymentFinished && (
        <Button
          color="secondary"
          variant="contained"
          onClick={clearReference}
          disabled={buttonDisabled}
          sx={{borderRadius: 0}}
        >
          Сменить способ оплаты
        </Button>
      )}
      {!paymentsListLoading && (
        <Button variant="contained" onClick={onClick} disabled={buttonDisabled} sx={{borderRadius: '0 0 8px 8px'}}>
          Оплатить
          {/* {payment?.status && ['error', 'declined'].includes(payment.status) ? 'Попробовать снова' : 'Оплатить'} */}
        </Button>
      )}
    </WidgetBoxWithLoader>
  );
};
