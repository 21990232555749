import {PropsWithChildren} from 'react';
import {useNavigate} from 'react-router';

import {Box} from '@mui/system';
import {Close} from '@mui/icons-material';
import {Dialog, DialogContent, IconButton} from '@mui/material';

import {useIsTablet} from 'utils';
import {Header} from 'components/Layout';

export const AuthInnerLayout = ({children, backClick}: PropsWithChildren<{backClick?: () => void}>) => {
  const navigate = useNavigate();
  const isTablet = useIsTablet();

  return (
    <Dialog
      open
      sx={{height: '100%'}}
      fullWidth
      fullScreen={isTablet}
      maxWidth="sm"
      PaperProps={!isTablet ? {sx: {maxHeight: '90vh', minHeight: '90vh'}} : {}}
    >
      <Header
        title=" "
        backLink={backClick}
        actions={
          <IconButton size="small" onClick={() => navigate('/welcome')}>
            <Close />
          </IconButton>
        }
      />
      <DialogContent
        sx={{
          margin: '0 auto',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'flex-start',
          height: '100%',
          width: '100%',
          maxWidth: 360,
          p: 2,
          pt: isTablet ? 7 : 9,
        }}
      >
        <Box width="100%">{children}</Box>
      </DialogContent>
    </Dialog>
  );
};

export default AuthInnerLayout;
