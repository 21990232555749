import ButtonMenu from 'components/ButtonMenu';

import {ReactComponent as SortAsc} from 'svg/icons/sortAsc.svg';
import {ReactComponent as SortDesc} from 'svg/icons/sortDesc.svg';
import {ReactComponent as SortIcon} from 'svg/goalSort/sortIcon.svg';

export const GoalReportFiltersMenu = ({onChange, selected}: {onChange: (newValue: any) => void; selected: string}) => (
  <ButtonMenu
    buttonProps={{
      icon: <SortIcon />,
      variant: 'contained',
      color: 'secondary',
      size: 'small',
    }}
    items={[
      {
        label: 'Сначала новые',
        icon: <SortAsc />,
        onClick: () => onChange('newest_first'),
        selected: selected === 'newest_first',
      },
      {
        label: 'Сначала старые',
        icon: <SortDesc />,
        onClick: () => onChange('oldest_first'),
        selected: selected === 'oldest_first',
      },
    ]}
  />
);
