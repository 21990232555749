import {ReactNode} from 'react';
import {Card, Box, Typography} from '@mui/material';
import {GREY} from 'components/theme/palette';
import {SxProps} from '@mui/system';

export type EmptyListProps = {
  text: string | ReactNode;
  title?: string;
  pic?: ReactNode;
  action?: ReactNode;
  sx?: SxProps;
};

export const EmptyList = ({action, text, pic, title, sx}: EmptyListProps) => {
  return (
    <Card
      sx={{
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        minHeight: 120,
        p: 2,
        ...sx,
      }}
    >
      {pic}
      {title && (
        <Typography variant="h1" align="center" width="80%" mt={2}>
          {title}
        </Typography>
      )}
      <Typography variant="body2" color={GREY[200]} align="center" width="80%" mt={title ? 1.5 : 1}>
        {text}
      </Typography>
      {action && <Box mt={2}>{action}</Box>}
    </Card>
  );
};

export default EmptyList;
