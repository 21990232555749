import * as yup from 'yup';
import {PublicationDto} from 'api/generated/users-api';
import {FormObject, privacyOptions} from 'components/FormFactory';
import {yupFilesRequired, yupPrivacy, yupRatio, yupStringRequired} from 'utils';

export const fields: FormObject<PublicationDto>[] = [
  {
    type: 'photo',
    name: 'filesId',
    initialValue: [],
    inputProps: {
      label: 'Картинка',
      multiple: true,
      ratioName: 'filesAspectRatio',
    },
  },
  {
    type: 'text',
    name: 'content',
    initialValue: '',
    inputProps: {
      label: 'Напишите свой инсайт, свои мысли',
      minRows: 10,
    },
  },
  {
    type: 'select',
    name: 'privacy',
    initialValue: 'public',
    inputProps: {
      label: 'Видимость поста',
      options: privacyOptions('masculine'),
    },
  },
];

export const initialValues: PublicationDto = {
  content: '',
  type: 'default',
  filesId: [],
  filesAspectRatio: '1:1',
  privacy: 'public',
  enabled: true,
};

export const validationSchema = yup.object().shape({
  content: yupStringRequired,
  filesId: yupFilesRequired,
  enabled: yup.boolean(),
  privacy: yupPrivacy,
  filesAspectRatio: yupRatio,
});
