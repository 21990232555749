import Box from '@mui/material/Box';
import {Divider, Stack, Tooltip, Typography} from '@mui/material';

import {getLockedReason} from 'modules/oldQuests2/utils';
import {QuestPointViewProps} from 'modules/oldQuests2/types';
import {WidgetImage} from 'components/EntityWidgets';

export const SubQuestPointView = ({
  name,
  fileId,
  locked,
  status,
  subtitle,
  additionalContent,
  lockedReason,
  onClick,
  Content: PointCustomContent,
}: QuestPointViewProps) => {
  const reason = getLockedReason(lockedReason);
  const Content = (
    <div>
      <Stack
        py={1}
        px={2}
        spacing={1.5}
        direction="row"
        alignItems="top"
        sx={{
          backgroundColor: 'transparent',
          transition: 'background-color .25s',
          cursor: onClick ? 'pointer' : locked ? 'default' : undefined,
          '&:hover': {
            backgroundColor: locked ? 'warning.lighter' : 'secondary.main',
          },
        }}
        onClick={onClick}
      >
        <WidgetImage fileId={fileId} alt={name} size="small" statusProps={{status: locked ? 'locked' : status}} />
        <Stack flexGrow={1} minHeight={48} justifyContent="center" position="relative">
          {PointCustomContent || (
            <>
              <Typography variant="body2" lineHeight="18px">
                {name}
              </Typography>
              {subtitle && subtitle.length && (
                <Typography variant="subtitle1" color="grey.200" mt={0.25}>
                  {subtitle.join(', ')}
                </Typography>
              )}
            </>
          )}
          {additionalContent && additionalContent.element}
          <Box position="absolute" bottom={-8} width={1}>
            <Divider />
          </Box>
        </Stack>
      </Stack>
      {additionalContent && additionalContent.bottomElement}
    </div>
  );

  if (!locked) return Content;
  return (
    <Tooltip
      arrow
      placement="top"
      enterTouchDelay={10}
      leaveTouchDelay={1500}
      title={reason || 'Недоступно'}
      sx={{'& .MuiTooltip-tooltip': {textAlign: 'center'}}}
    >
      {Content}
    </Tooltip>
  );
};
