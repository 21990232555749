import {CircularProgress} from '@mui/material';
import Box from '@mui/material/Box';
import {WidgetBoxProps} from '../types';

export const WidgetBox: React.FC<WidgetBoxProps> = ({children, p = 1.5, noShadow, sx, onClick, ...props}) => (
  <Box
    p={p}
    flexGrow={1}
    borderRadius={2}
    onClick={onClick}
    boxShadow={!noShadow ? theme => theme.customShadows.default : undefined}
    sx={{
      backgroundColor: 'white',
      cursor: onClick ? 'pointer' : 'default',
      ...sx,
    }}
    {...props}
  >
    {children}
  </Box>
);

export const WidgetBoxWithLoader: React.FC<WidgetBoxProps & {loading: boolean}> = ({loading, children, ...props}) => (
  <WidgetBox {...props} position="relative">
    {children}
    {loading && (
      <Box
        top={0}
        left={0}
        width={1}
        height={1}
        display="flex"
        position="absolute"
        alignItems="center"
        justifyContent="center"
        bgcolor="rgba(255, 255, 255, 0.7)"
      >
        <CircularProgress size={32} />
      </Box>
    )}
  </WidgetBox>
);
