import _ from 'lodash/fp';
import {Provider} from 'jotai';

import {useForm, FormProvider} from 'react-hook-form';

import {Card, Box, Typography, CardContent, CircularProgress} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';

import {api} from 'api';
import {ScriptsActions, ScriptsGroups} from 'api/generated/users-api';
import {useJobScriptForm, usePatchAnswers} from 'modules/tasks/containers/JobScript/hooks';

import {JobAction} from './JobAction';
import {optionsAton, patchAnswersAtom, scriptAnswersAtom} from './atoms';

const getActions = (groupsIds: string[]) =>
  _.compose(
    _.map(_.pick(['_id', 'scriptGroupId', 'positionNumber'])),
    _.filter(_.compose(_.includes(groupsIds), _.get('scriptGroupId')))
  );

const JobScriptForm = ({
  jobId,
  scriptId,
  actions,
  groups,
  onAfterSave,
}: {
  scriptId: string;
  jobId: string;
  groups: ScriptsGroups[];
  actions: Record<string, Required<Pick<ScriptsActions, '_id' | 'scriptGroupId'>>[]>;
  onAfterSave?: () => void;
}) => {
  const methods = useForm({});
  const {setError} = methods;
  const {onSubmit, isLoading, globalMessage} = useJobScriptForm(jobId, scriptId, setError, onAfterSave);

  return (
    <FormProvider {...methods}>
      <form autoComplete="off">
        {groups.map(group => (
          <Card key={group._id}>
            <CardContent>
              {(actions[`${group._id}`] || []).map(item => (
                <JobAction key={item._id} actionId={item._id} scriptGroupId={item.scriptGroupId} jobId={jobId} />
              ))}
            </CardContent>
          </Card>
        ))}
        <Card sx={{p: 2}}>
          <LoadingButton variant="contained" fullWidth size="medium" onClick={() => onSubmit()} loading={isLoading}>
            Сохранить
          </LoadingButton>
          {globalMessage && (
            <Typography variant="subtitle1" sx={{color: 'grey.200', mt: 1}}>
              {globalMessage}
            </Typography>
          )}
        </Card>
      </form>
    </FormProvider>
  );
};

export const JobScript = ({
  scriptId,
  jobId,
  onAfterSave,
}: {
  jobId: string;
  scriptId: string;
  onAfterSave?: () => void;
}) => {
  const {
    answersByScriptId,
    isSuccess: isAnswersFetched,
    isFetching,
  } = api.endpoints.jobAnswersGetAnswers.useQuery(
    {jobId, scriptId},
    {
      refetchOnMountOrArgChange: true,
      selectFromResult: ({data, ...rest}) => ({
        ...rest,
        answersByScriptId: _.compose(
          _.fromPairs,
          _.map(item => [item[0], _.map(_.pick(['templateOptionId', 'index', 'value']), item[1])]),
          _.toPairs,
          _.groupBy('scriptActionId')
        )(data || []) as any,
      }),
    }
  );

  const {data} = api.endpoints.jobStructure.useQuery(
    {jobId},
    {
      selectFromResult: ({data, ...rest}) => {
        if (!data || !data.structure) return {data: null, ...rest};
        const {scriptsGroups, scriptsActions, templatesOptions} = data.structure;
        const groups = _.filter(_.compose(_.equals(scriptId), _.get('scriptId')))(scriptsGroups) as ScriptsGroups[];
        const actions = _.compose(
          _.groupBy('scriptGroupId'),
          _.sortBy('positionNumber'),
          getActions(_.map(_.get('_id'), groups))
        )(scriptsActions);

        return {
          data: {
            groups,
            templatesOptions,
            actions: actions as unknown as Record<string, Required<Pick<ScriptsActions, '_id' | 'scriptGroupId'>>[]>,
          },
          ...rest,
        };
      },
    }
  );
  const patchAnswers = usePatchAnswers(jobId, scriptId);

  if (!data || isFetching || !isAnswersFetched)
    return (
      <Box px={2} py={2} justifyContent="center">
        <CircularProgress />
      </Box>
    );

  return (
    <Provider
      initialValues={[
        [patchAnswersAtom, patchAnswers],
        [optionsAton, data.templatesOptions],
        [scriptAnswersAtom, answersByScriptId],
      ]}
    >
      <JobScriptForm
        jobId={jobId}
        scriptId={scriptId}
        groups={data.groups}
        actions={data.actions}
        onAfterSave={onAfterSave}
      />
    </Provider>
  );
};
