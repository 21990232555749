import {Box, Card, CardContent, Skeleton} from '@mui/material';
import {ContentContentSkeleton} from '../components';

export const ContentPageSkeleton = () => (
  <Card>
    <CardContent>
      <Box mb={4}>
        <Skeleton variant="text" animation="wave" width="100%" height={40} sx={{transform: 'none'}} />
      </Box>
      <ContentContentSkeleton />
    </CardContent>
  </Card>
);
