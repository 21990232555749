import {Button} from '@mui/material';
import {EmptyList} from 'components/EmptyList';
import {useGoalForm} from 'modules/goals/containers';
import {ReactComponent as GoalPic} from 'svg/pics/goalPic.svg';

const texts = {
  me: '«Человек без цели подобен судну без руля». Поставьте свою первую цель прямо сейчас!',
  user: 'Пользователь еще не поставил ни одной цели',
};

export const EmptyGoalList = ({isMe}: {isMe?: boolean}) => {
  const openGoalForm = useGoalForm();
  if (!isMe || !openGoalForm) return <EmptyList text={texts.user} pic={<GoalPic />} />;
  return (
    <EmptyList
      text={texts.me}
      pic={<GoalPic />}
      action={
        <Button onClick={() => openGoalForm()} variant="contained" size="small" sx={{px: 3}}>
          Поставить цель
        </Button>
      }
    />
  );
};

export default EmptyGoalList;
