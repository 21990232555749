import {styled} from '@mui/system';
import {ToggleButton, ToggleButtonGroup} from '@mui/material';
import {PRIMARY} from 'components/theme/palette';

export const Group = styled(ToggleButtonGroup)`
  &.MuiToggleButtonGroup-root {
    background-color: ${PRIMARY[10]};
    height: 32px;
    border-radius: 6px;
  }

  & .MuiToggleButtonGroup-grouped {
    margin: ${({theme}) => theme.spacing(0.25)};
    border: none;
    &.mui-disabled {
      border: none;
    }
    &:not(:first-of-type) {
      border-radius: 6px;
    }
    &:first-of-type {
      border-radius: 6px;
    }
  }
  & .MuiToggleButton-root {
    font-size: 16px;
    line-height: 20px;
  }
`;

export const Button = styled(ToggleButton)`
  &.Mui-selected {
    background-color: white;
    &:hover {
      background-color: white;
    }
  }
`;
