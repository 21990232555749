import {Skeleton, Stack, Card} from '@mui/material';

export const PublicationSkeleton = () => (
  <Card>
    <Stack direction="row" alignItems="center" px={2} py={1} spacing={1.5}>
      <Skeleton variant="circular" animation="wave" width={40} height={40} />
      <Skeleton variant="text" animation="wave" width={160} height={18} sx={{transform: 'none'}} />
    </Stack>
    <Skeleton variant="rectangular" animation="wave" width={600} height={600} />
    <Stack direction="row" justifyContent="space-between" p={2}>
      <Stack direction="row" alignItems="center" spacing={1}>
        <Skeleton variant="rectangular" animation="wave" width={56} height={26} sx={{borderRadius: 12}} />
        <Skeleton variant="rectangular" animation="wave" width={56} height={26} sx={{borderRadius: 12}} />
      </Stack>
      <Skeleton variant="rectangular" animation="wave" width={120} height={26} sx={{borderRadius: 12}} />
    </Stack>
    <Stack px={2} pb={2} spacing={1}>
      <Skeleton variant="text" animation="wave" width="100%" height={14} sx={{transform: 'none'}} />
      <Skeleton variant="text" animation="wave" width="100%" height={14} sx={{transform: 'none'}} />
      <Skeleton variant="text" animation="wave" width="50%" height={14} sx={{transform: 'none'}} />
    </Stack>
  </Card>
);
