import {lazy, Suspense} from 'react';
import {skipToken} from '@reduxjs/toolkit/dist/query';
import {Navigate, RouteObject, useParams} from 'react-router';

import {api} from 'api';
import {GoalContext} from 'modules/profile/context';

import {GoalPageSkeleton} from 'components/Skeleton';
import IsExistsContainer from 'components/IsExistsContainer';

const GoalPageIndex = lazy(() => import('../goals/pages/GoalPage'));

const useGetGoal = () => {
  const {goalId} = useParams<'goalId'>();
  return api.endpoints.goalIndex.useQuery(goalId ? {goalId} : skipToken);
};

export const router: RouteObject = {
  path: '/goals',
  children: [
    {
      index: true,
      element: <Navigate to="/" />,
    },
    {
      path: ':goalId',
      element: (
        <IsExistsContainer
          useFetch={useGetGoal}
          context={GoalContext}
          LoadingComponent={<GoalPageSkeleton />}
          errorMessage={{403: 'Доступ к цели ограничен настройками приватности'}}
        />
      ),
      children: [
        {
          index: true,
          element: (
            <Suspense fallback={<GoalPageSkeleton />}>
              <GoalPageIndex />
            </Suspense>
          ),
        },
      ],
    },
  ],
};

export default router;
