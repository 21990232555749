import {Box, Skeleton, Stack} from '@mui/material';

export const ContentPreviewSkeleton = () => (
  <Box bgcolor="white">
    <Skeleton variant="rectangular" animation="wave" width={600} height={337.5} />
    <Box p={2} mt={2}>
      <Skeleton variant="text" animation="wave" width="100%" height={18} sx={{transform: 'none'}} />
    </Box>

    <Stack px={2} spacing={1}>
      <Skeleton variant="text" animation="wave" width="100%" height={14} sx={{transform: 'none'}} />
      <Skeleton variant="text" animation="wave" width="100%" height={14} sx={{transform: 'none'}} />
      <Skeleton variant="text" animation="wave" width="50%" height={14} sx={{transform: 'none'}} />
    </Stack>

    <Stack direction="row" alignItems="center" justifyContent="space-between" p={2}>
      <Stack direction="row" alignItems="center" spacing={0.5}>
        <Skeleton variant="rectangular" animation="wave" width={88} height={16} sx={{borderRadius: 12}} />
        <Skeleton variant="rectangular" animation="wave" width={88} height={16} sx={{borderRadius: 12}} />
      </Stack>
      <Skeleton variant="rectangular" animation="wave" width={40} height={16} sx={{borderRadius: 12}} />
    </Stack>
  </Box>
);
