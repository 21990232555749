import {LessonStagePoint} from './LessonStagePoint';
import {ButtonStagePoint} from './ButtonStagePoint';
import {WidgetStagePoint} from './WidgetStagePoint';
import {DefaultStagePoint} from './DefaultStagePoint';
import {TopButtonStagePoint} from './TopButtonStagePoint';
import {ThumbnailStagePoint} from './ThumbnailStagePoint';
import {StageInlinePreviewStagePoint} from './StageInlinePreviewStagePoint';

export const stagePointComponentsMap = {
  ButtonStagePoint,
  LessonStagePoint,
  WidgetStagePoint,
  DefaultStagePoint,
  ThumbnailStagePoint,
  TopButtonStagePoint,
  StageInlinePreviewStagePoint,
};
