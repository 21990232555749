import {uniqBy} from 'lodash';
import {formatDate} from 'utils/dates';
import {TimelineCheckReason} from 'api/generated/users-api';

export const questsPath = `/modules`;
export const getQuestPath = (questId?: string) => `${questsPath}/view/${questId}`;
export const getActiveQuestPath = (stageId?: string, postfix: string = '') => `${questsPath}/${stageId}${postfix}`;

export const getLockedReason = (reasons?: TimelineCheckReason[]) => {
  if (!reasons || !reasons.length) return null;
  return uniqBy([...reasons].reverse(), item => item.requirement)
    .map(item => {
      if (item.requirement === 'dateStart') return `Станет доступно ${formatDate(item.value, 'dd MMMM')}`;
      if (item.requirement === 'quests') return `Станет доступно после выполнения блока «${item.value}»`;
      if (item.requirement === 'questPoints') return `Станет доступно после выполнения задания «${item.value}»`;
      return null;
    })
    .filter(Boolean)
    .join(', ');
};
