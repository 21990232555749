import {useEffect, useRef} from 'react';
import {useLocation} from 'react-router-dom';
import {useAtomValue, useUpdateAtom} from 'jotai/utils';

import {closeNotificationDialog, notificationDialogState} from 'modules/notifications/store';
import {NotificationsDialogPreview, NotificationsDialogFull} from 'modules/notifications/components';

export const NotificationPreviewDialog = () => {
  const location = useLocation();
  const prevLocation = useRef<string | undefined>();
  const opened = useAtomValue(notificationDialogState);
  const close = useUpdateAtom(closeNotificationDialog);

  useEffect(() => {
    if (opened) {
      if (prevLocation.current && location.pathname !== prevLocation.current) {
        close();
        prevLocation.current = undefined;
      } else prevLocation.current = location.pathname;
    }
  }, [close, opened, location]);

  if (!opened) return null;
  if (opened === 'preview') return <NotificationsDialogPreview />;
  return <NotificationsDialogFull />;
};
