import {Theme} from '@mui/material/styles';

// ----------------------------------------------------------------------

export default function Card(theme: Theme) {
  return {
    MuiCard: {
      defaultProps: {
        elevation: 0,
      },
      styleOverrides: {
        root: {
          borderRadius: 0,
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: theme.spacing(1, 2),
          '&:last-child': {
            paddingBottom: theme.spacing(1),
          },
        },
      },
    },
    MuiCardHeader: {
      defaultProps: {
        titleTypographyProps: {variant: 'h3'},
      },
      styleOverrides: {
        root: {
          padding: theme.spacing(1, 2),
        },
        avatar: {
          marginRight: theme.spacing(1.5),
        },
        title: {
          // lineHeight: '32px',
        },
        subheader: {},
        action: {
          margin: theme.spacing(0),
        },
      },
    },
  };
}
