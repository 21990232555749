import {useMeasure} from 'react-use';

import Box from '@mui/system/Box';
import Stack from '@mui/material/Stack';
import Rating from '@mui/material/Rating';
import Typography from '@mui/material/Typography';

import {ReactComponent as StarEmpty} from 'svg/rating/star.svg';
import {ReactComponent as StarFilled} from 'svg/rating/starFilled.svg';

export type RatingBarProps = {
  max?: number;
  value?: number;
  size?: 'small' | 'medium';
  onChange?: (newScore?: number) => void;
};

const sizes = {
  small: 25,
  medium: 32,
};

export const RatingBar = ({max = 10, value, onChange, size = 'small'}: RatingBarProps) => {
  const [ref, {width}] = useMeasure();
  const dimension = width / max > sizes[size] ? sizes[size] : width / max;

  return (
    <Box ref={ref}>
      <Rating
        max={max}
        precision={1}
        value={value || null}
        icon={<Box component={StarFilled} width={dimension} sx={{color: 'warning.light'}} height={dimension} />}
        emptyIcon={<Box component={StarEmpty} width={dimension} height={dimension} />}
        onChange={onChange ? (_, newScore) => onChange(newScore || undefined) : undefined}
      />
    </Box>
  );
};

export const RatingBarWithLabel = ({
  label,
  labelVariant = 'body1',
  ...props
}: RatingBarProps & {label: string; labelVariant?: 'body1' | 'subtitle1'}) => (
  <Stack spacing={1}>
    <Typography variant={labelVariant} sx={{color: 'grey.200', lineHeight: '24px'}}>
      {label}
    </Typography>
    <RatingBar {...props} />
  </Stack>
);
