import TextField from '@mui/material/TextField';

import {memo} from 'react';
import NumberFormat from 'react-number-format';

import * as Styled from './styled';
import {AnswerProps} from '../types';
import {useSingleFieldProps} from './hooks';

const DELIMETER = ' ';

const Field = (params: any) => <TextField {...params} fullWidth size="small" variant="standard" />;

export const NumberInput = memo((params: AnswerProps) => {
  const {option, field, setData} = useSingleFieldProps(params);

  if (!option) return null;
  return (
    <Styled.TextField>
      <NumberFormat
        {...field}
        type="text"
        pattern="[0-9]*"
        inputMode="numeric"
        label={option.name}
        placeholder={option.description}
        allowNegative={false}
        thousandSeparator={DELIMETER}
        onValueChange={({floatValue}) => setData(`${floatValue}`)}
        customInput={Field}
      />
    </Styled.TextField>
  );
});
