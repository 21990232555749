import {useContext} from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import {skipToken} from '@reduxjs/toolkit/dist/query';

import {api} from 'api';
import {getUserLink} from 'modules/profile/utils';
import {Stage} from 'modules/quests/containers/Stage';
import {ProfileContext} from 'modules/profile/context';
import {CardLinkHeader} from 'components/CardLinkHeader';

export const ProfileQuestWidget = () => {
  const profile = useContext(ProfileContext);
  const defaultAdventureId = (profile?.account?.stats?.streams?.[0] as any)?.defaultAdventureId;
  const {stageId, title} = api.endpoints.stagesIndex.useQuery(
    profile?.account._id && defaultAdventureId
      ? {questType: 'adventure', populates: ['quest'], usersId: [profile?.account._id], questId: defaultAdventureId}
      : skipToken,
    {
      selectFromResult: ({data, isLoading}) => {
        const stage = data?.data ? data.data[0] : undefined;
        if (!stage) return {stageId: undefined, isLoading};
        return {stageId: stage._id, title: stage.quest?.design?.header || stage.quest?.name || '', isLoading};
      },
    }
  );

  if (!profile || !stageId || !title) return null;
  return (
    <Box component={Card} pb={1}>
      <CardLinkHeader title={title} to={getUserLink(profile.account, 'modules')} />
      <Stage stageId={stageId} componentName="HorizontalPreviewStageVisual" hideUnavailablePoints />
    </Box>
  );
};
