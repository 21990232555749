import {Box} from '@mui/material';
// import {StyleSheet, View} from 'react-native';
// import {BORDER_COLOR} from 'src/components/common';
import {VisualComponent} from '../../types';

export type DelimeterComponentProps = {};

export const DelimeterComponent: VisualComponent<DelimeterComponentProps> = () => (
  <Box width={1} height="1px" bgcolor="grey" />
);
