import Box from '@mui/system/Box';
import LoadingButton from '@mui/lab/LoadingButton';
import {Link} from 'react-router-dom';

import Stack from '@mui/material/Stack';
import MUILink from '@mui/material/Link';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import {api} from 'api';
import {Users} from 'api/generated/users-api';
import {ReactComponent as EditIcon} from 'svg/icons/editIcon.svg';

import {useIsMe} from 'modules/auth';
import {CollectorWidget} from 'modules/dataCollectors';
import {relationStatusText} from 'modules/profile/utils';

const SubscribeButton = ({userId, isSubscribed}: {userId: string; isSubscribed: boolean}) => {
  const [toggleSubscription, {isLoading: subscribing}] = api.endpoints.userToggleSubscription.useMutation();
  const onClick = () => toggleSubscription({userId});

  return (
    <LoadingButton
      loading={subscribing}
      variant="contained"
      size="small"
      color={isSubscribed ? 'secondary' : 'primary'}
      onClick={onClick}
    >
      {isSubscribed ? 'Подписаны' : 'Подписаться'}
    </LoadingButton>
  );
};

export const ProfileInfo = ({user, isSubscribed}: {user: Users; isSubscribed: boolean}) => {
  const isMe = useIsMe(user);

  return (
    <Stack spacing={1}>
      {user.description && (
        <Typography variant="body2" sx={{whiteSpace: 'pre-wrap'}}>
          {user.description}
        </Typography>
      )}
      {(user.city || user.relationStatus) && (
        <Typography variant="body2" sx={{whiteSpace: 'pre-wrap'}}>
          {[user.city, user.relationStatus ? relationStatusText(user.gender)[user.relationStatus] : undefined]
            .filter(Boolean)
            .join(', ')}
        </Typography>
      )}
      {!user.description && isMe && (
        <Typography
          variant="body2"
          to="/settings"
          component={Link}
          sx={{
            color: 'grey.200',
            gap: 0.5,
            display: 'flex',
            alignItems: 'center',
            '&:hover': {color: 'grey.400', transition: '0.25s color'},
          }}
        >
          Напишите пару строк о себе
          <Box component={EditIcon} width={30} height={30} sx={{color: 'grey.200'}} />
        </Typography>
      )}
      {user.externalLink && (
        <MUILink
          variant="body2"
          target="_blank"
          underline="hover"
          sx={{color: 'primary.500', height: 20, overflow: 'hidden', textOverflow: 'ellipsis'}}
          href={!/^https?:\/\//i.test(user.externalLink) ? `https://${user.externalLink}` : user.externalLink}
        >
          {user.externalLink}
        </MUILink>
      )}
      <CollectorWidget code="first" userId={`${user._id}`} />
      <Box display="flex" justifyContent="space-between" gap={1}>
        <Box flexGrow={1}>
          <Button variant="contained" color="secondary" size="small" fullWidth component={Link} to="./info">
            Посмотреть контракт
          </Button>
        </Box>
        {!isMe && <SubscribeButton isSubscribed={isSubscribed} userId={user._id as string} />}
      </Box>
    </Stack>
  );
};

export default ProfileInfo;
