import {StageIdProp} from 'modules/quests/types';
import {createContext, useContext, useCallback} from 'react';
import {DialogViewTypes} from './types';

export const DialogViewContext = createContext<
  ((type: DialogViewTypes, entryId: string, options?: Partial<StageIdProp>) => void) | undefined
>(undefined);

export const useDialogView = (type: DialogViewTypes, entryId?: string) => {
  const open = useContext(DialogViewContext);
  const openHandler = useCallback(
    (newEntryId?: string, options?: Partial<StageIdProp>) => {
      if (open) open(type, `${newEntryId || entryId}`, options);
    },
    [type, entryId, open]
  );
  if (open) return openHandler;
  return undefined;
};
