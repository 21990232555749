import {ReactNode} from 'react';
import * as Styled from './styled';

export type SegmentedControlProps = {
  value?: string | number;
  fullWidth?: boolean;
  onChange: (newValue: string | number | undefined) => void;
  buttons: {
    value: string | number;
    ariaLabel?: string;
    content: ReactNode | string;
  }[];
};

export const SegmentedControl = ({value, onChange, buttons, fullWidth = false}: SegmentedControlProps) => (
  <Styled.Group
    fullWidth={fullWidth}
    size="small"
    exclusive
    value={value}
    onChange={(e, newValue) => newValue && onChange(newValue)}
  >
    {buttons.map(button => (
      <Styled.Button key={button.value} value={button.value} aria-label={button.ariaLabel || ''}>
        {button.content}
      </Styled.Button>
    ))}
  </Styled.Group>
);

export default SegmentedControl;
