import {Box, styled} from '@mui/material';

export const HideScrollbar = styled(Box)`
  max-width: 100%;
  overflow-x: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;
