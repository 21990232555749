import {Skeleton, Stack} from '@mui/material';
import {GroupRequestsListSkeleton} from '../components';

export const GroupRequestsPageSkeleton = () => (
  <Stack>
    <Stack p={2} bgcolor="white" spacing={3} mb={0.5}>
      <Skeleton variant="rectangular" animation="wave" width="100%" height={40} sx={{borderRadius: 12}} />
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Skeleton variant="text" animation="wave" width="50%" height={16} sx={{transform: 'none'}} />
        <Skeleton variant="rectangular" animation="wave" width={36} height={24} sx={{borderRadius: 9}} />
      </Stack>
    </Stack>
    <GroupRequestsListSkeleton />
  </Stack>
);
