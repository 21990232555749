import {startsWith} from 'lodash';
import {useNavigate} from 'react-router';
import {LazyLoadImage} from 'react-lazy-load-image-component';

import Box from '@mui/system/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import {NotificationIndication} from 'components/Layout/NotificationIndication';
import {timeAgo} from 'utils/dates';
import {croppedImagePath} from 'utils';
import {
  getLikeText,
  getImage,
  getCommentText,
  getScoreText,
  getSubscriptionText,
  getCommentReplyText,
  getScoreReplyText,
  getLinkPath,
} from './utils';
import {Notifications, Users} from 'api/generated/users-api';
import {NotificationAuthoredMessage} from '../NotificationAuthoredMessage';
import {ReactComponent as ChevronRight} from 'svg/chevrons/chevronRight.svg';

export const NotificationItem = ({notification}: {notification: Notifications}) => {
  const navigate = useNavigate();

  if (
    !notification.type ||
    !['like', 'score', 'comment', 'score_reply', 'comment_reply', 'new_subscription'].includes(notification.type)
  )
    return null;

  const author = (notification.attachments || [])?.find(item => item.referenceName === 'Users')?.referenceId as
    | Users
    | undefined;
  if (!author) return null;
  // const attachments = (notification.attachments || []).filter(item => item.referenceName !== 'Users');
  const fileId = getImage(notification);
  let imageStyle = 'square';
  if (startsWith(notification.type, 'group_') || notification.type === 'new_subscription') imageStyle = 'round';

  let text = '';
  if (notification.type === 'score_reply') text = getScoreReplyText({author});
  if (notification.type === 'comment_reply') text = getCommentReplyText({author});
  if (notification.type === 'new_subscription') text = getSubscriptionText({author});
  if (notification.type === 'like') text = getLikeText({referenceName: notification.referenceName, author});
  if (notification.type === 'score') text = getScoreText({referenceName: notification.referenceName, author});
  if (notification.type === 'comment') text = getCommentText({referenceName: notification.referenceName, author});

  const to = getLinkPath(notification);
  return (
    <Stack
      spacing={1.5}
      direction="row"
      sx={{
        px: 2,
        py: 1,
        alignItems: 'flex-start',
        backgroundColor: 'transparent',
        cursor: to ? 'pointer' : undefined,
        transition: 'background-color .3s',
        '&:hover': {backgroundColor: 'primary.10'},
      }}
      onClick={to ? () => navigate(to) : undefined}
    >
      <Stack spacing={1.5} direction="row" sx={{alignItems: 'center', minHeight: 12}}>
        <NotificationIndication isSeen={!!notification.seenAt} sx={{flexShrink: 0}} />
        {fileId && (
          <Box
            flexShrink={0}
            sx={{
              borderRadius: imageStyle === 'round' ? '50%' : 2,
              overflow: 'hidden',
              '& span': {verticalAlign: 'top'},
            }}
          >
            <LazyLoadImage
              width={48}
              height={48}
              effect="blur"
              src={croppedImagePath(fileId, {ratio: '1:1', maxWidth: 100})}
            />
          </Box>
        )}
      </Stack>
      <Stack flexGrow={1} spacing={0.5} sx={{alignSelf: 'flex-start'}}>
        <Typography variant="subtitle1" sx={{color: 'grey.200'}}>
          {timeAgo(notification.createdAt)}
        </Typography>
        <NotificationAuthoredMessage author={author} message={text} />
      </Stack>
      {to && (
        <Box
          component={ChevronRight}
          sx={{flexShrink: 0, width: 24, height: 24, alignSelf: 'center', color: 'grey.200'}}
        />
      )}
    </Stack>
  );
};
