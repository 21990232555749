import Box from '@mui/system/Box';
import {SxProps} from '@mui/system';

export const NotificationIndication = ({sx, isSeen}: {sx?: SxProps; isSeen?: boolean}) => {
  return (
    <Box
      sx={{
        ...sx,
        width: 6,
        height: 6,
        borderRadius: '50%',
        backgroundColor: isSeen ? 'transparent' : '#EC096F',
      }}
    />
  );
};
