import {FC} from 'react';

import {Box, CircularProgress} from '@mui/material';
import {WidgetImage} from 'components/EntityWidgets';
import {Stages, StagesPoints, TimelineCheckResult} from 'api/generated/users-api';
import {FileIdProp, IsLoadingProp, SVGComponent} from 'utils';
import {ReactComponent as BadIcon} from 'svg/icons/badIcon.svg';
import {ReactComponent as CheckIcon} from 'svg/icons/checkIcon.svg';

export type StagePointImageProps = FileIdProp &
  Partial<Pick<StagesPoints, 'status'>> &
  Pick<TimelineCheckResult, 'available'> &
  IsLoadingProp;

const statusIcons: {[K in Stages['status']]: SVGComponent | null} = {
  new: null,
  in_progress: null,
  checking: null,
  ready: CheckIcon,
  completed: CheckIcon,
  deadline: null,
  failed: BadIcon,
  rejected: BadIcon,
};

const bgColors: {[K in Stages['status'] | 'loading']: string | undefined} = {
  new: undefined,
  in_progress: undefined,
  checking: undefined,
  ready: 'rgba(11, 214, 102, 0.7)',
  completed: 'rgba(11, 214, 102, 0.7)',
  deadline: undefined,
  failed: 'rgba(143, 145, 150, 0.8)',
  rejected: 'rgba(143, 145, 150, 0.8)',
  loading: 'rgba(255, 255, 255, 0.5)',
};

export const StagePointImage: FC<StagePointImageProps> = ({status, fileId, isLoading}) => {
  const Icon = status ? statusIcons[status] : null;
  return (
    <WidgetImage alt="" fileId={fileId} sx={{borderRadius: 4, position: 'relative', overflow: 'hidden'}} size="medium">
      {status && (Icon || !isLoading) && (
        <Box
          position="absolute"
          bgcolor={isLoading ? 'rgba(255, 255, 255, 0.5)' : bgColors[status]}
          width={1}
          height="100%"
          top={0}
          left={0}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          {Icon && !isLoading && <Icon width={24} color="white" />}
          {isLoading && <CircularProgress size={24} />}
        </Box>
      )}
    </WidgetImage>
  );
};
