import {useToggle} from 'react-use';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import ButtonBase from '@mui/material/ButtonBase';

import {WidgetBox} from 'components/EntityWidgets';

import {JobsAttachment} from './JobsAttachment';
import {GoalReportAttachment} from './GoalReportAttachment';
import QuestReportAttachment from './QuestReportAttachment';
import {ReactComponent as ChevronRight} from 'svg/chevrons/chevronRight.svg';
import {AttachmentContainerProps, PublicationAttachmentsProps} from './types';

const Components = {
  Jobs: JobsAttachment,
  Stages: QuestReportAttachment,
  GoalsReports: GoalReportAttachment,
};

const AttachmentContainer: React.VFC<AttachmentContainerProps> = ({
  attachment: {referenceId, referenceName},
  initialExpanded,
}) => {
  const expandable = referenceName !== 'GoalsReports';
  const [expanded, toggle] = useToggle(initialExpanded || false);
  const Component = Components[referenceName as keyof typeof Components];

  if (!Component) return null;
  return (
    <WidgetBox
      p={0}
      onClick={!expanded && expandable ? toggle : undefined}
      sx={
        expandable && !expanded
          ? {
              cursor: 'pointer',
              '&:hover .expand-button': {
                backgroundColor: 'grey.10',
              },
            }
          : undefined
      }
    >
      <Component entryId={referenceId} expanded={expanded} />
      {expandable && (
        <Box
          component={ButtonBase}
          onClick={expanded ? () => toggle(false) : undefined}
          width="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
          className="expand-button"
          sx={{
            transition: 'background-color .25s',
            '&:hover': {
              backgroundColor: 'grey.10',
            },
          }}
        >
          <Box
            width={24}
            height={24}
            component={ChevronRight}
            sx={{transform: `rotate(${(expanded ? -1 : 1) * 90}deg)`}}
          />
        </Box>
      )}
    </WidgetBox>
  );
};

export const PublicationAttachments = ({data, initialExpanded}: PublicationAttachmentsProps) => {
  if (!data || !data.length) return null;
  return (
    <Stack spacing={1.5}>
      {data.map(item => (
        <AttachmentContainer attachment={item} key={item._id} initialExpanded={initialExpanded} />
      ))}
    </Stack>
  );
};
