import React, {createContext, ReactNode} from 'react';

export type HeaderContextState = {
  title?: string;
  actions?: ReactNode | null;
  showBackButton?: boolean;
  showSettings?: boolean;
  showMainActions?: boolean;
  showNotifications?: boolean;
  backLink?: string | (() => void);
  info?: string;
};

export type HeaderContextAction = {type: 'set'; payload: Partial<HeaderContextState>} | {type: 'reset'};
export type HeaderContextDispatch = React.Dispatch<HeaderContextAction>;

export const initialHeaderState: HeaderContextState = {
  title: '',
  actions: null,
  backLink: undefined,
  showSettings: false,
  showBackButton: false,
  showMainActions: false,
};

export const HeaderContext = createContext<HeaderContextState>(initialHeaderState);
export const HeaderActionsContext = createContext<HeaderContextDispatch | undefined>(undefined);
