import {Skeleton, Stack} from '@mui/material';
import {GoalListSkeleton} from './GoalListSkeleton';

export const GoalListBlockSkeleton = () => (
  <Stack>
    <Stack direction="row" p={2} justifyContent="space-between" bgcolor="white">
      <Stack direction="row" spacing={1}>
        <Skeleton variant="rectangular" animation="wave" width={82} height={32} sx={{borderRadius: 1}} />
        <Skeleton variant="rectangular" animation="wave" width={44} height={32} sx={{borderRadius: 1}} />
      </Stack>
      <Skeleton variant="rectangular" animation="wave" width={56} height={32} sx={{borderRadius: 1}} />
    </Stack>
    <Stack direction="row" spacing={2} p={2} bgcolor="white">
      <Skeleton variant="text" animation="wave" width={75} height={16} sx={{transform: 'none'}} />
      <Skeleton variant="text" animation="wave" width={75} height={16} sx={{transform: 'none'}} />
      <Skeleton variant="text" animation="wave" width={75} height={16} sx={{transform: 'none'}} />
    </Stack>
    <Stack spacing={1}>
      <GoalListSkeleton />
    </Stack>
  </Stack>
);
