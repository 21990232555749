import {createAction} from '@reduxjs/toolkit';
import {StageIdProp, StagePointIdProp} from 'modules/quests/types';

const prefix = 'UI-FORMS';

export const closePostForm = createAction(`${prefix}/PostForm_close`);
export const openPostForm = createAction<
  {
    entryId?: string;
  } & Partial<StagePointIdProp & StageIdProp>
>(`${prefix}/PostForm_open`);
