import {useMemo} from 'react';
import {focusAtom} from 'jotai/optics';
import {useAtomValue} from 'jotai/utils';

import Box from '@mui/system/Box';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';

import {useMyId} from 'modules/auth';
import {commentsStatsAtom} from 'modules/social/store';
import {commentsPlural} from 'modules/social/comments/utils';
import {CommonCommentsProps} from 'modules/social/comments/types';
import {useCommentsSortingMenu} from 'modules/social/comments/components';

import {useIsMobile} from 'utils';

import {CommentInput} from '../CommentInput';
import {CommentsInfiniteList} from '../CommentList';

type CommentsProps = CommonCommentsProps & {
  noInput?: boolean;
  fixedInput?: boolean;
  restrictScore?: boolean;
};

export const Comments = ({noInput, fixedInput = true, ...params}: CommentsProps) => {
  const isAuth = Boolean(useMyId());
  const isMobile = useIsMobile();
  const [SortMenu, sort] = useCommentsSortingMenu('created_desc');
  const totalAtom = useMemo(
    () =>
      focusAtom(commentsStatsAtom, o =>
        o.prop(`${params.referenceName}.${params.referenceId}`).optional().prop('count')
      ),
    [params.referenceName, params.referenceId]
  );

  const total = useAtomValue(totalAtom);
  const canAddComment = isAuth && !noInput;
  const title = !total ? 'Комментарии' : commentsPlural(total);

  const Input = canAddComment ? (
    <CardContent>
      <CommentInput {...params} />
    </CardContent>
  ) : undefined;

  return (
    <Box sx={fixedInput && isMobile ? {pb: 13.5} : {}} id="comments">
      <Card>
        <CardHeader title={title} action={!!total && <SortMenu />} />
        {!isMobile && Input}
        <CardContent>
          <CommentsInfiniteList {...params} sort={sort} />
        </CardContent>
      </Card>
      {Input && isMobile && (
        <Card sx={fixedInput ? {position: 'fixed', bottom: 4, width: '100%', zIndex: 1002} : {}}>{Input}</Card>
      )}
    </Box>
  );
};

export default Comments;
