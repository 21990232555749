import {api} from 'api';

import {AdventureQuest} from '../AdventureQuest';
import {AdventureQuestSkeleton} from 'components/Skeleton';
import {useAdventureQuestListDataLoading} from 'modules/oldQuests2/containers/AdventureQuestList/hook';

export const AdventureQuestList = () => {
  const {data, isLoading} = api.endpoints.stagesIndex.useQuery({questType: 'adventure', populates: ['quest']});
  const isDataLoading = useAdventureQuestListDataLoading();

  if (isDataLoading || isLoading) return <AdventureQuestSkeleton />;
  return (
    <>
      {isLoading && <AdventureQuestSkeleton />}
      {(data?.data || []).map(item => (
        <AdventureQuest key={item._id} stageId={`${item._id}`} quest={item.quest as any} />
      ))}
    </>
  );
};
