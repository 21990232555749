import {api} from 'api';
import {useParams} from 'react-router';

export const useGetProfile = () => {
  const {userId} = useParams<'userId'>();
  return api.endpoints.userIndex.useQuery({userId: userId as string});
};

export const useGetGoal = () => {
  const {goalId} = useParams<'goalId'>();
  return api.endpoints.goalIndex.useQuery({goalId: goalId as string});
};
