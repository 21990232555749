import {ReactNode} from 'react';
import {To} from 'react-router';
import {Link} from 'react-router-dom';
import {Box, CardHeader, Stack, Typography} from '@mui/material';

import {ReactComponent as ChevronRight} from 'svg/chevrons/chevronRight.svg';

export type CardLinkHeaderProps = {
  to: To;
  title: string;
  subTitle?: string | ReactNode;
  fontWeight?: number;
  color?: string;
  onClick?: () => void;
};

export const CardLinkHeader = ({title, subTitle, to, onClick, fontWeight, color}: CardLinkHeaderProps) => (
  <CardHeader
    disableTypography
    title={
      <Stack direction="row" spacing={1} alignItems="center">
        <Typography variant="h4" color={color} fontWeight={fontWeight}>
          {title}
        </Typography>
        {subTitle && <div>{subTitle}</div>}
      </Stack>
    }
    to={to}
    component={Link}
    sx={{color: 'grey.700'}}
    onClick={(e: React.MouseEvent) => {
      if (onClick) {
        e.preventDefault();
        onClick();
      }
    }}
    action={
      <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%'}}>
        <ChevronRight />
      </Box>
    }
  />
);
