import {Box, Card, Stack, Typography} from '@mui/material';
import {api} from 'api';
import {GroupRequestItem} from 'modules/groups/components/GroupRequestItem';
import {CardLinkHeader} from 'components/CardLinkHeader';
import {HideScrollbar} from 'components/HideScrollbar';

type GroupRequestsListProps = {
  groupId: string;
};

export const GroupRequestsList = ({groupId}: GroupRequestsListProps) => {
  const {data} = api.endpoints.groupRequestsIndex.useQuery({});

  if (!data || !data.total) return null;

  return (
    <Card>
      <CardLinkHeader
        title="Запросы на вступление"
        to={`/teams/${groupId}/requests`}
        subTitle={
          <Typography variant="body2" color="grey.200">
            {data.total}
          </Typography>
        }
      />
      <HideScrollbar>
        <Stack spacing="8px" flexWrap="nowrap" alignItems="stretch" direction="row" px={2} pb={1}>
          {(data.data || []).map(item => (
            <Box
              key={item._id}
              width={280}
              flex="0 0 280px"
              border="1px solid"
              borderColor="grey.10"
              borderRadius={2}
              overflow="hidden"
            >
              <GroupRequestItem request={item} />
            </Box>
          ))}
        </Stack>
      </HideScrollbar>
    </Card>
  );
};
