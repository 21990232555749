import _ from 'lodash/fp';
import numeral from 'numeral';
import React from 'react';

import Box from '@mui/system/Box';
import Typography from '@mui/material/Typography';

import {formatDate} from 'utils/dates';

import {Goals} from 'api/generated/users-api';
import {GoalReportProps} from './types';

type ReportChangelog = {
  [K in keyof Goals]: {old: Goals[K]; new: Goals[K]};
}[];

const prefixes = {
  name: 'Изменено название на',
  description: 'Изменено описание на',
  planFinishDate: 'Цель перенесена на',
  privacy: 'Видимость цели изменена на',
  rewardValue: 'Денежный эквивалент изменен на',
};

const statuses = {
  draft: 'Создан черновик',
  new: 'Цель создана',
  completed: 'Цель выполнена',
  archived: 'Цель архивирована',
  failed: 'Цель не выполнена',
};

export const GoalSystemReport = ({goalReport}: GoalReportProps) => {
  const changelog = goalReport.changelog as ReportChangelog;
  if (!changelog) return null;
  const Content = changelog.map((item, index) => {
    const data = _.pick(['name', 'description', 'planFinishDate', 'privacy', 'rewardValue', 'status'], item);
    return (
      <React.Fragment key={index}>
        {Object.keys(data).map((key: string) => {
          let value = item[key as keyof Goals]?.new;
          if (!value || (key === 'status' && value === 'completed')) return null;
          if (key === 'planFinishDate') value = formatDate(`${value}`, 'PPP');
          if (key === 'rewardValue') value = numeral(((value as any).value as number) || 0).format('0,0');
          if (key === 'status') value = statuses[value as keyof typeof statuses];
          return (
            <Typography variant="body2" sx={{color: 'grey.200'}} key={key}>
              {prefixes[key as keyof typeof prefixes]}
              &nbsp;
              {key !== 'status' && `«`}
              <Box sx={{color: key !== 'status' ? 'grey.700' : 'grey.200'}} component="span">
                {value}
              </Box>
              {key !== 'status' && `»`}
            </Typography>
          );
        })}
      </React.Fragment>
    );
  });
  return <div>{Content}</div>;
};
