import {lazy, Suspense} from 'react';
import {Navigate, RouteObject} from 'react-router';

import {SearchMainPageSkeleton} from 'components/Skeleton';

import SearchAllPageMarkup from './pages/SearchAllPageMarkup';
import SearchFocusPageMarkup from './pages/SearchFocusPageMarkup';
import SearchPostsPageMarkup from './pages/SearchPostsPageMarkup';
import SearchUsersPageMarkup from './pages/SearchUsersPageMarkup';
import SearchTeamsPageMarkup from './pages/SearchTeamsPageMarkup';
import SearchGoalsPageMarkup from './pages/SearchGoalsPageMarkup';
import SearchNotFoundPageMarkup from './pages/SearchNotFoundPageMarkup';
import SearchFocusEmptyPageMarkup from './pages/SearchFocusEmptyPageMarkup';
import {RatingPage} from './pages/RatingPage';
import {CompletedGoals} from './pages/CompletedGoals';

const SearchMainPage = lazy(() => import('./pages/SearchMainPage'));

export const router: RouteObject = {
  path: 'discover',
  children: [
    {
      index: true,
      element: (
        <Suspense fallback={<SearchMainPageSkeleton />}>
          <SearchMainPage />
        </Suspense>
      ),
    },
    {
      path: 'goals',
      element: <CompletedGoals />,
    },
    {
      path: 'rating',
      children: [
        {
          index: true,
          element: <Navigate to="./users" />,
        },
        {
          path: 'users',
          element: <RatingPage path="/discover/rating/:tab" />,
        },
        {
          path: 'groups',
          element: <RatingPage path="/discover/rating/:tab" />,
        },
      ],
    },
    {
      path: 'markup-search--focus',
      element: <SearchFocusPageMarkup />,
    },
    {
      path: 'markup-search--focus-empty',
      element: <SearchFocusEmptyPageMarkup />,
    },
    {
      path: 'markup-search--all',
      element: <SearchAllPageMarkup />,
    },
    {
      path: 'markup-search--posts',
      element: <SearchPostsPageMarkup />,
    },
    {
      path: 'markup-search--users',
      element: <SearchUsersPageMarkup />,
    },
    {
      path: 'markup-search--teams',
      element: <SearchTeamsPageMarkup />,
    },
    {
      path: 'markup-search--goals',
      element: <SearchGoalsPageMarkup />,
    },
    {
      path: 'markup-search--not-found',
      element: <SearchNotFoundPageMarkup />,
    },
  ],
};

export default router;
