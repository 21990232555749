import {useState, useCallback} from 'react';
import _ from 'lodash/fp';

import {Button, Box, Dialog, Typography, DialogActions} from '@mui/material';

import {GREY} from 'components/theme/palette';
import {ReactComponent as AlertBadge} from 'svg/alertBadge.svg';

export type ConfirmDialogProps<T> = {
  title?: string | ((p?: T) => string);
  text?: string;
  btnTitle?: string;
  cancelBtnTitle?: string;
  btnType?: 'error' | 'primary';
  align?: 'center' | 'left';
  badge?: 'alert';
};

export type ConfirmHookProps<T> = {
  onCancel?: (p?: T) => void;
  onConfirm: (p?: T) => void;
  defaultOpen?: boolean;
} & Partial<ConfirmDialogProps<T>>;

export function useConfirmDialog<T>({
  onCancel,
  onConfirm,
  defaultOpen,
  ...dialogProps
}: ConfirmHookProps<T>): [(p: ConfirmDialogProps<T>) => JSX.Element, (p?: T) => void] {
  const [open, setOpen] = useState<T | boolean>(defaultOpen ?? false);
  const onOpen = useCallback((p?: T | boolean) => setOpen(p || true), []);

  const Content = useCallback(
    (props: ConfirmDialogProps<T>) => {
      const {
        text,
        title,
        badge,
        btnTitle,
        cancelBtnTitle,
        align = 'left',
        btnType = 'error',
      } = Object.assign(dialogProps, props);
      const close = () => setOpen(false);
      const confirmHandler = () => _.compose(close, onConfirm)(typeof open !== 'boolean' ? open : undefined);
      const cancelHandler = () =>
        _.compose(close, onCancel ? onCancel : () => {})(typeof open !== 'boolean' ? open : undefined);

      return (
        <Dialog
          open={!!open}
          onClose={close}
          onBackdropClick={close}
          maxWidth="xs"
          fullWidth
          PaperProps={{sx: {borderRadius: 2}}}
        >
          <Box sx={{p: 3, pb: 0}}>
            {badge && (
              <Box sx={{mb: 1, display: 'flex', justifyContent: 'center'}}>
                <AlertBadge />
              </Box>
            )}
            {title && (
              <Typography variant="h2" align={align}>
                {typeof title === 'string' ? title : title(typeof open !== 'boolean' ? open : undefined)}
              </Typography>
            )}
            {text && (
              <Typography variant="body2" align={align} color={GREY[200]} sx={{mt: 1}}>
                {text}
              </Typography>
            )}
          </Box>
          <DialogActions sx={{p: 3, justifyContent: align === 'center' ? 'center' : 'flex-end'}}>
            <Button
              size="small"
              variant="contained"
              sx={{px: 3}}
              onClick={cancelHandler}
              color={btnType === 'error' ? 'primary' : 'secondary'}
            >
              {cancelBtnTitle || 'Отмена'}
            </Button>
            <Button variant="contained" color={btnType} size="small" onClick={confirmHandler} sx={{px: 3}}>
              {btnTitle || 'Да'}
            </Button>
          </DialogActions>
        </Dialog>
      );
    },
    [open, onConfirm, onCancel, dialogProps]
  );

  return [Content, onOpen];
}
