import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export const InfiniteListHeader: React.VFC<{title: string; count?: number}> = ({title, count}) => (
  <Box sx={{py: 1, px: 2, backgroundColor: 'white'}}>
    <Typography variant="h3">
      {title}
      {count && (
        <Typography component="span" variant="h3" sx={{color: 'grey.200', display: 'inline-block', ml: 1}}>
          {count}
        </Typography>
      )}
    </Typography>
  </Box>
);
