import {Card, CardContent, Stack} from '@mui/material';

import {timeAgo} from 'utils/dates';
import {useSafeContext} from 'utils';
import {Users} from 'api/generated/users-api';

import {SocialBlock} from 'components/SocialBlock';
import {ImageGallery} from 'components/ImageGallery';
import {HeaderSetup, UserCardHeader} from 'components/Layout';
import {RemovedEntryWithRestore} from 'components/RestoreEntry';

import {useMyId} from 'modules/auth';
import {getPostPath} from 'modules/feed/utils';
import {Comments} from 'modules/social/comments';
import {SocialStateProvider} from 'modules/social';
import {PublicationMenu, PublicationDescription, PublicationAttachments} from 'modules/feed/containers';

import {PublicationContext} from './context';
import {WidgetVisualsContainer} from 'modules/widgets';

export const Publication = () => {
  const myId = useMyId();
  const post = useSafeContext(PublicationContext);
  if (!post) return null;
  const postId = `${post._id}`;
  const stagesId = post.attachments?.filter(item => item.referenceName === 'Stages').map(item => item.referenceId);

  if (post.deletedAt)
    return (
      <RemovedEntryWithRestore
        variant="contained"
        params={{postId}}
        authorId={`${post.user?._id}`}
        mutationName="publicationSafeRestore"
        props={{direction: 'column', showBadge: true}}
      />
    );
  return (
    <SocialStateProvider
      score={post.score}
      comments={post.comments}
      reactions={post.reactions}
      scope={`Publications.${post._id}`}
    >
      <Stack spacing={1} sx={{position: 'relative'}}>
        <HeaderSetup title="Запись" backLink="/feed" showSettings={false} actions={null} />
        <Card>
          <UserCardHeader
            user={post.user as Users}
            subTitle={`${timeAgo(post.createdAt)}`}
            action={
              <PublicationMenu
                entryId={postId}
                isMy={post.userId === myId}
                jobId={post.attachments?.find(item => item.referenceName === 'Jobs')?.referenceId}
              />
            }
          />
          {post.filesId && <ImageGallery filesId={post.filesId} ratio={post.filesAspectRatio} width={1200} />}
          <CardContent>
            <Stack spacing={2} pb={1}>
              <SocialBlock
                commentsCount={post.comments?.count || 0}
                referenceId={postId}
                referenceName="Publications"
                commentButtonTo={{pathname: getPostPath(postId), hash: 'comments'}}
              />
              <PublicationAttachments data={post.attachments} initialExpanded />
              <PublicationDescription content={post.content} />
              {stagesId && stagesId.length > 0 && (
                <WidgetVisualsContainer widgetContainerName="PostWidgetContainer" stagesId={stagesId} />
              )}
            </Stack>
          </CardContent>
        </Card>
        <Comments referenceName="Publications" referenceId={postId} referenceAuthorId={post.userId} />
      </Stack>
    </SocialStateProvider>
  );
};

export default Publication;
