import {StageComponentsMap} from '../../types';

import {GoalStageVisual} from './GoalStageVisual';
import {LessonStageVisual} from './LessonStageVisual';
import {DefaultStageVisual} from './DefaultStageVisual';
import {GoalReportStageVisual} from './GoalReportStageVisual';
import {HorizontalPreviewStageVisual} from './HorizontalPreviewStageVisual';

export const additionalStageComponents = {
  HorizontalPreviewStageVisual,
};

export const stagePointComponents: StageComponentsMap = {
  goal: GoalStageVisual,
  season: DefaultStageVisual,
  lesson: LessonStageVisual,
  default: DefaultStageVisual,
  adventure: DefaultStageVisual,
  goal_report: GoalReportStageVisual,
  block: null,
  event: null,
  point: null,
  meeting: null,
  challenge: null,
};
