import {Skeleton, Stack} from '@mui/material';
import {HideScrollbar} from 'components/HideScrollbar';
import {AdventureQuestSkeleton} from '../components';

export const QuestPageListSkeleton = () => (
  <Stack>
    <Stack spacing={2} mb={0.5} px={2} py={1} bgcolor="white">
      <HideScrollbar>
        <Stack direction="row" spacing={2}>
          <Skeleton variant="rectangular" animation="wave" width={200} height={200} sx={{borderRadius: 1}} />
          <Skeleton variant="rectangular" animation="wave" width={200} height={200} sx={{borderRadius: 1}} />
          <Skeleton variant="rectangular" animation="wave" width={200} height={200} sx={{borderRadius: 1}} />
        </Stack>
      </HideScrollbar>
      <Skeleton variant="rectangular" animation="wave" width="100%" height={32} sx={{borderRadius: 1.5}} />
    </Stack>
    <AdventureQuestSkeleton />
  </Stack>
);
