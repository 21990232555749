import {api} from 'api';

export const useStageData = (stageId: string) => {
  const {data, isError, isFetching, isLoading} = api.endpoints.stageIndex.useQuery(
    {stageId, partial: true},
    {
      selectFromResult: ({data, ...props}) => ({
        ...props,
        data: {
          data,
          status: data?.stage.status,
          name: data?.stage.quest?.name,
          questType: data?.stage.questType || 'default',
          timeline: data?.stage.timeline,
        },
      }),
    }
  );

  return {data, isError, isFetching, isLoading};
};
