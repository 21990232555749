import {Link} from 'react-router-dom';
import {FunctionComponent} from 'react';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import {useIsViewed} from 'utils';
import {formatDate} from 'utils/dates';

import {Deadline} from 'components/Deadline';
import {LazyImage} from 'components/ImageGallery';
import {getTaskJobPath} from 'modules/tasks/utils';

import {TaskPreviewHeader} from './TaskPreviewHeader';
import {TaskCompactPreview} from './TaskCompactPreview';
import {JobPreviewProps, TaskPreviewProps} from './types';
import {useAcceptTask} from 'modules/tasks/containers/TaskAcceptButton/hook';

export const TaskPreview: FunctionComponent<TaskPreviewProps> = ({data, compact = true, job}) => {
  const [isViewed] = useIsViewed({referenceName: 'Tasks', referenceId: `${data._id}`}, Boolean(data.isViewed));
  const [timeline] = data.timelines || [];
  const {onAccept} = useAcceptTask({taskId: `${data._id}`, timelineId: timeline?._id || ''});

  const finishDate = job && job.status === 'completed' ? job.completedAt : undefined;
  let status: 'new' | 'completed' | 'in_progress' | undefined = !isViewed ? 'new' : undefined;
  if (job?.status === 'completed') status = 'completed';
  else if (job?.status === 'new' || job?.status === 'edit') status = 'in_progress';

  const Content = (
    <>
      {compact && (
        <TaskCompactPreview
          data={data}
          status={status}
          isViewed={isViewed}
          timelines={job?.timeline}
          finishDate={finishDate}
        />
      )}
      {!compact && (
        <>
          {data.filesId && !!data.filesId.length && (
            <LazyImage
              width={800}
              fileId={data.filesId[0]}
              alt={`Задание ${data.name}`}
              ratio={data.filesAspectRatio}
            />
          )}
          <Stack spacing={2} sx={{p: 2}}>
            <TaskPreviewHeader name={data.name} category={data.category?.name} status={status} />
            {data.short_description && <Typography variant="body1">{data.short_description}</Typography>}
            {!finishDate && <Deadline timelines={job?.timeline ? [job.timeline] : data.timelines || []} />}
            {finishDate && (
              <Box sx={{typography: 'subtitle1', color: 'grey.200'}}>{`Завершено: ${formatDate(finishDate)}`}</Box>
            )}
          </Stack>
        </>
      )}
    </>
  );

  if (job && job._id)
    return (
      <Card sx={{pb: 0}} component={Link} to={getTaskJobPath(job._id)}>
        {Content}
      </Card>
    );
  if (timeline)
    return (
      <Card sx={{pb: 0, cursor: 'pointer'}} onClick={onAccept}>
        {Content}
      </Card>
    );
  return null;
};

export const JobPreview: FunctionComponent<JobPreviewProps> = ({job, compact}) => {
  if (!job.task) return null;
  return <TaskPreview data={job.task} job={job} compact={compact} />;
};
