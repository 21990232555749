import {api} from 'api';

import {Waypoint} from 'react-waypoint';
import {NotionRenderer} from 'react-notion-x';

import styled from '@mui/system/styled';

import IsExistsContainer from 'components/IsExistsContainer';
import {GREY} from 'components/theme/palette';
import {ContentContentSkeleton} from 'components/Skeleton';

import 'react-notion-x/src/styles.css';
import 'prismjs/themes/prism-tomorrow.css';
import 'rc-dropdown/assets/index.css';
import 'katex/dist/katex.min.css';
import {InfoMessageBlock} from 'components/InfoMessageBlock';

const NotionWrapper = styled('div')`
  font-family: 'Source Serif Pro', serif;
  position: relative;

  & .notion {
    font-family: 'Source Serif Pro', serif;
    color: #344054;

    &-list li,
    &-text,
    &-quote,
    &-callout-text {
      padding: 0;
      font-size: 17px;
      line-height: 24px;
      font-weight: 400;
      ${({theme}) => ({
        [theme.breakpoints.up('md')]: {
          fontSize: 17,
          lineHeight: '24px',
        },
      })}
    }
    &-callout {
      border: none;
      background-color: white;
      font-size: 16px;
      line-height: 20px;
      box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06);
    }
    &-callout-text {
      font-size: 16px;
      line-height: 20px;
    }
    &-text,
    &-quote,
    &-callout,
    &-asset-wrapper,
    &-h1,
    &-h2,
    &-h3 {
      margin-bottom: 24px;
    }

    &-asset-wrapper,
    &-callout,
    &-h3,
    &-quote,
    &-h1 {
      margin-top: 0;
    }

    &-asset-caption {
      padding: 0;
      margin-top: 10px;
      font-size: 13px;
      line-height: 16px;
      color: ${GREY[700]};
    }

    &-text b,
    &-asset-caption,
    &-callout-text,
    &-h1,
    &-h2,
    &-h3,
    &-h4 {
      font-family: 'EuclidCircularA';
    }

    &-h1 {
      font-size: 24px;
      line-height: 32px;
      font-weight: 600;

      ${({theme}) => ({
        [theme.breakpoints.up('md')]: {
          fontSize: 32,
          lineHeight: '40px',
        },
      })}
    }

    &-h2 {
      margin-top: 16px;
      font-size: 20px;
      font-weight: 600;
      line-height: 24px;
      ${({theme}) => ({
        [theme.breakpoints.up('md')]: {
          fontSize: 20,
          lineHeight: '24px',
        },
      })}
    }

    &-h3 {
      font-size: 17px;
      font-weight: 600;
      line-height: 24px;
      ${({theme}) => ({
        [theme.breakpoints.up('md')]: {
          fontSize: 17,
          lineHeight: '28px',
        },
      })}
    }

    &-h4 {
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      ${({theme}) => ({
        [theme.breakpoints.up('md')]: {
          fontSize: 16,
          lineHeight: '28px',
        },
      })}
    }

    &-quote {
      padding-top: 8px;
      padding-left: 16px;
      padding-right: 16px;
      padding-bottom: 8px;
      border-left-width: 2px;
      border-left-color: #b450f7;
      background-color: #f7ecfe;
    }

    &-list {
      & + .notion-text,
      & + .notion-quote,
      & + .notion-callout,
      & + .notion-asset-wrapper,
      & + .notion-h3 {
        margin-top: 24px;
      }

      &-numbered,
      &-disc,
      &-square,
      &-circle {
        padding-inline-start: 32px;
      }
    }

    &-list,
    &-list-numbered {
      padding-top: 0;
      padding-bottom: 0;
      li {
        padding: 0;
        padding-left: 8px;
        margin-bottom: 12px;
      }
    }

    &-page {
      padding-left: 0;
      padding-right: 0;
    }
    &-viewport {
      display: none;
      position: absolute;
    }
  }
`;

export const NotionContent = ({notionPageId, onLoaded}: {notionPageId: string; onLoaded?: (key: string) => void}) => {
  const loadedHandler = () => onLoaded && onLoaded(`notionPage.${notionPageId}`);
  return (
    <IsExistsContainer
      useFetch={() => api.endpoints.notionPageIndex.useQuery({pageId: notionPageId})}
      LoadingComponent={<ContentContentSkeleton />}
      ErrorComponent={
        <>
          <InfoMessageBlock status="error" message="Произошла ошибка при загрузке материала" />
          <Waypoint onEnter={loadedHandler} />
        </>
      }
    >
      {notionPage => {
        return (
          <NotionWrapper>
            {onLoaded && <Waypoint onEnter={loadedHandler} />}
            <NotionRenderer recordMap={notionPage as any} />
          </NotionWrapper>
        );
      }}
    </IsExistsContainer>
  );
};
