import {useSnackbar} from 'notistack';
import {useNavigate} from 'react-router';

import {api} from 'api';
import {useIsViewed} from 'utils';
import {Quests} from 'api/generated/users-api';
import {getActiveQuestPath} from 'modules/oldQuests2/utils';

export const useQuestActions = (quest: Quests) => {
  const navigate = useNavigate();
  const [acceptQuest, {isLoading: isAccepting}] = api.endpoints.questAccept.useMutation();
  const [rejectQuest, {isLoading: isRejecting}] = api.endpoints.questReject.useMutation();
  const [, setViewed] = useIsViewed({referenceName: 'Quests', referenceId: `${quest._id}`}, Boolean(quest.isViewed));
  const {enqueueSnackbar} = useSnackbar();
  const isLoading = isAccepting || isRejecting;

  const action = async (accept: boolean) => {
    if (!quest.timelines || isLoading || (!accept && !quest.allowReject)) return;

    const requestBody = {
      questId: `${quest._id}`,
      questActionDto: {timelineId: `${quest.timelines[0]._id}`},
    };
    const result = await (accept ? acceptQuest : rejectQuest)(requestBody);

    setViewed();
    if (accept && 'data' in result) navigate(getActiveQuestPath(result.data.stage._id));
    else if ('error' in result) {
      const defaultMessage = accept ? 'Не получилось взять квест' : 'Не получилось отклонить квест';
      enqueueSnackbar((result.error as any).data.message || defaultMessage, {
        variant: 'error',
        autoHideDuration: 2000,
      });
    }
  };

  return {action, isAccepting, isRejecting};
};
