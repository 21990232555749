import {LoadingButton} from '@mui/lab';
import {Box, Card, CardContent} from '@mui/material';
import {skipToken} from '@reduxjs/toolkit/dist/query';

import {api} from 'api';
import {Goals} from 'api/generated/users-api';
import {Form as Factory} from 'components/FormFactory';
import {getInitialValues} from 'components/FormFactory/Form/utils';
import {useIsMe} from 'modules/auth';

import {fields, validation, extractInitials} from './fields';
import {useGoalForm, useGetGoalTagsOptions} from './hooks';

type GoalFormProps = {
  goalId?: string;
  goalTypeId?: string;
  close?: () => void;
  callback?: (goal: Goals) => void;
};

const initialValues = getInitialValues(fields([], []));

export const GoalForm = ({goalId, close, goalTypeId, callback}: GoalFormProps) => {
  const {data} = api.endpoints.goalIndex.useQuery(goalId ? {goalId} : skipToken);
  const tags = useGetGoalTagsOptions();
  const isMyGoal = useIsMe(data?.user);
  const {onSubmit, goalTypesOptions} = useGoalForm(goalId, close, callback, isMyGoal);
  const initials = extractInitials(data, isMyGoal) || initialValues;

  if (!goalTypesOptions || !goalTypesOptions.length || !tags.length) return null;
  const filteredOptions = !goalTypeId ? goalTypesOptions : goalTypesOptions.filter(item => item.value === goalTypeId);
  return (
    <Card sx={{pb: 1}}>
      <CardContent>
        <Factory
          fields={fields(filteredOptions, tags)}
          validationSchema={validation}
          initialValues={initials}
          onSubmit={onSubmit}
        >
          {({isValid, isSubmitting}) => (
            <Box
              sx={{
                py: 1,
                width: '100%',
                backgroundColor: 'white',
              }}
            >
              <LoadingButton
                fullWidth
                type="submit"
                color="primary"
                variant="contained"
                loading={isSubmitting}
                disabled={!isValid || isSubmitting}
              >
                {goalId ? (isMyGoal ? 'Сохранить изменения' : 'Поставить цель') : 'Поставить цель'}
              </LoadingButton>
            </Box>
          )}
        </Factory>
      </CardContent>
    </Card>
  );
};
