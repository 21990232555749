import {lazy, Suspense} from 'react';
import {Navigate, Outlet, RouteObject, useParams} from 'react-router';

import {api} from 'api';
import {Jobs} from 'api/generated/users-api';

import questsRouter from 'modules/quests';
import {SocialStateProvider} from 'modules/social';
import IsExistsContainer from 'components/IsExistsContainer';
import {
  TaskCommentsPageSkeleton,
  TaskDonePageSkeleton,
  TaskFeedPageSkeleton,
  TaskPageSkeleton,
  TasksListPageSkeleton,
} from 'components/Skeleton';

import {TasksLayout} from './containers';
import {TaskContext, TaskJobContext} from './context';
import {skipToken} from '@reduxjs/toolkit/dist/query';

const TaskListPage = lazy(() => import('./pages/TaskList'));
const TaskFeedPage = lazy(() => import('./pages/TaskFeed'));
const JobFinishPage = lazy(() => import('./pages/JobFinish'));
const TaskCommentsPage = lazy(() => import('./pages/TaskComments'));
const TaskJobLandinPage = lazy(() => import('./pages/Job'));

const useGetTask = () => {
  const {taskId} = useParams<'taskId'>();
  return api.endpoints.taskIndex.useQuery({taskId: taskId as string});
};

const useGetJob = () => {
  const {jobId} = useParams<'jobId'>();
  return api.endpoints.jobStructure.useQuery(jobId ? {jobId} : skipToken, {refetchOnMountOrArgChange: true});
};

export const router: RouteObject = {
  element: <TasksLayout />,
  children: [
    questsRouter,
    {
      path: 'tasks',
      children: [
        {
          index: true,
          element: (
            <Suspense fallback={<TasksListPageSkeleton />}>
              <TaskListPage />
            </Suspense>
          ),
        },
        {
          path: ':taskId',
          element: <IsExistsContainer useFetch={useGetTask} context={TaskContext} />,
          children: [
            {
              index: true,
              element: <Navigate to={'/tasks'} />,
            },
            {
              path: 'feed',
              element: (
                <Suspense fallback={<TaskFeedPageSkeleton />}>
                  <TaskFeedPage />
                </Suspense>
              ),
            },
            {
              path: 'comments',
              element: (
                <Suspense fallback={<TaskCommentsPageSkeleton />}>
                  <TaskCommentsPage />
                </Suspense>
              ),
            },
          ],
        },
        {
          path: 'job/:jobId',
          element: (
            <IsExistsContainer<Jobs>
              checkLoadingFlag
              useFetch={useGetJob}
              context={TaskJobContext}
              LoadingComponent={<TaskPageSkeleton />}
            >
              {job => (
                <SocialStateProvider
                  score={job.task?.score}
                  comments={job.task?.comments}
                  reactions={job.task?.reactions}
                  scope={`Tasks.${job.taskId}`}
                >
                  <Outlet />
                </SocialStateProvider>
              )}
            </IsExistsContainer>
          ),
          children: [
            {
              index: true,
              element: (
                <Suspense fallback={<TaskPageSkeleton />}>
                  <TaskJobLandinPage />
                </Suspense>
              ),
            },
            {
              path: 'done',
              element: (
                <Suspense fallback={<TaskDonePageSkeleton />}>
                  <JobFinishPage />
                </Suspense>
              ),
            },
            {
              path: 'feed',
              element: (
                <Suspense fallback={<TaskFeedPageSkeleton />}>
                  <TaskFeedPage />
                </Suspense>
              ),
            },
            {
              path: 'comments',
              element: (
                <Suspense fallback={<TaskCommentsPageSkeleton />}>
                  <TaskCommentsPage />
                </Suspense>
              ),
            },
          ],
        },
      ],
    },
  ],
};

export default router;
