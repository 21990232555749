import {isNumber} from 'lodash';
import {Box} from '@mui/material';
import {styled, SxProps} from '@mui/system';

import {GREY} from 'components/theme/palette';
import {ReactComponent as StarIcon} from 'svg/rating/starEmpty.svg';

const NPSBadgeContainer = styled(Box)<{size?: 'small' | 'medium'}>`
  ${({size, theme}) => ({
    ...(size === 'small' && {
      height: theme.spacing(2),
      padding: '0 5px 0 3px',
    }),
    ...(size === 'medium' && {
      height: theme.spacing(2.5),
      padding: '0 5px',
    }),
  })}

  width: fit-content;
  display: inline-flex;
  vertical-align: middle;
  align-items: center;
  justify-content: flex-start;

  border: 1px solid #d9dee8;
  box-sizing: border-box;
  border-radius: 24px;
  color: ${GREY[200]};
  background-color: white;
`;

const NPSBadgeNumber = styled('div')`
  font-size: 11px;
  line-height: 12px;
`;

export interface NPSBadgeProps {
  value?: number;
  size?: 'small' | 'medium';
  prefix?: string;
  sx?: SxProps;
  fixedValue?: number;
}

export const NPSBadge = ({value, fixedValue = 2, size = 'medium', prefix, sx}: NPSBadgeProps) => {
  if (!isNumber(value)) return null;
  const withDecinal = value % 1 !== 0;
  const fixed = withDecinal ? fixedValue : 0;
  return (
    <NPSBadgeContainer size={size} gap={0.25} sx={sx}>
      {prefix && <NPSBadgeNumber>{prefix}</NPSBadgeNumber>}
      <StarIcon width={12} />
      <NPSBadgeNumber>{value.toFixed(fixed)}</NPSBadgeNumber>
    </NPSBadgeContainer>
  );
};

export default NPSBadge;
