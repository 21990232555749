import {Box, Skeleton, Stack} from '@mui/material';
import {UserListSkeleton} from '../components';

export const UserListPageSkeleton = () => (
  <Stack>
    <Box p={2} bgcolor="white" mb={0.5}>
      <Skeleton variant="rectangular" animation="wave" width="100%" height={40} sx={{borderRadius: 12}} />
    </Box>
    <UserListSkeleton />
  </Stack>
);
