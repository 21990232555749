import {api} from 'api';
import {useParams} from 'react-router';
import {QuestsPoints} from 'api/generated/users-api';

import {QuestPointPressHook, QuestPointProps} from '../types';

import {useTaskPointPress} from './taskPointPress.hook';
import {usePostPointPress} from './postPointPress.hook';
import {useGoalPointPress} from './goalPointPress.hook';
import {useQuestPointPress} from './questPointPress.hook';
import {useButtonPointPress} from './buttonPointPress.hook';
import {useContentPointPress} from './contentPointPress.hook';
import {useTimelinePointPress} from './timelinePointPress.hook';
import {skipToken} from '@reduxjs/toolkit/dist/query';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const useDefaultPress = (data: QuestPointProps) => ({isLoading: false, canPress: false, onPress: () => {}});

export * from './getStageData.hook';
export * from './refreshStage.hook';
export * from './getStagePointsByContainerName.hook';

export const pressHooksMap: {[K in QuestsPoints['type']]: QuestPointPressHook} = {
  goal: useGoalPointPress,
  widget: useDefaultPress,
  task: useTaskPointPress,
  payment: useDefaultPress,
  quest: useQuestPointPress,
  button: useButtonPointPress,
  shortcut: useQuestPointPress,
  content: useContentPointPress,
  publication: usePostPointPress,
  timeline: useTimelinePointPress,
};

export const useGetRouterStage = () => {
  const {stageId} = useParams<'stageId'>();
  return api.endpoints.stageIndex.useQuery(stageId ? {stageId} : skipToken);
};
