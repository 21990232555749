import {ReactComponent as FeedIcon} from 'svg/bottomMenu/home.svg';
import {ReactComponent as TaskIcon} from 'svg/bottomMenu/task.svg';
import {ReactComponent as SearchIcon} from 'svg/bottomMenu/search.svg';
import {ReactComponent as ContentIcon} from 'svg/bottomMenu/content.svg';
// import {ReactComponent as MessagesIcon} from 'svg/bottomMenu/chat.svg';

const BottonMavConfig = ({isUnreadTasks, isUnreadContents}: {isUnreadContents?: number; isUnreadTasks?: number}) => [
  {
    Icon: FeedIcon,
    to: '/feed',
    text: 'Главная',
  },
  {
    Icon: SearchIcon,
    to: '/discover',
    text: 'Интересное',
  },
  {
    Icon: TaskIcon,
    to: '/modules',
    text: 'Задания',
    isUnread: Boolean(isUnreadTasks),
  },
  {
    Icon: ContentIcon,
    to: '/contents',
    text: 'База знаний',
    isUnread: Boolean(isUnreadContents),
  },
];

export default BottonMavConfig;
