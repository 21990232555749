import numeral from 'numeral';
import * as _ from 'lodash/fp';
import {isAfter, parseISO} from 'date-fns';
import {Grid, Card, Stack, Button, Typography, CardContent} from '@mui/material';

import {useSafeContext} from 'utils';
import {formatDistance} from 'utils/dates';

import {useIsMe} from 'modules/auth';
import {CommentsBlock} from 'modules/social/comments';
import {SocialStateProvider} from 'modules/social';
import {GoalContext} from 'modules/profile/context';
import {getUserLink} from 'modules/profile/utils';
import {GoalMoneyProgress} from 'modules/goals/components';
import {GoalContextMenu, GoalReportFeed} from 'modules/goals/containers';
import {useCloneGoalForm} from 'modules/goals/containers/GoalFormProvider/context';

import {SocialBlock} from 'components/SocialBlock';
import {ImageGallery} from 'components/ImageGallery';
import {MarkdownRender} from 'components/MarkdownRender';
import {RemovedEntry} from 'components/RestoreEntry';
import {HeaderSetup, InfoBlock, UserCardHeader} from 'components/Layout';

export const GoalPage = () => {
  const goal = useSafeContext(GoalContext);
  const isMe = useIsMe(goal.user);
  const openCloneGoalForm = useCloneGoalForm(goal);

  if (!goal) return null;
  const goalId = goal._id as string;
  const goalType = goal.goalType?.name || '';
  const rewardValue = numeral(goal.rewardValue?.value || 0).format('0,0') + goal.reward?.symbol || '';
  const timeLeft = formatDistance(goal.planFinishDate || undefined);
  const finishDate = formatDistance(goal.planFinishDate || undefined, goal.createdAt);
  const isMoneyGoal = !!goal.goalType?.rewardId && !goal.goalType?.rewardAsAnalog;
  const qualities = _.compose(_.join(', '), _.map(_.get('tag.name')))(goal.tagsSet || []);
  const isOverdue =
    (goal.status === 'new' && !!goal.planFinishDate && isAfter(new Date(), parseISO(goal.planFinishDate))) ||
    (goal.status === 'completed' &&
      !!goal.realFinishDate &&
      !!goal.planFinishDate &&
      isAfter(parseISO(goal.realFinishDate), parseISO(goal.planFinishDate)));

  if (goal.deletedAt)
    return (
      <>
        <HeaderSetup showSettings={false} title="Цель" backLink={getUserLink(goal.user, 'goals')} />
        <RemovedEntry direction="column" showBadge />
      </>
    );
  return (
    <Stack spacing={0.5}>
      <HeaderSetup
        title="Цель"
        showSettings={false}
        backLink={getUserLink(goal.user, 'goals')}
        actions={<GoalContextMenu goal={goal} isMe={isMe} />}
      />
      <SocialStateProvider
        score={goal.score}
        reactions={goal.reactions}
        comments={goal.comments}
        scope={`Goals.${goal._id}`}
      >
        <Card>
          {goal.user && <UserCardHeader user={goal.user} />}
          <ImageGallery alt={goal.name} ratio={goal.filesAspectRatio} filesId={goal.filesId} width={1200} />
          <CardContent>
            <Stack spacing={2} mt={1}>
              <SocialBlock
                commentsCount={goal.comments?.count}
                referenceName="Goals"
                referenceId={goalId}
                commentButtonTo={{pathname: getUserLink(goal.user, `goals/${goal._id}/comments`), hash: 'comments'}}
              />
              <Typography variant="h3" mt={0}>
                {goal.name}
              </Typography>
              <MarkdownRender content={goal.description} />
            </Stack>
            <Grid container spacing={1.5} sx={{mt: 0.5}}>
              {!goal.goalType?.rewardAsAnalog && goal.rewardValue?.value && (
                <Grid item xs={12}>
                  <GoalMoneyProgress
                    progress={goal.progress}
                    goal={goal.rewardValue?.value}
                    symbol={goal.reward?.symbol}
                  />
                </Grid>
              )}
              {qualities && (
                <Grid item xs={12}>
                  <InfoBlock fullWidth label="Качество развития" value={qualities} />
                </Grid>
              )}
              {goal.status !== 'completed' && timeLeft && (
                <Grid item xs={6}>
                  <InfoBlock fullWidth label={isOverdue ? 'Просрочено на' : 'Осталось'} value={timeLeft} />
                </Grid>
              )}
              {goal.status === 'completed' && goal.realFinishDate && (
                <Grid item xs={6}>
                  <InfoBlock
                    fullWidth
                    label="Выполнена за"
                    value={formatDistance(goal.realFinishDate, goal.createdAt)}
                  />
                </Grid>
              )}
              {finishDate && (
                <Grid item xs={6}>
                  <InfoBlock fullWidth label="Цель" value={finishDate} />
                </Grid>
              )}
              <Grid item xs={6}>
                <InfoBlock fullWidth label="Тип цели" value={goalType} />
              </Grid>
              {!isMoneyGoal && goal.rewardValue?.value && (
                <Grid item xs={6}>
                  <InfoBlock fullWidth label="Денежный экв." value={rewardValue} />
                </Grid>
              )}
            </Grid>
          </CardContent>
          <CardContent>
            <CommentsBlock
              referenceName="Goals"
              referenceId={goalId}
              moreLink={'./comments'}
              referenceAuthorId={goal.userId}
            />
          </CardContent>
        </Card>
      </SocialStateProvider>
      {openCloneGoalForm && (
        <Card>
          <Stack spacing={1} sx={{py: 1, px: 2}}>
            <Button onClick={openCloneGoalForm} size="small" variant="contained" fullWidth>
              Копировать цель себе
            </Button>
          </Stack>
        </Card>
      )}

      <GoalReportFeed goal={goal} isMyGoal={isMe} />
    </Stack>
  );
};

export default GoalPage;
