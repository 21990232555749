import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import {WidgetImage} from '../WidgetImage';
import {EntityPreviewProps} from '../types';

export const EntityPreview: React.FC<EntityPreviewProps> = ({
  title,
  caption,
  children,
  imageProps,
  statusProps,
  imagePosition,
  alignItems = 'flex-start',
}) => {
  return (
    <Stack
      width="100%"
      spacing={1.5}
      display="flex"
      justifyContent="space-between"
      direction={imagePosition === 'end' ? 'row' : 'row-reverse'}
    >
      <Box
        flex={1}
        flexGrow={1}
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        justifyContent="center"
        sx={{minWidth: 0}}
      >
        <Stack spacing={0.5} width="100%">
          {caption && (
            <Typography variant="caption" component="div" letterSpacing={0} color="grey.200">
              {caption}
            </Typography>
          )}
          <Typography
            variant="h4"
            fontWeight={500}
            sx={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}
          >
            {title}
          </Typography>
        </Stack>
        {children && (
          <Box mt={0.5} width="100%">
            {children}
          </Box>
        )}
      </Box>
      {imageProps && (
        <WidgetImage sx={{alignSelf: alignItems}} statusProps={statusProps} {...(imageProps || {})} alt={title} />
      )}
    </Stack>
  );
};
