import {Skeleton} from '@mui/material';
import {fill} from 'lodash';

export const GoalSliderContentSkeleton = ({count = 8}: {count?: number}) => (
  <>
    {fill(Array(count), '').map((_, i) => (
      <Skeleton key={i} variant="rectangular" animation="wave" width={80} height={80} />
    ))}
  </>
);
