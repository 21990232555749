import {ButtonMenu} from 'components/ButtonMenu';

import {ReactComponent as PlusIcon} from 'svg/icons/plusIcon.svg';

import {useGoalCreateMenuItem} from '../GoalFormProvider/context';
import {useGoalReportMenuItem} from '../GoalReportForm';

export const GoalsButtonMenu = () => {
  const createFormItem = useGoalCreateMenuItem();
  const createGoalReportItem = useGoalReportMenuItem();

  const buttons = [createFormItem, createGoalReportItem].filter(Boolean);

  if (!buttons.length) return null;
  return (
    <ButtonMenu
      buttonProps={{
        icon: <PlusIcon />,
        variant: 'contained',
        size: 'small',
        sx: {
          px: 2,
        },
      }}
      items={buttons as any}
    />
  );
};
