import {Stack} from '@mui/material';
import {Rewards} from 'api/generated/users-api';

export const ReactionsList = ({rewards}: {rewards: Rewards[]}) => (
  <Stack direction="row-reverse" pl="5px">
    {rewards.map(reward => (
      <Stack
        key={reward._id}
        alignItems="center"
        justifyContent="center"
        ml={-1}
        width={16}
        height={16}
        fontSize={16}
        borderRadius={4}
        boxSizing="content-box"
        border="2px solid white"
        bgcolor="white"
      >
        {reward.symbol}
      </Stack>
    ))}
  </Stack>
);
