import {useCallback} from 'react';

import {QuestPointProps} from '../types';
import {useSetPointReference} from './setReference.hook';

export const useButtonPointPress = (props: QuestPointProps) => {
  const {setReference, referenceProccessing, questPoint, canPress} = useSetPointReference(props);

  const onPress = useCallback(() => {
    if (setReference) setReference();
  }, [setReference]);

  return {
    onPress,
    isLoading: referenceProccessing,
    canPress: questPoint.type === 'button' && canPress,
  };
};
