import {memo} from 'react';
import {useField} from 'formik';
import {isArray, pull, uniq} from 'lodash';
import {Stack, Checkbox, FormControlLabel} from '@mui/material';

import {CheckboxGroupFieldProps, CommonProps} from '../types';

type CheckboxGroupProps = {name: string; type: string} & CommonProps & CheckboxGroupFieldProps['inputProps'];

export const CheckboxGroupField = ({name, options}: CheckboxGroupProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [{value: values, onBlur}, meta, {setValue}] = useField(name);
  if (!isArray(values)) return null;
  return (
    <Stack>
      {options.map(opt => {
        const checked = Boolean(values.includes(opt.value));
        const onChange = (state: boolean) => {
          const newVals = [...values];
          setValue(state ? uniq([...newVals, opt.value]) : pull(newVals, opt.value));
        };
        return (
          <FormControlLabel
            name={name}
            onBlur={onBlur}
            label={opt.name}
            labelPlacement="end"
            key={`${name}.${opt.value}`}
            onChange={() => onChange(!checked)}
            control={<Checkbox checked={checked} />}
          />
        );
      })}
    </Stack>
  );
};

export default memo(CheckboxGroupField);
