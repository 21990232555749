import {Link} from 'react-router-dom';
import {Stack, Box, Typography} from '@mui/material';

import {GREY} from 'components/theme/palette';
import {ReactComponent as LogoDesktop} from 'svg/logoDesktop.svg';

export const Footer = () => (
  <Stack px={2} spacing={1}>
    <LogoDesktop />
    <Typography variant="subtitle1" sx={{color: GREY[200]}}>
      THE&nbsp;GAME — комплексная годовая программа проработки ключевых ﻿сфер жизни через обратную связь и командное
      взаимодействие
    </Typography>
    <Box mt={2} />
    <Typography variant="subtitle1" sx={{color: GREY[400], fontWeight: 500}} component={Link} to="/userAgreement">
      Пользовательское соглашение
    </Typography>
    <Typography variant="subtitle1" sx={{color: GREY[400], fontWeight: 500}} component={Link} to="/privacyPolicy">
      Политика конфиденциальности
    </Typography>
    <Typography variant="subtitle1" sx={{color: GREY[200]}}>
      © 2021 The Game
    </Typography>
  </Stack>
);
