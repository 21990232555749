import {merge} from 'lodash';
import {Theme} from '@mui/material/styles';
import TextField from './TextField';
import Button from './Button';
import Card from './Card';
import Tabs from './Tabs';
import Chip from './Chip';
import Link from './Link';
import Typography from './Typography';

// ----------------------------------------------------------------------

export default function ComponentsOverrides(theme: Theme) {
  return merge(TextField(theme), Typography(theme), Card(theme), Button(theme), Chip(theme), Tabs(theme), Link());
}
