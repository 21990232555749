import {forwardRef} from 'react';
import {Button} from '@mui/material';
import {GREY, PRIMARY} from 'components/theme/palette';

export type SocialButtonProps = {
  icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  text?: number | string;
  onClick: () => void;
  plainText?: boolean;
  isActive?: boolean;
};

export const SocialButton = forwardRef(
  ({icon: Icon, text = '0', onClick, plainText = false, isActive = false}: SocialButtonProps, ref) => (
    <Button
      ref={ref as any}
      size="small"
      color={plainText ? 'primary' : 'secondary'}
      variant={plainText ? 'text' : 'contained'}
      sx={{
        ...{
          '& .MuiButton-startIcon': {
            mr: 0.5,
          },
          fontSize: 15,
          lineHeight: '18px',
        },
        ...(!plainText
          ? {borderRadius: 100, height: 24, py: '5px', px: '8px', backgroundColor: isActive ? 'primary.25' : undefined}
          : {py: 0, px: 1, height: 24, color: 'grey.200'}),
      }}
      onClick={onClick}
      startIcon={Icon && <Icon color={plainText ? GREY[200] : PRIMARY[500]} />}
    >
      {text}
    </Button>
  )
);

export default SocialButton;
