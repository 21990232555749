import {Link} from 'react-router-dom';
import {Groups} from 'api/generated/users-api';
import {Avatar, AvatarProps} from 'components/ProfileImage';

export type GroupProfileImageProps = {
  group: Groups;
  noLink?: boolean;
} & Pick<AvatarProps, 'size' | 'inline'>;

export const GroupProfileImage = ({group, noLink = false, ...props}: GroupProfileImageProps) => {
  const avatarProps: Partial<AvatarProps> = {
    ...props,
    type: 'group',
    fileId: group.fileId,
  };
  const Content = <Avatar {...avatarProps} />;
  if (noLink) return Content;
  return <Link to={`/teams/${group._id}`}>{Content}</Link>;
};
