import {useToggle} from 'react-use';
import Stack from '@mui/material/Stack';

import {QuestPointsProgressData} from 'api/generated/users-api';

import {CollapseAllButton} from 'components/CollapseButton';
import {QuestPointView} from 'modules/oldQuests2/components/QuestPoint';
import {QuestPointContent} from 'modules/oldQuests2/containers/QuestPoint';

export const GroupQuestPointStats = ({questPointsStats}: {questPointsStats: QuestPointsProgressData[]}) => {
  const [collapsedAll, setCollapsedAll] = useToggle(true);
  return (
    <Stack>
      <Stack direction="row" justifyContent="end" py={1.75}>
        <CollapseAllButton onClick={() => setCollapsedAll()} />
      </Stack>
      {questPointsStats.map((pointData, index) => (
        <QuestPointContent
          key={pointData.questPoint._id}
          index={index}
          points={pointData.points}
          collapsedAll={collapsedAll}
          Component={QuestPointView}
          questPointData={pointData}
        />
      ))}
    </Stack>
  );
};
