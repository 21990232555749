import {ReactNode} from 'react';
import {useMatch} from 'react-router';
import Box from '@mui/system/Box';
import Typography from '@mui/material/Typography';

import * as Styled from './styles';
import {NotificationIndication} from '../NotificationIndication';

export const SideNavigationItem = ({
  to,
  Icon,
  text,
  Content,
  isUnread,
  size = 'medium',
  forceWhiteBg = false,
}: {
  to: string;
  text: string;
  isUnread?: boolean;
  Content?: ReactNode;
  forceWhiteBg?: boolean;
  size?: 'medium' | 'big';
  Icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
}) => {
  const match = useMatch(to);
  const IconContent = Content || (Icon && <Box component={Icon} sx={{color: match ? 'primary.main' : 'grey.200'}} />);
  return (
    <Styled.SideContainer
      to={to}
      sx={{
        borderRadius: 2,
        height: size === 'medium' ? 48 : 64,
        backgroundColor: match || forceWhiteBg ? 'white' : 'transparent',
        transition: 'background-color 0.25s',
        '&:hover': {backgroundColor: 'white'},
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          mr: 1.5,
          width: 32,
          height: 32,
          position: 'relative',
        }}
      >
        {IconContent}
        <NotificationIndication isSeen={!isUnread} sx={{position: 'absolute', top: 0, right: 0}} />
      </Box>
      <Typography variant="h4" component="div" sx={{fontWeight: 500}}>
        {text}
      </Typography>
    </Styled.SideContainer>
  );
};

export default SideNavigationItem;
