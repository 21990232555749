import {skipToken} from '@reduxjs/toolkit/dist/query';
import {useSnackbar} from 'notistack';

import {api} from 'api';
import {GroupInviteDeleteInviteApiArg, GroupInvitesSendApiArg, Users, UsersIndexApiArg} from 'api/generated/users-api';

import {useMyStreamId} from 'modules/auth';
import useTypedSelector from 'store/hooks';
import {useSafeContext} from 'utils';

import {ReactComponent as InviteIcon} from 'svg/groupIcons/inviteToGroup.svg';
import {ReactComponent as RemoveIcon} from 'svg/icons/removeGroupMember.svg';

import {useIsMe} from '../auth/hooks/index';
import {GroupContext} from './context';

export const useGroupInviteActions = () => {
  const [inviteF] = api.endpoints.groupInvitesSend.useMutation();
  const [deleteF] = api.endpoints.groupInviteDeleteInvite.useMutation();
  const {enqueueSnackbar} = useSnackbar();

  const sendInvite = async (query: GroupInvitesSendApiArg) => {
    const result = await inviteF(query);
    if ('data' in result) {
      enqueueSnackbar('Приглашение отправлено', {
        autoHideDuration: 2000,
        variant: 'success',
      });
    } else {
      enqueueSnackbar((result.error as any).data.message || 'Ошибка отправки приглашения', {
        autoHideDuration: 2000,
        variant: 'error',
      });
    }
  };
  const deleteInvite = async (query: GroupInviteDeleteInviteApiArg) => {
    const result = await deleteF(query);
    if ('data' in result) {
      enqueueSnackbar('Приглашение отозвано', {
        autoHideDuration: 2000,
        variant: 'success',
      });
    } else {
      enqueueSnackbar((result.error as any).data.message || 'Ошибка отмены приглашения', {
        autoHideDuration: 2000,
        variant: 'error',
      });
    }
  };
  return {sendInvite, deleteInvite};
};

export const useInviteGroupItem = (user: Users) => {
  const {data: settings} = api.endpoints.settingsIndex.useQuery();
  const isMe = useIsMe(user);
  const my = useTypedSelector(store => store.authModule.user);
  const {sendInvite, deleteInvite} = useGroupInviteActions();
  const {request} = api.endpoints.groupInvitesIndex.useQuery(settings?.groups?.allowInvites ? {} : skipToken, {
    selectFromResult: ({data}) => ({
      request: data?.data ? data?.data.find(request => request.invitedUserId === user._id) : null,
    }),
  });

  if (!settings?.groups?.allowInvites) return null;
  if (!my?.groups || !my.groups[0]?.group?._id) return null;
  if (!my.groups[0].isLeader || isMe) return null;
  if (user?.groups?.length) return null;

  if (request) {
    return {
      label: 'Отменить приглашение в команду',
      icon: <RemoveIcon />,
      onClick: () => deleteInvite({inviteId: request._id as string}),
    };
  }

  return {
    label: 'Пригласить в команду',
    icon: <InviteIcon />,
    onClick: () =>
      sendInvite({
        groupInviteDto: {
          groupId: my?.groups![0].group?._id as string,
          invitedUserId: user._id as string,
          message: 'Присоединяйся к нам!',
        },
      }),
  };
};

export const useInviteRequestActions = (isLeader?: boolean) => {
  const [acceptVote, {isLoading: isAcceptVoteLoading}] = api.endpoints.groupRequestApproveVote.useMutation();
  const [declineVote, {isLoading: isDeclineVoteLoaing}] = api.endpoints.groupRequestDeclineVote.useMutation();
  const [approveRequest, {isLoading: isApproveRequestLoaing}] = api.endpoints.groupRequestApproveRequest.useMutation();
  const [declineRequest, {isLoading: isDeclineRequestLoaing}] = api.endpoints.groupRequestDeclineRequest.useMutation();
  const {enqueueSnackbar} = useSnackbar();

  const isLoading = isAcceptVoteLoading || isDeclineVoteLoaing || isApproveRequestLoaing || isDeclineRequestLoaing;

  const approveAction = async (requestId: string) => {
    if (isLoading) return null;
    const query = {requestId};
    const result = isLeader ? await approveRequest(query) : await acceptVote(query);

    if ('data' in result) {
      if (isLeader) {
        enqueueSnackbar('Пользователь принят в команду', {
          autoHideDuration: 2000,
          variant: 'success',
        });
      }
    } else {
      enqueueSnackbar(
        (result.error as any).data.message ||
          `Ошибка ${isLeader ? 'добавления пользователя в команду' : 'голосования'}`,
        {
          autoHideDuration: 2000,
          variant: 'error',
        }
      );
    }
  };
  const declineAction = async (requestId: string) => {
    if (isLoading) return null;
    const query = {requestId};
    const result = isLeader ? await declineRequest(query) : await declineVote(query);

    if ('data' in result) {
      if (isLeader) {
        enqueueSnackbar('Запрос отклонён', {
          autoHideDuration: 2000,
          variant: 'success',
        });
      }
    } else {
      enqueueSnackbar(
        (result.error as any).data.message || `Ошибка ${isLeader ? 'отклонения запроса' : 'голосования'}`,
        {
          autoHideDuration: 2000,
          variant: 'error',
        }
      );
    }
  };

  return {approveAction, declineAction};
};

export const useInvitesLists = (username: string, sort: UsersIndexApiArg['sort'] = 'rating') => {
  const {data: invites, isLoading: isInvitesLoading} = api.endpoints.groupInvitesIndex.useQuery({});
  const streamId = useMyStreamId();
  const {users: groupUsers} = useSafeContext(GroupContext);
  const skipId = [
    ...groupUsers.map(i => i.user?._id as string),
    ...(invites?.data ? invites.data.filter(i => i.invitedUser).map(i => i.invitedUser?._id as string) : []),
  ];
  const {data: users, isLoading: isUsersLoading} = api.endpoints.usersIndex.useQuery(
    username
      ? {
          username,
          streamId,
          sort,
          skipId,
          limit: 20,
        }
      : skipToken
  );

  const mySubscribesUsers = users?.data ? users.data.filter(user => user.meIsSubscriber === 1) : [];
  const mySubscribersUsers = users?.data ? users.data.filter(user => user.isMySubscriber === 1) : [];
  const suggestedUsers = users?.data
    ? users.data.filter(
        user =>
          !(invites?.data ? invites.data.filter(i => i.invitedUser).map(i => i.invitedUser) : []).find(
            i => i?._id === user._id
          ) &&
          !mySubscribesUsers.find(i => i._id === user._id) &&
          !mySubscribersUsers.find(i => i._id === user._id)
      )
    : [];
  const isLoading = isInvitesLoading || isUsersLoading;
  return {mySubscribesUsers, mySubscribersUsers, suggestedUsers, invites, isLoading};
};
