import last from 'lodash/last';

import {FC} from 'react';
import {skipToken} from '@reduxjs/toolkit/dist/query';

import {api} from 'api';

import {Stage} from '../Stage';
import {StageIdProp, StagePointIdProp} from '../../types';
import {useGetStagePointByIdWithSelector} from '../../hooks';
import {selectStagePointCustomOptions} from '../../utils';
import {Box} from '@mui/material';
import {LoadingButton} from '@mui/lab';

export const SubscriptionContainer: FC<StagePointIdProp & StageIdProp> = props => {
  const pointId = props.stagePointId;
  const {isActive, options} = useGetStagePointByIdWithSelector(props, data => ({
    isActive: data.stagePoint?.status !== 'new',
    options: selectStagePointCustomOptions(data),
  }));
  const {lastStageId, isLoading: subscriptionsLoading} = api.endpoints.stagePointsSubscriptionsStagesIds.useQuery(
    {pointId},
    {
      skip: !isActive,
      selectFromResult: ({data, ...rest}) => ({
        ...rest,
        lastStageId: last(data?._id || []),
      }),
    }
  );
  const [add, {isLoading}] = api.endpoints.stagePointsSubscriptionsAdd.useMutation();
  const {
    status,
    stageExists,
    isLoading: stageLoading,
  } = api.endpoints.stageIndex.useQuery(lastStageId ? {stageId: lastStageId} : skipToken, {
    skip: !isActive,
    selectFromResult: ({data, ...rest}) => ({
      ...rest,
      status: data?.stage.status,
      stageExists: !!data?.stage,
    }),
  });

  if (subscriptionsLoading || stageLoading || subscriptionsLoading || !isActive) return null;
  if (lastStageId && stageExists && status !== 'completed') return <Stage stageId={lastStageId} hideHeader />;
  return (
    <Box px={2} py={2}>
      <LoadingButton fullWidth size="large" variant="contained" loading={isLoading} onClick={() => add({pointId})}>
        {options?.subscriptionAddButtonText || 'Добавить отчет'}
      </LoadingButton>
    </Box>
  );
};
