import {Box, Skeleton, Stack} from '@mui/material';

export const QuestItemListSkeleton = () => (
  <Stack direction="row" spacing={1.5} py={1.5} px={2}>
    <Stack flex={1} spacing={1}>
      <Skeleton variant="text" animation="wave" width="75%" height={16} sx={{transform: 'none'}} />
      <Skeleton variant="text" animation="wave" width="25%" height={12} sx={{transform: 'none'}} />
      <Box pt={0.5}>
        <Skeleton variant="text" animation="wave" width="100%" height={5} sx={{transform: 'none'}} />
      </Box>
    </Stack>
    <Skeleton variant="rectangular" animation="wave" width={56} height={56} sx={{borderRadius: 2}} />
  </Stack>
);
