import {useCallback} from 'react';
import {api} from 'api';
import {GoalsIndexApiArg} from 'api/generated/users-api';

export const useGetGoalsList = (params: Omit<GoalsIndexApiArg, 'limit' | 'offset'>) => {
  const [getGoals, result] = api.endpoints.goalsIndex.useLazyQuery();
  const getGoalsItems = useCallback(
    ({offset, limit}: {offset: number; limit: number}) => {
      getGoals({...params, limit, offset});
    },
    [params, getGoals]
  );

  return {getGoalsItems, result};
};
