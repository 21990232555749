import {FC} from 'react';
import {useMeasure} from 'react-use';
import {Box} from '@mui/material';
import {StagePointListProps} from 'modules/quests/types';
import {useIsMobile} from 'utils';
import {StagePointList} from './StagePointsList';

// const fixedProps: Partial<BoxProps> = {
//   position: 'fixed',
//   left: 0,
//   bottom: 60,
//   zIndex: 11,
// };

export const BottomButtonsContainer: FC<Omit<StagePointListProps, 'containerName'> & {inline?: boolean}> = ({
  inline,
  ...props
}) => {
  const [ref, {height}] = useMeasure();
  const isMobile = useIsMobile();

  return (
    <Box sx={isMobile ? {minHeight: height} : undefined} p={isMobile ? 0 : 2}>
      <Box width={1} p={!inline && isMobile ? 2 : 0} {...(!inline && isMobile ? {} : {})} ref={ref}>
        <StagePointList {...props} spacing={1} containerName="bottomButtonsContainer" />
      </Box>
    </Box>
  );
};
