import {useSnackbar} from 'notistack';
import {useUpdateAtom} from 'jotai/utils';
import * as yup from 'yup';
import 'yup-phone';

import {ReactNode, useCallback, useState, useEffect} from 'react';
import {ErrorMessage, Form, FormikProvider, useFormik} from 'formik';

import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import FormControlLabel from '@mui/material/FormControlLabel';

import {authApi} from 'api';
import {VALIDATION_TEXTS} from 'utils';
import {UsersLoginsTokens} from 'api/generated/auth-api';
import {TOKEN_STORAGE_NAME} from 'modules/auth/constants';
import {VerificationCodeInput} from 'modules/auth/components';

import {ConfirmCodeProps} from './types';
import {openHash, privacyDialogAtom, termsDialogAtom} from '../PrivacyPolicy/atoms';

const ResendButton = ({onSend, isSuccess}: {onSend: () => void; isSuccess: boolean}) => {
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    let timer: any;
    if (disabled) timer = setTimeout(() => setDisabled(false), 60000);
    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [disabled]);

  useEffect(() => {
    if (isSuccess) setDisabled(true);
  }, [isSuccess]);

  return (
    <LoadingButton fullWidth variant="text" disabled={disabled} onClick={onSend}>
      Выслать код повторно
    </LoadingButton>
  );
};

const codeValidation = yup.object().shape({
  code: yup.string().length(6).required(VALIDATION_TEXTS.required).matches(/^\d+$/),
  policyConfirmed: yup.boolean().required(VALIDATION_TEXTS.required).oneOf([true], VALIDATION_TEXTS.required),
  privacyConfirmed: yup.boolean().required(VALIDATION_TEXTS.required).oneOf([true], VALIDATION_TEXTS.required),
});

export const ConfirmCode = ({
  onSuccess,
  Header,
  login,
  policyConfirmationRequired,
  privacyConfirmationRequired,
}: ConfirmCodeProps & {
  onSuccess?: (data: UsersLoginsTokens) => void;
  Header?: ReactNode;
}) => {
  const {enqueueSnackbar, closeSnackbar} = useSnackbar();
  const [sendCode, {isSuccess: codeResent}] = authApi.endpoints.loginRouteRequestCode.useMutation();
  const [confirmCode] = authApi.endpoints.loginRouteConfirmCode.useMutation();
  const formik = useFormik({
    validationSchema: codeValidation,
    initialValues: {
      code: '',
      policyConfirmed: !policyConfirmationRequired,
      privacyConfirmed: !privacyConfirmationRequired,
    },
    onSubmit: async (data, {setSubmitting, setFieldError}) => {
      const result = await confirmCode({body: {...data, login}});
      setSubmitting(false);
      if ('error' in result) {
        if ('data' in result.error) {
          const message = (result.error.data as any).message;
          if (message) setFieldError('code', message);
          else {
            const key = enqueueSnackbar('Прозола неизвестная ошибка, обратитесь в службу заботы', {variant: 'error'});
            setTimeout(() => closeSnackbar(key), 3000);
          }
        }
      } else if ('data' in result && result.data.accessToken) {
        localStorage.setItem(TOKEN_STORAGE_NAME, result.data.accessToken);
        if (onSuccess) onSuccess(result.data);
      }
    },
  });
  const resendCode = useCallback(() => sendCode({body: {login}}), [login, sendCode]);

  const open = useUpdateAtom(openHash);
  const {dirty, isValid, isSubmitting, getFieldProps, values, setFieldValue} = formik;
  return (
    <>
      {Header}
      <FormikProvider value={formik}>
        <Form>
          <Stack spacing={2}>
            <Stack spacing={1}>
              <VerificationCodeInput
                length={6}
                value={values.code}
                disabled={isSubmitting}
                onComplete={code => setFieldValue('code', code, true)}
              />
              <ErrorMessage name="code">
                {message => (
                  <Stack direction="row" justifyContent="space-between">
                    <Typography variant="subtitle1" color="error">
                      {message}
                    </Typography>
                    {/* <Typography
                      variant="subtitle1"
                      color="primary"
                      component={Link}
                      onClick={() => {
                        setFieldError('code', undefined);
                        setFieldValue('code', '', false);
                      }}
                    >
                      Сбросить
                    </Typography> */}
                  </Stack>
                )}
              </ErrorMessage>
            </Stack>
            {policyConfirmationRequired && (
              <FormControlLabel
                label={
                  <Typography variant="body1" sx={{pt: 0.25}}>
                    <span>Я соглашаюсь с&nbsp;</span>
                    <Link
                      sx={{color: 'primary.500'}}
                      underline="hover"
                      onClick={e => {
                        e.preventDefault();
                        open(termsDialogAtom);
                      }}
                    >
                      правилами пользования
                    </Link>
                  </Typography>
                }
                labelPlacement="end"
                sx={{alignItems: 'flex-start'}}
                control={
                  <Checkbox
                    {...getFieldProps('policyConfirmed')}
                    sx={{pl: 0, pt: 0}}
                    checked={values.policyConfirmed}
                  />
                }
              />
            )}
            {privacyConfirmationRequired && (
              <FormControlLabel
                label={
                  <Typography variant="body1" sx={{pt: 0.25}}>
                    <span>Даю согласие на обработку&nbsp;</span>
                    <Link
                      sx={{color: 'primary.500'}}
                      underline="hover"
                      onClick={e => {
                        e.preventDefault();
                        open(privacyDialogAtom);
                      }}
                    >
                      персональных данных
                    </Link>
                  </Typography>
                }
                labelPlacement="end"
                sx={{alignItems: 'flex-start'}}
                control={
                  <Checkbox
                    {...getFieldProps('privacyConfirmed')}
                    sx={{pl: 0, pt: 0}}
                    checked={values.privacyConfirmed}
                  />
                }
              />
            )}
            <Box pt={2}>
              <LoadingButton
                fullWidth
                type="submit"
                variant="contained"
                loading={isSubmitting}
                disabled={!dirty || !isValid || isSubmitting}
              >
                {policyConfirmationRequired || privacyConfirmationRequired ? 'Зарегистрироваться' : 'Войти'}
              </LoadingButton>
              <Box mt={2}>
                <ResendButton onSend={resendCode} isSuccess={codeResent} />
              </Box>
            </Box>
          </Stack>
        </Form>
      </FormikProvider>
    </>
  );
};

export default ConfirmCode;
