import * as yup from 'yup';
import {yupFiles, yupFilesRequired, yupPrivacy, yupRatio, yupStringRequired} from 'utils';
import {GoalsReports} from 'api/generated/users-api';

export const initialValues = {
  report: {
    filesId: [] as string[],
    description: '',
    rewardValue: '',
    filesAspectRatio: '1:1' as '1:1' | '16:9' | '4:5' | '4:3' | undefined,
  },
  publication: {
    content: '',
    privacy: 'public' as any,
    enabled: true,
  },
  createPublication: false,
  goalCompleted: false,
};

export const getInitialValues = (report?: GoalsReports) => {
  if (report) {
    return {
      report: {
        filesId: report.filesId || [],
        description: report.publication?.content || report.description || '',
        rewardValue: report.rewardValue?.value.toString() || '',
        filesAspectRatio: report.filesAspectRatio || '1:1',
      },
      publication: {
        content: report.publication?.content || '',
        privacy: 'public' as any,
        enabled: true,
      },
      createPublication: false,
      goalCompleted: false,
    };
  } else {
    return initialValues;
  }
};

const reportSchema = (isMoneyGoal: boolean, filesRequired: boolean) =>
  yup.object().shape({
    filesId: filesRequired ? yupFilesRequired : yupFiles,
    rewardValue: isMoneyGoal ? yup.number().required().min(0) : yup.number(),
    description: yupStringRequired.min(3, 'Минимальное количество символов для отчета – 3'),
    filesAspectRatio: yupRatio,
  });

const publicationSchema = yup.object().shape({
  privacy: yupPrivacy,
});

export const validationSchema = (isMoneyGoal: boolean) =>
  yup.object().shape({
    report: yup.object().when('createPublication', {
      is: true,
      then: reportSchema(isMoneyGoal, true),
      otherwise: reportSchema(isMoneyGoal, false),
    }),
    publication: yup
      .object()
      .when('createPublication', {is: true, then: publicationSchema, otherwise: publicationSchema}),
    goalCompleted: yup.boolean().default(false),
    createPublication: yup.boolean().default(false).when('goalCompleted', {is: true, then: yup.boolean().required()}),
  });
