import {useNavigate} from 'react-router';
import Box from '@mui/system/Box';
import CircularProgress from '@mui/material/CircularProgress';
import {getActiveQuestPath} from 'modules/oldQuests2/utils';
import {api} from 'api';
import {croppedImagePath} from 'utils';

export const QuestProgressBadge = ({userId}: {userId: string; currentSeason?: boolean}) => {
  const navigate = useNavigate();
  const {data, isLoading} = api.endpoints.questsCurrentSeasonProgress.useQuery({userId: [userId]});
  const stage = data?.stages ? data.stages[0] : undefined;
  if ((!data || !stage) && isLoading) return <CircularProgress />;
  if (!data || !stage) return null;

  const backgroundImage = data.quest.design?.backgroundFileId
    ? `url(${croppedImagePath(data.quest.design?.backgroundFileId, {ratio: '16:9', maxWidth: 24})})`
    : '';

  return (
    <Box
      onClick={() => navigate(getActiveQuestPath(stage?._id || ''))}
      sx={{
        height: '40px',
        borderRadius: '5rem',
        backgroundColor: data.quest.design?.backgroundColor || 'primary.main',
        backgroundImage,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        width: 'auto',
        cursor: 'pointer',
      }}
    >
      <Box
        sx={{
          typography: 'body2',
          borderRadius: 5,
          color: 'white',
          display: 'flex',
          alignItems: 'center',
          backgroundColor: 'rgba(52, 64, 84, 0.4)',
          backdropFilter: data.quest.design?.backgroundFileId ? 'blur(10px)' : '',
          width: '100%',
          height: '100%',
          px: 1.5,
        }}
      >
        {data.quest.design?.fileId && (
          <Box
            sx={{borderRadius: 1, height: 24, width: 24, mr: 0.5}}
            component="img"
            src={croppedImagePath(data.quest.design?.fileId, {ratio: '1:1', maxWidth: 200})}
          />
        )}
        <Box component="span" sx={{fontWeight: 'bold', color: 'white', whiteSpace: 'nowrap'}}>
          {stage.totalProgress?.requiredPointsCompleted || 0}&nbsp;/&nbsp;
          {stage.totalProgress?.requiredPointsTotal || data.questRequiredPoints}
        </Box>
      </Box>
    </Box>
  );
};
