import {styled} from '@mui/system';
import {Card, CardHeader, CardContent, Stack, Box, Typography, Link} from '@mui/material';

import {HeaderSetup, InfoBlock} from 'components/Layout';

import PostItem from 'modules/search/components/PostItem';
import GoalItem from 'modules/search/components/GoalItem';
import {SearchBlock, SearchTabs, Divider, TeamItem} from 'modules/search/components';

const StyledLink = styled(Link)(() => ({
  padding: '3px 0',
  marginLeft: 'auto',
  fontWeight: 400,
}));

export const SearchFocusPageMarkup = () => {
  return (
    <>
      <HeaderSetup title="" />
      <Stack spacing={0.5}>
        <Card>
          <CardContent>
            <SearchBlock />
          </CardContent>
          <SearchTabs />
        </Card>

        <Card>
          <CardHeader
            title={
              <Box display="flex" alignItems="center">
                <Typography variant="h3">Недавние результаты поиска</Typography>
                <StyledLink underline="none" variant="body2" href="#">
                  Очистить
                </StyledLink>
              </Box>
            }
          />
          <CardContent>
            <Divider title={'Цели'} />
            <Stack gap={0.25}>
              <GoalItem
                userName="ivanderbilov"
                goalName="Mercedes S Coupe 63 AMG"
                score={93.2}
                type="gold"
                goalInfo={
                  <>
                    <InfoBlock size="small" label="Осталось" value={'1 год 6 месяцев'} />
                    <InfoBlock size="small" label="Цель" value={'3 года'} />
                    <InfoBlock size="small" label="Денежный экв." value={'200 000₽'} />
                  </>
                }
                isMoneyGoal
              />

              <GoalItem
                userName="pdll"
                goalName="Заработать 1 000 000₽ за месяц"
                score={98.24}
                type="bronze"
                goalInfo={
                  <>
                    <InfoBlock size="small" label="Завершена за" value={'2 дня'} />
                    <InfoBlock size="small" label="Цель" value={'Месяц'} />
                    <InfoBlock size="small" label="Денежный экв." value={'200 000₽'} />
                  </>
                }
              />
            </Stack>

            <Divider title={'Пользователи'} />
            <Stack gap={0.25}>
              {/* <UserItem title={'ivanderbilov'} followers={'435 подписчиков'} type="gold" score={99.13} />
              <UserItem title={'ksenia.garaeva'} followers={'108 подписчиков'} score={75.13} />
              <UserItem title={'pdll'} followers={'108 подписчиков'} type="bronze" score={98.24} /> */}
            </Stack>

            <Divider title={'Команды'} />
            <Stack gap={0.25}>
              <TeamItem
              // title={'Фемида Team'}
              // members={'10 участников'}
              // comment={'Команда лучших юристов'}
              // type="gold"
              // score={99.67}
              />
              <TeamItem
              // title={'FortuneClub'}
              // members={'9 участников'}
              // comment={`Набираем персон с доходом\nот $50к/мес`}
              // score={89.99}
              />
            </Stack>

            <Divider title={'Посты'} />
            <Stack gap={0.25}>
              <PostItem
                userName={'ksenia.garaeva'}
                description={
                  <Typography variant="body2" display="inline-block">
                    ... Благо дарности пост 🙏 <br />
                    Последний месяц я работаю почти без выходных, что сильно выделяет ...
                  </Typography>
                }
                score={75.13}
              />
              <PostItem
                userName={'ksenia.garaeva'}
                description={
                  <Typography variant="body2" display="inline-block">
                    ... благо даря своему упорству я осуществила свою давнюю цель – я позавтракала в Кении в сафаре ...
                  </Typography>
                }
                score={75.13}
                isCompleted
              />
              <PostItem
                userName={'ivanderbilov'}
                description={
                  <Typography variant="body2" display="inline-block">
                    ... В свое время, благодаря себя, я упустил момент, когда все алгоритмы показа постов поменялись.
                    ...
                  </Typography>
                }
                score={93.2}
              />
            </Stack>
          </CardContent>
        </Card>
      </Stack>
    </>
  );
};

export default SearchFocusPageMarkup;
