import {keys} from 'lodash';
import {AnswerDto} from 'api/generated/users-api';

export const clearCachedJobAnswers = (jobId: string) => localStorage.removeItem(`job_${jobId}`);

export const getCachedJobAnswers = (jobId: string) => {
  const cachedJobAnswers: {[key: string]: AnswerDto[] | undefined} = JSON.parse(
    localStorage.getItem(`job_${jobId}`) || '{}'
  );
  return keys(cachedJobAnswers)?.length ? cachedJobAnswers : undefined;
};

export const setCachedJobAnswers = (jobId: string, scriptId: string, answers: AnswerDto[]) => {
  const cachedJobAnswers = getCachedJobAnswers(jobId) || {};
  cachedJobAnswers[scriptId] = answers;
  localStorage.setItem(`job_${jobId}`, JSON.stringify(cachedJobAnswers));
  return cachedJobAnswers;
};

export const modifyCachedJobAnswers = (jobId: string, scriptId: string, dto: AnswerDto) => {
  const cachedJobAnswers = getCachedJobAnswers(jobId) || {};
  const cachedDto =
    cachedJobAnswers[scriptId]?.filter(
      i =>
        !(
          i.scriptActionId === dto.scriptActionId &&
          i.templateOptionId === dto.templateOptionId &&
          i.index === dto.index
        )
    ) || [];
  const newJobAnswers = {...cachedJobAnswers, ...{[scriptId]: [...cachedDto, dto]}};
  localStorage.setItem(`job_${jobId}`, JSON.stringify(newJobAnswers));

  return newJobAnswers;
};
