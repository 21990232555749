import {Box, SxProps} from '@mui/system';
import {ReactComponent as CheckIcon} from 'svg/quest/questCheck.svg';
import {ReactComponent as LockFillIcon} from 'svg/quest/lockFill.svg';

export const StatusIcon = ({status, sx}: {status?: 'locked' | 'completed' | 'new'; sx?: SxProps}) => {
  if (!status) return null;
  let backgroundColor = 'transparent';
  let color: string | undefined = undefined;
  let Icon: any = undefined;

  switch (status) {
    case 'completed': {
      color = 'white';
      Icon = CheckIcon;
      backgroundColor = 'success.main';
      break;
    }
    case 'locked': {
      Icon = LockFillIcon;
      backgroundColor = 'warning.light';
      break;
    }
    case 'new': {
      backgroundColor = '#EC096F';
      break;
    }
  }

  return (
    <Box
      sx={{
        borderRadius: 2,
        width: 12,
        height: 12,
        boxSizing: 'content-box',
        border: '1px solid #fff',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: Icon ? backgroundColor : 'white',
        ...sx,
      }}
    >
      <Box
        component={Icon}
        sx={{
          width: 8,
          height: 8,
          color,
          borderRadius: status === 'new' ? 2 : undefined,
          backgroundColor: status === 'new' ? backgroundColor : undefined,
        }}
      />
    </Box>
  );
};
