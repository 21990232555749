import {RefObject} from 'react';
import {Menu, Stack, MenuItem} from '@mui/material';
import {compose} from 'lodash/fp';

import {Rewards} from 'api/generated/users-api';

type ReactionsMenuProps = {
  open: boolean;
  close: () => void;
  rewards: Rewards[];
  selected?: string;
  action: (rewardId: string) => Promise<void>;
  anchorElRef: RefObject<HTMLButtonElement | null>;
};

export const ReactionsMenu = ({open, close, anchorElRef, rewards, action, selected}: ReactionsMenuProps) => (
  <Menu
    open={open}
    onClose={close}
    anchorEl={anchorElRef.current}
    sx={{
      '& .MuiList-root': {py: 0},
      '& .MuiMenu-paper': {borderRadius: 2},
    }}
  >
    <Stack
      direction="row"
      alignItems="center"
      p={0.25}
      sx={{
        '& > .MuiMenuItem-root': {
          py: 0.5,
          px: 0.5,
          width: 36,
          height: 36,
          minHeight: 36,
          fontSize: 22,
          borderRadius: 1.5,
        },
        '& > .MuiMenuItem-root, & > .MuiMenuItem-root + .MuiMenuItem-root': {mt: 0},
        '& > .MuiMenuItem-root:not(:last-of-type)': {mr: '1px'},
      }}
    >
      {rewards.map(i => (
        <MenuItem
          key={i._id}
          selected={i._id === selected}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            backgroundColor: 'transparent',
            transition: 'background-color .5s',
            '&:hover': {backgroundColor: 'secondary.main'},
            '&:focus': {backgroundColor: 'secondary.dark'},
            '&:acitve': {backgroundColor: 'secondary.dark'},
          }}
          onClick={compose(() => action(`${i._id}`), close)}
        >
          {i.symbol}
        </MenuItem>
      ))}
    </Stack>
  </Menu>
);
