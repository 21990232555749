import SegmentedControl from 'components/SegmentedControl';
import {ReactComponent as GridIcon} from 'svg/icons/gridView.svg';
import {ReactComponent as ListIcon} from 'svg/icons/listView.svg';
import {ReactComponent as CardIcon} from 'svg/icons/cardViewIcon.svg';

const options = [
  {value: 'grid', ariaLabel: 'Отобразить плиткой', content: <GridIcon />},
  {value: 'list', ariaLabel: 'Отобразить списком', content: <ListIcon />},
  {value: 'card', ariaLabel: 'Отобразить карточками', content: <CardIcon />},
];

export const GridListControl = ({
  value,
  onChange,
  includeOptions = ['grid', 'list'],
}: {
  value?: string;
  onChange: (newValue?: string | number) => void;
  includeOptions?: ('grid' | 'list' | 'card')[];
}) => {
  const displayOptions = options.filter(item => includeOptions.includes(item.value as any));
  return <SegmentedControl value={value} onChange={onChange} buttons={displayOptions} />;
};
