import {WidgetImage} from 'components/EntityWidgets';
import {WidgetImageProps} from 'components/EntityWidgets/types';
import {VisualComponent} from '../../types';

export type ImageComponentProps = Partial<WidgetImageProps>;

export const ImageComponent: VisualComponent<ImageComponentProps> = ({options, value}) => {
  if (!value) return null;
  return <WidgetImage fileId={value} alt="" {...options} />;
};
