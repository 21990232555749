import {Link} from 'react-router-dom';
import React, {useContext} from 'react';

import Box from '@mui/system/Box';
import styled from '@mui/system/styled';
import Stack from '@mui/material/Stack';
import CardMedia from '@mui/material/CardMedia';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';

import {api} from 'api';
import {croppedImagePath} from 'utils';

import {ReactComponent as RepostIcon} from 'svg/icons/repostIcon.svg';

import {RemovedEntry} from 'components/RestoreEntry';
import {useDialogView} from 'components/Dialog/context';
import {getPostPath} from 'modules/feed/utils';
import {GoalContext} from 'modules/profile/context';
import {GoalReportContextMenu} from 'modules/goals/containers';
import {GoalMoneyProgress} from '..';
import {GoalReportProps} from './types';

const Container = styled('div')`
  border: 1px solid;
  box-sizing: border-box;

  width: 100%;
  max-width: 279px;
  margin: 0 auto;
`;

export const GoalReport = ({goalReport: initialData, goalMoneyGoal}: GoalReportProps) => {
  const goal = useContext(GoalContext);
  const {data: updateData} = api.endpoints.goalReportIndex.useQueryState({
    goalId: `${goal?._id}`,
    reportId: `${initialData._id}`,
  });
  const goalReport = updateData || initialData;
  const openPost = useDialogView('feed', goalReport.publicationId);

  if (goalReport.deletedAt) return <RemovedEntry />;
  return (
    <Container sx={{borderColor: goalReport.goalClosed ? 'success.main' : 'grey.25', borderRadius: 2}}>
      <CardHeader title="Отчет" action={<GoalReportContextMenu goalReport={goalReport} authorId={goal?.user?._id} />} />
      {goalReport.filesId && goalReport.filesId[0] && (
        <CardMedia
          component="img"
          src={croppedImagePath(goalReport.filesId[0], {ratio: goalReport.filesAspectRatio || '1:1', maxWidth: 500})}
        />
      )}
      <Box p={2}>
        <Stack spacing={1}>
          <GoalMoneyProgress
            progress={goalReport.localProgress}
            increment={goalReport.rewardValue?.value}
            goal={goalMoneyGoal}
          />
          <Typography variant="body2" sx={{lineHeight: '18px'}}>
            {goalReport.publication?.content || goalReport.description}
          </Typography>
          {!!goalReport.publicationId && (
            <Box
              component={Link}
              to={getPostPath(goalReport.publicationId)}
              onClick={(e: React.MouseEvent) => {
                e.preventDefault();
                if (openPost) openPost();
              }}
              sx={{color: 'grey.200', typography: 'body2', gap: 0.5, alignItems: 'center', display: 'flex'}}
            >
              <RepostIcon />
              Смотреть пост
            </Box>
          )}
        </Stack>
      </Box>
    </Container>
  );
};

export default GoalReport;
