import {pick} from 'lodash';
import {api as rawAuthApi} from './generated/auth-api';
import {api as rawUsersApi} from './generated/users-api';
import {api as rawFilesApi} from './generated/files-api';

export const api = rawUsersApi.enhanceEndpoints({
  addTagTypes: [
    'MyProfilePhoto',
    'MyProfile',
    'Profile',
    'Comments',
    'Contents',
    'Tasks',
    'Jobs',
    'ScriptStatus',
    'ScriptAnswers',
    'JobsAnswers',
    'Publications',
    'Goals',
    'GoalReports',
    'Group',
    'GroupInvites',
    'GroupRequests',
    'Widgets',
    'UsersList',
    'Notifications',
    'NotificationsCount',
    'Quests',
    'Stages',
    'StagesPoints',
    'Seen',
    'Reactions',
    'Payments',
  ],
  endpoints: {
    appLogout: {
      invalidatesTags: [
        'MyProfilePhoto',
        'MyProfile',
        'Profile',
        'Comments',
        'Contents',
        'Tasks',
        'Jobs',
        'JobsAnswers',
        'Publications',
        'Goals',
        'GoalReports',
        'Group',
        'GroupInvites',
        'GroupRequests',
        'Widgets',
        'UsersList',
        'Notifications',
        'NotificationsCount',
        'Quests',
        'Stages',
        'StagesPoints',
        'Seen',
        'Reactions',
      ],
    },
    // SEEN
    contentsUnreadedCount: {
      providesTags: [
        {type: 'Seen', id: 'Contents'},
        {type: 'Seen', id: 'all'},
      ],
    },
    tasksUnreadedCount: {
      providesTags: [
        {type: 'Seen', id: 'Tasks'},
        {type: 'Seen', id: 'all'},
      ],
    },
    questsUnreadedCount: {
      providesTags: [
        {type: 'Seen', id: 'Quests'},
        {type: 'Seen', id: 'all'},
      ],
    },
    viewsSetViewed: {
      invalidatesTags: (_, __, {referenceName, referenceId}) => [
        {type: 'Seen', id: referenceName},
        {type: referenceName, id: referenceId},
      ],
    },
    // STAGES
    stagePointClearReference: {
      invalidatesTags: (data, _, {pointId}) => [
        {type: 'Stages', id: data?.stagePoint?.stageId},
        {type: 'StagesPoints', id: pointId},
        {type: 'StagesPoints', id: data?.stagePoint?.questPointId},
      ],
    },
    stagePointSetReference: {
      invalidatesTags: (data, _, {pointId}) => [
        {type: 'Stages', id: data?.stagePoint?.stageId},
        {type: 'StagesPoints', id: pointId},
        {type: 'StagesPoints', id: data?.stagePoint?.questPointId},
      ],
      onQueryStarted: async (args, {dispatch, queryFulfilled}) => {
        try {
          await queryFulfilled;
          dispatch(api.util.prefetch('stagePointIndex', {pointId: args.pointId, partial: args.partial}, {force: true}));
        } catch (e) {}
      },
    },
    stagePointActivateTimeline: {
      invalidatesTags: (data, _, {pointId}) => [
        {type: 'StagesPoints', id: pointId},
        {type: 'StagesPoints', id: data?.stagePoint?.questPointId},
      ],
    },
    stagePointIndex: {
      providesTags: data => [{type: 'StagesPoints' as const, id: data?.stagePoint?._id}],
    },
    stagePointsIndex: {
      providesTags: data => {
        const list = [{type: 'StagesPoints' as const, id: 'type.list'}];
        return (data || [])
          .map(item => ({
            type: 'StagesPoints' as const,
            id: item.stagePoint?._id || item.questPointData.questPoint._id,
          }))
          .concat(list);
      },
    },
    stagesIndex: {
      providesTags: (data, __, {questType}) => {
        const list = [{type: 'Stages' as const, id: `type.${questType}`}];
        return (data?.data || []).map(item => ({type: 'Stages' as const, id: item._id})).concat(list);
      },
    },
    stageIndex: {
      providesTags: data => [{type: 'Stages' as const, id: data?.stage._id}],
    },
    // QUESTS
    questsIndex: {
      providesTags: data => {
        const list = [{type: 'Quests' as const, id: 'type.list'}];
        return (data?.data || []).map(item => ({type: 'Quests' as const, id: item._id})).concat(list);
      },
    },
    questAccept: {
      invalidatesTags: (data, _, {questId}) => [
        {type: 'Quests' as const, id: questId},
        {type: 'Stages' as const, id: `type.${data?.stage.questType}`},
      ],
    },
    // NOTIFICATIONS
    notificationsIndex: {
      providesTags: (_, __, {unseenOnly}) => [{type: 'Notifications', id: unseenOnly ? 'unseen' : 'all'}],
    },
    notificationsReadAll: {
      invalidatesTags: [{type: 'Notifications', id: 'unseen'}, 'NotificationsCount'],
    },
    notificationsUnreadedCount: {
      providesTags: ['NotificationsCount'],
      // onCacheEntryAdded: async (arg, {cacheDataLoaded, cacheEntryRemoved, dispatch}) => {
      //   try {
      //     await cacheDataLoaded;
      //     const listener = (e: MessageEvent) => {
      //       dispatch(api.util.prefetch('notificationsUnreadedCount', undefined, {force: true}));
      //       dispatch(api.util.prefetch('notificationsIndex', {unseenOnly: 1}, {force: true}));
      //     };
      //     const ws = sockets.connect('api/notifications', {onmessage: listener});
      //     await cacheEntryRemoved;
      //     ws.close();
      //   } catch {}
      // },
    },
    // USERS
    usersIndex: {
      providesTags: ['UsersList'],
    },
    // WIDGET
    widgetsIndex: {
      providesTags: data =>
        (data || [])?.map(item => {
          let id = '';
          if (item.jobId) id = `job.${item.jobId}`;
          if (item.taskId) id = `task.${item.taskId}`;
          if (item.collectorId) id = `collector.${item.collectorId}`;
          return {type: 'Widgets', id};
        }),
    },
    // TASKS
    jobScriptsStatuses: {
      providesTags: (_, __, {jobId}) => [{type: 'ScriptStatus', id: jobId}],
    },
    jobAnswersSaveAnswers: {
      invalidatesTags: (_, __, {jobId}) => [{type: 'ScriptStatus', id: jobId}],
    },
    jobAnswersGetAnswers: {
      providesTags: (_, __, {jobId, scriptId}) => [{type: 'ScriptAnswers', id: `job.${jobId}-script.${scriptId}`}],
    },
    // JOBS
    jobsIndex: {
      providesTags: (_, __, {status}) => (status || []).map(item => ({type: 'Jobs', id: `list.${item}`})),
    },
    jobUniversalComplete: {
      invalidatesTags: (_, __, {jobId}) => [
        {type: 'Widgets'},
        {type: 'Jobs', id: `list.new`},
        {type: 'ScriptStatus', id: jobId},
        {type: 'JobsAnswers', id: `job.${jobId}`},
        {type: 'Jobs', id: `structure.${jobId}`},
      ],
      onQueryStarted: async (_, {dispatch, queryFulfilled}) => {
        try {
          const result = await queryFulfilled;
          if (result.data.publicationId)
            dispatch(api.util.prefetch('publicationIndex', {postId: result.data.publicationId}, {force: true}));
        } catch {}
      },
    },
    jobStructure: {
      providesTags: (_, __, {jobId}) => [{type: 'Jobs', id: `structure.${jobId}`}],
    },
    jobIndex: {
      providesTags: (_, __, {jobId}) => [{type: 'Jobs', id: jobId}],
    },
    jobAnswersIndex: {
      providesTags: (_, __, {jobId}) => [{type: 'JobsAnswers', id: `job.${jobId}`}],
    },
    jobMakeEditable: {
      invalidatesTags: (_, __, {jobId}) => [
        {type: 'Jobs', id: `list.new`},
        {type: 'Jobs', id: `structure.${jobId}`},
        {type: 'JobsAnswers', id: `job.${jobId}`},
      ],
    },
    // PUBLICATIONS
    publicationPatch: {
      invalidatesTags: (_, __, {postId}) => [{type: 'Publications', id: postId}],
      onQueryStarted: async ({postId}, {dispatch, queryFulfilled}) => {
        try {
          await queryFulfilled;
          dispatch(api.util.prefetch('publicationIndex', {postId}, {force: true}));
        } catch {}
      },
    },
    publicationSafeRestore: {
      onQueryStarted: async ({postId}, {dispatch, queryFulfilled}) => {
        try {
          await queryFulfilled;
          dispatch(api.util.prefetch('publicationIndex', {postId}, {force: true}));
        } catch {}
      },
    },
    publicationSafeDelete: {
      onQueryStarted: async ({postId}, {dispatch, queryFulfilled}) => {
        try {
          await queryFulfilled;
          dispatch(api.util.prefetch('publicationIndex', {postId}, {force: true}));
        } catch {}
      },
      invalidatesTags: () => [{id: 'postList.deleted', type: 'Publications'}],
    },
    publicationUpdate: {
      invalidatesTags: (_, __, {postId}) => [{type: 'Publications', id: postId}],
      onQueryStarted: async ({postId}, {dispatch, queryFulfilled}) => {
        try {
          await queryFulfilled;
          dispatch(api.util.prefetch('publicationIndex', {postId}, {force: true}));
        } catch {}
      },
    },
    publicationIndex: {
      providesTags: (_, __, {postId}) => [{type: 'Publications', id: postId}],
    },
    publicationsIndex: {
      providesTags: (data, _, params) => {
        const tags = [
          {
            id: `postList.${JSON.stringify(pick(params, ['userId', 'groupId', 'streamId']))}`,
            type: 'Publications' as const,
          },
        ];
        if (params.isDeleted) tags.push({id: 'postList.deleted', type: 'Publications'});
        return (data?.data || []).map(post => ({id: post._id, type: 'Publications' as const})).concat(tags);
      },
    },
    // GROUP INVITES
    groupInviteAccept: {
      invalidatesTags: ['GroupInvites'],
      onQueryStarted: async (_, {dispatch, queryFulfilled}) => {
        try {
          const result = await queryFulfilled;
          dispatch(api.util.prefetch('groupIndex', {groupId: result.data.groupId}, {force: true}));
        } catch {}
      },
    },
    groupInviteDecline: {
      invalidatesTags: ['GroupInvites'],
    },
    groupInviteIndex: {
      providesTags: ['GroupInvites'],
    },
    groupInvitesIndex: {
      providesTags: ['GroupInvites'],
    },
    groupInvitesSend: {
      invalidatesTags: ['GroupInvites'],
    },
    groupInviteDeleteInvite: {
      invalidatesTags: ['GroupInvites'],
    },
    // GROUP REQUESTS
    groupRequestApproveRequest: {
      invalidatesTags: (_, __, {requestId}) => [{type: 'GroupRequests', id: requestId}],
      onQueryStarted: async (_, {dispatch, queryFulfilled}) => {
        try {
          const result = await queryFulfilled;
          dispatch(api.util.prefetch('groupIndex', {groupId: result.data.groupId}, {force: true}));
        } catch {}
      },
    },
    groupRequestDeclineRequest: {
      invalidatesTags: (_, __, {requestId}) => [{type: 'GroupRequests', id: requestId}],
    },
    groupRequestsSend: {
      invalidatesTags: data => [{type: 'GroupRequests', id: data?._id}],
      onQueryStarted: async (_, {dispatch, queryFulfilled}) => {
        try {
          await queryFulfilled;
          dispatch(api.util.prefetch('groupRequestsIndex', {}, {force: true}));
        } catch {}
      },
    },
    groupRequestApproveVote: {invalidatesTags: (_, __, {requestId}) => [{type: 'GroupRequests', id: requestId}]},
    groupRequestDeclineVote: {invalidatesTags: (_, __, {requestId}) => [{type: 'GroupRequests', id: requestId}]},
    groupRequestsIndex: {
      providesTags: data => (data?.data || []).map(request => ({id: request._id, type: 'GroupRequests' as const})),
    },
    // GROUP
    groupIndex: {
      providesTags: (_, __, {groupId}) => [{type: 'Group', id: groupId}],
    },
    groupPatch: {
      invalidatesTags: (_, __, {groupId}) => [{type: 'Group', id: groupId}],
    },
    groupUpdate: {
      invalidatesTags: (_, __, {groupId}) => [{type: 'Group', id: groupId}],
    },
    groupKickOut: {
      invalidatesTags: (_, __, {groupId}) => [{type: 'Group', id: groupId}],
    },
    groupLeaveGroup: {
      invalidatesTags: (_, __, {groupId}) => [{type: 'Group', id: groupId}, 'MyProfile'],
    },
    groupDestroy: {
      invalidatesTags: (_, __, {groupId}) => [{type: 'Group', id: groupId}, 'MyProfile'],
    },
    // USER PHOTOS
    userPhotosAdd: {
      invalidatesTags: ['MyProfilePhoto', 'MyProfile'],
    },
    userPhotoUpdate: {
      invalidatesTags: ['MyProfilePhoto', 'MyProfile'],
    },
    userPhotoDelete: {
      invalidatesTags: ['MyProfilePhoto', 'MyProfile'],
    },
    userPhotosProfilePhoto: {
      providesTags: ['MyProfilePhoto'],
    },
    profileIndex: {
      providesTags: ['MyProfile'],
    },
    profileUpdate: {
      invalidatesTags: data => ['MyProfile', {type: 'Profile', id: data?.account._id}],
    },
    userIndex: {
      providesTags: data => [{type: 'Profile', id: data?.account._id}],
    },
    userToggleSubscription: {
      invalidatesTags: (_, __, {userId}) => [
        {type: 'Profile', id: userId},
        {
          id: `goalList.${JSON.stringify({userId})}`,
          type: 'Goals' as const,
        },
        {
          id: `postList.${JSON.stringify({userId})}`,
          type: 'Publications' as const,
        },
      ],
    },
    // COMMENTS
    commentSafeDelete: {
      onQueryStarted: async ({commentId}, {dispatch, queryFulfilled}) => {
        try {
          await queryFulfilled;
          dispatch(api.util.prefetch('commentIndex', {commentId}, {force: true}));
        } catch {}
      },
    },
    commentSafeRestore: {
      onQueryStarted: async ({commentId}, {dispatch, queryFulfilled}) => {
        try {
          await queryFulfilled;
          dispatch(api.util.prefetch('commentIndex', {commentId}, {force: true}));
        } catch {}
      },
    },
    commentsIndex: {
      providesTags: (data, __, {referenceId, referenceName}) =>
        (data?.data || [])
          .map(comment => ({id: `comment.${comment._id}`, type: 'Comments' as const}))
          .concat([{id: `${referenceName}.${referenceId}`, type: 'Comments' as const}]),
    },
    commentsAdd: {
      invalidatesTags: (_, __, {referenceId, referenceName}) => [
        {id: `${referenceName}.${referenceId}`, type: 'Comments'},
      ],
      onQueryStarted: async ({addCommentDto}, {queryFulfilled, dispatch}) => {
        if (addCommentDto.commentId) {
          try {
            await queryFulfilled;
            dispatch(api.util.prefetch('commentIndex', {commentId: addCommentDto.commentId}, {force: true}));
          } catch {}
        }
      },
    },
    // GOALS
    goalSafeDelete: {
      onQueryStarted: async ({goalId}, {dispatch, queryFulfilled}) => {
        try {
          await queryFulfilled;
          dispatch(api.util.prefetch('goalIndex', {goalId}, {force: true}));
        } catch {}
      },
    },
    goalsIndex: {
      providesTags: (data, _, params) => {
        return (data?.data || [])
          .map(goal => ({id: goal._id, type: 'Goals' as const}))
          .concat([
            {
              id: `goalList.${JSON.stringify(pick(params, ['userId', 'groupId', 'streamId']))}`,
              type: 'Goals' as const,
            },
          ]);
      },
    },
    goalIndex: {
      providesTags: (_, __, {goalId}) => [{type: 'Goals', id: goalId}],
    },
    goalsAdd: {
      invalidatesTags: data => [{type: 'Goals', id: `goalList.${JSON.stringify({userId: data?.userId})}`}, 'MyProfile'],
    },
    goalUpdate: {
      invalidatesTags: (data, _, {goalId}) => [
        {id: `goal.${goalId}`, type: 'GoalReports'},
        {id: goalId, type: 'Goals'},
      ],
    },
    goalSafeRestore: {
      onQueryStarted: async ({goalId}, {dispatch, queryFulfilled}) => {
        try {
          await queryFulfilled;
          dispatch(api.util.prefetch('goalIndex', {goalId}, {force: true}));
        } catch {}
      },
    },
    // GOAL REPORT
    goalReportsIndex: {
      providesTags: (data, __, {goalId}) =>
        (data || [])
          .map(report => ({id: `report.${report._id}`, type: 'GoalReports' as const}))
          .concat([{id: `goal.${goalId}`, type: 'GoalReports' as const}]),
    },
    goalReportSafeDelete: {
      invalidatesTags: (data, __, {goalId}) => [{id: goalId, type: 'Goals'}],
      onQueryStarted: async ({goalId, reportId}, {dispatch, queryFulfilled}) => {
        try {
          await queryFulfilled;
          dispatch(api.util.prefetch('goalReportIndex', {goalId, reportId}, {force: true}));
        } catch {}
      },
    },
    goalReportsAdd: {
      invalidatesTags: (_, __, {goalId}) => [
        {id: `goal.${goalId}`, type: 'GoalReports'},
        {id: goalId, type: 'Goals'},
      ],
    },
    goalReportUpdate: {
      onQueryStarted: async ({goalId, reportId}, {dispatch, queryFulfilled}) => {
        try {
          await queryFulfilled;
          dispatch(api.util.prefetch('goalReportIndex', {goalId, reportId}, {force: true}));
        } catch {}
      },
    },
    goalArchive: {
      invalidatesTags: (_, __, {goalId}) => [
        {id: `goal.${goalId}`, type: 'GoalReports'},
        {id: goalId, type: 'Goals'},
        'MyProfile',
      ],
    },
    // REACTIONS
    reactionsIndex: {
      providesTags: data => (data?.data || []).map(reaction => ({id: reaction._id, type: 'Reactions' as const})),
    },
    reactionsAdd: {
      invalidatesTags: data => [{type: 'Reactions', id: (data?.reactionsId as any)?.[0]?._id}],
      onQueryStarted: async ({referenceId, referenceName}, {dispatch, queryFulfilled}) => {
        try {
          await queryFulfilled;
          dispatch(api.util.prefetch('reactionsIndex', {referenceId, referenceName}, {force: true}));
        } catch {}
      },
    },
    reactionUpdate: {
      invalidatesTags: (_, __, {reactionId}) => [{type: 'Reactions', id: reactionId}],
    },
    reactionDelete: {
      invalidatesTags: (_, __, {reactionId}) => [{type: 'Reactions', id: reactionId}],
    },
    // PAYMENTS
    paymentIndex: {
      providesTags: (data, __, {paymentId}) => [
        {type: 'Payments', id: paymentId},
        {type: 'StagesPoints', id: data?.stagePointId},
      ],
    },
    paymentsIndex: {
      providesTags: data => (data?.data || []).map(payment => ({id: payment?._id, type: 'Payments' as const})),
    },
    paymentRefresh: {
      providesTags: (data, __, {paymentId}) => [{type: 'Payments', id: paymentId}],
      onQueryStarted: async ({paymentId}, {dispatch, queryFulfilled}) => {
        try {
          const result = await queryFulfilled;
          dispatch(api.util.prefetch('paymentIndex', {paymentId}, {force: true}));
          if (result.data.stagePointId)
            dispatch(api.util.prefetch('stagePointIndex', {pointId: result.data.stagePointId}, {force: true}));
        } catch {}
      },
    },
  },
});

export const authApi = rawAuthApi.enhanceEndpoints({});
export const filesApi = rawFilesApi.enhanceEndpoints({});
