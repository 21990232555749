import {Provider} from 'jotai';
import {useUpdateAtom} from 'jotai/utils';

import {PropsWithChildren} from 'react';
import {useEffectOnce} from 'react-use';
import {scopeAtom, setSocialState} from 'modules/social/store';
import {CommentsStats, LikesStats, ReactionsStats, ScoresStats} from 'api/generated/users-api';

export const SocialStateProvider = ({
  children,
  scope,
  ...state
}: PropsWithChildren<{
  score?: ScoresStats;
  likes?: LikesStats;
  comments?: CommentsStats;
  scope: string;
  reactions?: ReactionsStats;
}>) => {
  const setState = useUpdateAtom(setSocialState);
  useEffectOnce(() => {
    setState({...state, scope});
  });

  return (
    <Provider initialValues={[[scopeAtom, scope]]} scope={Symbol.for('social-keys')}>
      {children}
    </Provider>
  );
};
