import {styled} from '@mui/system';
import {TabContext, TabList, TabPanel} from '@mui/lab';
import {Box, Card, CardContent, CardHeader, Stack, Tab, Typography} from '@mui/material';

import _ from 'lodash/fp';
import {useQueryParam, StringParam} from 'use-query-params';

import {api} from 'api';
import {CollectorKeyValuesDto, CollectorValuesByTypeApiArg} from 'api/generated/users-api';
import {GREY} from 'components/theme/palette';

export type CollectorTypePageProps = {
  type: CollectorValuesByTypeApiArg['type'];
  userId: string;
};

// value - score | number
// fileId - file | photo | video | audio
// goalId - goal
// date - date (string ISO)
// text - any

const Position = styled('div')`
  width: 20px;
  height: 20px;
  border-radius: 10px;
  box-sizing: border-box;

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 10px;
  line-height: 10px;
  color: ${GREY[200]};
  border: 1px solid ${GREY[25]};
`;

const Collector = ({data}: {code: string; data: CollectorKeyValuesDto[]}) => {
  return (
    <Stack spacing={0.5}>
      {data.map(item => {
        const showIndex = item.values.length > 1;
        return (
          <Card key={item.key._id}>
            <CardHeader title={item.key.name} />
            <CardContent>
              <Stack spacing={1}>
                {item.values.map((item, i) => (
                  <Stack spacing={1} direction="row" key={i}>
                    {showIndex && <Position>{i + 1}</Position>}
                    <Typography variant="body2" key={`${item.type}-${i}`}>
                      {item.text}
                    </Typography>
                  </Stack>
                ))}
              </Stack>
            </CardContent>
          </Card>
        );
      })}
    </Stack>
  );
};

export const CollectorTypePage = ({type, userId}: CollectorTypePageProps) => {
  const {data, tabs} = api.endpoints.collectorValuesByType.useQuery(
    {type, userId},
    {
      selectFromResult: ({data, ...rest}) => ({
        ...rest,
        tabs: _.map(_.compose(_.pick(['name', 'code']), _.get('collector')))(data || []),
        data: (data || []).reduce(
          (acc, item) => ({...acc, [`${item.collector.code}`]: item.keyValues}),
          {} as {[K: string]: CollectorKeyValuesDto[]}
        ),
      }),
    }
  );
  const [tab, setTab] = useQueryParam('tab', StringParam);

  return (
    <TabContext value={tab || tabs[0]?.code || ''}>
      <Box sx={{backgroundColor: 'white'}} mb={0.5}>
        <TabList onChange={(e, newTab) => setTab(newTab)} variant="scrollable">
          {tabs.map(item => (
            <Tab key={item.code} label={item.name} value={item.code} />
          ))}
        </TabList>
      </Box>
      {Object.keys(data).map(code => (
        <TabPanel value={code} key={`collector-${code}`} sx={{p: 0}}>
          <Collector data={data[code]} code={code} />
        </TabPanel>
      ))}
    </TabContext>
  );
};
