import {ClaimsIndexApiArg} from 'api/generated/users-api';

export const entitiesNames: Record<ClaimsIndexApiArg['referenceName'], string> = {
  Goals: 'цель',
  Tasks: 'задание',
  Contents: 'материал',
  GoalsReports: 'отчет',
  Users: 'пользователя',
  Comments: 'комментарий',
  Jobs: 'отчет по заданию',
  Publications: 'публикацию',
};
