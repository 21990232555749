import {ReactNode, Fragment} from 'react';
import {Box, Stack, Typography} from '@mui/material';
import {GREY} from 'components/theme/palette';

export type InfoBlockProps = {
  label: string;
  value: string | null;
  size?: 'small' | 'medium';
  fullWidth?: boolean;
};

export type InfoBlockContainerProps = {
  items: (Omit<InfoBlockProps, 'size' | 'fullWidth'> | ReactNode)[][];
  size?: 'small' | 'medium';
};

const isInfoBlock = (data: any): data is InfoBlockProps => !!data.label;

export const InfoBlock = ({label, value, size = 'medium', fullWidth = false}: InfoBlockProps) => {
  return (
    <Box sx={fullWidth ? {width: '100%'} : {}}>
      <Typography variant={size === 'medium' ? 'body2' : 'subtitle2'} sx={{whiteSpace: 'nowrap'}} color={GREY[200]}>
        {label}
      </Typography>
      <Box mt={0.5}>
        <Typography variant={size === 'medium' ? 'body1' : 'subtitle1'}>{value}</Typography>
      </Box>
    </Box>
  );
};

export const InfoBlockContainer = ({items, size}: InfoBlockContainerProps) => (
  <Stack
    spacing={1}
    sx={{
      p: 1,
      borderRadius: 2,
      border: '1px solid',
      width: 'fit-content',
      borderColor: 'divider',
      backgroundColor: 'white',
    }}
  >
    {items.map((row, i) => {
      if (!row.length) return null;
      return (
        <Stack direction="row" spacing={2} key={i} alignItems="flex-end">
          {row.map((item, k) => {
            return isInfoBlock(item) ? (
              <InfoBlock size={size} key={item.label} {...item} />
            ) : (
              <Fragment key={k}>{item}</Fragment>
            );
          })}
        </Stack>
      );
    })}
  </Stack>
);

export default InfoBlock;
