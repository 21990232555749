import {skipToken} from '@reduxjs/toolkit/dist/query';
import {Box, Stack, Typography} from '@mui/material';

import {api} from 'api';
import {GroupsRequests} from 'api/generated/users-api';

import {useSafeContext} from 'utils';
import {formatDate} from 'utils/dates';

import {UserItem} from 'modules/search/components';
import {GroupRequestVotePanel} from 'modules/groups/containers';
import {useMyStreamId} from 'modules/auth';
import {GroupContext} from 'modules/groups/context';
import {UserSmallItem} from 'components/UserSmallItem';

type GroupRequestItemProps = {
  request: GroupsRequests;
  showVotedUsers?: boolean;
};

export const GroupRequestItem = ({request, showVotedUsers}: GroupRequestItemProps) => {
  const group = useSafeContext(GroupContext);
  const streamId = useMyStreamId();
  const isLeader = group.isLeader;
  const {data: usersData} = api.endpoints.usersIndex.useQuery(
    isLeader
      ? {_id: [...request.approvedUsersId, ...request.declinedUsersId], sort: 'created_date', streamId}
      : skipToken
  );
  const users = usersData?.data;
  const user = request.user;
  if (!user || !request._id) return null;

  return (
    <Box bgcolor="white">
      <Box py={1}>
        <Typography variant="subtitle2" ml={2} mb={0.5} color="grey.200" fontSize="12px" lineHeight="12px">
          {formatDate(request.createdAt || '', 'd MMMM в kk:mm')}
        </Typography>
        <UserItem user={user} noDisclaimer={true} showSubscribers={true} noHover={true} />
      </Box>
      {showVotedUsers && (!!request.approvedUsersId.length || !!request.declinedUsersId.length) && (
        <Stack direction="row" borderTop="1px solid" borderBottom="1px solid" borderColor="grey.10">
          <Stack minWidth="50%" borderRight="1px solid" borderColor="grey.10" py={1} px={2}>
            {(users || [])
              .filter(i => request.approvedUsersId.indexOf(i._id as string) + 1)
              .map(i => (
                <UserSmallItem user={i} color="grey.200" />
              ))}
          </Stack>
          <Stack minWidth="50%" py={1} px={2}>
            {(users || [])
              .filter(i => request.declinedUsersId.indexOf(i._id as string) + 1)
              .map(i => (
                <UserSmallItem user={i} color="grey.200" />
              ))}
          </Stack>
        </Stack>
      )}
      <Box borderTop="1px solid" borderColor="grey.10">
        <GroupRequestVotePanel request={request} />
      </Box>
    </Box>
  );
};
