import {Stack} from '@mui/material';
import {CommentsListSkeleton} from '../../Feed';
import {GoalItemSkeleton} from '../components';

export const GoalCommentsPageSkeleton = () => (
  <Stack spacing={0.5}>
    <GoalItemSkeleton />
    <CommentsListSkeleton />
  </Stack>
);
