import {api} from 'api';
import {marked} from 'marked';
import Box from '@mui/system/Box';
import Stack from '@mui/material/Stack';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';

import {getUserName} from 'modules/profile/utils';
import {PublicationIdProp} from 'modules/feed/types';

import {Avatar} from 'components/ProfileImage';
import {LazyImage} from 'components/ImageGallery';
import {SocialButtonsGroup} from 'components/SocialButton';
import {WidgetBox} from 'components/EntityWidgets';

export const PublicationWidget = ({
  postId,
  hideImage,
  showBox = true,
}: PublicationIdProp & {hideImage?: boolean; showBox?: boolean}) => {
  const {data: post, isLoading, isError, error} = api.endpoints.publicationIndex.useQuery({postId});
  if (isLoading) return null;
  if (isError && (error as any)?.status === 403) return <>Публикация скрыта</>;
  if (!post) return null;

  let content = '';
  if (post) {
    const lexer = marked.lexer(post.content || '');
    const paragraph = lexer.find(item => item.type === 'paragraph');
    if (paragraph && 'text' in paragraph) content = paragraph.text;
  }

  const Content = (
    <Stack spacing={1.5} direction="row">
      {!hideImage && isLoading && <Skeleton variant="rectangular" width={48} height={48} sx={{borderRadius: 1.5}} />}
      {!hideImage && !isLoading && post?.filesId && (
        <Box width={48} height={48} flexShrink={1} position="relative">
          <LazyImage ratio="1:1" width={200} alt="Публикация" fileId={post.filesId[0]} borderRadius={1.5} />
          {post?.user && (
            <Box
              sx={{
                top: -5,
                right: -5,
                position: 'absolute',
                borderRadius: '50%',
                borderWidth: 2,
                borderColor: 'white',
                borderStyle: 'solid',
              }}
            >
              <Avatar fileId={post.user.fileId} size="xsmall" />
            </Box>
          )}
        </Box>
      )}

      <Stack flexGrow={1} spacing={1}>
        {isLoading ? (
          <Skeleton variant="text" width="30%" sx={{typography: 'subtitle1'}} />
        ) : (
          <Typography variant={post?.user ? 'subtitle1' : 'caption'} sx={{color: 'grey.200'}}>
            {post?.user ? getUserName(post.user) : 'ПОСТ'}
          </Typography>
        )}
        {isLoading ? (
          <Box>
            <Skeleton variant="text" sx={{typography: 'body2'}} />
            <Skeleton variant="text" sx={{typography: 'body2'}} />
          </Box>
        ) : (
          <Typography
            variant="body2"
            sx={{
              lineClamp: 3,
              WebkitLineClamp: 3,
              WebkitBoxOrient: 'vertical',
              display: '-webkit-box',
              overflow: 'hidden',
            }}
          >
            {content}
          </Typography>
        )}
        {isLoading && <Skeleton variant="text" width="20%" />}
        {!isLoading && (
          <SocialButtonsGroup
            rating={post?.score?.average}
            likesCount={post?.likes?.count}
            commentsCount={post?.comments?.count}
          />
        )}
      </Stack>
    </Stack>
  );
  if (!showBox) return Content;
  return <WidgetBox>{Content}</WidgetBox>;
};
