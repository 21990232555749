import {Provider} from 'jotai';
import {useToggle} from 'react-use';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Collapse from '@mui/material/Collapse';

import {Deadline} from 'components/Deadline';
import {QuestHeader} from 'modules/oldQuests2/components';
import {WidgetHoverBox} from 'components/EntityWidgets';
import {QuestProp, StageProp} from 'modules/oldQuests2/types';

import {QuestContents} from './QuestContents';
import {StageContents} from './StageContents';
import {MarkdownRender} from 'components/MarkdownRender';
import {rootStageIdAtom} from './atom';

export const Quest: React.VFC<QuestProp & Partial<StageProp> & {alwaysOpened?: boolean}> = ({
  quest,
  stage,
  alwaysOpened,
}) => {
  const [expandedState, setExpanded] = useToggle(false);
  const expanded = alwaysOpened || expandedState;
  const Content = (
    <Provider initialValues={[[rootStageIdAtom, stage?._id]]} scope={Symbol.for('root-stage')}>
      <Provider initialValues={[[rootStageIdAtom, stage?._id]]} scope={Symbol.for('parent-stage')}>
        <Box sx={{overflowX: 'hidden'}}>
          <Box
            onClick={alwaysOpened ? undefined : () => setExpanded()}
            px={1}
            py={1.25}
            sx={{
              borderBottomWidth: 1,
              borderBottomStyle: 'solid',
              transition: 'border-bottom-color .3s',
              cursor: alwaysOpened ? 'default' : 'pointer',
              borderBottomColor: expanded && !alwaysOpened ? 'grey.10' : 'transparent',
            }}
          >
            <QuestHeader
              name={quest.name}
              subtitle={quest.design?.hint}
              fileId={quest.design?.fileId}
              progress={stage?.totalProgress}
              status={stage?.status || 'new'}
            />
          </Box>

          <Collapse in={expanded} mountOnEnter unmountOnExit>
            <Box zIndex={3} my={1.5} position="relative">
              <Stack spacing={2} mx={-1}>
                {!!stage && (
                  <StageContents
                    stageId={`${stage._id}`}
                    description={
                      <Stack px={2} spacing={2}>
                        {quest.description && <MarkdownRender content={quest.description} />}
                        <Deadline timelines={stage.timeline} />
                      </Stack>
                    }
                  />
                )}
                {!stage && (
                  <QuestContents
                    quest={quest}
                    description={
                      <Stack px={2} spacing={2}>
                        {quest.description && <MarkdownRender content={quest.description} />}
                        <Deadline timelines={quest.timelines} />
                      </Stack>
                    }
                  />
                )}
                {/* {!alwaysOpened && stage && isMe && (
              <Box justifyContent="center" display="flex" py={1}>
                <Button
                  variant="text"
                  target="_blank"
                  component={Link}
                  endIcon={<OpenExternalLinkIcon />}
                  sx={{height: 32, color: 'grey.200'}}
                  to={stage ? getActiveQuestPath(`${stage._id}`) : getQuestPath(quest._id)}
                >
                  Перейти в квест
                </Button>
              </Box>
            )} */}
              </Stack>
            </Box>
          </Collapse>
        </Box>
      </Provider>
    </Provider>
  );

  if (!alwaysOpened)
    return (
      <WidgetHoverBox px={0} py={0} showShadow={expandedState}>
        {Content}
      </WidgetHoverBox>
    );
  return <Card sx={{px: 1, pb: 0.5}}>{Content}</Card>;
};
