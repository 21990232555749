import {Stack} from '@mui/material';
import {fill} from 'lodash';
import {GroupRequestSkeleton} from '../components';

export const GroupRequestsListSkeleton = () => (
  <Stack spacing={1} p={2} bgcolor="white">
    {fill(Array(3), '').map((_, i) => (
      <GroupRequestSkeleton key={i} />
    ))}
  </Stack>
);
