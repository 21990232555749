import {ReactComponent as AddPostIcon} from 'svg/icons/addPostIcon.svg';

import ButtonMenu from 'components/ButtonMenu';
import {GREY, PRIMARY} from 'components/theme/palette';
import {useGoalCreateMenuItem} from 'modules/goals/containers';
import {useGoalReportMenuItem} from 'modules/goals/containers';
import {usePublicationFormMenuItem} from 'modules/feed/containers';
import {ButtonMenuProps} from 'components/ButtonMenu/ButtonMenu';

export const HeaderButtonMenu = ({
  iconColor = 'grey',
  size,
  Button,
}: {
  iconColor: 'grey' | 'blue';
  size: 'small' | 'medium';
  Button?: ButtonMenuProps['Button'];
}) => {
  const postItem = usePublicationFormMenuItem();
  const goalFormItem = useGoalCreateMenuItem();
  const goalReportFormItem = useGoalReportMenuItem();
  const items = [postItem, goalFormItem, goalReportFormItem].filter(Boolean);

  if (!items.length) return null;
  return (
    <ButtonMenu
      variant="iconButton"
      buttonProps={{
        size,
        sx: {},
        icon: <AddPostIcon color={iconColor === 'grey' ? GREY[200] : PRIMARY[500]} />,
      }}
      Button={Button}
      items={items}
    />
  );
};
