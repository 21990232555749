import {Box, Stack, Typography} from '@mui/material';

import {Users} from 'api/generated/users-api';
import {QuestPointProgress} from 'api/generated/users-api';
import {useSafeContext} from 'utils';

import {GroupContext} from 'modules/groups/context';
import {UserSmallItem} from 'components/UserSmallItem';

export const GroupUsersProgress = ({progress}: {progress: QuestPointProgress[]}) => {
  const {users} = useSafeContext(GroupContext);

  const completedUsers =
    progress.find(progressData => ['completed', 'ready'].includes(progressData.status))?.users || [];
  const notCompletedUsers = users
    .filter(i => i.user)
    .map(i => i.user as Users)
    .filter(user => !completedUsers.find(u => u._id === user._id));
  return (
    <Stack
      direction="row"
      p={1}
      borderRadius={1.5}
      mx={2}
      my={0.5}
      sx={{boxShadow: theme => theme.customShadows.default}}
    >
      <Stack width="50%" spacing={0.75}>
        <Typography variant="subtitle1" color="grey.200" fontWeight="500">
          Выполнили
        </Typography>
        {completedUsers.map(user => (
          <Box key={user._id}>
            <UserSmallItem user={user} fontSize={12} lineHeight="12px" fontWeight={400} />
          </Box>
        ))}
      </Stack>
      <Stack width="50%" spacing={0.75}>
        <Typography variant="subtitle1" color="grey.200" fontWeight="500">
          Не выполнили
        </Typography>
        {notCompletedUsers.map(user => (
          <Box key={user._id}>
            <UserSmallItem user={user} fontSize={12} lineHeight="12px" fontWeight={400} />
          </Box>
        ))}
      </Stack>
    </Stack>
  );
};
