import {useState} from 'react';
import {useNavigate} from 'react-router';

import {AuthHeader, AuthInnerLayout} from 'modules/auth/components';
import {ConfirmCode, Login, UserSettings, useConfirmationSettings} from 'modules/auth/containers';

import {useSafeContext} from 'utils';
import {ReactComponent as UserIcon} from 'svg/auth/user.svg';
import {ReactComponent as MessageIcon} from 'svg/auth/message.svg';
import {ReactComponent as SuccessIcon} from 'svg/auth/success.svg';

import {AuthInviteContext} from './hook';

export default function AuthInviteResgo() {
  const navigate = useNavigate();
  const [showUserSettings, setShowUserSettings] = useState(false);
  const [confirmationSettings, setSettings] = useConfirmationSettings();
  const {login, user} = useSafeContext(AuthInviteContext);
  const onFinish = () => navigate('/my-profile');
  const onBackClick = () => {
    if (showUserSettings) setShowUserSettings(false);
    else if (confirmationSettings) setSettings(undefined);
  };

  return (
    <AuthInnerLayout backClick={!confirmationSettings ? undefined : onBackClick}>
      {!confirmationSettings && (
        <Login
          initialLogin={login.value}
          initialLoginType={login.type as 'phone' | 'email'}
          onSuccess={setSettings}
          Header={
            <AuthHeader
              icon={<UserIcon />}
              title={`${user.name}!`}
              subtitle="Приветствуем тебя в системе по развитию личности! Для завершения регистрации необходимо подтвердить твой номер телефона, который был тобой указан 
        при оплате доступа"
            />
          }
        />
      )}
      {confirmationSettings && !showUserSettings && (
        <ConfirmCode
          {...confirmationSettings}
          onSuccess={() => setShowUserSettings(true)}
          Header={
            <AuthHeader
              icon={<MessageIcon />}
              title="Введите код подтверждения"
              subtitle={
                confirmationSettings.loginType === 'phone'
                  ? 'На указанный номер телефона отправлен SMS-код'
                  : 'На указанный email отправлен код'
              }
            />
          }
        />
      )}
      {showUserSettings && (
        <>
          <AuthHeader
            icon={<SuccessIcon />}
            title="Супер"
            subtitle="Твой номер телефона подтвержден, добро пожалось в систему!"
            subtitle2="Можешь заполнить базовую информацию 
        о себе прямо тут. Или же беги изучать задания, ты всегда можешь отредактировать информацию о себе в своем профиле"
          />
          <UserSettings onSkip={onFinish} onSuccess={onFinish} />
        </>
      )}
    </AuthInnerLayout>
  );
}
