interface CustomShadowOptions {
  default: string;
  inset: string;
}

declare module '@mui/material/styles' {
  interface Theme {
    customShadows: CustomShadowOptions;
  }
  interface ThemeOptions {
    customShadows?: CustomShadowOptions;
  }
}

export const customShadows = {
  inset: 'inset 0px 0px 1px rgba(0, 0, 0, 0.3)',
  default: `0px 0px 2px rgba(16, 24, 40, 0.08), 0px 1px 2px rgba(16, 24, 40, 0.1)`,
};
