import memoize from 'lodash/memoize';
import {createSelector} from '@reduxjs/toolkit';

import {StagePointData} from 'api/generated/users-api';
import {stagePointComponentsMap} from '../components/StagePoints';
import {QuestPointContainerNames, StagePointProps, SubscriptionContainerNames, WidgetsContainerNames} from '../types';

const selectBaseStagePointData = (item: StagePointData) =>
  ({
    stageId: item.stagePoint?.stageId,
    questPointId: item.questPointData.questPoint._id,
    stagePointId: item.stagePoint?._id,
    type: item.questPointData.questPoint.type,
    available: item.questPointData.timelineCheck.available,
    containerName: ((item.questPointData.questPoint.options.design as any)?.containerType as string) || 'default',
    hasSubscriptions: Boolean(item.questPointData.questPoint.options.subscriptions?.isActive),
    hasWidgets: Boolean(
      item.questPointData.questPoint.options.widgets?.visualsId ||
        item.questPointData.questPoint.options.widgets?.widgetId ||
        item.questPointData.questPoint.options.widgets?.widgetsId
    ),
  } as StagePointProps);

export const getStagePointsLengthByContainerName = createSelector(
  (points?: StagePointData[]) => points || [],
  points =>
    memoize(
      (containerName: QuestPointContainerNames) =>
        points.filter(
          item =>
            (item.questPointData.questPoint.options.design?.containerName || 'default') === containerName &&
            !item.questPointData.timelineCheck.skipped
        ).length
    )
);

export const getWidgetsByContainerName = createSelector(
  (points?: StagePointData[]) => points || [],
  points =>
    memoize((containerName: WidgetsContainerNames) =>
      points
        .filter(item => {
          const options = selectStagePointCustomOptions(item);
          return (
            (options.widgetsContainerName || 'default') === containerName &&
            !item.questPointData.timelineCheck.skipped &&
            Boolean(
              item.questPointData.questPoint.options.widgets?.visualsId ||
                item.questPointData.questPoint.options.widgets?.widgetId ||
                item.questPointData.questPoint.options.widgets?.widgetsId
            )
          );
        })
        .map(selectBaseStagePointData)
    )
);

export const getSubscriptionsByContainerName = createSelector(
  (points?: StagePointData[]) => points || [],
  points =>
    memoize((containerName: SubscriptionContainerNames) =>
      points
        .filter(item => {
          const options = selectStagePointCustomOptions(item);
          return (
            (options.subscriptionContainerName || 'default') === containerName &&
            !item.questPointData.timelineCheck.skipped &&
            Boolean(item.questPointData.questPoint.options.subscriptions?.isActive)
          );
        })
        .map(selectBaseStagePointData)
    )
);

export const getStagePointsByContainerName = createSelector(
  (points?: StagePointData[]) => points || [],
  points =>
    memoize((containerName: QuestPointContainerNames) =>
      points
        .filter(
          item =>
            (item.questPointData.questPoint.options.design?.containerName || 'default') === containerName &&
            !item.questPointData.timelineCheck.skipped
        )
        .map(selectBaseStagePointData)
    )
);

export const selectStagePointCustomOptions = (point: StagePointData) =>
  (point.questPointData.questPoint.options.design?.customPropsJSONString
    ? JSON.parse(point.questPointData.questPoint.options.design?.customPropsJSONString)
    : {}) as {
    hidePointOnDone?: boolean;
    showIfDisabled?: boolean;
    subscriptionAddButtonText?: string;
    stagePointComponent?: keyof typeof stagePointComponentsMap;
    acceptOnRender?: boolean;
    widgetsContainerName?: string;
    subscriptionContainerName?: string;
  };

export const buttonStagePointPropsSelector = createSelector(
  (point: StagePointData) => point,
  point => {
    return {
      status: point.stagePoint?.status,
      color: point.questPointData.questPoint.options.buttons?.color || 'primary',
      variant: point.questPointData.questPoint.options.buttons?.variant || 'contained',
      name: point.questPointData.questPoint.options.buttons?.title || point.questPointData.name || '',
      disabled: !point.questPointData.timelineCheck.available || point.questPointData.timelineCheck.skipped,
      ...selectStagePointCustomOptions(point),
    };
  }
);

export const skippedStatusSelector = createSelector(
  (point: StagePointData) => point,
  point => ({skipped: point.questPointData.timelineCheck.skipped})
);
