import numeral from 'numeral';
import isNull from 'lodash/isNull';

import Box from '@mui/system/Box';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';

import {api} from 'api';
import {formatDistance} from 'utils/dates';
import {GoalReportData} from 'api/generated/users-api';

import {EntityPreview} from 'components/EntityWidgets';
import {useDialogView} from 'components/Dialog/context';
import {GoalMoneyProgress} from 'modules/goals/components';
import {SocialButtonsGroup} from 'components/SocialButton';
import IsExistsContainer from 'components/IsExistsContainer';

const GoalReportSkeleton = () => (
  <Stack
    direction="row"
    spacing={1.5}
    sx={{borderRadius: 2, boxShadow: '0px 0px 2px rgba(16, 24, 40, 0.08), 0px 1px 2px rgba(16, 24, 40, 0.1)', p: 2}}
  >
    <Skeleton variant="rectangular" sx={{height: 48, width: 48, borderRadius: 2}} animation="wave" />
    <Stack spacing={0.5} flexGrow={1}>
      <Box>
        <Skeleton variant="text" width="30%" animation="wave" />
        <Skeleton variant="text" width="100%" animation="wave" />
      </Box>
      <Skeleton variant="text" width="100%" animation="wave" />
      <Skeleton variant="text" width="100%" animation="wave" />
    </Stack>
  </Stack>
);

const InfoBlock = ({label, text}: {label: string; text: string}) => (
  <Box>
    <Typography color="grey.100" variant="body2" fontSize="10px" lineHeight="10px" mb={0.5}>
      {label}
    </Typography>
    <Typography color="grey.700" variant="body2" fontSize="12px" lineHeight="12px">
      {text}
    </Typography>
  </Box>
);

const GoalReport = ({goal, report}: GoalReportData) => {
  const openGoal = useDialogView('goals', goal._id);

  const isClosed = !!report.goalClosed;
  const isPrivate = isNull(goal.name);
  const completedIn = formatDistance(report.createdAt, goal.createdAt);
  const rewardValue = numeral(report.rewardValue?.value || 0).format('0,0');
  const timeLeft = formatDistance(goal.planFinishDate || undefined, report.createdAt);
  const finishDate = formatDistance(goal.planFinishDate || undefined, goal.createdAt);

  return (
    <Box
      p={1.5}
      onClick={isPrivate || !openGoal ? undefined : () => openGoal()}
      sx={{cursor: isPrivate ? 'default' : 'pointer'}}
    >
      <EntityPreview
        title={isPrivate ? 'Цель скрыта' : goal.name}
        caption={isClosed ? 'Цель закрыта' : 'Отчет по цели'}
        statusProps={isClosed ? {status: 'completed'} : undefined}
        imageProps={{fileId: goal.filesId[0], blurred: isPrivate, alt: `Цель ${goal.name}`}}
      >
        {!isPrivate && (
          <Stack
            mt={1}
            spacing={1.5}
            direction="row"
            alignItems="center"
            divider={
              <Divider
                orientation="vertical"
                component="span"
                flexItem
                sx={{height: 16, transform: 'translateY(4px)'}}
              />
            }
          >
            <InfoBlock label={isClosed ? 'Завершена за' : 'Осталось'} text={isClosed ? completedIn : timeLeft} />
            <InfoBlock label="Цель" text={finishDate} />
          </Stack>
        )}
        {!isPrivate && report.rewardValue?.value && <InfoBlock label="Денежный экв." text={rewardValue} />}
        <Box sx={{my: 1}}>
          <GoalMoneyProgress
            size="small"
            progress={report.localProgress}
            increment={report.rewardValue?.value}
            goal={goal.rewardValue?.value}
            symbol={goal.reward?.symbol}
          />
        </Box>
        <Box mt={1.5}>
          <SocialButtonsGroup
            likesCount={goal.likes?.count}
            commentsCount={goal.comments?.count}
            rating={goal.score?.average}
          />
        </Box>
      </EntityPreview>
    </Box>
  );
};

export const GoalReportAttachment = ({entryId}: {entryId: string; expanded?: boolean}) => {
  return (
    <IsExistsContainer<GoalReportData>
      hideError
      useFetch={() => api.endpoints.goalsReportData.useQuery({reportId: entryId})}
      LoadingComponent={<GoalReportSkeleton />}
    >
      {data => <GoalReport {...data} />}
    </IsExistsContainer>
  );
};
