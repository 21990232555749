import Box from '@mui/material/Box';

import {LazyImage} from 'components/ImageGallery';
import {useOpenLightbox} from 'components/Dialog';

import {WidgetImageProps} from '../types';
import {EntityStatus} from '../EntityStatus';

const dimensionsBySize = {
  small: 48,
  medium: 56,
};

export const WidgetImage: React.FC<WidgetImageProps> = ({
  alt,
  fileId,
  blurred,
  children,
  statusProps,
  round = false,
  size = 'medium',
  aspectRatio = '1:1',
  sx,
}) => {
  const openLightbox = useOpenLightbox();
  const dimensions = typeof size === 'string' && size !== 'contain' ? dimensionsBySize[size] : size;
  const borderRadius = round && typeof dimensions === 'number' ? dimensions / 2 : 2;

  return (
    <Box
      position="relative"
      width={dimensions}
      height={dimensions}
      flexShrink={0}
      sx={sx}
      onClick={fileId ? () => openLightbox(fileId) : undefined}
    >
      {fileId && (
        <LazyImage
          fileId={fileId}
          borderRadius={borderRadius}
          width={200}
          ratio={aspectRatio}
          alt={alt}
          blurred={blurred}
        />
      )}
      {!fileId && (
        <Box
          width={dimensions}
          height={dimensions}
          sx={{
            borderRadius,
            borderWidth: 1,
            borderStyle: 'dashed',
            borderColor: 'grey.50',
            ...sx,
          }}
        />
      )}
      {statusProps && <EntityStatus status={statusProps.status} sx={{position: 'absolute', top: -4, right: -4}} />}
      {children}
    </Box>
  );
};
