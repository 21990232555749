import {lazy, Suspense} from 'react';
import {RouteObject} from 'react-router';

import Layout from 'components/Layout';
import IsExistsContainer from 'components/IsExistsContainer';
import {useGetActiveQuest} from 'modules/oldQuests2/pages/StagePage/hook';

import {useGetGoal, useGetProfile} from './hooks';
import {GoalContext, ProfileContext} from './context';
import {
  ProfilePageSkeleton,
  UserListPageSkeleton,
  ProfileInfoPageSkeleton,
  ProfileQuestsPageSkeleton,
  ProfileGoalsPageSkeleton,
  GoalPageSkeleton,
  GoalCommentsPageSkeleton,
  QuestPageSkeleton,
} from 'components/Skeleton';

const ProfileInfo = lazy(() => import('./pages/ProfileInfo'));
const MyProfile = lazy(() => import('./containers/MyProfile'));
const ProfileIndex = lazy(() => import('./pages/ProfileIndex'));
const ProfileGoals = lazy(() => import('./pages/ProfileGoals'));
const ProfileQuests = lazy(() => import('./pages/ProfileQuests'));
const StagePage = lazy(() => import('../oldQuests2/pages/StagePage'));
const GoalPageIndex = lazy(() => import('../goals/pages/GoalPage'));
const ProfileSubscribers = lazy(() => import('./pages/ProfileSubscribers'));
const ProfileGoalComments = lazy(() => import('./pages/ProfileGoalComments'));

export const router: RouteObject[] = [
  {
    path: 'my-profile',
    element: (
      <Layout>
        <Suspense fallback={<ProfilePageSkeleton />}>
          <MyProfile />
        </Suspense>
      </Layout>
    ),
  },
  {
    path: '/@:userId',
    element: (
      <Layout>
        <IsExistsContainer
          useFetch={useGetProfile}
          context={ProfileContext}
          LoadingComponent={<ProfilePageSkeleton />}
        />
      </Layout>
    ),
    children: [
      {
        index: true,
        element: (
          <Suspense fallback={<ProfilePageSkeleton />}>
            <ProfileIndex />
          </Suspense>
        ),
      },
      {
        path: 'info',
        element: (
          <Suspense fallback={<ProfileInfoPageSkeleton />}>
            <ProfileInfo />
          </Suspense>
        ),
      },
      {
        path: 'subscribers',
        element: (
          <Suspense fallback={<UserListPageSkeleton />}>
            <ProfileSubscribers mode="subscribers" />
          </Suspense>
        ),
      },
      {
        path: 'subscriptions',
        element: (
          <Suspense fallback={<UserListPageSkeleton />}>
            <ProfileSubscribers mode="subscriptions" />
          </Suspense>
        ),
      },
      {
        path: 'modules',
        children: [
          {
            element: (
              <Suspense fallback={<ProfileQuestsPageSkeleton />}>
                <ProfileQuests />
              </Suspense>
            ),
            index: true,
          },
          {
            path: ':stageId',
            element: <IsExistsContainer useFetch={useGetActiveQuest} LoadingComponent={<QuestPageSkeleton />} />,
            children: [
              {
                index: true,
                element: (
                  <Suspense fallback={<QuestPageSkeleton />}>
                    <StagePage />
                  </Suspense>
                ),
              },
            ],
          },
        ],
      },
      {
        path: 'goals',
        children: [
          {
            index: true,
            element: (
              <Suspense fallback={<ProfileGoalsPageSkeleton />}>
                <ProfileGoals />
              </Suspense>
            ),
          },
          {
            path: ':goalId',
            element: (
              <IsExistsContainer useFetch={useGetGoal} context={GoalContext} LoadingComponent={<GoalPageSkeleton />} />
            ),
            children: [
              {
                index: true,
                element: (
                  <Suspense fallback={<GoalPageSkeleton />}>
                    <GoalPageIndex />
                  </Suspense>
                ),
              },
              {
                path: 'comments',
                element: (
                  <Suspense fallback={<GoalCommentsPageSkeleton />}>
                    <ProfileGoalComments />
                  </Suspense>
                ),
              },
            ],
          },
        ],
      },
    ],
  },
];

export default router;
