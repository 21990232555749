import {ContentPage} from './ContentPage';
import IsExistsContainer from 'components/IsExistsContainer';
import {ContentContext} from './context';
import {useGetContentPage} from './hook';

export const ContentDialog = ({entryId}: {entryId?: string}) => {
  return (
    <IsExistsContainer hideError useFetch={useGetContentPage(entryId)} context={ContentContext}>
      <ContentPage />
    </IsExistsContainer>
  );
};
