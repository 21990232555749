import 'react-image-crop/dist/ReactCrop.css';
import ReactCrop, {Crop} from 'react-image-crop';
import {useCallback, useEffect, useRef, useState} from 'react';
import {LoadingButton} from '@mui/lab';
import {CircularProgress, Dialog, DialogActions, DialogContent} from '@mui/material';

import {filePath} from 'utils';
import {Files} from 'api/generated/files-api';
import {filesApi} from 'api';

export type DialogCropperProps = {
  file?: Files;
  isUploading: boolean;
  onSuccess: (file: Files) => void;
};

export const DialogCropper = ({file, isUploading, onSuccess}: DialogCropperProps) => {
  const [uploadFile, {isLoading}] = filesApi.endpoints.rootUpload.useMutation();

  const [open, setOpen] = useState(false);
  const imgRef = useRef<HTMLImageElement | null>(null);
  const previewCanvasRef = useRef<HTMLCanvasElement | null>(null);
  const [crop, setCrop] = useState<Crop>({aspect: 1 / 1, unit: 'px', width: 50, height: 50, x: 25, y: 25});

  useEffect(() => {
    if (!!file || isUploading) setOpen(true);
  }, [file, isUploading]);

  const onLoad = useCallback(img => {
    imgRef.current = img;
    if (imgRef.current) imgRef.current.crossOrigin = 'anonymous';

    const aspect = 1 / 1;
    const isHorizontal = img.width > img.height;
    const size = (isHorizontal ? img.height : img.width) / 1.15;
    const y = (img.height - size) / 2;
    const x = (img.width - size) / 2;
    setCrop({unit: 'px', width: size, height: size, x, y, aspect});

    return false;
  }, []);

  useEffect(() => {
    if (!crop || !previewCanvasRef.current || !imgRef.current) return;

    const image = imgRef.current;
    const canvas = previewCanvasRef.current;

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext('2d');
    const pixelRatio = 1;

    canvas.width = crop.width * pixelRatio * scaleX;
    canvas.height = crop.height * pixelRatio * scaleY;

    if (ctx) {
      ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
      ctx.imageSmoothingQuality = 'high';

      ctx.drawImage(
        image,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        crop.width * scaleX,
        crop.height * scaleY
      );
    }
  }, [crop]);

  const handleAccept = () => {
    if (!crop || !imgRef.current || !previewCanvasRef.current) return null;
    previewCanvasRef.current.toBlob(
      async blob => {
        if (!blob || !file) return null;
        const body = new FormData();
        body.append('file', new File([blob], `crop_${file.name}`));
        const result = await uploadFile({body: body as any});
        if ('data' in result && result.data[0]) {
          onSuccess(result.data[0]);
          setOpen(false);
        }
      },
      'image/png',
      1
    );
  };

  return (
    <Dialog open={open} onClose={() => setOpen(false)} onBackdropClick={() => setOpen(false)}>
      <DialogContent sx={{pb: 1}}>
        {file && (
          <>
            <ReactCrop
              src={filePath(file._id as string)}
              crop={crop}
              circularCrop
              keepSelection
              onChange={setCrop}
              onImageLoaded={onLoad}
              // onComplete={setCompletedCrop}
            />
          </>
        )}
        {!file && <CircularProgress />}
        <div style={{visibility: 'hidden', position: 'fixed', right: 999999}}>
          <canvas ref={previewCanvasRef} />
        </div>
      </DialogContent>
      <DialogActions>
        <LoadingButton
          loading={isLoading}
          disabled={isLoading || !crop}
          variant="contained"
          onClick={handleAccept}
          fullWidth
        >
          Сохранить
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
