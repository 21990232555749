import Box from '@mui/system/Box';
import Stack from '@mui/material/Stack';

import {Templates, TemplatesOptions} from 'api/generated/users-api';
import {ScoreInput} from './Inputs/Score';
import {SelectInput, TextInput, CheckboxGroupField, RadioInput, NumberInput, FileInput, PhotoInput} from './Inputs';

export const Template = ({
  template,
  options,
  index,
  scriptActionId,
}: {
  template: Templates;
  options: TemplatesOptions[];
  index: number;
  scriptActionId: string;
}) => {
  if (['multi_select', 'select'].includes(template.type))
    return <SelectInput index={index} template={template} scriptActionId={scriptActionId} />;
  if (template.type === 'checkbox')
    return <CheckboxGroupField index={index} template={template} scriptActionId={scriptActionId} />;
  if (template.type === 'radio')
    return <RadioInput index={index} template={template} scriptActionId={scriptActionId} />;
  // if (template.type === 'photo')
  //   return <RadioInput index={index} template={template} scriptActionId={scriptActionId} />;

  return (
    <Stack spacing={1} mb={options.length > 1 ? 2 : 0}>
      {options.map(option => {
        const type = option.type || template.type;
        const params = {scriptActionId, templateOptionId: `${option._id}`, index};
        if (type === 'custom') return null;
        return (
          <Box key={option._id}>
            {['text', 'string', 'date'].includes(type) && <TextInput {...params} type={type as any} />}
            {type === 'number' && <NumberInput {...params} />}
            {type === 'score' && <ScoreInput {...params} />}
            {type === 'file' && <FileInput {...params} />}
            {type === 'photo' && <PhotoInput {...params} />}
          </Box>
        );
      })}
    </Stack>
  );
};
