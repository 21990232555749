import {useNavigate} from 'react-router';

import ButtonMenu from 'components/ButtonMenu';
import {ReactComponent as SettingsIcon} from 'svg/icons/settingsIcon.svg';
import {ReactComponent as InviteIcon} from 'svg/groupIcons/inviteToGroup.svg';
import {ReactComponent as RemoveGroupIcon} from 'svg/groupIcons/removeGroup.svg';

import {api} from 'api';
import {Groups} from 'api/generated/users-api';
import {StyledSwitch} from 'components/FormFactory/Field/CheckboxField';

export const GroupLeaderMenu = ({group}: {group: Groups}) => {
  const navigate = useNavigate();
  const {data: settings} = api.endpoints.settingsIndex.useQuery();

  const [destroy] = api.endpoints.groupDestroy.useMutation();
  const [patch] = api.endpoints.groupPatch.useMutation();
  const onDestroy = () => destroy({groupId: `${group._id}`, groupDismissDto: {dismissReason: ''}});
  const onRequestsToggle = () =>
    patch({groupId: `${group._id}`, groupPartialDto: {requestsIsActive: !group.requestsIsActive}});

  return settings ? (
    <ButtonMenu
      buttonProps={{
        size: 'small',
      }}
      variant="dots"
      items={[
        settings.groups.allowRequests
          ? {
              label: 'Закрыть набор',
              icon: <StyledSwitch checked={!group.requestsIsActive} onChange={onRequestsToggle} />,
            }
          : null,
        settings.groups.allowInvites
          ? {
              label: 'Пригласить в команду',
              icon: <InviteIcon />,
              onClick: () => navigate(`/teams/${group._id}/invites`),
            }
          : null,
        {
          label: 'Настройки',
          icon: <SettingsIcon />,
          onClick: () => navigate(`/teams/${group._id}/settings`),
        },
        settings.groups.allowDestroy
          ? {label: 'Расформировать команду', icon: <RemoveGroupIcon />, onClick: onDestroy}
          : null,
      ]}
    />
  ) : null;
};
