import {Box} from '@mui/system';
import {CircularProgress} from '@mui/material';

import {isUndefined} from 'lodash';
import {StageProgress, StagesPoints} from 'api/generated/users-api';
import {ReactComponent as CheckIcon} from 'svg/quest/questCheck.svg';
import {ReactComponent as LockFillIcon} from 'svg/quest/lockFill.svg';
import {ReactComponent as LockOutlineIcon} from 'svg/quest/lockOutline.svg';
import {EntityStatus} from 'components/EntityWidgets';

export type QuestPointStatusProps = {
  index?: number;
  locked?: boolean;
  progress?: StageProgress;
} & Partial<Pick<StagesPoints, 'status'>>;

export const SubPointStatusIcon = ({locked, isCompleted}: {locked?: boolean; isCompleted?: boolean}) => {
  if (!isCompleted && !locked) return null;
  return (
    <Box
      sx={{
        top: -4,
        right: -4,
        position: 'absolute',
        width: 18,
        height: 18,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderWidth: 2,
        borderStyle: 'solid',
        borderColor: 'white',
        borderRadius: 3,
        backgroundColor: isCompleted ? 'success.main' : 'warning.light',
      }}
    >
      <Box component={isCompleted ? CheckIcon : LockFillIcon} sx={{color: 'white'}} />
    </Box>
  );
};

export const QuestPointStatus = ({index, status, progress, locked = false}: QuestPointStatusProps) => {
  const withIndex = !isUndefined(index);
  const isCompleted = status === 'completed' || status === 'ready';
  const progressPercent =
    progress && progress.requiredPointsTotal
      ? ((progress.requiredPointsCompleted || 0) / progress.requiredPointsTotal) * 100
      : undefined;

  return (
    <Box
      width={24}
      height={24}
      flexShrink={0}
      borderRadius={3}
      display="flex"
      alignItems="center"
      position="relative"
      justifyContent="center"
      sx={{
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: 'grey.25',
        backgroundColor: 'white',
      }}
    >
      {withIndex && (
        <>
          <Box component="div" textAlign="center" sx={{fontSize: 15, color: 'grey.200'}}>
            {index}
          </Box>
          {!!progressPercent && progressPercent > 0 && progressPercent < 100 && (
            <CircularProgress
              size={24}
              sx={{
                top: -1,
                left: -1,
                position: 'absolute',
                color: 'success.main',
              }}
              value={progressPercent}
              variant="determinate"
            />
          )}
          {status && ['completed', 'failed', 'locked', 'ready'].includes(status) && (
            <EntityStatus size={12} status={status} sx={{position: 'absolute', top: -3, right: -3}} />
          )}
        </>
      )}
      {locked && withIndex && (
        <Box
          sx={{
            top: -5,
            right: -5,
            position: 'absolute',
            borderRadius: 2,
            width: 12,
            height: 12,
            boxSizing: 'content-box',
            border: '1px solid #fff',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'warning.light',
          }}
        >
          <Box component={LockFillIcon} sx={{width: 8, height: 8}} />
        </Box>
      )}
      {!withIndex && (
        <>
          {locked && <Box component={LockOutlineIcon} sx={{width: 12, height: 12}} />}
          {!locked && (
            <Box component={CheckIcon} sx={{width: 8, height: 8, color: isCompleted ? 'success.dark' : undefined}} />
          )}
        </>
      )}
    </Box>
  );
};
