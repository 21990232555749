import {api} from 'api';
import {useCallback} from 'react';
import {useSnackbar} from 'notistack';
import {useNavigate} from 'react-router';

import {getTaskJobPath} from 'modules/tasks/utils';

export const useEditJob = () => {
  const navigate = useNavigate();
  const {enqueueSnackbar} = useSnackbar();
  const [refetchJob] = api.endpoints.jobStructure.useLazyQuery();
  const [makeEditable] = api.endpoints.jobMakeEditable.useMutation();

  const onEditJob = async (jobId: string) => {
    const result = await makeEditable({jobId});
    if ('data' in result) {
      await refetchJob({jobId});
      navigate(getTaskJobPath(jobId));
    } else if ('error' in result) {
      enqueueSnackbar((result.error as any).data.message || 'Не получилось взять задачу в редактирование', {
        variant: 'error',
        autoHideDuration: 3000,
      });
    }
  };

  return {onEditJob};
};

export const useAcceptTask = ({
  taskId,
  callback,
  timelineId,
  stagePointId,
}: {
  taskId: string;
  timelineId: string;
  stagePointId?: string;
  callback?: (referenceId: string) => Promise<boolean>;
}) => {
  const navigate = useNavigate();
  const {enqueueSnackbar} = useSnackbar();
  const [accept, {isLoading, isError, error}] = api.endpoints.taskAccept.useMutation();

  const onAccept = useCallback(async () => {
    const result = await accept({taskId, taskActionDto: {timelineId, stagePointId}});
    if ('data' in result) {
      if (callback) {
        const cbResult = await callback(`${result.data._id}`);
        if (cbResult) navigate(getTaskJobPath(`${result.data._id}`));
      } else navigate(getTaskJobPath(`${result.data._id}`));
    } else if ('error' in result) {
      const message = (result.error as any).data.message || 'Не получилось взять задачу в работу';
      enqueueSnackbar(message, {variant: 'error', autoHideDuration: 3000});
    }
  }, [navigate, taskId, callback, stagePointId, timelineId, enqueueSnackbar, accept]);

  return {onAccept, isLoading, isError, error};
};
