import {styled, SxProps, useTheme} from '@mui/system';
import {GREY} from 'components/theme/palette';
import {isUndefined} from 'lodash';
import {getNPSBadgeColor} from 'utils';

const sizes = {
  xsmall: 6,
  small: 10,
  medium: 12,
  big: 16,
  large: 20,
  default: 24,
};
const fontSizes = {
  xsmall: 3,
  small: 6,
  medium: 6,
  big: 8,
  large: 12,
  default: 12,
};

const Container = styled('div')`
  box-sizing: border-box;

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 11px;
  line-height: 12px;
  color: ${GREY[200]};
  border: 1px solid ${GREY[25]};
`;

export const RatingPosition = ({
  position,
  sx,
  size = 'default',
}: {
  position?: number;
  sx?: SxProps;
  size?: keyof typeof sizes;
}) => {
  const theme = useTheme();
  if (isUndefined(position)) return null;
  const positionColor = getNPSBadgeColor(position);
  return (
    <Container
      sx={{
        ...sx,
        width: sizes[size],
        height: sizes[size],
        borderRadius: '50%',
        fontSize: fontSizes[size],
        lineHeight: `fontSizes[size]px`,
        backgroundColor: 'white',
        ...(positionColor
          ? {
              color: `badge.${positionColor}.color`,
              borderColor: `badge.${positionColor}.bgcolor`,
              backgroundColor: `badge.${positionColor}.bgcolor`,
              backgroundImage: theme.palette.badge[positionColor].linearGradient,
            }
          : {}),
      }}
    >
      {position}
    </Container>
  );
};
