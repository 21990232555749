import {Box, Stack, Typography} from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import SuccessIcon from '@mui/icons-material/CheckCircle';
import InfoIcon from '@mui/icons-material/Info';
import WarningIcon from '@mui/icons-material/Warning';

type InfoMessageBlockProps = {
  status: 'info' | 'error' | 'success' | 'warning';
  message: string;
};

const statusIcons = {
  info: InfoIcon,
  error: ErrorIcon,
  success: SuccessIcon,
  warning: WarningIcon,
};

export const InfoMessageBlock = ({status, message}: InfoMessageBlockProps) => {
  return (
    <Stack direction="row" alignItems="center" spacing={1} p={1} borderRadius={2} bgcolor={`${status}.lighter`}>
      <Box component={statusIcons[status]} color={`${status}.main`} />
      <Typography variant="body2" color={`${status}.main`}>
        {message}
      </Typography>
    </Stack>
  );
};
