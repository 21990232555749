import {Stack, Typography, Avatar} from '@mui/material';
import {styled} from '@mui/system';

import {BottomButtonGroup} from '..';
import aspectRatio from 'img/aspect-ratio.png';
import GoalCompleteBadge from 'components/GoalCompleteBadge';

const StyledPostImage = styled(Avatar)(() => ({
  width: '64px',
  height: '64px',
  borderRadius: '8px',
}));

export const PostItem = ({
  userName,
  description,
  isCompleted = false,
}: {
  userName: string;
  description?: any;
  isCompleted?: boolean;
  score: number;
}) => {
  return (
    <Stack direction="row" gap={1.5} py={1}>
      <StyledPostImage src={aspectRatio} />
      {/* <UserAvatar type={type} size="medium" /> */}
      <Stack
        flexGrow={1}
        gap={1}
        display="flex"
        flexDirection="column"
        alignSelf="stretch"
        justifyContent="center"
        alignItems="flex-start"
      >
        <Stack direction="row" alignItems="center" gap={0.5}>
          <Typography variant="body2" sx={{fontWeight: 500}}>
            {userName}
          </Typography>
          {/* <NPSBadge value={score} size="small" type={type} /> */}
        </Stack>
        {isCompleted && <GoalCompleteBadge />}
        {description}
        <BottomButtonGroup commentsCount={24} />
      </Stack>
    </Stack>
  );
};

export default PostItem;
