import {memo} from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import {api} from 'api';
import {timeAgo} from 'utils/dates';
import {Comments, Users} from 'api/generated/users-api';

import {ReactionButton} from 'modules/social/reactions';
import {SocialStateProvider} from 'modules/social';
import {ProfileImage, UserName} from 'modules/profile/components';

import {NPSBadge} from 'components/Layout';
import {CommentContextMenu} from '../CommentContextMenu';
import {MarkdownRender} from 'components/MarkdownRender';
import {useIsMe} from 'modules/auth';
import {RestoreEntry} from 'components/RestoreEntry';

type FullCommentEntryProps = {comment: Comments; onReply?: () => void};

export const FullCommentEntry = memo(({comment: initialData, onReply}: FullCommentEntryProps) => {
  const {updatedData} = api.endpoints.commentIndex.useQueryState(
    {commentId: `${initialData._id}`},
    {selectFromResult: ({data}) => ({updatedData: data?.comment})}
  );
  const comment = updatedData || initialData;
  const commentId = `${comment._id}`;
  const author = comment.user as Users;
  const isMe = useIsMe(author._id);

  return (
    <Stack direction="row" spacing={1} sx={{width: '100%', opacity: comment.deletedAt ? 0.6 : 1}}>
      <ProfileImage user={author} />
      <Stack spacing={0.5} flexGrow={1}>
        <UserName user={author} />
        {!comment.deletedAt && !!comment.scoreInfo && (
          <NPSBadge prefix="Оценка" fixedValue={0} value={comment.scoreInfo.value} />
        )}
        {!comment.deletedAt && <MarkdownRender content={comment.content} />}
        {comment.deletedAt && (
          <>
            <Typography variant="body2" sx={{fontStyle: 'italic', color: 'grey.200'}}>
              Комментарий удален
            </Typography>
            {isMe && <RestoreEntry params={{commentId}} authorId={`${author._id}`} mutationName="commentSafeRestore" />}
          </>
        )}
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Stack direction="row" alignItems="center" spacing={1}>
            <Typography variant="subtitle1" sx={{color: 'grey.200'}}>
              {timeAgo(`${comment.createdAt}`)}
            </Typography>
            {onReply && !comment.deletedAt && (
              <Typography
                variant="body2"
                onClick={onReply}
                sx={{color: 'grey.200', fontWeight: 500, cursor: 'pointer', '&:hover': {color: 'grey.400'}}}
              >
                Ответить
              </Typography>
            )}
            {!comment.deletedAt && <CommentContextMenu entryId={commentId} authorId={`${author._id}`} />}
          </Stack>
          <SocialStateProvider scope={`Comments.${comment._id}`} reactions={comment.reactions}>
            <ReactionButton />
          </SocialStateProvider>
        </Stack>
      </Stack>
    </Stack>
  );
});
