import {Box, Skeleton, Stack} from '@mui/material';
import {PublicationListSkeleton} from '../../Feed/components';

export const TaskFeedPageSkeleton = () => (
  <Stack spacing={0.5}>
    <Stack direction="row" alignItems="center" spacing={1} p={2} bgcolor="white">
      <Skeleton variant="rectangular" width={56} height={56} sx={{borderRadius: 2}} />
      <Skeleton variant="text" animation="wave" width="75%" height={24} sx={{transform: 'none'}} />
    </Stack>
    <Box px={2} py={1} bgcolor="white" mb={0.5}>
      <Skeleton variant="rectangular" animation="wave" width="100%" height={40} sx={{borderRadius: 12}} />
    </Box>
    <Stack direction="row" spacing={2} px={2} py={1} bgcolor="white">
      <Skeleton variant="text" animation="wave" width={90} height={16} sx={{transform: 'none'}} />
      <Skeleton variant="text" animation="wave" width={90} height={16} sx={{transform: 'none'}} />
    </Stack>
    <Stack spacing={1} bgcolor="white">
      <PublicationListSkeleton />
    </Stack>
  </Stack>
);
