import Card from '@mui/material/Card';
import {Stack} from '@mui/material';
import Button from '@mui/material/Button';
import {skipToken} from '@reduxjs/toolkit/dist/query';
import {useUpdateAtom} from 'jotai/utils';

import {api} from 'api';
import {Notifications} from 'api/generated/users-api';

import {useDialog} from 'components/Dialog';
import {InfiniteList} from 'components/InfiniteLoader';
import {closeNotificationDialog} from 'modules/notifications/store';

import {NotificationItem} from '..';
import {NotificationGroupInviteItem} from '../NotificationGroupInviteItem';
import {NotificationGroupRequestItem} from '../NotificationGroupRequestItem';

export const NotificationsDialogFull = () => {
  const [Dialog] = useDialog();
  const close = useUpdateAtom(closeNotificationDialog);
  const {data: settings} = api.endpoints.settingsIndex.useQuery();
  const [get, result] = api.endpoints.notificationsIndex.useLazyQuery();
  const [markReadAll] = api.endpoints.notificationsReadAll.useMutation();
  const {data} = api.endpoints.notificationsUnreadedCount.useQueryState(undefined);
  const {data: invitesData} = api.endpoints.groupInvitesIndex.useQuery(
    settings?.groups?.allowInvites ? {forMe: 1} : skipToken
  );
  const {data: requestsData} = api.endpoints.groupRequestsIndex.useQuery(
    settings?.groups?.allowRequests ? {} : skipToken
  );

  const invites = invitesData?.data || [];
  const requests = requestsData?.data || [];

  return (
    <Dialog
      title="Уведомления"
      open
      onClose={close}
      action={
        !!data?.unreadCount && (
          <Button variant="contained" color="secondary" size="medium" fullWidth onClick={() => markReadAll()}>
            Отметить все как прочитанное
          </Button>
        )
      }
    >
      <Card>
        <Stack>
          {invites.map(invite => (
            <NotificationGroupInviteItem key={invite._id} invite={invite} />
          ))}
          {requests.map(request => (
            <NotificationGroupRequestItem key={request._id} request={request} />
          ))}
        </Stack>
        <InfiniteList<Notifications> fetchFn={get} result={result} spacing={0.25} limit={30}>
          {notification => <NotificationItem notification={notification} />}
        </InfiniteList>
      </Card>
    </Dialog>
  );
};
