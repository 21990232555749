import {useState, useCallback} from 'react';

import {api} from 'api';
import {StageIdProp} from '../types';

export const useRefreshStage = ({stageId}: Partial<StageIdProp>) => {
  const [refreshing, setRefreshing] = useState(false);
  const [refreshStage] = api.endpoints.stageIndex.useLazyQuerySubscription();
  const [refreshStagePoints] = api.endpoints.stagePointsIndex.useLazyQuerySubscription();

  const onRefresh = useCallback(async () => {
    if (stageId) {
      setRefreshing(true);
      await Promise.all([refreshStage({stageId, partial: true}), refreshStagePoints({stageId, partial: true})]);
      setRefreshing(false);
    }
  }, [stageId, refreshStage, refreshStagePoints]);

  return {onRefresh, refreshing};
};
