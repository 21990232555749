import Slider from 'react-slick';
import {Link} from 'react-router-dom';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import {api} from 'api';
import {useSafeContext} from 'utils';

import {GroupFeedWidgetItem} from 'modules/groups/components/GroupFeedWidgetItem';
import {GroupContext} from 'modules/groups/context';
import {useMyStreamId} from 'modules/auth/hooks';

export const GroupFeedWidget = () => {
  const {groupId} = useSafeContext(GroupContext);
  const streamId = useMyStreamId();
  // const ref = useRef(null);
  // const {isSliding} = useSlider(ref);
  // TODO: Сделать скелетон на загрузку
  const {data} = api.endpoints.publicationsIndex.useQuery({
    streamId,
    groupId: [groupId],
    sort: 'created_date',
    limit: 10,
  });
  return (
    <Stack py={2} pb={1} spacing={1}>
      <Stack px={2} direction="row" alignItems="center" justifyContent="space-between">
        <Typography variant="h3">Лента</Typography>
        <Link to="./feed">
          <Typography variant="body2" color="grey.200" sx={{transition: 'opacity .2s', '&:hover': {opacity: 0.75}}}>
            Смотреть всё
          </Typography>
        </Link>
      </Stack>
      <Box sx={{'.slick-slide': {margin: '0 8px'}, '.slick-track': {margin: '0 16px', padding: '8px 0'}}}>
        <Slider slidesToScroll={1} slidesToShow={1} infinite={false} arrows={false} variableWidth swipeToSlide>
          {(data?.data || []).map(item => (
            <GroupFeedWidgetItem key={item._id} item={item} />
          ))}
        </Slider>
      </Box>
    </Stack>
  );
};
