import {Link} from 'react-router-dom';

import {StagePointProps} from 'modules/oldQuests2/types';
import {getContentsPath} from 'modules/contents/utils';
import {useDialogView} from 'components/Dialog/context';
import {QuestPointWithProgress} from './QuestPointWithProgress';
import {useSetPointReference} from './hook';

export const ContentQuestPoint = ({children, stagePoint, questPoint: point}: StagePointProps) => {
  const {setReference} = useSetPointReference({stagePoint, questPointData: point});
  const contentId = `${point.originId}`;
  const openContent = useDialogView('contents', contentId);
  const openContentHandler = (e: React.MouseEvent) => {
    e.preventDefault();
    if (openContent) {
      openContent();
      setReference(contentId);
    }
  };

  const View = children({});
  if ('progress' in point) {
    return <QuestPointWithProgress questPoint={point} children={children} />;
  }
  if (!stagePoint || !point.timelineCheck.available) return View;
  return (
    <Link to={{pathname: getContentsPath(contentId)}} onClick={openContentHandler}>
      {View}
    </Link>
  );
};
