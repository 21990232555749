import {FC} from 'react';

// import {useUpdateAtom} from 'jotai/utils';
import {atom, useAtom} from 'jotai';

// import FlagIcon from 'svg/icons/flagIcon.svg';

import {StageIdProp} from '../../types';
import {StagePointList} from './StagePointsList';
import {Box, ButtonBase} from '@mui/material';

const openedAtom = atom(false);
// const layoutAtom = atom< | undefined>(undefined);

export const TopButtonsControlButton = () => {
  // const update = useUpdateAtom(openedAtom);
  // const updateLayout = useUpdateAtom(layoutAtom);
  return <Box>{/* <IconButton icon={FlagIcon} onClick={() => update(state => !state)} /> */}</Box>;
};

export const StagePointMenuList: FC<StageIdProp> = ({stageId}) => {
  const [opened, setOpened] = useAtom(openedAtom);
  // const layout = useAtomValue(layoutAtom);
  // const positionStyles = {top: (layout?.y || 0) + (layout?.height || 0), right: 0};

  return (
    <>
      {opened && (
        <ButtonBase onClick={() => setOpened(false)}>
          <Box
            mx={2}
            borderRadius={4}
            // style={[positionAbsolute, whiteBg, overflowHidden, styles.container, positionStyles]}
          >
            <StagePointList
              stageId={stageId}
              withDivider
              dividerPX={0}
              componentName="TopButtonStagePoint"
              containerName="topButtonsContainer"
            />
          </Box>
        </ButtonBase>
      )}
    </>
  );
};

// const styles = StyleSheet.create({
//   container: {
//     width: '60%',
//     borderWidth: 1,
//     borderColor: BORDER_COLOR,
//   },
// });
