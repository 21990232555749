import {Skeleton, Stack} from '@mui/material';
import {QuestListSkeleton} from './QuestListSkeleton';

export const AdventureQuestSkeleton = () => (
  <Stack spacing={1.5} bgcolor="white">
    <Stack direction="row" spacing={1} p={2}>
      <Skeleton variant="circular" width={24} height={24} />
      <Skeleton variant="text" animation="wave" width="75%" height={24} sx={{transform: 'none'}} />
    </Stack>
    <QuestListSkeleton />
  </Stack>
);
