import {Box, Typography} from '@mui/material';
import {GREY} from 'components/theme/palette';
import {useField} from 'formik';
import {useMemo} from 'react';

const useFieldValueLength = (name: string) => {
  const [{value}] = useField(name);
  return useMemo(() => (value || '').length, [value]);
};

export const InputCharCounter = ({maxValue, name}: {maxValue: number; name: string}) => {
  const lengtn = useFieldValueLength(name);
  return (
    <Box display="flex" justifyContent="flex-end" mt={1}>
      <Typography variant="subtitle1" sx={{color: GREY[200]}}>
        {lengtn}&nbsp;
      </Typography>
      <Typography variant="subtitle1" sx={{color: GREY[700]}}>
        / {maxValue}
      </Typography>
    </Box>
  );
};
