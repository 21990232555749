import {useToggle} from 'react-use';
import Box from '@mui/system/Box';
import {Collapse} from '@mui/material';

import {QuestPointsProgressData} from 'api/generated/users-api';

import {StagePointProps} from 'modules/oldQuests2/types';
import {GroupUsersProgress} from 'modules/groups/components/GroupUsersProgress';
import {GroupProgressBadge} from 'modules/groups/components/GroupProgressBadge';

export const QuestPointWithProgress: React.FC<
  Omit<StagePointProps, 'stagePoint, questPoint'> & {questPoint: QuestPointsProgressData}
> = ({questPoint, children}) => {
  const [showUsersProgressList, setShowUsersProgressList] = useToggle(false);

  const additionalContent = {
    element: (
      <GroupProgressBadge
        progress={questPoint.progress}
        sx={{
          mt: showUsersProgressList ? '0 !important' : 1,
          height: showUsersProgressList ? 0 : '20px',
          opacity: showUsersProgressList ? 0 : 1,
        }}
      />
    ),
    bottomElement: (
      <Collapse in={showUsersProgressList} mountOnEnter unmountOnExit>
        <GroupUsersProgress progress={questPoint.progress} />
      </Collapse>
    ),
  };
  const type = questPoint.questPoint.type;
  const name =
    questPoint.name ||
    (type === 'goal' ? 'Поставьте цель' : type === 'task' ? 'Задание' : type === 'publication' ? 'Пост' : undefined);
  return (
    <Box>
      {children({
        name,
        onClick: () => setShowUsersProgressList((p: boolean) => !p),
        additionalContent,
      })}
    </Box>
  );
};
