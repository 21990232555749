import {useCallback, ReactNode} from 'react';
import {useMeasure} from 'react-use';
import {ImageList} from '@mui/material';

import {api} from 'api';
import {Publications, PublicationsIndexApiArg} from 'api/generated/users-api';

import {useMyStreamId} from 'modules/auth';
import {Publication, PublicationImage} from 'modules/feed/containers';
import {InfiniteList} from 'components/InfiniteLoader';
import {PublicationGridSkeleton, PublicationListSkeleton} from 'components/Skeleton';

import {ReactComponent as EmptyFeedPic} from 'svg/pics/emptyFeedPic.svg';
import {PublicationCreateButton} from '../PublicationCreateButton';

const LIMIT = 9;

export type PublicationListProps = {
  limit?: number;
  params: Omit<PublicationsIndexApiArg, 'limit' | 'offset' | 'streamId'>;
  emptyListMessage?: string;
  showEmptyListCreateButton?: boolean;
  showContentIfDeleted?: boolean;
  LoadingComponent?: ReactNode;
};

export const PublicationGrid = ({params}: PublicationListProps) => {
  const [ref, {width}] = useMeasure<HTMLDivElement>();
  const streamId = useMyStreamId();
  const [fetch, result] = api.endpoints.publicationsIndex.useLazyQuery();

  const getItems = useCallback(
    async ({offset, limit}: {offset: number; limit: number}) => {
      if (limit) await fetch({...params, streamId, offset, limit});
    },
    [fetch, params, streamId]
  );

  return (
    <div ref={ref}>
      <InfiniteList
        fetchFn={getItems}
        limit={21}
        LoadingComponent={fetchItemsCount => <PublicationGridSkeleton width={width} limit={fetchItemsCount} />}
        result={result}
        containerComponent={content => (
          <ImageList gap={1} variant="quilted" cols={3} rowHeight={width / 3}>
            {content}
          </ImageList>
        )}
      >
        {(post: Publications) => <PublicationImage key={post._id} post={post} cols={1} rows={1} />}
      </InfiniteList>
    </div>
  );
};

export const PublicationList = ({
  params,
  showEmptyListCreateButton,
  emptyListMessage,
  showContentIfDeleted,
  LoadingComponent,
}: PublicationListProps) => {
  const streamId = useMyStreamId();
  const [fetch, result] = api.endpoints.publicationsIndex.useLazyQuery();

  const getItems = useCallback(
    async ({offset, limit}: {offset: number; limit: number}) => {
      if (limit) await fetch({...params, streamId, offset, limit});
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fetch, params]
  );

  return (
    <InfiniteList
      fetchFn={getItems}
      limit={LIMIT}
      result={result}
      LoadingComponent={fetchItemsCount => LoadingComponent || <PublicationListSkeleton limit={fetchItemsCount} />}
      emptyListProps={{
        text: emptyListMessage,
        pic: <EmptyFeedPic />,
        action: showEmptyListCreateButton && <PublicationCreateButton />,
      }}
    >
      {(post: Publications) => <Publication key={post._id} post={post} showContentIfDeleted={showContentIfDeleted} />}
    </InfiniteList>
  );
};

export default PublicationList;
