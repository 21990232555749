import {api} from 'api';
import {useNavigate} from 'react-router';
import {useEffect, useCallback} from 'react';

import {Box} from '@mui/system';
import {LoadingButton} from '@mui/lab';

import {Jobs} from 'api/generated/users-api';
import {getTaskJobPath} from 'modules/tasks/utils';
import {ReactComponent as EditIcon} from 'svg/icons/editIcon.svg';
import {useAcceptTask} from './hook';

export const TaskAcceptButton = ({
  taskId,
  timelineId,
  stagePointId,
  callback,
  size = 'medium',
  text = 'Начать выполнение',
}: {
  taskId: string;
  timelineId: string;
  stagePointId?: string;
  callback?: (referenceId: string) => Promise<boolean>;
  text?: string;
  size?: 'small' | 'medium';
}) => {
  const {onAccept, error, isError, isLoading} = useAcceptTask({taskId, timelineId, stagePointId, callback});

  return (
    <>
      <LoadingButton
        fullWidth
        size={size}
        onClick={onAccept}
        variant="contained"
        loading={isLoading}
        loadingIndicator="Беру в работу..."
        color={isError ? 'error' : 'primary'}
      >
        {text}
      </LoadingButton>
      {error && (error as any).data?.message && (
        <Box sx={{typography: 'subtitle1', color: 'error.main', mt: 1}}>{(error as any).data.message || ''}</Box>
      )}
    </>
  );
};

export const JobContinueButton = ({
  jobId,
  status,
  size = 'medium',
  showIcon = false,
}: {
  jobId: string;
  status: Jobs['status'];
  showIcon?: boolean;
  size?: 'small' | 'medium';
}) => {
  const isInEditState = ['new', 'edit'].includes(status);
  const navigate = useNavigate();
  const [refetchJob] = api.endpoints.jobStructure.useLazyQuery();
  const [makeEditable, {isSuccess, isLoading, isError, error}] = api.endpoints.jobMakeEditable.useMutation();
  const onClick = () => {
    if (isInEditState) navigate(getTaskJobPath(jobId));
    else makeEditable({jobId});
  };

  const refetch = useCallback(async () => {
    await refetchJob({jobId});
    navigate(getTaskJobPath(jobId));
  }, [refetchJob, jobId, navigate]);

  useEffect(() => {
    if (isSuccess) refetch();
  }, [isSuccess, refetch]);

  return (
    <>
      <LoadingButton
        fullWidth
        size={size}
        variant="contained"
        onClick={onClick}
        loading={isLoading}
        color={isError ? 'error' : 'primary'}
      >
        {showIcon && <Box component={EditIcon} sx={{color: isError ? 'error.dark' : 'white'}} />}
        {isInEditState ? 'Продолжить выполнение' : 'Редактировать'}
      </LoadingButton>
      {error && (error as any).data?.message && (
        <Box sx={{typography: 'subtitle1', color: 'error.main', mt: 1}}>{(error as any).data.message || ''}</Box>
      )}
    </>
  );
};

export default TaskAcceptButton;
