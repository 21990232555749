import {createSelector} from 'reselect';
import {StagePointData, QuestPointsIndexApiResponse, StagePointsIndexApiResponse} from 'api/generated/users-api';

const displayPointOnTop = (item: StagePointData) =>
  !item.questPointData.timelineCheck.skipped &&
  item.questPointData.timelineCheck.available &&
  !!item.questPointData.questPoint.options.buttons &&
  !!item.questPointData.questPoint.options.buttons?.alwaysOnTop &&
  ['new', 'in_progress'].includes(item.stagePoint?.status || '');

const displayPointinBody = (item: StagePointData) =>
  !item.questPointData.timelineCheck.skipped && item.questPointData.timelineCheck.available;
const selectSelf = (state: StagePointsIndexApiResponse) => state;
const selectSelfQuests = (state: QuestPointsIndexApiResponse) => state;

export const selectQuestPoints = createSelector(selectSelfQuests, state =>
  state.filter(item => !item.timelineCheck.skipped)
);
export const selectPointsOnTop = createSelector(selectSelf, state => state.filter(displayPointOnTop));
export const selectPointIds = createSelector(selectSelf, state =>
  state
    .filter(displayPointinBody)
    .filter(item => !displayPointOnTop(item))
    .map(item => `${item.questPointData.questPoint._id}`)
);
