import {Skeleton, Stack} from '@mui/material';
import {ContentsListSkeleton} from '../components';

export const ContentListPageSkeleton = () => (
  <Stack width={1}>
    <Stack px={2} py={1} bgcolor="white" alignItems="center" spacing={1} direction="row">
      <Skeleton variant="rectangular" animation="wave" width="100%" height={44} sx={{borderRadius: 12}} />
      <Skeleton variant="rectangular" animation="wave" width={44} height={44} sx={{borderRadius: 1}} />
    </Stack>
    <ContentsListSkeleton />
  </Stack>
);
