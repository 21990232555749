import Box from '@mui/material/Box';
import {useRef, useState} from 'react';
import capitalize from 'lodash/capitalize';

import {SocialButton} from 'components/SocialButton';
import {ReactionsMenu} from '../ReactionMenu';
import {useReactionsAction, useReactionsContext} from '../../hooks';
import {ReactComponent as LikeIcon} from 'svg/icons/likeIcon.svg';

type ReactionsButtonBlockProps = {
  plainText?: boolean;
  symbolOnly?: boolean;
  emptyText?: string;
};

export const ReactionsButtonBlock = ({plainText, symbolOnly, emptyText = 'Лайк'}: ReactionsButtonBlockProps) => {
  const {reactionBlock, myReaction} = useReactionsContext();
  const action = useReactionsAction();
  const [open, setOpen] = useState(false);
  const anchorElRef = useRef<HTMLButtonElement | null>(null);
  const myReactionReward = reactionBlock?.rewards.find(i => i._id === myReaction?.rewardValue.rewardId);

  if (!reactionBlock) return null;

  const text = myReactionReward
    ? `${myReactionReward.symbol}${!symbolOnly ? ` ${capitalize(myReactionReward.name)}` : ''}`
    : emptyText;
  return (
    <Box>
      <ReactionsMenu
        open={open}
        selected={myReactionReward && myReactionReward._id}
        action={action}
        close={() => setOpen(false)}
        rewards={reactionBlock.rewards}
        anchorElRef={anchorElRef}
      />
      <SocialButton
        onClick={() => setOpen(true)}
        plainText={plainText}
        ref={anchorElRef}
        isActive={Boolean(open || myReaction)}
        icon={!myReactionReward ? LikeIcon : undefined}
        text={text}
      />
    </Box>
  );
};
