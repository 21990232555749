import {Menu as MUIMenu, MenuItem as MUIMenuItem, ListItemIcon as MUIListItemIcon} from '@mui/material';
import {styled} from '@mui/system';

export const Menu = styled(MUIMenu)`
  &.MuiMenu {
    &-root {
      border-radius: 30px;
    }
    &-list {
      padding: 0 !important;
    }
  }
`;

export const MenuItem = styled(MUIMenuItem)`
  &.MuiMenuItem-root {
    flex-direction: row-reverse;
    justify-content: space-between;
    min-width: 190px;
    min-height: 40px;
    padding: 10px 12px;
    line-height: 20px;
    font-size: 16px;
    & + & {
      margin-top: 0;
    }
  }
  & .MuiListItemIcon-root {
    min-width: 24px;
  }
`;

export const ListItemIcon = styled(MUIListItemIcon)`
  & svg {
    height: 24px;
    width: 24px;
  }
`;
