import {memo} from 'react';
import Grid from '@mui/material/Grid';

import {Goals} from 'api/generated/users-api';
import {InfiniteList} from 'components/InfiniteLoader';
import {GoalGridSkeleton} from 'components/Skeleton/Goal';
import {ReactComponent as GoalPic} from 'svg/pics/goalPic.svg';

import {GoalGridItem} from 'modules/goals/components';
import {GoalListCommonProps} from 'modules/goals/types';
import {useGetGoalsList} from './hook';

const RenderItem = memo(({goal, onGoalClick}: {goal: Goals} & Pick<GoalListCommonProps, 'onGoalClick'>) => (
  <Grid item xs={4}>
    <GoalGridItem goal={goal} onClick={onGoalClick} />
  </Grid>
));

export const GoalsGridView = memo(({params, onGoalClick, emptyListProps}: GoalListCommonProps) => {
  const {getGoalsItems, result} = useGetGoalsList(params);

  return (
    <InfiniteList<Goals>
      limit={21}
      result={result}
      fetchFn={getGoalsItems}
      LoadingComponent={fetchItemsCount => <GoalGridSkeleton limit={fetchItemsCount} />}
      emptyListProps={{
        pic: <GoalPic />,
        text: 'Целей не найдено',
        ...emptyListProps,
      }}
      containerComponent={items => (
        <Grid container spacing="1px">
          {items}
        </Grid>
      )}
    >
      {goal => <RenderItem goal={goal} onGoalClick={onGoalClick} />}
    </InfiniteList>
  );
});
