import {useEffect} from 'react';
import {FieldProps} from 'formik';
import {TextField} from 'formik-mui';
import {last, startsWith} from 'lodash';
import {AsYouType} from 'libphonenumber-js';

/*
если строка состоит из цифр – телефон
  добавляем плюс
если строка начинается с + – телефон
  оставляем плюс
в ином случае - мейл
*/

export const PhoneField = ({field, ...props}: FieldProps<any>) => {
  const {value} = field;
  useEffect(() => {
    if (!startsWith(value, '+')) props.form.setFieldValue(field.name, `+${value}`);
    else if (startsWith(value, '++')) props.form.setFieldValue(field.name, `+${last(value.split('+'))}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const phone = new AsYouType();
  const formatted = phone.input(value);
  return <TextField {...props} field={{...field, value: formatted}} variant="standard" size="medium" />;
};

export default PhoneField;
