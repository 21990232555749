import {ReactNode} from 'react';
import {useIdle} from 'react-use';
import {useNavigate} from 'react-router';
import {useUpdateAtom} from 'jotai/utils';
import minutesToMilliseconds from 'date-fns/minutesToMilliseconds';

import {api} from 'api';
import {SxProps} from '@mui/system';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import ButtonBase from '@mui/material/ButtonBase';

import {useLogout} from 'modules/auth';
import {MyProfileImage} from 'modules/profile/components';
import {openNotificationDialog} from 'modules/notifications';

import {ReactComponent as LogoutIcon} from 'svg/icons/logoutIcon.svg';
import {ReactComponent as AddPostIcon} from 'svg/icons/addPostIcon.svg';
import {ReactComponent as SettingsIcon} from 'svg/icons/settingsIcon.svg';
import {ReactComponent as NotificationsIcon} from 'svg/icons/notificationsIcon.svg';

import {useConfirmDialog} from 'components/Dialog';
import {HeaderButtonMenu} from '../HeaderButtonMenu';
import {NotificationIndication} from '../NotificationIndication';

const HeaderButton = ({
  sx,
  Icon,
  count,
  Content,
  onClick,
}: {
  Icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  Content?: ReactNode;
  sx?: SxProps;
  count?: number;
  onClick?: () => void;
}) => {
  return (
    <ButtonBase
      onClick={onClick}
      sx={{
        position: 'relative',
        px: 1,
        height: 48,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 2,
        backgroundColor: 'transparent',
        transition: 'background-color .25s',
        '&:hover': {backgroundColor: 'white', '& > *': {color: 'primary.500'}},
        ...sx,
      }}
    >
      {Content}
      {Icon && <Box component={Icon} width={32} height={32} sx={{color: 'grey.200', transition: 'color .25s'}} />}
      {!!count && <NotificationIndication sx={{position: 'absolute', right: 8, top: 8}} />}
    </ButtonBase>
  );
};

export const DesktopHeader = () => {
  const logout = useLogout();
  const navigate = useNavigate();
  const openNotifications = useUpdateAtom(openNotificationDialog);
  const isidle = useIdle(minutesToMilliseconds(10));
  const {unreadCount} = api.endpoints.notificationsUnreadedCount.useQuery(undefined, {
    selectFromResult: ({data}) => ({unreadCount: data?.unreadCount || 0}),
    skip: isidle,
    pollingInterval: minutesToMilliseconds(0.5),
  });
  const [Dialog, onLogout] = useConfirmDialog({
    onConfirm: logout,
  });

  return (
    <>
      <Dialog
        text="Вы действительно хотите выйти из аккаунта?"
        btnTitle="Да, выйти"
        cancelBtnTitle="Отмена"
        btnType="primary"
      />
      <Stack py={2} justifyContent="space-between" direction="row">
        <Stack direction="row" spacing={1} alignItems="center">
          <HeaderButton
            onClick={() => navigate('/my-profile')}
            Content={<MyProfileImage size="small" withName noScore noLink noRatingPosition />}
            sx={{px: 2, border: '1px solid', borderColor: 'grey.25'}}
          />
          <HeaderButton
            Icon={LogoutIcon}
            onClick={onLogout}
            sx={{'&:hover': {backgroundColor: 'error.lighter', '& > *': {color: 'error.dark'}}}}
          />
        </Stack>
        <Stack direction="row" alignItems="center">
          <HeaderButtonMenu
            size="small"
            iconColor="grey"
            Button={props => <HeaderButton {...props} Icon={AddPostIcon} />}
          />
          <HeaderButton Icon={NotificationsIcon} count={unreadCount} onClick={openNotifications} />
          <HeaderButton Icon={SettingsIcon} onClick={() => navigate('/settings')} />
        </Stack>
      </Stack>
    </>
  );
};
