import _ from 'lodash/fp';
import {useEffectOnce, useUnmount} from 'react-use';
import {useRef, useCallback, useEffect, useMemo} from 'react';
import {FetchFn, FetchResult} from './types';

export const useInfinite = (fetch: FetchFn, result: FetchResult, limit: number, fetchOnMount?: boolean) => {
  const initialRender = useRef(true);
  const previousItems = useRef<any[]>([]);

  const getItems = useCallback(
    async ({startIndex, stopIndex}: {startIndex: number; stopIndex: number}) => {
      const limit = stopIndex - startIndex;
      if (limit) await fetch({limit: limit, offset: startIndex});
    },
    [fetch]
  );

  useEffect(() => {
    if (initialRender.current) initialRender.current = false;
    else {
      previousItems.current = [];
      getItems({startIndex: 0, stopIndex: limit});
    }
  }, [getItems, limit]);

  useEffectOnce(() => {
    if (fetchOnMount) getItems({startIndex: 0, stopIndex: limit});
  });

  useUnmount(() => {
    previousItems.current = [];
  });

  const items: any[] = useMemo(() => {
    let newIds: any = [];

    if (result.data?.total === 0) {
      previousItems.current = newIds;
    } else {
      newIds = _.compose(_.uniqBy('_id'), _.concat(previousItems.current))(result.data?.data || []);
      previousItems.current = newIds;
    }

    return newIds;
  }, [result.data]);

  const noItems = !_.isUndefined(result.data?.total) && !result.data?.total;
  const hasNoMoreItems = result.data?.total === items.length;

  return {items, loadMore: getItems, noItems, hasNoMoreItems, total: (result.data?.total || 0) as number};
};
