import {useState, ReactNode} from 'react';
import {Navigate, useLocation, Outlet} from 'react-router-dom';

import {useMyId} from 'modules/auth/hooks';

type AuthGuardProps = {
  children?: ReactNode;
  redirectIfAuth?: true;
};

export const AuthGuard = ({children}: AuthGuardProps) => {
  const isAuthenticated = useMyId();
  const {pathname} = useLocation();
  const [requestedLocation, setRequestedLocation] = useState<string | null>(null);

  if (!isAuthenticated) {
    if (pathname !== requestedLocation) setRequestedLocation(pathname);
    return <Navigate to="/welcome" />;
  }

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return children ? <>{children}</> : <Outlet />;
};

export default AuthGuard;
