import {Box} from '@mui/system';
import {Link} from 'react-router-dom';
import {slugify} from 'transliteration';
import {Stack, Typography} from '@mui/material';

import {formatDate} from 'utils/dates';
import {Contents} from 'api/generated/users-api';

import {NPSBadge} from 'components/Layout';
import {GREY} from 'components/theme/palette';
import {getContentsPathWithTags} from 'modules/contents/utils';

export const ContentsFooterInfo = ({data}: {data: Contents}) => {
  return (
    <Stack spacing={0.75}>
      <Stack direction="row" spacing={0.5}>
        {data.tagsSet?.map(item => (
          <Box
            key={item._id}
            component={Link}
            to={getContentsPathWithTags([slugify(item.tag?.name || '')])}
            sx={{color: 'grey.200', typography: 'subtitle1', textTransform: 'lowercase'}}
          >
            #{item.tag?.name}
          </Box>
        ))}
      </Stack>

      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="subtitle1" sx={{color: GREY[200]}}>
          {data.createdAt ? formatDate(data.createdAt) : ''}
        </Typography>
        <NPSBadge value={data.score?.average || 0} />
      </Stack>
    </Stack>
  );
};
