import {Provider} from 'jotai';
import {ReactNode, useEffect} from 'react';
import {useToggle} from 'react-use';
import Stack from '@mui/material/Stack';

import {api} from 'api';
import {useIsMe} from 'modules/auth';
import {StageIdProp} from 'modules/oldQuests2/types';
import {QuestCompleteBlock} from 'modules/oldQuests2/components';

import {CollapseAllButton} from 'components/CollapseButton';

import {stageModeAtom, stageStatusAtom} from './atom';
import {QuestPoint, QuestPointContent} from '../QuestPoint';
import {QuestTopButtonPoint} from '../../components/QuestPoint';
import {selectPointIds, selectPointsOnTop} from './selectors';

export const StageContents = ({stageId, description}: StageIdProp & {description?: ReactNode}) => {
  const [expandAll, toggleExpandAll] = useToggle(false);
  const {status, userId} = api.endpoints.stageIndex.useQuery(
    {stageId},
    {selectFromResult: ({data, isLoading}) => ({isLoading, status: data?.stage.status, userId: data?.stage.userId})}
  );
  const isMe = useIsMe(userId);
  const {pointIds, refetch, pointsOnTop} = api.endpoints.stagePointsIndex.useQuery(
    {stageId},
    {
      refetchOnFocus: true,
      refetchOnMountOrArgChange: true,
      selectFromResult: ({data = []}) => ({
        pointIds: selectPointIds(data),
        pointsOnTop: selectPointsOnTop(data),
      }),
    }
  );

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (window.document.visibilityState === 'visible') refetch();
    };
    window.addEventListener('focus', refetch, false);
    window.addEventListener('visibilitychange', handleVisibilityChange, false);

    return () => {
      window.removeEventListener('focus', refetch);
      window.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [refetch]);

  return (
    <Provider
      initialValues={[
        [stageStatusAtom, status],
        [stageModeAtom, isMe ? 'interact' : 'view'],
      ]}
    >
      {['completed', 'failed'].includes(status || '') && <QuestCompleteBlock status={status as any} />}
      {!['completed', 'failed'].includes(status || '') &&
        isMe &&
        pointsOnTop.map(item => (
          <QuestPointContent
            points={item.points}
            stagePoint={item.stagePoint}
            Component={QuestTopButtonPoint}
            questPointData={item.questPointData}
            key={item.questPointData.questPoint._id}
          />
        ))}
      {description}
      <Stack width="100%" alignItems="end" px={2}>
        <CollapseAllButton onClick={toggleExpandAll} />
      </Stack>
      <Stack spacing={1}>
        {pointIds.map((pointId, i) => (
          <QuestPoint stageId={stageId} pointId={pointId} index={i} key={pointId} collapsedAll={!expandAll} />
        ))}
      </Stack>
    </Provider>
  );
};
