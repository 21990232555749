import {useCallback} from 'react';

import {QuestPointProps} from '../types';
import {useSetPointReference} from './setReference.hook';
import {useDialogView} from 'components/Dialog/context';
import {useOpenGoalSelector} from 'modules/goals/containers';

export const useGoalPointPress = (props: QuestPointProps) => {
  const {
    canPress,
    stagePoint,
    setReference,
    referenceProccessing,
    questPoint: {originId: goalTypeId, type},
  } = useSetPointReference(props);

  const openGoalSelector = useOpenGoalSelector();
  const openGoal = useDialogView('goals', stagePoint?.referenceId);

  const navigateToGoal = async () => {
    if (stagePoint?.referenceId && openGoal) openGoal();
  };

  const setGoalReference = useCallback(async () => {
    if (setReference) openGoalSelector({params: {goalTypeId}, stagePoint: props});
  }, [goalTypeId, setReference, props, openGoalSelector]);

  return {
    canPress: type === 'goal' && canPress,
    isLoading: referenceProccessing,
    onPress: stagePoint?.referenceId ? navigateToGoal : setGoalReference,
  };
};
