import {Fragment, useState} from 'react';
import {getDate, parseISO} from 'date-fns';
import {Button, Card, CardContent, CardHeader, Stack, Typography} from '@mui/material';

import {api} from 'api';
import {GoalReportsIndexApiArg, Goals} from 'api/generated/users-api';

import {useGoalReportForm} from 'modules/goals';
import {GoalReportFiltersMenu} from 'modules/goals/components';
import {GoalReport, GoalSystemReport, GoalReportFeedDate} from 'modules/goals/components';
import {ReactComponent as GoalClosePic} from 'svg/goalClosePic.svg';

type GoalReportFeedProps = {
  goal: Goals;
  isMyGoal: boolean;
};

let date: Date | null = null;

export const GoalReportFeed = ({goal, isMyGoal}: GoalReportFeedProps) => {
  const goalId = `${goal._id}`;

  const [sort, setSort] = useState<GoalReportsIndexApiArg['sort']>('newest_first');
  const {data} = api.endpoints.goalReportsIndex.useQuery({goalId, sort: sort});
  const openReportForm = useGoalReportForm(goal, isMyGoal);
  const goalMoneyGoal = goal.rewardValue?.value;

  return (
    <Card sx={{borderBottomRightRadius: '8px', borderBottomLeftRadius: '8px'}}>
      <CardHeader
        title="Отчеты по цели"
        action={
          <Stack direction="row" spacing={1}>
            <GoalReportFiltersMenu selected={sort} onChange={setSort} />
            {isMyGoal && openReportForm && goal.status !== 'archived' && goal.status !== 'completed' && (
              <Button variant="contained" onClick={openReportForm} size="small">
                Написать отчет
              </Button>
            )}
          </Stack>
        }
      />
      <CardContent>
        <Stack spacing={2} alignItems="center" pb={2}>
          {goal.status === 'completed' && (
            <Stack spacing={1}>
              <GoalClosePic />
              <Typography variant="h2" align="center">
                Цель закрыта!
              </Typography>
            </Stack>
          )}
          {data?.map((item, i) => {
            const Component = item.isSystem ? GoalSystemReport : GoalReport;
            const currentDate = parseISO(item.createdAt as string);
            const showDate = !date || (date && getDate(date) !== getDate(currentDate));
            date = currentDate;
            return (
              <Fragment key={item._id}>
                {showDate && <GoalReportFeedDate isFirst={!i} date={date} />}
                <Component goalReport={item} goalMoneyGoal={goalMoneyGoal} />
              </Fragment>
            );
          })}
        </Stack>
      </CardContent>
    </Card>
  );
};

export default GoalReportFeed;
