import {ceil} from 'lodash/fp';
import {Box, Stack, Typography} from '@mui/material';

import {ReactComponent as PrivacyIcon} from 'svg/icons/privacyIcon.svg';

import {croppedImagePath, useIsTablet} from 'utils';
import {BlurredImage} from 'components/BlurredImage';

const privacyNames = {
  public: 'всем',
  private: 'автору',
  groups: 'команде',
  groups_subscribers: 'команде и подписчикам',
  subscribers: 'подписчикам',
};

export type GoalPrivacyProps = {
  width: number;
  fileId: string;
  hidePrivacyBadge?: boolean;
  privacy: keyof typeof privacyNames;
  round?: boolean;
};

export const GoalPrivacy = ({round = false, fileId, privacy, width, hidePrivacyBadge}: GoalPrivacyProps) => {
  const isTablet = useIsTablet();
  const roundProps = round ? {borderRadius: '50%', overflow: 'hidden', height: width, width} : {};
  return (
    <Box sx={{position: 'relative', ...roundProps}}>
      <BlurredImage path={croppedImagePath(fileId, {ratio: '1:1', maxWidth: ceil(width)})} width={width} />
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          opacity: 0.7,
        }}
      >
        <Stack spacing={0.25} alignItems="center">
          <PrivacyIcon style={{maxWidth: '65%'}} />
          {!hidePrivacyBadge && (
            <Typography
              variant="body2"
              color="white"
              align="center"
              sx={{
                textTransform: 'uppercase',
                fontSize: isTablet ? 6 : 9,
                lineHeight: isTablet ? '8px' : '12px',
                letterSpacing: 1,
                fontWeight: 700,
              }}
            >
              Видно только
              <br />
              {privacyNames[privacy]}
            </Typography>
          )}
        </Stack>
      </Box>
    </Box>
  );
};
