import {isUndefined} from 'lodash';
import {ReactNode, useState, useEffect} from 'react';
import plural from 'plural-ru';

import Box from '@mui/material/Box';
import {Typography} from '@mui/material';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';

import {api} from 'api';
import {Users} from 'api/generated/users-api';

import {NPSBadge} from 'components/Layout';
import {RatingPosition} from 'components/RatingPosition';
import {useIsMe} from 'modules/auth';
import {ProfileImage, UserName} from 'modules/profile/components';

import {ReactComponent as SubscribeIcon} from 'svg/icons/subscribeIcon.svg';
import {ReactComponent as UnsubscribeIcon} from 'svg/icons/unsubscribeIcon.svg';
import {ReactComponent as UsersIcon} from 'svg/icons/userIcon.svg';

import {Disclaimer} from '../Disclaimer';

export type UserItemProps = {
  user?: Users;
  action?: ReactNode;
  showPosition?: boolean;
  showSubscribeButton?: boolean;
  noDisclaimer?: boolean;
  showSubscribers?: boolean;
  noHover?: boolean;
  badge?: ReactNode | string;
};

const SubscribeButton = ({initial, userId}: {initial: boolean; userId: string}) => {
  const [isSubscribed, setIsSubscribed] = useState(initial);
  const isMe = useIsMe(userId);
  const [toggle, {isSuccess}] = api.endpoints.userToggleSubscription.useMutation();
  useEffect(() => {
    if (isSuccess) setIsSubscribed(state => !state);
  }, [isSuccess]);

  if (isMe) return null;

  return (
    <IconButton size="small" sx={{backgroundColor: 'white'}} onClick={() => toggle({userId})}>
      <Box
        height={24}
        width={24}
        sx={{color: isSubscribed ? 'error.dark' : 'grey.200'}}
        component={isSubscribed ? UnsubscribeIcon : SubscribeIcon}
      />
    </IconButton>
  );
};

const Badge = ({badge}: {badge: Required<UserItemProps['badge']>}) => (
  <Box sx={{position: 'absolute', bottom: 0, right: 0, zIndex: 1, bgcolor: 'white', borderRadius: '50%', p: '1px'}}>
    {badge}
  </Box>
);

const SubscribersCount = ({user}: {user: Users}) => {
  if (!user.stats) return null;
  const count = user.stats.subscribersCount;
  return (
    <Stack component="span" direction="row" alignItems="center">
      <UsersIcon />
      <Typography variant="subtitle2" sx={{color: 'grey.200', fontSize: '12px', lineHeight: '12px', ml: 0.5}}>
        {count} {plural(count, 'подписчик', 'подписчика', 'подписчиков')}
      </Typography>
    </Stack>
  );
};

export const UserItem = ({
  user,
  action,
  showPosition,
  showSubscribeButton,
  noDisclaimer,
  showSubscribers,
  noHover,
  badge,
}: UserItemProps) => {
  const isMe = useIsMe();
  if (!user) return null;
  const position = user.ratingPosition?.byNPS;

  return (
    <Stack
      direction="row"
      gap={1.5}
      py={0.5}
      px={2}
      alignItems="center"
      sx={{
        backgroundColor: 'white',
        transition: 'background-color .25s',
        '&:hover': noHover ? {} : {backgroundColor: 'primary.10'},
      }}
    >
      {showPosition && <RatingPosition sx={{mr: 0.5}} position={position} />}
      <Box sx={{width: 'fit-content', height: 'fit-content', position: 'relative'}}>
        {badge && <Badge badge={badge} />}
        <ProfileImage user={user} size="big" />
      </Box>

      <Stack flexGrow={1} gap={0.5} display="flex" flexDirection="column" alignSelf="stretch" justifyContent="center">
        <UserName user={user} noScore />
        {!noDisclaimer && <Disclaimer disclaimer={user.disclaimer} />}
        {showSubscribers && <SubscribersCount user={user} />}
      </Stack>
      <Stack spacing={1} direction="row" alignItems="center">
        <NPSBadge value={user.stats?.NPS} />
        {showSubscribeButton && !isMe && !isUndefined(user.meIsSubscriber) && (
          <SubscribeButton userId={`${user._id}`} initial={!!user.meIsSubscriber} />
        )}
        {action && action}
      </Stack>
    </Stack>
  );
};

export default UserItem;
