import {api} from 'api';

import {ReactComponent as RemoveGroupIcon} from 'svg/groupIcons/removeGroup.svg';

import {useClaimMenuItem} from 'modules/claims';
import {ButtonMenu} from 'components/ButtonMenu';
import {useConfirmDialog} from 'components/Dialog';
import {usePublicationFormMenuItem} from '..';
import {useNavigate} from 'react-router';
import {getTaskJobPath} from 'modules/tasks/utils';

type PublicationMenuProps = {
  entryId: string;
  isMy: boolean;
  jobId?: string;
};

export const PublicationMenu = ({entryId, isMy, jobId}: PublicationMenuProps) => {
  const navigate = useNavigate();
  const claimItem = useClaimMenuItem({referenceName: 'Publications', referenceId: entryId});
  const editItem = usePublicationFormMenuItem(entryId);
  const [postRemove] = api.endpoints.publicationSafeDelete.useMutation();
  const [ConfirmRemove, remove] = useConfirmDialog({
    onConfirm: () => postRemove({postId: entryId}),
  });
  if (editItem && jobId) {
    editItem.onClick = () => navigate(getTaskJobPath(jobId)) as any;
    editItem.label = 'К ответу на задание';
  }
  const buttons = [
    !isMy ? claimItem : null,
    isMy ? editItem : null,
    isMy ? {label: 'Удалить публикацию', onClick: remove, icon: <RemoveGroupIcon />} : null,
  ].filter(Boolean);

  if (!buttons.length) return null;
  return (
    <>
      {isMy && (
        <ConfirmRemove
          badge="alert"
          align="center"
          cancelBtnTitle="Нет"
          btnTitle="Да, удалить"
          title="Удалить публикацию?"
          text="Действие невозможно отменить"
        />
      )}
      <ButtonMenu
        buttonProps={{
          size: 'small',
        }}
        variant="dots"
        items={buttons}
      />
    </>
  );
};
