import React, {useCallback} from 'react';

import {api} from 'api';
import {Contents} from 'api/generated/users-api';

import {useContentsFilterMenu} from 'modules/contents/components/ContentsFilterMenu';
import {useAutocompleteField} from 'components/SearchField';
import {InfiniteList} from 'components/InfiniteLoader';
import {ContentsListSkeleton} from 'components/Skeleton';

import {ContentPreview} from '../ContentPreview';

export const ContentsList = () => {
  const [Search, tagsId] = useAutocompleteField();
  const {tags} = api.endpoints.tagsIndex.useQuery(
    {type: 'default'},
    {selectFromResult: ({data}) => ({tags: (data || []).map(item => ({label: item.name, value: `${item._id}`}))})}
  );
  const [CategoriesMenu, categoryId] = useContentsFilterMenu();
  const [get, result] = api.endpoints.contentsIndex.useLazyQuery();
  const getContents = useCallback(
    (params: {offset: number; limit: number}) =>
      get({categoryId, tagsId: tagsId.length ? tagsId : undefined, ...params}),
    [categoryId, get, tagsId]
  );

  return (
    <>
      <Search
        action={CategoriesMenu}
        options={tags}
        paramName="tags"
        placeholder="Поиск по тегам"
        noOptionsText="Таких тегов нет"
        openText="Открыть поиск по тегам"
        closeText="Закрыть поиск по тегам"
        clearText="Очистить выбранные теги"
        getLimitTagsText={more => `И еще ${more}`}
      />
      <InfiniteList<Contents>
        fetchFn={getContents}
        LoadingComponent={fetchItemsCount => <ContentsListSkeleton limit={fetchItemsCount} />}
        result={result}
        limit={10}
        spacing={1}
      >
        {item => <ContentPreview data={item} key={item._id} />}
      </InfiniteList>
    </>
  );
};

export default ContentsList;
