import {InputBase} from '@mui/material';
import {styled} from '@mui/system';
import {GREY} from 'components/theme/palette';

export const Container = styled('div')`
  display: flex;
  justify-content: flex-start;

  min-height: 42px;
  padding: ${({theme}) => theme.spacing(1)};

  box-sizing: border-box;
  border-radius: 20px;
  border: 1px solid ${GREY[25]};
  background-color: white;

  transition: background-color 0.25s, border-color 0.25s;
`;

export const Content = styled('div')`
  position: relative;
  flex-grow: 1;
  margin-left: ${({theme}) => theme.spacing(1)};
`;

export const Textarea = styled(InputBase)``;
