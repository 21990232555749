import {Users} from 'api/generated/users-api';

export const getUserName = (user: Users, preferUsername?: boolean) => {
  if (preferUsername && user.userLogin?.value) return user.userLogin?.value;
  return [user.name, user.surname].join(' ');
};
export const getUserLink = (user?: Users, postfix?: string) =>
  user ? `/@${user.userLogin?.value || user.ident || user._id}${postfix ? `/${postfix}` : ''}` : '';

const maleRelationStatuses: {[K in Exclude<Users['relationStatus'], undefined>]: string} = {
  single: 'Свободен',
  in_relation: 'В отношениях',
  in_love: 'Влюблен',
  in_search: 'В поиске',
  engaged: 'Обручен',
  married: 'Женат',
  civil_union: 'В гражданском браке',
  complicated: 'Все непросто',
};

const femaleRelationStatuses: {[K in Exclude<Users['relationStatus'], undefined>]: string} = {
  single: 'Свободна',
  in_relation: 'В отношениях',
  in_love: 'Влюблена',
  in_search: 'В поиске',
  engaged: 'Обручена',
  married: 'Замужем',
  civil_union: 'В гражданском браке',
  complicated: 'Все непросто',
};

export const relationStatusText = (gender: Users['gender']) =>
  gender === 'female' ? femaleRelationStatuses : maleRelationStatuses;

const relationStatusOptions = [
  {
    value: 'single',
    male: 'Одинок',
    female: 'Одинока',
  },
  {
    value: 'in_relation',
    male: 'В отношениях',
    female: 'В отношениях',
  },
  {
    value: 'engaged',
    male: 'Помолвлен',
    female: 'Помолвлена',
  },
  {
    value: 'married',
    male: 'Женат',
    female: 'Замужем',
  },
  {
    value: 'civil_union',
    male: 'В гражданском браке',
    female: 'В гражданском браке',
  },
  {
    value: 'in_love',
    male: 'Влюблен',
    female: 'Влюблена',
  },
  {
    value: 'complicated',
    male: 'Все сложно',
    female: 'Все сложно',
  },
  {
    value: 'in_search',
    male: 'В поиске',
    female: 'В поиске',
  },
];

export const useRelationStatusOptions = (gender?: Users['gender']) => {
  return relationStatusOptions.map(i => ({
    value: i.value,
    name: i[!gender || gender === 'not_sure' ? 'male' : gender || 'male'],
  }));
};
