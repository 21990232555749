import {FC, memo} from 'react';

import {QuestPointDistributorProps} from '../../types';
import {stagePointComponentsMap} from '../../components/StagePoints';
import {isStagePointEquals, selectStagePointCustomOptions} from '../../utils';
import {pressHooksMap, useGetStagePointById, useGetStagePointByIdWithSelector} from '../../hooks';

export const QuestPointDistributor: FC<QuestPointDistributorProps> = memo(
  ({type, componentName, ...props}: QuestPointDistributorProps) => {
    const pointExists = useGetStagePointById({stageId: props.stageId, stagePointId: props.stagePointId});
    const {stagePointComponent} = useGetStagePointByIdWithSelector(
      {stageId: props.stageId, stagePointId: props.stagePointId},
      selectStagePointCustomOptions
    );
    const Component = stagePointComponentsMap[stagePointComponent || componentName];

    if (!pointExists || !Component) return null;
    return <Component usePressOptions={pressHooksMap[type]} type={type} {...props} />;
  },
  isStagePointEquals
);
