import {Tabs, Tab} from '@mui/material';
import {useState} from 'react';

export const GoalsTabs = ({defaultValue = 'all'}: {defaultValue?: string}) => {
  const [tab, setTab] = useState(defaultValue);
  return (
    <Tabs value={tab} onChange={(e, value) => setTab(value)} scrollButtons={false} variant="scrollable">
      <Tab label="Все" value="all" />
      <Tab label="Активные" value="active" />
      <Tab label="Выполненные" value="done" />
      <Tab label="Архивные" value="archive" />
      <Tab label="Здоровье" value="health" />
    </Tabs>
  );
};

export default GoalsTabs;
