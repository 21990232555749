import {Waypoint} from 'react-waypoint';
import {useState, useCallback} from 'react';

import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';

import {useIsViewed} from 'utils';
import {HeaderSetup} from 'components/Layout';
import {Comments} from 'modules/social/comments';
import {SocialStateProvider} from 'modules/social';
import {ContentsStructure} from 'modules/contents/types';
import {ContentsContent} from 'modules/contents/containers';
import {ContentsFooterInfo} from 'modules/contents/components';
import {ReactionsBlock} from 'modules/social/reactions';

import {useContentsContext} from './context';
// import {SocialBlock} from 'components/SocialBlock';

export const ContentPage = () => {
  const data = useContentsContext();
  const [loaded, setLoaded] = useState(false);
  const onLoaded = useCallback(() => setLoaded(true), []);
  const [, setViewed] = useIsViewed({referenceName: 'Contents', referenceId: `${data._id}`}, Boolean(data.isViewed));

  return (
    <SocialStateProvider
      scope={`Contents.${data._id}`}
      comments={data.comments}
      reactions={data.reactions}
      score={data.score}
    >
      <HeaderSetup showSettings={false} title="Материал" backLink="/contents" />
      <Stack spacing={1} width={1}>
        <Card>
          <CardContent>
            <Typography variant="h1">{data.name}</Typography>
          </CardContent>
          <ContentsContent content={data.structure as ContentsStructure | undefined} onLoaded={onLoaded} />
          <Waypoint onEnter={loaded ? setViewed : undefined} />
          <CardContent>
            <ContentsFooterInfo data={data} />
          </CardContent>
        </Card>
        <ReactionsBlock title="Оцените статью" />
        <Comments referenceName="Contents" referenceId={`${data._id}`} referenceAuthorId={data.userId} />
      </Stack>
    </SocialStateProvider>
  );
};
