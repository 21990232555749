import {createSelector} from '@reduxjs/toolkit';
import {StagePointData} from 'api/generated/users-api';

const selectSelf = (state: StagePointData[] | undefined) => state || [];
export const selectAdventurePoints = createSelector(selectSelf, data =>
  data.filter(
    item =>
      !item.questPointData.timelineCheck.skipped &&
      ['quest', 'shortcut'].includes(item.questPointData.questPoint.type) &&
      item.questPointData.questPoint.options.quests?.questId
  )
);
