import {formatDate} from 'utils/dates';
import NumberFormat from 'react-number-format';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import {ListPosition} from 'components/ListPosition';
import {MarkdownRender} from 'components/MarkdownRender';

import {FileAnswer} from './FileAnswer';
import {PhotoAnswer} from './PhotoAnswer';
import {ActionAnswersProps} from './types';

export const ActionAnswers = ({action, template, answers, templateOptions, hideName = false}: ActionAnswersProps) => {
  if (!template) return null;
  const length = Object.keys(answers).length;
  return (
    <Box>
      {!hideName && (
        <Typography sx={{mb: 1}} variant="h3">
          {action.name || template?.name}
        </Typography>
      )}
      <Stack spacing={2}>
        {Object.keys(answers).map((key, index) => {
          const data = answers[key].reduce(
            (acc, item) => ({...acc, [item.templateOptionId]: item.value}),
            {} as Record<string, string>
          );
          const answersLength = answers[key].length;

          return (
            <Stack direction="row" spacing={2.5} key={key}>
              {length > 1 && (
                <Box flexShrink={0}>
                  <ListPosition index={index} />
                </Box>
              )}
              <Stack flexGrow={1} spacing={2}>
                {templateOptions.map(option => {
                  const rawValue = data[`${option._id}`];
                  if (!rawValue) return null;

                  let value: string | JSX.Element = rawValue;
                  if (template.type === 'number')
                    value = <NumberFormat displayType="text" value={rawValue} thousandSeparator={' '} />;
                  if (template.type === 'date') value = formatDate(rawValue, 'PPP');
                  if (template.type === 'file') value = <FileAnswer fileId={rawValue} />;
                  if (template.type === 'photo') value = <PhotoAnswer fileId={rawValue} />;
                  if (template.type === 'custom') {
                    if (option.type === 'number')
                      value = <NumberFormat displayType="text" value={rawValue} thousandSeparator={' '} />;
                    if (option.type === 'date') value = formatDate(rawValue, 'PPP');
                    if (option.type === 'file') value = <FileAnswer fileId={rawValue} />;
                    if (option.type === 'photo') value = <PhotoAnswer fileId={rawValue} />;
                  }

                  const prefix = answersLength > 1 && option && option.name.trim().length ? option.name : undefined;

                  return (
                    <Box key={`${option._id}`}>
                      {prefix && (
                        <Typography
                          variant="body2"
                          sx={{color: 'grey.200', letterSpacing: '0.25px', lineHeight: '18px', mb: 0.5}}
                          component="div"
                        >
                          {prefix}
                        </Typography>
                      )}
                      {typeof value === 'string' ? <MarkdownRender content={value} /> : value}
                    </Box>
                  );
                })}
              </Stack>
            </Stack>
          );
        })}
      </Stack>
    </Box>
  );
};
