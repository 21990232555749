import {Goals} from 'api/generated/users-api';
import {ReactComponent as ArchiveIcon} from 'svg/icons/archiveIcon.svg';
import {ReactComponent as RemoveGroupIcon} from 'svg/groupIcons/removeGroup.svg';

import ButtonMenu from 'components/ButtonMenu';
import {useClaimMenuItem} from 'modules/claims';
import {useArchiveHandler, useRemoveHandler} from 'modules/goals/hooks';

import {useGoalReportMenuItem} from '../GoalReportForm';
import {useGoalCreateMenuItem, useCloneGoalMenuItem} from '../GoalFormProvider/context';

type GoalContextMenuProps = {goal: Goals; isMe: boolean};

export const GoalContextMenu = ({goal, isMe}: GoalContextMenuProps) => {
  const editGoalMenuItem = useGoalCreateMenuItem(goal);
  const cloneGoalMenuItem = useCloneGoalMenuItem(goal, isMe);
  const addReportMenuItem = useGoalReportMenuItem(goal, isMe);
  const [ConfirmRemove, onRemove] = useRemoveHandler(`${goal._id}`);
  const [ConfirmArchive, onArchive] = useArchiveHandler(`${goal._id}`);
  const claimItem = useClaimMenuItem({referenceName: 'Goals', referenceId: `${goal._id}`}, isMe);

  const isArchived = goal.status === 'archived';
  const items = [
    cloneGoalMenuItem,
    addReportMenuItem,
    !isArchived ? editGoalMenuItem : null,
    isMe && goal.status !== 'completed' && !isArchived
      ? {label: 'Архивировать', icon: <ArchiveIcon />, onClick: onArchive}
      : null,
    isMe && !goal.deletedAt ? {label: 'Удалить', icon: <RemoveGroupIcon />, onClick: onRemove} : null,
    claimItem,
  ];

  if (!items.length) return null;
  return (
    <>
      <ConfirmArchive />
      <ConfirmRemove />
      <ButtonMenu items={items} variant="dots" buttonProps={{size: 'small'}} />
    </>
  );
};
