import {api} from 'api';
import {useCallback} from 'react';
import {useDispatch} from 'react-redux';
import {Publications} from 'api/generated/users-api';

import {useErrorShackbar} from 'utils';
import {openPostForm} from 'store/uiModule';
import {useDialogView} from 'components/Dialog/context';

import {QuestPointProps} from '../types';
import {useSetPointReference} from './setReference.hook';
import {useGetStagePointByIdWithSelector} from './getStagePointsByContainerName.hook';

export const usePostPointPress = (props: QuestPointProps) => {
  const dispatch = useDispatch();
  const {
    canPress,
    stagePoint,
    setReference,
    referenceProccessing,
    questPoint: {type, name},
  } = useSetPointReference(props);
  const openFullPost = useDialogView('feed');
  const {createPost, isLoading} = useCreatePost();
  const {publications} = useGetStagePointByIdWithSelector(props, data => ({
    publications: data.questPointData.questPoint.options.publications,
  }));

  const onPress = useCallback(async () => {
    if (stagePoint?.referenceId && openFullPost) openFullPost(stagePoint.referenceId);
    else if (setReference) {
      if (publications?.contentRequired) dispatch(openPostForm(props));
      else createPost(post => setReference(post._id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setReference, name, stagePoint?.referenceId, createPost, publications?.contentRequired]);

  return {
    onPress,
    isLoading: isLoading || referenceProccessing,
    canPress: type === 'publication' && canPress,
  };
};

const useCreatePost = () => {
  const openErrorSnackbar = useErrorShackbar();
  const [create, {isLoading}] = api.endpoints.publicationsAdd.useMutation();
  const createPost = useCallback(
    async (onSuccess: (data: Publications) => Promise<void>) => {
      const result = await create({
        addPublicationDto: {
          publication: {
            enabled: true,
            type: 'report',
            privacy: 'public',
            filesAspectRatio: '1:1',
          },
        },
      });

      if ('data' in result) return onSuccess(result.data);
      else if ('error' in result) openErrorSnackbar(result.error, 'Не получилось создать публикацию');
    },
    [create, openErrorSnackbar]
  );

  return {createPost, isLoading};
};
