import {Box, Stack, Typography} from '@mui/material';
import {LazyLoadImage} from 'react-lazy-load-image-component';
import {useNavigate} from 'react-router';

import {GroupsRequests} from 'api/generated/users-api';
import {croppedImagePath} from 'utils';
import {timeAgo} from 'utils/dates';
import {NotificationIndication} from 'components/Layout/NotificationIndication';

import {useMyId} from 'modules/auth';
import {GroupRequestVotePanel} from 'modules/groups';
import {ProfileImage} from 'modules/profile/components/ProfileImage';

import {ReactComponent as ChevronRight} from 'svg/chevrons/chevronRight.svg';

export const NotificationGroupRequestItem = ({request}: {request: GroupsRequests}) => {
  const navigate = useNavigate();
  const myId = useMyId();

  if (!request.group || !request.user || !request._id) return null;

  const {_id: groupId, fileId} = request.group;
  const to = `/teams/${groupId}/requests`;
  const isSeen = Boolean(
    request.approvedUsersId.find(i => i === myId) || request.declinedUsersId.find(i => i === myId)
  );

  return (
    <Stack
      spacing={1.5}
      direction="row"
      sx={{
        px: 2,
        py: 1,
        alignItems: 'flex-start',
        backgroundColor: 'transparent',
        cursor: to ? 'pointer' : undefined,
        transition: 'background-color .3s',
        '&:hover': {backgroundColor: 'primary.10'},
      }}
      onClick={to ? () => navigate(to) : undefined}
    >
      <Stack spacing={1.5} direction="row" sx={{alignItems: 'center', minHeight: 12}}>
        <NotificationIndication isSeen={isSeen} sx={{flexShrink: 0}} />
        {fileId && (
          <Box
            flexShrink={0}
            sx={{
              borderRadius: '50%',
              overflow: 'hidden',
              '& span': {verticalAlign: 'top'},
            }}
          >
            <LazyLoadImage
              width={48}
              height={48}
              effect="blur"
              src={croppedImagePath(fileId, {ratio: '1:1', maxWidth: 100})}
            />
          </Box>
        )}
      </Stack>
      <Stack flexGrow={1} spacing={0.5} sx={{alignSelf: 'flex-start'}}>
        <Typography variant="subtitle1" sx={{color: 'grey.200'}}>
          {timeAgo(request.createdAt)}
        </Typography>
        <Typography variant="body2" sx={{color: 'grey.200'}}>
          Запрос на вступление в вашу команду от{' '}
          <Box sx={{display: 'inline-flex', alignItems: 'center', transform: 'translateY(3px)', height: '14px'}}>
            <ProfileImage user={request.user} size="xsmall" noLink inline />
            <Typography fontWeight={500} sx={{color: 'grey.700', ml: 0.5}} variant="body2" component="span">
              {request.user.userLogin?.value || request.user.name}
            </Typography>
          </Box>
        </Typography>
        <Box sx={{border: '1px solid', borderColor: 'grey.10', borderRadius: 2, overflow: 'hidden'}}>
          <GroupRequestVotePanel request={request} />
        </Box>
      </Stack>
      {to && (
        <Box
          component={ChevronRight}
          sx={{flexShrink: 0, width: 24, height: 24, alignSelf: 'center', color: 'grey.200'}}
        />
      )}
    </Stack>
  );
};
