import ButtonMenu from 'components/ButtonMenu';
import {GoalsReports} from 'api/generated/users-api';

import {ReactComponent as RemoveGroupIcon} from 'svg/groupIcons/removeGroup.svg';

import {useIsMe} from 'modules/auth';
import {useClaimMenuItem} from 'modules/claims';
import {useRemoveGoalReportHandler} from 'modules/goals/hooks';
import {useGoalEditReportMenuItem} from '../GoalReportForm';

type GoalContextMenuProps = {goalReport: GoalsReports; authorId?: string};

export const GoalReportContextMenu = ({goalReport, authorId}: GoalContextMenuProps) => {
  const editGoalReportMenuItem = useGoalEditReportMenuItem(goalReport);
  const referenceId = `${goalReport._id}`;
  const author = goalReport.user?._id || authorId;
  const isMe = useIsMe(`${author}`);
  const claimItem = useClaimMenuItem({referenceName: 'GoalsReports', referenceId}, isMe);
  const [ConfirmRemove, onRemove] = useRemoveGoalReportHandler(referenceId, `${goalReport.goalId}`);
  const items = [
    editGoalReportMenuItem,
    isMe ? {label: 'Удалить', icon: <RemoveGroupIcon />, onClick: onRemove} : null,
    claimItem,
  ];

  if (!author || !items.filter(Boolean).length) return null;
  return (
    <>
      <ConfirmRemove />
      <ButtonMenu
        items={items}
        variant="dots"
        buttonProps={{
          size: 'small',
        }}
      />
    </>
  );
};
