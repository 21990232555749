import {useMemo} from 'react';
import {Box} from '@mui/system';
import {useAtomValue} from 'jotai/utils';
import {isUndefined, floor} from 'lodash';
import {Stack, Typography} from '@mui/material';

import {GREY} from 'components/theme/palette';
import {ratingStatsAtom, scopeAtom} from 'modules/social/store';
import {ReactComponent as StarFilledIcon} from 'svg/rating/starFilled.svg';
import {focusAtom} from 'jotai/optics';

export const ScoreValue = () => {
  const scope = useAtomValue(scopeAtom, Symbol.for('social-keys'));
  const ratingValueAtom = useMemo(() => focusAtom(ratingStatsAtom, o => o.prop(scope)), [scope]);
  const value = useAtomValue(ratingValueAtom);

  return (
    <Stack direction="row" spacing={0.5}>
      {!isUndefined(value) && value.average && (
        <Box
          display="flex"
          alignItems="center"
          sx={{
            marginLeft: 'auto',
            height: 26,
            border: 1,
            borderRadius: 100,
            borderColor: GREY[25],
            py: '5px',
            px: '8px',
          }}
        >
          <Typography variant="subtitle2" sx={{marginLeft: 0.5, color: GREY[200]}}>
            Общая оценка
          </Typography>
          <Box display="flex" alignItems="center" sx={{marginLeft: 0.5}}>
            <StarFilledIcon width="12" height="12" />
            <Typography variant="body2" sx={{marginLeft: 0.25}}>
              {floor(value.average, 2)}
            </Typography>
          </Box>
        </Box>
      )}
    </Stack>
  );
};

export default ScoreValue;
