import {Theme} from '@mui/material/styles';
import {GREY} from '../palette';

// ----------------------------------------------------------------------

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default function Typography(theme: Theme) {
  return {
    MuiTypography: {
      defaultProps: {},
      styleOverrides: {
        root: {
          color: GREY[700],
        },
      },
    },
  };
}
