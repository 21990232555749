import {useNavigate} from 'react-router';

import fill from 'lodash/fill';
import {SxProps} from '@mui/system';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Skeleton from '@mui/material/Skeleton';

import {api} from 'api';
import {HideScrollbar} from 'components/HideScrollbar';
import {ReactComponent as ChevronRight} from 'svg/chevrons/chevronRight.svg';
import {ContentsPreviewsItem} from '../../components';

const ContentsPreviewsItemSkeleton = () => (
  <Skeleton
    width={200}
    height={200}
    sx={{
      boxShadow: theme => theme.customShadows.inset,
      flex: '0 0 200px',
      borderRadius: '8px',
    }}
    animation="wave"
    variant="rectangular"
  />
);

export const ContentsPreviews: React.VFC<{sx?: SxProps}> = ({sx}) => {
  const navigate = useNavigate();
  const {data, isLoading} = api.endpoints.contentsIndex.useQuery({limit: 5});
  const itemsWithFiles = (data?.data || []).filter(item => Boolean(item.fileId));

  if (!itemsWithFiles.length && !isLoading) return null;
  return (
    <Card>
      <HideScrollbar>
        <Stack spacing={2} flexWrap="nowrap" direction="row" width="fit-content" alignItems="center" sx={sx}>
          {isLoading && fill(Array(4), '').map((_, i) => <ContentsPreviewsItemSkeleton key={i} />)}
          {itemsWithFiles.map(item => (
            <ContentsPreviewsItem key={item._id} item={item} />
          ))}
          {!!itemsWithFiles.length && (
            <Button size="small" variant="contained" color="secondary" onClick={() => navigate('/contents')}>
              Ещё
              <Box component={ChevronRight} color="primary.main" />
            </Button>
          )}
        </Stack>
      </HideScrollbar>
    </Card>
  );
};
