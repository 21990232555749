import {useIdle} from 'react-use';
import {Link} from 'react-router-dom';
import {memo, useContext} from 'react';
import {useNavigate} from 'react-router';
import {minutesToMilliseconds} from 'date-fns';

import Box from '@mui/system/Box';
import Stack from '@mui/material/Stack';
import {useUpdateAtom} from 'jotai/utils';
import IconButton from '@mui/material/IconButton';
import {Typography} from '@mui/material';

import {api} from 'api';
import {useIsTablet} from 'utils';

import {ReactComponent as Logo} from 'svg/logo.svg';
// import {ReactComponent as BackButton} from 'svg/icons/chevronLeftIcon.svg';
import {ReactComponent as SettingsIcon} from 'svg/icons/settingsIcon.svg';
import {ReactComponent as NotificationsIcon} from 'svg/icons/notificationsIcon.svg';

import {openNotificationDialog} from 'modules/notifications';

import * as Styled from './styled';
import {HeaderButtonMenu} from '../HeaderButtonMenu';
import {HeaderContext, HeaderContextState} from './context';
import {NotificationIndication} from '../NotificationIndication';
import {Backlink} from './Backlink';

export const Header = memo(
  ({title, backLink, actions, showSettings, showMainActions, showNotifications = true, info}: HeaderContextState) => {
    const isTablet = useIsTablet();
    const {data} = api.endpoints.profileIndex.useQueryState();
    const navigate = useNavigate();
    const toSettings = () => navigate('/settings');
    const openNotifications = useUpdateAtom(openNotificationDialog);
    const isidle = useIdle(minutesToMilliseconds(10));
    const {unreadCount} = api.endpoints.notificationsUnreadedCount.useQuery(undefined, {
      selectFromResult: ({data}) => ({unreadCount: data?.unreadCount || 0}),
      skip: isidle,
      pollingInterval: minutesToMilliseconds(0.5),
    });

    const Content = (
      <Styled.Content>
        <Styled.Side side="left">
          <Backlink backLink={backLink} />
          {showSettings && data && isTablet && (
            <IconButton
              size="small"
              onClick={toSettings}
              sx={{'&:hover': {backgroundColor: 'transparent', '& > *': {color: 'primary.light'}}}}
            >
              <Box component={SettingsIcon} sx={{color: 'primary.main', transition: 'color 0.25s'}} />
            </IconButton>
          )}
        </Styled.Side>
        <Styled.TitleContainer>
          {title && <Styled.Title>{title}</Styled.Title>}
          {!title && (
            <Link to="/feed">
              <Logo />
            </Link>
          )}
        </Styled.TitleContainer>
        <Styled.Side side="right">
          {info && (
            <Typography component="div" variant="body2" sx={{color: 'grey.200', pr: 1.25}}>
              {info}
            </Typography>
          )}
          <Stack direction="row" spacing={0.5} alignItems="center">
            {showNotifications && isTablet && (
              <IconButton sx={{position: 'relative'}} onClick={openNotifications} size="small">
                <Box component={NotificationsIcon} sx={{color: 'primary.500', display: 'block'}} width={32} />
                {unreadCount > 0 && <NotificationIndication sx={{position: 'absolute', top: 4, right: 4}} />}
              </IconButton>
            )}
            {actions || (isTablet && showMainActions && <HeaderButtonMenu iconColor="blue" size="small" />)}
          </Stack>
        </Styled.Side>
      </Styled.Content>
    );

    if (isTablet) {
      return (
        <>
          <Styled.Container />
          <Styled.FixedContainer boxShadow={theme => theme.customShadows.inset}>{Content}</Styled.FixedContainer>
        </>
      );
    }
    return <Styled.Container sx={{borderTopLeftRadius: 8, borderTopRightRadius: 8}}>{Content}</Styled.Container>;
  }
);

export const AppHeader = () => {
  const props = useContext(HeaderContext);
  return <Header {...props} />;
};

export default Header;
