import {memo} from 'react';
import {useField} from 'formik';
import {IconButton, Stack} from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';

import {CommonProps, NumberProps} from '../types';

type FieldProps = {name: string; type: string} & CommonProps & NumberProps;

export const ScoreField = ({name, maxValue}: FieldProps) => {
  const starArray = new Array(maxValue || 10).fill(0);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [{value}, meta, {setValue}] = useField(name);
  return (
    <Stack direction="row" justifyContent="space-between">
      {starArray.map((_, index) => (
        <IconButton key={`${name}-${index}`} size="small" onClick={() => setValue(index)}>
          {index > value ? <StarBorderIcon /> : <StarIcon />}
        </IconButton>
      ))}
    </Stack>
  );
};

export default memo(ScoreField);
