import {useCallback, useMemo} from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import {api} from 'api';
import {Users} from 'api/generated/users-api';

import {UserItem} from 'modules/search/components';
import {ProfileImage} from 'modules/profile/components';

import {useSearchField} from 'components/SearchField';
import {InfiniteList} from 'components/InfiniteLoader';
import {HideScrollbar} from 'components/HideScrollbar';
import {RatingPosition} from 'components/RatingPosition';
import {RoundListContentSkeleton, UserListSkeleton} from 'components/Skeleton';

import {UsersListProps} from './types';

export const UsersList = ({
  params,
  actions,
  spacing,
  limit = 30,
  withSearch,
  usersBadges,
  showPosition = false,
  showSubscribeButton,
}: UsersListProps) => {
  const [SearchField, username] = useSearchField();
  const [getUsers, result] = api.endpoints.usersIndex.useLazyQuery();
  const memoized = useMemo(() => params, [params]);
  const loadUsers = useCallback(
    ({limit, offset}: {limit: number; offset: number}) =>
      getUsers({
        ...memoized,
        limit,
        offset,
        username: params.username || username || undefined,
      }),
    [memoized, getUsers, username, params.username]
  );

  return (
    <Stack spacing={0.5}>
      {withSearch && <SearchField />}
      <Box>
        <InfiniteList
          limit={limit}
          result={result}
          spacing={spacing}
          fetchFn={loadUsers}
          LoadingComponent={fetchItemsCount => <UserListSkeleton limit={fetchItemsCount} />}
        >
          {(user: Users) => (
            <UserItem
              user={user}
              badge={usersBadges?.[user._id as string]}
              showPosition={showPosition}
              action={actions && actions(user)}
              showSubscribeButton={showSubscribeButton}
            />
          )}
        </InfiniteList>
      </Box>
    </Stack>
  );
};

export const UsersRoundList = ({params}: Omit<UsersListProps, 'withSearch' | 'actions'>) => {
  const {data, isLoading} = api.endpoints.usersIndex.useQuery({...params});
  return (
    <HideScrollbar sx={{p: 1, mt: 0, ml: -1}}>
      <Stack spacing={1} direction="row" flexWrap="nowrap" width="fit-content" pl={2}>
        {isLoading && <RoundListContentSkeleton count={9} />}
        {(data?.data || []).map(item => (
          <Box key={item._id} sx={{position: 'relative'}}>
            <ProfileImage user={item} size="large" />
            <Box position="absolute" sx={{top: 0, right: 0}}>
              <RatingPosition position={item.ratingPosition?.byNPS} size="large" />
            </Box>
          </Box>
        ))}
      </Stack>
    </HideScrollbar>
  );
};
