import {Box, CardContent, CardHeader, Stack, Typography} from '@mui/material';
import {meanBy, ceil} from 'lodash';

import {useSafeContext} from 'utils';
import {GroupContext} from 'modules/groups/context';
import {ReactComponent as RatingIcon} from 'svg/social/ratingIcon.svg';

export const GroupAverageRatingWidget = () => {
  const {users} = useSafeContext(GroupContext);
  const ratingCount = ceil(
    meanBy(users, i => i.user?.stats?.NPS),
    1
  );
  return (
    <Box
      flex={1}
      borderRadius={2}
      display="flex"
      bgcolor="white"
      flexDirection="column"
      justifyContent="space-between"
      boxShadow={theme => theme.customShadows.default}
    >
      <CardHeader
        title="Средний рейтинг участников"
        titleTypographyProps={{color: 'grey.300', fontWeight: '400', variant: 'h4'}}
      />
      <CardContent>
        <Stack direction="row" alignItems="center" spacing={1}>
          <Box component={RatingIcon} height={24} width={24} />
          <Typography variant="h1">{ratingCount}</Typography>
        </Stack>
      </CardContent>
    </Box>
  );
};
