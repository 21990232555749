import {api} from 'api';
import {StagePointData} from 'api/generated/users-api';
import {useAtomValue} from 'jotai';
import {useSnackbar} from 'notistack';
import {useCallback, useEffect} from 'react';
import {rootStageIdAtom} from '../Quest/atom';

export const useSetPointReference = ({questPointData, stagePoint}: StagePointData) => {
  const {enqueueSnackbar} = useSnackbar();
  const parentStageId = useAtomValue(rootStageIdAtom, 'parent-stage');
  const [set, {isLoading: referenceProccessing}] = api.endpoints.stagePointSetReference.useMutation();
  const [refreshPoints, {isLoading: pointsRefreshing}] = api.endpoints.stagePointsIndex.useLazyQuery();
  const setReference = useCallback(
    async (referenceId: string) => {
      if (stagePoint?.status !== 'new') return true;
      if (!stagePoint?._id || !questPointData.timelineCheck.available) return false;
      const result = await set({pointId: `${stagePoint._id}`, setReferenceDto: {referenceId}});
      if (parentStageId) refreshPoints({stageId: parentStageId});
      if ('error' in result) {
        enqueueSnackbar((result.error as any).data.message || 'Не получилось взять задачу в работу', {
          variant: 'error',
          autoHideDuration: 3000,
        });
        return false;
      } else if ('data' in result) return true;
      return false;
    },
    [
      //
      set,
      refreshPoints,
      parentStageId,
      enqueueSnackbar,
      stagePoint?.status,
      stagePoint?._id,
      questPointData.timelineCheck.available,
    ]
  );

  return {setReference, isLoading: pointsRefreshing || referenceProccessing};
};

export const useAcceptSubQuestAndSetReference = ({questPointData, stagePoint}: StagePointData) => {
  const {setReference} = useSetPointReference({questPointData, stagePoint});
  const [acceptQuest] = api.endpoints.questAccept.useMutation();
  const acceptQuestSetRef = useCallback(async () => {
    if (questPointData.originId && stagePoint?._id) {
      const result = await acceptQuest({
        questId: questPointData.originId,
        questActionDto: {timelineId: questPointData.timelineId, stagePointId: stagePoint._id},
      });
      if ('data' in result) setReference(`${result.data.stage._id}`);
    }
  }, [questPointData.timelineId, questPointData.originId, setReference, acceptQuest, stagePoint?._id]);

  useEffect(() => {
    if (stagePoint && stagePoint.status === 'new' && questPointData.timelineCheck.available) acceptQuestSetRef();
  }, [stagePoint, questPointData.timelineCheck.available, acceptQuestSetRef]);
};
