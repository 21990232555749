import {useRef, useCallback, RefObject, useEffect} from 'react';

export const useCollapseScrollBack = (collapse?: boolean): [RefObject<HTMLElement>, () => void] => {
  const elementToScrollRef = useRef<HTMLElement>(null);
  const isVisible = useRef(false);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      isVisible.current = entry.isIntersecting;
    });
    if (elementToScrollRef.current) observer.observe(elementToScrollRef.current);
    return () => observer.disconnect();
  }, [elementToScrollRef]);

  const scrollCallback = useCallback(() => {
    if (!collapse && !isVisible.current)
      elementToScrollRef.current?.scrollIntoView({block: 'start', behavior: 'smooth'});
  }, [collapse]);

  return [elementToScrollRef, scrollCallback];
};
