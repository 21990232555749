import {FC} from 'react';

import {api} from 'api';
import {croppedImagePath} from 'utils';

import {StageIdProp} from '../../types';
import {WidgetsList} from '../WidgetsList';
import {Deadline} from '../../components/Deadline';
import {StageProgressBar, StageStatus} from '../../components';
import {TopButtonsControlButton} from '../StagePointsContainers';
import {useGetStagePointsLengthByContainerName} from '../../hooks';
import {alpha, Box, Stack, Typography, TypographyProps} from '@mui/material';

export type QuestHeaderProps = StageIdProp & {
  showProgress?: boolean;
  subTitle?: string;
  showStatus?: boolean;
  showDeadline?: boolean;
  gradient?: boolean;
  titleProps?: Pick<TypographyProps, 'align' | 'variant'>;
};

export const StageHeader: FC<QuestHeaderProps> = ({
  stageId,
  showProgress,
  subTitle,
  showStatus,
  // gradient,
  showDeadline,
  titleProps = {
    variant: 'h2',
    align: 'left',
  },
}) => {
  const {data} = api.endpoints.stageIndex.useQuery({stageId, partial: true});
  const buttonsExists = Boolean(
    useGetStagePointsLengthByContainerName({stageId, containerName: 'topButtonsContainer'})
  );
  const backgroundFileId = data?.stage.quest?.design?.backgroundFileId;
  const backgroundColor = data?.stage.quest?.design?.backgroundColor || '#000';

  // const {navigate} = useNavigation();
  // const tagsId = data?.stage.quest?.tagsSet?.map(item => item.tagId);
  // const onTagPress = () => navigate('contentMain', {tab: 'answered', tagsId});

  return (
    <Box
      position="relative"
      overflow="hidden"
      sx={{
        backgroundColor: data?.stage.quest?.design?.backgroundColor || undefined,
      }}
    >
      <Box style={{backgroundColor: 'rgba(0, 0, 0, 0.05)'}} zIndex={5} position="relative">
        <Box px={2} py={4} pb={7}>
          <Box flexDirection="row" width={1}>
            <Box alignSelf="center" justifyContent="center">
              <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
                <Stack>
                  <Typography {...titleProps} color="white" fontWeight="bold">
                    {data?.stage.quest?.design?.header || data?.stage.quest?.name}
                  </Typography>

                  {subTitle && (
                    <Typography variant="subtitle1" color="white" sx={{opacity: 0.7}}>
                      {subTitle}
                    </Typography>
                  )}

                  {showDeadline && (
                    <Deadline color="white" variant="subtitle1" sx={{opacity: 0.7}} timeline={data?.stage.timeline} />
                  )}
                </Stack>

                {/* {tagsId && tagsId.length > 0 && (
                      <TouchableOpacity onPress={onTagPress}>
                        <VideoIcon color="white" />
                      </TouchableOpacity>
                    )} */}
                {showStatus && data?.stage.status && <StageStatus status={data?.stage.status} />}
              </Box>

              {showProgress && (
                <Box mt={1.25}>
                  <StageProgressBar stageId={stageId} />
                </Box>
              )}
            </Box>
            {buttonsExists && <TopButtonsControlButton />}
          </Box>
          <WidgetsList stageId={stageId} widgetContainerName="stageHeader" boxProps={{mt: 2}} />
        </Box>
      </Box>

      <Box
        top="-10%"
        left="-10%"
        width="120%"
        height="120%"
        position="absolute"
        sx={{
          filter: 'blur(15px)',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          // backgroundColor: backgroundFileId ? undefined : 'rgba(0, 0, 0, 0.5)',
          backgroundImage: backgroundFileId
            ? `linear-gradient(0deg, ${alpha(backgroundColor, 0.3)}, ${alpha(
                backgroundColor,
                0.55
              )}), url(${croppedImagePath(backgroundFileId, {ratio: '16:9', maxWidth: 700})})`
            : `linear-gradient(0deg, ${alpha('rgb(0, 0, 0)', 0.3)}, ${alpha('rgb(0, 0, 0)', 0.55)})`,
        }}
      />
    </Box>
  );
};
