import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import {filePath} from 'utils';
import {QuestPointViewProps} from 'modules/oldQuests2/types';
import {useConfirmDialog} from 'components/Dialog';

export const QuestTopButtonPoint = ({name, options, onClick}: QuestPointViewProps) => {
  const {design, buttons} = options;
  const isNeedConfirm = !!buttons?.confirmText;
  const [ConfirmDialog, openConfirm] = useConfirmDialog({
    onConfirm: onClick || (() => null),
    title: 'Подтвердите действие',
    text: buttons?.confirmText || '',
    btnType: buttons?.color === 'error' ? 'error' : 'primary',
  });

  if (!buttons) return null;
  return (
    <>
      <ConfirmDialog />
      <Box mx={2} borderRadius={2} boxShadow={theme => theme.customShadows.default}>
        <Stack p={1.5} spacing={2} direction="row" alignItems="center">
          {design?.fileId && (
            <Box width={48} height={48} flexShrink={0} component="img" src={filePath(design.fileId)} />
          )}
          <Box flexGrow={1}>
            <Typography variant="h3">{design?.title || name}</Typography>
            {design?.description && (
              <Typography variant="body2" color="grey.400">
                {design.description}
              </Typography>
            )}
          </Box>
        </Stack>
        <Button variant="text" color={buttons.color as any} onClick={isNeedConfirm ? openConfirm : onClick} fullWidth>
          {buttons.title}
        </Button>
      </Box>
    </>
  );
};
