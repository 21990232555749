import {Box} from '@mui/material';
import React, {FC} from 'react';
import {WidgetVisual} from 'modules/widgets';

import {StageIdProp, StagePointIdProp, WidgetsListProps} from '../../types';
import {useGetStagePointByIdWithSelector, useWidgetsByContainerName} from '../../hooks';

const WidgetContainer: FC<StagePointIdProp & StageIdProp> = props => {
  const {visualsId, userId} = useGetStagePointByIdWithSelector(props, point => ({
    ...point.questPointData.questPoint.options.widgets,
    userId: point.stagePoint?.userId,
  }));

  if (!visualsId) return null;
  return (
    <>
      {visualsId.map(visualId => (
        <WidgetVisual
          visualId={visualId}
          stagesId={[props.stageId]}
          stagesPointsId={[props.stagePointId]}
          usersId={userId ? [userId] : []}
        />
      ))}
    </>
  );
};

export const WidgetsList: FC<WidgetsListProps> = ({stageId, widgetContainerName, boxProps = {}}) => {
  const points = useWidgetsByContainerName({stageId, widgetContainerName});

  return (
    <Box {...boxProps}>
      {points.map(item => (
        <React.Fragment key={item.stagePointId}>
          <WidgetContainer stagePointId={item.stagePointId} stageId={stageId} />
        </React.Fragment>
      ))}
    </Box>
  );
};
