import {Form, Formik} from 'formik';

import {FormBlock} from '../FormBlock';
import {FormFactoryProps} from '../types';

export default function FormFactory<Values extends object>({
  fields,
  children,
  container: Container,
  initialValues,
  ...props
}: FormFactoryProps<Values>) {
  return (
    <Formik {...props} initialValues={initialValues} enableReinitialize>
      {formikProps => {
        const {handleSubmit, handleReset} = formikProps;
        return (
          <Form
            onSubmit={e => {
              e.preventDefault();
              handleSubmit();
            }}
            onReset={handleReset}
            autoComplete="off"
            // autoCapitalize="off"
          >
            {Container ? Container(<FormBlock fields={fields} />) : <FormBlock fields={fields} />}
            {children && children(formikProps)}
          </Form>
        );
      }}
    </Formik>
  );
}
