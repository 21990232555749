import {fill} from 'lodash';
import {Skeleton, Stack} from '@mui/material';
import {TasksListSkeleton} from '../components';

export const TasksListPageSkeleton = () => (
  <Stack>
    <Stack mb={0.5} px={2} pb={1} pt={2} spacing={3} bgcolor="white">
      <Skeleton variant="text" animation="wave" width={120} height={28} sx={{transform: 'none'}} />
      <Skeleton variant="rectangular" animation="wave" width={82} height={32} sx={{borderRadius: 1.5}} />
    </Stack>
    <Stack spacing={1}>
      {fill(Array(3), '').map((_, i) => (
        <TasksListSkeleton key={i} compact={true} />
      ))}
    </Stack>
  </Stack>
);
