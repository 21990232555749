import {useCallback, useMemo} from 'react';

import Box from '@mui/system/Box';
import {atom, useAtom} from 'jotai';
import LoadingButton from '@mui/lab/LoadingButton';

import {api} from 'api';
import {Comments} from 'api/generated/users-api';
import {ReactComponent as EmptyComments} from 'svg/emptyComments.svg';

import {SocialStateProvider} from 'modules/social';
import {InfiniteList} from 'components/InfiniteLoader';

import CommentInput from '../CommentInput';
import {FullCommentEntry} from '../FullCommentEntry';
import {CommentsListProps, CommentsBranchProps} from './types';
import {CommentsListSkeleton} from 'components/Skeleton';

const CommentBranch = ({comment: initialData, inputStateAtom, ...params}: CommentsBranchProps) => {
  const [inputBranchId, setBranchId] = useAtom(inputStateAtom);
  const {updatedData} = api.endpoints.commentIndex.useQueryState(
    {commentId: `${initialData._id}`},
    {selectFromResult: ({data}) => ({updatedData: data?.comment})}
  );
  const comment = updatedData || initialData;
  const stateScope = `Comments.${comment._id}`;
  const isBranch = !!comment.branchCommentsCount;
  const showBranch = isBranch || inputBranchId === comment._id;

  return (
    <SocialStateProvider scope={stateScope} score={comment.score} reactions={comment.reactions}>
      <FullCommentEntry comment={comment} onReply={() => setBranchId(comment._id)} />
      {showBranch && (
        <Box>
          <Box
            sx={{
              my: 1,
              ml: 3,
              pl: 2,
              boxShadow: `inset 1px 0px 0px #D9DEE8`,
            }}
          >
            {isBranch && <CommentsInfiniteList {...params} branchCommentId={comment._id} />}
            <Box mt={1}>
              <CommentInput
                scope={stateScope}
                replyCommentId={comment._id}
                referenceId={params.referenceId}
                restrictScore={!comment.scoreInfo}
                referenceAuthorId={comment.userId}
                referenceName={params.referenceName}
              />
            </Box>
          </Box>
        </Box>
      )}
    </SocialStateProvider>
  );
};

export const CommentsInfiniteList = (params: CommentsListProps) => {
  const inputStateAtom = useMemo(() => atom<string | undefined>(undefined), []);
  const [fetch, result] = api.endpoints.commentsIndex.useLazyQuery();
  const getItems = useCallback(
    ({offset, limit}: {offset: number; limit: number}) =>
      fetch({
        limit,
        offset,
        sort: params.sort,
        referenceId: params.referenceId,
        referenceName: params.referenceName,
        branchCommentId: params.branchCommentId,
      }),
    [params.referenceId, params.referenceName, params.branchCommentId, params.sort, fetch]
  );

  return (
    <InfiniteList<Comments>
      limit={10}
      result={result}
      LoadingComponent={fetchItemsCount => <CommentsListSkeleton limit={fetchItemsCount} />}
      fetchFn={getItems}
      fetchOnMount
      disableWaypoint
      Footer={({total, currentCount, limit, loadMore, isFetching, itemsLeft}) => {
        if (!itemsLeft) return null;
        return (
          <Box sx={{display: 'flex', mt: 2, justifyContent: 'center'}}>
            <LoadingButton
              sx={{mb: 2}}
              size="small"
              color="secondary"
              variant="contained"
              loading={isFetching}
              disabled={isFetching}
              onClick={loadMore}
            >
              Показать еще {total - currentCount > limit ? limit : total - currentCount}
            </LoadingButton>
          </Box>
        );
      }}
      emptyListProps={{
        pic: <EmptyComments />,
        text: 'Комментариев к этой записи еще нет',
      }}
    >
      {comment => (
        <>
          {!params.branchCommentId && <CommentBranch {...params} comment={comment} inputStateAtom={inputStateAtom} />}
          {params.branchCommentId && <FullCommentEntry comment={comment} />}
        </>
      )}
    </InfiniteList>
  );
};
