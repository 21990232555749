import Stack from '@mui/material/Stack';

import {useGetUnread} from 'utils/hooks';
import {useMyProfileInfo} from 'modules/auth';
import {MyProfileImage} from 'modules/profile/components';

import {BottomNavigationItem} from './BottomNavigationItem';
import BottomNavigationConfig from './BottomNavigationConfig';

export const BottomNavigation = () => {
  const {link} = useMyProfileInfo();
  const unread = useGetUnread();

  return (
    <Stack
      left={0}
      bottom={0}
      width="100%"
      zIndex={1001}
      position="fixed"
      direction="row"
      justifyContent="space-between"
      boxShadow={theme => theme.customShadows.inset}
      sx={{
        backgroundColor: 'white',
      }}
    >
      {BottomNavigationConfig(unread).map(item => (
        <BottomNavigationItem {...item} key={item.to} />
      ))}
      {link && <BottomNavigationItem to={link} Content={<MyProfileImage noLink size="small" />} />}
    </Stack>
  );
};

export default BottomNavigation;
