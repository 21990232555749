import {Outlet} from 'react-router';
import {PropsWithChildren, useCallback} from 'react';
import {useAtomCallback, useAtomValue} from 'jotai/utils';

import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import CardHeader from '@mui/material/CardHeader';

import {GoalList} from 'modules/goals';
import {useDialog} from 'components/Dialog';
import {useGetCurrentUser} from 'modules/auth/hooks';

import {goalReportFormAtom} from './atoms';
import {GoalReportForm} from './GoalReportForm';

export const GoalReportFormProvider = ({children}: PropsWithChildren<{}>) => {
  const [Dialog] = useDialog();
  const user = useGetCurrentUser();
  const {goalId, reportId, open} = useAtomValue(goalReportFormAtom);

  const onClose = useAtomCallback(
    useCallback((get, set) => set(goalReportFormAtom, {goalId: null, reportId: null, open: false}), [])
  );

  const setGoalId = useAtomCallback(
    useCallback((get, set, goalId?: string) => {
      if (goalId) set(goalReportFormAtom, {goalId, reportId: null, open: true});
    }, [])
  );

  if (!user) return null;

  return (
    <>
      <Dialog open={open} onClose={onClose} title="Отчет по цели">
        {goalId && <GoalReportForm goalId={goalId} reportId={reportId || undefined} close={onClose} />}
        {!goalId && (
          <Stack spacing={0.5}>
            <Card>
              <CardHeader title="Выберите цель для отчета" />
            </Card>
            <Card>
              <GoalList
                display="list"
                disableStatusSelect
                params={{userId: `${user._id}`, status: 'new'}}
                onGoalClick={goal => setGoalId(goal._id)}
              />
            </Card>
          </Stack>
        )}
      </Dialog>
      {children || <Outlet />}
    </>
  );
};

export default GoalReportFormProvider;
