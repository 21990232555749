import {TOKEN_STORAGE_NAME} from 'modules/auth/constants';

export const prepareHeaders = async (headers: Headers) => {
  const token = await localStorage.getItem(TOKEN_STORAGE_NAME);
  if (token) headers.set('Authorization', `Bearer ${token}`);
  headers.set('X-App-Build', `${process.env.REACT_APP_BUILD}`);
  headers.set('X-App-Mode', `${process.env.NODE_ENV}`);
  return headers;
};

export default prepareHeaders;
