import {FC, memo} from 'react';

import {api} from 'api';
import {Stack} from '@mui/material';

import {WidgetVisual} from '../WidgetVisual';
import {WidgetVisualsContainerProps} from '../../types';
import {widgetContainers} from '../../components/WidgetContainers';

export const WidgetVisualsContainer: FC<WidgetVisualsContainerProps> = memo(
  ({widgetContainerName, containerName, horizontal = false, boxProps, usersId, injectedViews = [], ...params}) => {
    const {data: visuals} = api.endpoints.widgetsVisualsIndex.useQuery({containerName, ...params});
    const Container = widgetContainers[widgetContainerName];

    if (!visuals) return null;
    return (
      <Stack spacing={0} direction={horizontal ? 'row' : 'column'}>
        {visuals?.map(item => (
          <Container key={item._id} boxProps={boxProps}>
            <WidgetVisual visualId={item._id as string} usersId={usersId} {...params} />
          </Container>
        ))}
        {injectedViews &&
          injectedViews.map((item, index) => (
            <Container key={index} boxProps={boxProps}>
              {item}
            </Container>
          ))}
      </Stack>
    );
  }
);
