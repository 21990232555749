import {useCallback, useMemo} from 'react';

import Box from '@mui/system/Box';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';

import {Goals} from 'api/generated/users-api';

import {GoalList} from 'modules/goals';
import {useMyId} from 'modules/auth';
import {useConfirmDialog, useDialog} from 'components/Dialog';

import {GoalListProps} from '../GoalList/types';
import {GoalForm} from '../GoalForm';
import {atom, useAtom} from 'jotai';
import {QuestPointProps} from 'modules/quests/types';
import {useExternalSetPointReference} from 'modules/quests/hooks/setReference.hook';
import {PlainFnProp} from 'utils';
import {useAtomCallback} from 'jotai/utils';

type GoalSelectDialogProps = {
  params?: GoalListProps['params'];
  onSelect?: (goal: Goals) => void;
  disableSelectStatus?: boolean;
};

export const GoalSelect = ({
  close,
  onSelect,
  params: paramsProp,
  disableSelectStatus = false,
}: GoalSelectDialogProps & PlainFnProp<'close'>) => {
  const myId = useMyId();
  const handleSelect = useCallback(
    async (goal: Goals) => {
      if (onSelect) await onSelect(goal);
      close();
    },
    [close, onSelect]
  );
  const params: GoalListProps['params'] = useMemo(
    () => (disableSelectStatus ? {...paramsProp, userId: myId, status: 'new'} : {...paramsProp, userId: myId}),
    [disableSelectStatus, paramsProp, myId]
  );

  return (
    <>
      <Stack spacing={0.5}>
        <Card>
          <CardContent>
            <Box sx={{backgroundColor: 'warning.lighter', p: 1, borderRadius: 2}}>
              <Typography variant="body2" sx={{color: 'warning.main'}}>
                Выбирайте цель осмысленно, заменить ее будет нельзя
              </Typography>
            </Box>
          </CardContent>
        </Card>
        <Card>
          <GoalList
            display="list"
            disableSort
            disableViewSelect
            onGoalClick={handleSelect}
            disableStatusSelect={disableSelectStatus}
            params={params}
          />
        </Card>
      </Stack>
    </>
  );
};

type GoalDialogModes = 'select' | 'create';
type GoalSelectorStateProps = {
  mode: GoalDialogModes;
  open: boolean;
  stagePoint?: QuestPointProps;
} & Omit<GoalSelectDialogProps, 'onSelect'>;

const defaultGoalSelectorState: GoalSelectorStateProps = {mode: 'select', open: false};

export const goalSelectorStateAtom = atom<GoalSelectorStateProps>(defaultGoalSelectorState);

export const useOpenGoalSelector = () =>
  useAtomCallback(
    useCallback(
      (get, set, update: Omit<GoalSelectorStateProps, 'open' | 'mode'>) =>
        set(goalSelectorStateAtom, {...update, open: true, mode: 'select'}),
      []
    )
  );

export const GoalSelector = () => {
  const [{open, stagePoint, disableSelectStatus, params, mode}, setState] = useAtom(goalSelectorStateAtom);
  const toggleMode = () => setState(state => ({...state, mode: state.mode === 'create' ? 'select' : 'create'}));
  const close = () => setState(defaultGoalSelectorState);

  const [Dialog] = useDialog();
  const {setReference} = useExternalSetPointReference(stagePoint);

  const [ConfirmDialog, setGoal] = useConfirmDialog<Goals>({
    btnTitle: 'Да',
    badge: 'alert',
    align: 'center',
    btnType: 'primary',
    cancelBtnTitle: 'Отмена',
    text: 'Заменить ее будет нельзя',
    onConfirm: goal => {
      if (goal && setReference) setReference(`${goal._id}`);
    },
    title: goal => `Вы уверены что хотите выбрать цель «${goal?.name || 'цель'}»?`,
  });
  const onSelect = setReference ? setGoal : undefined;

  return (
    <>
      <ConfirmDialog />
      <Dialog
        open={open}
        title={mode === 'create' ? 'Поставьте цель' : 'Выберите цель'}
        action={
          <Button variant="contained" color="secondary" fullWidth onClick={toggleMode}>
            {mode === 'create' ? 'Выбрать цель из списка' : 'Поставить цель'}
          </Button>
        }
      >
        {mode === 'select' && (
          <GoalSelect onSelect={onSelect} disableSelectStatus={disableSelectStatus} params={params} close={close} />
        )}
        {mode === 'create' && <GoalForm close={close} goalTypeId={params?.goalTypeId} callback={onSelect} />}
      </Dialog>
    </>
  );
};
