import {Theme} from '@mui/material/styles';
import {ERROR, GREY, PRIMARY, SUCCESS} from '../palette';

// ----------------------------------------------------------------------

export default function Button(theme: Theme) {
  return {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
          boxShadow: 'none',
          padding: '12px',
          letterSpacing: '0.1px',
          '&:hover': {
            boxShadow: 'none',
          },
        },
        sizeMedium: {
          height: 44,
          minWidth: 44,
        },
        sizeSmall: {
          height: 32,
          minWidth: 32,
          padding: theme.spacing(1, 1.25),
        },
        // contained
        containedInherit: {
          color: theme.palette.grey[200],
          backgroundColor: GREY[10],
          // boxShadow: theme.customShadows.z8,
          '&:hover': {
            color: theme.palette.grey[300],
          },
          '&.Mui-disabled': {
            color: theme.palette.grey[100],
          },
        },
        containedPrimary: {
          borderRadius: 8,
          '&:hover': {
            boxShadow: 'none',
            backgroundColor: PRIMARY[600],
          },
          '&.Mui-disabled': {
            color: '#fff',
            backgroundColor: PRIMARY[50],
          },
        },
        containedSecondary: {
          borderRadius: 8,
          // boxShadow: theme.customShadows.secondary,
          color: PRIMARY[500],
          backgroundColor: PRIMARY[10],
          '&:hover': {
            color: PRIMARY[500],
            backgroundColor: PRIMARY[25],
          },
          '&.Mui-disabled': {
            color: PRIMARY[50],
            backgroundColor: PRIMARY[10],
          },
        },
        containedInfo: {
          // boxShadow: theme.customShadows.info,
        },
        containedSuccess: {
          // boxShadow: theme.customShadows.success,
        },
        containedWarning: {
          // boxShadow: theme.customShadows.warning,
        },
        containedError: {
          color: ERROR.dark,
          backgroundColor: ERROR.lighter,
          '&:hover': {
            backgroundColor: ERROR.light,
          },
          // boxShadow: theme.customShadows.error,
        },
        // outlined
        outlinedInherit: {
          // border: `1px solid ${theme.palette.grey[500_32]}`,
          '&:hover': {
            backgroundColor: theme.palette.action.hover,
          },
        },
        outlinedSecondary: {
          height: 16,
          color: GREY[200],
          borderColor: GREY[25],
          fontSize: 11,
          lineHeight: '12px',
        },
        textInherit: {
          '&:hover': {
            backgroundColor: theme.palette.action.hover,
          },
        },
        textSuccess: {
          color: SUCCESS.main,
        },
      },
    },
    MuiButtonGroup: {
      styleOverrides: {
        sizeSmall: {
          height: 16,
        },
      },
    },
  };
}
