import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';

import {useOpenLightbox} from 'components/Dialog';
import {LazyImage} from 'components/ImageGallery';
import {ReactComponent as CloseIcon} from 'svg/icons/closeIcon.svg';

export const PhotoAnswer = ({fileId, onRemove}: {fileId: string; onRemove?: () => void}) => {
  const openLightbox = useOpenLightbox();
  return (
    <Box position="relative">
      <Box onClick={() => openLightbox(fileId)} width={120} sx={{cursor: 'pointer'}}>
        <LazyImage fileId={fileId} ratio="4:3" width={120} alt="" borderRadius={2} />
      </Box>
      {onRemove && (
        <Box position="absolute" top={-10} right={-4}>
          <IconButton
            onClick={e => {
              e.stopPropagation();
              onRemove();
            }}
            sx={{
              p: 0,
              width: 16,
              height: 16,
              bgcolor: 'primary.500',
              '&:hover': {bgcolor: 'primary.400'},
            }}
          >
            <Box component={CloseIcon} color="white" width={16} height={16} />
          </IconButton>
        </Box>
      )}
    </Box>
  );
};
