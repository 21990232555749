import {DropzoneProps, useDropzone} from 'react-dropzone';

import {SxProps} from '@mui/system';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import {Files} from 'api/generated/files-api';

import {ReactComponent as RetryIcon} from 'svg/icons/retryIcon.svg';
import {ReactComponent as AttachIcon} from 'svg/icons/fileAttachIcon.svg';
import {ReactComponent as CircularIcon} from 'svg/icons/circularProgressIcon.svg';
import {FileAnswer, PhotoAnswer} from 'modules/tasks/containers/Answers';

const LoadingMod = () => {
  return (
    <Stack
      borderRadius={2}
      width="100%"
      height="100%"
      alignItems="center"
      justifyContent="center"
      sx={{
        opacity: '0.8',
        position: 'absolute',
        bgcolor: 'primary.500',
      }}
    >
      <CircularIcon />
    </Stack>
  );
};

const RetryMod = () => (
  <Stack alignItems="center" justifyContent="center" position="absolute" width="100%" height="100%">
    <RetryIcon />
  </Stack>
);

type FileDropzoneProps = {
  accept?: DropzoneProps['accept'];
  onDrop: DropzoneProps['onDrop'];
  onRemove: () => void;
  file?: Files | null;
  meta?: {isLoading: boolean; isError: boolean};
  btnText?: string;
  sx?: SxProps;
};

export const FileDropzone = ({onDrop, onRemove, file, meta, accept, btnText, sx}: FileDropzoneProps) => {
  const {getRootProps, getInputProps} = useDropzone({multiple: false, accept, onDrop, disabled: !!file});
  const {isLoading = false, isError = false} = meta || {};
  const isImage = file?.type.includes('image');
  const showButton = !(file || isLoading || isError);

  return (
    <Stack {...getRootProps()} sx={{'&:focus, &:active': {outline: 'none'}}}>
      <input {...getInputProps()} style={{display: 'none'}} />
      {showButton && (
        <div>
          <Button size="medium" variant="contained" color="secondary">
            {btnText || <AttachIcon />}
          </Button>
        </div>
      )}
      {!showButton && (
        <Stack
          height="100%"
          alignItems="center"
          position="relative"
          justifyContent="center"
          borderRadius={2}
          sx={{
            cursor: 'pointer',
            bgcolor: 'secondary.main',
            width: !file && btnText ? 'fit-content' : isImage ? 120 : 64,
            height: !file && btnText ? 'fit-content' : isImage ? 90 : 64,
            transition: 'all .25s',
            '&:hover': {
              bgcolor: 'primary.25',
            },
            ...sx,
          }}
        >
          {isLoading && <LoadingMod />}
          {isError && <RetryMod />}
          {file &&
            file._id &&
            (isImage ? (
              <PhotoAnswer fileId={file._id} onRemove={onRemove} />
            ) : (
              <FileAnswer fileId={file._id} onRemove={onRemove} />
            ))}
        </Stack>
      )}
    </Stack>
  );
};
// import {MouseEventHandler} from 'react';
// import {ReactComponent as CloseIcon} from 'svg/icons/closeIcon.svg';
// import Box from '@mui/material/Box';
// import IconButton from '@mui/material/IconButton';
// import Typography from '@mui/material/Typography';
// import {croppedImagePath} from 'utils';
// const CloseCross = ({removeFile}: {removeFile: MouseEventHandler<HTMLButtonElement>}) => (
//   <IconButton
//     onClick={removeFile}
//     sx={{
//       position: 'absolute',
//       p: 0,
//       top: -4,
//       right: -4,
//       width: 16,
//       height: 16,
//       borderRadius: '50%',
//       bgcolor: 'primary.500',
//       '&:hover': {
//         bgcolor: 'primary.400',
//       },
//     }}
//   >
//     <CloseIcon color="white" width="16" height="16" />
//   </IconButton>
// );
// const ImageMod = ({file, removeFile}: {file: Files; removeFile: MouseEventHandler<HTMLButtonElement>}) => {
//   const backgroundImage = `url(${croppedImagePath(file?._id as string, {ratio: '4:3', maxWidth: 120})})`;
//   return (
//     <Stack
//       width="100%"
//       height="100%"
//       borderRadius={2}
//       sx={{
//         backgroundImage,
//         backgroundSize: 'cover',
//         backgroundRepeat: 'no-repeat',
//       }}
//     >
//       <CloseCross removeFile={removeFile} />
//     </Stack>
//   );
// };
// const FileMod = ({file, removeFile}: {file: Files; removeFile: MouseEventHandler<HTMLButtonElement>}) => {
//   const fileName = file?.name.substring(0, file?.name.lastIndexOf('.'));
//   const fileType = file?.name.substring(file?.name.lastIndexOf('.'));
//   return (
//     <>
//       <CloseCross removeFile={removeFile} />
//       <Stack height="100%" width="100%" p={0.75} justifyContent="space-between">
//         <Typography component="div" variant="subtitle1" color="grey.200">
//           {fileType}
//         </Typography>
//         <Box>
//           <Typography
//             mb={0.25}
//             height={10}
//             fontWeight={500}
//             color="grey.700"
//             overflow="hidden"
//             whiteSpace="nowrap"
//             variant="subtitle2"
//             textOverflow="ellipsis"
//           >
//             {fileName}
//           </Typography>

//           <Typography
//             height={10}
//             color="grey.200"
//             overflow="hidden"
//             whiteSpace="nowrap"
//             variant="subtitle2"
//             textOverflow="ellipsis"
//           >
//             {Math.round(file.size / 1000)} KB
//           </Typography>
//         </Box>
//       </Stack>
//     </>
//   );
// };
