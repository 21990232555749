import {filePath} from 'utils';
import {atom, useAtom} from 'jotai';
import {useUpdateAtom} from 'jotai/utils';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Modal from '@mui/material/Modal';
import Close from '@mui/icons-material/Close';
import CardMedia from '@mui/material/CardMedia';
import IconButton from '@mui/material/IconButton';
import {alpha} from '@mui/material';

const lightboxAtom = atom<string | null>(null);

export const useOpenLightbox = () => {
  return useUpdateAtom(lightboxAtom, Symbol.for('lightbox'));
};

export const Lightbox = () => {
  const [fileId, update] = useAtom(lightboxAtom, Symbol.for('lightbox'));
  const close = () => update(null);

  return (
    <Modal
      onClose={close}
      open={Boolean(fileId)}
      BackdropProps={{sx: {backgroundColor: alpha('#000', 0.8)}}}
      sx={{
        m: 'auto',
        maxWidth: '80vh',
        maxHeight: '80vh',
        width: 'fit-content',
        height: 'fit-content',
        zIndex: 4200,
      }}
    >
      <>
        <Box position="fixed" sx={{right: 25, top: 25}}>
          <IconButton onClick={close}>
            <Close sx={{color: 'grey.25'}} />
          </IconButton>
        </Box>
        <Card sx={{outline: 'none'}}>
          <CardMedia component="img" image={filePath(fileId!)} alt="avatar" />
        </Card>
      </>
    </Modal>
  );
};
