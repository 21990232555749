import {lazy, Suspense} from 'react';
import {RouteObject} from 'react-router';
import IsExistsContainer from 'components/IsExistsContainer';
import {QuestPageListSkeleton, QuestPageSkeleton} from 'components/Skeleton';
import {useGetRouterStage} from './hooks';

const StagePage = lazy(() => import('./pages/StagePage'));
// const QuestPage = lazy(() => import('./pages/QuestPage'));
const QuestListPage = lazy(() => import('./pages/QuestListPage'));
const StageCompletedPage = lazy(() => import('./pages/StageCompletedPage'));
// const QuestFinishPage = lazy(() => import('./pages/QuestFinish'));
// const DevQuestsListPage = lazy(() => import('./pages/QuestsList/DevQuestsList'));

export const router: RouteObject = {
  path: 'modules',
  children: [
    {
      index: true,
      element: (
        <Suspense fallback={<QuestPageListSkeleton />}>
          <QuestListPage />
        </Suspense>
      ),
    },
    // process.env.NODE_ENV === 'development' && {
    //   path: 'dev',
    //   element: <DevQuestsListPage />,
    // },
    {
      path: ':stageId',
      element: <IsExistsContainer useFetch={useGetRouterStage} LoadingComponent={<QuestPageSkeleton />} />,
      children: [
        {
          index: true,
          element: (
            <Suspense fallback={<QuestPageSkeleton />}>
              <StagePage />
            </Suspense>
          ),
        },
        {
          path: 'done',
          element: (
            <Suspense fallback={<QuestPageSkeleton />}>
              <StageCompletedPage />
            </Suspense>
          ),
        },
      ],
    },
    // {
    //   path: 'view/:questId',
    //   element: <IsExistsContainer useFetch={useGetQuest} LoadingComponent={<QuestPageSkeleton />} />,
    //   children: [
    //     {
    //       index: true,
    //       element: (
    //         <Suspense fallback={<QuestPageSkeleton />}>
    //           <StagePage />
    //         </Suspense>
    //       ),
    //     },
    //   ],
    // },
  ].filter(Boolean) as RouteObject['children'],
};

export default router;
