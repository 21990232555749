import {Box, Skeleton, Stack} from '@mui/material';
import {ContentContentSkeleton} from '../../Content/components';

export const TaskPageSkeleton = () => (
  <Stack spacing={0.5}>
    <Stack bgcolor="white">
      <Stack direction="row" px={2} py={1} spacing={2}>
        <Skeleton variant="text" animation="wave" width={95} height={16} sx={{transform: 'none'}} />
        <Skeleton variant="text" animation="wave" width={55} height={16} sx={{transform: 'none'}} />
        <Skeleton variant="text" animation="wave" width={55} height={16} sx={{transform: 'none'}} />
        <Skeleton variant="text" animation="wave" width={130} height={16} sx={{transform: 'none'}} />
      </Stack>
      <Skeleton variant="rectangular" animation="wave" width={600} height={337} />
      <Stack p={2} spacing={2}>
        <Stack spacing={1}>
          <Skeleton variant="text" animation="wave" width="25%" height={10} sx={{transform: 'none'}} />
          <Skeleton variant="text" animation="wave" width="55%" height={24} sx={{transform: 'none'}} />
        </Stack>
        <Skeleton variant="text" animation="wave" width="75%" height={16} sx={{transform: 'none'}} />
        <Stack direction="row" justifyContent="space-between">
          <Stack direction="row" spacing={0.5}>
            <Skeleton variant="rectangular" animation="wave" width={42} height={24} sx={{borderRadius: 12}} />
            <Skeleton variant="rectangular" animation="wave" width={62} height={24} sx={{borderRadius: 12}} />
          </Stack>
          <Skeleton variant="rectangular" animation="wave" width={120} height={24} sx={{borderRadius: 12}} />
        </Stack>
      </Stack>
    </Stack>
    <Box p={2} bgcolor="white">
      <ContentContentSkeleton />
    </Box>
  </Stack>
);
