import {Skeleton, Stack} from '@mui/material';
import {PublicationListSkeleton} from '../../Feed/components';

export const TaskDonePageSkeleton = () => (
  <Stack spacing={0.5}>
    <Stack bgcolor="white" spacing={2} alignItems="center" pb={6}>
      <Skeleton variant="rectangular" width={160} height={120} sx={{borderRadius: 2}} />
      <Skeleton variant="text" animation="wave" width={220} height={32} sx={{transform: 'none'}} />
      <Skeleton variant="text" animation="wave" width="50%" height={15} sx={{transform: 'none'}} />
      <Stack spacing={2.5}>
        <Skeleton variant="rectangular" width={150} height={44} sx={{borderRadius: 2}} />
        <Skeleton variant="text" animation="wave" width={150} height={15} sx={{transform: 'none'}} />
      </Stack>
    </Stack>
    <Stack spacing={2} px={2} py={1} bgcolor="white">
      <Skeleton variant="text" animation="wave" width={260} height={17} sx={{transform: 'none'}} />
      <Stack direction="row" spacing={2}>
        <Skeleton variant="text" animation="wave" width={90} height={16} sx={{transform: 'none'}} />
        <Skeleton variant="text" animation="wave" width={90} height={16} sx={{transform: 'none'}} />
      </Stack>
    </Stack>
    <Stack spacing={1} bgcolor="white">
      <PublicationListSkeleton />
    </Stack>
  </Stack>
);
