import {Card, CardContent, Stack, Paper} from '@mui/material';
import {GoalsTabs, SearchBlock, SearchTabs} from 'modules/search/components';
import {HeaderSetup, InfoBlock} from 'components/Layout';
import GoalItem from 'modules/search/components/GoalItem';

export const SearchGoalsPageMarkup = () => {
  return (
    <>
      <HeaderSetup title="" />
      <Stack spacing={0.5}>
        <Card>
          <CardContent>
            <SearchBlock />
          </CardContent>
          <SearchTabs defaultValue="goals" />
        </Card>

        <Card>
          <GoalsTabs />
        </Card>

        <Stack gap={0.25}>
          <Paper elevation={0} sx={{padding: '0 16px'}}>
            <GoalItem
              userName="ivanderbilov"
              goalName="Mercedes S Coupe 63 AMG"
              score={93.2}
              type="gold"
              goalInfo={
                <>
                  <InfoBlock size="small" label="Осталось" value={'1 год 6 месяцев'} />
                  <InfoBlock size="small" label="Цель" value={'3 года'} />
                  <InfoBlock size="small" label="Денежный экв." value={'200 000₽'} />
                </>
              }
              isMoneyGoal
            />
          </Paper>

          <Paper elevation={0} sx={{padding: '0 16px'}}>
            <GoalItem
              userName="ksenia.garaeva"
              goalName="Совершить восхождение на Аннапурну без кислорода"
              score={75.13}
              goalInfo={
                <>
                  <InfoBlock size="small" label="Осталось" value={'3 месяца'} />
                  <InfoBlock size="small" label="Цель" value={'2 года'} />
                  <InfoBlock size="small" label="Денежный экв." value={'200 000₽'} />
                </>
              }
            />
          </Paper>

          <Paper elevation={0} sx={{padding: '0 16px'}}>
            <GoalItem
              userName="pdll"
              goalName="Посетить водопад Деттифосс"
              score={75.13}
              type="bronze"
              goalInfo={
                <>
                  <InfoBlock size="small" label="Осталось" value={'4 месяца'} />
                  <InfoBlock size="small" label="Цель" value={'1 год'} />
                  <InfoBlock size="small" label="Денежный экв." value={'200 000₽'} />
                </>
              }
            />
          </Paper>

          <Paper elevation={0} sx={{padding: '0 16px'}}>
            <GoalItem
              userName="ivanklabukov"
              goalName="Научится летать на вертолете"
              score={81.2}
              goalInfo={
                <>
                  <InfoBlock size="small" label="Осталось" value={'4 месяца'} />
                  <InfoBlock size="small" label="Цель" value={'1 год'} />
                  <InfoBlock size="small" label="Денежный экв." value={'200 000₽'} />
                </>
              }
            />
          </Paper>

          <Paper elevation={0} sx={{padding: '0 16px'}}>
            <GoalItem
              userName="ivan_ovsi"
              goalName="Заработать на дом"
              score={76.19}
              goalInfo={
                <>
                  <InfoBlock size="small" label="Завершена за" value={'2 дня'} />
                  <InfoBlock size="small" label="Цель" value={'Месяц'} />
                  <InfoBlock size="small" label="Денежный экв." value={'200 000₽'} />
                </>
              }
              isMoneyGoal
            />
          </Paper>

          <Paper elevation={0} sx={{padding: '0 16px'}}>
            <GoalItem
              goalName="Ходить в спортзал"
              goalInfo={
                <>
                  <InfoBlock size="small" label="Осталось" value={'1 месяц'} />
                  <InfoBlock size="small" label="Цель" value={'4 месяца'} />
                  <InfoBlock size="small" label="Денежный экв." value={'200 000₽'} />
                </>
              }
            />
          </Paper>
        </Stack>
      </Stack>
    </>
  );
};

export default SearchGoalsPageMarkup;
