import {useState} from 'react';
import {useDispatch} from 'react-redux';
import {uniq} from 'lodash';

import {api} from 'api';
import {StagePointData} from 'api/generated/users-api';

export const useAdventureQuestListDataLoading = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const {data: adventureStages, isLoading} = api.endpoints.stagesIndex.useQuery({
    questType: 'adventure',
    populates: ['quest'],
  });

  const adventuresStagesPoints = (adventureStages?.data || []).map(adventureStage =>
    dispatch(api.endpoints.stagePointsIndex.initiate({stageId: `${adventureStage._id}`}))
  );
  Promise.all(adventuresStagesPoints).then(data => {
    const pointsShortcutData = uniq(
      data
        .filter((i: any) => i?.status === 'fulfilled')
        .map((i: any) => i?.data)
        .flat()
        .filter((i: StagePointData) => i?.questPointData?.questPoint?.type === 'shortcut' && i?.stagePoint?._id)
        .map((i: StagePointData) => i?.stagePoint?._id)
    ).map((pointId: any) => dispatch(api.endpoints.stagePointShortcutData.initiate({pointId: `${pointId}`})));

    Promise.all(pointsShortcutData).then(() => {
      if (!isLoading && adventureStages) setLoading(false);
    });
  });

  return isLoading || loading;
};
