import {createReducer} from '@reduxjs/toolkit';
import {UIFormsState} from './types';
import {openPostForm, closePostForm} from './actions';

const initialState: UIFormsState = {
  post: {
    open: false,
    entryId: undefined,
  },
};

export default createReducer(initialState, b =>
  b
    .addCase(openPostForm, (state, {payload}) => ({...state, post: {open: true, ...payload}}))
    .addCase(closePostForm, state => ({...state, post: initialState.post}))
);
