import {useAtom} from 'jotai';
import {useAtomValue} from 'jotai/utils';

import {useEffect, useRef} from 'react';
import {useDeepCompareEffect, useUnmount} from 'react-use';
import {useController, useFormContext} from 'react-hook-form';
import {
  getOptionsAtonm,
  getOptionsByTemplateIdAtom,
  patchAnswersAtom,
  scriptActionExactValue,
  scriptActionGroupedValue,
} from '../atoms';
import {AnswerProps} from '../types';

export const useSingleFieldProps = (params: AnswerProps, setDataOnChange = false) => {
  const name = `${params.scriptActionId}.${params.index}.${params.templateOptionId}`;
  const [defaultValue, setData] = useAtom(scriptActionExactValue(params));
  const {control} = useFormContext();
  const [option] = useAtomValue(getOptionsAtonm(params.templateOptionId));
  const patch = useAtomValue(patchAnswersAtom);
  const isInitRender = useRef<boolean>(true);

  useUnmount(() => {
    getOptionsAtonm.remove(params.templateOptionId);
    scriptActionExactValue.remove(params);
  });

  const {
    field: {ref, ...field},
  } = useController({name, control, defaultValue});

  useEffect(() => {
    if (setDataOnChange) setData(field.value);
  }, [field.value, setData, setDataOnChange]);

  useDeepCompareEffect(() => {
    if (isInitRender.current) isInitRender.current = false;
    else if (patch) patch({...params, value: defaultValue});
  }, [defaultValue, params, patch]);

  return {ref, field, option, setData, defaultValue, patch};
};

export const useMultiFieldProps = (scriptActionId: string, templateId: string, index: number) => {
  const name = `${scriptActionId}.${index}`;
  const options = useAtomValue(getOptionsByTemplateIdAtom(templateId));
  const [defaultValue, setData] = useAtom(scriptActionGroupedValue({scriptActionId, index}));

  const {control} = useFormContext();
  const {field} = useController({name, control, defaultValue: defaultValue.split(',')});

  useEffect(() => {
    setData(field.value);
  }, [field.value, setData]);

  useUnmount(() => {
    getOptionsByTemplateIdAtom.remove(templateId);
    scriptActionGroupedValue.remove({scriptActionId, index});
  });

  const value: string[] = defaultValue.split(',').filter(Boolean);

  return {value, options, field, setData};
};
