import * as yup from 'yup';
import {yupFilesRequired, yupPrivacy, yupRatio, yupStringRequired} from 'utils';
import {JobCompleteDto} from 'api/generated/users-api';

export const initialValues = {
  publication: {
    type: 'report',
    content: '',
    enabled: true,
    filesId: [],
    privacy: 'public',
    filesAspectRatio: '1:1',
  },
  scoreComment: {
    comment: '',
    score: undefined,
  },
  createPublication: false,
} as JobCompleteDto;

export const validationSchema = (publicationRequired: boolean) =>
  yup.object().shape({
    createPublication: yup.boolean().default(false),
    comment: yup.object().shape({
      comment: yup
        .string()
        .default('')
        .when('score', {is: (value: any) => !!value, then: yupStringRequired}),
      score: yup.number().nullable(),
    }),
    publication: yup.object().when('createPublication', {
      is: (value: any) => publicationRequired || !!value,
      then: yup.object().shape({
        content: yup.string(),
        filesId: yupFilesRequired,
        privacy: yupPrivacy,
        filesAspectRatio: yupRatio,
      }),
    }),
  });
