import {ReactNode} from 'react';
import Box from '@mui/material/Box';
import {useMatch} from 'react-router';

import {PRIMARY} from 'components/theme/palette';
import * as Styled from './styles';
import {NotificationIndication} from '../NotificationIndication';
import {SVGComponentProp} from 'utils';

export const BottomNavigationItem = ({
  to,
  Icon,
  Content,
  isUnread,
}: {
  to: string;
  Content?: ReactNode;
  isUnread?: boolean;
} & Partial<SVGComponentProp<'Icon'>>) => {
  const match = useMatch(to);
  return (
    <Styled.Conrainer to={to}>
      {Boolean(match) && <Styled.TopBorder />}
      {Content ||
        (Icon ? (
          <Box
            sx={{
              width: 32,
              height: 32,
              position: 'relative',
            }}
          >
            <Box
              component={Icon}
              sx={{
                width: 32,
                height: 32,
              }}
              color={PRIMARY[500]}
            />
            <NotificationIndication isSeen={!isUnread} sx={{position: 'absolute', top: 0, right: 0}} />
          </Box>
        ) : null)}
    </Styled.Conrainer>
  );
};

export default BottomNavigationItem;
