import Box from '@mui/system/Box';
import compose from 'lodash/fp/compose';
import {useAtomValue} from 'jotai/utils';

import {useIsMe} from 'modules/auth';
import {StagePointProps} from 'modules/oldQuests2/types';
import {PublicationFormDialog, PublicationWidget} from 'modules/feed/containers';
import {useDialog} from 'components/Dialog';
import {useDialogView} from 'components/Dialog/context';

import {QuestPointWithProgress} from './QuestPointWithProgress';
import {useSetPointReference} from './hook';
import {isParentStageCompleted, isSubQuestPointAtom} from '../Quest/atom';

const EmptyPublicationPoint: React.FC<StagePointProps> = ({stagePoint, questPoint, children}) => {
  const [Dialog, toggleFormState] = useDialog();
  const {setReference} = useSetPointReference({stagePoint, questPointData: questPoint});

  return (
    <>
      <Box>{children({name: questPoint.name || 'Публикация', onClick: () => toggleFormState()})}</Box>
      <Dialog>
        <PublicationFormDialog
          onClose={toggleFormState}
          entryId={stagePoint?.referenceId}
          onPostCreated={compose(toggleFormState, setReference)}
        />
      </Dialog>
    </>
  );
};

const FilledPublicationPoint: React.FC<StagePointProps> = ({stagePoint, questPoint, children}) => {
  const openPost = useDialogView('feed', stagePoint?.referenceId);
  const isSubPoint = useAtomValue(isSubQuestPointAtom);

  return (
    <Box
      onClick={openPost ? () => openPost : undefined}
      sx={{
        cursor: 'pointer',
        transition: 'background-color .25s',
        '&:active': {color: 'grey.700'},
        '&:hover': {backgroundColor: 'secondary.main'},
      }}
    >
      {children({
        name: questPoint.name || 'Пост',
        Content: stagePoint?.referenceId ? (
          <PublicationWidget hideImage={isSubPoint} showBox={!isSubPoint} postId={stagePoint.referenceId} />
        ) : undefined,
      })}
    </Box>
  );
};

export const PublicationQuestPoint = ({children, stagePoint, questPoint}: StagePointProps) => {
  const isMy = useIsMe(stagePoint?.userId);
  const isCompleted = ['completed', 'failed', 'checking'].includes(stagePoint?.status || '');
  const locked = !questPoint.timelineCheck.available && !isCompleted;
  const parentStageCompleted = useAtomValue(isParentStageCompleted);

  const Content = children({name: questPoint.name || 'Пост'});
  if ('progress' in questPoint) {
    return <QuestPointWithProgress questPoint={questPoint} children={children} />;
  }
  if (locked || !stagePoint) return Content;
  if (!stagePoint.referenceId) {
    if (parentStageCompleted || !isMy || stagePoint.status !== 'new') return Content;
    return <EmptyPublicationPoint questPoint={questPoint} stagePoint={stagePoint} children={children} />;
  }
  if (stagePoint.referenceId)
    return <FilledPublicationPoint questPoint={questPoint} stagePoint={stagePoint} children={children} />;
  return Content;
};
