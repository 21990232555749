import Box from '@mui/system/Box';
import {Link} from 'react-router-dom';
import Typography from '@mui/material/Typography';

import {Users} from 'api/generated/users-api';
import {ProfileImage} from 'modules/profile/components';
import {getUserLink} from 'modules/profile/utils';

export const NotificationAuthoredMessage = ({author, message}: {author: Users; message: string}) => {
  if (!author) return null;
  return (
    <Box sx={{display: 'inline', position: 'relative'}} component="span">
      <Box sx={{display: 'inline-flex', pl: 2, verticalAlign: 'text-top'}} onClick={e => e.stopPropagation()}>
        <Box sx={{position: 'absolute', left: 0, top: 1}}>
          <ProfileImage user={author} size="xsmall" noLink inline />
        </Box>
        <Typography
          fontWeight={500}
          variant="body2"
          component={Link}
          to={getUserLink(author)}
          sx={{color: 'grey.700', ml: 0.5, lineHeight: '18px'}}
        >
          {author.userLogin?.value || author.name}
        </Typography>
      </Box>
      <Typography sx={{color: 'grey.200', verticalAlign: 'top', lineHeight: '18px'}} variant="body2" component="span">
        &nbsp;{message}
      </Typography>
    </Box>
  );
};
