import {api} from 'api';
import {lazy, Suspense} from 'react';
import {Navigate, RouteObject, useParams} from 'react-router';

import {
  GroupGoalsPageSkeleton,
  GroupPageSkeleton,
  GroupRequestsPageSkeleton,
  GroupSettingsPageSkeleton,
  UserListPageSkeleton,
} from 'components/Skeleton';
import IsExistsContainer from 'components/IsExistsContainer';

import {GroupContext} from './context';
import {GroupLeaderGuard} from './containers';
import {GroupMemberGuard} from './containers/GroupMemberGuard';

const GroupFeed = lazy(() => import('./pages/GroupFeed'));
const GroupGoals = lazy(() => import('./pages/GroupGoals'));
const GroupStats = lazy(() => import('./pages/GroupStats'));
const GroupUsers = lazy(() => import('./pages/GroupUsers'));
const GroupProfile = lazy(() => import('./pages/GroupProfile'));
const GroupInvites = lazy(() => import('./pages/GroupInvites'));
const GroupSettings = lazy(() => import('./pages/GroupSettings'));
const GroupRequests = lazy(() => import('./pages/GroupRequests'));

const useGetGroup = () => {
  const {groupId} = useParams<'groupId'>();
  return api.endpoints.groupIndex.useQuery({groupId: groupId as string});
};

export const router: RouteObject = {
  path: 'teams',
  children: [
    {index: true, element: <Navigate to="/discover/rating/groups" />},
    {
      path: ':groupId',
      element: (
        <IsExistsContainer useFetch={useGetGroup} context={GroupContext} LoadingComponent={<GroupPageSkeleton />} />
      ),
      children: [
        {
          index: true,
          element: (
            <Suspense fallback={<GroupPageSkeleton />}>
              <GroupProfile />
            </Suspense>
          ),
        },
        {
          path: 'feed',
          element: <GroupFeed />,
        },
        {
          path: 'members',
          element: (
            <Suspense fallback={<UserListPageSkeleton />}>
              <GroupUsers />
            </Suspense>
          ),
        },
        {
          path: 'goals',
          element: (
            <Suspense fallback={<GroupGoalsPageSkeleton />}>
              <GroupGoals />
            </Suspense>
          ),
        },
        {
          path: 'stats',
          element: <GroupStats />,
        },
        {
          element: <GroupMemberGuard />,
          children: [
            {
              path: 'requests',
              element: (
                <Suspense fallback={<GroupRequestsPageSkeleton />}>
                  <GroupRequests />
                </Suspense>
              ),
            },
          ],
        },
        {
          element: <GroupLeaderGuard />,
          children: [
            {
              path: 'invites',
              element: (
                <Suspense fallback={<UserListPageSkeleton />}>
                  <GroupInvites />
                </Suspense>
              ),
            },
            {
              path: 'settings',
              element: (
                <Suspense fallback={<GroupSettingsPageSkeleton />}>
                  <GroupSettings />
                </Suspense>
              ),
            },
          ],
        },
      ],
    },
  ],
};

export default router;
