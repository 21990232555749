import {SxProps} from '@mui/system';
import Box from '@mui/material/Box';

import {StageProgress, Stages} from 'api/generated/users-api';
import {croppedImagePath} from 'utils';

import {Status} from 'components/Status';

type QuestProgressCompactProps = {
  sx?: SxProps;
  fileId?: string | null;
  progress: StageProgress;
  status: Stages['status'] | 'in_progress';
};

const QuestProgressBadge = ({progress, sx = {}}: {progress?: StageProgress; sx?: SxProps}) => {
  if (!progress || !progress.requiredPointsTotal) return null;
  return (
    <Box height={20} width="100%" display="flex" justifyContent="center" sx={sx}>
      <Box
        px={0.75}
        height={20}
        borderRadius={8}
        sx={{
          lineHeight: '20px',
          typography: 'subtitle1',
          backgroundColor: 'white',
        }}
      >
        {`${progress.requiredPointsCompleted || 0}/${progress.requiredPointsTotal || 0}`}
      </Box>
    </Box>
  );
};

export const QuestProgressCompact = ({fileId, status, progress, sx}: QuestProgressCompactProps) => {
  const questStatus =
    status === 'completed'
      ? 'completed'
      : status === 'deadline' || status === 'failed' || status === 'rejected'
      ? 'failed'
      : undefined;
  return (
    <Box
      sx={sx}
      width={48}
      height={48}
      minWidth={48}
      minHeight={48}
      borderRadius={6}
      boxShadow={theme => theme.customShadows.inset}
    >
      {fileId && (
        <Box position="relative">
          <Box
            component="img"
            flexShrink={0}
            borderRadius={1.5}
            boxShadow={theme => theme.customShadows.inset}
            src={croppedImagePath(fileId, {maxWidth: 56, ratio: '1:1'})}
          />
          {status && <Status status={questStatus} top={-6} right={-6} />}
          <QuestProgressBadge progress={progress} sx={{bottom: -10, position: 'absolute'}} />
        </Box>
      )}
    </Box>
  );
};
