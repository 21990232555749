import {FastField} from 'formik';
import {TextField} from 'formik-mui';
import {MobileDatePicker} from 'formik-mui-lab';
import {memo, ReactNode, useContext} from 'react';

import {FileField} from './FileField';
import {PhotoField} from './PhotoField';
import {ScoreField} from './ScoreField';
import {RadioField} from './RadioField';
import {SelectField} from './SelectField';
import {AutocompleteField} from './AutocompleteField';
import {CheckboxGroupField} from './CheckboxGroupField';
import {CheckboxField, ToggleField} from './CheckboxField';
import {
  SelectFieldProps,
  TFormFieldProps,
  DividerFieldProps,
  TextInputProps,
  CheckboxFieldProps,
  PhotoFieldProps,
} from './types';
import Divider from './Divider';
import {FormItem} from '../types';
import {StyledFastField} from './styled';
import {FiledNameContext} from '../context';

import {InputCharCounter} from 'components/InputCharCounter';
import NumberField from './NumberField';

function FormField({name: originName, type, inputProps}: FormItem<any> & TFormFieldProps) {
  const namePrefix = useContext(FiledNameContext) || '';
  const name = namePrefix ? `${namePrefix}${originName ? `.${originName}` : ''}` : originName;

  if (type === 'select' || type === 'multi_select')
    return <SelectField {...(inputProps as SelectFieldProps['inputProps'])} name={name} type={type} />;
  if (type === 'autocomplete') return <AutocompleteField {...(inputProps as any)} name={name} type={type} />;
  if (type === 'radio')
    return <RadioField {...(inputProps as SelectFieldProps['inputProps'])} name={name} type={type} />;
  if (type === 'photo')
    return <PhotoField {...(inputProps as PhotoFieldProps['inputProps'])} name={name} type={type} />;
  if (type === 'file') return <FileField {...(inputProps as PhotoFieldProps['inputProps'])} name={name} type={type} />;
  if (type === 'checkbox_group') return <CheckboxGroupField {...(inputProps as any)} name={name} type={type} />;
  if (type === 'score')
    return <ScoreField {...(inputProps as SelectFieldProps['inputProps'])} name={name} type={type} />;
  if (type === 'divider') return <Divider {...(inputProps as DividerFieldProps['inputProps'])} />;
  if (type === 'toggle')
    return <FastField name={name} component={ToggleField} {...(inputProps as CheckboxFieldProps['inputProps'])} />;

  let Component: ReactNode = null;
  if (type === 'date') Component = MobileDatePicker;
  if (type === 'checkbox') Component = CheckboxField;
  if (type === 'number') Component = NumberField;
  if (['string', 'text', 'password'].includes(type)) Component = TextField;

  // TODO: [MVP-146] Сделать крестик, который очищвает строку
  if (!Component) return null;

  const {counter} = inputProps as TextInputProps['inputProps'];
  let addProps: any = type !== 'checkbox' ? {fullWidth: true, inputProps: {}} : {inputProps: {}};
  let overrides = {};

  if (type === 'text') {
    addProps = {
      ...addProps,
      multiline: true,
      minRows: 1,
      placeholder: (inputProps as TextInputProps['inputProps']).label,
    };

    overrides = {
      label: '',
    };
  }
  if ((type === 'text' || type === 'string') && counter) addProps.inputProps.maxLength = counter;

  return (
    <>
      <StyledFastField
        component={Component}
        {...addProps}
        size="medium"
        variant="standard"
        name={name}
        type={type === 'text' ? 'textarea' : type}
        {...inputProps}
        {...overrides}
      />
      {counter && <InputCharCounter name={name} maxValue={counter} />}
    </>
  );
}

export default memo(FormField);
