import {Box, Divider, Stack} from '@mui/material';

import {ProfileHeaderProps} from './types';
import {FollowersCount} from './FollowersCount';
import {GREY} from 'components/theme/palette';

export const ProfileHeader = ({name, image, counters = []}: ProfileHeaderProps) => (
  <Box>
    <Box display="flex" justifyContent="space-between" gap="8px" alignItems="center" mb={1}>
      <Box>{image}</Box>
      <Stack
        direction="row"
        spacing="4px"
        justifyContent="space-between"
        flexGrow={1}
        divider={
          <Divider orientation="vertical" flexItem sx={{borderColor: GREY[25], height: 16, alignSelf: 'center'}} />
        }
      >
        {counters.map((item, i) => (
          <FollowersCount key={i} {...item} />
        ))}
      </Stack>
    </Box>
    {name}
  </Box>
);

export default ProfileHeader;
