import {Stack} from '@mui/material';

import {GoalSliderSkeleton} from '../../Goal/components';
import {PublicationListSkeleton} from '../../Feed/components';
import {GroupHeaderSkeleton} from '../components';

export const GroupPageSkeleton = () => (
  <Stack>
    <GroupHeaderSkeleton />
    <GoalSliderSkeleton />
    <PublicationListSkeleton />
  </Stack>
);
