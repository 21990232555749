import {Outlet} from 'react-router-dom';
import {Box, Stack} from '@mui/material';
import {FunctionComponent, PropsWithChildren} from 'react';

import {useIsTablet} from 'utils';
import StickyBox from 'react-sticky-box';

import {Footer} from './Footer';
import {DesktopHeader} from './DesktopHeader';
import {AppHeader, HeaderProvider} from './Header';
import {BottomNavigation, SideNavigation} from './BottomNavigation';

export const Layout: FunctionComponent<PropsWithChildren<{}>> = ({children}) => {
  const isTablet = useIsTablet();
  if (isTablet) {
    return (
      <HeaderProvider>
        <Box
          maxWidth="sm"
          sx={{
            pb: 8,
            margin: '0 auto',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            minHeight: '100vh',
          }}
        >
          <AppHeader />
          <Box display="flex" flexGrow={1} sx={{'& > *': {width: '100%'}}}>
            {children || <Outlet />}
          </Box>
          <BottomNavigation />
        </Box>
      </HeaderProvider>
    );
  }
  return (
    <HeaderProvider>
      <Stack direction="row" spacing={4} mx={2} sx={{minHeight: '100vh'}} justifyContent="center">
        <Box
          maxWidth="sm"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            minHeight: '100%',
            width: '100%',
          }}
        >
          <DesktopHeader />
          <AppHeader />
          <Box sx={{flexGrow: 1, pb: 4, borderRadius: 2}}>{children || <Outlet />}</Box>
        </Box>
        <Box sx={{width: 300, py: 2}}>
          <StickyBox offsetTop={16} offsetBottom={16}>
            <Stack sx={{width: 300}} spacing={4}>
              <SideNavigation />
              <Footer />
            </Stack>
          </StickyBox>
        </Box>
      </Stack>
    </HeaderProvider>
  );
};

export default Layout;
export * from './ContentBlock';
export * from './InfoBlock';
export * from './UserCardHeader';
export * from './NPSBadge';
export * from './Header';
export * from './ErrorPage';
