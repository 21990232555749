import ButtonMenu from 'components/ButtonMenu';

import {ReactComponent as SortIcon} from 'svg/goalSort/sortIcon.svg';
import {ReactComponent as NewSortIcon} from 'svg/goalSort/newSort.svg';
import {ReactComponent as TimeSortIcon} from 'svg/goalSort/timeSort.svg';
import {ReactComponent as MoneySortIcon} from 'svg/goalSort/moneySort.svg';
import {ReactComponent as RatingSortIcon} from 'svg/goalSort/ratingSort.svg';

export const GoalFiltersMenu = ({onChange, selected}: {onChange: (newValue: any) => void; selected: string}) => (
  <ButtonMenu
    buttonProps={{
      icon: <SortIcon />,
      variant: 'contained',
      color: 'secondary',
      size: 'small',
    }}
    items={[
      {
        label: 'Сначала ближайшие',
        icon: <TimeSortIcon />,
        onClick: () => onChange('finish_date'),
        selected: selected === 'finish_date',
      },
      {
        label: 'Сначала дорогие',
        icon: <MoneySortIcon />,
        onClick: () => onChange('reward_value'),
        selected: selected === 'reward_value',
      },
      {
        label: 'Высокий рейтинг',
        icon: <RatingSortIcon />,
        onClick: () => onChange('rating'),
        selected: selected === 'rating',
      },
      {
        label: 'Новые',
        icon: <NewSortIcon />,
        onClick: () => onChange('created_date'),
        selected: selected === 'created_date',
      },
    ]}
  />
);
