import {useToggle} from 'react-use';
import {Outlet, useNavigate} from 'react-router';
import {FunctionComponent, PropsWithChildren, forwardRef} from 'react';

import {Close} from '@mui/icons-material';
import {TransitionProps} from '@mui/material/transitions';
import {Box, Slide, Button, Typography, Dialog, IconButton, useTheme} from '@mui/material';

import {useIsTablet} from 'utils';
import {Header} from 'components/Layout';
import {ReactComponent as LogoFullWhite} from 'svg/logo-full-white.svg';
import AuthBg from 'svg/auth/authBg.svg';

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const AuthLayout: FunctionComponent<PropsWithChildren<{}>> = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const isTablet = useIsTablet();
  const [open, toggleOpen] = useToggle(false);

  const onEnterClick = () => navigate('/enter');

  return (
    <Box
      sx={{
        minHeight: 500,
        backgroundColor: '#21075F',
        backgroundImage: `url(${AuthBg})`,
        backgroundPosition: 'center bottom',
        backgroundRepeat: 'no-repeat',
        backgroundSize: isTablet ? 'cover' : 'contain',
      }}
    >
      <Outlet />
      <Dialog
        open={open}
        onClose={toggleOpen}
        fullScreen={isTablet}
        maxWidth="sm"
        fullWidth
        TransitionComponent={Transition}
        PaperProps={
          !isTablet
            ? {
                sx: {minHeight: '90vh', maxHeight: '90vh'},
              }
            : {}
        }
      >
        <Header
          actions={
            <IconButton onClick={toggleOpen}>
              <Close />
            </IconButton>
          }
        />
        <Typography>Как получить приглашение</Typography>
      </Dialog>
      <Box
        sx={{
          height: '100vh',
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'relative',
        }}
      >
        <Box
          sx={{
            margin: '0 auto',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'flex-start',
            height: '100%',
            width: '100%',
            maxWidth: 375,
            position: 'relative',
            p: 2,
            pt: '25vh',
            minHeight: 600,
          }}
        >
          <LogoFullWhite />
          <Box sx={{position: 'absolute', bottom: theme.spacing(isTablet ? 4 : 6), width: '100%'}} px={2}>
            <Button fullWidth variant="contained" onClick={onEnterClick}>
              Войти
            </Button>
            <Box component="p" sx={{textAlign: 'center', mt: 2}}>
              <Typography variant="body2" align="center" component="span" sx={{color: 'white', opacity: 0.5}}>
                Вход только по приглашениям.
              </Typography>
              {/* &nbsp;
              <Typography
                variant="body1"
                component={Link}
                sx={{textDecoration: 'none', color: 'white'}}
                onClick={toggleOpen}
              >
                Как получить?
              </Typography> */}
            </Box>
            <Box mt={4}>
              <Typography variant="body1" align="center" sx={{color: 'white', opacity: 0.2}}>
                0.2 alpha
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default AuthLayout;
