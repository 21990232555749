import {useMemo} from 'react';
import {useAtomValue} from 'jotai/utils';

import Box from '@mui/system/Box';

import {useIsMe} from 'modules/auth';
import {StagePointProps} from 'modules/oldQuests2/types';
import {GoalWidget, useOpenGoalSelector} from 'modules/goals/containers';
import {useDialogView} from 'components/Dialog/context';

import {QuestPointWithProgress} from './QuestPointWithProgress';
import {isParentStageCompleted, isSubQuestPointAtom} from '../Quest/atom';

const EmptyGoalPoint: React.FC<Omit<StagePointProps, 'children'>> = ({children, stagePoint, questPoint: point}) => {
  const openGoalSelector = useOpenGoalSelector();

  const params = useMemo(() => ({goalTypeId: point.originId}), [point.originId]);
  const open =
    stagePoint?.stageId && stagePoint._id
      ? () =>
          openGoalSelector({
            params,
            stagePoint: {stageId: stagePoint.stageId, stagePointId: stagePoint._id as string},
          })
      : undefined;

  return (
    <>
      <Box sx={{cursor: 'pointer'}} onClick={open}>
        {children}
      </Box>
    </>
  );
};

const FilledGoalPoint: React.FC<StagePointProps> = ({children, stagePoint, questPoint: point}) => {
  const openGoal = useDialogView('goals', stagePoint?.referenceId);
  const name = point.name ? point.name : 'Поставьте цель';
  const isSubPoint = useAtomValue(isSubQuestPointAtom);

  if (!stagePoint || !stagePoint.referenceId) return children({name: 'Цель не поставлена'});
  return (
    <Box
      flexGrow={1}
      display="block"
      color="grey.700"
      onClick={openGoal ? () => openGoal() : undefined}
      sx={{
        cursor: 'pointer',
        transition: 'background-color .25s',
        '&:active': {color: 'grey.700'},
        '&:hover': {backgroundColor: 'secondary.main'},
      }}
    >
      {children({
        name,
        Content: (
          <GoalWidget
            showBox={!isSubPoint}
            goalId={stagePoint.referenceId}
            hideImage={isSubPoint}
            status={stagePoint.status}
          />
        ),
      })}
    </Box>
  );
};

export const GoalQuestPoint = ({stagePoint, questPoint, children}: StagePointProps) => {
  const isMy = useIsMe(stagePoint?.userId);
  const name = questPoint.name || 'Поставьте цель';
  const isCompleted = ['completed', 'failed', 'checking'].includes(stagePoint?.status || '');
  const locked = !questPoint.timelineCheck.available && !isCompleted;
  const parentStageCompleted = useAtomValue(isParentStageCompleted);

  const Content = children({name});
  if ('progress' in questPoint) {
    return <QuestPointWithProgress questPoint={questPoint} children={children} />;
  }
  if (locked || !stagePoint) return Content;
  if (stagePoint.status === 'new') {
    if (parentStageCompleted || !isMy) return Content;
    return (
      <EmptyGoalPoint questPoint={questPoint} stagePoint={stagePoint}>
        {Content}
      </EmptyGoalPoint>
    );
  }
  if (stagePoint.status === 'in_progress' || stagePoint.status === 'ready') {
    return <FilledGoalPoint stagePoint={stagePoint} questPoint={questPoint} children={children} />;
  }
  if (stagePoint.status === 'checking' || stagePoint.status === 'failed' || stagePoint.status === 'completed') {
    return <FilledGoalPoint stagePoint={stagePoint} questPoint={questPoint} children={children} />;
  }
  return Content;
};
