import * as _ from 'lodash/fp';
import * as R from 'ramda';
import {flattenDeep, isArray} from 'lodash';
import {Answers, Timelines} from 'api/generated/users-api';

import {ScriptActionAnswer, ScriptAnswers} from './types';

export const getTaskPath = (taskId: string, postfix = '') => `/tasks/${taskId}${postfix}`;
export const getTaskJobPath = (jobId: string, postfix = '') => `/tasks/job/${jobId}${postfix}`;

export const getTaskDeadline = (settings?: Timelines[], param?: 'dateFinish' | 'dateStart'): string | undefined =>
  _.compose(_.last, _.sortBy(_.identity), _.filter(Boolean), _.map(_.get(param || 'dateFinish')))(settings || []);

const populateValues = (scriptActionId: string, values: ScriptActionAnswer, index = 0) => {
  return Object.entries(values).map(templateOption => {
    const [tmpOptId, value] = templateOption;
    const result = {index, value: '', scriptActionId, templateOptionId: ''};
    if (!isArray(value))
      return {
        ...result,
        value: typeof value === 'boolean' ? tmpOptId : value.toString(),
        templateOptionId: tmpOptId === scriptActionId ? value.toString() : tmpOptId,
      };
    return value.map(item => ({
      ...result,
      value: typeof item === 'boolean' ? tmpOptId : item.toString(),
      templateOptionId: tmpOptId === scriptActionId ? item.toString() : tmpOptId,
    }));
  });
};

export const formDataToAnswers = (data: ScriptAnswers) =>
  flattenDeep(
    Object.entries(data).map(item => {
      if (!isArray(item[1])) return [populateValues(item[0], item[1])];
      else return item[1].map((values, index) => populateValues(item[0], values, index));
    })
  );

//

export const answersToFormData = (data: Answers[]) => {
  const res = R.compose(
    R.compose(
      R.fromPairs as any,
      R.map(item => {
        const res2 = R.compose(
          R.map(
            R.reduce((acc, item: any) => {
              let tmpId = item.templateOptionId;
              if (item.templateOptionId === item.value) tmpId = item.scriptActionId;
              if (acc[tmpId]) {
                const arrayValue = [...(isArray(acc[tmpId]) ? acc[tmpId] : [acc[tmpId]]), item.value];
                return {...acc, [tmpId]: arrayValue};
              }
              return {...acc, [tmpId]: item.value};
            }, {} as any)
          ),
          R.values,
          R.groupBy((item: any) => item.index)
        )(item[1] as any[]);
        return [item[0], res2];
      }),
      R.toPairs
    ),
    R.groupBy<Answers>(item => item.scriptActionId)
  )(data);
  return res;
};
