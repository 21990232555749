import {ReactNode} from 'react';
import {CardHeader} from '@mui/material';

import {Users} from 'api/generated/users-api';
import {ProfileImage, UserName} from 'modules/profile/components';
import {GREY} from 'components/theme/palette';

export type UserCardHeaderProps = {
  user: Users;
  subTitle?: string;
  action?: ReactNode;
};

export const UserCardHeader = ({user, subTitle, action}: UserCardHeaderProps) => (
  <CardHeader
    avatar={<ProfileImage user={user} />}
    title={<UserName user={user} />}
    subheader={subTitle}
    titleTypographyProps={{variant: 'body2', sx: {fontWeight: 500, lineHeight: '18px'}}}
    subheaderTypographyProps={{variant: 'subtitle2', color: GREY[200], sx: {lineHeight: '12px'}}}
    action={action}
  />
);
