import isNil from 'lodash/isNil';

import {FC} from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ButtonBase from '@mui/material/ButtonBase';
import {alpha, SxProps} from '@mui/material';

import {croppedImagePath, PlainFnProp} from 'utils';

import {StageProp} from '../../types';
import {requiredStageProgress} from '../../utils';

type QuestPreviewProps = StageProp & PlainFnProp<'onClick'> & {sx?: SxProps};

export const QuestPreview: FC<QuestPreviewProps> = ({stage, onClick, sx = {}}) => {
  const progress = requiredStageProgress(stage.localProgress);
  const backgroundFileId = stage.quest?.design?.backgroundFileId;
  const backgroundColor = stage.quest?.design?.backgroundColor || '#000';

  return (
    <ButtonBase sx={{width: '100%', position: 'relative', ...sx}} onClick={onClick}>
      <Box
        width={1}
        sx={{
          minHeight: 160,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundImage: backgroundFileId
            ? `linear-gradient(0deg, ${alpha(backgroundColor, 0.4)}, ${alpha(
                backgroundColor,
                0.45
              )}), url(${croppedImagePath(backgroundFileId, {ratio: '16:9', maxWidth: 700})})`
            : undefined,
          ...sx,
        }}
      />
      {/* <LinearGradient colors={['rgba(2, 20, 83, 0.8)', 'rgba(2, 21, 86, 0)']} locations={[0, 0.79]}> */}
      <Box
        px={2}
        py={3}
        width={1}
        height="100%"
        position="absolute"
        top={0}
        left={0}
        sx={{
          backgroundImage: `linear-gradient(0deg, rgba(2, 20, 83, 0.8), rgba(2, 21, 86, 0))`,
        }}
      >
        <Typography textAlign="left" color="white" variant="h2" fontWeight="bold">
          {stage.quest?.design?.header || stage.quest?.name}
        </Typography>
        {!isNil(progress) && (
          <Box flexDirection="row" mt={1}>
            <Typography textAlign="left" color="white" variant="body1">
              {progress}% Выполнено
            </Typography>
          </Box>
        )}
      </Box>
    </ButtonBase>
  );
};
