import {isArray} from 'lodash';
import {SxProps} from '@mui/system';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import isValid from 'date-fns/isValid';
import parseISO from 'date-fns/parseISO';
import isBefore from 'date-fns/isBefore';

import {Timelines} from 'api/generated/users-api';
import {formatDeadline} from 'utils/dates';

import {DeadlineBadge} from '.';
import {getTimelineDeadline} from './utils';

export const Deadline = ({
  timelines,
  hideBadge,
  sx = {},
}: {
  sx?: SxProps;
  hideBadge?: boolean;
  timelines?: Timelines | Timelines[];
}) => {
  if (!timelines) return null;
  const deadline = parseISO(getTimelineDeadline(isArray(timelines) ? timelines : [timelines]) || '');
  if (!deadline || !isValid(deadline)) return null;
  const overdue = isBefore(deadline, new Date());

  return (
    <Stack direction="row" sx={{alignItems: 'center', ...sx}} spacing={1}>
      <Box sx={{typography: 'subtitle1', color: 'grey.200'}}>{`Дедлайн: до ${formatDeadline(deadline)}`}</Box>
      {!hideBadge && !overdue && <DeadlineBadge deadline={deadline} size="small" />}
    </Stack>
  );
};
