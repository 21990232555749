import {atom} from 'jotai';
import {focusAtom} from 'jotai/optics';
import {useAtomValue, useUpdateAtom} from 'jotai/utils';

import {useToggle} from 'react-use';
import {useState, useRef, useMemo} from 'react';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Close from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import {keyframes} from '@mui/material';

import {api} from 'api';
import {ReactComponent as SendIcon} from 'svg/send.svg';
import {ReactComponent as LoaderIcon} from 'svg/icons/loaderIcon.svg';

import {RatingBar} from 'components/RatingBar/RatingBar';

import {useMyId} from 'modules/auth';
import {MyProfileImage} from 'modules/profile/components';
import {commentsStatsAtom, ratingStatsAtom} from 'modules/social/store';

import * as Styled from './styled';
import {CommentsIndexApiArg} from 'api/generated/users-api';

const SendButton = ({onSubmit, disabled, isLoading}: {onSubmit: () => void; disabled: boolean; isLoading: boolean}) => {
  const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;
  return (
    <IconButton
      size="small"
      onClick={onSubmit}
      sx={[
        {backgroundColor: 'primary.500', width: 32, height: 32},
        {'&[disabled]': {opacity: 0.5, backgroundColor: 'primary.400'}},
        {'&:hover': {backgroundColor: 'primary.400'}},
        {'& svg': {fill: 'rgba(0,0,0,0)'}},
        {'& svg path': {stroke: 'white'}},
      ]}
      disabled={disabled}
    >
      {isLoading ? (
        <Box sx={{animation: `${spin} 1s infinite ease`, width: '100%', height: '100%'}}>
          <LoaderIcon width="20px" height="22px" />
        </Box>
      ) : (
        <SendIcon />
      )}
    </IconButton>
  );
};

type CommentInputProps = {
  scope?: string;
  restrictScore?: boolean;
  replyCommentId?: string;
  referenceAuthorId?: string;
} & Pick<CommentsIndexApiArg, 'referenceId' | 'referenceName'>;

export const CommentInput = ({
  restrictScore = false,
  referenceAuthorId,
  replyCommentId,
  referenceId,
  referenceName,
  scope = `${referenceName}.${referenceId}`,
}: CommentInputProps) => {
  const myId = useMyId();
  const [addComment, {isSuccess, isLoading}] = api.endpoints.commentsAdd.useMutation();
  const lastMutation = useRef<ReturnType<typeof addComment>>();

  const ratingAtom = useMemo(() => focusAtom(ratingStatsAtom, o => o.prop(scope)), [scope]);
  const commentsAtom = useMemo(() => focusAtom(commentsStatsAtom, o => o.prop(scope)), [scope]);
  const isScoredAtom = useMemo(() => atom(get => !!get(ratingAtom)?.scoresId?.length), [ratingAtom]);

  const setRating = useUpdateAtom(ratingAtom);
  const isScored = useAtomValue(isScoredAtom);
  const setStats = useUpdateAtom(commentsAtom);

  const canScore = !isScored && !restrictScore && myId !== referenceAuthorId;

  const [focused, setFocused] = useToggle(false);
  const [score, setScore] = useState<number>();
  const [text, setText] = useState<string>('');
  const reset = () => {
    setText('');
    setScore(undefined);
    setFocused(false);
    lastMutation.current?.unsubscribe();
  };

  const onSubmit = async () => {
    lastMutation.current = addComment({
      addCommentDto: {comment: text, score, commentId: replyCommentId},
      referenceName: referenceName as any,
      referenceId,
    });
    const result = await lastMutation.current;
    if ('data' in result) {
      setStats(result.data.referenceCommentsStats);
      setRating(result.data.referenceScoreStats);
      setTimeout(reset, 2000);
    }
  };

  return (
    <Styled.Container sx={isSuccess ? {backgroundColor: 'success.light', borderColor: 'success.light'} : {}}>
      {isSuccess && (
        <Typography variant="body2" align="center" alignSelf="center" sx={{color: 'success.main', width: '100%'}}>
          Отправлено!
        </Typography>
      )}
      {!isSuccess && (
        <>
          <MyProfileImage size="small" noLink />
          <Styled.Content sx={{pt: '1px'}}>
            <Box display="flex">
              <Box sx={{pb: focused && canScore ? 4 : 0, width: '100%', display: 'inherit'}} flexGrow={1}>
                <Styled.Textarea
                  value={text}
                  multiline
                  fullWidth
                  size="small"
                  minRows={focused ? 3 : 1}
                  placeholder="Ваш комментарий"
                  sx={{
                    py: 0,
                    '& .MuiInputBase-input::placeholder': {
                      fontSize: 16,
                      color: 'grey.200',
                    },
                  }}
                  onFocus={() => setFocused(true)}
                  onChange={e => setText(e.target.value)}
                  onKeyUp={e => {
                    if (e.ctrlKey && e.keyCode === 13) onSubmit();
                  }}
                />
              </Box>

              {focused && (
                <Stack
                  spacing={1}
                  alignItems="flex-end"
                  justifyContent="space-between"
                  flexGrow={1}
                  sx={{position: 'relative', zIndex: 5}}
                >
                  <IconButton
                    size="small"
                    sx={[
                      {backgroundColor: 'grey.10', width: 24, height: 24},
                      {'&:hover': {backgroundColor: 'grey.25'}},
                    ]}
                    onClick={reset}
                  >
                    <Close fontSize="small" sx={{width: 12, height: 12}} />
                  </IconButton>
                  <SendButton
                    onSubmit={onSubmit}
                    disabled={!text || (text.length < 3 && !/\p{Extended_Pictographic}/u.test(text)) || isLoading}
                    isLoading={isLoading}
                  />
                </Stack>
              )}
            </Box>
            {focused && canScore && (
              <Box sx={{position: 'absolute', bottom: 4, left: -32, height: 24, width: 1 / 1}}>
                <RatingBar value={score} onChange={setScore} size="small" />
              </Box>
            )}
          </Styled.Content>
        </>
      )}
    </Styled.Container>
  );
};

export default CommentInput;
