import {atom} from 'jotai';
import {useUpdateAtom} from 'jotai/utils';

export const goalReportFormAtom = atom<{goalId: string | null; reportId: string | null; open: boolean}>({
  open: false,
  goalId: null,
  reportId: null,
});

export const useOpenGoalReportForm = (goalId?: string, reportId?: string) => {
  const fn = useUpdateAtom(goalReportFormAtom);
  return () => fn({goalId: goalId || null, reportId: reportId || null, open: true});
};
