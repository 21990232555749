export const sizes = {
  xsmall: 16,
  small: 24,
  medium: 32,
  big: 48,
  large: 64,
};

export const iconSizes = {
  xsmall: 10,
  small: 15,
  medium: 20,
  big: 30,
  large: 40,
};

export const borderSize = {
  large: 3,
  big: 3,
  medium: 3,
  small: 2,
  xsmall: 1,
};
