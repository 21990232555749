import {ReactNode} from 'react';
import {Box, Stack, Typography} from '@mui/material';

export type AuthHeaderProps = {
  title?: string;
  icon: ReactNode;
  subtitle?: string;
  subtitle2?: string;
};

export const AuthHeader = ({title, icon, subtitle, subtitle2}: AuthHeaderProps) => {
  return (
    <Stack justifyContent="center" spacing={2} mb={6}>
      <Box display="flex" justifyContent="center">
        {icon}
      </Box>
      {title && (
        <Typography variant="h1" align="center">
          {title}
        </Typography>
      )}
      {subtitle && (
        <Typography variant="body1" align="center">
          {subtitle}
        </Typography>
      )}
      {subtitle2 && (
        <Typography variant="body1" align="center">
          {subtitle2}
        </Typography>
      )}
    </Stack>
  );
};

export default AuthHeader;
