import Box from '@mui/system/Box';
import {SxProps} from '@mui/system';
import Typography from '@mui/material/Typography';

import {StageProgress} from 'api/generated/users-api';
import {Deadline} from 'components/Deadline';
import {EntityPreview} from 'components/EntityWidgets';

import {QuestOverallProgress} from '..';
import {QuestHeaderProps} from './types';

export const QuestProgressChip = ({
  progress,
  inProgress,
  showFraction,
  sx = {},
}: {
  progress?: StageProgress;
  inProgress?: boolean;
  showFraction?: boolean;
  sx?: SxProps;
}) => {
  if (!progress || progress.requiredPointsTotal == null || progress.requiredPointsCompleted == null) return null;
  if (!showFraction && !progress.requiredPointsCompleted && !inProgress) return null;

  let progressValue = '';
  if (!progress.requiredPointsCompleted && !progress.requiredPointsTotal) {
    progressValue = `0%`;
  } else {
    const percent = Math.floor(((progress.requiredPointsCompleted || 0) / (progress.requiredPointsTotal || 0)) * 100);
    progressValue = `${percent > 100 ? 100 : percent}%`;
  }
  return (
    <Box height={20} width="100%" display="flex" justifyContent="center" sx={sx}>
      <Typography px={0.75} height={20} borderRadius={8} lineHeight="20px" variant="subtitle1" bgcolor="white">
        {progressValue}
      </Typography>
    </Box>
  );
};

export const QuestHeader: React.VFC<QuestHeaderProps> = ({
  fileId,
  name,
  status,
  progress,
  subtitle,
  timeline,
  caption,
  imageSize,
  alignItems = 'flex-start',
  imagePosition = 'start',
}) => {
  return (
    <EntityPreview
      title={name}
      caption={caption}
      alignItems={alignItems}
      imagePosition={imagePosition}
      statusProps={status ? {status: status === 'deadline' ? 'in_progress' : status} : undefined}
      imageProps={{
        fileId,
        alt: name,
        size: imageSize,
        children: (
          <QuestProgressChip
            progress={progress}
            inProgress={status && ['edit', 'in_progress'].includes(status)}
            sx={{position: 'absolute', bottom: -10}}
          />
        ),
      }}
    >
      <>
        {timeline && <Deadline timelines={timeline} />}
        {!timeline && subtitle && (
          <Typography variant="subtitle1" color="grey.200" mt={0.5}>
            {subtitle}
          </Typography>
        )}
        {progress && (
          <Box mt={1.25} mb={0.25} width="100%">
            <QuestOverallProgress progress={progress} />
          </Box>
        )}
      </>
    </EntityPreview>
  );
};
