import {Card, CardContent, Stack, Link, Box} from '@mui/material';
import {HeaderSetup} from 'components/Layout';
import {SearchBlock, SearchTabs, Divider} from 'modules/search/components';
import {ReactComponent as ArrowUpIcon} from 'svg/icons/arrowUpIcon.svg';

export const SearchUsersPageMarkup = () => {
  return (
    <>
      <HeaderSetup title="" />
      <Stack spacing={0.5}>
        <Card>
          <CardContent>
            <SearchBlock />
          </CardContent>
          <SearchTabs defaultValue="users" />
        </Card>

        <Card>
          <CardContent>
            <Stack direction="row" paddingLeft="1px" gap={1}>
              <Link display="inline-flex" gap={0.5} underline="none" variant="body2" href="#">
                По рейтингу
                <Box display="flex" py={'1px'}>
                  <ArrowUpIcon />
                </Box>
              </Link>

              <Link display="inline-flex" gap={0.5} underline="none" variant="body2" href="#">
                По количеству подписчиков
              </Link>
            </Stack>
          </CardContent>
        </Card>

        <Card sx={{flexShrink: 0}}>
          <CardContent>
            <Divider title={'Мои подписки'} />
            <Stack gap={0.25}>
              {/* <UserItem title={'pdll'} followers={'233 подписчика'} type="bronze" score={98.24} /> */}
            </Stack>

            <Divider title={'Мои подписчики'} />
            <Stack gap={0.25}>
              {/* <UserItem title={'ivanklabukov'} followers={'50 подписчиков'} score={75.13} />
              <UserItem title={'ivan_ovsi'} followers={'20 подписчиков'} score={75.13} />
              <UserItem title={'ivansky96'} followers={'36 подписчиков'} score={75.13} /> */}
            </Stack>

            <Divider />
            <Stack gap={0.25}>
              {/* <UserItem title={'ivan_kenzo'} followers={'86 подписчиков'} score={75.13} />
              <UserItem title={'ivantcherezov'} followers={'96 подписчиков'} score={75.13} />
              <UserItem title={'dorn_ivan'} followers={'10 подписчиков'} score={75.13} />
              <UserItem title={'urgantcom'} followers={'156 подписчиков'} score={75.13} /> */}
            </Stack>
          </CardContent>
        </Card>
      </Stack>
    </>
  );
};

export default SearchUsersPageMarkup;
