import {lazy, Suspense} from 'react';
import Box from '@mui/material/Box';
import {Navigate, RouteObject} from 'react-router';

import AuthLayout from './components/AuthLayout';
import IsExistsContainer from 'components/IsExistsContainer';

import {ReactComponent as AuthIntiveError} from 'svg/auth/authInviteError.svg';

import {AuthHeader, AuthInnerLayout} from './components';
import {AuthGuard, RestrictLoggedGuard} from './containers';
import {useGetInvite, AuthInviteContext} from './pages/AuthInviteRegister/hook';

import Welcome from './pages/Welcome';
import AuthLogin from './pages/AuthLogin';
import AuthInviteRegister from './pages/AuthInviteRegister';
import {SettingsPageSkeleton} from 'components/Skeleton/Settings/pages';

const SettingsPage = lazy(() => import('./pages/Settings'));
const PublicationsRestoreFeed = lazy(() => import('./pages/PublicationsRestoreFeed'));
const GoalsRestoreFeed = lazy(() => import('./pages/GoalsRestoreFeed'));

export const router: RouteObject = {
  element: (
    <RestrictLoggedGuard>
      <AuthLayout />
    </RestrictLoggedGuard>
  ),
  children: [
    {index: true, element: <Navigate to="/welcome" />},
    {
      path: 'welcome',
      element: <Welcome />,
    },
    {
      path: 'enter',
      element: <AuthLogin />,
    },
    {
      path: 'invite',
      children: [
        // {index: true, element: <Navigate to="../" />},
        {
          path: ':inviteHash',
          element: (
            <IsExistsContainer
              useFetch={useGetInvite}
              context={AuthInviteContext}
              ErrorComponent={
                <AuthInnerLayout>
                  <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%'}}>
                    <AuthHeader
                      title="Приглашение неактивно"
                      icon={<AuthIntiveError />}
                      subtitle="Неверная ссылка либо регистрация уже завершена"
                    />
                  </Box>
                </AuthInnerLayout>
              }
            >
              <AuthInviteRegister />
            </IsExistsContainer>
          ),
        },
      ],
    },
  ],
};

export const settingsRoute = {
  path: 'settings',
  element: <AuthGuard />,
  children: [
    {
      index: true,
      element: (
        <Suspense fallback={<SettingsPageSkeleton />}>
          <SettingsPage />
        </Suspense>
      ),
    },
    {
      path: 'restoreFeed',
      element: <Navigate to="/publicationsRestoreFeed" />,
    },
    {
      path: 'publicationsRestoreFeed',
      element: <PublicationsRestoreFeed />,
    },
    {
      path: 'goalsRestoreFeed',
      element: <GoalsRestoreFeed />,
    },
  ],
};

export default router;
