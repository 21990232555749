import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import {ReactComponent as ResizeIcon} from 'svg/icons/resizeIcon.svg';

export const CollapseAllButton = ({onClick}: {onClick: () => void}) => (
  <Button
    variant="text"
    onClick={onClick}
    sx={{
      width: 'fit-content',
      height: 'fit-content',
      color: 'grey.200',
      py: 0.25,
      pl: 1,
      pr: 0.5,
      fontSize: 15,
      lineHeight: '18px',
    }}
  >
    Показать/скрыть все
    <Box component={ResizeIcon} ml={0.5} />
  </Button>
);
