import {api} from 'api';
import {useCallback} from 'react';
import {useNavigate} from 'react-router';

import {useErrorShackbar} from 'utils';
import {StagePointData} from 'api/generated/users-api';
import {getActiveQuestPath} from 'modules/oldQuests2/utils';

import {useSetPointReference} from './setReference.hook';
import {useGetStagePointByIdWithSelector} from './getStagePointsByContainerName.hook';
import {QuestPointPressHook, QuestPointProps, StageIdProp, StagePointIdProp} from '../types';

const selectQuestOptions = (point: StagePointData) => {
  const questPoint = point.questPointData;
  return {
    quests: questPoint.questPoint.options.quests,
  };
};

export const useQuestPointPress: QuestPointPressHook = ({stagePointId, stageId}: QuestPointProps) => {
  const navigate = useNavigate();
  const {quests} = useGetStagePointByIdWithSelector({stagePointId, stageId}, selectQuestOptions);
  const {
    canPress,
    acceptQuest,
    isLoading,
    stagePoint,
    questPoint: {type},
  } = useAcceptQuest({stagePointId, stageId});

  const navigateToQuest = () => {
    if (stagePoint?.referenceId) {
      navigate(getActiveQuestPath(stagePoint.referenceId));
    }
  };

  const setQuestReference = useCallback(async () => {
    if (quests?.questId) acceptQuest(quests?.questId);
    else if (quests?.questsId) {
      // navigate('questSelect', {stageId, stagePointId, title: name});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [acceptQuest, stagePointId]);

  return {
    isLoading: isLoading,
    canPress: !!type && ['quest', 'shortcut'].includes(type) && canPress,
    onPress: stagePoint?.referenceId ? navigateToQuest : setQuestReference,
  };
};

export const useAcceptQuest = ({stagePointId, stageId}: StageIdProp & StagePointIdProp) => {
  const navigate = useNavigate();
  const openErrorShackbar = useErrorShackbar();
  const {canPress, setReference, stagePoint, referenceProccessing, questPoint} = useSetPointReference({
    stagePointId,
    stageId,
  });
  const {timelineId} = questPoint;
  const [accept, {isLoading}] = api.endpoints.questAccept.useMutation();

  const acceptQuest = useCallback(
    async (questId: string) => {
      if (setReference) {
        const result = await accept({questId, partial: true, questActionDto: {timelineId, stagePointId}});
        if ('data' in result) {
          setReference(`${result.data.stage._id}`);
          navigate(getActiveQuestPath(`${result.data.stage._id}`));
        } else if ('error' in result) openErrorShackbar(result.error, 'Не получилось взять квест в работу');
      }
    },
    [setReference, accept, stagePointId, timelineId, openErrorShackbar, navigate]
  );

  return {acceptQuest, isLoading: referenceProccessing || isLoading, canPress, questPoint, stagePoint};
};
