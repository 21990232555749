import {Provider} from 'jotai';
import Box from '@mui/system/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import {QuestProp, StageIdProp} from 'modules/oldQuests2/types';
import {QuestListSkeleton} from 'components/Skeleton';
import {ReactComponent as AdventureIcon} from 'svg/quest/adventure.svg';

import {api} from 'api';
import {QuestPoint} from '..';
import {rootStageIdAtom} from '../Quest/atom';
import {selectAdventurePoints} from './selectors';
import {ShortcutQuestPoint} from './ShortcutQuestPoint';
import {MarkdownRender} from 'components/MarkdownRender';

export const AdventureQuest = ({quest, stageId}: QuestProp & StageIdProp) => {
  const {points = [], isLoading} = api.endpoints.stagePointsIndex.useQuery(
    {stageId},
    {
      selectFromResult: data => ({
        isLoading: data.isLoading,
        points: selectAdventurePoints(data?.data),
      }),
    }
  );

  return (
    <Provider initialValues={[[rootStageIdAtom, stageId]]} scope={Symbol.for('root-stage')}>
      <Provider initialValues={[[rootStageIdAtom, stageId]]} scope={Symbol.for('parent-stage')}>
        <Box>
          <Stack direction="row" p={2} spacing={1} alignItems="center">
            <Box component={AdventureIcon} width={24} height={24} borderRadius={12} sx={{backgroundColor: 'blue'}} />
            <Typography variant="h2">{quest.name}</Typography>
          </Stack>
          {quest.description && (
            <Box px={2} mb={1}>
              <MarkdownRender content={quest.description} />
            </Box>
          )}
          {isLoading && <QuestListSkeleton />}
          {points.map(point => {
            const key = point.questPointData.questPoint._id;
            if (point.questPointData.questPoint.type === 'shortcut') return <ShortcutQuestPoint key={key} {...point} />;
            if (point.questPointData.questPoint.type === 'quest')
              return <QuestPoint key={key} pointId={point.questPointData.questPoint._id as string} stageId={stageId} />;
            return null;
          })}
        </Box>
      </Provider>
    </Provider>
  );
};
