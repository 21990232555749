import {Card, CardContent, Stack, Link, Box, Paper} from '@mui/material';
import {HeaderSetup} from 'components/Layout';
import {SearchBlock, SearchTabs, TeamItem} from 'modules/search/components';
import {ReactComponent as ArrowUpIcon} from 'svg/icons/arrowUpIcon.svg';

export const SearchTeamsPageMarkup = () => {
  return (
    <>
      <HeaderSetup title="" />
      <Stack spacing={0.5}>
        <Card>
          <CardContent>
            <SearchBlock />
          </CardContent>
          <SearchTabs defaultValue="teams" />
        </Card>

        <Card>
          <CardContent>
            <Stack direction="row" paddingLeft="1px" gap={1}>
              <Link display="inline-flex" gap={0.5} underline="none" variant="body2" href="#">
                По рейтингу
                <Box display="flex" py={'1px'}>
                  <ArrowUpIcon />
                </Box>
              </Link>

              <Link display="inline-flex" gap={0.5} underline="none" variant="body2" href="#">
                По количеству подписчиков
              </Link>
            </Stack>
          </CardContent>
        </Card>

        <Stack gap={0.25}>
          <Paper elevation={0} sx={{padding: '0 16px'}}>
            <TeamItem
            // title={'Фемида Team'}
            // members={'10 участников'}
            // comment={'Команда лучших юристов'}
            // type="gold"
            // score={99.67}
            />
          </Paper>
          <Paper elevation={0} sx={{padding: '0 16px'}}>
            <TeamItem
            // title={'FortuneClub'}
            // members={'9 участников'}
            // comment={`Набираем персон с доходом\nот $50к/мес`}
            // score={89.99}
            />
          </Paper>
          <Paper elevation={0} sx={{padding: '0 16px'}}>
            <TeamItem
            // title={'FizCult'}
            // members={'9 участников'}
            // comment={`IronMan клуб для успешных людей`}
            // score={76.59}
            />
          </Paper>
          <Paper elevation={0} sx={{padding: '0 16px'}}>
            {/* <TeamItem title={'MoneyMakers'} members={'9 участников'} score={81.36} /> */}
          </Paper>
        </Stack>
      </Stack>
    </>
  );
};

export default SearchTeamsPageMarkup;
