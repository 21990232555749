import Box from '@mui/material/Box';
import MUIAvatar from '@mui/material/Avatar';

import {croppedImagePath} from 'utils';

import {ReactComponent as AvatarMale} from 'svg/avatar/noAvMale.svg';
import {ReactComponent as AvatarGroup} from 'svg/avatar/noAvGroup.svg';
import {ReactComponent as AvatarFemale} from 'svg/avatar/noAvFemale.svg';
import {ReactComponent as AvatarNoGender} from 'svg/avatar/noAvNotSure.svg';

import {sizes, iconSizes} from './utils';
import {AvatarImageProps} from './types';

export const Avatar = ({type = 'not_sure', fileId, inline, size = 'medium', sx}: AvatarImageProps) => {
  let Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>> | undefined;
  switch (type) {
    case 'not_sure':
      Icon = AvatarNoGender;
      break;
    case 'male':
      Icon = AvatarMale;
      break;
    case 'female':
      Icon = AvatarFemale;
      break;
    case 'group':
      Icon = AvatarGroup;
      break;
    default:
  }
  return (
    <MUIAvatar
      component="span"
      sx={{
        width: sizes[size],
        height: sizes[size],
        position: 'relative',
        backgroundColor: 'white',
        display: inline ? 'inline-block' : undefined,
        boxShadow: theme => theme.customShadows.inset,
        ...sx,
      }}
      src={fileId ? croppedImagePath(fileId, {ratio: '1:1', maxWidth: 200}) : undefined}
    >
      {Icon && (
        <Box
          top="50%"
          left="50%"
          position="absolute"
          component={Icon}
          width={iconSizes[size]}
          height={iconSizes[size]}
          sx={{color: 'grey.200', transform: 'translate(-50%, -50%)'}}
        />
      )}
    </MUIAvatar>
  );
};

export default Avatar;
