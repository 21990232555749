import {Stack, Avatar, Typography} from '@mui/material';
import {BottomButtonGroup} from 'modules/search/components';
import {GoalMoneyProgress} from 'modules/goals/components';
import aspectRatio from 'img/aspect-ratio.png';
import {Link} from 'react-router-dom';
import {GREY} from 'components/theme/palette';

export const GoalItem = ({
  goalName,
  goalInfo,
  isMoneyGoal = false,
}: {
  userName?: string;
  goalName: string;
  score?: number;
  goalInfo: any;
  isMoneyGoal?: boolean;
  type?: 'gold' | 'silver' | 'bronze' | undefined;
}) => {
  return (
    <Stack gap={1.5} direction="row" py={1}>
      <Avatar src={aspectRatio} sx={{width: 48, height: 48}} />
      <Stack flexGrow={1} spacing={1} pr={4}>
        {/* {userName && score && (
          <Stack direction="row" alignItems="center" gap={0.5}>
            <UserAvatar ImageComponent={<ProfileImage user={user} size="xsmall" />} type={type} size="xsmall" />
            <Typography variant="body2" sx={{fontWeight: 500}}>
              {userName}
            </Typography>
            <NPSBadge value={score} size="small" type={type} />
          </Stack>
        )} */}
        <Typography variant="body2" component={Link} sx={{fontWeight: 500, color: GREY[700]}} to={`/`}>
          {goalName}
        </Typography>
        <Stack direction="row" spacing={2}>
          {goalInfo}
        </Stack>
        {isMoneyGoal && <GoalMoneyProgress size="small" progress={{total: 1000000, count: 1000000, percentage: 100}} />}
        <BottomButtonGroup />
      </Stack>
    </Stack>
  );
};

export default GoalItem;
