import {FunctionComponent} from 'react';
import useTypedSelector from 'store/hooks';

import {Box, Typography} from '@mui/material';

import {ReactComponent as Logo} from 'svg/logoBlockerPage.svg';
import {TBlocker, TBlockerPage} from './types';
import {PRIMARY} from 'components/theme/palette';

const BlockerPage: FunctionComponent<TBlockerPage> = ({name, message}) => {
  return (
    <Box
      maxWidth="sm"
      sx={{
        margin: '0 auto',
        minHeight: '100vh',
        width: '100%',
        maxWidth: '100%',
        backgroundColor: PRIMARY[500],
      }}
    >
      <Box
        sx={{
          margin: '0 auto',
          width: '280px',
          minHeight: '100vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Logo />
        <Typography variant="h1" sx={{textAlign: 'center', color: 'white', margin: '48px 0 16px'}} component="h1">
          {name}
        </Typography>
        <Typography variant="subtitle1" sx={{textAlign: 'center', color: 'white', opacity: '0.8'}}>
          {message}
        </Typography>
      </Box>
    </Box>
  );
};

const Blocker: FunctionComponent<TBlocker> = ({children}) => {
  const {isBlocked, name, message} = useTypedSelector(state => state.blockerState);

  if (isBlocked)
    return <BlockerPage name={name || 'Сервис временно недоступен'} message={message || 'Скоро будет доступен'} />;

  return <>{children}</>;
};

export default Blocker;
