import {Stack} from '@mui/material';
import {fill} from 'lodash';
import {QuestPointItemSkeleton} from './QuestPointItemSkeleton';

export const QuestPointsListSkeleton = () => (
  <Stack spacing={1.5}>
    {fill(Array(3), '').map((_, i) => (
      <QuestPointItemSkeleton key={i} />
    ))}
  </Stack>
);
