import {Skeleton, Stack} from '@mui/material';
import {CommentsListSkeleton} from '../../Feed';

export const TaskCommentsPageSkeleton = () => (
  <Stack spacing={0.5}>
    <Stack direction="row" alignItems="center" spacing={1} p={2} bgcolor="white">
      <Skeleton variant="rectangular" width={56} animation="wave" height={56} sx={{borderRadius: 2}} />
      <Skeleton variant="text" animation="wave" width="75%" height={24} sx={{transform: 'none'}} />
    </Stack>
    <CommentsListSkeleton />
  </Stack>
);
