import Box from '@mui/system/Box';
import {Link} from 'react-router-dom';
import {useAtomValue} from 'jotai/utils';

import {api} from 'api';

import {useIsMe} from 'modules/auth';
import {getTaskJobPath} from 'modules/tasks/utils';
import {StagePointProps} from 'modules/oldQuests2/types';
import {useAcceptTask} from 'modules/tasks/containers/TaskAcceptButton/hook';
import {useDialogView} from 'components/Dialog/context';

import {useSetPointReference} from './hook';
import {isParentStageCompleted} from '../Quest/atom';
import {QuestPointWithProgress} from './QuestPointWithProgress';

const ViewTaskPublication: React.FC<{jobId: string}> = ({jobId, children}) => {
  const {data} = api.endpoints.jobIndex.useQuery({jobId: jobId});
  const openPost = useDialogView('feed', data?.publicationId);
  return (
    <Box onClick={data?.publicationId && openPost ? () => openPost() : undefined} sx={{cursor: 'pointer'}}>
      {children}
    </Box>
  );
};

const EmptyTaskPoint: React.FC<Omit<StagePointProps, 'children'>> = ({stagePoint, questPoint, children}) => {
  const taskId = `${questPoint.originId}`;
  const {setReference} = useSetPointReference({stagePoint, questPointData: questPoint});
  const {onAccept} = useAcceptTask({
    taskId,
    callback: setReference,
    timelineId: questPoint.timelineId,
    stagePointId: stagePoint?._id,
  });

  return (
    <Box onClick={onAccept} sx={{cursor: 'pointer'}}>
      {children}
    </Box>
  );
};

export const TaskQuestPoint = ({children, stagePoint, questPoint}: StagePointProps) => {
  const isMy = useIsMe(stagePoint?.userId);
  const isCompleted = ['completed', 'failed', 'checking'].includes(stagePoint?.status || '');
  const locked = !questPoint.timelineCheck.available && !isCompleted;
  const parentStageCompleted = useAtomValue(isParentStageCompleted);

  const Content = children({name: questPoint.name || 'Задание'});
  if ('progress' in questPoint) {
    return <QuestPointWithProgress questPoint={questPoint} children={children} />;
  }
  if (locked || !stagePoint) return Content;
  if (stagePoint.status === 'new' || (isMy && !stagePoint.referenceId && isCompleted)) {
    if (!isMy) return Content;
    return (
      <EmptyTaskPoint questPoint={questPoint} stagePoint={stagePoint}>
        {Content}
      </EmptyTaskPoint>
    );
  }
  if (stagePoint.referenceId && (stagePoint.status === 'in_progress' || stagePoint.status === 'ready')) {
    if (parentStageCompleted || !isMy)
      return <ViewTaskPublication jobId={stagePoint.referenceId}>{Content}</ViewTaskPublication>;
    return (
      <Box component={Link} to={getTaskJobPath(stagePoint.referenceId)}>
        {Content}
      </Box>
    );
  }
  if (stagePoint.referenceId && isCompleted) {
    if (isMy)
      return (
        <Box component={Link} to={getTaskJobPath(stagePoint.referenceId)}>
          {Content}
        </Box>
      );
    return <ViewTaskPublication jobId={stagePoint.referenceId}>{Content}</ViewTaskPublication>;
  }
  return Content;
};
