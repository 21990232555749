import * as yup from 'yup';
import {pick} from 'lodash';
import {ru} from 'date-fns/locale';
import {getDate, getMonth, getYear, addMonths, parseISO, parse, isValid} from 'date-fns';

import {FormObject, privacyOptions} from 'components/FormFactory';
import {DateButtonSelect} from 'modules/goals/components';

import {GoalInput} from './types';
import {VALIDATION_TEXTS, yupFilesRequired, yupStringRequired} from 'utils';
import {Goals} from 'api/generated/users-api';
import {FormItem} from 'components/FormFactory/types';

const today = new Date();
const year = getYear(today);
const month = getMonth(today);
const day = getDate(today);

const getDateChilds = (initials?: {day?: number; month?: number; year?: number}): FormItem<GoalInput>[] => {
  return [
    {
      type: 'select',
      name: 'day',
      initialValue: initials?.day || day,
      inputProps: {
        label: '',
        dense: true,
        options: new Array(31).fill(0).map((_, index) => {
          const value = (index + 1).toString();
          return {value, name: value};
        }),
      },
    },
    {
      type: 'select',
      name: 'month',
      initialValue: initials?.month || month,
      inputProps: {
        label: '',
        dense: true,
        options: new Array(12).fill(0).map((_, index) => {
          const name = ru.localize?.month(index);
          return {value: index + 1, name};
        }),
      },
    },
    {
      type: 'select',
      name: 'year',
      initialValue: initials?.year || year,
      inputProps: {
        label: '',
        dense: true,
        options: new Array(100).fill(0).map((_, index) => ({value: year + index, name: year + index})),
      },
    },
  ];
};

export const fields = (goalTypes: any[], tagsOptions: any[]): FormObject<GoalInput>[] => [
  {
    type: 'photo',
    name: 'filesId',
    initialValue: [],
    inputProps: {
      label: 'Картинка',
      multiple: true,
      ratioName: 'filesAspectRatio',
    },
  },
  {
    name: 'divider',
    type: 'divider',
    inputProps: {
      title: 'Общая информация',
      hasLine: true,
    },
  },
  {
    type: 'string',
    name: 'name',
    initialValue: '',
    validate: yup.string().required(),
    inputProps: {
      label: 'Название цели',
      counter: 70,
    },
  },
  {
    type: 'autocomplete',
    name: 'personalQualityTagsId',
    initialValue: [],
    inputProps: {
      label: 'Качество развития',
      options: tagsOptions,
    },
  },
  {
    type: 'select',
    name: 'goalTypeId',
    initialValue: '',
    inputProps: {
      label: 'Тип цели',
      options: goalTypes,
    },
  },
  {
    showIf: values => {
      const type = goalTypes.find(item => item.value === values.goalTypeId);
      return type && type.reward && type.rewardAsAnalog;
    },
    type: 'number',
    name: 'rewardValueInput',
    initialValue: '',
    inputProps: {
      label: 'Денежный эквивалент цели',
    },
  },
  {
    showIf: values => {
      const type = goalTypes.find(item => item.value === values.goalTypeId);
      return type && type.reward && !type.rewardAsAnalog;
    },
    type: 'number',
    name: 'rewardValueInput',
    initialValue: 0,
    inputProps: {
      label: 'Цель в деньгах',
    },
  },
  {
    type: 'text',
    name: 'description',
    initialValue: '',
    inputProps: {
      label: 'Опишите подробнее свою цель',
      counter: 500,
    },
  },
  {
    name: 'divider',
    type: 'divider',
    inputProps: {
      title: 'Дедлайн цели',
      hasLine: true,
    },
  },
  {
    type: 'toggle',
    name: 'showExactDate',
    inputProps: {
      label: 'Указать точную дату',
    },
  },
  {
    showIf: values => !!values.showExactDate,
    name: 'exactDate',
    display: 'inline',
    childs: getDateChilds({month: month + 1}),
  },
  {
    type: 'date',
    showIf: values => !values.showExactDate,
    name: 'planFinishDate',
    render: (field, meta, form) => <DateButtonSelect value={field.value} setValue={form.setValue} />,
    initialValue: addMonths(today, 1),
    inputProps: {
      label: 'Дата',
    },
  },
  {
    type: 'toggle',
    name: 'showStartDate',
    inputProps: {
      label: 'Указать дату фактического начала цели',
    },
  },
  {
    showIf: values => values.showStartDate,
    display: 'inline',
    name: 'startDate',
    childs: getDateChilds({month: month + 1}),
  },
  // {
  //   name: 'divider',
  //   type: 'divider',
  //   inputProps: {
  //     title: 'Периодичность отчетов',
  //     hasLine: true,
  //   },
  // },
  // {
  //   type: 'toggle',
  //   name: 'isScheduleEnabled',
  //   inputProps: {
  //     label: 'Указать периодичность отчетов',
  //   },
  // },
  // {
  //   name: 'schedule',
  //   showIf: values => !!values.isScheduleEnabled,
  //   display: 'inline',
  //   childs: [
  //     {
  //       type: 'select',
  //       name: 'actionsCount',
  //       initialValue: 3,
  //       validate: yup.string().required(),
  //       inputProps: {
  //         label: '',
  //         options: [
  //           {value: 1, name: '1 раз'},
  //           {value: 2, name: '2 раза'},
  //           {value: 3, name: '3 раза'},
  //           {value: 4, name: '4 раза'},
  //           {value: 5, name: '5 раз'},
  //         ],
  //       },
  //     },
  //     {
  //       type: 'select',
  //       name: 'actionsPeriod',
  //       initialValue: 'per_week',
  //       validate: yup.string().required(),
  //       inputProps: {
  //         label: '',
  //         options: [
  //           {value: 'total', name: 'Всего'},
  //           {value: 'per_week', name: 'В неделю'},
  //           {value: 'per_month', name: 'В месяц'},
  //           {value: 'per_year', name: 'В год'},
  //         ],
  //       },
  //     },
  //   ],
  // },
  {
    name: 'divider',
    type: 'divider',
    inputProps: {
      title: 'Настройки видимости',
      hasLine: true,
    },
  },
  {
    type: 'select',
    name: 'privacy',
    initialValue: 'public',
    inputProps: {
      label: 'Видимость цели',
      options: privacyOptions('feminine'),
    },
  },
];

export const validation = yup.object().shape({
  filesId: yupFilesRequired,
  personalQualityTagsId: yup.array().of(
    yup.object().shape({
      name: yupStringRequired,
      value: yupStringRequired,
    })
  ),
  name: yupStringRequired,
  goalTypeId: yup.string().required(VALIDATION_TEXTS.required),
  exactDate: yup
    .object()
    .shape({
      day: yup.number(),
      month: yup.number(),
      year: yup.number(),
      // day: yup.number().min(1).max(31),
      // month: yup.number().min(1).max(12),
      // year: yup.number().min(year),
    })
    .test('isCorrectDate', 'Введена некорректная дата', (data: any) => {
      const date = parse(`${data.day}.${data.month}.${data.year}`, 'dd.MM.yyyy', new Date());
      return isValid(date);
    })
    .when('showExactDate', {is: true, then: yup.object().required(VALIDATION_TEXTS.required)}),
  planFinishDate: yup
    .date()
    .min(today)
    .when('showExactDate', {is: false, then: yup.date().required(VALIDATION_TEXTS.required)}),
  showExactDate: yup.boolean().default(false),
  description: yup.string().required(VALIDATION_TEXTS.required),
  rewardValueInput: yup.number().min(0),
  // isScheduleEnabled: yup.boolean().default(false),
  // schedule: yup
  //   .object()
  //   .shape({
  //     actionsCount: yup
  //       .number()
  //       .when('isScheduleEnabled', {is: true, then: yup.number().required(VALIDATION_TEXTS.required)}),
  //     actionsPeriod: yup
  //       .string()
  //       .oneOf(['total', 'per_week', 'per_month', 'per_year'])
  //       .when('isScheduleEnabled', {is: true, then: yup.string().required(VALIDATION_TEXTS.required)}),
  //   })
  //   .when('isScheduleEnabled', {is: true, then: yup.object().required(VALIDATION_TEXTS.required)}),
  privacy: yup
    .string()
    .oneOf(['public', 'subscribers', 'groups', 'private', 'groups_subscribers'], 'Неверное значение приватности')
    .required(VALIDATION_TEXTS.required),
});

export const extractInitials = (goal?: Goals, isMyGoal?: boolean): GoalInput | null => {
  if (!goal) return null;
  let planFinishDate = goal.planFinishDate;
  const realStartDate = (goal && isMyGoal ? goal.realStartDate || goal.createdAt : new Date().toISOString()) as string;
  const startDate = Date.parse(realStartDate);
  if (!isMyGoal && parseISO(planFinishDate as string) < today) {
    const newDate = new Date();
    newDate.setMonth(newDate.getMonth() + 1);
    planFinishDate = newDate.toISOString();
  }
  const finishDate = parseISO(planFinishDate as string);
  return {
    ...pick(goal, ['filesId', 'name', 'privacy', 'goalTypeId', 'schedule', 'description']),
    showExactDate: true,
    showStartDate: false,
    filesAspectRatio: '1:1',
    personalQualityTagsId: (goal.tagsSet || []).map(item => ({name: `${item.tag?.name}`, value: `${item.tagId}`})),
    rewardValueInput: goal.rewardValue?.value || 0,
    isScheduleEnabled: goal.schedule?.isEnabled || false,
    planFinishDate,
    realStartDate,
    exactDate: {
      day: getDate(finishDate),
      month: getMonth(finishDate) + 1,
      year: getYear(finishDate),
    },
    startDate: {
      day: getDate(startDate),
      month: getMonth(startDate) + 1,
      year: getYear(startDate),
    },
  };
};
