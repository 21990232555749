import {Box, Skeleton, Stack} from '@mui/material';
import {RoundListSkeleton} from '../../RoundList';
import {GoalSliderSkeleton} from '../../Goal';
import {PublicationGridSkeleton} from '../../Feed';

export const SearchMainPageSkeleton = () => (
  <Stack spacing={0.5}>
    <GoalSliderSkeleton />
    <RoundListSkeleton />
    <RoundListSkeleton />
    <Stack bgcolor="white">
      <Box px={2} py={1}>
        <Skeleton variant="text" animation="wave" width={160} height={20} sx={{transform: 'none'}} />
      </Box>
      <PublicationGridSkeleton />
    </Stack>
  </Stack>
);
