import {Stack} from '@mui/material';

import {ReactionsButtonBlock} from 'modules/social/reactions/containers/ReactionsButtonBlock';
import {LastReaction} from 'modules/social/reactions/containers/LastReaction';
import {useReactionsContext} from 'modules/social/reactions/hooks';

export const ReactionButton = () => {
  const {myReaction, reactions} = useReactionsContext();
  return (
    <Stack direction="row" spacing={1}>
      <ReactionsButtonBlock symbolOnly plainText emptyText={reactions.length ? '' : '0'} />
      {!(myReaction && reactions.length === 1) && <LastReaction />}
    </Stack>
  );
};
