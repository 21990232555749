import {api} from 'api';
import {TOKEN_STORAGE_NAME} from '../constants';
import {useDispatch} from 'react-redux';

export const useLogout = () => {
  const dispatch = useDispatch();
  const [logout] = api.endpoints.appLogout.useMutation();
  return async () => {
    await logout();
    localStorage.removeItem(TOKEN_STORAGE_NAME);
    dispatch(api.util.resetApiState());
  };
};
