import {Skeleton, Stack} from '@mui/material';
import {PublicationListSkeleton} from '../components';

export const PublicationsPageSkeleton = () => (
  <Stack spacing={0.5}>
    <Stack mt={0.5} p={2} bgcolor="white">
      <Skeleton variant="rectangular" animation="wave" width="100%" height={32} sx={{borderRadius: 1.5}} />
    </Stack>
    <PublicationListSkeleton />
  </Stack>
);
