import {To} from 'react-router';
import {Stack, CardActions} from '@mui/material';

import {ScoreValue} from 'modules/social/nps';
import {CommentButton} from 'modules/social/comments';
import {ReactionsButtonBlock, LastReaction} from 'modules/social/reactions';

export const SocialBlock = ({
  commentsCount,
  referenceId,
  referenceName,
  commentButtonTo,
}: {
  commentsCount?: number;
  commentButtonTo?: To;
  referenceId: string;
  referenceName: string;
}) => {
  const commonProps = {referenceId, referenceName};
  return (
    <Stack spacing={1}>
      <CardActions sx={{p: 0, display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
        <Stack spacing={1} direction="row" alignItems="center">
          <ReactionsButtonBlock />
          <CommentButton initialCount={commentsCount} {...(commonProps as any)} to={commentButtonTo} />
        </Stack>
        <ScoreValue />
      </CardActions>
      <LastReaction />
    </Stack>
  );
};

export default SocialBlock;
