import {api} from 'api';
import {Provider} from 'jotai';
import {StageIdProp} from 'modules/oldQuests2/types';
import {QuestPointView} from 'modules/oldQuests2/components';

import {stageModeAtom} from './atom';
import {QuestPointContent} from '../QuestPoint';

export const ViewStage: React.VFC<StageIdProp> = ({stageId}) => {
  const {points} = api.endpoints.stageIndex.useQuery(
    {stageId},
    {
      selectFromResult: ({data}) => ({
        points: (data?.pointsData || []).filter(
          item => !!item.questPointData.timelineCheck.available && !item.questPointData.timelineCheck.skipped
        ),
      }),
    }
  );

  return (
    <Provider initialValues={[[stageModeAtom, 'view']]}>
      {points?.map((item, index) => (
        <div key={item.questPointData.questPoint._id}>
          <QuestPointContent
            index={index}
            points={item.points}
            Component={QuestPointView}
            stagePoint={item.stagePoint}
            questPointData={item.questPointData}
          />
        </div>
      ))}
    </Provider>
  );
};
