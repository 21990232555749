import {Button, ButtonGroup} from '@mui/material';
import {GREY} from 'components/theme/palette';
import {ReactComponent as LikeIcon} from 'svg/icons/likeIcon.svg';
import {ReactComponent as StarIcon} from 'svg/rating/star.svg';
import {ReactComponent as CommentIcon} from 'svg/icons/commentIcon.svg';
import {styled} from '@mui/system';

const StyledButtonGroup = styled(ButtonGroup)(() => ({
  justifyContent: 'flex-start',
  color: GREY[25],
  borderRadius: '32px',
  '& .MuiButton-root': {
    minWidth: 'initial',

    height: 16,
    padding: '2px 4px 2px 3px',
  },
}));

const StyledLikeIcon = styled(LikeIcon)`
  & path {
    stroke: ${GREY[200]};
  }
`;

const StyledCommentIcon = styled(CommentIcon)`
  & path {
    stroke: ${GREY[200]};
  }
`;

export const BottomButtonGroup = ({commentsCount}: {commentsCount?: number}) => {
  return (
    <StyledButtonGroup size="small">
      <Button variant="outlined" color="secondary">
        <StyledLikeIcon width="12" height="12" />
        254
      </Button>
      {commentsCount && (
        <Button variant="outlined" color="secondary">
          <StyledCommentIcon width="12" height="12" />
          {commentsCount}
        </Button>
      )}
      <Button variant="outlined" color="secondary">
        <StarIcon width="12" height="12" />
        8.9
      </Button>
    </StyledButtonGroup>
  );
};

export default BottomButtonGroup;
