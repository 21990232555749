import {Stack} from '@mui/material';
import {fill} from 'lodash';
import {GroupListItemSkeleton} from './GroupListItemSkeleton';

export const GroupListSkeleton = ({limit = 10}: {limit?: number}) => (
  <Stack>
    {fill(Array(limit), '').map((_, i) => (
      <GroupListItemSkeleton key={i} />
    ))}
  </Stack>
);
