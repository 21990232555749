import {Box, Skeleton, Stack} from '@mui/material';

export const ContentContentSkeleton = () => (
  <Box bgcolor="white">
    <Stack spacing={1.5} mb={5}>
      <Skeleton variant="text" animation="wave" width="100%" height={32} sx={{transform: 'none'}} />
      <Skeleton variant="text" animation="wave" width="75%" height={32} sx={{transform: 'none'}} />
    </Stack>
    <Stack spacing={1} mb={5}>
      <Skeleton variant="text" animation="wave" width="100%" height={17} sx={{transform: 'none'}} />
      <Skeleton variant="text" animation="wave" width="100%" height={17} sx={{transform: 'none'}} />
      <Skeleton variant="text" animation="wave" width="100%" height={17} sx={{transform: 'none'}} />
      <Skeleton variant="text" animation="wave" width="25%" height={17} sx={{transform: 'none'}} />
    </Stack>
    <Stack spacing={1.25} mb={5}>
      <Skeleton variant="text" animation="wave" width="100%" height={20} sx={{transform: 'none'}} />
      <Skeleton variant="text" animation="wave" width="50%" height={20} sx={{transform: 'none'}} />
    </Stack>
    <Stack spacing={1} mb={5}>
      <Skeleton variant="text" animation="wave" width="100%" height={17} sx={{transform: 'none'}} />
      <Skeleton variant="text" animation="wave" width="25%" height={17} sx={{transform: 'none'}} />
    </Stack>
    <Skeleton variant="rectangular" animation="wave" width="100%" height={320} />
    <Stack spacing={0.75} mt={1} mb={5}>
      <Skeleton variant="text" animation="wave" width="100%" height={13} sx={{transform: 'none'}} />
      <Skeleton variant="text" animation="wave" width="35%" height={13} sx={{transform: 'none'}} />
    </Stack>
    <Stack spacing={1.25} mb={5}>
      <Skeleton variant="text" animation="wave" width="100%" height={20} sx={{transform: 'none'}} />
      <Skeleton variant="text" animation="wave" width="50%" height={20} sx={{transform: 'none'}} />
    </Stack>
    <Stack spacing={1} mb={1}>
      <Skeleton variant="text" animation="wave" width="100%" height={17} sx={{transform: 'none'}} />
      <Skeleton variant="text" animation="wave" width="100%" height={17} sx={{transform: 'none'}} />
      <Skeleton variant="text" animation="wave" width="100%" height={17} sx={{transform: 'none'}} />
      <Skeleton variant="text" animation="wave" width="65%" height={17} sx={{transform: 'none'}} />
    </Stack>
  </Box>
);
