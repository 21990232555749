import * as yup from 'yup';
import {Outlet} from 'react-router';
import {useState, PropsWithChildren} from 'react';

import {TextField} from 'formik-mui';
import {Form, FormikProvider, useFormik} from 'formik';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import DialogActions from '@mui/material/DialogActions';

import {api} from 'api';

import {useMyId} from 'modules/auth';
import {entitiesNames} from 'modules/claims/utils';
import {ClaimContext} from 'modules/claims/context';
import {ClaimDataProps} from 'modules/claims/types';
import {useIsTablet, yupStringRequired} from 'utils';

import {GREY} from 'components/theme/palette';
import {StyledFastField} from 'components/FormFactory';

const ClaimForm = ({data, onClose}: {data: ClaimDataProps; onClose: () => void}) => {
  const isTablet = useIsTablet();
  const [addClaim, {isLoading, isSuccess}] = api.endpoints.claimsAdd.useMutation();
  const formik = useFormik({
    initialValues: {
      description: '',
    },
    onReset: onClose,
    onSubmit: async (input, {setSubmitting}) => {
      await addClaim({...data, claimDto: input});
      // eslint-disable-next-line no-console
      // if ('error' in result) console.log(result);
      setSubmitting(false);
    },
    validationSchema: yup.object().shape({
      description: yupStringRequired.min(10),
    }),
  });
  const {isValid, isSubmitting} = formik;

  return (
    <Dialog open onClose={onClose} onBackdropClick={onClose} fullScreen={isTablet} fullWidth maxWidth="xs">
      {!isSuccess && (
        <FormikProvider value={formik}>
          <Form autoComplete="off">
            <Box sx={{p: 3, pb: 0}}>
              <Typography variant="h2" sx={{mb: 2}}>
                Пожаловаться на {entitiesNames[data.referenceName]}
              </Typography>
              <StyledFastField
                component={TextField}
                fullWidth
                multiline
                minRows={4}
                type="textarea"
                name="description"
                variant="standard"
                placeholder="Напишите в чем причина жалобы"
              />
            </Box>
            <DialogActions sx={{p: 3}}>
              <Button variant="contained" color="primary" type="reset" size="small" sx={{px: 3}}>
                Отмена
              </Button>
              <LoadingButton
                sx={{px: 3}}
                loading={isLoading || isSubmitting}
                disabled={!isValid || isSubmitting}
                variant="contained"
                color="error"
                type="submit"
                size="small"
              >
                Отправить жалобу
              </LoadingButton>
            </DialogActions>
          </Form>
        </FormikProvider>
      )}
      {isSuccess && (
        <Box sx={{p: 3}}>
          <Typography variant="h2" sx={{mb: 2}}>
            Спасибо!
          </Typography>
          <Typography variant="body1" color={GREY[200]} sx={{mt: 1}}>
            Важа жалоба отправлена на рассмотрение модераторами
          </Typography>
          <DialogActions sx={{p: 0, mt: 3}}>
            <Button variant="contained" color="primary" size="small" onClick={onClose} sx={{px: 3}}>
              Закрыть
            </Button>
          </DialogActions>
        </Box>
      )}
    </Dialog>
  );
};

export const ClaimsProvider = ({children}: PropsWithChildren<{}>) => {
  const isAuth = Boolean(useMyId());
  const [claimData, setClaimData] = useState<ClaimDataProps | undefined>(undefined);

  if (!isAuth) return <>{children}</> || <Outlet />;
  return (
    <ClaimContext.Provider value={setClaimData}>
      {isAuth && !!claimData && <ClaimForm data={claimData} onClose={() => setClaimData(undefined)} />}
      {children || <Outlet />}
    </ClaimContext.Provider>
  );
};
