import {useCallback, useRef, useState} from 'react';
import {useDispatch} from 'react-redux';
import {debounce, keys} from 'lodash';
import {useAtomCallback} from 'jotai/utils';
import {useSnackbar} from 'notistack';

import {api} from 'api';
import {AnswerDto} from 'api/generated/users-api';
import {useNetwork} from 'utils';

import {flatAnswersAtom} from 'modules/tasks/containers/JobScript/atoms';
import {ScriptAnswersError} from 'modules/tasks/types';
import {
  clearCachedJobAnswers,
  getCachedJobAnswers,
  modifyCachedJobAnswers,
  setCachedJobAnswers,
} from 'modules/tasks/containers/JobScript/utils';

export const useSaveCachedAnswers = (jobId: string) => {
  const dispatch = useDispatch();
  const [fetchAnswers] = api.endpoints.jobAnswersGetAnswers.useLazyQuery();
  return useCallback(
    async (withoutRefetch?: true) => {
      const cachedJobAnswers = getCachedJobAnswers(jobId);
      if (!cachedJobAnswers) return;

      const scriptsId = keys(cachedJobAnswers);
      const updPromises = scriptsId
        .filter(scriptId => Boolean(cachedJobAnswers[scriptId]))
        .map(scriptId => {
          const answers = cachedJobAnswers[scriptId] as AnswerDto[];
          return dispatch(
            api.endpoints.jobAnswersSavePartialAnswers.initiate({jobId, scriptId, answersBodyDto: {answers}})
          );
        });
      await Promise.all(updPromises);
      clearCachedJobAnswers(jobId);

      if (withoutRefetch) return;

      const getPromises = scriptsId.map(scriptId => {
        return fetchAnswers({jobId, scriptId});
      });
      await Promise.all(getPromises);
    },
    [dispatch, jobId, fetchAnswers]
  );
};

export const usePatchAnswers = (jobId: string, scriptId: string) => {
  const dispatch = useDispatch();
  const saveCachedAnswers = useSaveCachedAnswers(jobId);
  const isOnline = useNetwork();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useCallback(
    debounce((dto: AnswerDto) => {
      if (!isOnline.current) {
        modifyCachedJobAnswers(jobId, scriptId, dto);
      } else {
        if (getCachedJobAnswers(jobId)) {
          modifyCachedJobAnswers(jobId, scriptId, dto);
          saveCachedAnswers();
        } else {
          dispatch(
            api.endpoints.jobAnswersSavePartialAnswers.initiate({jobId, scriptId, answersBodyDto: {answers: [dto]}})
          );
        }
      }
    }, 300),
    [dispatch, jobId, scriptId]
  );
};

export const useJobScriptForm = (
  jobId: string,
  scriptId: string,
  setError: (scriptActionId: string, error: {type: 'manual'; message: string}) => void,
  onAfterSave?: () => void
) => {
  const {enqueueSnackbar} = useSnackbar();
  const [submit, {isLoading}] = api.endpoints.jobAnswersSaveAnswers.useMutation();
  const lastMutation = useRef<ReturnType<typeof submit>>();
  const [globalMessage, setGlobalMessage] = useState<string | null>(null);
  const saveCachedAnswers = useSaveCachedAnswers(jobId);
  const isOnline = useNetwork(
    useCallback(async () => {
      if (!getCachedJobAnswers(jobId)) return;
      await saveCachedAnswers(true);
      clearCachedJobAnswers(jobId);
      enqueueSnackbar('Подключение восстановлено. Сохранено успешно', {variant: 'success', autoHideDuration: 3000});
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [jobId, saveCachedAnswers])
  );

  const onSubmit = useAtomCallback(
    useCallback(
      async get => {
        setGlobalMessage(null);
        const answers = get(flatAnswersAtom);
        if (!isOnline.current) {
          enqueueSnackbar('Отсутствует подключение к интернету. Сохранено локально на этом устройстве', {
            variant: 'warning',
            autoHideDuration: 6000,
            preventDuplicate: true,
          });
          return setCachedJobAnswers(jobId, scriptId, answers);
        }

        if (getCachedJobAnswers(jobId)) {
          setCachedJobAnswers(jobId, scriptId, answers);
          await saveCachedAnswers();
          if (onAfterSave) onAfterSave();
        } else {
          lastMutation.current = submit({scriptId, jobId, answersBodyDto: {answers}});
          const result = await lastMutation.current;
          if ('error' in result) {
            const err = result.error as ScriptAnswersError;
            if (err.status === 409) {
              if ('data' in err) {
                if (err.data?.message) setGlobalMessage(err.data?.message);
                if (err.data?.data) {
                  err.data.data.errors.forEach(item => {
                    setError(item.scriptActionId, {type: 'manual', message: item.error});
                  });
                }
              }
            }
          } else if (onAfterSave) onAfterSave();
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [jobId, scriptId, saveCachedAnswers, onAfterSave, submit, setError]
    )
  );

  return {onSubmit, isLoading, globalMessage};
};
