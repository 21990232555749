import {alpha} from '@mui/system';
import Box from '@mui/system/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import {Quests} from 'api/generated/users-api';

import {croppedImagePath} from 'utils';
import {QuestPreviewActions} from '../QuestActions';

export const QuestPreview = ({quest}: {quest: Quests}) => {
  const backgroundFileId = quest.design?.backgroundFileId;
  const backgroundColor = quest.design?.backgroundColor || '#000';

  return (
    <Stack
      sx={{
        px: 2,
        pb: 5,
        pt: 4,
        backgroundColor,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundImage: backgroundFileId
          ? `linear-gradient(0deg, ${alpha(backgroundColor, 0.7)}, ${alpha(
              backgroundColor,
              0.65
            )}), url(${croppedImagePath(backgroundFileId, {ratio: '1:1', maxWidth: 400})})`
          : undefined,
        justifyContent: 'center',
      }}
      spacing={2}
    >
      <Stack spacing={1}>
        {quest.design?.fileId && (
          <Box
            component="img"
            src={croppedImagePath(quest.design.fileId, {ratio: '1:1', maxWidth: 64})}
            sx={{
              margin: '0 auto',
              borderRadius: 2,
              position: 'relative',
            }}
          />
        )}
        <Typography variant="h2" sx={{color: 'white', pt: 1, fontWeight: 600, textAlign: 'center'}}>
          {quest.name}
        </Typography>
        <Typography variant="body2" sx={{color: 'white', opacity: 0.7, textAlign: 'center'}}>
          {quest.design?.header || 'Начните прямо сейчас!'}
        </Typography>
      </Stack>
      <QuestPreviewActions quest={quest} />
    </Stack>
  );
};
