import {api} from 'api';
import {omit} from 'lodash';
import {useEffect} from 'react';
import {useFormik} from 'formik';
import {useSnackbar} from 'notistack';

import {EditReportDto, Goals, GoalsReports} from 'api/generated/users-api';

import {useMyId} from 'modules/auth';
import {useConfirmDialog} from 'components/Dialog';
import {ReactComponent as EditIcon} from 'svg/icons/editIcon.svg';
import {ReactComponent as AddReportIcon} from 'svg/icons/addIcon.svg';

import {GoalReportInput} from './types';
import {useOpenGoalReportForm} from './atoms';
import {getInitialValues, validationSchema} from './utils';

export const useGoalReportForm = (goal: Goals, isMe: boolean) => {
  const fn = useOpenGoalReportForm(goal._id);
  const {goalsExists} = api.endpoints.profileIndex.useQueryState(undefined, {
    selectFromResult: ({data}) => ({
      goalsExists: !!data?.account.stats?.goalsCount,
    }),
  });

  if (!fn || !goalsExists) return null;
  if (!isMe || goal.status === 'completed' || goal.status === 'archived') return null;
  return fn;
};

export const useGoalReportMenuItem = (goal?: Goals, isMe?: boolean) => {
  const fn = useOpenGoalReportForm(goal?._id);
  const {goalsExists} = api.endpoints.profileIndex.useQueryState(undefined, {
    selectFromResult: ({data}) => ({
      goalsExists: !!data?.account.stats?.goalsCount,
    }),
  });

  if (!fn || !goalsExists) return null;
  if (!goal) return {label: 'Добавить отчет', icon: <AddReportIcon />, onClick: fn};
  if (!isMe || goal.status === 'completed' || goal.status === 'archived') return null;
  return {label: 'Добавить отчет', icon: <AddReportIcon />, onClick: fn};
};

export const useGoalEditReportMenuItem = (goalReport?: GoalsReports) => {
  const myId = useMyId();
  const fn = useOpenGoalReportForm(goalReport?.goalId, goalReport?._id);
  if (!myId || !fn || (goalReport && goalReport.userId !== myId)) return null;
  if (goalReport) return {label: 'Редактировать', icon: <EditIcon />, onClick: fn};

  return null;
};

export const useReportForm = (goal: Goals, report?: GoalsReports, close?: () => void) => {
  const {enqueueSnackbar} = useSnackbar();
  const [createReport] = api.endpoints.goalReportsAdd.useMutation();
  const [updateReport] = api.endpoints.goalReportUpdate.useMutation();
  const isMoneyGoal = !goal.goalType?.rewardAsAnalog && Boolean(goal.goalType?.rewardId);

  const formik = useFormik({
    validationSchema: validationSchema(isMoneyGoal),
    initialValues: getInitialValues(report),
    onSubmit: async (data: GoalReportInput, {setSubmitting}) => {
      let result;

      if (report) {
        const editReportDto: EditReportDto = {
          report: {
            ...data.report,
            rewardValue: report.rewardValue?.rewardId
              ? {rewardId: report.rewardValue.rewardId, value: parseInt(data.report.rewardValue, 10)}
              : undefined,
          },
          publication: report.publication
            ? {
                type: 'report',
                filesId: data.report.filesId,
                content: data.report.description,
                privacy: data.publication.privacy,
                enabled: data.publication.enabled,
                filesAspectRatio: data.report.filesAspectRatio || '1:1',
              }
            : undefined,
        };
        result = await updateReport({
          goalId: goal._id as string,
          reportId: report._id as string,
          editReportDto,
        });
      } else {
        const dto = {...omit(data, ['report.rewardValue'])} as any;
        if (data.report.rewardValue && dto.report && goal.goalType?.rewardId) {
          dto.report.rewardValue = {rewardId: goal.goalType.rewardId, value: parseInt(values.report.rewardValue, 10)};
        }
        if (!data.createPublication && !data.goalCompleted) delete dto.publication;
        else {
          dto.publication.filesId = dto.report.filesId;
          dto.publication.content = dto.report.description;
          dto.publication.filesAspectRatio = dto.report.filesAspectRatio;
        }
        result = await createReport({addReportDto: dto, goalId: goal._id as string});
      }
      setSubmitting(false);
      if ('data' in result) {
        enqueueSnackbar(`Отчет по цели ${report ? 'изменён' : 'добавлен'}`, {
          variant: 'success',
          persist: false,
          autoHideDuration: 2000,
        });
        if (close) close();
      } else if ('error' in result) {
        enqueueSnackbar((result.error as any)?.data?.message || 'Произошло ошибка сохранения данных', {
          persist: false,
          variant: 'error',
          autoHideDuration: 2000,
        });
      }
    },
  });
  const {values, setFieldValue} = formik;

  useEffect(() => {
    if (values.goalCompleted) setFieldValue('createPublication', values.goalCompleted, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.goalCompleted]);

  const [ConfirmDialog, submitWithConfirm] = useConfirmDialog({onConfirm: formik.submitForm});
  const onSubmit = values.goalCompleted ? submitWithConfirm : formik.submitForm;

  return {onSubmit, formik, ConfirmDialog};
};
