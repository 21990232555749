import {atom} from 'jotai';
import {CommentsStats, LikesStats, ReactionsStats, ScoresStats} from 'api/generated/users-api';
import {focusAtom} from 'jotai/optics';

export const likesStatsAtom = atom<Record<string, LikesStats | undefined>>({});
export const ratingStatsAtom = atom<Record<string, ScoresStats | undefined>>({});
export const commentsStatsAtom = atom<Record<string, CommentsStats | undefined>>({});
export const reactionsStatsAtom = atom<Record<string, ReactionsStats | undefined>>({});

export const scopeAtom = atom('');
export const getScopeReference = atom(get => get(scopeAtom).split('.'));

export const setSocialState = atom(
  undefined,
  (
    get,
    set,
    update: {
      comments?: CommentsStats;
      likes?: LikesStats;
      reactions?: ReactionsStats;
      score?: ScoresStats;
      scope: string;
    }
  ) => {
    const {scope} = update;
    if (update.score)
      set(
        focusAtom(ratingStatsAtom, o => o.prop(scope)),
        prev => prev || update.score
      );
    if (update.likes)
      set(
        focusAtom(likesStatsAtom, o => o.prop(scope)),
        prev => prev || update.likes
      );
    if (update.comments)
      set(
        focusAtom(commentsStatsAtom, o => o.prop(scope)),
        prev => prev || update.comments
      );
    if (update.reactions)
      set(
        focusAtom(reactionsStatsAtom, o => o.prop(scope)),
        prev => prev || update.reactions
      );
  }
);
