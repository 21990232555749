import {Stack, Typography} from '@mui/material';
import {ReactComponent as GoalCompleteIcon} from 'svg/icons/goalCompleteIcon.svg';

export const GoalCompleteBadge = () => {
  return (
    <Stack
      direction="row"
      gap={0.5}
      sx={{borderRadius: 5, backgroundColor: '#D1FADF', py: 0.5, px: 0.75, paddingLeft: '9px'}}
    >
      <GoalCompleteIcon />
      <Typography variant="subtitle1" sx={{color: '#039855'}}>
        Цель выполнена
      </Typography>
    </Stack>
  );
};

export default GoalCompleteBadge;
