import {useCallback} from 'react';
import {useParams} from 'react-router';
import {useDispatch} from 'react-redux';
import {useToggle} from 'react-use';
import {skipToken} from '@reduxjs/toolkit/dist/query';

import {api} from 'api';
import {StagePointData} from 'api/generated/users-api';

export const useGetActiveQuest = () => {
  const {stageId} = useParams<'stageId'>();
  return api.endpoints.stageIndex.useQuery(stageId ? {stageId} : skipToken);
};

const useFetchStagePointAttachments = () => {
  const dispatch = useDispatch();

  return useCallback(
    (point: StagePointData) => {
      const stageId = point.stagePoint?.stageId;
      if (stageId) dispatch(api.endpoints.stageIndex.initiate({stageId}));

      switch (point.questPointData.questPoint.type) {
        case 'task':
          const taskId = point.questPointData?.questPoint?.options?.tasks?.taskId;
          const jobId = point.stagePoint?.referenceId;
          if (jobId) {
            return dispatch(api.endpoints.jobIndex.initiate({jobId}));
          } else {
            return taskId ? dispatch(api.endpoints.taskIndex.initiate({taskId})) : null;
          }
        case 'publication':
          const postId = point.stagePoint?.referenceId;
          return postId ? dispatch(api.endpoints.publicationIndex.initiate({postId})) : null;
        case 'goal':
          const goalId = point.stagePoint?.referenceId;
          return goalId ? dispatch(api.endpoints.goalIndex.initiate({goalId})) : null;
        case 'content':
          const contentId = point.questPointData?.questPoint?.options?.contents?.contentId;
          return contentId ? dispatch(api.endpoints.contentIndex.initiate({contentId})) : null;
        default:
          return null;
      }
    },
    [dispatch]
  );
};

export const useQuestStageDataLoading = () => {
  const {stageId} = useParams<'stageId'>();
  const fetch = useFetchStagePointAttachments();
  const [loading, setLoading] = useToggle(true);
  const {data: stagePointsData, isLoading} = api.endpoints.stagePointsIndex.useQuery(stageId ? {stageId} : skipToken);

  const result = (stagePointsData || [])
    .map(rootStagePoint => [rootStagePoint, ...(rootStagePoint.points || [])].map(fetch))
    .flat()
    .filter(i => Boolean(i));

  Promise.all(result).then(() => {
    if (!isLoading && stagePointsData) setLoading(false);
  });

  return isLoading || loading;
};
