import {PropsWithChildren} from 'react';
import {Navigate, Outlet} from 'react-router-dom';

import {api} from 'api';

export const RestrictLoggedGuard = ({children}: PropsWithChildren<{}>) => {
  const {isLogged} = api.endpoints.profileIndex.useQueryState(undefined, {
    selectFromResult: ({data}) => ({isLogged: Boolean(data?.account)}),
  });

  if (isLogged) return <Navigate to="/my-profile" />;
  return children ? <>{children}</> : <Outlet />;
};

export default RestrictLoggedGuard;
