import {floor} from 'lodash';
import {styled} from '@mui/system';
import {Typography, Box, Stack} from '@mui/material';

import {Comments, Users} from 'api/generated/users-api';

import {NPSBadge} from 'components/Layout';
import {ProfileImage} from 'modules/profile/components';

const ProfileInlineName = ({user}: {user: Users}) => (
  <Stack display="inline-flex" direction="row" spacing={0.5}>
    <ProfileImage user={user} size="xsmall" inline noLink />
    <Typography fontWeight={500} sx={{color: 'grey.700', ml: 0.5}} variant="body2" component="span">
      {user.name}
    </Typography>
  </Stack>
);

const TruncatedBox = styled(Box)`
  max-height: 40px;
  overflow: hidden;
`;

export const CommentEntry = ({comment}: {comment: Comments}) => {
  return (
    <TruncatedBox>
      <Box sx={{verticalAlign: 'top', display: 'inline'}}>
        <ProfileInlineName user={comment.user as Users} />
        &nbsp;
        {comment.scoreInfo && (
          <NPSBadge
            size="small"
            value={floor(comment.scoreInfo.value)}
            fixedValue={0}
            sx={{verticalAlign: 'baseline'}}
          />
        )}
        <Typography variant="body2" component="span" sx={{verticalAlign: 'top'}}>
          &nbsp;{comment.content}
        </Typography>
      </Box>
    </TruncatedBox>
  );
};

export default CommentEntry;
