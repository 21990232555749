import {useCallback, useState} from 'react';
import ButtonMenu from 'components/ButtonMenu';

import {ReactComponent as SortIcon} from 'svg/goalSort/sortIcon.svg';
import {ReactComponent as SortAsc} from 'svg/icons/sortAsc.svg';
import {ReactComponent as SortDesc} from 'svg/icons/sortDesc.svg';
import {CommentsIndexApiArg} from 'api/generated/users-api';

export const useCommentsSortingMenu = (
  initial?: CommentsIndexApiArg['sort']
): [() => JSX.Element, CommentsIndexApiArg['sort']] => {
  const [sort, setSort] = useState<CommentsIndexApiArg['sort']>(initial || 'created_asc');
  const Menu = useCallback(
    () => (
      <ButtonMenu
        buttonProps={{
          icon: <SortIcon />,
          variant: 'contained',
          color: 'secondary',
          size: 'small',
        }}
        items={[
          {
            label: 'Сначала новые',
            icon: <SortAsc />,
            onClick: () => setSort('created_desc'),
            selected: sort === 'created_desc',
          },
          {
            label: 'Сначала старые',
            icon: <SortDesc />,
            onClick: () => setSort('created_asc'),
            selected: sort === 'created_asc',
          },
          {
            label: 'По рейтингу',
            icon: <SortDesc />,
            onClick: () => setSort('nps'),
            selected: sort === 'nps',
          },
        ]}
      />
    ),
    [sort]
  );

  return [Menu, sort];
};
