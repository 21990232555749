import {ReactNode} from 'react';
import Slider from 'react-slick';
import {useMeasure} from 'react-use';
import {LazyLoadImage} from 'react-lazy-load-image-component';

import {Theme} from '@mui/material';
import Box from '@mui/material/Box';
import {styled, SxProps} from '@mui/system';
import {croppedImagePath, getRatioMultiplier} from 'utils';

import {FileCropApiArg} from 'api/generated/files-api';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const StyledSlider = styled('div')`
  & .slick-dots {
    height: ${({theme}) => theme.spacing(2)};
    bottom: ${({theme}) => theme.spacing(2)};

    li {
      width: 6px;
      height: 6px;
      margin: ${({theme}) => theme.spacing(0, 0.5)};
      border-radius: 50%;
      background-color: white;
      opacity: 0.5;

      &.slick-active {
        opacity: 1;
      }
    }
  }
`;

export const LazyImage = ({
  ratio = '1:1',
  width,
  fileId,
  alt,
  blurred,
  withPreview,
  borderRadius,
  sx,
}: {
  width: number;
  alt: string;
  fileId: string;
  ratio?: FileCropApiArg['ratio'];
  withPreview?: boolean;
  borderRadius?: number;
  sx?: SxProps<Theme>;
  blurred?: boolean;
}) => {
  const [ref, {width: boxWidth}] = useMeasure<HTMLDivElement>();
  const height = boxWidth / getRatioMultiplier(ratio);

  return (
    <Box
      ref={ref}
      sx={{
        borderRadius,
        position: 'relative',
        overflow: 'hidden',
        '& span': {
          display: 'block !important',
          filter: blurred ? 'blur(6px) !important' : undefined,
          transform: blurred ? 'scale(1.2)' : undefined,
        },
        '&::after': {
          top: 0,
          left: 0,
          borderRadius,
          content: '""',
          width: 1 / 1,
          height: 1 / 1,
          display: 'block',
          position: 'absolute',
          boxShadow: theme => theme.customShadows.inset,
        },
        ...sx,
      }}
    >
      {withPreview && (
        <Box top={0} left={0} position="absolute" width="100%" height="100%">
          <LazyLoadImage
            alt={alt}
            effect="blur"
            width={boxWidth}
            height={height}
            src={croppedImagePath(fileId, {ratio, maxWidth: 50})}
          />
        </Box>
      )}
      <LazyLoadImage
        alt={alt}
        effect="blur"
        width={boxWidth}
        height={height}
        src={croppedImagePath(fileId, {ratio, maxWidth: width})}
      />
    </Box>
  );
};

export const ImageGallery = ({
  filesId,
  ratio,
  alt = '',
  width = 1000,
  action: Action,
  withPreview,
}: {
  filesId: string[];
  ratio: FileCropApiArg['ratio'];
  width?: number;
  alt?: string;
  action?: (fileId: string) => ReactNode;
  dotsOutside?: boolean;
  withPreview?: boolean;
}) => {
  if (!filesId) return null;
  return (
    <StyledSlider>
      <Slider infinite={false} slidesToShow={1} slidesToScroll={1} dots={true}>
        {filesId.map(item => (
          <Box position="relative" key={item}>
            <LazyImage fileId={item} width={width} ratio={ratio} alt={alt || ''} withPreview={withPreview} />
            {Action && Action(item)}
          </Box>
        ))}
      </Slider>
    </StyledSlider>
  );
};
