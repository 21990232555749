import {FastField} from 'formik';
import Box from '@mui/system/Box';
import styled from '@mui/system/styled';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';

import {PRIMARY, GREY} from 'components/theme/palette';

export const StyledFastField = styled(FastField)(() => ({
  position: 'relative',
  '& .MuiInputBase-root': {
    height: '46px',
    marginTop: '6px',
    padding: 0,
    fontSize: '16px',
    lineHeight: '20px',
    color: GREY[700],
    '&:hover:not(.Mui-disabled)::before': {
      borderBottom: 'none',
    },
    '&::before, &::after': {
      borderBottom: 'none',
    },
  },
  '& .MuiInput-input': {
    padding: 0,
    '&:not(.MuiInputBase-inputMultiline)': {
      paddingBottom: '14px',
    },
  },
  '& .MuiInputLabel-root': {
    color: GREY[200],
    fontSize: '12px',
    lineHeight: '12px',
    '&.Mui-focused': {
      color: 'inherit',
    },
  },
  '& .MuiInputBase-multiline': {
    height: 'auto',
    minHeight: '44px',
  },
  '& .MuiInput-input::placeholder': {
    color: GREY[200],
    fontSize: '16px',
    lineHeight: '20px',
  },
}));

export const StyledFormControlLabel = styled(FormControlLabel)(() => ({
  padding: '12px 0',
}));

export const StyledSwitch = styled(Switch)(() => ({
  width: 36,
  height: 24,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 1,
    '&.Mui-checked': {
      color: '#fff',
      transform: 'translateX(12px)',
      '& + .MuiSwitch-track': {
        opacity: 1,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
  },
  '& .MuiSwitch-track': {
    backgroundColor: PRIMARY[10],
    borderRadius: 36,
    opacity: 1,
  },
  '& .MuiSwitch-thumb': {
    boxShadow: 'none',
    width: 16,
    height: 16,
    margin: 3,
  },
}));

export const CardWithoutScroll = styled(Box)`
  & {
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }
`;
