import {memo} from 'react';
import {Link} from 'react-router-dom';
import {Card, CardContent, Stack} from '@mui/material';

import {Contents} from 'api/generated/users-api';
import {useIsViewed} from 'utils/isViewed';

import {TaskPreviewHeader} from 'modules/tasks/components/TaskPreview';
import {ContentsFooterInfo} from 'modules/contents/components/ContentsFooterInfo';

import {ImageGallery} from 'components/ImageGallery';
import {MarkdownRender} from 'components/MarkdownRender';

type ContentPreviewProps = {
  data: Contents;
};

export const ContentPreview = memo(
  ({data}: ContentPreviewProps) => {
    const [isViewed] = useIsViewed({referenceName: 'Contents', referenceId: `${data._id}`}, Boolean(data.isViewed));
    return (
      <Card sx={{pb: 1}}>
        {data.fileId && (
          <Link to={`/contents/${data._id}`}>
            <ImageGallery filesId={[data.fileId]} width={1000} ratio="16:9" alt={`Материал ${data.name}`} />
          </Link>
        )}
        <CardContent sx={{py: 2}}>
          <Stack spacing={1}>
            <TaskPreviewHeader
              name={data.name || 'Материал'}
              category={data.category?.name}
              link={`/contents/${data._id}`}
              status={isViewed ? undefined : 'new'}
            />
            <MarkdownRender content={data.description} />
            <ContentsFooterInfo data={data} />
          </Stack>
        </CardContent>
      </Card>
    );
  },
  (p, n) => p.data._id === n.data._id && p.data.isViewed === n.data.isViewed
);
