import {
  Contents,
  Goals,
  Groups,
  GroupsInvites,
  GroupsRequests,
  Jobs,
  Notifications,
  Publications,
  Tasks,
  Users,
} from 'api/generated/users-api';
import {getPostPath} from 'modules/feed/utils';
import {getUserLink} from 'modules/profile/utils';

const likeText: any = {
  Goals: 'лайк вашей цели',
  Publications: 'лайк вашей записи',
  Comments: 'лайк вашему комментарию',
  default: 'лайк вашей записи',
};

const likeActionText: any = {
  female: 'поставила',
  male: 'поставил',
};

const scoreActionText: any = {
  female: 'оценила',
  male: 'оценил',
};

const scoreText: any = {
  Publications: 'вашу запись',
  Comments: 'ваш комментарий',
  Goals: 'вашу цель',
  default: 'вашу запись',
};

const commentActionText: any = {
  female: 'написала',
  male: 'написал',
};
const commentReplyActionText: any = {
  female: 'ответила',
  male: 'ответил',
};

const commentText: any = {
  Goals: 'комментарий к вашей цели',
  default: 'комментарий к вашей записи',
  Publications: 'комментарий к вашей записи',
  Comments: 'комментарий к вашему комментарию',
};

export const getCommentText = ({
  referenceName,
  author,
}: {
  author: Users;
  referenceName: Notifications['referenceName'];
}) =>
  `${commentActionText[author.gender === 'female' ? 'female' : 'male']} ${
    commentText[referenceName] || commentText['default']
  }`;

export const getSubscriptionText = ({author}: {author: Users}) =>
  `${author.gender === 'female' ? 'подписалась' : 'подписался'} на вас`;

export const getCommentReplyText = ({author}: {author: Users}) =>
  `${commentReplyActionText[author.gender === 'female' ? 'female' : 'male']} на ваш комментарий`;

export const getScoreReplyText = ({author}: {author: Users}) =>
  `${scoreActionText[author.gender === 'female' ? 'female' : 'male']} ваш комментарий`;

export const getLikeText = ({referenceName, author}: {author: Users; referenceName: Notifications['referenceName']}) =>
  `${likeActionText[author.gender === 'female' ? 'female' : 'male']} ${likeText[referenceName] || likeText['default']}`;

export const getScoreText = ({referenceName, author}: {author: Users; referenceName: Notifications['referenceName']}) =>
  `${scoreActionText[author.gender === 'female' ? 'female' : 'male']} ${
    scoreText[referenceName] || scoreText['default']
  }`;

export const getImage = (item: Notifications) => {
  if (item.referenceName === 'Publications') return (item.referenceId as unknown as Publications).filesId?.[0];
  // if (item.referenceName === 'Comments') return (item.referenceId as unknown as Comments).re;
  if (item.referenceName === 'Contents') return (item.referenceId as unknown as Contents).fileId;
  if (item.referenceName === 'Goals') return (item.referenceId as unknown as Goals).filesId[0];
  // if (item.referenceName === 'GoalsReports') return (item.referenceId as unknown as GoalsReports).filesId[0];
  if (item.referenceName === 'Groups') return (item.referenceId as unknown as Groups).fileId;
  if (item.referenceName === 'GroupsInvites') return (item.referenceId as unknown as GroupsInvites).group?.fileId;
  if (item.referenceName === 'GroupsRequests') return (item.referenceId as unknown as GroupsRequests).group?.fileId;
  if (item.referenceName === 'Jobs') return (item.referenceId as unknown as Jobs).task?.filesId[0];
  // if (item.referenceName === 'Likes') return item.referenceId as unknown as any;
  // if (item.referenceName === 'Scores') return item.referenceId as unknown as Scores;
  if (item.referenceName === 'Tasks') return (item.referenceId as unknown as Tasks).filesId[0];
  if (item.referenceName === 'Users') return (item.referenceId as unknown as Users).fileId;
  return undefined;
};

type Entity = {_id: string};

export const getLinkPath = (item: Notifications) => {
  const reference = item.referenceId as unknown as Entity;
  if (item.referenceName === 'Publications') return getPostPath(reference._id);
  if (item.referenceName === 'Users') return getUserLink(reference as Users);
  if (item.referenceName === 'Goals') return `@${(reference as Goals).userId}/goals/${reference._id}/comments`;
  return undefined;
};
