import {Outlet} from 'react-router';
import {PropsWithChildren, useCallback, useContext, useState} from 'react';

import {useDialog} from 'components/Dialog';

import {GoalForm} from '../GoalForm';
import {GoalFormContext} from './context';

export const useGoalFormOpener = () => {
  const openForm = useContext(GoalFormContext);
  if (!openForm) throw new Error('useGoalFormOpener should be used inside GoalFormContext');
  return openForm;
};

export const GoalFormProvider = ({children}: PropsWithChildren<{}>) => {
  const [goalId, setGoalId] = useState<string>();
  const [Dialog, toggleOpen] = useDialog();
  const onClose = useCallback(() => {
    if (goalId) setGoalId(undefined);
    toggleOpen(false);
  }, [goalId, toggleOpen]);
  const openForm = useCallback(
    (newGoalId?: string) => {
      if (newGoalId) setGoalId(newGoalId);
      toggleOpen(true);
    },
    [toggleOpen]
  );

  return (
    <GoalFormContext.Provider value={openForm}>
      <Dialog onClose={onClose} title="Постановка цели">
        <GoalForm goalId={goalId} close={onClose} />
      </Dialog>
      {children || <Outlet />}
    </GoalFormContext.Provider>
  );
};

export default GoalFormProvider;
