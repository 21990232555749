import {Stack} from '@mui/material';

import {useMyProfileInfo} from 'modules/auth';
import {MyProfileImage} from 'modules/profile/components';

import SideNavigationItem from './SideNavigationItem';
import BottomNavigationConfig from './BottomNavigationConfig';
import {useGetUnread} from 'utils';

type SideNavigationProps = {
  mode?: 'side' | 'page';
  withProfileImage?: boolean;
};

export const SideNavigation = ({mode = 'side', withProfileImage = false}: SideNavigationProps) => {
  const {link, name} = useMyProfileInfo();
  const unread = useGetUnread();
  return (
    <Stack
      spacing={mode === 'side' ? 0.5 : 1}
      sx={{maxWidth: 375, px: mode === 'side' ? 0 : 2, margin: '0 auto', width: '100%'}}
    >
      {BottomNavigationConfig(unread).map(item => (
        <SideNavigationItem
          {...item}
          key={item.to}
          size={mode === 'side' ? 'medium' : 'big'}
          forceWhiteBg={mode === 'page'}
        />
      ))}
      {link && name && withProfileImage && (
        <SideNavigationItem
          to={link}
          text={name}
          Content={<MyProfileImage noLink noScore size="medium" />}
          size={mode === 'side' ? 'medium' : 'big'}
          forceWhiteBg={mode === 'page'}
        />
      )}
    </Stack>
  );
};
