import Stack from '@mui/material/Stack';
import {QuestProp} from 'modules/oldQuests2/types';
import LoadingButton from '@mui/lab/LoadingButton';
import {useQuestActions} from './hook';

export const QuestPreviewActions = ({quest}: QuestProp) => {
  const {action, isAccepting, isRejecting} = useQuestActions(quest);
  if (!quest.timelines || !quest.timelines.length) return null;
  return (
    <Stack direction="row" spacing={1} sx={{justifyContent: 'center'}}>
      <LoadingButton
        loading={isAccepting}
        onClick={() => action(true)}
        size="small"
        variant="contained"
        sx={{backgroundColor: 'rgba(255, 255, 255, 0.4)'}}
      >
        {quest.design?.acceptTitle || 'Начать'}
      </LoadingButton>
      {quest.allowReject && (
        <LoadingButton
          loading={isRejecting}
          onClick={() => action(false)}
          size="small"
          variant="contained"
          sx={{backgroundColor: 'rgba(255, 255, 255, 0.4)'}}
        >
          {quest.design?.rejectTitle || 'Отклонить'}
        </LoadingButton>
      )}
    </Stack>
  );
};
