import {useCallback} from 'react';
import {useDialogView} from 'components/Dialog/context';

import {QuestPointProps} from '../types';
import {useSetPointReference} from './setReference.hook';

export const useContentPointPress = (props: QuestPointProps) => {
  const openFull = useDialogView('contents');
  const {
    canPress,
    setReference,
    referenceProccessing,
    questPoint: {originId: contentId, type},
  } = useSetPointReference(props);

  const onPress = useCallback(() => {
    if (contentId && openFull) {
      openFull(contentId);
      if (setReference) setReference(contentId);
    }
  }, [setReference, contentId, openFull]);

  return {
    onPress,
    isLoading: referenceProccessing,
    canPress: type === 'content' && canPress,
  };
};
