import {api} from 'api';
import {useMemo} from 'react';
import {Users} from 'api/generated/users-api';
import {getUserLink, getUserName} from 'modules/profile/utils';

export * from './useLogout';

export const useGetCurrentUser = () => {
  const user = api.endpoints.profileIndex.useQueryState(undefined, {selectFromResult: ({data}) => data?.account});
  return user;
};

export const useIsMe = (user?: Users | string) => {
  const me = api.endpoints.profileIndex.useQueryState(undefined, {selectFromResult: ({data}) => data?.account});
  return !!me && !!user ? me._id === (typeof user === 'object' ? user._id : user) : false;
};

export const useMyId = () => {
  const {myId} = api.endpoints.profileIndex.useQueryState(undefined, {
    selectFromResult: ({data}) => ({myId: data?.account._id}),
  });
  return myId;
};

export const useMyProfileInfo = () => {
  return api.endpoints.profileIndex.useQueryState(undefined, {
    selectFromResult: ({data}) => ({
      userId: data?.account._id,
      name: data?.account ? getUserName(data.account) : undefined,
      link: data?.account ? getUserLink(data.account) : undefined,
      gender: data?.account.gender,
    }),
  });
};

export const useMyStreamId = () => {
  const {streamId} = api.endpoints.profileIndex.useQueryState(undefined, {
    selectFromResult: ({data}) => ({streamId: data?.account.stats?.streamsId || []}),
  });
  return useMemo(() => [...streamId].sort(), [streamId]);
};
