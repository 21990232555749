import {Link} from 'react-router-dom';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import {Contents} from 'api/generated/users-api';

import {LazyImage} from 'components/ImageGallery';
import {getContentsPath} from 'modules/contents/utils';

export const ContentsPreviewsItem = ({item}: {item: Contents}) => {
  const background = `
      linear-gradient(256deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.5) 90%),
      rgba(0, 0, 0, 0.1)`;

  return (
    <Link to={getContentsPath(item._id as string)}>
      <Stack
        p={2}
        width={200}
        height={200}
        borderRadius={2}
        flex="0 0 200px"
        overflow="hidden"
        position="relative"
        justifyContent="space-between"
        boxShadow={theme => theme.customShadows.inset}
        sx={{
          background,
          '&:hover .contentImage': {
            transform: 'scale(1.1)',
          },
        }}
      >
        {item.fileId && (
          <Box
            position="absolute"
            top={0}
            left={0}
            width="100%"
            height="100%"
            zIndex={1}
            className="contentImage"
            sx={{transition: 'transform .3s'}}
          >
            <LazyImage fileId={item.fileId} width={300} ratio="1:1" alt={item.name || 'Материал'} />
            <Box position="absolute" top={0} left={0} width="100%" height="100%" zIndex={2} sx={{background}} />
          </Box>
        )}
        <Stack direction="row" spacing={1} mb={0.5} zIndex={2}>
          {(item.tagsSet || [])
            .filter(tagSet => tagSet?.tag?.name)
            .map(tagSet => (
              <Typography key={tagSet.tag?._id} variant="caption" color="white" sx={{opacity: '0.6'}} letterSpacing={0}>
                #{tagSet.tag?.name}
              </Typography>
            ))}
        </Stack>
        <Stack justifyContent="end" spacing={0.5} zIndex={2}>
          <Typography variant="h3" color="white">
            {item.name}
          </Typography>
          {item.description && (
            <Box maxHeight={36} overflow="hidden" textOverflow="ellipsis">
              <Typography variant="body2" color="white" sx={{opacity: 0.8, mt: 0.5}}>
                {item.description}
              </Typography>
            </Box>
          )}
        </Stack>
      </Stack>
    </Link>
  );
};
