import {PropsWithChildren} from 'react';
import {Navigate, Outlet} from 'react-router';

import {useSafeContext} from 'utils';
import {GroupContext} from 'modules/groups/context';

export const GroupLeaderGuard = ({children}: PropsWithChildren<{}>) => {
  const {isLeader, groupId} = useSafeContext(GroupContext);
  if (!isLeader) return <Navigate to={`/teams/${groupId}`} />;
  return children ? <>children</> : <Outlet />;
};
