import {atom} from 'jotai';
import {HashBlockProps} from './types';

// export const currentHashAtom = atomWithHash<string | undefined>('step', undefined, {replaceState: true});
export const currentHashAtom = atom<string | undefined>(undefined);
export const hashStore = atom<HashBlockProps[]>([]);
export const insertHashBlock = atom(undefined, (get, set, newItem: HashBlockProps) => {
  const current = get(hashStore);
  const index = current.findIndex(item => item.hash === newItem.hash);
  if (index === -1) set(hashStore, [...current, newItem]);
  else {
    current.splice(index, 1, newItem);
    set(hashStore, current);
  }
});
