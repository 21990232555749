import {useEffect} from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import {useAtomValue, useUpdateAtom} from 'jotai/utils';

import {api} from 'api';
import {useDialog} from 'components/Dialog';
import {LoadingBox} from 'components/LoadingBox';
import {MarkdownRender} from 'components/MarkdownRender';
import {closeHash, termsDialogAtom} from './atoms';

export const Agreement = () => {
  const [Dialog] = useDialog();
  const [get, {data, isSuccess, isLoading}] = api.endpoints.rootAgreement.useLazyQuery(undefined);

  const open = useAtomValue(termsDialogAtom);
  const close = useUpdateAtom(closeHash);
  useEffect(() => {
    if (open && !isSuccess) get();
  }, [open, isSuccess, get]);

  return (
    <Dialog open={open} title="Пользовательское соглашение" onClose={() => close(termsDialogAtom)}>
      <Card>
        <CardContent>
          {!data?.agreement && isLoading && <LoadingBox />}
          {data?.agreement && <MarkdownRender content={data.agreement || ''} />}
        </CardContent>
      </Card>
    </Dialog>
  );
};
