import {ImageList, Skeleton} from '@mui/material';
import {fill} from 'lodash';

export const PublicationGridSkeleton = ({width, limit = 12}: {width?: number; limit?: number}) => (
  <ImageList gap={1} variant="quilted" cols={3} rowHeight={width ? width / 3 : 'auto'}>
    {fill(Array(limit), '').map((_, i) => (
      <Skeleton
        key={i}
        variant="rectangular"
        width="100%"
        height="200px"
        animation="wave"
        sx={{
          gridColumnEnd: 'span 1',
          gridRowEnd: 'span 1',
        }}
      />
    ))}
  </ImageList>
);
