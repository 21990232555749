import {api} from 'api';
import {omit} from 'lodash';
import {parse} from 'date-fns';
import {FormikHelpers} from 'formik';

import {GoalDto, Goals} from 'api/generated/users-api';
import {GoalInput} from './types';
import {useSnackbar} from 'notistack';

export const useGetGoalTagsOptions = () => {
  const {tags} = api.endpoints.tagsIndex.useQuery(
    {type: 'personal_quality'},
    {
      selectFromResult: ({data}) => ({
        tags: (data || []).map(item => ({value: item._id as string, name: item.name})),
      }),
    }
  );

  return tags;
};

export const useGoalForm = (
  goalId?: string,
  close?: () => void,
  callback?: (goal: Goals) => void,
  isMyGoal?: boolean
) => {
  const {enqueueSnackbar} = useSnackbar();
  const [createGoal] = api.endpoints.goalsAdd.useMutation();
  const [updateGoal] = api.endpoints.goalUpdate.useMutation();
  const [cloneGoal] = api.endpoints.goalClone.useMutation();
  const {data: goalTypes = []} = api.endpoints.goalsTypes.useQuery();

  const goalTypesOptions = goalTypes.map(item => ({
    name: item.name,
    value: item._id as string,
    rewardAsAnalog: item.rewardAsAnalog,
    reward: item.reward,
  }));

  const onSubmit = async (data: GoalInput, {setSubmitting}: FormikHelpers<GoalInput>) => {
    const goalDto = omit(data, [
      'exactDate',
      'startDate',
      'showPeriodSettings',
      'showExactDate',
      'showStartDate',
      'isScheduleEnabled',
      'personalQualityTagsId',
    ]) as GoalDto;
    const goalType = goalTypes.find(item => item._id === data.goalTypeId);
    if (goalType) {
      if (goalType.reward && data.rewardValueInput)
        goalDto.rewardValue = {rewardId: goalType.reward._id as string, value: data.rewardValueInput};
      if (goalDto.schedule) goalDto.schedule = {...goalDto.schedule, isEnabled: data.isScheduleEnabled || false};
      if (data.startDate) {
        goalDto.realStartDate = parse(
          `${data.startDate.day}.${data.startDate.month}.${data.startDate.year}`,
          'dd.MM.yyyy',
          new Date()
        ).toISOString();
      }
      if (data.showExactDate && data.exactDate) {
        goalDto.planFinishDate = parse(
          `${data.exactDate.day}.${data.exactDate.month}.${data.exactDate.year}`,
          'dd.MM.yyyy',
          new Date()
        ).toISOString();
      }
      if (data.personalQualityTagsId)
        goalDto.personalQualityTagsId = data.personalQualityTagsId.map(item => `${item.value}`);
      (goalDto as any).rewardValueInput = undefined;

      let result: any = null;
      if (goalId && isMyGoal) result = await updateGoal({goalId, goalDto});
      else if (goalId && !isMyGoal) result = await cloneGoal({goalId, goalDto});
      else result = await createGoal({goalDto});
      if ('data' in result) {
        setSubmitting(false);
        enqueueSnackbar('Цель поставлена', {variant: 'success', autoHideDuration: 3000});
        if (callback && result.data._id) await callback(result.data);
        if (close) close();
      }
      if ('error' in result) {
        enqueueSnackbar(result.error?.data?.message || 'Произошла ошибка при сохранении даных', {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
    }
  };

  return {onSubmit, goalTypesOptions};
};
