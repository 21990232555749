import {memo} from 'react';
import Box from '@mui/system/Box';

import {api} from 'api';

import {QuestPointView} from 'modules/oldQuests2/components';
import {QuestPointProps, StageIdProp, StagePointProps, StagePointIdProp} from 'modules/oldQuests2/types';

import {SubQuestPoint} from './SubQuestPoint';
import {TaskQuestPoint} from './TaskStagePoint';
import {GoalQuestPoint} from './GoalQuestPoint';
import {ContentQuestPoint} from './ContentQuestPoint';
import {TimelineQuestPoint} from './TimelineQuestPoint';
import {PublicationQuestPoint} from './PublicationQuestPoint';
import {PaymentQuestPoint} from './PaymentQuestPoint/PaymentQuestPoint';
import {QuestSubscriptionsPoints} from './QuestSubscriptionsPoints';
import {useMyId} from 'modules/auth';

export const QuestPointContent = memo(
  ({
    index,
    Component,
    stagePoint,
    questPointData,
    points = [],
    lockedReason: parentLockedReason = [],
    collapsedAll,
    subscriptionsStages,
  }: QuestPointProps) => {
    const myId = useMyId();
    if (questPointData.timelineCheck.skipped) return null;
    if (questPointData.questPoint.options.payments && myId !== stagePoint?.userId) return null;

    const type = questPointData.questPoint?.type;
    const lockedReason = [...parentLockedReason, ...(questPointData.timelineCheck.reasons || [])];
    const isCompleted = ['completed', 'failed', 'checking'].includes(stagePoint?.status || '');
    const locked =
      'progress' in questPointData
        ? !questPointData.timelineCheck.available
        : !stagePoint || (!questPointData.timelineCheck.available && !isCompleted);
    const questPointProgress = 'progress' in questPointData ? questPointData.progress : undefined;
    const childProps: StagePointProps = {
      stagePoint,
      lockedReason,
      questPoint: questPointData,
      children: viewProps => (
        <Component
          type={type}
          index={index}
          status={questPointProgress ? undefined : stagePoint?.status}
          locked={locked}
          lockedReason={lockedReason}
          fileId={questPointData.fileId}
          name={questPointData.name || ''}
          options={questPointData.questPoint.options}
          {...viewProps}
        />
      ),
    };

    return !questPointData.timelineCheck.skipped ? (
      <Box>
        {type === 'quest' && <SubQuestPoint {...childProps} points={points} collapsedAll={collapsedAll} />}
        {type === 'timeline' && <TimelineQuestPoint {...childProps} points={points} />}
        {type === 'task' && <TaskQuestPoint {...childProps} />}
        {type === 'goal' && <GoalQuestPoint {...childProps} />}
        {type === 'content' && <ContentQuestPoint {...childProps} />}
        {type === 'publication' && <PublicationQuestPoint {...childProps} />}
        {type === 'payment' && <PaymentQuestPoint {...childProps} />}
        {subscriptionsStages && !!subscriptionsStages.length && (
          <QuestSubscriptionsPoints subscriptionsStages={subscriptionsStages} />
        )}
      </Box>
    ) : null;
  }
);

export const QuestPoint = memo(
  ({
    pointId,
    stageId,
    index,
    collapsedAll,
  }: StagePointIdProp & StageIdProp & {index?: number; collapsedAll?: boolean}) => {
    const {point} = api.endpoints.stagePointsIndex.useQueryState(
      {stageId},
      {
        selectFromResult: ({data = []}) => ({
          point: data.find(item => item.questPointData.questPoint._id === pointId),
        }),
      }
    );

    if (!point) return null;
    return (
      <QuestPointContent
        index={index}
        points={point.points}
        Component={QuestPointView}
        stagePoint={point.stagePoint}
        questPointData={point.questPointData}
        subscriptionsStages={point.subscriptionsStages}
        collapsedAll={collapsedAll}
      />
    );
  }
);
