import {FastField} from 'formik';
import {styled} from '@mui/system';
import {GREY, PRIMARY} from 'components/theme/palette';

export const StyledFastField = styled(FastField)(() => ({
  position: 'relative',
  '& .MuiInputBase-root': {
    height: '52px',
    marginTop: 0,
    padding: 0,
    fontSize: '16px',
    lineHeight: '24px',
    color: GREY[700],
    '&:hover:not(.Mui-disabled)::before, &.Mui-disabled::before': {
      borderBottom: 'none',
    },
    '&::before, &::after': {
      borderBottom: 'none',

      boxShadow: '0px 1px 0px #F2F4F7',
      bottom: '0',
    },
  },
  '& .MuiInput-underline:after': {
    borderBottom: '2px solid',
    borderBottomColor: PRIMARY[50],
  },
  '& .MuiInput-input': {
    padding: 0,
    // '&:not(.MuiInputBase-inputMultiline)': {
    //   paddingBottom: '14px',
    // },
  },
  '& .MuiInputLabel-root': {
    color: GREY[200],
    fontSize: '16px',
    lineHeight: '20px',
    transform: 'translate(0, 16px) scale(1)',
    '&.Mui-focused': {
      color: GREY[200],
    },
  },
  '& .MuiInputLabel-shrink': {
    fontSize: '12px',
    lineHeight: '12px',
    color: GREY[200],
    transform: 'translate(0, 0) scale(1)',
  },
  '& .MuiInputBase-multiline': {
    alignItems: 'flex-start',
    height: 'auto',
    minHeight: '36px',
    paddingBottom: '8px',
  },
  '& .MuiInput-input::placeholder': {
    color: GREY[200],
    fontSize: '16px',
    lineHeight: '24px',
  },
  '&[counter]': {
    marginBottom: '4px',
  },
  '&[counter] .MuiFormHelperText-root.Mui-error': {
    position: 'absolute',
    bottom: '-3px',
    transform: 'translateY(100%)',
  },
}));
