import {Box, Skeleton, Stack} from '@mui/material';
import {fill} from 'lodash';
import {CommentSkeleton} from './CommentSkeleton';

export const CommentsListSkeleton = ({limit = 3}: {limit?: number}) => (
  <Stack px={2} py={1} spacing={2} bgcolor="white">
    <Stack direction="row" alignItems="center" justifyContent="space-between">
      <Skeleton variant="text" animation="wave" width={140} height={17} sx={{transform: 'none'}} />
      <Skeleton variant="rectangular" animation="wave" width={44} height={32} sx={{borderRadius: 2}} />
    </Stack>
    <Box bgcolor="white">
      <Skeleton variant="rectangular" animation="wave" width="100%" height={42} sx={{borderRadius: 12}} />
    </Box>
    <Stack spacing={1}>
      {fill(Array(limit), '').map((_, i) => (
        <CommentSkeleton key={i} />
      ))}
    </Stack>
  </Stack>
);
