import plural from 'plural-ru';

import Chip from '@mui/material/Chip';
import {alpha} from '@mui/system';
import {chipClasses} from '@mui/material';

import isValid from 'date-fns/isValid';
import differenceInDays from 'date-fns/differenceInDays';
import differenceInHours from 'date-fns/differenceInHours';

import {ERROR, GREY} from 'components/theme/palette';
import {ReactComponent as BombIcon} from 'svg/icons/bombIcon.svg';
import {ReactComponent as FireIcon} from 'svg/icons/fireIcon.svg';

export const DeadlineBadge = ({deadline}: {deadline: Date; size?: 'small' | 'medium'}) => {
  if (!isValid(deadline)) return null;
  const today = new Date();
  const daysLeft = Math.abs(differenceInDays(deadline, today));
  if (daysLeft > 30) return null;
  const hoursLeft = Math.abs(differenceInHours(deadline, today));

  let color: string = GREY[200];
  let text = plural(daysLeft, '%d день', '%d дня', '%d дней');
  let Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>> | null = null;
  if (hoursLeft < 24) {
    color = ERROR.dark;
    Icon = BombIcon;
    text = plural(hoursLeft, '%d чвс', '%d часа', '%d часов');
  } else if (daysLeft < 3) {
    color = '#DC6803';
    Icon = FireIcon;
  }

  return (
    <Chip
      size="small"
      sx={{
        backgroundColor: 'white',
        border: '1px solid',
        borderColor: alpha(color, 0.2),
        [`&.${chipClasses.sizeSmall}`]: {
          height: 16,
        },
        [`& .${chipClasses.labelSmall}`]: {
          color,
          px: 0.75,
          typography: 'subtitle1',
        },
      }}
      icon={Icon ? <Icon width={16} height={16} /> : undefined}
      label={text}
    />
  );
};

export default DeadlineBadge;
