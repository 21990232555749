import {Skeleton, Stack} from '@mui/material';

export const TaskItemSkeleton = () => (
  <Stack py={2} px={2.5} bgcolor="white">
    <Skeleton variant="text" animation="wave" width="100%" height={18} sx={{transform: 'none'}} />
    <Stack my={2} spacing={1}>
      <Skeleton variant="text" animation="wave" width="100%" height={14} sx={{transform: 'none'}} />
      <Skeleton variant="text" animation="wave" width="100%" height={14} sx={{transform: 'none'}} />
      <Skeleton variant="text" animation="wave" width="50%" height={14} sx={{transform: 'none'}} />
    </Stack>
    <Stack direction="row" mb={2} spacing={0.5}>
      <Skeleton variant="rectangular" animation="wave" width={80} height={18} sx={{borderRadius: 12}} />
      <Skeleton variant="text" animation="wave" width={120} height={18} sx={{transform: 'none'}} />
    </Stack>
    <Skeleton variant="rectangular" animation="wave" width="100%" height={210} />
  </Stack>
);
