import {FieldProps} from 'formik';
import {styled} from '@mui/system';
import {CheckboxWithLabel} from 'formik-mui';

import {CommonProps} from '../types';
import {PRIMARY} from 'components/theme/palette';
import {Switch, FormControlLabel} from '@mui/material';

const StyledFormControlLabel = styled(FormControlLabel)(() => ({
  padding: '4px 0',
  marginLeft: 0,
  marginRight: 0,
  width: '100%',
  justifyContent: 'space-between',
}));

export const StyledSwitch = styled(Switch)(() => ({
  width: 36,
  height: 24,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 1,
    '&.Mui-checked': {
      color: '#fff',
      transform: 'translateX(12px)',
      '& + .MuiSwitch-track': {
        opacity: 1,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
  },
  '& .MuiSwitch-track': {
    backgroundColor: PRIMARY[10],
    borderRadius: 36,
    opacity: 1,
  },
  '& .MuiSwitch-thumb': {
    boxShadow: 'none',
    width: 16,
    height: 16,
    margin: 3,
  },
}));

export const CheckboxField = ({label, ...props}: FieldProps & CommonProps) => (
  <CheckboxWithLabel {...props} field={{...props.field, value: Boolean(props.field.value)}} Label={{label}} />
);

export const ToggleField = ({label, field}: FieldProps & CommonProps) => {
  return (
    <StyledFormControlLabel
      control={<StyledSwitch {...field} defaultChecked={Boolean(field.value)} />}
      labelPlacement="start"
      label={label}
    />
  );
};

export default CheckboxField;
