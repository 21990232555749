import Box from '@mui/material/Box';

import {StageHeader} from '../StageHeader';
import {StagePointList} from '../StagePointsContainers';

import {StageComponent} from '../../types';
import {BottomButtonsContainer} from '../StagePointsContainers';

export const DefaultStageVisual: StageComponent = ({stageId, Header, hideHeader = false, inline = false}) => (
  <Box height="100%" bgcolor="white" sx={{borderRadius: 2}}>
    {!hideHeader && !Header && <StageHeader stageId={stageId} showProgress showDeadline showStatus />}
    {!hideHeader && !!Header && Header}
    <Box py={1} sx={{borderRadius: 4}} bgcolor="white" position="relative" zIndex={10} mt={-2}>
      <StagePointList stageId={stageId} containerName="default" componentName="DefaultStagePoint" withDivider />
    </Box>

    <BottomButtonsContainer stageId={stageId} componentName="ButtonStagePoint" inline={inline} />
  </Box>
);
