// import {styled} from '@mui/system';
import {Box, Typography} from '@mui/material';
import {getYear, addMonths, addYears, differenceInMonths} from 'date-fns';

import {GREY} from 'components/theme/palette';
import SegmentedControl from 'components/SegmentedControl';
// import {ReactComponent as EditIcon} from 'svg/icons/editIcon.svg';
import {formatDate} from 'utils/dates';

const today = new Date();
const year = getYear(today);

const buttons = [
  {value: 'month', content: 'Месяц', ariaLabel: 'Месяц'},
  {value: 'year', content: 'Год', ariaLabel: 'Год'},
  {value: '3-years', content: '3 Года', ariaLabel: '3 Года'},
  {value: '10-years', content: '10 Лет', ariaLabel: '10 Лет'},
];

const getValue = (value: Date) => {
  const valueYear = getYear(value);
  if (Math.abs(differenceInMonths(value, today)) === 1) {
    return 'month';
  } else if (valueYear - year === 1) {
    return 'year';
  } else if (valueYear - year === 3) {
    return '3-years';
  } else if (valueYear - year === 10) {
    return '10-years';
  }
};

// const StyledEditIcon = styled(EditIcon)(() => ({
//   width: 30,
//   height: 30,
//   '& path': {
//     stroke: GREY[200],
//   },
// }));

export const DateSelectButtons = ({value, setValue}: {value: Date; setValue: (newValue: any) => void}) => {
  // TO NOTE: It is made just for the markup
  const calculatedValue = getValue(value);
  return (
    <>
      <Box flexGrow={1}>
        <SegmentedControl
          fullWidth
          buttons={buttons}
          value={calculatedValue}
          onChange={value => {
            switch (value) {
              case 'month':
                setValue(addMonths(today, 1));
                break;
              case 'year':
                setValue(addYears(today, 1));
                break;
              case '3-years':
                setValue(addYears(today, 3));
                break;
              case '10-years':
                setValue(addYears(today, 10));
                break;
            }
          }}
        />
        <Box display="flex" alignItems="center" mt={2}>
          <Box display="inline-flex" alignItems="center">
            <Typography variant="body2" sx={{color: GREY[200]}}>
              Цель будет завершена&nbsp;
            </Typography>
            <Typography variant="body2" sx={{color: GREY[700]}}>
              {formatDate(value)}
            </Typography>
          </Box>
          {/* <IconButton sx={{padding: 0}}>
            <StyledEditIcon width="30" height="30" />
          </IconButton> */}
        </Box>
      </Box>
    </>
  );
};

export default DateSelectButtons;
