import {useMemo} from 'react';
import {To, useNavigate} from 'react-router';
import {focusAtom} from 'jotai/optics';
import {useAtomValue} from 'jotai/utils';

import {commentsStatsAtom, scopeAtom} from 'modules/social/store';
import {CommonCommentsProps} from 'modules/social/comments/types';
import {SocialButton} from 'components/SocialButton';

import {ReactComponent as CommentIcon} from 'svg/icons/commentIcon.svg';

export type CommentButtonProps = CommonCommentsProps & {
  to?: To;
  initialCount?: number;
};

export const CommentButton = ({to}: CommentButtonProps) => {
  const navigate = useNavigate();
  const scope = useAtomValue(scopeAtom, Symbol.for('social-keys'));
  const commentsAtom = useMemo(
    () => focusAtom(commentsStatsAtom, o => o.prop(scope).optional().prop('count')),
    [scope]
  );
  const count = useAtomValue(commentsAtom);

  return <SocialButton icon={CommentIcon} onClick={to ? () => navigate(to) : () => {}} text={count} />;
};

export default CommentButton;
