import {Skeleton, Stack} from '@mui/material';
import {fill} from 'lodash';

export const QuestPointContentSkeleton = () => (
  <Stack spacing={1}>
    {fill(Array(7), '').map((_, i) => (
      <Stack key={i} direction="row" alignItems="center" spacing={1}>
        <Skeleton variant="rectangular" width={48} height={48} sx={{borderRadius: 2}} />
        <Skeleton variant="text" animation="wave" width="65%" height={15} sx={{transform: 'none'}} />
      </Stack>
    ))}
  </Stack>
);
