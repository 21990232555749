import createStore from 'teaful';
import {Publications} from 'api/generated/users-api';

export const {useStore: usePostStore, getStore} = createStore({}, () => {});
export const addPosts = (posts: Publications[]) => {
  posts.forEach(item => {
    const key = item._id as string;
    const setStoreField = getStore[key]()[1];
    setStoreField(item);
  });
};
export const removePosts = () => {
  getStore()[1]({});
};
