import {ReactNode} from 'react';
import {Link} from 'react-router-dom';
import {Stack, Typography, Box} from '@mui/material';

import {GroupProfileImage} from 'modules/groups';
import {NPSBadge} from 'components/Layout';
import {GREY} from 'components/theme/palette';
import {RatingPosition} from 'components/RatingPosition';

import {Groups} from 'api/generated/users-api';
import {ReactComponent as UserIcon} from 'svg/icons/userIcon.svg';

import {Disclaimer} from '../Disclaimer';

export type TeamItemProps = {group?: Groups; action?: ReactNode; showPosition?: boolean};

export const TeamItem = ({group, action, showPosition}: TeamItemProps) => {
  if (!group) return null;
  return (
    <Stack direction="row" gap={1.5} py={0.5} px={2} sx={{backgroundColor: 'white'}} alignItems="center">
      {showPosition && <RatingPosition position={group.ratingPosition?.byNPS} />}
      <GroupProfileImage group={group} size="big" />
      <Stack
        flexGrow={1}
        spacing={0.5}
        display="flex"
        alignSelf="stretch"
        flexDirection="column"
        justifyContent="center"
        alignItems="flex-start"
      >
        <Box>
          <Typography variant="body2" sx={{fontWeight: 500}} component={Link} to={`/teams/${group._id}`}>
            {group.name}
          </Typography>
          {!!group.membersCount && (
            <Box display="flex" alignItems="center" gap={0.5}>
              <UserIcon />
              <Typography variant="subtitle1" sx={{color: GREY[200]}}>
                {group.membersCount}
              </Typography>
            </Box>
          )}
        </Box>
        <Disclaimer disclaimer={group.short_description} />
      </Stack>
      <Box display="flex" justifyContent="space-between" alignItems="center" height={48}>
        <NPSBadge value={group.stats?.NPS} />
        {action}
      </Box>
    </Stack>
  );
};

export default TeamItem;
