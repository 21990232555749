import _ from 'lodash/fp';
import {memo} from 'react';
import sortBy from 'lodash/sortBy';
import groupBy from 'lodash/groupBy';

import {api} from 'api';
import {Templates} from 'api/generated/users-api';

import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import {ScriptAnswersProps} from './types';
import {ActionAnswers} from './ActionAnswers';

export const ScriptAnswers = memo(({jobId, scriptId, onEdit, p = 2}: ScriptAnswersProps) => {
  const {structure} = api.endpoints.jobIndex.useQuery(
    {jobId},
    {
      selectFromResult: ({data}) => {
        if (!data || !data.structure) return {structure: null};
        const struct = data.structure;
        const groups: string[] = _.compose(
          _.map(_.get('_id')),
          _.filter(_.compose(_.equals(scriptId), _.get('scriptId')))
        )(struct.scriptsGroups);
        const actions = struct.scriptsActions.filter(item => groups.includes(item.scriptGroupId));

        return {
          structure: sortBy(
            actions.map(action => {
              const template = struct.templates.find(item => item._id === action.templateId) as Templates;
              const templateOptions = sortBy(
                struct.templatesOptions.filter(item => item.templateId === action.templateId),
                'positionNumber'
              );
              return {action, templateOptions, template};
            }),
            'positionNumber'
          ),
        };
      },
    }
  );

  const {answers} = api.endpoints.jobAnswersIndex.useQuery(
    {jobId},
    {
      selectFromResult: ({data}) => {
        if (!data || !structure) return {answers: []};
        const result = structure.map(action => {
          const answers = groupBy(
            data
              .filter(item => item.scriptActionId === action.action._id)
              .map(({templateOptionId, value, index}) => ({templateOptionId, value, index: index || 0})),
            'index'
          );
          return {answers, ...action};
        });
        return {answers: result};
      },
    }
  );

  return (
    <Stack spacing={2} component={Card} p={p}>
      {answers.map(item => (
        <ActionAnswers key={item.action._id} hideName={answers.length <= 1} {...item} />
      ))}
      {onEdit && (
        <Button onClick={onEdit} variant="contained" size="medium" fullWidth>
          Редактировать
        </Button>
      )}
    </Stack>
  );
});
