import {ReactNode} from 'react';
import {CssBaseline} from '@mui/material';
import {createTheme, ThemeProvider, StyledEngineProvider} from '@mui/material/styles';

import palette from './palette';
import componentsOverride from './overrides';
import {GlobalStyles} from './GlobalStyles';
import {customShadows} from './shadows';

// ----------------------------------------------------------------------

type ThemeConfigProps = {
  children: ReactNode;
};

export default function ThemeConfig({children}: ThemeConfigProps) {
  let theme = createTheme({palette: palette.light, customShadows});
  theme.components = componentsOverride(theme) as any;
  theme.shape.borderRadius = 4;
  theme.typography = {
    ...theme.typography,
    fontFamily: 'EuclidCircularA',
    h1: {
      fontSize: 24,
      lineHeight: '32px',
      fontWeight: 600,
      [theme.breakpoints.up('md')]: {
        fontSize: 32,
        lineHeight: '40px',
      },
    },
    h2: {
      fontSize: 20,
      fontWeight: 600,
      lineHeight: '24px',
      [theme.breakpoints.up('md')]: {
        fontSize: 24,
        lineHeight: '28px',
      },
    },
    h3: {
      fontSize: 17,
      fontWeight: 600,
      lineHeight: '24px',
      [theme.breakpoints.up('md')]: {
        fontSize: 17,
        lineHeight: '24px',
      },
    },
    h4: {
      fontSize: 16,
      fontWeight: 600,
      lineHeight: '20px',
      [theme.breakpoints.up('md')]: {
        fontSize: 16,
        lineHeight: '20px',
      },
    },
    body1: {
      fontSize: 16,
      lineHeight: '20px',
      [theme.breakpoints.up('md')]: {
        fontSize: 16,
        lineHeight: '20px',
      },
      letterSpacing: '0em',
      '& + &': {
        marginTop: theme.spacing(1),
      },
    },
    body2: {
      fontSize: 15,
      lineHeight: '18px',
    },
    button: {
      fontSize: 16,
      lineHeight: '20px',
      [theme.breakpoints.up('md')]: {},
    },
    subtitle1: {
      fontSize: 12,
      lineHeight: '12px',
      fontWeight: 'normal',
      [theme.breakpoints.up('md')]: {},
    },
    subtitle2: {
      fontSize: 10,
      lineHeight: '10px',
      fontWeight: 'normal',
      [theme.breakpoints.up('md')]: {},
    },
    caption: {
      fontSize: 10,
      letterSpacing: 1,
      lineHeight: '10px',
    },
  };

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <GlobalStyles />
        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  );
}
