import {lazy, Suspense} from 'react';
import {RouteObject} from 'react-router';

import IsExistsContainer from 'components/IsExistsContainer';
import {PublicationPageSkeleton, PublicationsPageSkeleton} from 'components/Skeleton';

import {useGetPublication} from './pages/Publication/hook';
import {PublicationContext} from './pages/Publication/context';

const MainPage = lazy(() => import('./pages/Main'));
const PublicationPage = lazy(() => import('./pages/Publication'));

export const router: RouteObject = {
  path: '/feed',
  children: [
    {
      index: true,
      element: (
        <Suspense fallback={<PublicationsPageSkeleton />}>
          <MainPage />
        </Suspense>
      ),
    },
    {
      path: ':postId',
      element: (
        <IsExistsContainer
          useFetch={useGetPublication}
          context={PublicationContext}
          LoadingComponent={<PublicationPageSkeleton />}
          errorMessage={{403: 'Доступ к публиции ограничен настройками приватности'}}
        >
          <Suspense fallback={<PublicationPageSkeleton />}>
            <PublicationPage />
          </Suspense>
        </IsExistsContainer>
      ),
    },
  ],
};

export default router;
