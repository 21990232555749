import {FC, memo} from 'react';

import {StagePointListProps} from '../../types';
import {QuestPointDistributor} from '../QuestPointBase';
import {useGetStagePointsByContainerName} from '../../hooks';
import {Box, Stack} from '@mui/material';

const Divider: FC<{px?: number}> = ({px = 2}) => (
  <Box px={px}>
    <Box width={1} height="1px" bgcolor="divider" />
  </Box>
);

export const StagePointList: FC<StagePointListProps> = memo(
  ({
    stageId,
    containerName,
    componentName,
    //
    spacing = 0,
    dividerPX = 2,
    horizontal = false,
    withDivider = false,
    pointShadow = false,
    hideUnavailablePoints = false,
  }: StagePointListProps) => {
    const points = useGetStagePointsByContainerName({stageId, containerName, hideUnavailablePoints});

    const Content = points.map((item, index) => (
      <Box
        mt={index > 0 && !horizontal ? spacing : 0}
        ml={horizontal ? spacing : 0}
        key={item.stagePointId}
        boxShadow={pointShadow ? theme => theme.customShadows.default : undefined}
      >
        <QuestPointDistributor {...item} stageId={stageId} componentName={componentName} />
        {withDivider && index < points.length - 1 && <Divider px={dividerPX} />}
      </Box>
    ));

    return (
      <>
        {!horizontal && <Box width={1}>{Content}</Box>}
        {horizontal && (
          <Box
            width={1}
            overflow="scroll"
            sx={{
              '-ms-overflow-style': 'none',
              'scrollbar-width': 'none',
              '&::-webkit-scrollbar': {
                display: 'none',
              },
            }}
          >
            <Stack direction="row">{Content}</Stack>
          </Box>
        )}
      </>
    );
  },
  (prev, next) => prev.stageId === next.stageId
);

// const styles = StyleSheet.create({
//   divider: {backgroundColor: BORDER_COLOR, height: 1, width: '100%'},
// });
