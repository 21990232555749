import {Box, Skeleton, Stack} from '@mui/material';
import {QuestItemListSkeleton, QuestPointsListSkeleton} from '../components';

export const QuestPageSkeleton = () => (
  <Stack bgcolor="white">
    <QuestItemListSkeleton />
    <Box px={2} pb={1}>
      <Skeleton variant="text" animation="wave" width={180} height={12} sx={{transform: 'none'}} />
    </Box>
    <Box p={2}>
      <QuestPointsListSkeleton />
    </Box>
  </Stack>
);
