import {FC} from 'react';
import {api} from 'api';
import {Box} from '@mui/material';

import {StageIdProp} from '../../types';

export const StageProgressBar: FC<StageIdProp> = ({stageId}) => {
  const {progress} = api.endpoints.stageIndex.useQuery(
    {stageId, partial: true},
    {selectFromResult: ({data}) => ({progress: data?.stage.localProgress})}
  );

  if (!progress || !progress.requiredPointsTotal) return null;

  return (
    <Box position="relative">
      <Box height={4} borderRadius={2} width={1} sx={{opacity: 0.6, backgroundColor: 'rgba(255, 255, 255, 0.5)'}} />
      <Box
        position="absolute"
        top={0}
        left={0}
        height={4}
        borderRadius={2}
        bgcolor="white"
        width={(progress.requiredPointsCompleted || 0) / progress.requiredPointsTotal}
      />
    </Box>
  );
};
