import plural from 'plural-ru';
import numeral from 'numeral';
import {skipToken} from '@reduxjs/toolkit/query';
import {Typography, Stack} from '@mui/material';

import {api, QuestsPointOptionsSubscriptions} from 'api/generated/users-api';

import {useIsMe} from 'modules/auth';
import {StagePointProps} from 'modules/oldQuests2/types';
import {StagePointPaymentRefreshWatcher} from 'modules/oldQuests2/components/StagePointPaymentRefreshWatcher';
import {WidgetBox} from 'components/EntityWidgets/WidgetBox';

import {ReactComponent as CompletedIcon} from 'svg/statuses/completed.svg';
import {ReactComponent as RejectedIcon} from 'svg/statuses/rejected.svg';

import {PaymentStatus} from './PaymentStatus';
import {MarkdownRender} from 'components/MarkdownRender';
import {ChosePaymentService} from './ChosePaymentService';

const getPluralInterval = (value: number, interval: QuestsPointOptionsSubscriptions['emitInterval']) => {
  switch (interval) {
    case 'minute':
      return plural(value, 'минуту', 'минуты', 'минут');
    case 'hour':
      return plural(value, 'час', 'часа', 'часов');
    case 'day':
      return plural(value, 'день', 'дня', 'дней');
    case 'week':
      return plural(value, 'неделю', 'недели', 'недель');
    case 'month':
      return plural(value, 'месяц', 'месяца', 'месяцев');
    case 'year':
      return plural(value, 'год', 'года', 'лет');
    default:
      return '';
  }
};

const ActivePaymentQuestPoint: React.FC<
  Omit<StagePointProps, 'stagePoint'> & Required<Pick<StagePointProps, 'stagePoint'>>
> = ({children, stagePoint, questPoint}) => {
  const {design, payments, subscriptions} = questPoint.questPoint.options;
  const name = design?.title || questPoint.questPoint.name;
  const [cost, symbol] = questPoint.name?.split(':')?.[1].split(',') || ['', ''];
  const {data: payment} = api.endpoints.paymentIndex.useQuery(
    stagePoint.referenceId ? {paymentId: stagePoint.referenceId} : skipToken
  );
  let period = '';
  if (subscriptions?.isActive) {
    period = `в ${subscriptions.emitPeriod > 1 ? `${subscriptions.emitPeriod} ` : ''}${getPluralInterval(
      subscriptions.emitPeriod,
      subscriptions.emitInterval
    )}`;
  }

  const Content = (
    <WidgetBox>
      <Stack spacing={0.5} mb={1}>
        <Typography variant="subtitle1" color="grey.200">
          Платеж
        </Typography>
        {name && <Typography variant="h3">{name}</Typography>}
      </Stack>
      <Stack spacing={1.5}>
        {design?.description && <MarkdownRender content={design.description} />}
        <Stack direction="row" alignItems="center" spacing={1}>
          <Typography variant="h1">
            {numeral(cost).format('0,0')}
            {symbol.trim()}
            {period ? ` ${period}` : ''}
          </Typography>
          {payment?.status === 'paid' && <CompletedIcon />}
          {['declined', 'error'].includes(payment?.status || '') && <RejectedIcon />}
        </Stack>
        {stagePoint.referenceId && payment && stagePoint?.stageId && (
          <PaymentStatus
            payment={payment}
            errorMessage={payments?.errorMessage}
            successMessage={payments?.successMessage}
          />
        )}
        {payment?.status !== 'paid' && (
          <ChosePaymentService
            questPoint={questPoint}
            stagePoint={stagePoint}
            payment={stagePoint.referenceId && payment ? payment : undefined}
          />
        )}
      </Stack>
    </WidgetBox>
  );

  return (
    <>
      <StagePointPaymentRefreshWatcher />
      {children({
        Content,
        name: name || 'Оплата',
        fileId: questPoint.fileId || design?.fileId || '',
      })}
    </>
  );
};

export const PaymentQuestPoint = ({children, stagePoint, questPoint}: StagePointProps) => {
  const isMy = useIsMe(stagePoint?.userId);
  if (!stagePoint || !isMy) return null;
  return <ActivePaymentQuestPoint questPoint={questPoint} stagePoint={stagePoint} children={children} />;
};
