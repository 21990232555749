import React, {FC} from 'react';

import {SubcriptionsListProps} from '../../types';
import {SubscriptionContainer} from '../SubscriptionContainer';
import {useGetSubscriptionsByContainerName} from '../../hooks';

export const SubscriptionsList: FC<SubcriptionsListProps> = ({
  stageId,
  containerName,
  //
  // onLayout,
  // spacing = 0,
}) => {
  const points = useGetSubscriptionsByContainerName({stageId, containerName});

  return (
    <>
      {points.map(item => (
        <SubscriptionContainer key={item.stagePointId} stagePointId={item.stagePointId as string} stageId={stageId} />
      ))}
    </>
  );
};
