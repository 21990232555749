import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import {Payments, QuestsPointOptionsPayments} from 'api/generated/users-api';

export const PaymentStatus = ({
  payment,
  errorMessage,
  successMessage,
}: {
  payment: Payments;
} & Pick<QuestsPointOptionsPayments, 'successMessage' | 'errorMessage'>) => {
  const isHaveUrl = Boolean(payment?.result?.paymentUrl);
  const {status} = payment;

  if (['returned'].includes(status)) return null;
  let state = 'success';
  if (['declined', 'error'].includes(status) || (payment && !isHaveUrl)) state = 'error';
  else if (['wait_payment', 'new'].includes(status) && isHaveUrl) state = 'warning';

  return (
    <Box p={1} borderRadius={2} bgcolor={`${state}.lighter`}>
      <Typography variant="body2" color={`${state}.dark`}>
        {state === 'success' && <>{successMessage || 'Оплата произведена успешно!'}</>}
        {state === 'error' && <>{errorMessage || 'Во время проведения платежа произошла ошибка'}</>}
        {state === 'warning' && 'Платёж создан, нажмите кнопку ниже для перехода на страницу оплаты'}
      </Typography>
    </Box>
  );
};
