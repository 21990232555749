import {useNavigate} from 'react-router';

import {AuthHeader, AuthInnerLayout} from 'modules/auth/components';
import {Login, ConfirmCode, useConfirmationSettings} from 'modules/auth/containers';
import {ReactComponent as LogoFull} from 'svg/logoFull.svg';

export const AuthLogin = () => {
  const [confirmationSettings, setSettings] = useConfirmationSettings();
  const navigate = useNavigate();
  const onFinish = () => navigate('/my-profile');

  return (
    <AuthInnerLayout>
      {!confirmationSettings && <Login onSuccess={setSettings} Header={<AuthHeader icon={<LogoFull />} />} />}
      {confirmationSettings && (
        <ConfirmCode
          {...confirmationSettings}
          onSuccess={onFinish}
          Header={
            <AuthHeader
              icon={<LogoFull />}
              title={confirmationSettings.loginType === 'phone' ? 'Введите код из СМС' : 'Введите код из письма'}
              subtitle={
                confirmationSettings.loginType === 'phone'
                  ? 'На указанный номер телефона отправлен SMS-код для входа в систему'
                  : 'На указанный email отправлен код для входа в систему'
              }
            />
          }
        />
      )}
    </AuthInnerLayout>
  );
};

export default AuthLogin;
