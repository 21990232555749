import {memo} from 'react';
import {Box, ButtonBase} from '@mui/material';

import {StagePointData} from 'api/generated/users-api';

import {StagePointComponent} from '../../types';
import {StagePointImage} from '../StagePointImage';
import {useGetStagePointByIdWithSelector} from '../../hooks';
import {isCompletedStatus, selectStagePointCustomOptions} from '../../utils';

export const selectDefaultStagePointData = (point: StagePointData) => ({
  stagePoint: {
    status: point.stagePoint?.status,
    referenceId: point.stagePoint?.referenceId,
    isCompleted: isCompletedStatus(point.stagePoint?.status),
  },
  questPoint: {
    ...selectStagePointCustomOptions(point),
    available: point.questPointData.timelineCheck.available,
    imageFileId: point.questPointData.questPoint.options.design?.fileId || point.questPointData.fileId,
  },
});

export const ThumbnailStagePoint: StagePointComponent = memo(({stagePointId, stageId, usePressOptions, type}) => {
  const {isLoading, canPress, onPress} = usePressOptions({stagePointId, stageId});
  const {
    questPoint: {available, imageFileId, hidePointOnDone},
    stagePoint: {status, isCompleted},
  } = useGetStagePointByIdWithSelector({stagePointId, stageId}, selectDefaultStagePointData);

  if (['goal', 'payment', 'widget'].includes(type)) return null;
  if (isCompleted && hidePointOnDone) return null;

  const Image = <StagePointImage isLoading={isLoading} status={status} available={available} fileId={imageFileId} />;
  if (!canPress) return <Box>{Image}</Box>;
  return (
    <ButtonBase onClick={onPress} disabled={!canPress || isLoading}>
      <Box>{Image}</Box>
    </ButtonBase>
  );
});

// const styles = StyleSheet.create({
//   unavailablePointImage: {
//     opacity: 0.3,
//   },
// });
