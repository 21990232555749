import * as _ from 'lodash/fp';
import {stringify} from 'query-string';
import React, {useContext} from 'react';
import {SerializedError} from '@reduxjs/toolkit';
import {FetchBaseQueryError} from '@reduxjs/toolkit/dist/query';

import {FileCropApiArg} from 'api/generated/files-api';
import {UsersRatingPosition} from 'api/generated/users-api';

export * from './yup';
export * from './types';
export * from './hooks';
export * from './isViewed';

export const getRowId = (row: any) => row._id;

export const useSafeContext = <T>(Context: React.Context<T>): NonNullable<T> => {
  const ctxValue = useContext(Context);
  if (!ctxValue) throw new Error('No context found');
  return ctxValue as NonNullable<T>;
};

export const filePath = (fileId: string, method?: 'stream' | 'download' | 'preview') =>
  `${process.env.REACT_APP_FILE_API_PATH}/file_${fileId}/${method || 'download'}`;

export const croppedImagePath = (fileId: string, params: Pick<FileCropApiArg, 'ratio' | 'maxWidth'>) => {
  return `${process.env.REACT_APP_FILE_API_PATH}/file_${fileId}/crop?${stringify(params)}`;
};

export const getRatioMultiplier = (ratio: FileCropApiArg['ratio']) =>
  _.compose((item: [number, number]) => _.divide(...item), _.map(_.parseInt(10)), _.split(':'))(ratio);

export const preventDefault = (e: React.MouseEvent) => e.preventDefault();

const colotsByPosition = [undefined, 'gold' as const, 'silver' as const, 'bronze' as const];
export const getNPSBadgeColor = (position?: UsersRatingPosition['byNPS']) => {
  if (!position) return undefined;
  return colotsByPosition[position];
};

export const defaultApiErrorMessage = (error: FetchBaseQueryError | SerializedError, defaultError?: string) =>
  (error as any).data.message || defaultError;

export default getRowId;
