// import {LoadingButton} from '@mui/lab';
import {useEffect, useState} from 'react';
import {useDropzone} from 'react-dropzone';
import {useSnackbar} from 'notistack';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';

import {filePath} from 'utils';
import {api, filesApi} from 'api';
import {Files} from 'api/generated/users-api';

import {DialogCropper} from 'components/Cropper';
// import {ReactComponent as TrashIcon} from 'svg/icons/trashIcon.svg';
// import {ERROR} from 'components/theme/palette';

export const UploadUserPhoto = () => {
  const [file, setFile] = useState<Files>();
  const [croppedFile, setCroppedFile] = useState<Files>();
  const {enqueueSnackbar} = useSnackbar();

  const {data} = api.endpoints.userPhotosProfilePhoto.useQuery();
  const [uploadFile, {isLoading: isUploading}] = filesApi.endpoints.rootUpload.useMutation();
  const [addUserPhoto] = api.endpoints.userPhotosAdd.useMutation();

  // const [deletePhoto, {isLoading: photoRemoving}] = api.endpoints.userPhotoDelete.useMutation();
  // const onDelete = () => {
  //   if (data) deletePhoto({photoId: data._id as string});
  // };

  const upload = async (file: File) => {
    const body = new FormData();
    body.append('file', file);
    const result = await uploadFile({body: body as any});
    if ('data' in result && result.data[0]) {
      setFile(result.data[0]);
    } else if ('error' in result) {
      enqueueSnackbar((result.error as any).data.message || 'Не удалось загрузить изображение', {
        variant: 'error',
        autoHideDuration: 2000,
      });
    }
  };

  const {getRootProps, getInputProps} = useDropzone({
    multiple: false,
    accept: 'image/*',
    onDrop: files => upload(files[0]),
  });

  useEffect(() => {
    if (file && croppedFile) {
      const updatePhoto = async () => {
        await addUserPhoto({userPhotoAdminDto: {fileId: file._id as string, croppedFileId: croppedFile._id as string}});
        setFile(undefined);
        setCroppedFile(undefined);
      };
      updatePhoto();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [croppedFile, file]);

  return (
    <>
      <DialogCropper file={file} onSuccess={setCroppedFile} isUploading={isUploading} />
      <Stack direction="row" gap={2} alignItems="center">
        <Avatar
          sx={{width: 64, height: 64, backgroundColor: '#F7ECFE'}}
          src={filePath(data?.croppedFileId as string, 'preview')}
        />
        <Stack flexGrow={1} spacing={1} direction="row">
          <Box {...getRootProps()} width="100%">
            <Button variant="contained" color="secondary" size="small" fullWidth>
              {data ? 'Сменить фото' : 'Добавить фото'}
            </Button>
            <input {...getInputProps()} />
          </Box>
          {/* {data && (
            <LoadingButton loading={photoRemoving} variant="contained" color="error" size="small" onClick={onDelete}>
              <TrashIcon color={ERROR.dark} />
            </LoadingButton>
          )} */}
        </Stack>
      </Stack>
    </>
  );
};
