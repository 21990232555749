import Box from '@mui/system/Box';
import Stack from '@mui/material/Stack';
import LinearProgress from '@mui/material/LinearProgress';
import {linearProgressClasses} from '@mui/material';
import {StageProgress} from 'api/generated/users-api';

export const QuestOverallProgress = ({
  progress,
  withAdditional,
}: {
  progress?: StageProgress;
  withAdditional?: boolean;
}) => {
  if (!progress) return null;
  const withAdditionalBar = withAdditional && !!progress.optionalPointsTotal;
  const requiredPercent = !progress.requiredPointsTotal
    ? 0
    : Math.round(((progress.requiredPointsCompleted || 0) / progress.requiredPointsTotal) * 100);
  const optionalPercent = !progress.optionalPointsTotal
    ? 0
    : Math.round(((progress.optionalPointsCompleted || 0) / progress.optionalPointsTotal) * 100);

  return (
    <Stack spacing={0.5} direction="row" sx={{position: 'relative', backgroundColor: 'white', width: 1 / 1}}>
      <Box sx={{position: 'relative', width: withAdditionalBar ? '80%' : '100%'}}>
        <LinearProgress
          variant="determinate"
          value={requiredPercent > 100 ? 100 : requiredPercent}
          sx={{
            height: '4px',
            [`&.${linearProgressClasses.colorPrimary}`]: {
              backgroundColor: 'grey.10',
            },
            [`& .${linearProgressClasses.bar}`]: {
              left: Boolean(requiredPercent) ? '' : '-1px',
              borderRadius: 0,
              backgroundColor: requiredPercent < 100 ? '#C577F9' : 'success.main',
            },
          }}
        />
      </Box>
      {withAdditionalBar && (
        <Box sx={{position: 'relative', width: '20%'}}>
          <LinearProgress
            variant="determinate"
            value={optionalPercent}
            sx={{
              height: '4px',
              [`&.${linearProgressClasses.colorPrimary}`]: {
                backgroundColor: 'grey.10',
              },
              [`& .${linearProgressClasses.bar}`]: {
                left: Boolean(optionalPercent) ? '' : '-1px',
                borderRadius: 0,
                backgroundColor: '#C577F9',
              },
            }}
          />
        </Box>
      )}
    </Stack>
  );
};
