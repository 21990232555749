import {fill} from 'lodash';
import {Box, Skeleton, Stack} from '@mui/material';
import {TaskItemSkeleton} from './TaskItemSkeleton';
import {TaskCompactItemSkeleton} from './TaskCompactItem';

export const TasksListSkeleton = ({compact, limit = 7}: {limit?: number; compact?: boolean}) => (
  <Stack>
    <Box bgcolor="white" px={2} py={1}>
      <Skeleton variant="text" animation="wave" width="25%" height={24} sx={{transform: 'none'}} />
    </Box>
    {fill(Array(limit), '').map((_, i) =>
      compact ? <TaskCompactItemSkeleton key={i} /> : <TaskItemSkeleton key={i} />
    )}
  </Stack>
);
