import {Skeleton, Stack} from '@mui/material';
import {RoundListContentSkeleton} from './RoundListContentSkeleton';

export const RoundListSkeleton = () => (
  <Stack spacing={2} px={2} py={1} bgcolor="white">
    <Skeleton variant="text" animation="wave" width={160} height={20} sx={{transform: 'none'}} />
    <Stack direction="row" spacing={1}>
      <RoundListContentSkeleton />
    </Stack>
  </Stack>
);
