import {useSnackbar} from 'notistack';
import {PropsWithChildren} from 'react';

import {SxProps} from '@mui/system';
import {Typography, Box, Stack, Button} from '@mui/material';

import {MutationDefinition} from '@reduxjs/toolkit/dist/query';
import {ApiEndpointMutation} from '@reduxjs/toolkit/dist/query/core/module';

import {api} from 'api';
import {useIsMe} from 'modules/auth';
import {ReactComponent as RestoreIcon} from 'svg/icons/restoreIcon.svg';
import {ReactComponent as AlertBadge} from 'svg/alertBadge.svg';

export type ExtractParams<Type> = Type extends ApiEndpointMutation<MutationDefinition<infer X, any, any, any, any>, any>
  ? X
  : never;

type PermittedMutations = 'commentSafeRestore' | 'publicationSafeRestore' | 'goalSafeRestore';

type RemovedEntryProps = {
  sx?: SxProps;
  direction?: 'row' | 'column';
  typography?: 'subtitle1' | 'body2';
  showBadge?: boolean;
};

type RestoreEntryProps<T extends PermittedMutations> = {
  authorId: string;
  mutationName: T;
  forbidRestore?: boolean;
  variant?: 'text' | 'contained';
  params: ExtractParams<typeof api.endpoints[T]>;
};

export function RestoreEntry<T extends PermittedMutations>({
  params,
  variant = 'text',
  mutationName,
}: RestoreEntryProps<T>) {
  const {enqueueSnackbar} = useSnackbar();
  const handler = api.endpoints[mutationName]?.useMutation();
  if (!handler) return null;
  const [restore, {isLoading}] = handler;
  const onRestore = async () => {
    const result = await restore(params as any);
    if ('error' in result) {
      const message =
        (result.error as any)?.data?.message ||
        `Не удалось восстановить ${mutationName === 'commentSafeRestore' ? 'коментарий' : 'публикацию'}`;
      enqueueSnackbar(message, {variant: 'error', autoHideDuration: 2000});
    }
  };

  return (
    <Button
      size="small"
      variant={variant}
      color={variant === 'text' ? 'primary' : 'secondary'}
      onClick={onRestore}
      sx={{
        gap: 0.5,
        transition: 'color .25s',
        '&:hover, &:hover > *': {color: variant === 'text' ? 'grey.700' : undefined},
        color: variant === 'text' ? 'grey.400' : undefined,
      }}
    >
      <Box component={RestoreIcon} sx={{color: variant === 'text' ? 'grey.400' : undefined}} />
      <span>{!isLoading ? 'Восстановить' : 'Восстанавливаем...'}</span>
    </Button>
  );
}

export function RemovedEntryWithRestore<T extends PermittedMutations>({
  forbidRestore = false,
  isRestored,
  props,
  ...params
}: RestoreEntryProps<T> & {props?: RemovedEntryProps} & {isRestored?: boolean}) {
  const isMe = useIsMe(params.authorId);
  if (!isRestored)
    return <RemovedEntry {...props}>{!forbidRestore && isMe && <RestoreEntry {...params} />}</RemovedEntry>;
  else
    return (
      <Stack width="100%" alignItems="center" p={2}>
        <Typography
          variant="body2"
          height={32}
          width="fit-content"
          lineHeight="32px"
          px={1}
          bgcolor="success.light"
          borderRadius={2}
        >
          Запись восстановлена
        </Typography>
      </Stack>
    );
}

export function RemovedEntry({
  children,
  sx,
  showBadge = false,
  direction = 'row',
  typography = 'body2',
}: PropsWithChildren<RemovedEntryProps>) {
  return (
    <Stack spacing={1} direction={direction} p={2} alignItems="center" justifyContent="center" bgcolor="white" sx={sx}>
      {showBadge && <AlertBadge />}
      <Box color="grey.200" sx={{typography}}>
        Запись удалена
      </Box>
      {children}
    </Stack>
  );
}
