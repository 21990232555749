import {createApi} from '@reduxjs/toolkit/query/react';
import {default as customBaseQuery} from '../queries/users';
export const api = createApi({
  reducerPath: 'users',
  baseQuery: customBaseQuery,
  tagTypes: [],
  endpoints: build => ({
    rootPolicy: build.query<RootPolicyApiResponse, RootPolicyApiArg>({
      query: () => ({url: `/policy`}),
    }),
    rootAgreement: build.query<RootAgreementApiResponse, RootAgreementApiArg>({
      query: () => ({url: `/agreement`}),
    }),
    rootIndex: build.query<RootIndexApiResponse, RootIndexApiArg>({
      query: () => ({url: `/`}),
    }),
    rootJsonDocs: build.query<RootJsonDocsApiResponse, RootJsonDocsApiArg>({
      query: () => ({url: `/docs.json`}),
    }),
    rootDocs: build.query<RootDocsApiResponse, RootDocsApiArg>({
      query: () => ({url: `/docs.html`}),
    }),
    rootSwagger: build.query<RootSwaggerApiResponse, RootSwaggerApiArg>({
      query: () => ({url: `/swagger.html`}),
    }),
    pixelIndex: build.query<PixelIndexApiResponse, PixelIndexApiArg>({
      query: () => ({url: `/1px.png`}),
    }),
    appLogout: build.mutation<AppLogoutApiResponse, AppLogoutApiArg>({
      query: () => ({url: `/api/logout`, method: 'POST'}),
    }),
    achievementsIndex: build.query<AchievementsIndexApiResponse, AchievementsIndexApiArg>({
      query: queryArg => ({
        url: `/api/achievements`,
        params: {
          streamId: queryArg.streamId,
          userId: queryArg.userId,
          groupId: queryArg.groupId,
          stageId: queryArg.stageId,
          rewardType: queryArg.rewardType,
        },
      }),
    }),
    paymentsIndex: build.query<PaymentsIndexApiResponse, PaymentsIndexApiArg>({
      query: queryArg => ({
        url: `/api/payments`,
        params: {type: queryArg['type']},
      }),
    }),
    paymentIndex: build.query<PaymentIndexApiResponse, PaymentIndexApiArg>({
      query: queryArg => ({
        url: `/api/payments/payment_${queryArg.paymentId}`,
      }),
    }),
    paymentRegister: build.mutation<PaymentRegisterApiResponse, PaymentRegisterApiArg>({
      query: queryArg => ({
        url: `/api/payments/payment_${queryArg.paymentId}/init`,
        method: 'POST',
        body: queryArg.registerPaymentDto,
      }),
    }),
    paymentRefresh: build.query<PaymentRefreshApiResponse, PaymentRefreshApiArg>({
      query: queryArg => ({
        url: `/api/payments/payment_${queryArg.paymentId}/refresh`,
      }),
    }),
    paymentsServicesIndex: build.query<PaymentsServicesIndexApiResponse, PaymentsServicesIndexApiArg>({
      query: queryArg => ({
        url: `/api/payments/services`,
        params: {type: queryArg['type']},
      }),
    }),
    messageIndex: build.query<MessageIndexApiResponse, MessageIndexApiArg>({
      query: queryArg => ({
        url: `/api/messages/message_${queryArg.messageId}`,
      }),
    }),
    reactionsIndex: build.query<ReactionsIndexApiResponse, ReactionsIndexApiArg>({
      query: queryArg => ({
        url: `/api/reactions`,
        params: {
          referenceName: queryArg.referenceName,
          referenceId: queryArg.referenceId,
          offset: queryArg.offset,
          limit: queryArg.limit,
        },
      }),
    }),
    reactionsAdd: build.mutation<ReactionsAddApiResponse, ReactionsAddApiArg>({
      query: queryArg => ({
        url: `/api/reactions`,
        method: 'POST',
        body: queryArg.reactionDto,
        params: {
          referenceName: queryArg.referenceName,
          referenceId: queryArg.referenceId,
        },
      }),
    }),
    reactionsBlocksIndex: build.query<ReactionsBlocksIndexApiResponse, ReactionsBlocksIndexApiArg>({
      query: () => ({url: `/api/reactions/blocks`}),
    }),
    reactionIndex: build.query<ReactionIndexApiResponse, ReactionIndexApiArg>({
      query: queryArg => ({
        url: `/api/reactions/reaction_${queryArg.reactionId}`,
      }),
    }),
    reactionUpdate: build.mutation<ReactionUpdateApiResponse, ReactionUpdateApiArg>({
      query: queryArg => ({
        url: `/api/reactions/reaction_${queryArg.reactionId}`,
        method: 'PATCH',
        body: queryArg.reactionPatchDto,
      }),
    }),
    reactionDelete: build.mutation<ReactionDeleteApiResponse, ReactionDeleteApiArg>({
      query: queryArg => ({
        url: `/api/reactions/reaction_${queryArg.reactionId}`,
        method: 'DELETE',
      }),
    }),
    reactionStats: build.query<ReactionStatsApiResponse, ReactionStatsApiArg>({
      query: queryArg => ({
        url: `/api/reactions/reaction_${queryArg.reactionId}/stats`,
      }),
    }),
    viewsIndex: build.query<ViewsIndexApiResponse, ViewsIndexApiArg>({
      query: queryArg => ({
        url: `/api/views`,
        params: {
          referenceName: queryArg.referenceName,
          referenceId: queryArg.referenceId,
        },
      }),
    }),
    viewsSetViewed: build.mutation<ViewsSetViewedApiResponse, ViewsSetViewedApiArg>({
      query: queryArg => ({
        url: `/api/views`,
        method: 'POST',
        params: {
          referenceName: queryArg.referenceName,
          referenceId: queryArg.referenceId,
        },
      }),
    }),
    stagePointsIndex: build.query<StagePointsIndexApiResponse, StagePointsIndexApiArg>({
      query: queryArg => ({
        url: `/api/stages/points`,
        params: {
          status: queryArg.status,
          stageId: queryArg.stageId,
          partial: queryArg.partial,
        },
      }),
    }),
    stagePointsIds: build.query<StagePointsIdsApiResponse, StagePointsIdsApiArg>({
      query: queryArg => ({
        url: `/api/stages/points/ids`,
        params: {status: queryArg.status, stageId: queryArg.stageId},
      }),
    }),
    stagePointIndex: build.query<StagePointIndexApiResponse, StagePointIndexApiArg>({
      query: queryArg => ({
        url: `/api/stages/points/point_${queryArg.pointId}`,
        params: {partial: queryArg.partial},
      }),
    }),
    stagePointSetReference: build.mutation<StagePointSetReferenceApiResponse, StagePointSetReferenceApiArg>({
      query: queryArg => ({
        url: `/api/stages/points/point_${queryArg.pointId}/setReference`,
        method: 'POST',
        body: queryArg.setReferenceDto,
        params: {partial: queryArg.partial},
      }),
    }),
    stagePointClearReference: build.mutation<StagePointClearReferenceApiResponse, StagePointClearReferenceApiArg>({
      query: queryArg => ({
        url: `/api/stages/points/point_${queryArg.pointId}/clearReference`,
        method: 'DELETE',
        params: {partial: queryArg.partial},
      }),
    }),
    stagePointActivateTimeline: build.mutation<StagePointActivateTimelineApiResponse, StagePointActivateTimelineApiArg>(
      {
        query: queryArg => ({
          url: `/api/stages/points/point_${queryArg.pointId}/activateTimeline`,
          method: 'POST',
          body: queryArg.activateTimelineDto,
          params: {partial: queryArg.partial},
        }),
      }
    ),
    stagePointShortcutData: build.query<StagePointShortcutDataApiResponse, StagePointShortcutDataApiArg>({
      query: queryArg => ({
        url: `/api/stages/points/point_${queryArg.pointId}/shortcutData`,
      }),
    }),
    stagePointPaymentData: build.mutation<StagePointPaymentDataApiResponse, StagePointPaymentDataApiArg>({
      query: queryArg => ({
        url: `/api/stages/points/point_${queryArg.pointId}/paymentData`,
        method: 'POST',
        body: queryArg.paymentBodyDto,
      }),
    }),
    stagePointsSubscriptionsIndex: build.query<
      StagePointsSubscriptionsIndexApiResponse,
      StagePointsSubscriptionsIndexApiArg
    >({
      query: queryArg => ({
        url: `/api/stages/points/point_${queryArg.pointId}/subscriptions`,
      }),
    }),
    stagePointsSubscriptionsAdd: build.mutation<
      StagePointsSubscriptionsAddApiResponse,
      StagePointsSubscriptionsAddApiArg
    >({
      query: queryArg => ({
        url: `/api/stages/points/point_${queryArg.pointId}/subscriptions`,
        method: 'POST',
      }),
    }),
    stagePointsSubscriptionsStages: build.query<
      StagePointsSubscriptionsStagesApiResponse,
      StagePointsSubscriptionsStagesApiArg
    >({
      query: queryArg => ({
        url: `/api/stages/points/point_${queryArg.pointId}/subscriptions/stages`,
        params: {
          offset: queryArg.offset,
          limit: queryArg.limit,
          skip_id: queryArg.skipId,
          _id: queryArg._id,
          sort: queryArg.sort,
          status: queryArg.status,
          questType: queryArg.questType,
          questId: queryArg.questId,
          usersId: queryArg.usersId,
          parentStageId: queryArg.parentStageId,
          timelineId: queryArg.timelineId,
        },
      }),
    }),
    stagePointsSubscriptionsStagesIds: build.query<
      StagePointsSubscriptionsStagesIdsApiResponse,
      StagePointsSubscriptionsStagesIdsApiArg
    >({
      query: queryArg => ({
        url: `/api/stages/points/point_${queryArg.pointId}/subscriptions/stages_ids`,
        params: {
          offset: queryArg.offset,
          limit: queryArg.limit,
          skip_id: queryArg.skipId,
          _id: queryArg._id,
          sort: queryArg.sort,
          status: queryArg.status,
          questType: queryArg.questType,
          questId: queryArg.questId,
          usersId: queryArg.usersId,
          parentStageId: queryArg.parentStageId,
          timelineId: queryArg.timelineId,
        },
      }),
    }),
    stageIndex: build.query<StageIndexApiResponse, StageIndexApiArg>({
      query: queryArg => ({
        url: `/api/stages/stage_${queryArg.stageId}`,
        params: {partial: queryArg.partial},
      }),
    }),
    stagesIndex: build.query<StagesIndexApiResponse, StagesIndexApiArg>({
      query: queryArg => ({
        url: `/api/stages`,
        params: {
          offset: queryArg.offset,
          limit: queryArg.limit,
          skip_id: queryArg.skipId,
          _id: queryArg._id,
          populates: queryArg.populates,
          sort: queryArg.sort,
          status: queryArg.status,
          questType: queryArg.questType,
          questId: queryArg.questId,
          usersId: queryArg.usersId,
          parentStageId: queryArg.parentStageId,
          timelineId: queryArg.timelineId,
        },
      }),
    }),
    stagesByTimeline: build.query<StagesByTimelineApiResponse, StagesByTimelineApiArg>({
      query: queryArg => ({
        url: `/api/stages/byTimeline`,
        params: {timelineId: queryArg.timelineId},
      }),
    }),
    questsIndex: build.query<QuestsIndexApiResponse, QuestsIndexApiArg>({
      query: queryArg => ({
        url: `/api/quests`,
        params: {
          offset: queryArg.offset,
          limit: queryArg.limit,
          populates: queryArg.populates,
          type: queryArg['type'],
          parentQuestId: queryArg.parentQuestId,
          tagsId: queryArg.tagsId,
          ignoreDate: queryArg.ignoreDate,
        },
      }),
    }),
    questsData: build.query<QuestsDataApiResponse, QuestsDataApiArg>({
      query: queryArg => ({
        url: `/api/quests/data`,
        params: {
          skip_id: queryArg.skipId,
          _id: queryArg._id,
          populates: queryArg.populates,
        },
      }),
    }),
    questsTags: build.query<QuestsTagsApiResponse, QuestsTagsApiArg>({
      query: queryArg => ({
        url: `/api/quests/tags`,
        params: {
          type: queryArg['type'],
          parentQuestId: queryArg.parentQuestId,
          tagsId: queryArg.tagsId,
          skip_id: queryArg.skipId,
          _id: queryArg._id,
          ignoreDate: queryArg.ignoreDate,
          tagType: queryArg.tagType,
        },
      }),
    }),
    questsUnreadedCount: build.query<QuestsUnreadedCountApiResponse, QuestsUnreadedCountApiArg>({
      query: queryArg => ({
        url: `/api/quests/unreadCount`,
        params: {
          type: queryArg['type'],
          parentQuestId: queryArg.parentQuestId,
          tagsId: queryArg.tagsId,
          ignoreDate: queryArg.ignoreDate,
        },
      }),
    }),
    questsCurrentSeasonProgress: build.query<QuestsCurrentSeasonProgressApiResponse, QuestsCurrentSeasonProgressApiArg>(
      {
        query: queryArg => ({
          url: `/api/quests/currentSeasonProgress`,
          params: {
            userId: queryArg.userId,
            timelineId: queryArg.timelineId,
            groupId: queryArg.groupId,
            streamId: queryArg.streamId,
          },
        }),
      }
    ),
    questIndex: build.query<QuestIndexApiResponse, QuestIndexApiArg>({
      query: queryArg => ({url: `/api/quests/quest_${queryArg.questId}`}),
    }),
    questAccept: build.mutation<QuestAcceptApiResponse, QuestAcceptApiArg>({
      query: queryArg => ({
        url: `/api/quests/quest_${queryArg.questId}/accept`,
        method: 'POST',
        body: queryArg.questActionDto,
        params: {partial: queryArg.partial},
      }),
    }),
    questReject: build.mutation<QuestRejectApiResponse, QuestRejectApiArg>({
      query: queryArg => ({
        url: `/api/quests/quest_${queryArg.questId}/reject`,
        method: 'POST',
        body: queryArg.questActionDto,
        params: {partial: queryArg.partial},
      }),
    }),
    questUserProgress: build.query<QuestUserProgressApiResponse, QuestUserProgressApiArg>({
      query: queryArg => ({
        url: `/api/quests/quest_${queryArg.questId}/progress`,
        params: {
          userId: queryArg.userId,
          timelineId: queryArg.timelineId,
          groupId: queryArg.groupId,
          streamId: queryArg.streamId,
        },
      }),
    }),
    questPointsIndex: build.query<QuestPointsIndexApiResponse, QuestPointsIndexApiArg>({
      query: queryArg => ({
        url: `/api/quests/quest_${queryArg.questId}/points`,
      }),
    }),
    questPointsIds: build.query<QuestPointsIdsApiResponse, QuestPointsIdsApiArg>({
      query: queryArg => ({
        url: `/api/quests/quest_${queryArg.questId}/points/ids`,
      }),
    }),
    questPointsStats: build.query<QuestPointsStatsApiResponse, QuestPointsStatsApiArg>({
      query: queryArg => ({
        url: `/api/quests/quest_${queryArg.questId}/points/stats`,
        params: {
          userId: queryArg.userId,
          timelineId: queryArg.timelineId,
          groupId: queryArg.groupId,
          streamId: queryArg.streamId,
        },
      }),
    }),
    notificationsIndex: build.query<NotificationsIndexApiResponse, NotificationsIndexApiArg>({
      query: queryArg => ({
        url: `/api/notifications`,
        params: {
          offset: queryArg.offset,
          limit: queryArg.limit,
          unseenOnly: queryArg.unseenOnly,
        },
      }),
    }),
    notificationsUnreadedCount: build.query<NotificationsUnreadedCountApiResponse, NotificationsUnreadedCountApiArg>({
      query: () => ({url: `/api/notifications/unreadedCount`}),
    }),
    notificationsReadAll: build.mutation<NotificationsReadAllApiResponse, NotificationsReadAllApiArg>({
      query: () => ({url: `/api/notifications/readAll`, method: 'POST'}),
    }),
    notificationIndex: build.query<NotificationIndexApiResponse, NotificationIndexApiArg>({
      query: queryArg => ({
        url: `/api/notifications/notification_${queryArg.notificationId}`,
      }),
    }),
    notificationMarkRead: build.mutation<NotificationMarkReadApiResponse, NotificationMarkReadApiArg>({
      query: queryArg => ({
        url: `/api/notifications/notification_${queryArg.notificationId}/read`,
        method: 'POST',
      }),
    }),
    widgetsIndex: build.query<WidgetsIndexApiResponse, WidgetsIndexApiArg>({
      query: queryArg => ({
        url: `/api/widgets`,
        params: {userId: queryArg.userId, type: queryArg['type']},
      }),
    }),
    widgetsInfo: build.query<WidgetsInfoApiResponse, WidgetsInfoApiArg>({
      query: queryArg => ({
        url: `/api/widgets/info`,
        params: {
          usersId: queryArg.usersId,
          groupsId: queryArg.groupsId,
          groupBy: queryArg.groupBy,
          precision: queryArg.precision,
          timelinesId: queryArg.timelinesId,
          stagesId: queryArg.stagesId,
          stagesPointsId: queryArg.stagesPointsId,
          withSubStages: queryArg.withSubStages,
          withParentStages: queryArg.withParentStages,
          _id: queryArg._id,
        },
      }),
    }),
    widgetIndex: build.query<WidgetIndexApiResponse, WidgetIndexApiArg>({
      query: queryArg => ({
        url: `/api/widgets/widget_${queryArg.widgetId}`,
      }),
    }),
    widgetInfo: build.query<WidgetInfoApiResponse, WidgetInfoApiArg>({
      query: queryArg => ({
        url: `/api/widgets/widget_${queryArg.widgetId}/info`,
        params: {
          usersId: queryArg.usersId,
          groupsId: queryArg.groupsId,
          groupBy: queryArg.groupBy,
          precision: queryArg.precision,
          timelinesId: queryArg.timelinesId,
          stagesId: queryArg.stagesId,
          stagesPointsId: queryArg.stagesPointsId,
          withSubStages: queryArg.withSubStages,
          withParentStages: queryArg.withParentStages,
        },
      }),
    }),
    widgetsVisualsIndex: build.query<WidgetsVisualsIndexApiResponse, WidgetsVisualsIndexApiArg>({
      query: queryArg => ({
        url: `/api/widgets/visuals`,
        params: {
          containerName: queryArg.containerName,
          withStagesSubscriptions: queryArg.withStagesSubscriptions,
          timelinesId: queryArg.timelinesId,
          stagesId: queryArg.stagesId,
          stagesPointsId: queryArg.stagesPointsId,
          withSubStages: queryArg.withSubStages,
          withParentStages: queryArg.withParentStages,
          populates: queryArg.populates,
          skip_id: queryArg.skipId,
          _id: queryArg._id,
        },
      }),
    }),
    settingsIndex: build.query<SettingsIndexApiResponse, SettingsIndexApiArg>({
      query: () => ({url: `/api/settings`}),
    }),
    collectorsIndex: build.query<CollectorsIndexApiResponse, CollectorsIndexApiArg>({
      query: queryArg => ({
        url: `/api/collectors`,
        params: {type: queryArg['type']},
      }),
    }),
    collectorValuesIndex: build.query<CollectorValuesIndexApiResponse, CollectorValuesIndexApiArg>({
      query: queryArg => ({
        url: `/api/collectors/values`,
        params: {
          collectorId: queryArg.collectorId,
          code: queryArg.code,
          userId: queryArg.userId,
        },
      }),
    }),
    collectorValuesByType: build.query<CollectorValuesByTypeApiResponse, CollectorValuesByTypeApiArg>({
      query: queryArg => ({
        url: `/api/collectors/values/byType`,
        params: {type: queryArg['type'], userId: queryArg.userId},
      }),
    }),
    tagsIndex: build.query<TagsIndexApiResponse, TagsIndexApiArg>({
      query: queryArg => ({
        url: `/api/tags`,
        params: {
          name: queryArg.name,
          type: queryArg['type'],
          skip_id: queryArg.skipId,
          _id: queryArg._id,
          countReference: queryArg.countReference,
        },
      }),
    }),
    groupsIndex: build.query<GroupsIndexApiResponse, GroupsIndexApiArg>({
      query: queryArg => ({
        url: `/api/groups`,
        params: {
          offset: queryArg.offset,
          limit: queryArg.limit,
          skip_id: queryArg.skipId,
          _id: queryArg._id,
          userId: queryArg.userId,
          streamId: queryArg.streamId,
          name: queryArg.name,
          sort: queryArg.sort,
        },
      }),
    }),
    groupInvitesIndex: build.query<GroupInvitesIndexApiResponse, GroupInvitesIndexApiArg>({
      query: queryArg => ({
        url: `/api/groups/invites`,
        params: {forMe: queryArg.forMe, fromMe: queryArg.fromMe},
      }),
    }),
    groupInvitesSend: build.mutation<GroupInvitesSendApiResponse, GroupInvitesSendApiArg>({
      query: queryArg => ({
        url: `/api/groups/invites`,
        method: 'POST',
        body: queryArg.groupInviteDto,
      }),
    }),
    groupInviteIndex: build.query<GroupInviteIndexApiResponse, GroupInviteIndexApiArg>({
      query: queryArg => ({
        url: `/api/groups/invites/invite_${queryArg.inviteId}`,
      }),
    }),
    groupInviteDeleteInvite: build.mutation<GroupInviteDeleteInviteApiResponse, GroupInviteDeleteInviteApiArg>({
      query: queryArg => ({
        url: `/api/groups/invites/invite_${queryArg.inviteId}`,
        method: 'DELETE',
      }),
    }),
    groupInviteAccept: build.mutation<GroupInviteAcceptApiResponse, GroupInviteAcceptApiArg>({
      query: queryArg => ({
        url: `/api/groups/invites/invite_${queryArg.inviteId}/accept`,
        method: 'POST',
      }),
    }),
    groupInviteDecline: build.mutation<GroupInviteDeclineApiResponse, GroupInviteDeclineApiArg>({
      query: queryArg => ({
        url: `/api/groups/invites/invite_${queryArg.inviteId}/decline`,
        method: 'POST',
      }),
    }),
    groupRequestsIndex: build.query<GroupRequestsIndexApiResponse, GroupRequestsIndexApiArg>({
      query: queryArg => ({
        url: `/api/groups/requests`,
        params: {forMe: queryArg.forMe, fromMe: queryArg.fromMe},
      }),
    }),
    groupRequestsSend: build.mutation<GroupRequestsSendApiResponse, GroupRequestsSendApiArg>({
      query: queryArg => ({
        url: `/api/groups/requests`,
        method: 'POST',
        body: queryArg.groupRequestDto,
      }),
    }),
    groupRequestIndex: build.query<GroupRequestIndexApiResponse, GroupRequestIndexApiArg>({
      query: queryArg => ({
        url: `/api/groups/requests/request_${queryArg.requestId}`,
      }),
    }),
    groupRequestDeleteRequest: build.mutation<GroupRequestDeleteRequestApiResponse, GroupRequestDeleteRequestApiArg>({
      query: queryArg => ({
        url: `/api/groups/requests/request_${queryArg.requestId}`,
        method: 'DELETE',
      }),
    }),
    groupRequestApproveVote: build.mutation<GroupRequestApproveVoteApiResponse, GroupRequestApproveVoteApiArg>({
      query: queryArg => ({
        url: `/api/groups/requests/request_${queryArg.requestId}/approveVote`,
        method: 'POST',
      }),
    }),
    groupRequestDeclineVote: build.mutation<GroupRequestDeclineVoteApiResponse, GroupRequestDeclineVoteApiArg>({
      query: queryArg => ({
        url: `/api/groups/requests/request_${queryArg.requestId}/declineVote`,
        method: 'POST',
      }),
    }),
    groupRequestApproveRequest: build.mutation<GroupRequestApproveRequestApiResponse, GroupRequestApproveRequestApiArg>(
      {
        query: queryArg => ({
          url: `/api/groups/requests/request_${queryArg.requestId}/approveRequest`,
          method: 'POST',
        }),
      }
    ),
    groupRequestDeclineRequest: build.mutation<GroupRequestDeclineRequestApiResponse, GroupRequestDeclineRequestApiArg>(
      {
        query: queryArg => ({
          url: `/api/groups/requests/request_${queryArg.requestId}/declineRequest`,
          method: 'POST',
        }),
      }
    ),
    groupIndex: build.query<GroupIndexApiResponse, GroupIndexApiArg>({
      query: queryArg => ({url: `/api/groups/group_${queryArg.groupId}`}),
    }),
    groupUpdate: build.mutation<GroupUpdateApiResponse, GroupUpdateApiArg>({
      query: queryArg => ({
        url: `/api/groups/group_${queryArg.groupId}`,
        method: 'PUT',
        body: queryArg.groupDto,
      }),
    }),
    groupPatch: build.mutation<GroupPatchApiResponse, GroupPatchApiArg>({
      query: queryArg => ({
        url: `/api/groups/group_${queryArg.groupId}`,
        method: 'PATCH',
        body: queryArg.groupPartialDto,
      }),
    }),
    groupDestroy: build.mutation<GroupDestroyApiResponse, GroupDestroyApiArg>({
      query: queryArg => ({
        url: `/api/groups/group_${queryArg.groupId}`,
        method: 'POST',
        body: queryArg.groupDismissDto,
      }),
    }),
    groupKickOut: build.mutation<GroupKickOutApiResponse, GroupKickOutApiArg>({
      query: queryArg => ({
        url: `/api/groups/group_${queryArg.groupId}/kickOut`,
        method: 'POST',
        body: queryArg.kickOutDto,
      }),
    }),
    groupLeaveGroup: build.mutation<GroupLeaveGroupApiResponse, GroupLeaveGroupApiArg>({
      query: queryArg => ({
        url: `/api/groups/group_${queryArg.groupId}/leave`,
        method: 'POST',
      }),
    }),
    groupNewLeader: build.mutation<GroupNewLeaderApiResponse, GroupNewLeaderApiArg>({
      query: queryArg => ({
        url: `/api/groups/group_${queryArg.groupId}/newLeader`,
        method: 'POST',
        body: queryArg.newLeaderDto,
      }),
    }),
    contentsIndex: build.query<ContentsIndexApiResponse, ContentsIndexApiArg>({
      query: queryArg => ({
        url: `/api/contents`,
        params: {
          offset: queryArg.offset,
          limit: queryArg.limit,
          skip_id: queryArg.skipId,
          _id: queryArg._id,
          tagsId: queryArg.tagsId,
          name: queryArg.name,
          categoryId: queryArg.categoryId,
          sort: queryArg.sort,
        },
      }),
    }),
    contentsCategories: build.query<ContentsCategoriesApiResponse, ContentsCategoriesApiArg>({
      query: () => ({url: `/api/contents/categories`}),
    }),
    contentsUnreadedCount: build.query<ContentsUnreadedCountApiResponse, ContentsUnreadedCountApiArg>({
      query: queryArg => ({
        url: `/api/contents/unreadCount`,
        params: {
          tagsId: queryArg.tagsId,
          name: queryArg.name,
          categoryId: queryArg.categoryId,
        },
      }),
    }),
    notionPageIndex: build.query<NotionPageIndexApiResponse, NotionPageIndexApiArg>({
      query: queryArg => ({
        url: `/api/contents/notion/page_${queryArg.pageId}`,
      }),
    }),
    contentIndex: build.query<ContentIndexApiResponse, ContentIndexApiArg>({
      query: queryArg => ({
        url: `/api/contents/content_${queryArg.contentId}`,
      }),
    }),
    likesIndex: build.query<LikesIndexApiResponse, LikesIndexApiArg>({
      query: queryArg => ({
        url: `/api/likes`,
        params: {
          referenceName: queryArg.referenceName,
          referenceId: queryArg.referenceId,
        },
      }),
    }),
    likesAdd: build.mutation<LikesAddApiResponse, LikesAddApiArg>({
      query: queryArg => ({
        url: `/api/likes`,
        method: 'POST',
        params: {
          referenceName: queryArg.referenceName,
          referenceId: queryArg.referenceId,
        },
      }),
    }),
    claimsIndex: build.query<ClaimsIndexApiResponse, ClaimsIndexApiArg>({
      query: queryArg => ({
        url: `/api/claims`,
        params: {
          referenceName: queryArg.referenceName,
          referenceId: queryArg.referenceId,
          offset: queryArg.offset,
          limit: queryArg.limit,
        },
      }),
    }),
    claimsAdd: build.mutation<ClaimsAddApiResponse, ClaimsAddApiArg>({
      query: queryArg => ({
        url: `/api/claims`,
        method: 'POST',
        body: queryArg.claimDto,
        params: {
          referenceName: queryArg.referenceName,
          referenceId: queryArg.referenceId,
        },
      }),
    }),
    claimIndex: build.query<ClaimIndexApiResponse, ClaimIndexApiArg>({
      query: queryArg => ({url: `/api/claims/claim_${queryArg.claimId}`}),
    }),
    claimUpdate: build.mutation<ClaimUpdateApiResponse, ClaimUpdateApiArg>({
      query: queryArg => ({
        url: `/api/claims/claim_${queryArg.claimId}`,
        method: 'PUT',
        body: queryArg.claimDto,
      }),
    }),
    claimPatch: build.mutation<ClaimPatchApiResponse, ClaimPatchApiArg>({
      query: queryArg => ({
        url: `/api/claims/claim_${queryArg.claimId}`,
        method: 'PATCH',
        body: queryArg.claimPartialDto,
      }),
    }),
    claimDelete: build.mutation<ClaimDeleteApiResponse, ClaimDeleteApiArg>({
      query: queryArg => ({
        url: `/api/claims/claim_${queryArg.claimId}`,
        method: 'DELETE',
      }),
    }),
    commentsIndex: build.query<CommentsIndexApiResponse, CommentsIndexApiArg>({
      query: queryArg => ({
        url: `/api/comments`,
        params: {
          offset: queryArg.offset,
          limit: queryArg.limit,
          sort: queryArg.sort,
          commentId: queryArg.commentId,
          branchCommentId: queryArg.branchCommentId,
          referenceName: queryArg.referenceName,
          referenceId: queryArg.referenceId,
        },
      }),
    }),
    commentsAdd: build.mutation<CommentsAddApiResponse, CommentsAddApiArg>({
      query: queryArg => ({
        url: `/api/comments`,
        method: 'POST',
        body: queryArg.addCommentDto,
        params: {
          referenceName: queryArg.referenceName,
          referenceId: queryArg.referenceId,
        },
      }),
    }),
    commentIndex: build.query<CommentIndexApiResponse, CommentIndexApiArg>({
      query: queryArg => ({
        url: `/api/comments/comment_${queryArg.commentId}`,
      }),
    }),
    commentEdit: build.mutation<CommentEditApiResponse, CommentEditApiArg>({
      query: queryArg => ({
        url: `/api/comments/comment_${queryArg.commentId}`,
        method: 'PATCH',
        body: queryArg.editCommentDto,
      }),
    }),
    commentSafeDelete: build.mutation<CommentSafeDeleteApiResponse, CommentSafeDeleteApiArg>({
      query: queryArg => ({
        url: `/api/comments/comment_${queryArg.commentId}`,
        method: 'DELETE',
      }),
    }),
    commentSafeRestore: build.mutation<CommentSafeRestoreApiResponse, CommentSafeRestoreApiArg>({
      query: queryArg => ({
        url: `/api/comments/comment_${queryArg.commentId}/restore`,
        method: 'POST',
      }),
    }),
    publicationsIndex: build.query<PublicationsIndexApiResponse, PublicationsIndexApiArg>({
      query: queryArg => ({
        url: `/api/publications`,
        params: {
          sort: queryArg.sort,
          type: queryArg['type'],
          streamId: queryArg.streamId,
          userId: queryArg.userId,
          groupId: queryArg.groupId,
          subscribersOf: queryArg.subscribersOf,
          subscriptionsOf: queryArg.subscriptionsOf,
          username: queryArg.username,
          privacy: queryArg.privacy,
          withAttachment: queryArg.withAttachment,
          taskId: queryArg.taskId,
          questId: queryArg.questId,
          stageId: queryArg.stageId,
          tagId: queryArg.tagId,
          withStageSubscriptions: queryArg.withStageSubscriptions,
          isDeleted: queryArg.isDeleted,
          qaStatus: queryArg.qaStatus,
          offset: queryArg.offset,
          limit: queryArg.limit,
        },
      }),
    }),
    publicationsAdd: build.mutation<PublicationsAddApiResponse, PublicationsAddApiArg>({
      query: queryArg => ({
        url: `/api/publications`,
        method: 'POST',
        body: queryArg.addPublicationDto,
      }),
    }),
    publicationsTags: build.query<PublicationsTagsApiResponse, PublicationsTagsApiArg>({
      query: queryArg => ({
        url: `/api/publications/tags`,
        params: {
          type: queryArg['type'],
          streamId: queryArg.streamId,
          userId: queryArg.userId,
          groupId: queryArg.groupId,
          subscribersOf: queryArg.subscribersOf,
          subscriptionsOf: queryArg.subscriptionsOf,
          username: queryArg.username,
          privacy: queryArg.privacy,
          withAttachment: queryArg.withAttachment,
          taskId: queryArg.taskId,
          questId: queryArg.questId,
          stageId: queryArg.stageId,
          tagId: queryArg.tagId,
          withStageSubscriptions: queryArg.withStageSubscriptions,
          isDeleted: queryArg.isDeleted,
          qaStatus: queryArg.qaStatus,
          tagType: queryArg.tagType,
        },
      }),
    }),
    publicationIndex: build.query<PublicationIndexApiResponse, PublicationIndexApiArg>({
      query: queryArg => ({
        url: `/api/publications/post_${queryArg.postId}`,
      }),
    }),
    publicationUpdate: build.mutation<PublicationUpdateApiResponse, PublicationUpdateApiArg>({
      query: queryArg => ({
        url: `/api/publications/post_${queryArg.postId}`,
        method: 'PUT',
        body: queryArg.addPublicationDto,
      }),
    }),
    publicationPatch: build.mutation<PublicationPatchApiResponse, PublicationPatchApiArg>({
      query: queryArg => ({
        url: `/api/publications/post_${queryArg.postId}`,
        method: 'PATCH',
        body: queryArg.editPublicationDto,
      }),
    }),
    publicationSafeDelete: build.mutation<PublicationSafeDeleteApiResponse, PublicationSafeDeleteApiArg>({
      query: queryArg => ({
        url: `/api/publications/post_${queryArg.postId}`,
        method: 'DELETE',
      }),
    }),
    publicationSetQaStatus: build.mutation<PublicationSetQaStatusApiResponse, PublicationSetQaStatusApiArg>({
      query: queryArg => ({
        url: `/api/publications/post_${queryArg.postId}/setQaStatus`,
        method: 'POST',
        body: queryArg.setQaStatusDto,
      }),
    }),
    publicationSafeRestore: build.mutation<PublicationSafeRestoreApiResponse, PublicationSafeRestoreApiArg>({
      query: queryArg => ({
        url: `/api/publications/post_${queryArg.postId}/restore`,
        method: 'POST',
      }),
    }),
    usersIndex: build.query<UsersIndexApiResponse, UsersIndexApiArg>({
      query: queryArg => ({
        url: `/api/users`,
        params: {
          offset: queryArg.offset,
          limit: queryArg.limit,
          skip_id: queryArg.skipId,
          _id: queryArg._id,
          sort: queryArg.sort,
          streamId: queryArg.streamId,
          groupId: queryArg.groupId,
          subscribersOf: queryArg.subscribersOf,
          subscriptionsOf: queryArg.subscriptionsOf,
          username: queryArg.username,
          referenceLikes: queryArg.referenceLikes,
          referenceReactions: queryArg.referenceReactions,
          referenceId: queryArg.referenceId,
          privacy: queryArg.privacy,
        },
      }),
    }),
    userIndex: build.query<UserIndexApiResponse, UserIndexApiArg>({
      query: queryArg => ({url: `/api/users/user_${queryArg.userId}`}),
    }),
    userToggleSubscription: build.mutation<UserToggleSubscriptionApiResponse, UserToggleSubscriptionApiArg>({
      query: queryArg => ({
        url: `/api/users/user_${queryArg.userId}/toggleSubscription`,
        method: 'POST',
      }),
    }),
    userAddToBlacklist: build.mutation<UserAddToBlacklistApiResponse, UserAddToBlacklistApiArg>({
      query: queryArg => ({
        url: `/api/users/user_${queryArg.userId}/blacklist`,
        method: 'POST',
        body: queryArg.blackListDto,
      }),
    }),
    userRemoveFromBlacklist: build.mutation<UserRemoveFromBlacklistApiResponse, UserRemoveFromBlacklistApiArg>({
      query: queryArg => ({
        url: `/api/users/user_${queryArg.userId}/blacklist`,
        method: 'DELETE',
      }),
    }),
    jobsIndex: build.query<JobsIndexApiResponse, JobsIndexApiArg>({
      query: queryArg => ({
        url: `/api/jobs`,
        params: {
          offset: queryArg.offset,
          limit: queryArg.limit,
          populates: queryArg.populates,
          status: queryArg.status,
          userId: queryArg.userId,
        },
      }),
    }),
    jobIndex: build.query<JobIndexApiResponse, JobIndexApiArg>({
      query: queryArg => ({url: `/api/jobs/job_${queryArg.jobId}`}),
    }),
    jobSafeDelete: build.mutation<JobSafeDeleteApiResponse, JobSafeDeleteApiArg>({
      query: queryArg => ({
        url: `/api/jobs/job_${queryArg.jobId}`,
        method: 'DELETE',
      }),
    }),
    jobUniversalComplete: build.mutation<JobUniversalCompleteApiResponse, JobUniversalCompleteApiArg>({
      query: queryArg => ({
        url: `/api/jobs/job_${queryArg.jobId}/universal_complete`,
        method: 'POST',
        body: queryArg.jobCompleteDto,
      }),
    }),
    jobMakeEditable: build.mutation<JobMakeEditableApiResponse, JobMakeEditableApiArg>({
      query: queryArg => ({
        url: `/api/jobs/job_${queryArg.jobId}/makeEditable`,
        method: 'POST',
      }),
    }),
    jobStructure: build.query<JobStructureApiResponse, JobStructureApiArg>({
      query: queryArg => ({
        url: `/api/jobs/job_${queryArg.jobId}/refresh_structure`,
      }),
    }),
    jobTaskShortInfo: build.query<JobTaskShortInfoApiResponse, JobTaskShortInfoApiArg>({
      query: queryArg => ({
        url: `/api/jobs/job_${queryArg.jobId}/taskShortInfo`,
      }),
    }),
    jobScriptsStatuses: build.query<JobScriptsStatusesApiResponse, JobScriptsStatusesApiArg>({
      query: queryArg => ({
        url: `/api/jobs/job_${queryArg.jobId}/scriptsStatus`,
      }),
    }),
    jobAnswersIndex: build.query<JobAnswersIndexApiResponse, JobAnswersIndexApiArg>({
      query: queryArg => ({url: `/api/jobs/job_${queryArg.jobId}/answers`}),
    }),
    jobAnswersSimple: build.query<JobAnswersSimpleApiResponse, JobAnswersSimpleApiArg>({
      query: queryArg => ({
        url: `/api/jobs/job_${queryArg.jobId}/answers/simple`,
      }),
    }),
    jobAnswersGetAnswers: build.query<JobAnswersGetAnswersApiResponse, JobAnswersGetAnswersApiArg>({
      query: queryArg => ({
        url: `/api/jobs/job_${queryArg.jobId}/answers/script_${queryArg.scriptId}`,
      }),
    }),
    jobAnswersSaveAnswers: build.mutation<JobAnswersSaveAnswersApiResponse, JobAnswersSaveAnswersApiArg>({
      query: queryArg => ({
        url: `/api/jobs/job_${queryArg.jobId}/answers/script_${queryArg.scriptId}`,
        method: 'PUT',
        body: queryArg.answersBodyDto,
      }),
    }),
    jobAnswersSavePartialAnswers: build.mutation<
      JobAnswersSavePartialAnswersApiResponse,
      JobAnswersSavePartialAnswersApiArg
    >({
      query: queryArg => ({
        url: `/api/jobs/job_${queryArg.jobId}/answers/script_${queryArg.scriptId}`,
        method: 'PATCH',
        body: queryArg.answersBodyDto,
      }),
    }),
    tasksIndex: build.query<TasksIndexApiResponse, TasksIndexApiArg>({
      query: queryArg => ({
        url: `/api/tasks`,
        params: {
          categoryId: queryArg.categoryId,
          offset: queryArg.offset,
          limit: queryArg.limit,
        },
      }),
    }),
    tasksCategories: build.query<TasksCategoriesApiResponse, TasksCategoriesApiArg>({
      query: () => ({url: `/api/tasks/categories`}),
    }),
    tasksUnreadedCount: build.query<TasksUnreadedCountApiResponse, TasksUnreadedCountApiArg>({
      query: queryArg => ({
        url: `/api/tasks/unreadCount`,
        params: {categoryId: queryArg.categoryId},
      }),
    }),
    taskIndex: build.query<TaskIndexApiResponse, TaskIndexApiArg>({
      query: queryArg => ({url: `/api/tasks/task_${queryArg.taskId}`}),
    }),
    taskAccept: build.mutation<TaskAcceptApiResponse, TaskAcceptApiArg>({
      query: queryArg => ({
        url: `/api/tasks/task_${queryArg.taskId}/accept`,
        method: 'POST',
        body: queryArg.taskActionDto,
      }),
    }),
    goalsIndex: build.query<GoalsIndexApiResponse, GoalsIndexApiArg>({
      query: queryArg => ({
        url: `/api/goals`,
        params: {
          sort: queryArg.sort,
          streamId: queryArg.streamId,
          userId: queryArg.userId,
          groupId: queryArg.groupId,
          goalTypeId: queryArg.goalTypeId,
          status: queryArg.status,
          privacy: queryArg.privacy,
          name: queryArg.name,
          personalQualityTagsId: queryArg.personalQualityTagsId,
          isDeleted: queryArg.isDeleted,
          offset: queryArg.offset,
          limit: queryArg.limit,
        },
      }),
    }),
    goalsAdd: build.mutation<GoalsAddApiResponse, GoalsAddApiArg>({
      query: queryArg => ({
        url: `/api/goals`,
        method: 'POST',
        body: queryArg.goalDto,
      }),
    }),
    goalsMyPrivateLimit: build.query<GoalsMyPrivateLimitApiResponse, GoalsMyPrivateLimitApiArg>({
      query: () => ({url: `/api/goals/myPrivateLimit`}),
    }),
    goalsTypes: build.query<GoalsTypesApiResponse, GoalsTypesApiArg>({
      query: () => ({url: `/api/goals/types`}),
    }),
    goalsReportData: build.query<GoalsReportDataApiResponse, GoalsReportDataApiArg>({
      query: queryArg => ({
        url: `/api/goals/reportData`,
        params: {reportId: queryArg.reportId},
      }),
    }),
    goalIndex: build.query<GoalIndexApiResponse, GoalIndexApiArg>({
      query: queryArg => ({url: `/api/goals/goal_${queryArg.goalId}`}),
    }),
    goalUpdate: build.mutation<GoalUpdateApiResponse, GoalUpdateApiArg>({
      query: queryArg => ({
        url: `/api/goals/goal_${queryArg.goalId}`,
        method: 'PUT',
        body: queryArg.goalDto,
      }),
    }),
    goalPatch: build.mutation<GoalPatchApiResponse, GoalPatchApiArg>({
      query: queryArg => ({
        url: `/api/goals/goal_${queryArg.goalId}`,
        method: 'PATCH',
        body: queryArg.goalPartialDto,
      }),
    }),
    goalSafeDelete: build.mutation<GoalSafeDeleteApiResponse, GoalSafeDeleteApiArg>({
      query: queryArg => ({
        url: `/api/goals/goal_${queryArg.goalId}`,
        method: 'DELETE',
      }),
    }),
    goalArchive: build.mutation<GoalArchiveApiResponse, GoalArchiveApiArg>({
      query: queryArg => ({
        url: `/api/goals/goal_${queryArg.goalId}/archive`,
        method: 'POST',
      }),
    }),
    goalClone: build.mutation<GoalCloneApiResponse, GoalCloneApiArg>({
      query: queryArg => ({
        url: `/api/goals/goal_${queryArg.goalId}/clone`,
        method: 'POST',
        body: queryArg.goalDto,
      }),
    }),
    goalSafeRestore: build.mutation<GoalSafeRestoreApiResponse, GoalSafeRestoreApiArg>({
      query: queryArg => ({
        url: `/api/goals/goal_${queryArg.goalId}/restore`,
        method: 'POST',
      }),
    }),
    goalReportsIndex: build.query<GoalReportsIndexApiResponse, GoalReportsIndexApiArg>({
      query: queryArg => ({
        url: `/api/goals/goal_${queryArg.goalId}/reports`,
        params: {sort: queryArg.sort},
      }),
    }),
    goalReportsAdd: build.mutation<GoalReportsAddApiResponse, GoalReportsAddApiArg>({
      query: queryArg => ({
        url: `/api/goals/goal_${queryArg.goalId}/reports`,
        method: 'POST',
        body: queryArg.addReportDto,
      }),
    }),
    goalReportIndex: build.query<GoalReportIndexApiResponse, GoalReportIndexApiArg>({
      query: queryArg => ({
        url: `/api/goals/goal_${queryArg.goalId}/reports/report_${queryArg.reportId}`,
      }),
    }),
    goalReportPatch: build.mutation<GoalReportPatchApiResponse, GoalReportPatchApiArg>({
      query: queryArg => ({
        url: `/api/goals/goal_${queryArg.goalId}/reports/report_${queryArg.reportId}`,
        method: 'PATCH',
        body: queryArg.editReportPartialDto,
      }),
    }),
    goalReportUpdate: build.mutation<GoalReportUpdateApiResponse, GoalReportUpdateApiArg>({
      query: queryArg => ({
        url: `/api/goals/goal_${queryArg.goalId}/reports/report_${queryArg.reportId}`,
        method: 'PUT',
        body: queryArg.editReportDto,
      }),
    }),
    goalReportSafeDelete: build.mutation<GoalReportSafeDeleteApiResponse, GoalReportSafeDeleteApiArg>({
      query: queryArg => ({
        url: `/api/goals/goal_${queryArg.goalId}/reports/report_${queryArg.reportId}`,
        method: 'DELETE',
      }),
    }),
    userPhotosIndex: build.query<UserPhotosIndexApiResponse, UserPhotosIndexApiArg>({
      query: queryArg => ({
        url: `/api/photos`,
        params: {
          populates: queryArg.populates,
          skip_id: queryArg.skipId,
          _id: queryArg._id,
          userId: queryArg.userId,
        },
      }),
    }),
    userPhotosAdd: build.mutation<UserPhotosAddApiResponse, UserPhotosAddApiArg>({
      query: queryArg => ({
        url: `/api/photos`,
        method: 'POST',
        body: queryArg.userPhotoAdminDto,
      }),
    }),
    userPhotosProfilePhoto: build.query<UserPhotosProfilePhotoApiResponse, UserPhotosProfilePhotoApiArg>({
      query: () => ({url: `/api/photos/profilePhoto`}),
    }),
    userPhotosDeletePhoto: build.mutation<UserPhotosDeletePhotoApiResponse, UserPhotosDeletePhotoApiArg>({
      query: () => ({url: `/api/photos/profilePhoto`, method: 'DELETE'}),
    }),
    userPhotoIndex: build.query<UserPhotoIndexApiResponse, UserPhotoIndexApiArg>({
      query: queryArg => ({url: `/api/photos/photo_${queryArg.photoId}`}),
    }),
    userPhotoUpdate: build.mutation<UserPhotoUpdateApiResponse, UserPhotoUpdateApiArg>({
      query: queryArg => ({
        url: `/api/photos/photo_${queryArg.photoId}`,
        method: 'PATCH',
        body: queryArg.userPhotoPatchAdminDto,
      }),
    }),
    userPhotoDelete: build.mutation<UserPhotoDeleteApiResponse, UserPhotoDeleteApiArg>({
      query: queryArg => ({
        url: `/api/photos/photo_${queryArg.photoId}`,
        method: 'DELETE',
      }),
    }),
    profileIndex: build.query<ProfileIndexApiResponse, ProfileIndexApiArg>({
      query: () => ({url: `/api/profile`}),
    }),
    profileUpdate: build.mutation<ProfileUpdateApiResponse, ProfileUpdateApiArg>({
      query: queryArg => ({
        url: `/api/profile`,
        method: 'POST',
        body: queryArg.userProfileDto,
      }),
    }),
    profileDelete: build.mutation<ProfileDeleteApiResponse, ProfileDeleteApiArg>({
      query: () => ({url: `/api/profile`, method: 'DELETE'}),
    }),
    profileCheckLogin: build.mutation<ProfileCheckLoginApiResponse, ProfileCheckLoginApiArg>({
      query: queryArg => ({
        url: `/api/profile/checkLogin`,
        method: 'POST',
        body: queryArg.userLoginDto,
      }),
    }),
    profileBlacklist: build.query<ProfileBlacklistApiResponse, ProfileBlacklistApiArg>({
      query: () => ({url: `/api/profile/blacklist`}),
    }),
  }),
});
export type RootPolicyApiResponse = /** status 200 Текст соглашения */ PolicyDto;
export type RootPolicyApiArg = void;
export type RootAgreementApiResponse = /** status 200 Текст соглашения */ AgreementDto;
export type RootAgreementApiArg = void;
export type RootIndexApiResponse = /** status 200 Список маршрутов */ RouteElement[];
export type RootIndexApiArg = void;
export type RootJsonDocsApiResponse = /** status 200 Документация OpenAPI */ string;
export type RootJsonDocsApiArg = void;
export type RootDocsApiResponse = /** status 200 Интерфейс для работы с документацией */ string;
export type RootDocsApiArg = void;
export type RootSwaggerApiResponse = /** status 200 Интерфейс для работы с документацией */ string;
export type RootSwaggerApiArg = void;
export type PixelIndexApiResponse = /** status 200 1px */ any;
export type PixelIndexApiArg = void;
export type AppLogoutApiResponse = /** status 200  */ MessageResponse;
export type AppLogoutApiArg = void;
export type AchievementsIndexApiResponse = /** status 200 Состав наград */ AchievementsData[];
export type AchievementsIndexApiArg = {
  /** ID потока */
  streamId?: string[];
  /** ID пользователя */
  userId?: string[];
  /** ID группы */
  groupId?: string[];
  /** ID этапов */
  stageId?: string[];
  /** Тип награды */
  rewardType?: 'default' | 'currency' | 'experience' | 'quality' | 'ability' | 'achivement' | 'prize';
};
export type PaymentsIndexApiResponse = /** status 200 Структура со сводной информацией */ {
  total?: number;
  message?: string;
  data?: Payments[];
};
export type PaymentsIndexApiArg = {
  /** Тип */
  type?: ('yoo_kassa' | 'tinkoff_kassa' | 'paypal' | 'stripe' | 'bluesnap' | 'crypto' | 'manual')[];
};
export type PaymentIndexApiResponse = /** status 200 Информация о сервисе */ Payments;
export type PaymentIndexApiArg = {
  /** Идентификатор */
  paymentId: string;
};
export type PaymentRegisterApiResponse = /** status 200 Информация о сервисе */ Payments;
export type PaymentRegisterApiArg = {
  /** Идентификатор */
  paymentId: string;
  registerPaymentDto: RegisterPaymentDto;
};
export type PaymentRefreshApiResponse = /** status 200 Информация о сервисе */ Payments;
export type PaymentRefreshApiArg = {
  /** Идентификатор */
  paymentId: string;
};
export type PaymentsServicesIndexApiResponse = /** status 200 Структура со сводной информацией */ {
  total?: number;
  message?: string;
  data?: PaymentsServices[];
};
export type PaymentsServicesIndexApiArg = {
  /** Тип */
  type?: ('yoo_kassa' | 'tinkoff_kassa' | 'paypal' | 'stripe' | 'bluesnap' | 'crypto' | 'manual')[];
};
export type MessageIndexApiResponse = /** status 200 Сообщение */ Messages;
export type MessageIndexApiArg = {
  /** Идентификатор */
  messageId: string;
};
export type ReactionsIndexApiResponse = /** status 200 Структура со сводной информацией */ {
  total?: number;
  message?: string;
  data?: Reactions[];
};
export type ReactionsIndexApiArg = {
  /** Сущность */
  referenceName: 'Comments' | 'StagesPoints' | 'Publications' | 'Tasks' | 'Contents' | 'Goals' | 'Messages';
  /** ID значения */
  referenceId: string;
  /** Смещение */
  offset?: number;
  /** Ограничение количества */
  limit?: number;
};
export type ReactionsAddApiResponse = /** status 200 Сводка по реакции */ ReactionsStats;
export type ReactionsAddApiArg = {
  /** Сущность */
  referenceName: 'Comments' | 'StagesPoints' | 'Publications' | 'Tasks' | 'Contents' | 'Goals' | 'Messages';
  /** ID значения */
  referenceId: string;
  reactionDto: ReactionDto;
};
export type ReactionsBlocksIndexApiResponse = /** status 200 Структура со сводной информацией */ {
  total?: number;
  message?: string;
  data?: ReactionsBlocks[];
};
export type ReactionsBlocksIndexApiArg = void;
export type ReactionIndexApiResponse = /** status 200 Реакция */ Reactions;
export type ReactionIndexApiArg = {
  /** Идентификатор */
  reactionId: string;
};
export type ReactionUpdateApiResponse = /** status 200 Сводка по реакции */ ReactionsStats;
export type ReactionUpdateApiArg = {
  /** Идентификатор */
  reactionId: string;
  reactionPatchDto: ReactionPatchDto;
};
export type ReactionDeleteApiResponse = /** status 200 Сводка по реакции */ ReactionsStats;
export type ReactionDeleteApiArg = {
  /** Идентификатор */
  reactionId: string;
};
export type ReactionStatsApiResponse = /** status 200 Сводка по реакции */ ReactionsStats;
export type ReactionStatsApiArg = {
  /** Идентификатор */
  reactionId: string;
};
export type ViewsIndexApiResponse = /** status 200 Сводка по просмотрам */ LikesStats;
export type ViewsIndexApiArg = {
  /** Сущность */
  referenceName: 'Tasks' | 'Contents' | 'Quests';
  /** ID значения */
  referenceId: string;
};
export type ViewsSetViewedApiResponse = /** status 200 Сводка по просмотрам */ LikesStats;
export type ViewsSetViewedApiArg = {
  /** Сущность */
  referenceName: 'Tasks' | 'Contents' | 'Quests';
  /** ID значения */
  referenceId: string;
};
export type StagePointsIndexApiResponse = /** status 200 Список точек на этапе */ StagePointData[];
export type StagePointsIndexApiArg = {
  /** Статус точки */
  status?: ('new' | 'in_progress' | 'checking' | 'ready' | 'completed' | 'deadline' | 'failed' | 'rejected')[];
  /** ID этапа */
  stageId: string;
  /** Вывод частичной информации */
  partial?: boolean;
};
export type StagePointsIdsApiResponse = /** status 200  */ IDs;
export type StagePointsIdsApiArg = {
  /** Статус точки */
  status?: ('new' | 'in_progress' | 'checking' | 'ready' | 'completed' | 'deadline' | 'failed' | 'rejected')[];
  /** ID этапа */
  stageId: string;
};
export type StagePointIndexApiResponse = /** status 200  */ StagePointData;
export type StagePointIndexApiArg = {
  /** Идентификатор */
  pointId: string;
  /** Вывод частичной информации */
  partial?: boolean;
};
export type StagePointSetReferenceApiResponse = /** status 200  */ StagePointData;
export type StagePointSetReferenceApiArg = {
  /** Идентификатор */
  pointId: string;
  /** Вывод частичной информации */
  partial?: boolean;
  setReferenceDto: SetReferenceDto;
};
export type StagePointClearReferenceApiResponse = /** status 200  */ StagePointData;
export type StagePointClearReferenceApiArg = {
  /** Идентификатор */
  pointId: string;
  /** Вывод частичной информации */
  partial?: boolean;
};
export type StagePointActivateTimelineApiResponse = /** status 200  */ StagePointData;
export type StagePointActivateTimelineApiArg = {
  /** Идентификатор */
  pointId: string;
  /** Вывод частичной информации */
  partial?: boolean;
  activateTimelineDto: ActivateTimelineDto;
};
export type StagePointShortcutDataApiResponse = /** status 200 Данные о ссылке на квест */ ShortcutDataDto;
export type StagePointShortcutDataApiArg = {
  /** Идентификатор */
  pointId: string;
};
export type StagePointPaymentDataApiResponse = /** status 200 Данные об оплате */ PaymentDataDto;
export type StagePointPaymentDataApiArg = {
  /** Идентификатор */
  pointId: string;
  paymentBodyDto: PaymentBodyDto;
};
export type StagePointsSubscriptionsIndexApiResponse = /** status 200 Структура со сводной информацией */ {
  total?: number;
  message?: string;
  data?: StagesPointsSubscriptions[];
};
export type StagePointsSubscriptionsIndexApiArg = {
  /** Идентификатор */
  pointId: string;
};
export type StagePointsSubscriptionsAddApiResponse = /** status 200 Структура со сводной информацией */ {
  total?: number;
  message?: string;
  data?: StagesPointsSubscriptions[];
};
export type StagePointsSubscriptionsAddApiArg = {
  /** Идентификатор */
  pointId: string;
};
export type StagePointsSubscriptionsStagesApiResponse = /** status 200 Структура со сводной информацией */ {
  total?: number;
  message?: string;
  data?: StagesPointsSubscriptions[];
};
export type StagePointsSubscriptionsStagesApiArg = {
  /** Идентификатор */
  pointId: string;
  /** Смещение */
  offset?: number;
  /** Ограничение количества */
  limit?: number;
  /** Пропускаемый идентификатор */
  skipId?: string[];
  /** Идентификатор */
  _id?: string[];
  /** Сортировка */
  sort?: 'created_date_asc' | 'created_date_desc';
  /** Статус действий */
  status?: ('new' | 'in_progress' | 'checking' | 'ready' | 'completed' | 'deadline' | 'failed' | 'rejected')[];
  /** Тип квеста */
  questType?:
    | 'default'
    | 'season'
    | 'meeting'
    | 'event'
    | 'point'
    | 'block'
    | 'adventure'
    | 'challenge'
    | 'lesson'
    | 'goal'
    | 'goal_report';
  /** ID квеста */
  questId?: string;
  /** ID пользователя */
  usersId?: string[];
  /** ID родительского этапа */
  parentStageId?: string;
  /** ID таймлайна */
  timelineId?: string;
};
export type StagePointsSubscriptionsStagesIdsApiResponse = /** status 200  */ IDs;
export type StagePointsSubscriptionsStagesIdsApiArg = {
  /** Идентификатор */
  pointId: string;
  /** Смещение */
  offset?: number;
  /** Ограничение количества */
  limit?: number;
  /** Пропускаемый идентификатор */
  skipId?: string[];
  /** Идентификатор */
  _id?: string[];
  /** Сортировка */
  sort?: 'created_date_asc' | 'created_date_desc';
  /** Статус действий */
  status?: ('new' | 'in_progress' | 'checking' | 'ready' | 'completed' | 'deadline' | 'failed' | 'rejected')[];
  /** Тип квеста */
  questType?:
    | 'default'
    | 'season'
    | 'meeting'
    | 'event'
    | 'point'
    | 'block'
    | 'adventure'
    | 'challenge'
    | 'lesson'
    | 'goal'
    | 'goal_report';
  /** ID квеста */
  questId?: string;
  /** ID пользователя */
  usersId?: string[];
  /** ID родительского этапа */
  parentStageId?: string;
  /** ID таймлайна */
  timelineId?: string;
};
export type StageIndexApiResponse = /** status 200 Данные о действии */ StageInfo;
export type StageIndexApiArg = {
  /** Идентификатор */
  stageId: string;
  /** Вывод частичной информации */
  partial?: boolean;
};
export type StagesIndexApiResponse = /** status 200 Структура со сводной информацией */ {
  total?: number;
  message?: string;
  data?: Stages[];
};
export type StagesIndexApiArg = {
  /** Смещение */
  offset?: number;
  /** Ограничение количества */
  limit?: number;
  /** Пропускаемый идентификатор */
  skipId?: string[];
  /** Идентификатор */
  _id?: string[];
  populates?: ('quest' | 'timeline' | 'user')[];
  /** Сортировка */
  sort?: 'created_date_asc' | 'created_date_desc';
  /** Статус действий */
  status?: ('new' | 'in_progress' | 'checking' | 'ready' | 'completed' | 'deadline' | 'failed' | 'rejected')[];
  /** Тип квеста */
  questType?:
    | 'default'
    | 'season'
    | 'meeting'
    | 'event'
    | 'point'
    | 'block'
    | 'adventure'
    | 'challenge'
    | 'lesson'
    | 'goal'
    | 'goal_report';
  /** ID квеста */
  questId?: string;
  /** ID пользователя */
  usersId?: string[];
  /** ID родительского этапа */
  parentStageId?: string;
  /** ID таймлайна */
  timelineId?: string;
};
export type StagesByTimelineApiResponse = /** status 200 Список этапов в рамках таймлайна */ {
  user: Users;
  stage?: Stages;
}[];
export type StagesByTimelineApiArg = {
  /** ID таймлайна */
  timelineId?: string;
};
export type QuestsIndexApiResponse = /** status 200 Структура со сводной информацией */ {
  total?: number;
  message?: string;
  data?: Quests[];
};
export type QuestsIndexApiArg = {
  /** Смещение */
  offset?: number;
  /** Ограничение количества */
  limit?: number;
  populates?: ('isViewed' | 'tagsSet' | 'timelines')[];
  /** Типы квестов */
  type:
    | 'default'
    | 'season'
    | 'meeting'
    | 'event'
    | 'point'
    | 'block'
    | 'adventure'
    | 'challenge'
    | 'lesson'
    | 'goal'
    | 'goal_report';
  /** Родительский квест */
  parentQuestId?: string;
  /** ID тегов */
  tagsId?: string[];
  /** Игнорировать даты */
  ignoreDate?: number | null;
};
export type QuestsDataApiResponse = /** status 200 Структура со сводной информацией */ {
  total?: number;
  message?: string;
  data?: Quests[];
};
export type QuestsDataApiArg = {
  /** Пропускаемый идентификатор */
  skipId?: string[];
  /** Идентификатор */
  _id?: string[];
  populates?: ('isViewed' | 'tagsSet' | 'timelines')[];
};
export type QuestsTagsApiResponse = /** status 200 Список тегов */ Tags[];
export type QuestsTagsApiArg = {
  /** Типы квестов */
  type:
    | 'default'
    | 'season'
    | 'meeting'
    | 'event'
    | 'point'
    | 'block'
    | 'adventure'
    | 'challenge'
    | 'lesson'
    | 'goal'
    | 'goal_report';
  /** Родительский квест */
  parentQuestId?: string;
  /** ID тегов */
  tagsId?: string[];
  /** Пропускаемый идентификатор */
  skipId?: string[];
  /** Идентификатор */
  _id?: string[];
  /** Игнорировать даты */
  ignoreDate?: number | null;
  /** Тип тега */
  tagType: 'default' | 'personal_quality' | 'hashtag' | 'quest';
};
export type QuestsUnreadedCountApiResponse = /** status 200 Общее количество непросмотренных */ {
  total?: number;
  message?: string;
  data?: object;
};
export type QuestsUnreadedCountApiArg = {
  /** Типы квестов */
  type:
    | 'default'
    | 'season'
    | 'meeting'
    | 'event'
    | 'point'
    | 'block'
    | 'adventure'
    | 'challenge'
    | 'lesson'
    | 'goal'
    | 'goal_report';
  /** Родительский квест */
  parentQuestId?: string;
  /** ID тегов */
  tagsId?: string[];
  /** Игнорировать даты */
  ignoreDate?: number | null;
};
export type QuestsCurrentSeasonProgressApiResponse =
  /** status 200 Данные о квесте и выполнении */ QuestUserProgressResult;
export type QuestsCurrentSeasonProgressApiArg = {
  /** ID пользователя */
  userId?: string[];
  /** ID таймлайна */
  timelineId?: string[];
  /** ID групп */
  groupId?: string[];
  /** ID потока */
  streamId?: string[];
};
export type QuestIndexApiResponse = /** status 200 Информация о квесте */ QuestInfo;
export type QuestIndexApiArg = {
  /** Идентификатор */
  questId: string;
};
export type QuestAcceptApiResponse = /** status 200 Данные о действии */ StageInfo;
export type QuestAcceptApiArg = {
  /** Идентификатор */
  questId: string;
  /** Вывод частичной информации */
  partial?: boolean;
  questActionDto: QuestActionDto;
};
export type QuestRejectApiResponse = /** status 200 Данные о действии */ StageInfo;
export type QuestRejectApiArg = {
  /** Идентификатор */
  questId: string;
  /** Вывод частичной информации */
  partial?: boolean;
  questActionDto: QuestActionDto;
};
export type QuestUserProgressApiResponse = /** status 200 Данные о квесте и выполнении */ QuestUserProgressResult;
export type QuestUserProgressApiArg = {
  /** Идентификатор */
  questId: string;
  /** ID пользователя */
  userId?: string[];
  /** ID таймлайна */
  timelineId?: string[];
  /** ID групп */
  groupId?: string[];
  /** ID потока */
  streamId?: string[];
};
export type QuestPointsIndexApiResponse = /** status 200 Информация по контрольным точкам */ QuestPointData[];
export type QuestPointsIndexApiArg = {
  /** Идентификатор */
  questId: string;
};
export type QuestPointsIdsApiResponse = /** status 200  */ IDs;
export type QuestPointsIdsApiArg = {
  /** Идентификатор */
  questId: string;
};
export type QuestPointsStatsApiResponse =
  /** status 200 Информация по прогрессу в точках квеста */ QuestPointsProgressData[];
export type QuestPointsStatsApiArg = {
  /** Идентификатор */
  questId: string;
  /** ID пользователя */
  userId?: string[];
  /** ID таймлайна */
  timelineId?: string[];
  /** ID групп */
  groupId?: string[];
  /** ID потока */
  streamId?: string[];
};
export type NotificationsIndexApiResponse = /** status 200 Структура со сводной информацией */ {
  unreadCount: number;
  message?: string;
  data?: Notifications[];
  total?: number;
};
export type NotificationsIndexApiArg = {
  /** Смещение */
  offset?: number;
  /** Ограничение количества */
  limit?: number;
  /** Показывать только непрочитанные */
  unseenOnly?: number | null;
};
export type NotificationsUnreadedCountApiResponse = /** status 200 Кол-во непрочитанных */ UnreadedCountDto;
export type NotificationsUnreadedCountApiArg = void;
export type NotificationsReadAllApiResponse = /** status 200  */ MessageResponse;
export type NotificationsReadAllApiArg = void;
export type NotificationIndexApiResponse = /** status 200 Уведомление */ Notifications;
export type NotificationIndexApiArg = {
  /** Идентификатор */
  notificationId: string;
};
export type NotificationMarkReadApiResponse = /** status 200 Уведомление */ Notifications;
export type NotificationMarkReadApiArg = {
  /** Идентификатор */
  notificationId: string;
};
export type WidgetsIndexApiResponse = /** status 200 Доступные данные в виджете */ WidgetData[];
export type WidgetsIndexApiArg = {
  /** ID пользователя */
  userId: string;
  /** Тип виджета */
  type: 'user_profile';
};
export type WidgetsInfoApiResponse = /** status 200 Данные виджетов */ WidgetInfo[];
export type WidgetsInfoApiArg = {
  /** ID пользователей */
  usersId?: string[];
  /** ID групп */
  groupsId?: string[];
  /** Группировка по признаку */
  groupBy?: 'users' | 'total';
  /** Точность по датам */
  precision?: 'day' | 'week' | 'month' | 'year';
  /** ID таймлайнов */
  timelinesId?: string[];
  /** ID выполнений квеста */
  stagesId?: string[];
  /** ID точек выполнений квеста */
  stagesPointsId?: string[];
  /** Включая дочерние stages */
  withSubStages?: boolean;
  /** Включая родительские stages */
  withParentStages?: boolean;
  /** Идентификатор */
  _id?: string[];
};
export type WidgetIndexApiResponse = /** status 200 документ данных */ Widgets;
export type WidgetIndexApiArg = {
  /** Идентификатор */
  widgetId: string;
};
export type WidgetInfoApiResponse = /** status 200 Данные виджета */ WidgetInfo;
export type WidgetInfoApiArg = {
  /** Идентификатор */
  widgetId: string;
  /** ID пользователей */
  usersId?: string[];
  /** ID групп */
  groupsId?: string[];
  /** Группировка по признаку */
  groupBy?: 'users' | 'total';
  /** Точность по датам */
  precision?: 'day' | 'week' | 'month' | 'year';
  /** ID таймлайнов */
  timelinesId?: string[];
  /** ID выполнений квеста */
  stagesId?: string[];
  /** ID точек выполнений квеста */
  stagesPointsId?: string[];
  /** Включая дочерние stages */
  withSubStages?: boolean;
  /** Включая родительские stages */
  withParentStages?: boolean;
};
export type WidgetsVisualsIndexApiResponse = /** status 200 Доступные визуалы */ WidgetsVisuals[];
export type WidgetsVisualsIndexApiArg = {
  /** Название контейнера */
  containerName?: string;
  /** Включая подписки */
  withStagesSubscriptions?: boolean;
  /** ID таймлайнов */
  timelinesId?: string[];
  /** ID выполнений квеста */
  stagesId?: string[];
  /** ID точек выполнений квеста */
  stagesPointsId?: string[];
  /** Включая дочерние stages */
  withSubStages?: boolean;
  /** Включая родительские stages */
  withParentStages?: boolean;
  populates?: ('designs' | 'props')[];
  /** Пропускаемый идентификатор */
  skipId?: string[];
  /** Идентификатор */
  _id?: string[];
};
export type SettingsIndexApiResponse = /** status 200 Настройки */ Settings;
export type SettingsIndexApiArg = void;
export type CollectorsIndexApiResponse = /** status 200 Список коллекторов */ Collectors[];
export type CollectorsIndexApiArg = {
  /** Тип коллектора */
  type: 'index' | 'profile' | 'widget';
};
export type CollectorValuesIndexApiResponse = /** status 200 Значения в коллекторе */ CollectorDataDto;
export type CollectorValuesIndexApiArg = {
  /** Id коллектора */
  collectorId?: string;
  /** Код коллектора */
  code?: string;
  /** Id пользователя */
  userId: string;
};
export type CollectorValuesByTypeApiResponse = /** status 200 Значения в коллекторе */ CollectorDataDto[];
export type CollectorValuesByTypeApiArg = {
  /** Тип коллектора */
  type: 'index' | 'profile' | 'widget';
  /** Id пользователя */
  userId: string;
};
export type TagsIndexApiResponse = /** status 200 Список тегов */ Tags[];
export type TagsIndexApiArg = {
  /** Название тега */
  name?: string;
  /** Тип тега */
  type?: 'default' | 'personal_quality' | 'hashtag' | 'quest';
  /** Пропускаемый идентификатор */
  skipId?: string[];
  /** Идентификатор */
  _id?: string[];
  /** Поиск количества отмеченных по сущности */
  countReference?: 'Contents' | 'Goals' | 'Publications' | 'Tasks' | 'Quests';
};
export type GroupsIndexApiResponse = /** status 200 Структура со сводной информацией */ {
  total?: number;
  message?: string;
  data?: Groups[];
};
export type GroupsIndexApiArg = {
  /** Смещение */
  offset?: number;
  /** Ограничение количества */
  limit?: number;
  /** Пропускаемый идентификатор */
  skipId?: string[];
  /** Идентификатор */
  _id?: string[];
  /** ID пользователей */
  userId?: string[];
  /** ID потока */
  streamId?: string[];
  /** Название группы */
  name?: string;
  /** Сортировка */
  sort: 'rating' | 'nps' | 'name_asc' | 'name_desc' | 'subscribers_count' | 'created_date';
};
export type GroupInvitesIndexApiResponse = /** status 200 Структура со сводной информацией */ {
  total?: number;
  message?: string;
  data?: GroupsInvites[];
};
export type GroupInvitesIndexApiArg = {
  /** Заявки только для меня */
  forMe?: number;
  /** Заявки только от меня */
  fromMe?: number;
};
export type GroupInvitesSendApiResponse = /** status 200 Заявка */ GroupsInvites;
export type GroupInvitesSendApiArg = {
  groupInviteDto: GroupInviteDto;
};
export type GroupInviteIndexApiResponse = /** status 200 Заявка */ GroupsInvites;
export type GroupInviteIndexApiArg = {
  /** Идентификатор */
  inviteId: string;
};
export type GroupInviteDeleteInviteApiResponse = /** status 200  */ MessageResponse;
export type GroupInviteDeleteInviteApiArg = {
  /** Идентификатор */
  inviteId: string;
};
export type GroupInviteAcceptApiResponse = /** status 200 Успешное действие */ GroupMessageResponse;
export type GroupInviteAcceptApiArg = {
  /** Идентификатор */
  inviteId: string;
};
export type GroupInviteDeclineApiResponse = /** status 200  */ MessageResponse;
export type GroupInviteDeclineApiArg = {
  /** Идентификатор */
  inviteId: string;
};
export type GroupRequestsIndexApiResponse = /** status 200 Структура со сводной информацией */ {
  total?: number;
  message?: string;
  data?: GroupsRequests[];
};
export type GroupRequestsIndexApiArg = {
  /** Заявки только для меня */
  forMe?: number;
  /** Заявки только от меня */
  fromMe?: number;
};
export type GroupRequestsSendApiResponse = /** status 200 Заявка */ GroupsRequests;
export type GroupRequestsSendApiArg = {
  groupRequestDto: GroupRequestDto;
};
export type GroupRequestIndexApiResponse = /** status 200 Заявка */ GroupsRequests;
export type GroupRequestIndexApiArg = {
  /** Идентификатор */
  requestId: string;
};
export type GroupRequestDeleteRequestApiResponse = /** status 200  */ MessageResponse;
export type GroupRequestDeleteRequestApiArg = {
  /** Идентификатор */
  requestId: string;
};
export type GroupRequestApproveVoteApiResponse = /** status 200 Заявка */ GroupsRequests;
export type GroupRequestApproveVoteApiArg = {
  /** Идентификатор */
  requestId: string;
};
export type GroupRequestDeclineVoteApiResponse = /** status 200 Заявка */ GroupsRequests;
export type GroupRequestDeclineVoteApiArg = {
  /** Идентификатор */
  requestId: string;
};
export type GroupRequestApproveRequestApiResponse = /** status 200 Успешное действие */ GroupMessageResponse;
export type GroupRequestApproveRequestApiArg = {
  /** Идентификатор */
  requestId: string;
};
export type GroupRequestDeclineRequestApiResponse = /** status 200  */ MessageResponse;
export type GroupRequestDeclineRequestApiArg = {
  /** Идентификатор */
  requestId: string;
};
export type GroupIndexApiResponse = /** status 200 Информация о группе */ GroupData;
export type GroupIndexApiArg = {
  /** Идентификатор */
  groupId: string;
};
export type GroupUpdateApiResponse = /** status 200 Информация о группе */ GroupData;
export type GroupUpdateApiArg = {
  /** Идентификатор */
  groupId: string;
  groupDto: GroupDto;
};
export type GroupPatchApiResponse = /** status 200 Информация о группе */ GroupData;
export type GroupPatchApiArg = {
  /** Идентификатор */
  groupId: string;
  groupPartialDto: GroupPartialDto;
};
export type GroupDestroyApiResponse = /** status 200  */ MessageResponse;
export type GroupDestroyApiArg = {
  /** Идентификатор */
  groupId: string;
  groupDismissDto: GroupDismissDto;
};
export type GroupKickOutApiResponse = /** status 200  */ MessageResponse;
export type GroupKickOutApiArg = {
  /** Идентификатор */
  groupId: string;
  kickOutDto: KickOutDto;
};
export type GroupLeaveGroupApiResponse = /** status 200  */ MessageResponse;
export type GroupLeaveGroupApiArg = {
  /** Идентификатор */
  groupId: string;
};
export type GroupNewLeaderApiResponse = /** status 200  */ MessageResponse;
export type GroupNewLeaderApiArg = {
  /** Идентификатор */
  groupId: string;
  newLeaderDto: NewLeaderDto;
};
export type ContentsIndexApiResponse = /** status 200 Структура со сводной информацией */ {
  total?: number;
  message?: string;
  data?: Contents[];
};
export type ContentsIndexApiArg = {
  /** Смещение */
  offset?: number;
  /** Ограничение количества */
  limit?: number;
  /** Пропускаемый идентификатор */
  skipId?: string[];
  /** Идентификатор */
  _id?: string[];
  /** ID тегов */
  tagsId?: string[];
  /** Название */
  name?: string;
  /** Id категории */
  categoryId?: string;
  /** Сортировка */
  sort?: 'created_date_asc' | 'created_date_desc';
};
export type ContentsCategoriesApiResponse = /** status 200 Список категорий */ ContentsCategories[];
export type ContentsCategoriesApiArg = void;
export type ContentsUnreadedCountApiResponse = /** status 200 Общее количество непросмотренных */ {
  total?: number;
  message?: string;
  data?: object;
};
export type ContentsUnreadedCountApiArg = {
  /** ID тегов */
  tagsId?: string[];
  /** Название */
  name?: string;
  /** Id категории */
  categoryId?: string;
};
export type NotionPageIndexApiResponse = /** status 200 Результат */ object;
export type NotionPageIndexApiArg = {
  /** ID страницы */
  pageId: string;
};
export type ContentIndexApiResponse = /** status 200 Материал */ Contents;
export type ContentIndexApiArg = {
  /** Идентификатор */
  contentId: string;
};
export type LikesIndexApiResponse = /** status 200 Сводка по лайкам */ LikesStats;
export type LikesIndexApiArg = {
  /** Сущность */
  referenceName: 'Comments' | 'Goals' | 'GoalsReports' | 'Publications';
  /** ID значения */
  referenceId: string;
};
export type LikesAddApiResponse = /** status 200 Сводка по лайкам */ LikesStats;
export type LikesAddApiArg = {
  /** Сущность */
  referenceName: 'Comments' | 'Goals' | 'GoalsReports' | 'Publications';
  /** ID значения */
  referenceId: string;
};
export type ClaimsIndexApiResponse = /** status 200 Структура со сводной информацией */ {
  total?: number;
  message?: string;
  data?: Claims[];
};
export type ClaimsIndexApiArg = {
  /** Сущность */
  referenceName: 'Users' | 'Goals' | 'GoalsReports' | 'Contents' | 'Tasks' | 'Publications' | 'Jobs' | 'Comments';
  /** ID значения */
  referenceId: string;
  /** Смещение */
  offset?: number;
  /** Ограничение количества */
  limit?: number;
};
export type ClaimsAddApiResponse = /** status 200 Жалоба */ Claims;
export type ClaimsAddApiArg = {
  /** Сущность */
  referenceName: 'Users' | 'Goals' | 'GoalsReports' | 'Contents' | 'Tasks' | 'Publications' | 'Jobs' | 'Comments';
  /** ID значения */
  referenceId: string;
  claimDto: ClaimDto;
};
export type ClaimIndexApiResponse = /** status 200 Жалоба */ Claims;
export type ClaimIndexApiArg = {
  /** Идентификатор */
  claimId: string;
};
export type ClaimUpdateApiResponse = /** status 200 Жалоба */ Claims;
export type ClaimUpdateApiArg = {
  /** Идентификатор */
  claimId: string;
  claimDto: ClaimDto;
};
export type ClaimPatchApiResponse = /** status 200 Жалоба */ Claims;
export type ClaimPatchApiArg = {
  /** Идентификатор */
  claimId: string;
  claimPartialDto: ClaimPartialDto;
};
export type ClaimDeleteApiResponse = /** status 200 Жалоба */ Claims;
export type ClaimDeleteApiArg = {
  /** Идентификатор */
  claimId: string;
};
export type CommentsIndexApiResponse = /** status 200 Структура со сводной информацией */ {
  total?: number;
  message?: string;
  data?: Comments[];
};
export type CommentsIndexApiArg = {
  /** Смещение */
  offset?: number;
  /** Ограничение количества */
  limit?: number;
  /** Сортировка */
  sort: 'created_asc' | 'created_desc' | 'score' | 'nps';
  /** ID родительского комментария */
  commentId?: string | null;
  /** ID ветки комментариев */
  branchCommentId?: string | null;
  /** Сущность для комментарования */
  referenceName: 'Contents' | 'Goals' | 'Publications' | 'Jobs' | 'Tasks' | 'Quests';
  /** ID значения */
  referenceId: string;
};
export type CommentsAddApiResponse = /** status 200 Комментарий */ ReferenceScoreComment;
export type CommentsAddApiArg = {
  /** Сущность для комментарования */
  referenceName: 'Contents' | 'Goals' | 'Publications' | 'Jobs' | 'Tasks' | 'Quests';
  /** ID значения */
  referenceId: string;
  addCommentDto: AddCommentDto;
};
export type CommentIndexApiResponse = /** status 200 Комментарий */ ReferenceScoreComment;
export type CommentIndexApiArg = {
  /** Идентификатор */
  commentId: string;
};
export type CommentEditApiResponse = /** status 200 Комментарий */ ReferenceScoreComment;
export type CommentEditApiArg = {
  /** Идентификатор */
  commentId: string;
  editCommentDto: EditCommentDto;
};
export type CommentSafeDeleteApiResponse = /** status 200 Комментарий */ ReferenceScoreComment;
export type CommentSafeDeleteApiArg = {
  /** Идентификатор */
  commentId: string;
};
export type CommentSafeRestoreApiResponse = /** status 200 Комментарий */ ReferenceScoreComment;
export type CommentSafeRestoreApiArg = {
  /** Идентификатор */
  commentId: string;
};
export type PublicationsIndexApiResponse = /** status 200 Структура со сводной информацией */ {
  total?: number;
  message?: string;
  data?: Publications[];
};
export type PublicationsIndexApiArg = {
  /** Сортировка */
  sort: 'rating' | 'created_date';
  /** Тип публикаций */
  type?: ('default' | 'question' | 'report' | 'content' | 'goal')[];
  /** ID потока */
  streamId: string[];
  /** ID пользователя */
  userId?: string;
  /** ID группы */
  groupId?: string[];
  /** ID кто подписан на указанного пользователя */
  subscribersOf?: string;
  /** ID тех, на кого подписан указанный пользователь */
  subscriptionsOf?: string;
  /** Поиск по имени пользователя */
  username?: string;
  /** Поиск по настройкам приватности */
  privacy?: 'private' | 'groups' | 'subscribers' | 'groups_subscribers' | 'public';
  /** С приложениями указанного типа */
  withAttachment?: 'GoalsReports' | 'Jobs' | 'Stages';
  /** ID задачи */
  taskId?: string;
  /** ID квеста */
  questId?: string;
  /** ID выпонения квеста */
  stageId?: string;
  /** ID тега */
  tagId?: string[];
  /** Включая данные из подписок */
  withStageSubscriptions?: boolean;
  /** Отображать удаленные */
  isDeleted?: boolean;
  /** Статус вопроса */
  qaStatus?: 'new' | 'answered' | 'closed' | 'declined';
  /** Смещение */
  offset?: number;
  /** Ограничение количества */
  limit?: number;
};
export type PublicationsAddApiResponse = /** status 200 Публикация */ Publications;
export type PublicationsAddApiArg = {
  addPublicationDto: AddPublicationDto;
};
export type PublicationsTagsApiResponse = /** status 200 Список тегов */ Tags[];
export type PublicationsTagsApiArg = {
  /** Тип публикаций */
  type?: ('default' | 'question' | 'report' | 'content' | 'goal')[];
  /** ID потока */
  streamId: string[];
  /** ID пользователя */
  userId?: string;
  /** ID группы */
  groupId?: string[];
  /** ID кто подписан на указанного пользователя */
  subscribersOf?: string;
  /** ID тех, на кого подписан указанный пользователь */
  subscriptionsOf?: string;
  /** Поиск по имени пользователя */
  username?: string;
  /** Поиск по настройкам приватности */
  privacy?: 'private' | 'groups' | 'subscribers' | 'groups_subscribers' | 'public';
  /** С приложениями указанного типа */
  withAttachment?: 'GoalsReports' | 'Jobs' | 'Stages';
  /** ID задачи */
  taskId?: string;
  /** ID квеста */
  questId?: string;
  /** ID выпонения квеста */
  stageId?: string;
  /** ID тега */
  tagId?: string[];
  /** Включая данные из подписок */
  withStageSubscriptions?: boolean;
  /** Отображать удаленные */
  isDeleted?: boolean;
  /** Статус вопроса */
  qaStatus?: 'new' | 'answered' | 'closed' | 'declined';
  /** Тип тега */
  tagType: 'default' | 'personal_quality' | 'hashtag' | 'quest';
};
export type PublicationIndexApiResponse = /** status 200 Публикация */ Publications;
export type PublicationIndexApiArg = {
  /** Идентификатор */
  postId: string;
};
export type PublicationUpdateApiResponse = /** status 200 Публикация */ Publications;
export type PublicationUpdateApiArg = {
  /** Идентификатор */
  postId: string;
  addPublicationDto: AddPublicationDto;
};
export type PublicationPatchApiResponse = /** status 200 Публикация */ Publications;
export type PublicationPatchApiArg = {
  /** Идентификатор */
  postId: string;
  editPublicationDto: EditPublicationDto;
};
export type PublicationSafeDeleteApiResponse = /** status 200 Публикация */ Publications;
export type PublicationSafeDeleteApiArg = {
  /** Идентификатор */
  postId: string;
};
export type PublicationSetQaStatusApiResponse = /** status 200 Публикация */ Publications;
export type PublicationSetQaStatusApiArg = {
  /** Идентификатор */
  postId: string;
  setQaStatusDto: SetQaStatusDto;
};
export type PublicationSafeRestoreApiResponse = /** status 200 Публикация */ Publications;
export type PublicationSafeRestoreApiArg = {
  /** Идентификатор */
  postId: string;
};
export type UsersIndexApiResponse = /** status 200 Структура со сводной информацией */ {
  total?: number;
  message?: string;
  data?: Users[];
};
export type UsersIndexApiArg = {
  /** Смещение */
  offset?: number;
  /** Ограничение количества */
  limit?: number;
  /** Пропускаемый идентификатор */
  skipId?: string[];
  /** Идентификатор */
  _id?: string[];
  /** Сортировка */
  sort: 'rating' | 'nps' | 'subscribers_count' | 'created_date';
  /** ID потоков */
  streamId: string[];
  /** ID групп */
  groupId?: string[];
  /** Подписчики указанного пользователя */
  subscribersOf?: string;
  /** На кого подписан указанный пользователь */
  subscriptionsOf?: string;
  /** Логин или имя пользователя */
  username?: string;
  /** Поиск по лайкам */
  referenceLikes?: 'Comments' | 'Goals' | 'GoalsReports' | 'Publications';
  /** Поиск по реакциям */
  referenceReactions?: 'Comments' | 'StagesPoints' | 'Publications' | 'Tasks' | 'Contents' | 'Goals' | 'Messages';
  /** Id референсной сущности */
  referenceId?: string;
  /** Степень приватности относительно текущего пользователя */
  privacy?: 'private' | 'groups' | 'subscribers' | 'groups_subscribers' | 'public';
};
export type UserIndexApiResponse = /** status 200 Информация о пользователе */ UserInfo;
export type UserIndexApiArg = {
  /** ID, ident или логин */
  userId: string;
};
export type UserToggleSubscriptionApiResponse = /** status 200  */ MessageResponse;
export type UserToggleSubscriptionApiArg = {
  /** ID, ident или логин */
  userId: string;
};
export type UserAddToBlacklistApiResponse = /** status 200  */ MessageResponse;
export type UserAddToBlacklistApiArg = {
  /** ID, ident или логин */
  userId: string;
  blackListDto: BlackListDto;
};
export type UserRemoveFromBlacklistApiResponse = /** status 200  */ MessageResponse;
export type UserRemoveFromBlacklistApiArg = {
  /** ID, ident или логин */
  userId: string;
};
export type JobsIndexApiResponse = /** status 200 Структура со сводной информацией */ {
  total?: number;
  message?: string;
  data?: Jobs[];
};
export type JobsIndexApiArg = {
  /** Смещение */
  offset?: number;
  /** Ограничение количества */
  limit?: number;
  populates?: ('user' | 'task' | 'timeline')[];
  /** Статус задания */
  status?: ('new' | 'edit' | 'completed')[];
  /** ID пользователя (доступно только для суперадмина) */
  userId?: string;
};
export type JobIndexApiResponse = /** status 200 Данные о задаче */ Jobs;
export type JobIndexApiArg = {
  /** Идентификатор */
  jobId: string;
};
export type JobSafeDeleteApiResponse = /** status 200 Данные о задаче */ Jobs;
export type JobSafeDeleteApiArg = {
  /** Идентификатор */
  jobId: string;
};
export type JobUniversalCompleteApiResponse = /** status 200 Сообщение о завершении задачи */ JobCompleteReport;
export type JobUniversalCompleteApiArg = {
  /** Идентификатор */
  jobId: string;
  jobCompleteDto: JobCompleteDto;
};
export type JobMakeEditableApiResponse = /** status 200 Данные о задаче */ Jobs;
export type JobMakeEditableApiArg = {
  /** Идентификатор */
  jobId: string;
};
export type JobStructureApiResponse = /** status 200 Данные о задаче */ Jobs;
export type JobStructureApiArg = {
  /** Идентификатор */
  jobId: string;
};
export type JobTaskShortInfoApiResponse = /** status 200 Информация по задаче */ Tasks;
export type JobTaskShortInfoApiArg = {
  /** Идентификатор */
  jobId: string;
};
export type JobScriptsStatusesApiResponse = /** status 200 Список результатов проверки */ JobScriptsProgress[];
export type JobScriptsStatusesApiArg = {
  /** Идентификатор */
  jobId: string;
};
export type JobAnswersIndexApiResponse = /** status 200 Список ответов */ Answers[];
export type JobAnswersIndexApiArg = {
  /** Идентификатор */
  jobId: string;
};
export type JobAnswersSimpleApiResponse = /** status 200 Список ответов */ Answers[];
export type JobAnswersSimpleApiArg = {
  /** Идентификатор */
  jobId: string;
};
export type JobAnswersGetAnswersApiResponse = /** status 200 Список ответов */ Answers[];
export type JobAnswersGetAnswersApiArg = {
  /** Идентификатор */
  jobId: string;
  /** ID сценария */
  scriptId: string;
};
export type JobAnswersSaveAnswersApiResponse = /** status 200 Список ответов */ Answers[];
export type JobAnswersSaveAnswersApiArg = {
  /** Идентификатор */
  jobId: string;
  /** ID сценария */
  scriptId: string;
  answersBodyDto: AnswersBodyDto;
};
export type JobAnswersSavePartialAnswersApiResponse = /** status 200 Список ответов */ Answers[];
export type JobAnswersSavePartialAnswersApiArg = {
  /** Идентификатор */
  jobId: string;
  /** ID сценария */
  scriptId: string;
  answersBodyDto: AnswersBodyDto;
};
export type TasksIndexApiResponse = /** status 200 Структура со сводной информацией */ {
  total?: number;
  message?: string;
  data?: Tasks[];
};
export type TasksIndexApiArg = {
  /** Принадлежность категории */
  categoryId?: string;
  /** Смещение */
  offset?: number;
  /** Ограничение количества */
  limit?: number;
};
export type TasksCategoriesApiResponse = /** status 200 Список категорий */ TasksCategories[];
export type TasksCategoriesApiArg = void;
export type TasksUnreadedCountApiResponse = /** status 200 количество новых задач */ {
  total?: number;
  message?: string;
  data?: object;
};
export type TasksUnreadedCountApiArg = {
  /** Принадлежность категории */
  categoryId?: string;
};
export type TaskIndexApiResponse = /** status 200 Информация о задаче */ TaskInfo;
export type TaskIndexApiArg = {
  /** Идентификатор */
  taskId: string;
};
export type TaskAcceptApiResponse = /** status 200 Данные о задаче */ Jobs;
export type TaskAcceptApiArg = {
  /** Идентификатор */
  taskId: string;
  taskActionDto: TaskActionDto;
};
export type GoalsIndexApiResponse = /** status 200 Структура со сводной информацией */ {
  completed: number;
  message?: string;
  data?: Goals[];
  total?: number;
};
export type GoalsIndexApiArg = {
  /** Сортировка */
  sort: 'finish_date' | 'reward_value' | 'rating' | 'created_date';
  /** ID потока */
  streamId?: string[];
  /** ID пользователя */
  userId?: string;
  /** ID группы */
  groupId?: string[];
  /** ID типа цели */
  goalTypeId?: string;
  /** Статус цели */
  status?: 'draft' | 'new' | 'completed' | 'archived' | 'failed';
  /** Поиск по настройкам приватности */
  privacy?: 'private' | 'groups' | 'subscribers' | 'groups_subscribers' | 'public';
  /** Название */
  name?: string;
  /** Теги признаков качеств развития */
  personalQualityTagsId?: string[];
  /** Отображать удаленные */
  isDeleted?: boolean;
  /** Смещение */
  offset?: number;
  /** Ограничение количества */
  limit?: number;
};
export type GoalsAddApiResponse = /** status 200 Информация о цели */ Goals;
export type GoalsAddApiArg = {
  goalDto: GoalDto;
};
export type GoalsMyPrivateLimitApiResponse = /** status 200 Доступное значение */ AvailableLimitDto;
export type GoalsMyPrivateLimitApiArg = void;
export type GoalsTypesApiResponse = /** status 200 Типы целей */ GoalsTypes[];
export type GoalsTypesApiArg = void;
export type GoalsReportDataApiResponse = /** status 200 Информация по цели и отчету */ GoalReportData;
export type GoalsReportDataApiArg = {
  /** ID отчета */
  reportId: string;
};
export type GoalIndexApiResponse = /** status 200 Информация о цели */ Goals;
export type GoalIndexApiArg = {
  /** Идентификатор */
  goalId: string;
};
export type GoalUpdateApiResponse = /** status 200 Информация о цели */ Goals;
export type GoalUpdateApiArg = {
  /** Идентификатор */
  goalId: string;
  goalDto: GoalDto;
};
export type GoalPatchApiResponse = /** status 200 Информация о цели */ Goals;
export type GoalPatchApiArg = {
  /** Идентификатор */
  goalId: string;
  goalPartialDto: GoalPartialDto;
};
export type GoalSafeDeleteApiResponse = /** status 200 Информация о цели */ Goals;
export type GoalSafeDeleteApiArg = {
  /** Идентификатор */
  goalId: string;
};
export type GoalArchiveApiResponse = /** status 200 Информация о цели */ Goals;
export type GoalArchiveApiArg = {
  /** Идентификатор */
  goalId: string;
};
export type GoalCloneApiResponse = /** status 200 Информация о цели */ Goals;
export type GoalCloneApiArg = {
  /** Идентификатор */
  goalId: string;
  goalDto: GoalDto;
};
export type GoalSafeRestoreApiResponse = /** status 200 Информация о цели */ Goals;
export type GoalSafeRestoreApiArg = {
  /** Идентификатор */
  goalId: string;
};
export type GoalReportsIndexApiResponse = /** status 200 Список отчетов */ GoalsReports[];
export type GoalReportsIndexApiArg = {
  /** Идентификатор */
  goalId: string;
  /** Сортировка */
  sort: 'newest_first' | 'oldest_first';
};
export type GoalReportsAddApiResponse = /** status 200 Структура отчета */ GoalsReports;
export type GoalReportsAddApiArg = {
  /** Идентификатор */
  goalId: string;
  addReportDto: AddReportDto;
};
export type GoalReportIndexApiResponse = /** status 200 Структура отчета */ GoalsReports;
export type GoalReportIndexApiArg = {
  /** Идентификатор */
  goalId: string;
  /** Идентификатор */
  reportId: string;
};
export type GoalReportPatchApiResponse = /** status 200 Структура отчета */ GoalsReports;
export type GoalReportPatchApiArg = {
  /** Идентификатор */
  goalId: string;
  /** Идентификатор */
  reportId: string;
  editReportPartialDto: EditReportPartialDto;
};
export type GoalReportUpdateApiResponse = /** status 200 Структура отчета */ GoalsReports;
export type GoalReportUpdateApiArg = {
  /** Идентификатор */
  goalId: string;
  /** Идентификатор */
  reportId: string;
  editReportDto: EditReportDto;
};
export type GoalReportSafeDeleteApiResponse = /** status 200 Структура отчета */ GoalsReports;
export type GoalReportSafeDeleteApiArg = {
  /** Идентификатор */
  goalId: string;
  /** Идентификатор */
  reportId: string;
};
export type UserPhotosIndexApiResponse = /** status 200 Структура со сводной информацией */ {
  total?: number;
  message?: string;
  data?: UsersPhotos[];
};
export type UserPhotosIndexApiArg = {
  populates?: ('user' | 'file' | 'croppedFile')[];
  /** Пропускаемый идентификатор */
  skipId?: string[];
  /** Идентификатор */
  _id?: string[];
  /** ID пользователя */
  userId?: string;
};
export type UserPhotosAddApiResponse = /** status 200 Информация о фото */ UsersPhotos;
export type UserPhotosAddApiArg = {
  userPhotoAdminDto: UserPhotoAdminDto;
};
export type UserPhotosProfilePhotoApiResponse = /** status 200 Информация о фото */ UsersPhotos;
export type UserPhotosProfilePhotoApiArg = void;
export type UserPhotosDeletePhotoApiResponse = /** status 200 Сообщение о результате */ MessageResponse;
export type UserPhotosDeletePhotoApiArg = void;
export type UserPhotoIndexApiResponse = /** status 200 Информация о фото */ UsersPhotos;
export type UserPhotoIndexApiArg = {
  /** Идентификатор */
  photoId: string;
};
export type UserPhotoUpdateApiResponse = /** status 200 Информация о фото */ UsersPhotos;
export type UserPhotoUpdateApiArg = {
  /** Идентификатор */
  photoId: string;
  userPhotoPatchAdminDto: UserPhotoPatchAdminDto;
};
export type UserPhotoDeleteApiResponse = /** status 200  */ MessageResponse;
export type UserPhotoDeleteApiArg = {
  /** Идентификатор */
  photoId: string;
};
export type ProfileIndexApiResponse = /** status 200 Информация о пользователе */ UserInfo;
export type ProfileIndexApiArg = void;
export type ProfileUpdateApiResponse = /** status 200 Информация о пользователе */ UserInfo;
export type ProfileUpdateApiArg = {
  userProfileDto: UserProfileDto;
};
export type ProfileDeleteApiResponse = /** status 200 Уведомление об удалении */ MessageResponse;
export type ProfileDeleteApiArg = void;
export type ProfileCheckLoginApiResponse = /** status 200  */ MessageResponse;
export type ProfileCheckLoginApiArg = {
  userLoginDto: UserLoginDto;
};
export type ProfileBlacklistApiResponse = /** status 200  */ BlacklistUsersId;
export type ProfileBlacklistApiArg = void;
export type PolicyDto = {
  policy: string;
};
export type AgreementDto = {
  agreement: string;
};
export type AccessPoint = {
  method?: string;
  path?: string;
  prefix?: string;
};
export type RouteElement = {
  AccessAdminControl?: AccessPoint[];
  method: string;
  path: string;
  operationId: string;
};
export type MessageResponse = {
  message?: string;
};
export type ErrorMessage = {
  status: number;
  message: string;
  data?: object;
};
export type RewardsCurrency = {
  code: string | null;
  isoNumber: number | null;
};
export type Rewards = {
  name: string;
  currency?: RewardsCurrency | null;
  description?: string;
  symbol?: string;
  fileId?: string | null;
  type: 'default' | 'currency' | 'experience' | 'quality' | 'ability' | 'achivement' | 'prize';
  expKoef?: number;
  isSystem: boolean;
  isPublic: boolean;
  _id?: string;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
  enabled: boolean;
};
export type AchievementsData = {
  rewardId: string;
  reward: Rewards;
  referenceName: 'Reactions' | 'StagesPoints' | 'Stages' | 'Jobs';
  count: number;
  value: number;
};
export type ValidationErrorSchema = {
  target?: object;
  property: string;
  value?: any;
  children?: object[];
  constraints: object;
};
export type PaymentsProcessing = {
  params?: object;
  request?: object;
  hook?: object;
  error?: object;
  result?: object;
};
export type PaymentsResult = {
  paymentUrl?: string;
  error?: string;
};
export type Receipts = {
  userId: string;
  paymentId: string;
  receiptServiceId: string;
  value: number;
  url?: string;
  type?: 'buy_reward';
  status: 'new' | 'ready' | 'process' | 'success' | 'error';
  serviceType: 'kit_online' | 'cloud_kassir';
  _id?: string;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
  enabled: boolean;
};
export type Payments = {
  userId: string;
  paymentServiceId: string;
  paymentTokenId?: string;
  stagePointId: string;
  description: string;
  value: number;
  currencyName: string;
  currencyCode: string;
  processing: PaymentsProcessing;
  result: PaymentsResult;
  status: 'new' | 'wait_payment' | 'paid' | 'declined' | 'returned' | 'error';
  isSubscription: boolean;
  receipts?: Receipts[] | null;
  _id?: string;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
};
export type RegisterPaymentDto = {
  redirectURL: string;
  savePaymentMethod?: boolean;
};
export type PaymentsServices = {
  name: string;
  description?: string;
  fileId?: string | null;
  receiptServiceId?: string | null;
  type: 'yoo_kassa' | 'tinkoff_kassa' | 'paypal' | 'stripe' | 'bluesnap' | 'crypto' | 'manual';
  settings?: string;
  _id?: string;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
  enabled: boolean;
};
export type MessagesAttachments = {
  referenceName: 'Files' | 'Messages' | 'Goals' | 'Publications' | 'Quests' | 'Tasks' | 'Stages' | 'Jobs';
  referenceId: string;
  positionNumber?: number;
  _id?: string;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
};
export type RewardsValues = {
  rewardId: string;
  value: number;
};
export type ReactionsStats = {
  reactionsId: string[];
  rewardValues: RewardsValues[];
};
export type Messages = {
  userId?: string;
  roomId?: string;
  text: string;
  status: 'draft' | 'system' | 'ready' | 'banned' | 'error';
  submittedAt: string | string;
  attachments?: MessagesAttachments[];
  reactions?: ReactionsStats;
  _id?: string;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
};
export type UsersLogins = {
  userId?: string;
  _id?: string;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
  enabled: boolean;
  type: 'plain' | 'phone' | 'email';
  value: string;
};
export type Files = {
  name: string;
  path: string;
  type: string;
  size: number;
  location?: string;
  s3?: boolean;
  relationType?: 'preview' | 'jpg' | 'crop';
  relationParams?: string;
  relationId?: string;
  userId?: string;
  _id?: string;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
};
export type Streams = {
  name: string;
  description?: string;
  defaultQuestId?: string;
  defaultAdventureId?: string;
  _id?: string;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
  enabled: boolean;
};
export type GroupsStats = {
  subscribersCount: number;
  teammatesCount: number;
  goalsCount: number;
  goalsCompletedCount: number;
  publicationsCount: number;
  scoresCount: number;
  scoresPercent: number;
  likesCount: number;
  likesPercent: number;
  NPS: number;
  NPSRating: number;
  totalRating: number;
  updatedDate: string | string;
};
export type GroupsRatingPosition = {
  byNPS: number;
  totalPlaces: number;
  byTotal: number;
  updatedDate: string | string;
};
export type Groups = {
  ident?: number;
  name: string;
  short_description?: string;
  description?: string;
  externalLink?: string;
  fileId?: string;
  file?: Files;
  streamId?: string;
  stream?: Streams;
  requestsIsActive: boolean;
  membersLimit?: number;
  isDismissed?: boolean;
  dismissReason?: string;
  membersCount?: number;
  stats?: GroupsStats;
  ratingPosition?: GroupsRatingPosition;
  _id?: string;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
  enabled: boolean;
};
export type GroupsUsers = {
  title?: string;
  userId: string;
  user?: Users;
  groupId: string;
  group?: Groups;
  isLeader?: boolean;
  joinDate: string | string;
  exitDate: string | string;
  exitReason?: string;
  _id?: string;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
};
export type UsersStatus = {
  isOnline: number;
  lastSeen: number;
  updatedDate: string | string;
};
export type UsersStats = {
  subscribersCount: number;
  subscribesCount: number;
  teammatesCount: number;
  goalsCount: number;
  goalsCompletedCount: number;
  commentsCount: number;
  tasksCount: number;
  jobsCount: number;
  jobsCompletedCount: number;
  publicationsCount: number;
  groupsId: string[];
  groups: Groups[];
  streamsId: string[];
  streams: Groups[];
  scoresCount: number;
  scoresPercent: number;
  likesCount: number;
  likesPercent: number;
  NPS: number;
  NPSRating: number;
  totalRating: number;
  experience: number;
  experiencePower: number;
  updatedDate: string | string;
};
export type UsersRatingPosition = {
  byNPS: number;
  byExperience: number;
  totalPlaces: number;
  byTotal: number;
  updatedDate: string | string;
};
export type ClaimsStats = {
  count?: number;
  updatedDate?: string | string;
  isClaimed?: boolean;
  claimedDate?: string | string;
};
export type Users = {
  ident?: number;
  name: string;
  surname?: string;
  middlename?: string;
  disclaimer?: string;
  birthDate?: string | string;
  description?: string;
  gender?: 'male' | 'female' | 'not_sure';
  relationStatus?:
    | 'single'
    | 'in_relation'
    | 'engaged'
    | 'married'
    | 'civil_union'
    | 'in_love'
    | 'complicated'
    | 'in_search';
  city?: string;
  externalLink?: string;
  fileId?: string;
  backgroundFileId?: string | null;
  userLogin?: UsersLogins;
  logins?: UsersLogins[];
  groups?: GroupsUsers[];
  meIsSubscriber?: number;
  isMySubscriber?: number;
  file?: Files;
  status?: UsersStatus;
  stats?: UsersStats;
  ratingPosition?: UsersRatingPosition;
  claims?: ClaimsStats;
  _id?: string;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
  enabled: boolean;
};
export type Reactions = {
  reactionsBlockId: string;
  rewardValue: RewardsValues;
  referenceName: 'Comments' | 'StagesPoints' | 'Publications' | 'Tasks' | 'Contents' | 'Goals' | 'Messages';
  referenceId: string;
  user?: Users;
  _id?: string;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
};
export type ReactionDto = {
  reactionsBlockId: string;
  rewardValue: RewardsValues;
};
export type ReactionsBlocks = {
  name: string;
  allowedReferences: ('Comments' | 'StagesPoints' | 'Publications' | 'Tasks' | 'Contents' | 'Goals' | 'Messages')[];
  rewardValues: RewardsValues[];
  rewards: Rewards[];
  _id?: string;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
  enabled: boolean;
};
export type ReactionPatchDto = {
  rewardValue: RewardsValues;
};
export type LikesStats = {
  count?: number;
  usersId?: string[];
  topUsersId?: string[];
  topUsers?: Users[];
  updatedDate?: string | string;
};
export type QuestsPointOptionsDesign = {
  title?: string | null;
  description?: string | null;
  fileId?: string | null;
  bgFileId?: string | null;
  color?: string | null;
  borderColor?: string | null;
  containerName?: string | null;
  customPropsJSONString?: string | null;
};
export type QuestsPointOptionsTriggers = {
  isDeadline: boolean;
  isLocal: boolean;
  requiredStagePointStatus?:
    | 'new'
    | 'in_progress'
    | 'checking'
    | 'ready'
    | 'completed'
    | 'deadline'
    | 'failed'
    | 'rejected';
  setStageStatus?: 'new' | 'in_progress' | 'checking' | 'ready' | 'completed' | 'deadline' | 'failed' | 'rejected';
};
export type QuestsPointOptionsButtons = {
  title: string | null;
  confirmText?: string | null;
  fileId?: string | null;
  bgFileId?: string | null;
  color?: string | null;
  variant?: string | null;
  alwaysOnTop?: boolean | null;
};
export type QuestsPointOptionsGoals = {
  goalTypeId?: string | null;
  completeRequired: boolean;
};
export type QuestsPointOptionsPayments = {
  rewardId: string;
  paymentServicesTypes: ('yoo_kassa' | 'tinkoff_kassa' | 'paypal' | 'stripe' | 'bluesnap' | 'crypto' | 'manual')[];
  savePaymentData: boolean;
  successMessage?: string | null;
  errorMessage?: string | null;
};
export type QuestsPointOptionsQuests = {
  questId?: string;
  questsId?: string[];
};
export type AccessBase = {
  isActive: boolean;
  usersId?: string[];
  usersNotIn?: boolean;
  groupsId?: string[];
  groupsNotIn?: boolean;
  streamsId?: string[];
  streamsNotIn?: boolean;
  rewardsId?: string[];
  rewardsNotIn?: boolean;
};
export type QuestsPointOptionsTimelines = {
  access: AccessBase;
  privacy?: ('private' | 'groups' | 'subscribers' | 'groups_subscribers' | 'public') | null;
  isRequiredDeadline?: boolean;
  isBroadcast?: boolean;
  isNewQuest?: boolean;
  autoSuccess?: boolean;
  completeLimit?: number | null;
  successOffset?: number | null;
  allowRestart?: boolean;
  activationText?: string;
  сloseTimelineOnFinish?: boolean;
};
export type QuestsPointOptionsSubscriptions = {
  isActive?: boolean;
  autoStart?: boolean;
  emitPeriod: number;
  emitInterval: 'minute' | 'hour' | 'day' | 'week' | 'month' | 'year';
  strictDate?: boolean;
  emitTime?: any;
  emitLock?: boolean;
  deadlinePeriod: number;
  deadlineInterval: 'minute' | 'hour' | 'day' | 'week' | 'month' | 'year';
  completeLimit?: number | null;
  successOffset?: number | null;
  questId?: string;
  questsId?: string[];
};
export type QuestsPointOptionsTasks = {
  taskId?: string;
};
export type QuestsPointOptionsPublications = {
  videoRequired?: boolean;
  contentRequired?: boolean;
};
export type QuestsPointOptionsContents = {
  contentId?: string;
};
export type QuestsPointOptionsWidgets = {
  widgetId?: string;
  widgetsId?: string[];
  visualsId?: string[];
};
export type QuestsPointOptions = {
  isRequired: boolean;
  notInProgress?: boolean;
  showPositionNumber?: boolean;
  design?: QuestsPointOptionsDesign | null;
  triggers?: QuestsPointOptionsTriggers | null;
  buttons?: QuestsPointOptionsButtons | null;
  goals?: QuestsPointOptionsGoals | null;
  payments?: QuestsPointOptionsPayments | null;
  quests?: QuestsPointOptionsQuests | null;
  timelines?: QuestsPointOptionsTimelines | null;
  subscriptions?: QuestsPointOptionsSubscriptions | null;
  tasks?: QuestsPointOptionsTasks | null;
  publications?: QuestsPointOptionsPublications | null;
  contents?: QuestsPointOptionsContents | null;
  widgets?: QuestsPointOptionsWidgets | null;
};
export type Timelines = {
  referenceName: 'Tasks' | 'Quests' | 'QuestsPoints';
  referenceId: string;
  dateStart?: (string | string) | null;
  dateFinish?: (string | string) | null;
  streamsId?: string[];
  groupsId?: string[];
  usersId?: string[];
  questsId?: string[];
  questsPointsId?: string[];
  rewardsId?: string[];
  isNotIn?: boolean;
  access: AccessBase;
  isAllowedAfterFinishDate?: boolean;
  changeStatusOnProgressReadyOrComplete?: boolean;
  totalMatch?: boolean;
  userId?: string;
  status?: 'active' | 'building' | 'checking' | 'closed';
  _id?: string;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
};
export type QuestsPoints = {
  questId?: string;
  name?: string;
  type:
    | 'quest'
    | 'timeline'
    | 'task'
    | 'goal'
    | 'publication'
    | 'content'
    | 'button'
    | 'shortcut'
    | 'payment'
    | 'widget';
  positionNumber?: number;
  options: QuestsPointOptions;
  timelines?: Timelines[];
  _id?: string;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
  enabled: boolean;
};
export type TimelineCheckReason = {
  requirement: 'dateStart' | 'dateFinish' | 'groups' | 'streams' | 'quests' | 'questPoints' | 'rewards';
  value: string;
};
export type TimelineCheckResult = {
  available: boolean;
  skipped: boolean;
  reasons: TimelineCheckReason[];
};
export type QuestPointData = {
  questPoint: QuestsPoints;
  originId?: string;
  questType?:
    | 'default'
    | 'season'
    | 'meeting'
    | 'event'
    | 'point'
    | 'block'
    | 'adventure'
    | 'challenge'
    | 'lesson'
    | 'goal'
    | 'goal_report';
  name?: string;
  hint?: string;
  fileId?: string;
  timelineId: string;
  timelineCheck: TimelineCheckResult;
  points?: QuestPointData[];
};
export type StagesPoints = {
  stageId: string;
  userId: string;
  user?: Users;
  questPointId: string;
  timelineId: string;
  referenceName?:
    | 'Jobs'
    | 'Goals'
    | 'Users'
    | 'Stages'
    | 'Publications'
    | 'Timelines'
    | 'Contents'
    | 'Payments'
    | 'WidgetsValues';
  referenceId?: string;
  status: 'new' | 'in_progress' | 'checking' | 'ready' | 'completed' | 'deadline' | 'failed' | 'rejected';
  reactions?: ReactionsStats;
  _id?: string;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
};
export type Links = {
  title: string;
  url: string;
  isRecommended?: boolean;
};
export type QuestDesign = {
  header?: string;
  hint?: string;
  rejectTitle?: string;
  acceptTitle?: string;
  confirmTitle?: string;
  fileId?: string | null;
  backgroundFileId?: string | null;
  successImageFileId?: string | null;
  backgroundColor?: string | null;
  notionPageId?: string;
  completedText?: string;
  failedText?: string;
  estimatedText?: string;
  deadlineText?: string;
};
export type Tags = {
  name: string;
  type: 'default' | 'personal_quality' | 'hashtag' | 'quest';
  taggedCount?: number;
  _id?: string;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
  enabled: boolean;
};
export type TagsSets = {
  tagId: string;
  tag?: Tags;
  _id?: string;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
};
export type ScoresStats = {
  average?: number;
  total?: number;
  count?: number;
  NPS?: number;
  usersId?: string[];
  topUsersId?: string[];
  topUsers?: Users[];
  scoresId?: string[];
  updatedDate?: string | string;
};
export type Scores = {
  value: number;
  userId?: string;
  toUserId?: string;
  score?: ScoresStats;
  _id?: string;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
};
export type Comments = {
  content: string;
  userId?: string;
  user?: Users;
  commentId?: string;
  branchCommentId?: string;
  scoreId?: string;
  scoreInfo?: Scores;
  subCommentsCount?: number;
  branchCommentsCount?: number;
  score?: ScoresStats;
  likes?: LikesStats;
  claims?: ClaimsStats;
  reactions?: ReactionsStats;
  _id?: string;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
};
export type CommentsStats = {
  count?: number;
  lastCommentsId?: string[];
  lastComments?: Comments[];
  topCommentsId?: string[];
  topComments?: Comments[];
  updatedDate?: string | string;
};
export type Quests = {
  name: string;
  description?: string;
  contentsId?: string[];
  links?: Links[];
  design?: QuestDesign;
  type:
    | 'default'
    | 'season'
    | 'meeting'
    | 'event'
    | 'point'
    | 'block'
    | 'adventure'
    | 'challenge'
    | 'lesson'
    | 'goal'
    | 'goal_report';
  status: 'draft' | 'active' | 'archived';
  parentQuestId?: string;
  isPattern?: boolean;
  allowReject?: boolean;
  userId?: string;
  isViewed?: number;
  tagsSet?: TagsSets[];
  timelines?: Timelines[];
  score?: ScoresStats;
  comments?: CommentsStats;
  _id?: string;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
  enabled: boolean;
};
export type StageProgress = {
  requiredPointsTotal?: number;
  requiredPointsCompleted?: number;
  optionalPointsTotal?: number;
  optionalPointsCompleted?: number;
};
export type Stages = {
  questId: string;
  quest?: Quests;
  questType?:
    | 'default'
    | 'season'
    | 'meeting'
    | 'event'
    | 'point'
    | 'block'
    | 'adventure'
    | 'challenge'
    | 'lesson'
    | 'goal'
    | 'goal_report';
  parentStageId: string;
  timelineId: string;
  timeline?: Timelines;
  userId: string;
  user?: Users;
  status: 'new' | 'in_progress' | 'checking' | 'ready' | 'completed' | 'deadline' | 'failed' | 'rejected';
  readyDate: string | string;
  completeDate: string | string;
  localProgress: StageProgress;
  totalProgress: StageProgress;
  _id?: string;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
};
export type StageInfo = {
  stage: Stages;
  pointsData: StagePointData[];
  originTimelineStagePoint?: StagesPoints;
  originTimelineStage?: Stages;
};
export type StagePointData = {
  questPointData: QuestPointData;
  stagePoint?: StagesPoints;
  points?: StagePointData[];
  subscriptionsStages?: StageInfo[];
};
export type IDs = {
  _id: string[];
};
export type SetReferenceDto = {
  referenceId?: string;
};
export type ActivateTimelineDto = {
  questId?: string;
  finishDate?: string | string;
  usersId?: string[];
};
export type ShortcutDataDto = {
  quest: Quests;
  stage?: Stages;
};
export type PaymentDataDto = {
  payment: Payments;
};
export type PaymentBodyDto = {
  paymentServiceId: string;
};
export type StagesPointsSubscriptions = {
  planDateTime: string | string;
  executionDateTime?: string | string;
  lockDateTime?: string | string;
  stagePointId: string;
  timelineId?: string;
  parentStageId?: string;
  stageId?: string;
  _id?: string;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
};
export type QuestUserProgressResult = {
  stages?: Stages[];
  quest: Quests;
  questOptionalPoints: number;
  questRequiredPoints: number;
};
export type QuestInfo = {
  completedStagesCount: number;
  lastCompletedUsers: Users[];
  _id?: string;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
  enabled: boolean;
  name: string;
  description?: string;
  contentsId?: string[];
  links?: Links[];
  design?: QuestDesign;
  type:
    | 'default'
    | 'season'
    | 'meeting'
    | 'event'
    | 'point'
    | 'block'
    | 'adventure'
    | 'challenge'
    | 'lesson'
    | 'goal'
    | 'goal_report';
  status: 'draft' | 'active' | 'archived';
  parentQuestId?: string;
  isPattern?: boolean;
  allowReject?: boolean;
  userId?: string;
  isViewed?: number;
  tagsSet?: TagsSets[];
  timelines?: Timelines[];
  score?: ScoresStats;
  comments?: CommentsStats;
};
export type QuestActionDto = {
  timelineId: string;
  stagePointId?: string;
};
export type QuestPointProgress = {
  status: 'new' | 'in_progress' | 'checking' | 'ready' | 'completed' | 'deadline' | 'failed' | 'rejected';
  count: number;
  users: Users[];
};
export type QuestPointsProgressData = {
  progress: QuestPointProgress[];
  questPoint: QuestsPoints;
  originId?: string;
  questType?:
    | 'default'
    | 'season'
    | 'meeting'
    | 'event'
    | 'point'
    | 'block'
    | 'adventure'
    | 'challenge'
    | 'lesson'
    | 'goal'
    | 'goal_report';
  name?: string;
  hint?: string;
  fileId?: string;
  timelineId: string;
  timelineCheck: TimelineCheckResult;
  points?: QuestPointData[];
};
export type NotificationsAttachments = {
  referenceName:
    | 'Users'
    | 'Goals'
    | 'GoalsReports'
    | 'Publications'
    | 'Comments'
    | 'Tasks'
    | 'Jobs'
    | 'Contents'
    | 'Likes'
    | 'Reactions'
    | 'Scores'
    | 'Groups'
    | 'GroupsRequests'
    | 'GroupsInvites'
    | 'Quests';
  referenceId: string;
  _id?: string;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
};
export type Notifications = {
  userId?: string;
  type?:
    | 'like'
    | 'score'
    | 'comment'
    | 'reaction'
    | 'score_reply'
    | 'comment_reply'
    | 'new_subscription'
    | 'new_task'
    | 'new_content'
    | 'new_quest'
    | 'group_new_leader'
    | 'group_new_member'
    | 'group_leave_member'
    | 'group_new_request'
    | 'group_new_invite';
  referenceName:
    | 'Users'
    | 'Goals'
    | 'GoalsReports'
    | 'Publications'
    | 'Comments'
    | 'Tasks'
    | 'Jobs'
    | 'Contents'
    | 'Likes'
    | 'Reactions'
    | 'Scores'
    | 'Groups'
    | 'GroupsRequests'
    | 'GroupsInvites'
    | 'Quests';
  referenceId: string;
  seenAt?: string | string;
  attachments?: NotificationsAttachments[];
  _id?: string;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
};
export type UnreadedCountDto = {
  unreadCount?: number;
};
export type WidgetData = {
  name: string;
  type: 'user_profile';
  taskId?: string;
  jobId?: string;
  collectorId?: string;
};
export type Widgets = {
  name: string;
  type: 'table' | 'chart' | 'diagram' | 'radar' | 'pie_chart' | 'slider' | 'progress_bar';
  _id?: string;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
  enabled: boolean;
};
export type TemplatesOptionsSettings = {
  isRequired?: boolean;
  minValue?: string;
  maxValue?: string;
  iconSymbol?: string;
  iconFileId?: string;
  rewardsTypes?: ('default' | 'currency' | 'experience' | 'quality' | 'ability' | 'achivement' | 'prize')[];
  rewardId?: string;
};
export type TemplatesOptions = {
  name: string;
  description?: string;
  templateId?: string;
  positionNumber?: number;
  type?:
    | 'string'
    | 'text'
    | 'number'
    | 'score'
    | 'date'
    | 'checkbox'
    | 'radio'
    | 'select'
    | 'multi_select'
    | 'file'
    | 'photo'
    | 'video'
    | 'audio'
    | 'goal'
    | 'custom'
    | 'user'
    | 'reward';
  rewardValues?: RewardsValues[];
  settings?: TemplatesOptionsSettings;
  _id?: string;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
  enabled: boolean;
};
export type Templates = {
  name: string;
  description?: string;
  type:
    | 'string'
    | 'text'
    | 'number'
    | 'score'
    | 'date'
    | 'checkbox'
    | 'radio'
    | 'select'
    | 'multi_select'
    | 'file'
    | 'photo'
    | 'video'
    | 'audio'
    | 'goal'
    | 'custom'
    | 'user'
    | 'reward';
  scriptId?: string;
  _id?: string;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
  enabled: boolean;
};
export type WidgetsAttrs = {
  name: string;
  widgetId?: string;
  templateId: string;
  templateOptionId?: string;
  templateOption?: TemplatesOptions;
  template?: Templates;
  formula: 'first_value' | 'last_value' | 'list' | 'sum' | 'avg' | 'min' | 'max' | 'increment' | 'delta';
  positionNumber?: number;
  _id?: string;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
};
export type WidgetsRules = {
  name?: string;
  widgetId?: string;
  sourceAttrId: string;
  targetAttrId: string;
  rule: 'eq' | 'lte' | 'gte';
  _id?: string;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
};
export type WidgetDateValues = {
  value: string;
  date: string | string;
};
export type WidgetsAttrsValues = {
  widgetAttrId: string;
  templateOptionId: string;
  values: WidgetDateValues[];
  type:
    | 'string'
    | 'text'
    | 'number'
    | 'score'
    | 'date'
    | 'checkbox'
    | 'radio'
    | 'select'
    | 'multi_select'
    | 'file'
    | 'photo'
    | 'video'
    | 'audio'
    | 'goal'
    | 'custom'
    | 'user'
    | 'reward';
};
export type WidgetsValues = {
  widget: Widgets;
  stageId: string;
  stagePointId: string;
  userId: string;
  values: WidgetsAttrsValues[];
  rewardsValues: RewardsValues[];
  _id?: string;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
};
export type WidgetInfo = {
  widget: Widgets;
  attrs: WidgetsAttrs[];
  rules: WidgetsRules[];
  values: WidgetsValues[];
};
export type WidgetsVisualsDesigns = {
  visualId?: string;
  visualPropId?: string;
  name?: string;
  customJSONString?: string;
  positionNumber?: number;
  _id?: string;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
};
export type WidgetVisualsOptionsSlider = {
  aspectRatio?: '1:1' | '16:9' | '4:5' | '4:3';
};
export type WidgetVisualsOptionsChart = any;
export type WidgetsVisualsOptions = {
  slider?: WidgetVisualsOptionsSlider;
  chart?: WidgetVisualsOptionsChart;
};
export type WidgetsVisualsProps = {
  widgetId: string;
  widgetAttrId: string;
  widget?: Widgets;
  widgetAttr?: WidgetsAttrs;
  options?: WidgetsVisualsOptions;
  custom?: object;
  positionNumber?: number;
  _id?: string;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
};
export type WidgetsVisuals = {
  widgetsId: string[];
  name: string;
  containerName: string;
  customJSONString?: string;
  designs?: WidgetsVisualsDesigns[];
  props?: WidgetsVisualsProps[];
  _id?: string;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
};
export type GoalsSettings = {
  privateGoalsLimit?: number;
};
export type CommentsSettings = {
  restoreTimeout?: number;
};
export type GroupsSettings = {
  allowInvites?: boolean;
  allowRequests?: boolean;
  allowKickout?: boolean;
  allowLeave?: boolean;
  allowChangeLeader?: boolean;
  allowDestroy?: boolean;
};
export type Settings = {
  agreement: string;
  policy: string;
  goals: GoalsSettings;
  comments: CommentsSettings;
  groups: GroupsSettings;
  _id?: string;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
};
export type Collectors = {
  code: string;
  name: string;
  type: 'index' | 'profile' | 'widget';
  positionNumber?: number;
  _id?: string;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
  enabled: boolean;
};
export type CollectorsKeys = {
  collectorId?: string;
  templateOptionId: string;
  name?: string;
  positionNumber?: number;
  _id?: string;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
  enabled: boolean;
};
export type CollectorShortValue = {
  type:
    | 'string'
    | 'text'
    | 'number'
    | 'score'
    | 'date'
    | 'checkbox'
    | 'radio'
    | 'select'
    | 'multi_select'
    | 'file'
    | 'photo'
    | 'video'
    | 'audio'
    | 'goal'
    | 'custom'
    | 'user'
    | 'reward';
  text?: string;
  value?: number;
  date?: string | string;
  fileId?: string;
  goalId?: string;
  userId?: string;
};
export type CollectorKeyValuesDto = {
  key: CollectorsKeys;
  values: CollectorShortValue[];
};
export type CollectorDataDto = {
  collector: Collectors;
  keyValues: CollectorKeyValuesDto[];
};
export type GroupsInvites = {
  groupId: string;
  group?: Groups;
  invitedUserId: string;
  invitedUser?: Users;
  userId: string;
  user?: Users;
  message: string;
  status: 'pending' | 'accepted' | 'declined' | 'deleted';
  _id?: string;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
};
export type GroupInviteDto = {
  groupId: string;
  invitedUserId: string;
  message: string;
};
export type GroupMessageResponse = {
  groupId: string;
  message: string;
};
export type GroupsRequests = {
  groupId: string;
  group?: Groups;
  userId: string;
  user?: Users;
  message: string;
  approvedUsersId: string[];
  declinedUsersId: string[];
  status: 'pending' | 'accepted' | 'declined' | 'deleted';
  _id?: string;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
};
export type GroupRequestDto = {
  groupId: string;
  message: string;
};
export type GroupData = {
  groupId: string;
  info: Groups;
  users: GroupsUsers[];
  isMember: boolean;
  isLeader: boolean;
};
export type GroupDto = {
  name: string;
  short_description?: string;
  description?: string;
  externalLink?: string;
  fileId?: string;
  requestsIsActive: boolean;
};
export type GroupPartialDto = {
  name?: string;
  short_description?: string;
  description?: string;
  externalLink?: string;
  fileId?: string;
  requestsIsActive?: boolean;
};
export type GroupDismissDto = {
  dismissReason?: string;
};
export type KickOutDto = {
  userId: string;
  reason: string;
};
export type NewLeaderDto = {
  userId: string;
};
export type ContentsCategories = {
  name: string;
  positionNumber?: number;
  _id?: string;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
  enabled: boolean;
};
export type Contents = {
  name: string;
  description?: string;
  structure?: object[];
  userId?: string;
  categoryId?: string;
  category?: ContentsCategories;
  fileId?: string;
  isSystem: boolean;
  withVideo?: boolean;
  access: AccessBase;
  file?: Files;
  tagsSet?: TagsSets[];
  score?: ScoresStats;
  comments?: CommentsStats;
  claims?: ClaimsStats;
  reactions?: ReactionsStats;
  isViewed?: number;
  _id?: string;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
  enabled: boolean;
};
export type Claims = {
  description: string;
  filesId?: string[];
  result?: string;
  status?: 'new' | 'approved' | 'rejected';
  userId?: string;
  user?: Users;
  _id?: string;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
  enabled: boolean;
};
export type ClaimDto = {
  description: string;
  filesId?: string[];
};
export type ClaimPartialDto = {
  description?: string;
  filesId?: string[];
};
export type ReferenceScoreComment = {
  referenceScoreStats: ScoresStats;
  referenceCommentsStats: CommentsStats;
  comment: Comments;
};
export type AddCommentDto = {
  comment: string;
  commentId?: string;
  score?: number;
};
export type EditCommentDto = {
  comment: string;
};
export type PublicationsAttachments = {
  referenceName: 'GoalsReports' | 'Jobs' | 'Stages';
  referenceId: string;
  _id?: string;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
  enabled: boolean;
};
export type Publications = {
  title?: string;
  content?: string;
  videoLink?: string;
  filesId?: string[];
  filesAspectRatio: '1:1' | '16:9' | '4:5' | '4:3';
  userId?: string;
  user?: Users;
  privacy: 'private' | 'groups' | 'subscribers' | 'groups_subscribers' | 'public';
  type: 'default' | 'question' | 'report' | 'content' | 'goal';
  qaStatus?: ('new' | 'answered' | 'closed' | 'declined') | null;
  jobId?: string;
  tagsSet?: TagsSets[];
  attachments?: PublicationsAttachments[];
  score?: ScoresStats;
  likes?: LikesStats;
  comments?: CommentsStats;
  claims?: ClaimsStats;
  reactions?: ReactionsStats;
  viewsCount?: number;
  _id?: string;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
  enabled: boolean;
};
export type PublicationDto = {
  title?: string;
  content?: string;
  videoLink?: string;
  filesId?: string[];
  filesAspectRatio: '1:1' | '16:9' | '4:5' | '4:3';
  privacy: 'private' | 'groups' | 'subscribers' | 'groups_subscribers' | 'public';
  type: 'default' | 'question' | 'report' | 'content' | 'goal';
  enabled: boolean;
};
export type PublicationsAttachmentsDto = {
  referenceName: 'GoalsReports' | 'Jobs' | 'Stages';
  referenceId: string;
};
export type AddPublicationDto = {
  publication: PublicationDto;
  tagsId?: string[];
  attachments?: PublicationsAttachmentsDto[];
};
export type PublicationPartialDto = {
  title?: string;
  content?: string;
  videoLink?: string;
  filesId?: string[];
  filesAspectRatio?: '1:1' | '16:9' | '4:5' | '4:3';
  privacy?: 'private' | 'groups' | 'subscribers' | 'groups_subscribers' | 'public';
  type?: 'default' | 'question' | 'report' | 'content' | 'goal';
  enabled?: boolean;
};
export type EditPublicationDto = {
  publication: PublicationPartialDto;
  tagsId?: string[];
  attachments?: PublicationsAttachmentsDto[];
};
export type SetQaStatusDto = {
  qaStatus: 'new' | 'answered' | 'closed' | 'declined';
};
export type UserInfo = {
  account: Users;
  username?: string;
  isMe: boolean;
  isTeammate: boolean;
  isSubscribed: boolean;
  isSubscriber: boolean;
};
export type BlackListDto = {
  reason?: string;
};
export type TasksCategories = {
  name: string;
  positionNumber?: number;
  _id?: string;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
  enabled: boolean;
};
export type TasksLinks = {
  title: string;
  url: string;
  isRecommended?: boolean;
};
export type Tasks = {
  name: string;
  description?: string;
  short_description?: string;
  last_description?: string;
  status: 'draft' | 'checking' | 'active' | 'closed' | 'archived';
  categoryId?: string;
  category?: TasksCategories;
  scriptsId?: string[];
  contentsId?: string[];
  filesId: string[];
  filesAspectRatio: '1:1' | '16:9' | '4:5' | '4:3';
  publicationRequired: boolean;
  lastStepNotionPageId?: string;
  publicationPlaceholder?: string;
  userId?: string;
  links?: TasksLinks[];
  widgetType?: 'user_profile';
  widgetName?: string;
  isViewed?: number;
  score?: ScoresStats;
  comments?: CommentsStats;
  claims?: ClaimsStats;
  reactions?: ReactionsStats;
  timelines?: Timelines[];
  _id?: string;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
  enabled: boolean;
};
export type Scripts = {
  name: string;
  description?: string;
  contentId?: string | null;
  content?: Contents;
  streamId?: string;
  stream?: Streams;
  _id?: string;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
  enabled: boolean;
};
export type ScriptsGroups = {
  name?: string;
  scriptId?: string;
  _id?: string;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
  enabled: boolean;
};
export type ScriptsActionsSettings = {
  isRequired?: boolean;
  isCycled?: boolean;
  minCycleIteration?: number;
  maxCycleIteration?: number;
};
export type ScriptsActions = {
  name?: string;
  scriptGroupId: string;
  scriptGroup?: ScriptsGroups;
  templateId: string;
  template?: Templates;
  positionNumber?: number;
  settings?: ScriptsActionsSettings;
  _id?: string;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
  enabled: boolean;
};
export type ScriptsLinks = {
  scriptActionId?: string;
  templateOptionId: string;
  gotoScriptGroupId?: string;
  extraRewardValues?: RewardsValues[];
  _id?: string;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
};
export type JobsStructure = {
  scripts: Scripts[];
  scriptsGroups: ScriptsGroups[];
  scriptsActions: ScriptsActions[];
  scriptsLinks: ScriptsLinks[];
  templates: Templates[];
  templatesOptions: TemplatesOptions[];
};
export type Jobs = {
  userId?: string;
  user?: Users;
  taskId?: string;
  task?: Tasks;
  timelineId?: string;
  stagePointId?: string;
  completedAt?: string | string;
  publicationId?: string;
  timeline?: Timelines;
  status: 'new' | 'edit' | 'completed';
  structure?: JobsStructure;
  score?: ScoresStats;
  comments?: CommentsStats;
  claims?: ClaimsStats;
  _id?: string;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
};
export type JobCompleteReport = {
  publicationId?: string;
  message: string;
};
export type JobCompleteDto = {
  scoreComment?: AddCommentDto;
  createPublication?: boolean;
  publication?: PublicationDto;
};
export type JobScriptsProgress = {
  scriptId: string;
  answersCount: number;
  isSuccess: boolean;
};
export type Answers = {
  jobId: string;
  taskId: string;
  scriptId: string;
  scriptGroupId: string;
  scriptActionId: string;
  templateId: string;
  templateOptionId: string;
  value: string;
  index?: number;
  status?: 'temporary' | 'draft' | 'new' | 'accepted' | 'declined';
  type?:
    | 'string'
    | 'text'
    | 'number'
    | 'score'
    | 'date'
    | 'checkbox'
    | 'radio'
    | 'select'
    | 'multi_select'
    | 'file'
    | 'photo'
    | 'video'
    | 'audio'
    | 'goal'
    | 'custom'
    | 'user'
    | 'reward';
  userId?: string;
  _id?: string;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
};
export type AnswerDto = {
  scriptActionId: string;
  templateOptionId: string;
  value: string;
  index?: number;
};
export type AnswersBodyDto = {
  answers: AnswerDto[];
};
export type TaskInfo = {
  completedJobsCount: number;
  lastCompletedUsers: Users[];
  _id?: string;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
  enabled: boolean;
  name: string;
  description?: string;
  short_description?: string;
  last_description?: string;
  status: 'draft' | 'checking' | 'active' | 'closed' | 'archived';
  categoryId?: string;
  category?: TasksCategories;
  scriptsId?: string[];
  contentsId?: string[];
  filesId: string[];
  filesAspectRatio: '1:1' | '16:9' | '4:5' | '4:3';
  publicationRequired: boolean;
  lastStepNotionPageId?: string;
  publicationPlaceholder?: string;
  userId?: string;
  links?: TasksLinks[];
  widgetType?: 'user_profile';
  widgetName?: string;
  isViewed?: number;
  score?: ScoresStats;
  comments?: CommentsStats;
  claims?: ClaimsStats;
  reactions?: ReactionsStats;
  timelines?: Timelines[];
};
export type TaskActionDto = {
  timelineId: string;
  stagePointId?: string;
};
export type GoalsTypes = {
  name: string;
  description?: string;
  rewardId?: string;
  rewardAsAnalog?: boolean;
  reward?: Rewards;
  _id?: string;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
  enabled: boolean;
};
export type GoalsSchedules = {
  isEnabled: boolean;
  actionsCount?: number;
  actionsPeriods?: 'total' | 'per_week' | 'per_month' | 'per_year';
};
export type GoalsProgress = {
  total: number;
  count: number;
  percentage: number;
};
export type Goals = {
  name: string;
  description?: string;
  originGoalId?: string;
  userId?: string;
  user?: Users;
  filesId: string[];
  filesAspectRatio: '1:1' | '16:9' | '4:5' | '4:3';
  files?: Files[];
  goalTypeId: string;
  goalType?: GoalsTypes;
  rewardValue?: RewardsValues;
  schedule?: GoalsSchedules;
  reward?: Rewards;
  planFinishDate: (string | string) | null;
  realStartDate?: (string | string) | null;
  realFinishDate?: (string | string) | null;
  status?: ('draft' | 'new' | 'completed' | 'archived' | 'failed') | null;
  privacy: 'private' | 'groups' | 'subscribers' | 'groups_subscribers' | 'public';
  progress?: GoalsProgress;
  jobId?: string;
  tagsSet?: TagsSets[];
  score?: ScoresStats;
  likes?: LikesStats;
  comments?: CommentsStats;
  claims?: ClaimsStats;
  reactions?: ReactionsStats;
  _id?: string;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
};
export type GoalDto = {
  personalQualityTagsId?: string[];
  name: string;
  description?: string;
  filesId: string[];
  filesAspectRatio: '1:1' | '16:9' | '4:5' | '4:3';
  goalTypeId: string;
  rewardValue?: RewardsValues;
  schedule?: GoalsSchedules;
  planFinishDate: (string | string) | null;
  realStartDate?: (string | string) | null;
  privacy: 'private' | 'groups' | 'subscribers' | 'groups_subscribers' | 'public';
};
export type AvailableLimitDto = {
  value: number;
};
export type GoalsReports = {
  goalId?: string;
  goal?: Goals;
  userId?: string;
  user?: Users;
  filesId?: string[];
  filesAspectRatio?: '1:1' | '16:9' | '4:5' | '4:3';
  files?: Files[];
  description: string;
  rewardValue?: RewardsValues;
  isSystem?: boolean;
  goalClosed?: boolean;
  publicationId?: string;
  publication?: Publications;
  changelog?: object[];
  likes?: LikesStats;
  localProgress?: GoalsProgress;
  claims?: ClaimsStats;
  _id?: string;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
};
export type GoalReportData = {
  goal: Goals;
  report: GoalsReports;
};
export type GoalPartialDto = {
  personalQualityTagsId?: string[];
  name?: string;
  description?: string;
  filesId?: string[];
  filesAspectRatio?: '1:1' | '16:9' | '4:5' | '4:3';
  goalTypeId?: string;
  rewardValue?: RewardsValues;
  schedule?: GoalsSchedules;
  planFinishDate?: (string | string) | null;
  realStartDate?: (string | string) | null;
  privacy?: 'private' | 'groups' | 'subscribers' | 'groups_subscribers' | 'public';
};
export type GoalReportDto = {
  filesId?: string[];
  filesAspectRatio?: '1:1' | '16:9' | '4:5' | '4:3';
  description: string;
  rewardValue?: RewardsValues;
};
export type AddReportDto = {
  goalCompleted?: boolean;
  createPublication?: boolean;
  report: GoalReportDto;
  publication?: PublicationDto;
};
export type GoalReportPartialDto = {
  filesId?: string[];
  filesAspectRatio?: '1:1' | '16:9' | '4:5' | '4:3';
  description?: string;
  rewardValue?: RewardsValues;
};
export type EditReportPartialDto = {
  report: GoalReportPartialDto;
  publication?: PublicationPartialDto;
};
export type EditReportDto = {
  report: GoalReportDto;
  publication?: PublicationDto;
};
export type UsersPhotos = {
  description?: string;
  isProfilePhoto?: boolean;
  userId?: string;
  user?: Users;
  fileId: string;
  file?: Files;
  croppedFileId: string;
  croppedFile?: Files;
  _id?: string;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
  enabled: boolean;
};
export type UserPhotoAdminDto = {
  description?: string;
  fileId: string;
  croppedFileId: string;
};
export type UserPhotoPatchAdminDto = {
  description?: string;
  croppedFileId: string;
};
export type UserProfileDto = {
  username?: string;
  deletedAt?: string;
  name: string;
  surname?: string;
  middlename?: string;
  disclaimer?: string;
  birthDate?: string | string;
  description?: string;
  gender?: 'male' | 'female' | 'not_sure';
  relationStatus?:
    | 'single'
    | 'in_relation'
    | 'engaged'
    | 'married'
    | 'civil_union'
    | 'in_love'
    | 'complicated'
    | 'in_search';
  city?: string;
  externalLink?: string;
  backgroundFileId?: string | null;
  logins?: UsersLogins[];
  meIsSubscriber?: number;
  isMySubscriber?: number;
  status?: UsersStatus;
  claims?: ClaimsStats;
};
export type UserLoginDto = {
  username?: string;
};
export type BlacklistUsersId = {
  usersId: string[];
};
export const {
  useRootPolicyQuery,
  useRootAgreementQuery,
  useRootIndexQuery,
  useRootJsonDocsQuery,
  useRootDocsQuery,
  useRootSwaggerQuery,
  usePixelIndexQuery,
  useAppLogoutMutation,
  useAchievementsIndexQuery,
  usePaymentsIndexQuery,
  usePaymentIndexQuery,
  usePaymentRegisterMutation,
  usePaymentRefreshQuery,
  usePaymentsServicesIndexQuery,
  useMessageIndexQuery,
  useReactionsIndexQuery,
  useReactionsAddMutation,
  useReactionsBlocksIndexQuery,
  useReactionIndexQuery,
  useReactionUpdateMutation,
  useReactionDeleteMutation,
  useReactionStatsQuery,
  useViewsIndexQuery,
  useViewsSetViewedMutation,
  useStagePointsIndexQuery,
  useStagePointsIdsQuery,
  useStagePointIndexQuery,
  useStagePointSetReferenceMutation,
  useStagePointClearReferenceMutation,
  useStagePointActivateTimelineMutation,
  useStagePointShortcutDataQuery,
  useStagePointPaymentDataMutation,
  useStagePointsSubscriptionsIndexQuery,
  useStagePointsSubscriptionsAddMutation,
  useStagePointsSubscriptionsStagesQuery,
  useStagePointsSubscriptionsStagesIdsQuery,
  useStageIndexQuery,
  useStagesIndexQuery,
  useStagesByTimelineQuery,
  useQuestsIndexQuery,
  useQuestsDataQuery,
  useQuestsTagsQuery,
  useQuestsUnreadedCountQuery,
  useQuestsCurrentSeasonProgressQuery,
  useQuestIndexQuery,
  useQuestAcceptMutation,
  useQuestRejectMutation,
  useQuestUserProgressQuery,
  useQuestPointsIndexQuery,
  useQuestPointsIdsQuery,
  useQuestPointsStatsQuery,
  useNotificationsIndexQuery,
  useNotificationsUnreadedCountQuery,
  useNotificationsReadAllMutation,
  useNotificationIndexQuery,
  useNotificationMarkReadMutation,
  useWidgetsIndexQuery,
  useWidgetsInfoQuery,
  useWidgetIndexQuery,
  useWidgetInfoQuery,
  useWidgetsVisualsIndexQuery,
  useSettingsIndexQuery,
  useCollectorsIndexQuery,
  useCollectorValuesIndexQuery,
  useCollectorValuesByTypeQuery,
  useTagsIndexQuery,
  useGroupsIndexQuery,
  useGroupInvitesIndexQuery,
  useGroupInvitesSendMutation,
  useGroupInviteIndexQuery,
  useGroupInviteDeleteInviteMutation,
  useGroupInviteAcceptMutation,
  useGroupInviteDeclineMutation,
  useGroupRequestsIndexQuery,
  useGroupRequestsSendMutation,
  useGroupRequestIndexQuery,
  useGroupRequestDeleteRequestMutation,
  useGroupRequestApproveVoteMutation,
  useGroupRequestDeclineVoteMutation,
  useGroupRequestApproveRequestMutation,
  useGroupRequestDeclineRequestMutation,
  useGroupIndexQuery,
  useGroupUpdateMutation,
  useGroupPatchMutation,
  useGroupDestroyMutation,
  useGroupKickOutMutation,
  useGroupLeaveGroupMutation,
  useGroupNewLeaderMutation,
  useContentsIndexQuery,
  useContentsCategoriesQuery,
  useContentsUnreadedCountQuery,
  useNotionPageIndexQuery,
  useContentIndexQuery,
  useLikesIndexQuery,
  useLikesAddMutation,
  useClaimsIndexQuery,
  useClaimsAddMutation,
  useClaimIndexQuery,
  useClaimUpdateMutation,
  useClaimPatchMutation,
  useClaimDeleteMutation,
  useCommentsIndexQuery,
  useCommentsAddMutation,
  useCommentIndexQuery,
  useCommentEditMutation,
  useCommentSafeDeleteMutation,
  useCommentSafeRestoreMutation,
  usePublicationsIndexQuery,
  usePublicationsAddMutation,
  usePublicationsTagsQuery,
  usePublicationIndexQuery,
  usePublicationUpdateMutation,
  usePublicationPatchMutation,
  usePublicationSafeDeleteMutation,
  usePublicationSetQaStatusMutation,
  usePublicationSafeRestoreMutation,
  useUsersIndexQuery,
  useUserIndexQuery,
  useUserToggleSubscriptionMutation,
  useUserAddToBlacklistMutation,
  useUserRemoveFromBlacklistMutation,
  useJobsIndexQuery,
  useJobIndexQuery,
  useJobSafeDeleteMutation,
  useJobUniversalCompleteMutation,
  useJobMakeEditableMutation,
  useJobStructureQuery,
  useJobTaskShortInfoQuery,
  useJobScriptsStatusesQuery,
  useJobAnswersIndexQuery,
  useJobAnswersSimpleQuery,
  useJobAnswersGetAnswersQuery,
  useJobAnswersSaveAnswersMutation,
  useJobAnswersSavePartialAnswersMutation,
  useTasksIndexQuery,
  useTasksCategoriesQuery,
  useTasksUnreadedCountQuery,
  useTaskIndexQuery,
  useTaskAcceptMutation,
  useGoalsIndexQuery,
  useGoalsAddMutation,
  useGoalsMyPrivateLimitQuery,
  useGoalsTypesQuery,
  useGoalsReportDataQuery,
  useGoalIndexQuery,
  useGoalUpdateMutation,
  useGoalPatchMutation,
  useGoalSafeDeleteMutation,
  useGoalArchiveMutation,
  useGoalCloneMutation,
  useGoalSafeRestoreMutation,
  useGoalReportsIndexQuery,
  useGoalReportsAddMutation,
  useGoalReportIndexQuery,
  useGoalReportPatchMutation,
  useGoalReportUpdateMutation,
  useGoalReportSafeDeleteMutation,
  useUserPhotosIndexQuery,
  useUserPhotosAddMutation,
  useUserPhotosProfilePhotoQuery,
  useUserPhotosDeletePhotoMutation,
  useUserPhotoIndexQuery,
  useUserPhotoUpdateMutation,
  useUserPhotoDeleteMutation,
  useProfileIndexQuery,
  useProfileUpdateMutation,
  useProfileDeleteMutation,
  useProfileCheckLoginMutation,
  useProfileBlacklistQuery,
} = api;
