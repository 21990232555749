import {useParams} from 'react-router';
import {skipToken} from '@reduxjs/toolkit/dist/query';
import {api} from 'api';

export const useGetPublication = () => {
  const {postId} = useParams<'postId'>();
  return api.endpoints.publicationIndex.useQuery({postId: postId as string}, {refetchOnMountOrArgChange: true});
};

export const useGetPublicationById = (postId?: string | null) => () => {
  return api.endpoints.publicationIndex.useQuery(postId ? {postId} : skipToken);
};
