import {memo} from 'react';
import {Box} from '@mui/system';
import {Typography, Stack} from '@mui/material';
import {ErrorMessage} from '@hookform/error-message';

import {api} from 'api';
import {Template} from './Template';
import {TemplateArray} from './TemplateArray';
import {MarkdownRender} from 'components/MarkdownRender';

export const JobAction = memo(
  ({jobId, scriptGroupId, actionId}: {jobId: string; scriptGroupId: string; actionId: string}) => {
    const {data} = api.endpoints.jobStructure.useQuery(
      {jobId},
      {
        selectFromResult: ({data, ...rest}) => {
          if (!data || !data.structure) return {data: null};
          const {scriptsActions, templates, templatesOptions} = data.structure;
          const action = scriptsActions.find(item => item._id === actionId && item.scriptGroupId === scriptGroupId);
          if (!action) return {data: null, ...rest};

          const template = templates.find(item => item._id === action.templateId);
          const options = templatesOptions.filter(item => item.templateId === action.templateId);
          return {data: {template, action, options}};
        },
      }
    );

    if (!data || !data.template) return null;
    const {
      action: {settings},
      template,
      options,
    } = data;
    const isCycled = settings && settings.isCycled;

    return (
      <Stack spacing={2} sx={{mt: 1, '& + &': {mt: 3}}}>
        <Typography variant="h4">{data.action.name || data.template.name}</Typography>
        {data.template.description && (
          <Box>
            <MarkdownRender content={data.template.description} />
          </Box>
        )}
        <Stack spacing={2} pt={1}>
          {isCycled && (
            <TemplateArray
              options={options}
              template={template}
              scriptActionId={actionId}
              max={settings.maxCycleIteration}
              min={settings.minCycleIteration || 1}
            />
          )}
          {!isCycled && <Template options={options} index={0} template={template} scriptActionId={actionId} />}
        </Stack>
        <ErrorMessage
          name={actionId}
          render={({message}) => (
            <Box borderRadius={2} p={1} sx={{backgroundColor: 'warning.lighter'}}>
              <Typography variant="body2" color="warning.main">
                {message}
              </Typography>
            </Box>
          )}
        />
      </Stack>
    );
  }
);
