import {useState} from 'react';
import numeral from 'numeral';
import {keyBy, sum} from 'lodash';
import compose from 'lodash/fp/compose';

import {Tab, Box, Tabs, Stack, Typography} from '@mui/material';

import {UsersList} from 'modules/search';
import {useMyStreamId} from 'modules/auth';
import {useDialog} from 'components/Dialog';

import {useReactionsContext, useGetUsersSymbols} from '../../hooks';
import {ReactionsList} from './ReactionsList';

export const LastReaction = () => {
  const {referenceId, referenceName, reactionsStats, reactionBlock, myReaction, reactions} = useReactionsContext();
  const [Dialog, openDialog] = useDialog();
  const [usersId, setUsersId] = useState<string[] | undefined>();
  const [tab, setTab] = useState<string | 0>(0);
  const streamId = useMyStreamId();
  const {userRewards, usersBadges} = useGetUsersSymbols(reactions, reactionBlock);

  if (!reactionBlock || !reactionsStats) return null;

  const tabsChangeHandler = (_: any, value: string | 0) => {
    setUsersId(value ? userRewards[value] : undefined);
    setTab(value);
  };
  const rewardsObj = keyBy(reactionsStats.rewardValues, 'rewardId');
  const count = sum(Object.values(rewardsObj).map(item => item.value));
  const rewards = reactionBlock.rewards.filter(reward => Boolean(rewardsObj[reward._id as string]));

  return (
    <>
      <Dialog title="Реакции">
        <Tabs
          variant="scrollable"
          value={tab}
          onChange={tabsChangeHandler}
          scrollButtons={false}
          sx={{
            mb: 0.5,
            bgcolor: 'white',
            '& .MuiTab-root': {'&:not(:last-child)': {mr: 2}, px: 0, minWidth: 24},
          }}
        >
          <Tab label="Все" value={0} />
          {rewards.map(reward => (
            <Tab
              key={reward._id}
              label={
                <Typography variant="body2" color="grey.200">
                  {userRewards[reward._id as string].length} {reward.symbol}
                </Typography>
              }
              value={reward._id}
            />
          ))}
        </Tabs>
        <UsersList
          usersBadges={usersBadges}
          params={{referenceReactions: referenceName as any, referenceId, sort: 'rating', streamId, _id: usersId}}
        />
      </Dialog>
      {count > 0 && (
        <Stack
          height={24}
          spacing={0.5}
          direction="row"
          alignItems="center"
          onClick={compose(openDialog)}
          sx={{cursor: 'pointer'}}
        >
          {!!reactionBlock?.rewards.length && <ReactionsList rewards={rewards} />}
          <Typography variant="body2">
            {!!myReaction && (
              <>
                <Box component="span" fontWeight={500}>
                  Вы
                </Box>
                {count - 1 > 0 && (
                  <Box component="span">
                    &nbsp;и еще{' '}
                    <Box fontWeight={500} component="span">
                      {numeral(count - 1).format('0a')}
                    </Box>
                  </Box>
                )}
              </>
            )}
            {!myReaction && (
              <Box component="span" fontWeight={500}>
                {count}
              </Box>
            )}
          </Typography>
        </Stack>
      )}
    </>
  );
};
