import {useField} from 'formik';
import {useEffect, useState} from 'react';
import {useDropzone} from 'react-dropzone';
import {Avatar, Box, Button, Stack} from '@mui/material';

import {filePath} from 'utils';
import {filesApi} from 'api';
import {Files} from 'api/generated/users-api';

import {DialogCropper} from 'components/Cropper';
import {useConfirmDialog} from 'components/Dialog';
import {ReactComponent as TrashIcon} from 'svg/icons/trashIcon.svg';

export const UploadGroupPhoto = ({name}: {name: string}) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [{value}, meta, {setValue}] = useField(name);
  const [file, setFile] = useState<Files>();
  const [croppedFile, setCroppedFile] = useState<Files>();
  const [uploadFile, {isLoading: isUploading}] = filesApi.endpoints.rootUpload.useMutation();
  const [ConfirmDialog, removeItem] = useConfirmDialog({onConfirm: () => setValue('')});

  const upload = async (file: File) => {
    const body = new FormData();
    body.append('file', file);
    const result = await uploadFile({body: body as any});
    if ('data' in result && result.data[0]) setFile(result.data[0]);
  };

  const {getRootProps, getInputProps} = useDropzone({
    multiple: false,
    accept: 'image/*',
    onDrop: files => upload(files[0]),
  });

  useEffect(() => {
    if (file && croppedFile) {
      const updatePhoto = async () => {
        setValue(croppedFile._id);
        setFile(undefined);
        setCroppedFile(undefined);
      };
      updatePhoto();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [croppedFile, file]);

  return (
    <>
      <ConfirmDialog title="Удалить фотографию?" btnTitle="Удалить" />
      <DialogCropper file={file} onSuccess={setCroppedFile} isUploading={isUploading} />
      <Stack direction="row" gap={2} alignItems="center">
        <Avatar sx={{width: 64, height: 64, backgroundColor: '#F7ECFE'}} src={filePath(value, 'preview')} />
        <Stack flexGrow={1} spacing={1} direction="row">
          <Box {...getRootProps()} width="100%">
            <Button variant="contained" color="secondary" size="small" fullWidth>
              {value ? 'Сменить фото' : 'Добавить фото'}
            </Button>
            <input {...getInputProps()} />
          </Box>
          {value && (
            <Button variant="contained" color="error" size="small" onClick={removeItem}>
              <TrashIcon />
            </Button>
          )}
        </Stack>
      </Stack>
    </>
  );
};
