import {Skeleton, Stack} from '@mui/material';

export const GroupSettingsPageSkeleton = () => (
  <Stack spacing={0.5}>
    <Stack p={2} spacing={2} bgcolor="white">
      <Stack direction="row" alignItems="center" spacing={2}>
        <Skeleton variant="circular" animation="wave" width={64} height={64} />
        <Stack direction="row" spacing={1} flex={1}>
          <Skeleton variant="rectangular" animation="wave" width="100%" height={32} sx={{borderRadius: 2}} />
          <Skeleton variant="rectangular" animation="wave" width={42} height={32} sx={{borderRadius: 2}} />
        </Stack>
      </Stack>
      <Stack spacing={0.5} pb={2} mb={2} borderBottom="1px solid" borderColor="grey.10">
        <Skeleton variant="text" animation="wave" width={120} height={12} sx={{transform: 'none'}} />
        <Skeleton variant="text" animation="wave" width="50%" height={16} sx={{transform: 'none'}} />
      </Stack>
      <Stack spacing={1}>
        <Skeleton variant="text" animation="wave" width="75%" height={16} sx={{transform: 'none'}} />
        <Skeleton variant="text" animation="wave" width="100%" height={16} sx={{transform: 'none'}} />
        <Skeleton variant="text" animation="wave" width="25%" height={16} sx={{transform: 'none'}} />
        <Skeleton variant="text" animation="wave" width="100%" height={16} sx={{transform: 'none'}} />
        <Skeleton variant="text" animation="wave" width="80%" height={16} sx={{transform: 'none'}} />
      </Stack>
      <Stack spacing={0.5} pb={2} mb={2} borderBottom="1px solid" borderColor="grey.10">
        <Skeleton variant="text" animation="wave" width={120} height={12} sx={{transform: 'none'}} />
        <Skeleton variant="text" animation="wave" width="50%" height={16} sx={{transform: 'none'}} />
      </Stack>
      <Stack spacing={0.5} pb={2} mb={2} borderBottom="1px solid" borderColor="grey.10">
        <Skeleton variant="text" animation="wave" width={120} height={12} sx={{transform: 'none'}} />
        <Skeleton variant="text" animation="wave" width="50%" height={16} sx={{transform: 'none'}} />
      </Stack>
      <Stack direction="row" pb={2} alignItems="center" justifyContent="space-between">
        <Skeleton variant="text" animation="wave" width="50%" height={16} sx={{transform: 'none'}} />
        <Skeleton variant="rectangular" animation="wave" width={36} height={24} sx={{borderRadius: 9}} />
      </Stack>
      <Skeleton variant="rectangular" animation="wave" width="100%" height={44} sx={{borderRadius: 2}} />
    </Stack>
    <Stack p={2} bgcolor="white">
      <Skeleton variant="rectangular" animation="wave" width="100%" height={44} sx={{borderRadius: 2}} />
    </Stack>
  </Stack>
);
