import {PropsWithChildren} from 'react';
import {Navigate, Outlet} from 'react-router';
import {GroupContext} from 'modules/groups/context';
import {useSafeContext} from 'utils';

export const GroupMemberGuard = ({children}: PropsWithChildren<{}>) => {
  const {groupId, isMember} = useSafeContext(GroupContext);
  if (!isMember) return <Navigate to={`/teams/${groupId}`} />;
  return children ? <>children</> : <Outlet />;
};
