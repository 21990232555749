import {memo} from 'react';
import keyBy from 'lodash/keyBy';
import {useToggle} from 'react-use';
import {slugify} from 'transliteration';
import {skipToken} from '@reduxjs/toolkit/dist/query';

import {api} from 'api';
import {Jobs} from 'api/generated/users-api';
import {useNavigationBlocker} from 'utils';

import {RenderContent} from 'modules/contents/containers';
import {useSaveCachedAnswers} from 'modules/tasks/containers/JobScript/hooks';
import {clearCachedJobAnswers, getCachedJobAnswers} from 'modules/tasks/containers/JobScript/utils';
import {JobScript, ScriptAnswers, TaskCompletion} from 'modules/tasks/containers';
import {HashCollapseBlock} from 'components/HashNavigation';
import {useConfirmDialog} from 'components/Dialog';

import {useMakeEditable} from './hooks';
import {StageIdProp} from 'modules/quests/types';

const JobStep = ({
  scriptId,
  jobCompleted,
  contentId,
  jobId,
  onEdit,
  onFinish,
}: {
  jobCompleted: boolean;
  jobId: string;
  scriptId: string;
  contentId?: string;
  onEdit?: () => void;
  onFinish?: () => void;
} & Partial<StageIdProp>) => {
  const [loaded, setLoaded] = useToggle(!contentId);
  return (
    <>
      {contentId && <RenderContent contentId={contentId} onLoaded={() => setLoaded(true)} />}
      {loaded && !jobCompleted && <JobScript jobId={jobId} scriptId={scriptId} onAfterSave={onFinish} />}
      {loaded && jobCompleted && <ScriptAnswers jobId={jobId} scriptId={scriptId} onEdit={onEdit} />}
    </>
  );
};

export const JobEdit = memo(
  ({job, stageId}: {job: Jobs} & Partial<StageIdProp>) => {
    const jobId = `${job._id}`;
    const {makeEditable, confirmFinishEditing, ConfirmDialog} = useMakeEditable(job);
    const saveCachedAnswers = useSaveCachedAnswers(jobId);
    const [RestoreDataDialog] = useConfirmDialog({
      title: 'Восстановить данные формы?',
      text: 'У вас есть локально сохранённые на этом устройстве данные для задания. Вы хотите их восстановить?',
      btnTitle: 'Восстановить',
      cancelBtnTitle: 'Нет',
      onConfirm: saveCachedAnswers,
      onCancel: () => clearCachedJobAnswers(jobId),
      defaultOpen: true,
    });
    useNavigationBlocker(job.status !== 'completed');

    api.endpoints.jobStructure.useQuerySubscription({jobId});
    const {data: statuses = []} = api.endpoints.jobScriptsStatuses.useQuery(
      job.status === 'completed' ? skipToken : {jobId},
      {selectFromResult: ({data}) => ({data})}
    );

    const scripts = job.structure?.scripts || [];
    const jobCompleted = job.status === 'completed';
    const scriptStatuses = keyBy(statuses, 'scriptId');
    const defaultOpenedIndex = job.status === 'edit' ? 0 : statuses.findIndex(item => !item.isSuccess);

    if (!statuses.length && !jobCompleted) return null;
    const lastStepLocked = !Object.values(scriptStatuses).every(item => item.isSuccess);
    const isHaveCachedAnswers = !!getCachedJobAnswers(jobId);
    return (
      <>
        {ConfirmDialog && <ConfirmDialog />}
        {job.status !== 'completed' && isHaveCachedAnswers && <RestoreDataDialog />}
        {scripts.map((script, index) => {
          const locked = !jobCompleted && !statuses[index]?.isSuccess && index !== 0 && !statuses[index - 1]?.isSuccess;
          return (
            <HashCollapseBlock
              key={script._id}
              index={index}
              name={script.name}
              focusOnUnlock
              label={`${index + 1} шаг`}
              hash={slugify(script.name)}
              initialState={defaultOpenedIndex === index}
              completed={jobCompleted || statuses[index]?.isSuccess}
              locked={locked}
            >
              {!locked && (
                <JobStep
                  jobId={jobId}
                  onEdit={makeEditable}
                  scriptId={`${script._id}`}
                  jobCompleted={jobCompleted}
                  onFinish={confirmFinishEditing}
                  contentId={script.contentId || undefined}
                />
              )}
            </HashCollapseBlock>
          );
        })}
        {((job.status === 'completed' && job.publicationId) || job.status !== 'completed') && (
          <HashCollapseBlock
            hash="complete"
            name="Завершение"
            label="Завершение"
            focusOnUnlock
            index={scripts.length}
            completed={!!job.publicationId}
            initialState={defaultOpenedIndex === -1}
            locked={lastStepLocked}
          >
            {!lastStepLocked && (
              <TaskCompletion
                jobId={jobId}
                stageId={stageId}
                status={job.status}
                onEdit={makeEditable}
                postId={job.publicationId}
                lastStepNotionPageId={job.task?.lastStepNotionPageId}
                publicationPlaceholder={job.task?.publicationPlaceholder}
                publicationRequired={job.task?.publicationRequired || false}
              />
            )}
          </HashCollapseBlock>
        )}
      </>
    );
  },
  (p, n) => p.job._id === n.job._id && p.job.status === n.job.status
);
