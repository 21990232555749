import {api} from 'api';
import {Card, CardContent, Box, CircularProgress} from '@mui/material';
import {MarkdownRender} from 'components/MarkdownRender';

export const PrivacyPoilicyPage = () => {
  const {data, isLoading} = api.endpoints.rootPolicy.useQuery(undefined);

  return (
    <Card>
      <CardContent>
        {!data?.policy && isLoading && (
          <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', py: 4, px: 2}}>
            <CircularProgress />
          </Box>
        )}
        {data?.policy && <MarkdownRender content={data.policy || ''} />}
      </CardContent>
    </Card>
  );
};
