import {useMyId} from 'modules/auth';
import {useDispatch} from 'react-redux';
import {openPostForm} from 'store/uiModule';

import {ReactComponent as EditIcon} from 'svg/icons/editIcon.svg';
import {ReactComponent as CreatePostIcon} from 'svg/icons/postCreateIcon.svg';

export const usePublicationCreate = () => {
  const dispatch = useDispatch();
  const isAuth = Boolean(useMyId());

  if (!isAuth) return null;
  return () => dispatch(openPostForm({}));
};

export const usePublicationFormMenuItem = (postId?: string) => {
  const dispatch = useDispatch();
  const isAuth = Boolean(useMyId());

  if (!isAuth) return null;
  const openEdit = () => dispatch(openPostForm({entryId: postId}));
  return {
    onClick: openEdit,
    icon: postId ? <EditIcon /> : <CreatePostIcon />,
    label: !postId ? 'Написать пост' : 'Редактировать',
  };
};
