import {memo} from 'react';
import LoadingButton from '@mui/lab/LoadingButton';

import {StagePointComponent} from '../../types';
import {useGetStagePointByIdWithSelector} from '../../hooks';
import {buttonStagePointPropsSelector, isCompletedStatus} from '../../utils';

export const ButtonStagePoint: StagePointComponent = memo(({stageId, usePressOptions, stagePointId}) => {
  const {canPress, isLoading, onPress} = usePressOptions({stagePointId, stageId});
  const point = useGetStagePointByIdWithSelector({stageId, stagePointId}, buttonStagePointPropsSelector);

  if (point.disabled && !point.showIfDisabled) return null;
  if (isCompletedStatus(point.status)) return null;
  return (
    <LoadingButton
      fullWidth
      size="large"
      onClick={onPress}
      loading={isLoading}
      variant={point.variant as any}
      color={isCompletedStatus(point.status) ? 'success' : 'primary'}
      disabled={point.disabled || !canPress || isCompletedStatus(point.status)}
    >
      {point.name}
    </LoadingButton>
  );
});
