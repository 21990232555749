import {FC, memo} from 'react';
import {Box, Typography} from '@mui/material';
import {WidgetIdProp} from '../../types';

export const Widget: FC<WidgetIdProp> = memo(({widgetId}) => {
  // const {data} = api.endpoints.widgetInfo.useQuery({widgetId});
  return (
    <Box m={2} mt={0} p={2} bgcolor="primary.10" borderRadius={4}>
      <Typography variant="body1">{`Виджет ${widgetId}`}</Typography>
    </Box>
  );
});
