import {useSnackbar} from 'notistack';
import {useAtomValue, useUpdateAtom} from 'jotai/utils';
import {focusAtom} from 'jotai/optics';
import {useMemo} from 'react';
import {atom} from 'jotai';

import {api, Reactions, ReactionsAddApiArg, ReactionsBlocks, ReactionsIndexApiArg} from 'api/generated/users-api';

import {useMyId} from 'modules/auth';
import {scopeAtom, reactionsStatsAtom} from 'modules/social/store';
import {ReactionsIdPopulated} from 'modules/social/reactions/types';

export const useReactionsAction = () => {
  const {referenceId, referenceName, reactionBlock, myReaction} = useReactionsContext();
  const {enqueueSnackbar} = useSnackbar();
  const [add, {isLoading: isLoadingAdd}] = api.endpoints.reactionsAdd.useMutation();
  const [update, {isLoading: isLoadingUpdate}] = api.endpoints.reactionUpdate.useMutation();
  const [deleteAction, {isLoading: isLoadingDelete}] = api.endpoints.reactionDelete.useMutation();
  const {setReactionsState} = useReactionsContext();
  const isLoading = isLoadingAdd || isLoadingUpdate || isLoadingDelete;
  const reactionsAction = async (rewardId: string) => {
    if (isLoading || !reactionBlock) return;

    let result;
    if (myReaction && myReaction.rewardValue.rewardId === rewardId) {
      result = await deleteAction({
        reactionId: myReaction._id as string,
      });
    } else if (myReaction) {
      result = await update({
        reactionId: myReaction._id as string,
        reactionPatchDto: {rewardValue: {rewardId, value: 1}},
      });
    } else {
      result = await add({
        referenceId,
        referenceName,
        reactionDto: {
          reactionsBlockId: reactionBlock._id as string,
          rewardValue: {rewardId, value: 1},
        },
      });
    }

    if ('data' in result) {
      setReactionsState(result.data);
    } else {
      enqueueSnackbar((result.error as any)?.data?.message || 'Произошло ошибка оценки', {
        persist: false,
        variant: 'error',
        autoHideDuration: 2000,
      });
    }
  };
  return reactionsAction;
};

export const useReactionsContext = () => {
  const scope = useAtomValue(scopeAtom, Symbol.for('social-keys'));
  const [referenceName, referenceId] = scope.split('.');
  const valueAtom = useMemo(() => focusAtom(reactionsStatsAtom, o => o.prop(scope)), [scope]);
  const valueRefAtom = useMemo(
    () =>
      atom(get => {
        const data = get(valueAtom);
        if (!data) return data;
        return {...data};
      }),
    [valueAtom]
  );
  const reactionsStats = useAtomValue(valueRefAtom);
  const setReactionsState = useUpdateAtom(valueAtom);
  const {reactionBlock} = api.endpoints.reactionsBlocksIndex.useQuery(undefined, {
    selectFromResult: ({data}) => ({
      reactionBlock: data?.data?.find(i => i.allowedReferences.find(i => i === referenceName)),
    }),
  });
  const {data: reactions} = api.endpoints.reactionsIndex.useQuery({
    referenceName: referenceName as ReactionsIndexApiArg['referenceName'],
    referenceId,
  });
  const myId = useMyId();
  const myReaction = useMemo(
    () => ((reactionsStats?.reactionsId as any as ReactionsIdPopulated) || []).find(i => i.userId === myId),
    [myId, reactionsStats]
  );

  return {
    referenceId,
    referenceName: referenceName as ReactionsAddApiArg['referenceName'],
    reactionsStats,
    reactionBlock,
    myReaction,
    setReactionsState,
    reactions: reactions?.data || [],
  };
};

export const useGetUsersSymbols = (reactions: Reactions[], reactionBlock?: ReactionsBlocks) => {
  return useMemo(() => {
    const userRewards: {[key: string]: string[]} = {};
    const usersBadges: {[key: string]: string} = {};
    if (reactionBlock) {
      reactionBlock.rewards.forEach(reward => {
        if (!reward._id) return;
        userRewards[reward._id as string] = [];
      });

      reactions.forEach(i => {
        const reward = reactionBlock.rewards.find(reward => reward._id === i.rewardValue.rewardId);
        if (!reward?._id || !reward.symbol || !i.user?._id) return;
        usersBadges[i.user._id] = reward.symbol;
        userRewards[reward._id].push(i.user._id);
      });
    }

    return {userRewards, usersBadges};
  }, [reactions, reactionBlock]);
};
