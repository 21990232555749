import {Box} from '@mui/system';
import {api} from 'api';
import {Tasks} from 'api/generated/users-api';

import {EmptyList} from 'components/EmptyList';
import IsExistsContainer from 'components/IsExistsContainer';
import {ReactComponent as EmptyWidget} from 'svg/emptyWidget.svg';
import {TaskAcceptButton} from '..';

export const TaskWidget = ({taskId, isMe = false}: {taskId: string; isMe?: boolean}) => {
  return (
    <IsExistsContainer hideError useFetch={() => api.endpoints.taskIndex.useQuery({taskId})}>
      {(data: Tasks) => (
        <EmptyList
          pic={<EmptyWidget />}
          text={
            isMe ? (
              <Box component="span">
                Выполните задание «
                <Box component="span" sx={{color: 'grey.700'}}>
                  {data.name}
                </Box>
                » для заполнения этой страницы
              </Box>
            ) : (
              <Box component="span">
                Участник еще не выполнил задание «
                <Box component="span" sx={{color: 'grey.700'}}>
                  {data.name}
                </Box>
                »
              </Box>
            )
          }
          action={
            isMe &&
            data.timelines &&
            data.timelines[0] && (
              <TaskAcceptButton
                taskId={taskId}
                size="small"
                text="Перейти к выполнению"
                timelineId={`${data.timelines[0]._id}`}
              />
            )
          }
        />
      )}
    </IsExistsContainer>
  );
};
