import {api} from 'api/generated/users-api';
import {useAtomValue} from 'jotai';
import {rootStageIdAtom} from '../../Quest/atom';

export const useRegisterPaymentAction = (pointId?: string, savePaymentData = false) => {
  const stageId = useAtomValue(rootStageIdAtom, Symbol.for('root-stage'));
  const [paymentInit] = api.endpoints.paymentRegister.useMutation();

  if (!pointId || !stageId) return () => null;
  return async (paymentId: string) => {
    const res = await paymentInit({
      paymentId,
      registerPaymentDto: {
        savePaymentMethod: savePaymentData,
        redirectURL: `${window.location.origin}/modules/${stageId}/?checkStagePointPayment=${paymentId}`,
      },
    });
    if ('data' in res && res.data?.result?.paymentUrl) window.open(res.data.result.paymentUrl, '_self');
  };
};

export const useClearReference = (pointId?: string, referenceId?: string) => {
  const [clearReference, {isLoading}] = api.endpoints.stagePointClearReference.useMutation();
  return {
    isLoading,
    clearReference: pointId && referenceId ? () => clearReference({pointId}) : undefined,
  };
};

export const usePaymentCreateAndRegisterAction = (pointId?: string, savePaymentData = false) => {
  const [paymentCreate] = api.endpoints.stagePointPaymentData.useMutation();
  const paymentRegister = useRegisterPaymentAction(pointId, savePaymentData);

  if (!pointId) return () => null;
  return async (paymentServiceId: string) => {
    const res = await paymentCreate({
      pointId,
      paymentBodyDto: {paymentServiceId},
    });
    if ('data' in res && res.data.payment._id) await paymentRegister(res.data.payment._id);
  };
};
