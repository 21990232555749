import {Stack, Typography} from '@mui/material';

import {ReactComponent as CheckIcon} from 'svg/check.svg';
import {GREY} from 'components/theme/palette';

export type GoalCompleteCounterProps = {
  total: number;
  completed?: number;
};

export const GoalCompleteCounter = ({total, completed}: GoalCompleteCounterProps) => (
  <Stack direction="row" alignItems="center">
    {!!completed && <CheckIcon />}
    <Typography variant="body2" color={GREY[200]}>
      {completed ? `${completed} / ${total}` : total}
    </Typography>
  </Stack>
);
