import _ from 'lodash/fp';
import {Provider} from 'jotai';
import {memo, PropsWithChildren} from 'react';

import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import CardContent from '@mui/material/CardContent';

import {api} from 'api';
import {Answers, Jobs} from 'api/generated/users-api';

import {useMyId} from 'modules/auth';
import {Comments} from 'modules/social/comments';
import IsExistsContainer from 'components/IsExistsContainer';

import {JobContinueButton} from '..';
import {ScriptAnswers} from '../Answers';
import {scriptAnswersAtom} from '../JobScript/atoms';

export const JobAnswersPage = ({job, children}: PropsWithChildren<{job: Jobs}>) => {
  const myId = useMyId();
  const jobId = `${job._id}`;
  return (
    <Stack spacing={0.5}>
      {myId === job.userId && (
        <Card>
          <CardContent>
            <JobContinueButton jobId={jobId} size="small" showIcon status={job.status} />
          </CardContent>
        </Card>
      )}
      <Card>
        <CardContent>{children}</CardContent>
      </Card>
      <Comments referenceName="Jobs" referenceId={jobId} />
    </Stack>
  );
};

export const JobAnswers = memo(({jobId, mode = 'page'}: {jobId: string; mode: 'page' | 'post'}) => {
  return (
    <IsExistsContainer hideError useFetch={() => api.endpoints.jobIndex.useQuery({jobId})}>
      {(job: Jobs) => (
        <IsExistsContainer hideError useFetch={() => api.endpoints.jobAnswersIndex.useQuery({jobId})}>
          {(data: Answers[]) => {
            if (!job.structure) return null;
            const groupedAnswers = _.compose(
              _.fromPairs,
              _.map(item => [item[0], _.map(_.pick(['templateOptionId', 'index', 'value']), item[1])]),
              _.toPairs,
              _.groupBy('scriptActionId')
            )(data);

            const Content = (
              <Provider initialValues={[[scriptAnswersAtom, groupedAnswers]]}>
                <Stack spacing={1}>
                  {job.structure.scripts.map(item => (
                    <ScriptAnswers p={0} scriptId={`${item._id}`} jobId={`${job._id}`} key={item._id} />
                  ))}
                </Stack>
              </Provider>
            );

            if (mode === 'post') return Content;
            return <JobAnswersPage job={job}>{Content}</JobAnswersPage>;
          }}
        </IsExistsContainer>
      )}
    </IsExistsContainer>
  );
});
