import {api} from 'api';
import numeral from 'numeral';
import last from 'lodash/last';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import {formatDate} from 'utils/dates';
import {CollectorDataDto} from 'api/generated/users-api';
import {InfoBlock} from 'components/Layout';

const isEmpty = (data: CollectorDataDto) => {
  let isEmpty = true;
  for (var key in data.keyValues) {
    if (data.keyValues[key].values.length) {
      isEmpty = false;
      break;
    }
  }
  return isEmpty;
};

export const CollectorWidget = ({code, userId}: {code: string; userId: string}) => {
  const {data} = api.endpoints.collectorValuesIndex.useQuery({code, userId});
  if (!data) return null;
  if (isEmpty(data)) return null;
  return (
    <Box sx={{mx: -1}}>
      <Grid container spacing={1} sx={{py: 0.5, gap: 0}}>
        {data?.keyValues.map(item => {
          const value = last(item.values.filter(item => ['number', 'text', 'string', 'date'].includes(item.type)));
          if (!value) return null;
          let text = value.text || '';
          if (value.type === 'number') text = numeral(value.value || 0).format('0,0');
          else if (value.type === 'date') text = formatDate(`${value.date}`, 'PPP');
          return (
            <Grid item xs={6} key={item.key._id}>
              <InfoBlock label={item.key.name || ''} value={text} size="medium" />
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};
