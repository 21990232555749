import {Stages} from 'api/generated/users-api';
import {atom} from 'jotai';

export const stageModeAtom = atom<'view' | 'interact'>('view');
export const rootStageIdAtom = atom<string | undefined>(undefined);
export const stageStatusAtom = atom<Stages['status'] | undefined>(undefined);
export const isSubQuestPointAtom = atom<boolean>(false);
export const isParentStageCompleted = atom<boolean>(get =>
  ['completed', 'failed'].includes(get(stageStatusAtom) || '')
);
