import {styled} from '@mui/system';
import {Link} from 'react-router-dom';
import {Box, Typography} from '@mui/material';

import {HeaderCountProps} from './types';
import numeral from 'numeral';

const FollowersContainerLink = styled(Link)`
  width: 100%;
  height: 44px;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
`;

const FollowersContainer = styled(Box)`
  width: 100%;
  height: 44px;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
`;

export const FollowersCount = ({label, count, link = '', Content}: HeaderCountProps) => {
  const formatted = typeof count === 'number' ? numeral(count).format('0 a') : count;
  const cont = Content || (
    <Box>
      <Typography variant="h4" align="center" color="#3a0ca3" fontWeight={500}>
        {formatted}
      </Typography>
      <Typography variant="subtitle2" color="GrayText" align="center">
        {label}
      </Typography>
    </Box>
  );
  if (!link) return <FollowersContainer>{cont}</FollowersContainer>;
  return <FollowersContainerLink to={link}>{cont}</FollowersContainerLink>;
};

export default FollowersCount;
