import * as yup from 'yup';
import 'yup-phone';

import {pick} from 'lodash';
import {ReactNode, useState} from 'react';
import {LoadingButton} from '@mui/lab';
import {Button, Stack} from '@mui/material';
import {Form, FormikProvider, useFormik} from 'formik';
import {TextField} from 'formik-mui';

import {authApi} from 'api';
import {ConfirmCodeProps} from '..';
import {PhoneField} from '../../components';
import {StyledFastField} from 'components/FormFactory';
import {VALIDATION_TEXTS} from 'utils';

const emailValidation = yup.object().shape({
  email: yup.string().email('Введите корректный email адрес').required(VALIDATION_TEXTS.required),
});

const phoneValidateion = yup.object().shape({
  phone: yup
    .string()
    .phone(undefined, undefined, 'Введите корректный номер телефона, начинающийся с +')
    .required(VALIDATION_TEXTS.required),
});

export const Login = ({
  initialLogin,
  initialLoginType,
  onSuccess,
  Header,
}: {
  Header?: ReactNode;
  initialLogin?: string;
  initialLoginType?: 'phone' | 'email';
  onSuccess: (settings: ConfirmCodeProps) => void;
}) => {
  const [mode, setMode] = useState<'phone' | 'email'>(initialLoginType || 'email');
  const [sendCode] = authApi.endpoints.loginRouteRequestCode.useMutation();
  const formik = useFormik({
    enableReinitialize: true,
    validateOnBlur: true,
    validateOnChange: false,
    validationSchema: mode === 'email' ? emailValidation : phoneValidateion,
    initialValues: {
      phone: initialLogin || ``,
      email: initialLogin || ``,
    },
    onSubmit: async (data, {setSubmitting, setErrors}) => {
      const field = data[mode];
      const result = await sendCode({body: {login: field}});
      setSubmitting(false);
      if ('data' in result) {
        onSuccess({
          login: field,
          ...pick(result.data as any, ['loginType', 'privacyConfirmationRequired', 'policyConfirmationRequired']),
        });
      }
      if ('error' in result) setErrors({[mode]: (result.error as any)?.data?.message || ''});
    },
  });

  const {isValid, isSubmitting} = formik;
  return (
    <>
      {Header}
      <FormikProvider value={formik}>
        <Form autoComplete="off">
          <Stack spacing={2}>
            {mode === 'phone' && (
              <StyledFastField
                name="phone"
                component={PhoneField}
                placeholder="Введите номер телефона"
                disabled={Boolean(initialLogin)}
              />
            )}
            {mode === 'email' && (
              <StyledFastField
                name="email"
                size="medium"
                variant="standard"
                component={TextField}
                placeholder="Введите email адрес"
                disabled={Boolean(initialLogin)}
              />
            )}
            <Stack spacing={1} pt={2}>
              <LoadingButton
                fullWidth
                type="submit"
                variant="contained"
                loading={isSubmitting}
                disabled={!isValid || isSubmitting}
              >
                <span>Выслать код&nbsp;</span>
                <span>{mode === 'email' ? 'на Email' : 'по СМС'}</span>
              </LoadingButton>
              {!initialLoginType && (
                <Button
                  fullWidth
                  variant="text"
                  disabled={isSubmitting}
                  onClick={() => setMode(mode === 'email' ? 'phone' : 'email')}
                >
                  <span>Войти по&nbsp;</span>
                  <span>{mode === 'email' ? 'номеру телефона' : 'Email адресу'}</span>
                </Button>
              )}
            </Stack>
          </Stack>
        </Form>
      </FormikProvider>
    </>
  );
};

export default Login;
