import {api} from 'api';
import {useRef} from 'react';

import {useConfirmDialog} from 'components/Dialog';
import {useSnackbar} from 'notistack';

export const useArchiveHandler = (goalId: string) => {
  const {enqueueSnackbar, closeSnackbar} = useSnackbar();
  const [archive] = api.endpoints.goalArchive.useMutation();
  const acrhiveProcess = useRef<ReturnType<typeof archive>>();
  const onConfirm = async () => {
    acrhiveProcess.current = archive({goalId});
    const result = await acrhiveProcess.current;
    const isError = 'error' in result;
    const key = enqueueSnackbar(!isError ? 'Цель архивирована' : 'Не получилось архивировать цель', {
      variant: isError ? 'error' : 'success',
    });

    setTimeout(() => {
      acrhiveProcess.current?.unsubscribe();
      closeSnackbar(key);
    }, 2000);
  };

  return useConfirmDialog({
    onConfirm,
    badge: 'alert',
    align: 'center',
    btnType: 'error',
    title: 'Вы уверены?',
    cancelBtnTitle: 'Отмена',
    btnTitle: 'Архивировать',
    text: 'При архивировании цели другие участники и модератор не смогут ее увидеть.',
  });
};

export const useRemoveHandler = (goalId: string) => {
  const {enqueueSnackbar, closeSnackbar} = useSnackbar();
  const [remove] = api.endpoints.goalSafeDelete.useMutation();
  const onConfirm = async () => {
    const result = await remove({goalId});
    const isError = 'error' in result;
    const key = enqueueSnackbar(!isError ? 'Цель удалена' : 'Не получилось удалить цель', {
      variant: isError ? 'error' : 'success',
    });

    setTimeout(() => closeSnackbar(key), 2000);
  };

  return useConfirmDialog({
    onConfirm,
    badge: 'alert',
    align: 'center',
    btnType: 'error',
    title: 'Вы уверены?',
    btnTitle: 'Удалить',
    cancelBtnTitle: 'Отмена',
    text: 'При удалении цели безвозвратно удалится ее описание, отчеты, посты с оценками, комментарии и лайки к ним, что может отразиться на вашем рейтинге в игре.',
  });
};

export const useRemoveGoalReportHandler = (reportId: string, goalId: string) => {
  const {enqueueSnackbar, closeSnackbar} = useSnackbar();
  const [remove] = api.endpoints.goalReportSafeDelete.useMutation();
  const onConfirm = async () => {
    const result = await remove({reportId, goalId});
    const isError = 'error' in result;
    const key = enqueueSnackbar(!isError ? 'Отчет по цели удален' : 'Не получилось удалить отчет по цели', {
      variant: isError ? 'error' : 'success',
    });

    setTimeout(() => closeSnackbar(key), 2000);
  };

  return useConfirmDialog({
    onConfirm,
    badge: 'alert',
    align: 'center',
    btnType: 'error',
    cancelBtnTitle: 'Нет',
    btnTitle: 'Да, удалить',
    title: 'Удалить отчет по цели?',
    text: 'Действие невозможно отменить',
  });
};
