import {Stack} from '@mui/material';
import {fill} from 'lodash';
import {UserItemSkeleton} from '../components';

export const UserListSkeleton = ({limit = 10}: {limit?: number}) => (
  <Stack spacing={0.5}>
    {fill(Array(limit), '').map((_, i) => (
      <UserItemSkeleton key={i} />
    ))}
  </Stack>
);
