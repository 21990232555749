import {FunctionComponent} from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import {TaskCompactPreviewProps} from './types';
import {TaskPreviewHeader} from './TaskPreviewHeader';

import {NotificationIndication} from 'components/Layout/NotificationIndication';
import {ReactComponent as CheckIcon} from 'svg/quest/questCheck.svg';
import {ReactComponent as TaskStatusIconThird} from 'svg/icons/taskStatusIconThird.svg';
import {LazyImage} from 'components/ImageGallery';
import {Deadline} from 'components/Deadline';
import {formatDate} from 'utils/dates';

export const TaskCompactPreview: FunctionComponent<TaskCompactPreviewProps> = ({
  sx = {},
  data,
  finishDate,
  timelines,
  status,
}) => {
  //
  return (
    <Stack
      direction="row"
      spacing={1.5}
      sx={{py: 1.5, px: 2, transition: 'background-color .3s', '&:hover': {backgroundColor: 'primary.10'}, ...sx}}
    >
      {data.filesId && (
        <Box sx={{position: 'relative'}}>
          <Box
            sx={{
              width: 56,
              height: 56,
              borderRadius: 2,
              overflow: 'hidden',
            }}
          >
            <LazyImage width={300} ratio="1:1" fileId={data.filesId[0]} alt={`Задание ${data.name}`} />
          </Box>
          {status && (
            <Box
              sx={{
                position: 'absolute',
                top: -6,
                right: -6,
                width: status === 'in_progress' ? 20 : 16,
                height: status === 'in_progress' ? 20 : 16,
                borderRadius: 5,
                display: 'flex',
                alignItems: 'center',
                backgroundColor: 'white',
                justifyContent: 'center',
                boxSizing: 'content-box',
                border: status === 'in_progress' ? undefined : '2px solid white',
              }}
            >
              {status === 'new' && <NotificationIndication isSeen={false} />}
              {status === 'in_progress' && <Box component={TaskStatusIconThird} sx={{width: 20, height: 20}} />}
              {status === 'completed' && (
                <Box
                  sx={{
                    height: 16,
                    width: 16,
                    borderRadius: 2,
                    backgroundColor: 'success.main',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Box component={CheckIcon} sx={{width: 8, height: 8, color: 'white'}} />
                </Box>
              )}
            </Box>
          )}
        </Box>
      )}
      <Stack flexGrow={1} spacing={0.5} sx={{justifyContent: 'center'}}>
        <TaskPreviewHeader name={data.name} category={data.category?.name} />
        {!finishDate && <Deadline timelines={timelines ? [timelines] : data.timelines || []} />}
        {finishDate && (
          <Box sx={{typography: 'subtitle1', color: 'grey.200'}}>{`Завершено: ${formatDate(finishDate)}`}</Box>
        )}
      </Stack>
    </Stack>
  );
};
