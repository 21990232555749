import {useNavigate} from 'react-router';
import {useEffect, useCallback} from 'react';

import {LoadingButton} from '@mui/lab';
import {Card, Box} from '@mui/material';

import {api} from 'api';
import {ERROR} from 'components/theme/palette';
import {useConfirmDialog} from 'components/Dialog';

import {ReactComponent as CloseIcon} from 'svg/icons/closeIcon.svg';

export const GroupDismiss = ({groupId}: {groupId: string}) => {
  const navigate = useNavigate();
  const [destroy, {isLoading, isSuccess}] = api.endpoints.groupDestroy.useMutation();
  const onDestroy = useCallback(() => {
    destroy({groupId, groupDismissDto: {dismissReason: ''}});
  }, [groupId, destroy]);

  useEffect(() => {
    if (isSuccess) navigate(`/teams/${groupId}`);
  }, [isSuccess, groupId, navigate]);

  const [ConfirmDialog, destroyHandler] = useConfirmDialog({onConfirm: onDestroy});
  return (
    <>
      <ConfirmDialog
        title="Расформировать команду?"
        text="Команда будет расформирована. Это действие отменить будет нельзя."
        btnTitle="Расформировать"
      />
      <Card>
        <Box p={2}>
          <LoadingButton
            fullWidth
            color="error"
            variant="contained"
            onClick={destroyHandler}
            loading={isLoading}
            startIcon={<CloseIcon stroke={ERROR.darker} />}
          >
            Расформировать команду
          </LoadingButton>
        </Box>
      </Card>
    </>
  );
};
