import {StageComponent} from '../../types';
import {StagePointList} from '../StagePointsContainers';

export const HorizontalPreviewStageVisual: StageComponent = ({stageId, hideUnavailablePoints}) => {
  return (
    <StagePointList
      stageId={stageId}
      containerName="default"
      componentName="ThumbnailStagePoint"
      horizontal
      pointShadow
      spacing={1.5}
      hideUnavailablePoints={hideUnavailablePoints}
    />
  );
};
