import {useState} from 'react';
import {useToggle} from 'react-use';
import {Box, Collapse, Skeleton, Stack, Typography} from '@mui/material';

import {QuestPointsProgressData} from 'api/generated/users-api';

import SegmentedControl from 'components/SegmentedControl';
import {WidgetImage} from 'components/EntityWidgets/WidgetImage';
import {QuestProgressChip} from 'modules/oldQuests2/components/QuestHeader';
import {QuestOverallProgress} from 'modules/oldQuests2/components/QuestOverallProgress';

import {GroupMembersStats} from './GroupMembersStats';
import {GroupQuestPointStats} from './GroupQuestPointStats';
import {useProgress, useQuestPointStats} from './hooks';

export const GroupQuestStatsItemSkeleton = () => (
  <Stack direction="row" p={1.5} spacing={2}>
    <Skeleton variant="rectangular" width={48} height={48} sx={{borderRadius: 2, flexShrink: 0}} />
    <Stack spacing={1.5} flexGrow={1}>
      <Skeleton variant="text" height={18} />
      <Skeleton variant="text" height={6} />
    </Stack>
  </Stack>
);

export const GroupQuestStatsItem = ({
  questPointData,
  defaultCollapse,
}: {
  questPointData: QuestPointsProgressData;
  defaultCollapse?: boolean;
}) => {
  const {quest, questPointsStats, isLoading} = useQuestPointStats({questId: questPointData.originId});
  const [collapse, setCollapsed] = useToggle(defaultCollapse ?? true);
  const [tab, setTab] = useState<string | number | undefined>('points');
  const progress = useProgress(questPointsStats);

  if (isLoading) return <GroupQuestStatsItemSkeleton />;
  if (!questPointsStats || !quest) return null;

  const locked = !questPointData.timelineCheck.available;
  return (
    <>
      <Stack
        py={1.5}
        px={2}
        spacing={2}
        direction="row"
        onClick={() => setCollapsed(!collapse)}
        sx={
          !locked
            ? {cursor: 'pointer', transition: 'background-color .25s', '&:hover': {bgcolor: 'primary.10'}}
            : {pointerEvents: 'none'}
        }
      >
        <WidgetImage
          size="small"
          fileId={quest.design?.fileId}
          statusProps={locked ? {status: 'locked'} : {}}
          alt={`Выполнение квеста ${questPointData.name}`}
        >
          <QuestProgressChip progress={progress} showFraction={true} sx={{position: 'absolute', bottom: -10}} />
        </WidgetImage>
        <Stack spacing={0.5} width="100%">
          <Typography variant="body1" fontWeight="500">
            {questPointData.name}
          </Typography>
          {locked ? (
            <Typography variant="subtitle1" color="grey.200">
              Модуль ещё не доступен
            </Typography>
          ) : (
            <Box pt={1}>
              <QuestOverallProgress progress={progress} withAdditional={true} />
            </Box>
          )}
        </Stack>
      </Stack>
      {!locked && (
        <Collapse in={collapse} mountOnEnter unmountOnExit>
          <Box p={2}>
            <SegmentedControl
              fullWidth
              value={tab}
              onChange={newValue => setTab(newValue)}
              buttons={[
                {value: 'points', content: 'Цели'},
                {value: 'members', content: 'Участники'},
              ]}
            />
            <Collapse in={tab === 'members'} mountOnEnter unmountOnExit>
              <GroupMembersStats questPointsStats={questPointsStats} />
            </Collapse>
            <Collapse in={tab === 'points'} mountOnEnter unmountOnExit>
              <GroupQuestPointStats questPointsStats={questPointsStats} />
            </Collapse>
          </Box>
        </Collapse>
      )}
    </>
  );
};
