import {Skeleton, Grid} from '@mui/material';
import {fill} from 'lodash';

export const GoalGridSkeleton = ({limit = 5}: {limit?: number}) => (
  <Grid container spacing="1px">
    {fill(Array(limit), '').map((_, i) => (
      <Grid item xs={4}>
        <Skeleton key={i} animation="wave" variant="rectangular" width={199} height={199} />
      </Grid>
    ))}
  </Grid>
);
