import {Card, CardContent, Stack, Typography, Paper} from '@mui/material';
import {HeaderSetup} from 'components/Layout';
import {SearchBlock, SearchTabs} from 'modules/search/components';
import PostItem from 'modules/search/components/PostItem';
import {styled} from '@mui/system';

const HighlightedText = styled('span')(() => ({
  backgroundColor: '#FEF0C7',
  borderRadius: '4px',
  fontSize: '13px',
  lineHeight: '18px',
}));

export const SearchPostsPageMarkup = () => {
  return (
    <>
      <HeaderSetup title="" />
      <Stack spacing={0.5}>
        <Card>
          <CardContent>
            <SearchBlock />
          </CardContent>
          <SearchTabs defaultValue="posts" />
        </Card>

        <Stack gap={0.25}>
          <Paper elevation={0} sx={{padding: '0 16px'}}>
            <PostItem
              userName={'ksenia.garaeva'}
              description={
                <Typography variant="body2" display="inline-block">
                  ...
                  <HighlightedText>Благо</HighlightedText>
                  дарности пост 🙏 <br />
                  Последний месяц я работаю почти без выходных, что сильно выделяет ...
                </Typography>
              }
              score={75.13}
            />
          </Paper>

          <Paper elevation={0} sx={{padding: '0 16px'}}>
            <PostItem
              userName={'ksenia.garaeva'}
              description={
                <Typography variant="body2" display="inline-block">
                  ...
                  <HighlightedText>благо</HighlightedText>
                  даря своему упорству я осуществила свою давнюю цель – я позавтракала в Кении в сафаре ...
                </Typography>
              }
              score={75.13}
              isCompleted
            />
          </Paper>

          <Paper elevation={0} sx={{padding: '0 16px'}}>
            <PostItem
              userName={'ivanderbilov'}
              description={
                <Typography variant="body2" display="inline-block">
                  ... В свое время, <HighlightedText>благо</HighlightedText>даря себя, я упустил момент, когда все
                  алгоритмы показа постов поменялись. ...
                </Typography>
              }
              score={93.2}
            />
          </Paper>
        </Stack>
      </Stack>
    </>
  );
};

export default SearchPostsPageMarkup;
