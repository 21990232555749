import {Typography, TypographyProps} from '@mui/material';
import {Timelines} from 'api/generated/users-api';
import {formatDate} from 'utils/dates';
import {getDeadline} from '../../utils';

export const Deadline = ({timeline, ...props}: {timeline?: Timelines} & TypographyProps) => {
  if (!timeline) return null;
  const dateFinish = getDeadline([timeline]);
  if (!dateFinish) return null;
  const deadline = formatDate(dateFinish, 'd MMMM');
  return <Typography {...props}>{`Завершите до ${deadline}`}</Typography>;
};
