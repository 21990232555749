import {MouseEventHandler} from 'react';
import {useNavigate} from 'react-router';
import {LazyLoadImage} from 'react-lazy-load-image-component';
import {Box, Stack, Typography, Button} from '@mui/material';
import {useSnackbar} from 'notistack';

import {api} from 'api';
import {GroupsInvites} from 'api/generated/users-api';
import {NotificationIndication} from 'components/Layout/NotificationIndication';
import {timeAgo} from 'utils/dates';
import {croppedImagePath} from 'utils';

import {ReactComponent as ChevronRight} from 'svg/chevrons/chevronRight.svg';

export const NotificationGroupInviteItem = ({invite}: {invite: GroupsInvites}) => {
  const [acceptInvite] = api.endpoints.groupInviteAccept.useMutation();
  const [declineInvite] = api.endpoints.groupInviteDecline.useMutation();
  const navigate = useNavigate();
  const {enqueueSnackbar} = useSnackbar();
  if (!invite.group) return null;

  const {_id: groupId, fileId, name} = invite.group;
  const to = `/teams/${groupId}`;

  const clickHandler: MouseEventHandler<HTMLButtonElement> = async event => {
    event.stopPropagation();
    const isAccept = event.currentTarget.dataset.action === 'accept';
    let result;
    if (isAccept) result = await acceptInvite({inviteId: invite._id as string});
    else result = await declineInvite({inviteId: invite._id as string});
    if ('data' in result) {
      enqueueSnackbar(isAccept ? 'Вы вступили в команду' : 'Приглашение отклонено', {
        autoHideDuration: 2000,
        variant: 'success',
      });
    } else {
      enqueueSnackbar((result.error as any).data.message || 'Ошибка ответа на приглашение', {
        autoHideDuration: 2000,
        variant: 'error',
      });
    }
  };

  return (
    <Stack
      spacing={1.5}
      direction="row"
      sx={{
        px: 2,
        py: 1,
        alignItems: 'flex-start',
        backgroundColor: 'transparent',
        cursor: to ? 'pointer' : undefined,
        transition: 'background-color .3s',
        '&:hover': {backgroundColor: 'primary.10', '& .MuiButton-root': {backgroundColor: 'white'}},
        '& .MuiButton-root:hover': {backgroundColor: 'grey.10'},
      }}
      onClick={to ? () => navigate(to) : undefined}
    >
      <Stack spacing={1.5} direction="row" sx={{alignItems: 'center', minHeight: 12}}>
        <NotificationIndication isSeen={invite.status !== 'pending'} sx={{flexShrink: 0}} />
        {fileId && (
          <Box
            flexShrink={0}
            sx={{
              borderRadius: '50%',
              overflow: 'hidden',
              '& span': {verticalAlign: 'top'},
            }}
          >
            <LazyLoadImage
              width={48}
              height={48}
              effect="blur"
              src={croppedImagePath(fileId, {ratio: '1:1', maxWidth: 100})}
            />
          </Box>
        )}
      </Stack>
      <Stack flexGrow={1} spacing={0.5} sx={{alignSelf: 'flex-start'}}>
        <Typography variant="subtitle1" sx={{color: 'grey.200'}}>
          {timeAgo(invite.createdAt)}
        </Typography>
        <Typography variant="body2" sx={{color: 'grey.200'}}>
          Вы приглашены в команду
          <br />«
          <Typography component="span" variant="body2" sx={{color: 'grey.700'}}>
            {name}
          </Typography>
          »
        </Typography>
        <Stack direction="row" spacing={1}>
          <Button variant="contained" color="secondary" size="small" data-action="accept" onClick={clickHandler}>
            Принять
          </Button>
          <Button variant="contained" color="secondary" size="small" data-action="decline" onClick={clickHandler}>
            Отклонить
          </Button>
        </Stack>
      </Stack>
      {to && (
        <Box
          component={ChevronRight}
          sx={{flexShrink: 0, width: 24, height: 24, alignSelf: 'center', color: 'grey.200'}}
        />
      )}
    </Stack>
  );
};
