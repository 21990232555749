import {useCallback} from 'react';
import {api} from 'api';
import {Jobs} from 'api/generated/users-api';
import {useConfirmDialog} from 'components/Dialog';
import {useSnackbar} from 'notistack';
import {useDispatch} from 'react-redux';
import {useAtom} from 'jotai';
import {jobDialogState} from 'modules/tasks/containers';

const makeEditable = api.endpoints.jobMakeEditable.initiate;

export const useMakeEditable = (job: Jobs) => {
  const dispatch = useDispatch();
  const {enqueueSnackbar} = useSnackbar();
  const onEditClick = useCallback(async () => {
    const result = (await dispatch(makeEditable({jobId: `${job._id}`}))) as any;
    if ('error' in result) {
      enqueueSnackbar((result.error as any).data?.message || 'Не удалось взять задачу в редактирование', {
        variant: 'error',
        autoHideDuration: 3000,
      });
    }
  }, [dispatch, job._id, enqueueSnackbar]);
  const [{open, jobId}, setState] = useAtom(jobDialogState);

  const [complete] = api.endpoints.jobUniversalComplete.useMutation();
  const [ConfirmSave, confirmFinishEditing] = useConfirmDialog({
    onConfirm: async () => {
      await complete({jobId: `${job._id}`, jobCompleteDto: {}});
      if (open && jobId) setState({open: false});
    },
    title: 'Изменения сохранены',
    text: 'Завершить редактирование ответа на задание?',
    btnType: 'primary',
    btnTitle: 'Да, завершить',
    cancelBtnTitle: 'Продолжить',
  });

  return {
    ConfirmDialog: job.status !== 'edit' ? undefined : ConfirmSave,
    makeEditable: job.status !== 'completed' ? undefined : onEditClick,
    confirmFinishEditing: job.status !== 'edit' ? undefined : confirmFinishEditing,
  };
};
