import {Box} from '@mui/material';
import {styled} from '@mui/system';
import {GREY} from 'components/theme/palette';

export const Container = styled(Box)`
  height: 64px;
  padding: 16px;
  box-sizing: border-box;
  background: white;
`;

export const FixedContainer = styled(Container)`
  top: 0;
  left: 0;
  z-index: 1000;
  position: fixed;
  width: 100%;
`;

export const Content = styled('div')`
  width: 100%;
  height: 100%;
  position: relative;
`;

export const TitleContainer = styled('div')`
  width: 100%;
  max-width: 70%;
  height: inherit;
  margin: 0 auto;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Side = styled('div')<{side: 'left' | 'right'}>`
  top: 0;
  left: ${({side}) => side === 'left' && '-8px'};
  right: ${({side}) => side === 'right' && '-8px'};
  position: absolute;

  height: 100%;
  display: flex;
  align-items: center;
`;

export const Title = styled('h1')`
  font-weight: 500;
  font-size: 17px;
  line-height: 32px;
  font-weight: 500;

  text-align: center;
  color: ${GREY[700]};
`;
