import {api} from 'api';
import {FC, PropsWithChildren} from 'react';
import {Provider, useAtomValue} from 'jotai';

import {useRefreshStage} from '../../hooks';
import {StageComponent, StageIdProp} from '../../types';
import {refreshStages, STAGE_REFRESH_SCOPE} from '../../utils/refreshStages';
import {additionalStageComponents, stagePointComponents} from '../StagesVisuals';

const RefreshProvider: FC<PropsWithChildren<StageIdProp>> = ({children, stageId}) => {
  const {onRefresh} = useRefreshStage({stageId});
  const refreshArray = useAtomValue(refreshStages, STAGE_REFRESH_SCOPE);

  return (
    <Provider scope={STAGE_REFRESH_SCOPE} initialValues={[[refreshStages, [...refreshArray, onRefresh]]]}>
      {children}
    </Provider>
  );
};

export const Stage: StageComponent = ({stageId, componentName, ...props}) => {
  api.endpoints.stagePointsIndex.useQuerySubscription({stageId, partial: true});
  const {questType} = api.endpoints.stageIndex.useQuery(
    {stageId, partial: true},
    {
      selectFromResult: ({data, ...props}) => ({
        ...props,
        status: data?.stage.status,
        name: data?.stage.quest?.name,
        questType: data?.stage.questType || 'default',
      }),
    }
  );

  let Component = stagePointComponents[questType];
  if (componentName) Component = additionalStageComponents[componentName];
  if (!Component) return null;

  return (
    <RefreshProvider stageId={stageId}>
      <Component stageId={stageId} componentName={componentName} {...props} />
    </RefreshProvider>
  );
};
