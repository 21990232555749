import Box from '@mui/material/Box';
import {useMount, usePrevious, useUpdateEffect} from 'react-use';
import {PropsWithChildren, useEffect} from 'react';

import {useUpdateAtom} from 'jotai/utils';
import {CollapseBlock, CollapseBlockProps} from 'components/CollapseBlock';

import {HashBlockProps} from './types';
import {insertHashBlock} from './atom';
import {useHashPosition} from './hook';

export const HashBlock = ({children, hash, label}: PropsWithChildren<HashBlockProps>) => {
  const {ref, scrollToRef, isCurrentHash} = useHashPosition(hash);
  const updateStore = useUpdateAtom(insertHashBlock, Symbol.for('hash-block'));

  useUpdateEffect(() => {
    if (isCurrentHash) scrollToRef();
  });

  useMount(() => {
    updateStore({hash, label, scrollToRef});
  });

  return (
    <Box ref={ref} id={hash}>
      {children}
    </Box>
  );
};

export const HashCollapseBlock = ({
  hash,
  label,
  children,
  locked,
  completed,
  focusOnUnlock,
  ...collapseProps
}: PropsWithChildren<HashBlockProps & CollapseBlockProps & {focusOnUnlock?: boolean}>) => {
  const prevLocked = usePrevious(locked);
  const {ref, scrollToRef, isCurrentHash, setHash} = useHashPosition(hash);
  const updateStore = useUpdateAtom(insertHashBlock, Symbol.for('hash-block'));

  useEffect(() => {
    if (focusOnUnlock && prevLocked && !locked) {
      setHash(hash);
      setTimeout(scrollToRef, 300);
    }
  }, [prevLocked, locked, hash, focusOnUnlock, scrollToRef, setHash]);

  useEffect(() => {
    updateStore({hash, label, locked, completed, scrollToRef});
  }, [hash, label, locked, completed, updateStore, scrollToRef]);

  return (
    <Box id={hash} ref={ref}>
      <CollapseBlock {...collapseProps} locked={locked} completed={completed} state={isCurrentHash}>
        {children}
      </CollapseBlock>
    </Box>
  );
};
