import {atom, PrimitiveAtom} from 'jotai';
import {atomWithHash} from 'jotai/utils';

export const privacyDialogAtom = atomWithHash<boolean | undefined>('privacyPolicy', undefined);
export const termsDialogAtom = atomWithHash<boolean | undefined>('termsAgreement', undefined);
export const openHash = atom(null, (_, set, atom: PrimitiveAtom<any>) => set(atom, true));
export const closeHash = atom(null, (_, set, atom: PrimitiveAtom<any>) => {
  set(atom, undefined);
  window.history.replaceState({}, '', '#');
});
