import {api} from 'api';
import {isNull} from 'lodash';
import numeral from 'numeral';

import {ReactNode, memo} from 'react';
import {Link} from 'react-router-dom';

import {Box, Card, Stack, Typography, CardContent, Avatar as MUIAvatar} from '@mui/material';

import {croppedImagePath} from 'utils';
import {GREY} from 'components/theme/palette';
import {SocialButtonsGroup} from 'components/SocialButton';
import {InfoBlockContainer} from 'components/Layout';

import {useMyId} from 'modules/auth';
import {getUserLink} from 'modules/profile/utils';
import {GoalContextMenu} from 'modules/goals/containers';
import {GoalPreviewCommonProps} from 'modules/goals/types';

import {GoalMoneyProgress} from '..';
import {GoalPrivacy} from '../GoalPrivacy';
import {formatDistance} from 'utils/dates';
import {RemovedEntry, RemovedEntryWithRestore} from 'components/RestoreEntry';
import {isAfter, parseISO} from 'date-fns';

export const GoalListItem = ({
  goal: initialData,
  chip,
  onClick,
  showMenu = true,
  withoutCard = false,
  disableLink = false,
  showContentIfDeleted,
}: GoalPreviewCommonProps & {chip?: ReactNode}) => {
  const {data: updatedData} = api.endpoints.goalIndex.useQueryState({goalId: `${initialData._id}`});
  const goal = updatedData || initialData;

  const isMy = useMyId() === goal.user?._id;
  const isPrivate = isNull(goal.name);
  const clickHandler = onClick
    ? (e: React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        onClick(goal);
      }
    : undefined;

  const finishDate = formatDistance(goal.planFinishDate);
  const timeLeft = formatDistance(goal.planFinishDate, goal.createdAt);
  const rewardValue = numeral(goal.rewardValue?.value || 0).format('0,0');
  const isArchived = goal.status === 'archived';
  const isOverdue =
    (goal.status === 'new' && !!goal.planFinishDate && isAfter(new Date(), parseISO(goal.planFinishDate))) ||
    (goal.status === 'completed' &&
      !!goal.realFinishDate &&
      !!goal.planFinishDate &&
      isAfter(parseISO(goal.realFinishDate), parseISO(goal.planFinishDate)));

  if (goal.deletedAt && !showContentIfDeleted) return <RemovedEntry />;

  const Avatar = (
    <MUIAvatar src={croppedImagePath(goal.filesId[0], {ratio: '1:1', maxWidth: 50})} sx={{width: 48, height: 48}} />
  );
  const Content = (
    <>
      {showContentIfDeleted && (
        <RemovedEntryWithRestore
          isRestored={!goal.deletedAt}
          params={{goalId: `${goal._id}`}}
          authorId={`${goal.user?._id}`}
          mutationName="goalSafeRestore"
        />
      )}
      <Stack
        gap={1.5}
        direction="row"
        sx={{
          position: 'relative',
          opacity: isArchived ? 0.7 : 1,
        }}
        onClick={!disableLink && !isPrivate ? clickHandler : undefined}
      >
        {!isPrivate &&
          (onClick ? (
            <>{Avatar}</>
          ) : (
            <Link to={getUserLink(goal.user, `goals/${goal._id}`)} style={{height: 'fit-content'}}>
              {Avatar}
            </Link>
          ))}
        {isPrivate && <GoalPrivacy fileId={goal.filesId[0]} hidePrivacyBadge round width={50} privacy={goal.privacy} />}
        <Stack flexGrow={1} spacing={1} pr={5} sx={{overflow: 'hidden'}}>
          {chip && <div>{chip}</div>}
          {!disableLink && !isPrivate && (
            <Typography
              variant="body2"
              component={onClick ? Box : Link}
              onClick={clickHandler}
              sx={{
                fontWeight: 500,
                color: GREY[700],
                lineClamp: 1,
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
              to={getUserLink(goal.user, `goals/${goal._id}`)}
            >
              {isPrivate ? 'Цель скрыта' : goal.name}
            </Typography>
          )}
          {(disableLink || isPrivate) && (
            <Typography
              variant="body2"
              sx={{
                fontWeight: 500,
                color: GREY[700],
                lineClamp: 1,
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {isPrivate ? 'Цель скрыта' : goal.name}
            </Typography>
          )}
          {!isPrivate && (
            <>
              <InfoBlockContainer
                size="small"
                items={[
                  [
                    timeLeft && {label: isOverdue ? 'Просрочена на' : 'Осталось', value: timeLeft},
                    finishDate && {label: 'Цель', value: finishDate},
                    goal.rewardValue?.value &&
                      goal.rewardValue?.value > 0 &&
                      goal.goalType?.rewardAsAnalog && {label: 'Денежный эквив.', value: rewardValue},
                  ].filter(item => !!item),
                  [
                    !goal.goalType?.rewardAsAnalog && goal.rewardValue?.value && (
                      <GoalMoneyProgress
                        size="small"
                        progress={goal.progress}
                        goal={goal.rewardValue?.value}
                        symbol={goal.reward?.symbol}
                      />
                    ),
                  ].filter(item => !!item),
                ]}
              />
            </>
          )}
          {!goal.deletedAt && (
            <SocialButtonsGroup
              likesCount={goal.likes?.count}
              commentsCount={goal.comments?.count}
              rating={goal.score?.average}
            />
          )}
        </Stack>

        {showMenu && !goal.deletedAt && (
          <Box sx={{position: 'absolute', top: 0, right: 0}}>
            <GoalContextMenu goal={goal} isMe={isMy} />
          </Box>
        )}
      </Stack>
    </>
  );

  if (withoutCard) return Content;
  return (
    <Card sx={{flexShrink: 0}}>
      <CardContent
        sx={
          onClick && {
            transition: 'background-color .2s',
            cursor: 'pointer',
            '&:hover': {bgcolor: 'primary.10'},
          }
        }
      >
        {Content}
      </CardContent>
    </Card>
  );
};

export default memo(
  GoalListItem,
  (n, p) =>
    n.goal._id === p.goal._id &&
    n.disableLink === p.disableLink &&
    n.showMenu === p.showMenu &&
    n.goal.name === p.goal.name &&
    n.goal.status === p.goal.status
);
