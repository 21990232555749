import {fill} from 'lodash';
import {PublicationSkeleton} from './PublicationSkeleton';

export const PublicationListSkeleton = ({limit = 5}: {limit?: number}) => (
  <>
    {fill(Array(limit), '').map((_, i) => (
      <PublicationSkeleton key={i} />
    ))}
  </>
);
