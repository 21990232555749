import {isEmpty, isNull} from 'lodash';

import {memo} from 'react';
import {useMeasure} from 'react-use';
import {Link} from 'react-router-dom';
import ButtonBase from '@mui/material/ButtonBase';

import {api} from 'api';
import {croppedImagePath, preventDefault} from 'utils';
import {LazyLoadImage} from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

import {useIsMe} from 'modules/auth';
import {getUserLink} from 'modules/profile/utils';
import {GoalContextMenu} from 'modules/goals/containers';
import {GoalPreviewCommonProps} from 'modules/goals/types';
import {RemovedEntry} from 'components/RestoreEntry';

import * as Styled from './styles';
import {GoalBadge} from '../GoalBadge';
import {GoalPrivacy} from '../GoalPrivacy';

type GoalGridItemProps = GoalPreviewCommonProps & {
  noMenu?: boolean;
  noTimeBadge?: boolean;
  noPrivacyBadge?: boolean;
};

const GridItem = ({
  goal: initialData,
  onClick,
  noMenu = false,
  noTimeBadge = false,
  noPrivacyBadge = false,
}: GoalGridItemProps) => {
  const {data: updatedData} = api.endpoints.goalIndex.useQueryState({goalId: `${initialData._id}`});
  const goal = updatedData || initialData;

  const {filesId} = goal;
  const isMy = useIsMe(goal.user);
  const [ref, {width}] = useMeasure<HTMLDivElement>();
  const isPrivate = isNull(goal.name);

  if (goal.deletedAt)
    return <RemovedEntry sx={{width: 1 / 1, height: 1 / 1}} direction="column" typography="subtitle1" />;
  const Content = (
    <Styled.Container ref={ref}>
      {!!goal.planFinishDate && !noTimeBadge && <GoalBadge finishDate={goal.planFinishDate} status={goal.status} />}
      {!noMenu && (
        <Styled.ActionContainer onClick={preventDefault}>
          <GoalContextMenu goal={goal} isMe={isMy} />
        </Styled.ActionContainer>
      )}
      {filesId && !isEmpty(filesId) && (
        <Styled.MediaContainer>
          {!isPrivate && width && (
            <LazyLoadImage
              src={croppedImagePath(filesId[0], {ratio: '1:1', maxWidth: 400})}
              alt={goal.name}
              width={width}
              height={width}
              effect="blur"
              style={{width: '100%', opacity: goal.status === 'archived' ? 0.6 : 1}}
            />
          )}
          {isPrivate && (
            <GoalPrivacy
              privacy={goal.privacy}
              width={width}
              fileId={goal.filesId[0]}
              hidePrivacyBadge={noPrivacyBadge}
            />
          )}
        </Styled.MediaContainer>
      )}
    </Styled.Container>
  );

  if (isPrivate) return Content;
  if (onClick)
    return (
      <ButtonBase
        onClick={() => onClick(goal)}
        sx={{width: '100%', height: '100%', transition: 'opacity .2s', '&:hover': {opacity: 0.9}}}
      >
        {Content}
      </ButtonBase>
    );
  return <Link to={`${getUserLink(goal.user)}/goals/${goal._id}`}>{Content}</Link>;
};

export const GoalGridItem = memo(
  GridItem,
  (n, p) =>
    n.goal._id === p.goal._id &&
    n.disableLink === p.disableLink &&
    n.showMenu === p.showMenu &&
    n.goal.name === p.goal.name &&
    n.goal.status === p.goal.status
);

export default GoalGridItem;
