import {fill} from 'lodash';
import {Stack} from '@mui/material';
import {GoalItemSkeleton} from './GoalItemSkeleton';

export const GoalListSkeleton = ({limit = 7}: {limit?: number}) => (
  <Stack>
    {fill(Array(limit), '').map((_, i) => (
      <GoalItemSkeleton key={i} />
    ))}
  </Stack>
);
