import {Skeleton} from '@mui/material';
import {fill} from 'lodash';

export const RoundListContentSkeleton = ({count = 4}: {count?: number}) => (
  <>
    {fill(Array(count), '').map((_, i) => (
      <Skeleton key={i} variant="circular" animation="wave" width={64} height={64} />
    ))}
  </>
);
