import Box from '@mui/material/Box';
import {Jobs, Stages} from 'api/generated/users-api';
// import {ReactComponent as New} from 'svg/statuses/new.svg';
import {ReactComponent as Failed} from 'svg/statuses/failed.svg';
import {ReactComponent as Locked} from 'svg/statuses/locked.svg';
import {ReactComponent as Rejected} from 'svg/statuses/rejected.svg';
import {ReactComponent as Completed} from 'svg/statuses/completed.svg';
import {ReactComponent as InProgress} from 'svg/statuses/in_progress.svg';
import {EntityStatusProps} from '../types';

const StatusIcons: Record<
  Stages['status'] | Jobs['status'] | 'locked',
  React.FunctionComponent<React.SVGProps<SVGSVGElement>> | null
> = {
  new: null,
  failed: Failed,
  locked: Locked,
  ready: Completed,
  deadline: null,
  edit: InProgress,
  rejected: Rejected,
  checking: Completed,
  completed: Completed,
  in_progress: InProgress,
};

export const EntityStatus: React.VFC<EntityStatusProps> = ({status, size = 16, sx}) => {
  if (!status) return null;
  const Component = StatusIcons[status];
  if (!Component) return null;
  return (
    <Box
      sx={sx}
      width={size}
      height={size}
      borderRadius={2}
      display="flex"
      alignItems="center"
      justifyContent="center"
      boxSizing="content-box"
    >
      <Box component={Component} width={16} height={16} />
    </Box>
  );
};
