/* eslint-disable react-hooks/exhaustive-deps */
import {TextField} from 'formik-mui';
import {Form, FormikProvider} from 'formik';
import {skipToken} from '@reduxjs/toolkit/dist/query';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import CardContent from '@mui/material/CardContent';
import FormControlLabel from '@mui/material/FormControlLabel';

import {Goals, GoalsReports} from 'api/generated/users-api';
import {PrivacyField, StyledFastField} from 'components/FormFactory';
import {PhotoField} from 'components/FormFactory/Field/PhotoField';

import {GoalListItem, GoalMoneyProgress} from 'modules/goals/components';
import {ReactComponent as CheckGreenIcon} from 'svg/icons/checkFillGreen.svg';

import {api} from 'api';
import {GoalReportFormProps} from './types';

import {UserCardHeader} from 'components/Layout';
import NumberField from 'components/FormFactory/Field/NumberField';
import {StyledSwitch} from 'components/FormFactory/Field/CheckboxField';
import {CardWithoutScroll} from './styles';
import {useReportForm} from './hooks';

const ReportForm = ({goal, report, onClose}: {goal: Goals; report?: GoalsReports; onClose?: () => void}) => {
  const {onSubmit, formik, ConfirmDialog} = useReportForm(goal, report, onClose);
  const {values, getFieldProps, isValid, isSubmitting} = formik;
  const isMoneyGoal = !goal.goalType?.rewardAsAnalog && Boolean(goal.goalType?.rewardId);
  const newReward = values.report.rewardValue ? parseInt(values.report.rewardValue, 10) : 0;
  const canClose =
    !isMoneyGoal || (isMoneyGoal && (goal.rewardValue?.value || 0) <= (goal.progress?.total || 0) + newReward);

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off">
        <ConfirmDialog
          btnTitle="Да, уверен"
          btnType="primary"
          align="center"
          badge="alert"
          cancelBtnTitle="Отмена"
          text="Цель будет закрыта"
          title="Вы уверены, что полностью выполнили цель?"
        />
        <Stack spacing={0.5}>
          <Stack spacing={2} sx={{backgroundColor: 'white', px: 2}}>
            <PhotoField
              multiple
              name="report.filesId"
              type="photo"
              label="Картинка"
              ratioName="report.filesAspectRatio"
            />
            {isMoneyGoal && (
              <>
                <StyledFastField
                  name="report.rewardValue"
                  component={NumberField}
                  type="number"
                  fullWidth
                  size="medium"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  label="Количество заработанных денег"
                  placeholder="50 000 ₽"
                  variant="standard"
                />
                <GoalMoneyProgress
                  progress={report?.localProgress}
                  increment={report?.rewardValue?.value}
                  newReward={newReward}
                  goal={goal.rewardValue?.value}
                  symbol={goal.reward?.symbol}
                />
              </>
            )}
            <Box sx={{position: 'relative'}}>
              <StyledFastField
                name="report.description"
                type="textarea"
                component={TextField}
                variant="standard"
                InputLabelProps={{
                  shrink: true,
                }}
                size="medium"
                fullWidth
                placeholder={`Напишите отчет по цели`}
                multiline
                minRows={2}
                maxRows={20}
              />
            </Box>
            {!report && (
              <Card>
                <CardContent sx={{mx: -2}}>
                  <FormControlLabel
                    control={
                      <StyledSwitch
                        {...getFieldProps('createPublication')}
                        checked={values.createPublication || values.goalCompleted}
                        disabled={values.goalCompleted}
                      />
                    }
                    label="Написать пост с этим отчетом"
                    labelPlacement="start"
                    sx={{
                      mx: 0,
                      width: '100%',
                      py: 0.75,
                      minHeight: 32,
                      boxSizing: 'content-box',
                      justifyContent: 'space-between',
                    }}
                  />
                </CardContent>
              </Card>
            )}
          </Stack>
          {canClose && !report && (
            <Card sx={{backgroundColor: 'success.lighter'}}>
              <CardContent>
                <FormControlLabel
                  control={<StyledSwitch {...getFieldProps('goalCompleted')} checked={values.goalCompleted} />}
                  label={
                    <Box sx={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center', ml: -0.5}}>
                      <CheckGreenIcon />
                      <Box sx={{ml: 0.5}}>Цель полностью выполнена</Box>
                    </Box>
                  }
                  labelPlacement="start"
                  sx={{
                    mx: 0,
                    width: '100%',
                    py: 0.75,
                    minHeight: 32,
                    boxSizing: 'content-box',
                    justifyContent: 'space-between',
                  }}
                />
                {!!values.goalCompleted && (
                  <Box sx={{color: 'warning.dark', mt: 1, typography: 'subtitle1'}}>ВНИМАНИЕ! Цель будет закрыта</Box>
                )}
              </CardContent>
            </Card>
          )}
          {!report && (values.createPublication || values.goalCompleted) && (
            <Stack sx={{backgroundColor: 'white', px: 2}} spacing={2} pt={2}>
              <PrivacyField name="publication.privacy" gender="masculine" />
            </Stack>
          )}
          <div>
            <Box
              sx={{
                px: 2,
                py: 1,
                width: '100%',
                backgroundColor: '#fff',
              }}
            >
              <Button onClick={onSubmit} disabled={!isValid || isSubmitting} fullWidth variant="contained">
                {report ? 'Сохранить' : 'Отправить'} отчет
              </Button>
            </Box>
          </div>
        </Stack>
      </Form>
    </FormikProvider>
  );
};

export const GoalReportForm = ({goalId, reportId, close}: GoalReportFormProps) => {
  const {data: goal} = api.endpoints.goalIndex.useQuery({goalId});
  const {data: report} = api.endpoints.goalReportIndex.useQuery(reportId ? {goalId, reportId} : skipToken);
  if (!goal) return null;
  if (reportId && !report) return null;
  return (
    <Stack spacing={0.5} sx={{display: 'flex', flexDirection: 'column', height: '100%'}}>
      <GoalListItem goal={goal} showMenu={false} disableLink />
      <CardWithoutScroll sx={{display: 'flex', flexDirection: 'column', flexGrow: 1, overflowY: 'auto'}}>
        {goal.user && (
          <Box sx={{backgroundColor: 'white'}}>
            <UserCardHeader user={goal.user} />
          </Box>
        )}
        <Box sx={{display: 'flex', flexDirection: 'column', pt: 0, flexGrow: 1}}>
          <ReportForm goal={goal} report={report} onClose={close} />
        </Box>
      </CardWithoutScroll>
    </Stack>
  );
};

export default GoalReportForm;
