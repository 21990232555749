import {memo} from 'react';
import {Box, ButtonBase, Typography} from '@mui/material';
// import {whiteBg} from 'src/utils';
// import {Box, Typography} from 'src/components/common';
// import {StyleSheet, TouchableOpacity} from 'react-native';

import {StagePointComponent} from '../../types';
import {useGetStagePointByIdWithSelector} from '../../hooks';
import {buttonStagePointPropsSelector, isCompletedStatus} from '../../utils';

export const TopButtonStagePoint: StagePointComponent = memo(({usePressOptions, stagePointId, stageId}) => {
  const {canPress, onPress} = usePressOptions({stagePointId, stageId});
  const point = useGetStagePointByIdWithSelector({stageId, stagePointId}, buttonStagePointPropsSelector);

  if (!point) return null;
  if (isCompletedStatus(point.status)) return null;

  return (
    <ButtonBase onClick={onPress} disabled={!canPress}>
      <Box p={2}>
        <Typography variant="body1">{point.name}</Typography>
      </Box>
    </ButtonBase>
  );
});
