import isUndefined from 'lodash/isUndefined';
import {Typography, Box, Stack} from '@mui/material';

import {QuestPointStatus} from 'modules/oldQuests2/components';
import {QuestPointViewProps} from 'modules/oldQuests2/types';

import {ReactComponent as ChevronRight} from 'svg/chevrons/chevronRight.svg';

export const QuestPointView = ({
  name,
  Icon,
  locked,
  index,
  status,
  onClick,
  collapsed,
  progress,
  additionalContent,
  Content,
}: QuestPointViewProps) => {
  const withCollapse = !isUndefined(collapsed);
  return (
    <>
      <Stack
        py={1}
        px={2}
        spacing={1}
        width="100%"
        direction="row"
        onClick={onClick}
        justifyContent="space-between"
        sx={{cursor: onClick ? 'pointer' : undefined}}
      >
        <Stack spacing={2} direction="row" flexGrow={1} width="100%">
          <QuestPointStatus
            status={status}
            locked={locked}
            progress={progress}
            index={!isUndefined(index) ? index + 1 : undefined}
          />
          <Stack spacing={1} flexGrow={1} sx={{minWidth: 0}}>
            {Content || (
              <Typography fontWeight={500} variant="body1" sx={{fontSize: 17, lineHeight: '24px !important'}}>
                {name}
              </Typography>
            )}
            {additionalContent && additionalContent.element}
          </Stack>
        </Stack>
        {(Icon || withCollapse) && (
          <Box
            width={24}
            height={24}
            flexShrink={1}
            component={withCollapse ? ChevronRight : Icon}
            sx={{transform: `rotate(${(collapsed ? -1 : 1) * 90}deg)`}}
          />
        )}
      </Stack>
      {additionalContent && additionalContent.bottomElement}
    </>
  );
};
