import {Box, Card, Skeleton, Stack} from '@mui/material';

export const GoalSkeleton = () => (
  <Card>
    <Stack direction="row" alignItems="center" px={2} py={1} spacing={1.5}>
      <Skeleton variant="circular" animation="wave" width={40} height={40} />
      <Skeleton variant="text" animation="wave" width={160} height={18} sx={{transform: 'none'}} />
    </Stack>
    <Skeleton variant="rectangular" animation="wave" width={600} height={600} />
    <Stack direction="row" justifyContent="space-between" p={2}>
      <Stack direction="row" alignItems="center" spacing={1}>
        <Skeleton variant="rectangular" animation="wave" width={56} height={26} sx={{borderRadius: 12}} />
        <Skeleton variant="rectangular" animation="wave" width={56} height={26} sx={{borderRadius: 12}} />
      </Stack>
      <Skeleton variant="rectangular" animation="wave" width={120} height={26} sx={{borderRadius: 12}} />
    </Stack>
    <Stack px={2} pb={2} spacing={1}>
      <Skeleton variant="text" animation="wave" width="100%" height={14} sx={{transform: 'none'}} />
      <Skeleton variant="text" animation="wave" width="100%" height={14} sx={{transform: 'none'}} />
      <Skeleton variant="text" animation="wave" width="50%" height={14} sx={{transform: 'none'}} />
    </Stack>
    <Stack px={2} spacing={1}>
      <Stack spacing={1}>
        <Skeleton variant="text" animation="wave" width={150} height={15} sx={{transform: 'none'}} />
        <Skeleton variant="text" animation="wave" width={120} height={16} sx={{transform: 'none'}} />
      </Stack>
      <Stack direction="row" justifyContent="space-between">
        <Stack spacing={1} width="50%">
          <Skeleton variant="text" animation="wave" width={150} height={15} sx={{transform: 'none'}} />
          <Skeleton variant="text" animation="wave" width={120} height={16} sx={{transform: 'none'}} />
        </Stack>
        <Stack spacing={1} width="50%">
          <Skeleton variant="text" animation="wave" width={150} height={15} sx={{transform: 'none'}} />
          <Skeleton variant="text" animation="wave" width={120} height={16} sx={{transform: 'none'}} />
        </Stack>
      </Stack>
      <Stack spacing={1} width="50%">
        <Skeleton variant="text" animation="wave" width={150} height={15} sx={{transform: 'none'}} />
        <Skeleton variant="text" animation="wave" width={120} height={16} sx={{transform: 'none'}} />
      </Stack>
    </Stack>
    <Box p={2} bgcolor="white" mb={0.5}>
      <Skeleton variant="rectangular" animation="wave" width="100%" height={40} sx={{borderRadius: 12}} />
    </Box>
  </Card>
);
