import {Box, Skeleton, Stack} from '@mui/material';

import {GoalSliderContentSkeleton} from './GoalSliderContentSkeleton';

export const GoalSliderSkeleton = () => (
  <Stack bgcolor="white" spacing={1} pt={1} mt={0.5}>
    <Box px={2}>
      <Skeleton variant="text" animation="wave" width="35%" height={16} sx={{transform: 'none'}} />
    </Box>
    <Stack direction="row" spacing={0.25} overflow="hidden">
      <GoalSliderContentSkeleton count={7} />
    </Stack>
  </Stack>
);
