import {Skeleton, Stack} from '@mui/material';

export const TaskCompactItemSkeleton = () => (
  <Stack direction="row" px={2} py={1.5} spacing={1.5} bgcolor="white">
    <Skeleton variant="rectangular" animation="wave" width={56} height={56} sx={{borderRadius: 1}} />
    <Stack spacing={0.5} flex="1 1 auto">
      <Skeleton variant="text" animation="wave" width="50%" height={10} sx={{transform: 'none'}} />
      <Skeleton variant="rectangular" animation="wave" width="100%" height={24} sx={{borderRadius: 1}} />
      <Skeleton variant="rectangular" animation="wave" width="100%" height={12} sx={{borderRadius: 1}} />
    </Stack>
  </Stack>
);
