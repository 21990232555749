import {ReactNode} from 'react';
import {styled} from '@mui/material';

import {formatDistance} from 'utils/dates';
import {Goals} from 'api/generated/users-api';

import {ReactComponent as TimeIcon} from 'svg/icons/goalTimeIcon.svg';
import {ReactComponent as CheckIcon} from 'svg/icons/goalCheckIcon.svg';

export const Container = styled('div')`
  left: 4px;
  bottom: 4px;
  z-index: 5;
  position: absolute;

  height: 24px;
  min-width: 24px;
  padding: 4px 6px;
  box-sizing: border-box;

  display: flex;
  justify-content: center;

  color: white;
  border-radius: 12px;
`;

export const Text = styled('span')`
  display: block;
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;
  opacity: 0.8;
`;

export const Icon = styled('div')`
  display: flex;
  align-items: center;
  & + span {
    margin-left: 2px;
  }
`;

export type GoalBadgeProps = {
  finishDate: string;
  status?: Goals['status'];
};

export const GoalBadge = ({finishDate, status}: GoalBadgeProps) => {
  const isCompleted = status === 'completed';
  const showIcon = isCompleted || status === 'new';
  const showText = status === 'archived' || status === 'new';

  let IconComponent: ReactNode | null = null;
  if (showIcon) IconComponent = isCompleted ? <CheckIcon color="white" /> : <TimeIcon />;

  return (
    <Container sx={{backgroundColor: isCompleted ? '#12B76A' : 'rgba(0, 0, 0, 0.5)'}}>
      {IconComponent && <Icon sx={{opacity: isCompleted ? 1 : 0.7}}>{IconComponent}</Icon>}
      {showText && <Text>{status === 'archived' ? 'В архиве' : formatDistance(finishDate)}</Text>}
    </Container>
  );
};
