import {useCallback} from 'react';
import {useNavigate} from 'react-router';

import {api} from 'api';
import {useErrorShackbar} from 'utils';
import {StagePointData} from 'api/generated/users-api';

import {getStagePath} from '../utils';
import {useRefreshStage} from './refreshStage.hook';
import {useSetPointReference} from './setReference.hook';
import {QuestPointProps, StageIdProp, StagePointIdProp} from '../types';
import {useGetStagePointByIdWithSelector} from './getStagePointsByContainerName.hook';

const selectTimelineOptions = (point: StagePointData) => {
  return {
    questPoint: {
      timelines: point?.questPointData.questPoint.options.timelines,
      questId: point?.questPointData.questPoint.options.quests?.questId,
      selectFromMany: !!point?.questPointData.questPoint.options.quests?.questsId?.length,
    },
  };
};

export const useTimelinePointPress = ({stagePointId, stageId}: QuestPointProps) => {
  const navigate = useNavigate();
  const {
    canPress,
    stagePoint,
    referenceProccessing,
    questPoint: {timelineId, type},
  } = useSetPointReference({stagePointId, stageId});

  const {activate, isLoading} = useActivateTimeline({stagePointId, stageId});
  const {
    questPoint: {questId, selectFromMany},
  } = useGetStagePointByIdWithSelector({stagePointId, stageId}, selectTimelineOptions);

  const navigateToQuest = () => {
    if (stagePoint?.referenceId) navigate(getStagePath(stagePoint.referenceId));
  };

  const setQuestReference = useCallback(async () => {
    if (timelineId && stagePointId) {
      if (selectFromMany) {
        // navigate('timelineSelect', {stageId, stagePointId});
      } else if (questId) activate(questId);
    }
  }, [timelineId, stagePointId, questId, selectFromMany, activate]);

  return {
    isLoading: referenceProccessing || isLoading,
    canPress: !!type && ['timeline'].includes(type) && canPress,
    onPress: stagePoint?.referenceId ? navigateToQuest : setQuestReference,
  };
};

//

export const useActivateTimeline = ({stagePointId, stageId}: StagePointIdProp & StageIdProp) => {
  const navigate = useNavigate();
  const openErrorShackbar = useErrorShackbar();
  const {onRefresh} = useRefreshStage({stageId});
  const [activateTimeline, {isLoading}] = api.endpoints.stagePointActivateTimeline.useMutation();

  const activate = useCallback(
    async (questId: string) => {
      const result = await activateTimeline({pointId: stagePointId, activateTimelineDto: {questId}});
      onRefresh();
      if ('data' in result) navigate(getStagePath(`${result.data.stagePoint?.referenceId}`));
      else if ('error' in result) openErrorShackbar(result.error, 'Не удалось взять квест');
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [stagePointId, stageId, onRefresh, activateTimeline]
  );

  return {activate, isLoading};
};
