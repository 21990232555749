import {styled} from '@mui/system';
import {Link, NavLink} from 'react-router-dom';

export const Conrainer = styled(Link)`
  position: relative;

  height: 64px;

  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TopBorder = styled('div')`
  top: 0;
  position: absolute;

  height: 2px;
  width: 100%;

  background: #3a0ca3;
  border-radius: 0px 0px 2px 2px;
`;

export const SideContainer = styled(NavLink)`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
  padding: ${({theme}) => theme.spacing(1, 2)};
`;

export const SideIcon = styled('div')`
  display: flex;
  align-items: center;
  margin-right: ${({theme}) => theme.spacing(1.5)};
`;
