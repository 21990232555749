import Box from '@mui/system/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import {QuestProp} from 'modules/oldQuests2/types';
import {useQuestActions} from './hook';
import {useIsMobile} from 'utils';

import {ReactComponent as AcceptQuestIcon} from 'svg/quest/acceptQuest.svg';

export const QuestActions = ({quest}: QuestProp) => {
  const isMobile = useIsMobile();
  const {action, isAccepting, isRejecting} = useQuestActions(quest);
  if (!quest.timelines || !quest.timelines.length) return null;

  const Buttons = (
    <Stack direction={isMobile ? 'row' : 'column'} justifyContent="stretch" flexGrow={1}>
      <Button
        fullWidth
        variant="text"
        color="primary"
        disabled={isAccepting}
        onClick={() => action(true)}
        sx={{whiteSpace: 'nowrap'}}
      >
        {quest.design?.acceptTitle || 'Принять квест'}
      </Button>
      {quest.allowReject && (
        <Button
          fullWidth
          variant="text"
          color="error"
          disabled={isRejecting}
          onClick={() => action(false)}
          sx={{whiteSpace: 'nowrap'}}
        >
          {quest.design?.rejectTitle || 'Отклонить'}
        </Button>
      )}
    </Stack>
  );

  return (
    <Box px={2}>
      <Box borderRadius={2} boxShadow="0px 0px 2px rgba(16, 24, 40, 0.08), 0px 1px 2px rgba(16, 24, 40, 0.1)">
        <Stack direction="row" alignItems="center" spacing={1.5} p={2}>
          <Stack flexGrow={1} direction="row" alignItems="center" spacing={1.5}>
            <Box>
              <AcceptQuestIcon />
            </Box>
            <Box>
              <Typography variant="h3">{quest.design?.header || 'Вы можете начать выполнять этот квест'}</Typography>
              {quest.design?.hint && (
                <Typography variant="body2" color="grey.400">
                  {quest.design.hint}
                </Typography>
              )}
            </Box>
          </Stack>

          {!isMobile && Buttons}
        </Stack>
        {isMobile && Buttons}
      </Box>
    </Box>
  );
};
