import {alpha} from '@mui/material/styles';

// function createGradient(color1: string, color2: string) {
//   return `linear-gradient(to bottom, ${color1}, ${color2})`;
// }

// SETUP COLORS
// const PRIMARY = {
//   lighter: '#C8FACD',
//   light: '#BAA0F8',
//   main: '#3A0CA3',
//   dark: '#2F0A85',
//   darker: '#005249',
// };
const SECONDARY = {
  lighter: '#D6E4FF',
  light: '#84A9FF',
  main: '#F1ECFE',
  dark: '#1939B7',
  darker: '#091A7A',
};
const INFO = {
  lighter: '#D0F2FF',
  light: '#74CAFF',
  main: '#1890FF',
  dark: '#0C53B7',
  darker: '#04297A',
};
export const SUCCESS = {
  lighter: '#ECFDF3',
  light: '#D1FADF',
  main: '#12B76A',
  dark: '#027A48',
  darker: '#08660D',
};
const WARNING = {
  lighter: '#FFFCF5',
  light: '#FDB022',
  main: '#F79009',
  dark: '#DC6803',
  darker: '#7A2E0E',
};

export const ERROR = {
  lighter: '#FEE4E2',
  light: '#FDA29B',
  main: '#F04438',
  dark: '#D92D20',
  darker: '#7A271A',
};

export const PRIMARY = {
  10: '#F1ECFE',
  25: '#D6C6FB',
  50: '#BAA0F8',
  100: '#9F7AF5',
  200: '#8454F2',
  300: '#682EEF',
  400: '#5011E4',
  500: '#3A0CA3',
  600: '#2F0A85',
  700: '#21075F',
  800: '#1B064C',
  900: '#0D0326',
};

export const GREY = {
  0: '#FFFFFF',
  10: '#F2F4F7',
  25: '#D9DEE8',
  50: '#C0C9D8',
  100: '#A6B3C9',
  200: '#8D9DB9',
  300: '#7488AA',
  400: '#5D7398',
  500: '#4E5F7E',
  600: '#3E4C65',
  700: '#344054',
  800: '#1F2633',
  900: '#101319',
  500_8: alpha('#4E5F7E', 0.08),
  500_12: alpha('#4E5F7E', 0.12),
  500_16: alpha('#4E5F7E', 0.16),
  500_24: alpha('#4E5F7E', 0.24),
  500_32: alpha('#4E5F7E', 0.32),
  500_48: alpha('#4E5F7E', 0.48),
  500_56: alpha('#4E5F7E', 0.56),
  500_80: alpha('#4E5F7E', 0.8),
};

export const NEUTRAL = {
  0: '#333333',
  10: '#F5F4F6',
  20: '#caced8',
  30: '#878d9f',
  40: '#d2d4db',
  50: '#c7c9d6',
};

// const GRADIENTS = {
//   primary: createGradient(PRIMARY.light, PRIMARY.main),
//   info: createGradient(INFO.light, INFO.main),
//   success: createGradient(SUCCESS.light, SUCCESS.main),
//   warning: createGradient(WARNING.light, WARNING.main),
//   error: createGradient(ERROR.light, ERROR.main),
// };

const CHART_COLORS = {
  violet: ['#826AF9', '#9E86FF', '#D0AEFF', '#F7D2FF'],
  blue: ['#2D99FF', '#83CFFF', '#A5F3FF', '#CCFAFF'],
  green: ['#2CD9C5', '#60F1C8', '#A4F7CC', '#C0F2DC'],
  yellow: ['#FFE700', '#FFEF5A', '#FFF7AE', '#FFF3D6'],
  red: ['#FF6C40', '#FF8F6D', '#FFBD98', '#FFF2D4'],
};

const COMMON = {
  common: {black: '#000', white: '#fff'},
  primary: {...PRIMARY, contrastText: '#fff'},
  secondary: {...SECONDARY, contrastText: '#fff'},
  info: {...INFO, contrastText: '#fff'},
  success: {...SUCCESS, contrastText: GREY[800]},
  warning: {...WARNING, contrastText: GREY[800]},
  neutral: NEUTRAL,
  error: {...ERROR, contrastText: '#fff'},
  grey: GREY,
  // gradients: GRADIENTS,
  chart: CHART_COLORS,
  divider: GREY[10],
  appBg: '#f6f4f6',
  action: {
    hover: GREY[500_8],
    selected: GREY[500_16],
    disabled: GREY[500_80],
    disabledBackground: GREY[500_24],
    focus: GREY[500_24],
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
  },
  badge: {
    gold: {
      color: '#DC6803',
      bgcolor: '#FEDF89',
    },
    silver: {
      bgcolor: '#C9C9C9',
      linearGradient: 'linear-gradient(90deg, #C9C9C9 0%, #A8A8A8 100%)',
      color: '#fff',
    },
    bronze: {
      bgcolor: '#F1B796',
      linearGradient: 'linear-gradient(90deg, #FFCFB4 0%, #F1B796 100%)',
      color: '#CF6D36',
    },
  },
};

const palette = {
  light: {
    ...COMMON,
    text: {primary: GREY[800], secondary: GREY[600], disabled: GREY[500]},
    background: {paper: '#fff', default: '#fff', neutral: GREY[200]},
    action: {active: GREY[600], ...COMMON.action},
  },
  // dark: {
  //   ...COMMON,
  //   text: {primary: '#fff', secondary: GREY[500], disabled: GREY[600]},
  //   background: {paper: GREY[800], default: GREY[900], neutral: GREY[500_16]},
  //   action: {active: GREY[500], ...COMMON.action},
  // },
};

export default palette;
