import {api} from 'api';
import {useToggle} from 'react-use';
import {skipToken} from '@reduxjs/toolkit/dist/query';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import Typography from '@mui/material/Typography';

import {croppedImagePath} from 'utils';

import {useMyStreamId} from 'modules/auth';
import {getLockedReason} from 'modules/oldQuests2/utils';
import {StagePointWithChildsProps} from 'modules/oldQuests2/types';
import {QuestPointView} from 'modules/oldQuests2/components/QuestPoint';
import {ProfileImage} from 'modules/profile/components/ProfileImage';
import {SubQuestPointView} from 'modules/oldQuests2/components/QuestPoint/SubQuestPoint';

import {QuestPointContent} from './QuestPoint';

export const TimelineQuestPoint = ({
  points = [],
  children,
  stagePoint,
  lockedReason,
  questPoint: point,
}: StagePointWithChildsProps) => {
  const streamId = useMyStreamId();
  const [collapsed, setCollapsed] = useToggle(false);
  const [activate] = api.endpoints.stagePointActivateTimeline.useMutation();
  const {data, refetch, isLoading} = api.endpoints.stagesByTimeline.useQuery(
    stagePoint?.referenceId ? {timelineId: stagePoint?.referenceId} : skipToken
  );
  const {data: users} = api.endpoints.usersIndex.useQuery(
    point.questPoint?.options?.timelines
      ? {
          sort: 'rating',
          streamId,
          privacy: point.questPoint.options.timelines.privacy || undefined,
        }
      : skipToken
  );

  const progressPercent = data
    ? Math.round((data.length / 100) * data.filter(i => i.stage?.status === 'completed').length)
    : null;

  return (
    <>
      {children({collapsed, onClick: () => setCollapsed(!collapsed)})}
      {stagePoint && (
        <Collapse in={collapsed} timeout={150}>
          <Box sx={{p: 2}}>
            <Button
              color="primary"
              variant="contained"
              onClick={() => activate({pointId: `${stagePoint._id}`, activateTimelineDto: {}})}
            >
              Выслать приглашения
            </Button>

            <Box
              p={2}
              mt={3}
              borderRadius={2}
              boxShadow="0px 0px 2px rgba(16, 24, 40, 0.08), 0px 1px 2px rgba(16, 24, 40, 0.1)"
            >
              {progressPercent !== null && (
                <Stack direction="row" justifyContent="space-between" mb={3}>
                  <Stack spacing={1}>
                    <Typography variant="caption" color="grey.200" sx={{textTransform: 'uppercase'}}>
                      Общий процент завершения квеста
                    </Typography>
                    <Typography variant="h2" color="grey.700">
                      {progressPercent}%
                    </Typography>
                  </Stack>
                  {point?.fileId && (
                    <Box
                      component="img"
                      width={40}
                      height={40}
                      flexShrink={0}
                      borderRadius={2}
                      boxShadow={theme => theme.customShadows.inset}
                      src={croppedImagePath(point?.fileId, {maxWidth: 40, ratio: '1:1'})}
                    />
                  )}
                </Stack>
              )}
              <Stack spacing={1.5}>
                {(data || users?.data || []).map(item => {
                  const user = 'user' in item ? item.user : item;
                  const stage = 'stage' in item ? item.stage : null;
                  return (
                    <Stack key={user._id} alignItems="center" direction="row" spacing={0.5}>
                      <ProfileImage user={user} size="xsmall" noLink inline />
                      <Typography
                        fontWeight={500}
                        variant="body2"
                        component="span"
                        sx={{color: 'grey.700', ml: 0.5, width: '100%'}}
                      >
                        {user.userLogin?.value || user.name + ' ' + user.surname}
                      </Typography>
                      {stage && (
                        <Typography
                          variant="body2"
                          component="span"
                          sx={{whiteSpace: 'nowrap'}}
                          color={
                            stage.status === 'completed'
                              ? 'success.main'
                              : stage.status === 'failed' || stage.status === 'rejected' || stage.status === 'deadline'
                              ? 'error.main'
                              : 'grey.200'
                          }
                        >
                          {stage
                            ? stage.status === 'completed'
                              ? 'Выполнен'
                              : stage.status === 'failed' || stage.status === 'rejected' || stage.status === 'deadline'
                              ? 'Провален'
                              : 'В процессе'
                            : 'Не начат'}
                        </Typography>
                      )}
                    </Stack>
                  );
                })}
              </Stack>
              {data && (
                <Button
                  variant="contained"
                  color="secondary"
                  disabled={isLoading}
                  onClick={refetch}
                  sx={{p: '6px 8px', height: '32px', width: 'fit-content', mt: 3}}
                >
                  Обновить
                </Button>
              )}
            </Box>
          </Box>
        </Collapse>
      )}
      {(points.length > 0 || (point.points || [])?.length > 0) && (
        <>
          {!point.timelineCheck.available && (
            <Box sx={{typography: 'subtitle1', color: 'grey.200', mb: 1, px: 2}}>
              {getLockedReason(lockedReason) || 'Недостуно'}
            </Box>
          )}
          {(points || point.points).map(item => (
            <QuestPointContent
              {...('questPointData' in item ? item : {questPointData: item})}
              key={'questPointData' in item ? item.questPointData.questPoint._id : item.questPoint._id}
              lockedReason={point.timelineCheck.reasons}
              Component={item.points ? QuestPointView : SubQuestPointView}
            />
          ))}
        </>
      )}
    </>
  );
};
