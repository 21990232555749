import ButtonUnstyled from '@mui/base/BadgeUnstyled';
import styled from '@mui/system/styled';
import {PRIMARY} from 'components/theme/palette';

export const ActionsContainer = styled('div')`
  top: ${({theme}) => theme.spacing(2)};
  right: ${({theme}) => theme.spacing(2)};
  position: absolute;
`;

export const Action = styled(ButtonUnstyled)<{color?: 'black' | 'blue'}>`
  display: flex;
  align-items: center;
  justify-content: center;

  border: none;
  overflow: visible;
  -webkit-appearance: button;

  width: ${({theme}) => theme.spacing(5)};
  height: ${({theme}) => theme.spacing(5)};

  border-radius: 50%;
  background-color: ${({color}) => (color === 'black' ? 'rgba(0, 0, 0, 0.4)' : PRIMARY[500])};

  & + & {
    margin-top: ${({theme}) => theme.spacing(1)};
  }
`;

export const ImageContainer = styled('div')`
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
`;
