import {memo} from 'react';
import {ToggleButton, ToggleButtonGroup} from '@mui/material';
import {styled} from '@mui/material/styles';
import {GREY, PRIMARY} from 'components/theme/palette';
import {ReactComponent as Icon1to1} from 'svg/aspect-ratios/icon1to1.svg';
import {ReactComponent as Icon16to9} from 'svg/aspect-ratios/icon16to9.svg';
import {ReactComponent as Icon4to5} from 'svg/aspect-ratios/icon4to5.svg';
import {ReactComponent as Icon4to3} from 'svg/aspect-ratios/icon4to3.svg';
import {FileCropApiArg} from 'api/generated/files-api';

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({theme}) => ({
  zIndex: 10,
  position: 'absolute',
  left: '50%',
  bottom: '16px',
  transform: 'translateX(-50%)',
  '& .MuiToggleButtonGroup-grouped': {
    alignItems: 'center',
    padding: '6px 15px',
    border: '16px',
    color: GREY[200],
    backgroundColor: theme.palette.background.paper,
    '&.Mui-selected, &:hover, &.Mui-selected:hover': {
      color: '#fff',
      backgroundColor: PRIMARY[500],
      '& svg rect': {
        stroke: '#fff',
      },
    },
    '&.Mui-disabled': {
      border: '16px',
    },
    '&:not(:first-of-type)': {
      borderRadius: '16px',
    },
    '&:not(:last-of-type)': {
      marginRight: '4px',
    },
    '&:first-of-type': {
      borderRadius: '16px',
    },
  },
  '& svg': {
    marginRight: '8px',
  },
}));

export const ToggleButtonField = ({
  ratio,
  setRatio,
}: {
  ratio: FileCropApiArg['ratio'];
  setRatio: (size: FileCropApiArg['ratio']) => void;
}) => {
  const handleChange = (evt: any, newAspectRatio: typeof ratio) => {
    evt.stopPropagation();
    setRatio(newAspectRatio);
  };

  return (
    <StyledToggleButtonGroup exclusive value={ratio} onChange={handleChange}>
      <ToggleButton value={'1:1'}>
        <Icon1to1 />
        1:1
      </ToggleButton>
      <ToggleButton value={'16:9'}>
        <Icon16to9 />
        16:9
      </ToggleButton>
      <ToggleButton value={'4:5'}>
        <Icon4to5 />
        4:5
      </ToggleButton>
      <ToggleButton value={'4:3'}>
        <Icon4to3 />
        4:3
      </ToggleButton>
    </StyledToggleButtonGroup>
  );
};

export default memo(ToggleButtonField);
