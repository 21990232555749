import {createContext, useEffect} from 'react';
import {useNavigate, useParams} from 'react-router';

import {authApi} from 'api';
import {InviteDataResponse} from 'api/generated/auth-api';

export const useGetInvite = () => {
  const navigate = useNavigate();
  const {inviteHash} = useParams<'inviteHash'>();
  const result = authApi.endpoints.loginRouteInvite.useQuery({code: inviteHash as string});

  useEffect(() => {
    if (result.isSuccess && result.data?.isActiveUser) navigate('/enter');
  }, [result.data?.isActiveUser, result.isSuccess, navigate]);

  return result;
};

export const AuthInviteContext = createContext<InviteDataResponse | undefined>(undefined);
