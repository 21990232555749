import {memo} from 'react';
import {Box, ButtonBase, CircularProgress, Stack, Typography} from '@mui/material';

import {StagePointData, Stages} from 'api/generated/users-api';
import {ReactComponent as EditIcon} from 'svg/icons/editIcon.svg';
import {ReactComponent as LockIcon} from 'svg/icons/lockIcon.svg';
import {ReactComponent as CheckIcon} from 'svg/icons/checkIcon.svg';

import {SVGComponent} from 'utils';

import {StagePointComponent} from '../../types';
import {useGetStagePointByIdWithSelector} from '../../hooks';
import {isCompletedStatus, selectStagePointCustomOptions} from '../../utils';

const selectLessonStagePointData = (point: StagePointData) => ({
  stagePoint: {
    status: point.stagePoint?.status,
    isCompleted: isCompletedStatus(point.stagePoint?.status),
  },
  questPoint: {
    ...selectStagePointCustomOptions(point),
    name:
      point.questPointData.questPoint.options.design?.title ||
      point.questPointData.questPoint.name ||
      point.questPointData.name ||
      '',
  },
});

const statusIcons: {[K in Stages['status'] | 'locked']: SVGComponent | null} = {
  new: EditIcon,
  in_progress: EditIcon,
  checking: EditIcon,
  ready: CheckIcon,
  completed: CheckIcon,
  deadline: EditIcon,
  failed: null,
  rejected: null,
  locked: LockIcon,
};

const statusColors: {[K in Stages['status'] | 'locked']: string | undefined} = {
  new: 'grey.200',
  in_progress: 'grey.200',
  checking: 'grey.200',
  ready: 'rgba(11, 214, 102, 0.7)',
  completed: 'rgba(11, 214, 102, 0.7)',
  deadline: 'grey.200',
  failed: 'grey.200',
  rejected: 'grey.200',
  locked: 'grey.200',
};

export const LessonStagePoint: StagePointComponent = memo(({stagePointId, stageId, usePressOptions}) => {
  const {isLoading, canPress, onPress} = usePressOptions({stagePointId, stageId});
  const {
    questPoint: {name, hidePointOnDone},
    stagePoint: {isCompleted, status},
  } = useGetStagePointByIdWithSelector({stagePointId, stageId}, selectLessonStagePointData);

  if (isCompleted && hidePointOnDone) return null;
  const Icon = status ? statusIcons[!canPress ? 'locked' : status] : null;
  const color = status ? statusColors[!canPress ? 'locked' : status] : undefined;

  return (
    <ButtonBase onClick={onPress} disabled={!canPress || isLoading} sx={{width: '100%'}}>
      <Stack direction="row" py={3} px={2} spacing={2} alignItems="flex-start" width={1}>
        {isLoading && <CircularProgress size={18} />}
        {!isLoading && Icon && <Box component={Icon} width={18} height={18} color={color} />}

        <Typography variant="body1" color={isCompleted ? 'black' : 'grey.200'} width={1} textAlign="left">
          {name}
        </Typography>
      </Stack>
    </ButtonBase>
  );
});
