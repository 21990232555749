import {useMemo} from 'react';
import {skipToken} from '@reduxjs/toolkit/query';

import {api} from 'api';
import {QuestPointsProgressData, Users} from 'api/generated/users-api';
import {useSafeContext} from 'utils';

import {GroupContext} from 'modules/groups/context';

export const useProgress = (questPointsStats?: QuestPointsProgressData[]) => {
  const {users} = useSafeContext(GroupContext);
  return useMemo(() => {
    const progress = {
      requiredPointsTotal: 0,
      optionalPointsTotal: 0,
      requiredPointsCompleted: 0,
      optionalPointsCompleted: 0,
    };
    if (!questPointsStats) return progress;
    questPointsStats.forEach(pointData => {
      const isRequired = pointData.questPoint.options.isRequired;
      if (isRequired) ++progress.requiredPointsTotal;
      else ++progress.optionalPointsTotal;

      const completed = pointData.progress.find(pointProgress => pointProgress.status === 'completed');
      if (!completed || completed.users.length !== users.length) return;
      if (isRequired) ++progress.requiredPointsCompleted;
      else ++progress.optionalPointsCompleted;
    });
    return progress;
  }, [questPointsStats, users]);
};

export const useUsersProgress = (questPointsStats: QuestPointsProgressData[]) => {
  const {users} = useSafeContext(GroupContext);
  return useMemo(
    () =>
      (users || [])
        .map(i => i.user as Users)
        .map(user => {
          const userProgress = {
            user,
            requiredPointsCompleted: 0,
            optionalPointsCompleted: 0,
          };
          questPointsStats.forEach(pointData => {
            const completedUsersId = pointData.progress
              .find(progressData => progressData.status === 'completed')
              ?.users.map(i => i._id);
            if (!completedUsersId || !completedUsersId.includes(user._id as string)) return;
            if (pointData.questPoint.options.isRequired) ++userProgress.requiredPointsCompleted;
            else ++userProgress.optionalPointsCompleted;
          });
          return userProgress;
        })
        .sort((a, b) => (a.requiredPointsCompleted > b.requiredPointsCompleted ? -1 : 1)),
    [questPointsStats, users]
  );
};

export const useQuestPointStats = ({questId, isMainQuest}: {questId?: string; isMainQuest?: boolean}) => {
  const group = useSafeContext(GroupContext);
  const groupId = group.groupId;

  const {data: quest, isLoading: isQuestLoading} = api.endpoints.questIndex.useQuery(questId ? {questId} : skipToken);
  const {data: questPointsStats, isLoading: isQuestPointsLoading} = api.endpoints.questPointsStats.useQuery(
    questId ? {questId, groupId: [groupId]} : skipToken,
    {
      selectFromResult: data => ({
        ...data,
        data: isMainQuest
          ? (data?.data || []).filter(
              item =>
                !item.timelineCheck.skipped &&
                ['quest', 'shortcut'].includes(item.questPoint.type) &&
                item.questPoint.options.quests?.questId
            )
          : data?.data,
      }),
    }
  );
  const isLoading = isQuestLoading || isQuestPointsLoading;
  return {quest, questPointsStats, isLoading};
};
