import {memo} from 'react';
import TextField from '@mui/material/TextField';

import * as Styled from './styled';
import {AnswerProps} from '../types';
import {useSingleFieldProps} from './hooks';

export const TextInput = memo(
  ({type, ...params}: AnswerProps & {type: 'date' | 'string' | 'text' | 'number' | string}) => {
    const {option, field, ref, defaultValue, patch} = useSingleFieldProps(params, true);
    if (!option) return null;
    return (
      <Styled.TextField>
        <TextField
          {...field}
          inputRef={ref}
          type={type}
          multiline={type === 'text'}
          onBlur={patch ? () => patch({...params, value: field.value || defaultValue}) : undefined}
          minRows={type === 'text' ? 1 : type === 'string' ? 1 : undefined}
          label={option.name}
          value={field.value || defaultValue}
          placeholder={option.description}
          fullWidth
          variant="standard"
          size="small"
          InputLabelProps={type === 'date' ? {classes: {root: 'MuiFormLabel-filled MuiInputLabel-shrink'}} : undefined}
        />
      </Styled.TextField>
    );
  }
);
