import React, {ReactNode, useState} from 'react';
import {Box} from '@mui/system';
import {Button, ButtonProps, IconButton, ListItemText} from '@mui/material';
import {ReactComponent as MoreDotsIcon} from 'svg/moreDots.svg';
import * as Styled from './styled';
import {omit} from 'lodash';

export type ButtonMenuProps = {
  variant?: 'button' | 'iconButton' | 'dots';
  buttonProps: {
    icon?: ReactNode;
    text?: string;
  } & Pick<ButtonProps, 'variant' | 'size' | 'sx' | 'color'>;
  items: ({
    label: string;
    icon?: ReactNode;
    onClick?: () => void;
    selected?: boolean;
  } | null)[];
  Button?: (props: any) => JSX.Element;
};

export const ButtonMenu = ({items, Button: MainButton, buttonProps, variant = 'button'}: ButtonMenuProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setAnchorEl(null);
  };

  const btnProps = {
    'aria-haspopup': 'true',
    'aria-expanded': open ? 'true' : undefined,
    onClick: handleClick,
  };

  return (
    <>
      {!MainButton && variant === 'button' && (
        <Button {...omit(buttonProps, ['text', 'icon'])} {...(btnProps as any)}>
          {buttonProps.icon && <Box sx={{width: 24, height: 24}}>{buttonProps.icon}</Box>}
          {buttonProps.text && buttonProps.text}
        </Button>
      )}
      {!MainButton && variant === 'iconButton' && (
        <IconButton {...omit(buttonProps, ['text', 'icon', 'label'])} {...(btnProps as any)}>
          {buttonProps.icon}
        </IconButton>
      )}
      {!MainButton && variant === 'dots' && (
        <Button
          {...omit(buttonProps, ['text', 'icon', 'sx'])}
          {...(btnProps as any)}
          sx={[
            {
              borderRadius: '50%',
              border: '1px solid',
              borderColor: 'transparent',
              height: 32,
              width: 32,
              maxWidth: '32px',
              px: 0,
            },
            {'&:hover': {borderColor: 'grey.25', backgroundColor: 'transparent'}},
            {...(buttonProps.sx || {})},
          ]}
        >
          <Box sx={{width: 24, height: 24}}>
            <MoreDotsIcon />
          </Box>
        </Button>
      )}
      {MainButton && <MainButton {...btnProps} />}
      <Styled.Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
          sx: {padding: 0},
        }}
        PaperProps={{
          sx: {
            borderRadius: 2,
            boxShadow: `0px 0px 2px rgba(16, 24, 40, 0.06), 0px 24px 48px -12px rgba(16, 24, 40, 0.25)`,
          },
        }}
      >
        {items.map(item => {
          if (!item) return null;
          const clickHandler = item.onClick
            ? (e: React.MouseEvent<HTMLElement>) => {
                if (item.onClick) item.onClick();
                handleClose(e);
              }
            : undefined;

          return (
            <Styled.MenuItem key={item.label} onClick={clickHandler} selected={item.selected}>
              {item.icon && <Styled.ListItemIcon sx={{ml: 2}}>{item.icon}</Styled.ListItemIcon>}
              <ListItemText>{item.label}</ListItemText>
            </Styled.MenuItem>
          );
        })}
      </Styled.Menu>
    </>
  );
};
export default ButtonMenu;
