import {useQueryParam, StringParam} from 'use-query-params';
import {useCallback, useEffect} from 'react';

import {api} from 'api/generated/users-api';
import {useAtomValue} from 'jotai';
import {rootStageIdAtom} from 'modules/oldQuests2/containers/Quest/atom';

export const StagePointPaymentRefreshWatcher = () => {
  const parentStageId = useAtomValue(rootStageIdAtom, Symbol.for('parent-stage'));
  const [fetch] = api.endpoints.paymentRefresh.useLazyQuery();
  const [getStagePoints] = api.endpoints.stagePointsIndex.useLazyQuery();
  const [paymentId, setPaymentId] = useQueryParam('checkStagePointPayment', StringParam);

  const refresh = useCallback(async () => {
    if (paymentId) {
      await fetch({paymentId});
      if (parentStageId) await getStagePoints({stageId: parentStageId});
      setPaymentId(undefined);
    }
  }, [paymentId, setPaymentId, fetch, parentStageId, getStagePoints]);

  useEffect(() => {
    refresh();
  }, [refresh]);

  return null;
};
