import {GoalContext} from 'modules/profile/context';
import IsExistsContainer from 'components/IsExistsContainer';

import {GoalPage} from './GoalPage';
import {useGetGoalById} from './hook';

export const GoalDialog = ({entryId}: {entryId?: string}) => {
  return (
    <IsExistsContainer
      context={GoalContext}
      useFetch={useGetGoalById(entryId)}
      errorMessage={{403: 'Доступ к цели ограничен настройками приватности'}}
    >
      <GoalPage />
    </IsExistsContainer>
  );
};
