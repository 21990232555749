import {useIsTablet} from 'utils';
import {isUndefined} from 'lodash';
import {useToggle} from 'react-use';
import {forwardRef, PropsWithChildren, useCallback, ReactNode} from 'react';

import styled from '@mui/system/styled';
import Slide from '@mui/material/Slide';
import Dialog from '@mui/material/Dialog';
import Close from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import DialogContent from '@mui/material/DialogContent';
import {TransitionProps} from '@mui/material/transitions';

import Header from 'components/Layout/Header';
import {DialogActions} from '@mui/material';

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export type DialogProps = PropsWithChildren<{
  onClose?: () => void;
  title?: string;
  open?: boolean;
  action?: ReactNode;
}>;
export type UseDialogReturnType = [(props: DialogProps) => JSX.Element, (flag?: boolean) => void];

const StyledDialog = styled(Dialog)`
  & .MuiDialog-container {
    align-items: flex-start;
  }
`;

export const CommonDialog = ({
  children,
  onClose,
  title,
  open,
  action,
}: Required<Omit<DialogProps, 'title' | 'children' | 'action'>> &
  Pick<DialogProps, 'title' | 'children' | 'action'>) => {
  const isTablet = useIsTablet();

  return (
    <StyledDialog
      open={open}
      scroll="paper"
      closeAfterTransition
      onClose={onClose}
      fullScreen={isTablet}
      fullWidth={!isTablet}
      TransitionComponent={Transition}
      maxWidth={isTablet ? undefined : 'sm'}
      PaperProps={!isTablet ? {sx: {borderRadius: 2}} : undefined}
    >
      <Header
        title={title || ''}
        showNotifications={false}
        actions={
          <IconButton size="small" onClick={onClose}>
            <Close />
          </IconButton>
        }
      />
      <DialogContent sx={{backgroundColor: 'grey.10', padding: 0, width: '100%', height: '100%'}}>
        {children}
      </DialogContent>
      {action && <DialogActions>{action}</DialogActions>}
    </StyledDialog>
  );
};

export const useDialog = (): UseDialogReturnType => {
  const [open, toggleOpen] = useToggle(false);

  const Container = useCallback(
    ({open: forceOpen, onClose, ...props}: DialogProps) => {
      const isForceOpened = !isUndefined(forceOpen);
      const closeHandler = () => {
        if (!isForceOpened) toggleOpen(false);
        if (onClose) onClose();
      };
      return <CommonDialog {...props} onClose={closeHandler} open={isForceOpened ? forceOpen : open} />;
    },
    [open, toggleOpen]
  );

  return [Container, toggleOpen];
};
