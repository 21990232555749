import {Box, Card, Avatar, Typography, Button, IconButton, ButtonGroup} from '@mui/material';
import {styled} from '@mui/system';
import {ReactComponent as SelectArrow} from 'svg/icons/selectArrowRight.svg';
import {ReactComponent as GoalCompleteIcon} from 'svg/icons/goalCompleteIcon.svg';
import {ReactComponent as StarIcon} from 'svg/rating/star.svg';
import {ReactComponent as LikeIcon} from 'svg/icons/likeIcon.svg';
import {GREY} from 'components/theme/palette';

const StyledIcon = styled(LikeIcon)`
  & path {
    stroke: ${GREY[200]};
  }
`;

export const PublicationGoal = () => {
  return (
    <Card
      sx={{
        display: 'flex',
        alignItems: 'flex-start',
        px: 2,
        py: 1,
        border: 1,
        borderColor: '#12B76A',
        borderRadius: 2,
        marginBottom: 2,
      }}
    >
      <Box mr={1}>
        <Avatar>O</Avatar>
      </Box>
      <Box mr={1} display="flex" flexDirection="column" alignItems="flex-start">
        <Box display="flex" mb={0.5} sx={{borderRadius: 5, backgroundColor: '#D1FADF', py: 0.5, px: 0.75}}>
          <GoalCompleteIcon />
          <Typography variant="subtitle1" sx={{color: '#039855', marginLeft: 0.25}}>
            Цель выполнена
          </Typography>
        </Box>
        <Typography variant="body2" mb={1} sx={{fontWeight: 500}}>
          Погладить жирафа на завтраке в Кении
        </Typography>

        <Box display="flex" mb={1}>
          <Box mr={2}>
            <Typography variant="subtitle2" color={GREY[200]} mb={0.5}>
              Завершена за
            </Typography>
            <Typography variant="subtitle1">6 месяцев</Typography>
          </Box>
          <Box mr={2}>
            <Typography variant="subtitle2" color={GREY[200]} mb={0.5}>
              Цель
            </Typography>
            <Typography variant="subtitle1">3 года</Typography>
          </Box>
          <Box>
            <Typography variant="subtitle2" color={GREY[200]} mb={0.5}>
              Денежный эквив.
            </Typography>
            <Typography variant="subtitle1">200 000₽</Typography>
          </Box>
        </Box>

        <ButtonGroup size="small" sx={{color: GREY[25], borderRadius: 4}}>
          <Button variant="outlined" color="secondary" sx={{height: 16, px: 0.5, py: 0.25}}>
            <StyledIcon width="12" height="12" />
            254
          </Button>
          <Button variant="outlined" color="secondary" sx={{height: 16, px: 0.5, py: 0.25}}>
            <StarIcon width="12" height="12" />
            8.9
          </Button>
        </ButtonGroup>
      </Box>
      <IconButton sx={{marginLeft: 'auto'}}>
        <SelectArrow />
      </IconButton>
    </Card>
  );
};

export default PublicationGoal;
