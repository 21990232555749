import {memo} from 'react';
import {TextField} from '@mui/material';
import {Autocomplete, AutocompleteRenderInputParams} from 'formik-mui';
import {isFunction} from 'lodash';

import {StyledFastField} from 'components/FormFactory';
import {ReactComponent as DropdownIcon} from 'svg/icons/dropdownIcon.svg';

import {AutocompleteFieldProps, SelectOption} from '../types';

type FieldProps = {name: string; type: 'select' | 'multi_select'} & AutocompleteFieldProps['inputProps'];

const CommonField = ({options, label, name}: FieldProps & {options: SelectOption[]}) => (
  <StyledFastField
    component={Autocomplete}
    name={name}
    label={label}
    options={options}
    multiple
    fullWidth
    ChipProps={{
      size: 'small',
    }}
    filterSelectedOptions
    sx={{
      '& .MuiInputBase-root': {
        height: 'auto',
        pt: 2,
        pb: 1,
        minHeight: '54px',
      },
      '& .MuiAutocomplete-endAdornment': {
        right: 8,
      },
    }}
    noOptionsText="Нет подходящих вариантов"
    popupIcon={<DropdownIcon />}
    isOptionEqualToValue={(option: SelectOption, value: SelectOption) => option.value === value.value}
    getOptionLabel={(option: SelectOption) => option.name}
    renderInput={(params: AutocompleteRenderInputParams) => (
      <TextField {...params} name={name} label={label} variant="standard" size="small" />
    )}
  />
);

const HookField = ({options: useOptions, ...props}: FieldProps & {options: () => SelectOption[]}) => {
  const options = useOptions();
  if (!options) return null;
  return <CommonField {...props} options={options} />;
};

export const AutocompleteField = memo((props: FieldProps) => {
  if (isFunction(props.options)) return <HookField {...(props as any)} />;
  return <CommonField {...(props as any)} />;
});

export default AutocompleteField;
