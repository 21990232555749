import {ru} from 'date-fns/locale';
import {
  format,
  isDate,
  formatDistanceStrict,
  formatDistanceToNowStrict,
  isYesterday,
  parseISO,
  differenceInHours,
  differenceInYears,
  differenceInMinutes,
} from 'date-fns';

export const timeAgo = (date?: string, options?: {short?: boolean}) => {
  if (!date) return '';
  const {short} = options || {};

  const parsed = parseISO(date);
  const now = new Date();
  if (!isDate(parsed)) return '';

  if (differenceInMinutes(now, parsed) < 2) return 'Только что';

  const diffInHours = differenceInHours(now, parsed);
  if (diffInHours < 12) {
    const result = formatDistanceToNowStrict(parsed, {locale: ru});
    if (short) {
      const suffix = diffInHours < 1 ? ' мин.' : ' ч.';
      return result.split(' ')[0] + suffix;
    }
    return result + ' назад';
  }

  if (isYesterday(parsed)) return short ? 'Вчера' : `Вчера в ${format(parsed, 'HH:mm')}`;

  if (diffInHours > 12 && diffInHours < 24) return 'День назад';

  const diffInYears = differenceInYears(now, parsed);
  return format(parsed, `d MMM ${diffInYears ? 'yyyy ' : ''}${short ? '' : 'в HH:mm'}`, {locale: ru});
};

export const formatDistance = (date?: string | null, dateFrom?: string | null) => {
  if (!date) return '';
  const parsed = parseISO(date);
  if (!isDate(parsed)) return '';
  if (!dateFrom) return formatDistanceToNowStrict(parsed, {locale: ru});
  const parsedFrom = parseISO(dateFrom);
  if (!isDate(parsedFrom)) return '';
  return formatDistanceStrict(parsed, parsedFrom, {locale: ru});
};

export const formatDate = (date: string | Date, formatString = 'PP') => {
  const parsedDate = typeof date === 'string' ? parseISO(date) : date;
  return format(parsedDate, formatString, {locale: ru});
};

export const formatDeadline = (date: string | Date) => {
  const parsed = typeof date === 'string' ? parseISO(date) : date;
  if (!isDate(parsed)) return '';
  return format(parsed, 'd MMMM yyyy HH:mm', {locale: ru});
};
