import {memo} from 'react';
import {FastField} from 'formik';
import {Select} from 'formik-mui';
import {isFunction} from 'lodash';
import {styled} from '@mui/system';
import {Box, MenuItem} from '@mui/material';

import {GREY, PRIMARY} from 'components/theme/palette';
import {ReactComponent as DropdownIcon} from 'svg/icons/dropdownIcon.svg';

import {SelectFieldProps, SelectOption} from '../types';

export const StyledBox = styled(Box)(() => ({
  '& .MuiInputLabel-root': {
    color: GREY[200],
    transform: 'translate(0, 13px)',
  },
  '& .MuiFormLabel-filled': {
    fontSize: '12px',
    lineHeight: '12px',
    color: GREY[200],
    transform: 'translate(0, -1px)',
  },
  '& .MuiFormHelperText-root': {
    marginLeft: 0,
    marginRight: 0,
  },
}));

export const StyledFastField = styled(FastField)(() => ({
  '&.MuiInputBase-root': {
    height: '50px',
    padding: '16px 0',
    marginTop: '0',
    fontSize: '16px',
    lineHeight: '20px',
    color: GREY[700],
    boxShadow: '0px 1px 0px #F2F4F7',
    '& .MuiInput-input': {
      padding: 0,
      '&:focus': {
        backgroundColor: '#fff',
      },
    },
    '&:hover:not(.Mui-disabled)::before': {
      borderBottom: 'none',
    },
    '&::before, &::after': {
      borderBottom: 'none',
    },
  },
  '& .MuiInput-underline:after': {
    borderBottom: '2px solid',
    borderBottomColor: PRIMARY[50],
  },
  '& .MuiSelect-icon': {
    top: '50%',
    right: '8px',
    transform: 'translateY(-50%)',
  },
}));

type FieldProps = {name: string; type: 'select' | 'multi_select'} & SelectFieldProps['inputProps'];

const CommonField = ({options, label, name, type, dense = false}: FieldProps & {options: SelectOption[]}) => (
  <StyledBox display="flex" flexDirection="column" position="relative">
    <StyledFastField
      component={Select}
      variant="standard"
      size="medium"
      multiple={type === 'multi_select'}
      name={name}
      label={label}
      formControl={{fullWidth: true}}
      IconComponent={DropdownIcon}
    >
      {options.map(item => (
        <MenuItem key={item.name} value={item.value} dense={dense}>
          {item.name}
        </MenuItem>
      ))}
    </StyledFastField>
  </StyledBox>
);

const HookField = ({options: useOptions, ...props}: FieldProps & {options: () => SelectOption[]}) => {
  const options = useOptions();
  if (!options) return null;
  return <CommonField {...props} options={options} />;
};

export const SelectField = (props: FieldProps) => {
  if (isFunction(props.options)) return <HookField {...(props as any)} />;
  return <CommonField {...(props as any)} />;
};

export default memo(SelectField);
