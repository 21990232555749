import {LoadingButton} from '@mui/lab';
import {Box, Button, Tooltip, Typography} from '@mui/material';
import {api} from 'api';
import {useSnackbar} from 'notistack';
import {useSafeContext} from 'utils';
import {GroupContext} from 'modules/groups/context';
import {getGroupPath} from 'modules/groups/utils';
import {Link} from 'react-router-dom';
import {Groups} from 'api/generated/users-api';

const InviteExistTitle = ({title, group}: {title: string; group?: Groups}) => (
  <Typography color="white" variant="subtitle2" sx={{fontSize: 11, fontWeight: 500, lineHeight: '20px'}}>
    {title} <br />«
    <Link to={getGroupPath(`${group?._id}`)} style={{color: 'white', textDecoration: 'underline'}}>
      {group?.name}
    </Link>
    »
  </Typography>
);

export const GroupInviteButtons = ({groupId}: {groupId: string}) => {
  const {enqueueSnackbar} = useSnackbar();
  const {
    info: {requestsIsActive},
  } = useSafeContext(GroupContext);
  const {invite, isInvitedToGroup, isInvitedToOtherGroup} = api.endpoints.groupInvitesIndex.useQuery(
    {},
    {
      selectFromResult: ({data}) => ({
        isInvitedToGroup: Boolean(data?.data?.length && data?.data?.find(item => item.groupId === groupId)),
        isInvitedToOtherGroup: Boolean(data?.data?.length && !data?.data?.find(item => item.groupId === groupId)),
        invite: data?.data?.[0],
      }),
    }
  );
  const {isRequestSent, isRequestToOtherGroup, request} = api.endpoints.groupRequestsIndex.useQuery(
    {},
    {
      selectFromResult: ({data}) => ({
        isRequestSent: Boolean(data?.data?.find(item => item.groupId === groupId)),
        isRequestToOtherGroup: Boolean(data?.data?.length && !data?.data?.find(item => item.groupId === groupId)),
        request: data?.data?.[0],
      }),
    }
  );
  const [requestJoin, {isLoading: requestLoading}] = api.endpoints.groupRequestsSend.useMutation();
  const [acceptInvite, {isLoading: inviteAccepting}] = api.endpoints.groupInviteAccept.useMutation();

  const onRequestJoinClick = async () => {
    const result = await requestJoin({groupRequestDto: {groupId, message: 'Хочу к вам в команду!'}});
    if ('data' in result) {
      enqueueSnackbar('Заяка отправлена', {
        variant: 'success',
        autoHideDuration: 3000,
      });
    } else if ('error' in result) {
      enqueueSnackbar((result.error as any)?.data?.message || 'Ошибка отправления заявки', {
        variant: 'error',
        autoHideDuration: 3000,
      });
    }
  };
  const onInviteAcceptClick = () => {
    if (invite) acceptInvite({inviteId: invite._id as string});
  };

  const RequestButton = (
    <LoadingButton
      size="small"
      color="primary"
      variant="contained"
      loading={requestLoading}
      disabled={!requestsIsActive || isRequestToOtherGroup || isInvitedToOtherGroup}
      onClick={onRequestJoinClick}
      fullWidth
    >
      Отправить запрос на вступление
    </LoadingButton>
  );

  const tooltipTitle = !requestsIsActive ? (
    'Приём заявок в группу закрыт'
  ) : isRequestToOtherGroup ? (
    <InviteExistTitle title="Вы уже отправили заявку в группу" group={request?.group} />
  ) : isInvitedToOtherGroup ? (
    <InviteExistTitle title="Вас уже пригласили в группу" group={invite?.group} />
  ) : null;

  const SendRequestButton = tooltipTitle ? (
    <Tooltip title={tooltipTitle} arrow enterTouchDelay={10} leaveTouchDelay={500} placement="bottom">
      <span>{RequestButton}</span>
    </Tooltip>
  ) : !isRequestSent && !isInvitedToGroup ? (
    RequestButton
  ) : null;

  return (
    <Box>
      {SendRequestButton}
      {isRequestSent && (
        <Button variant="contained" size="small" color="secondary" fullWidth disabled>
          Запрос на вступление отправлен
        </Button>
      )}
      {isInvitedToGroup && (
        <LoadingButton
          size="small"
          color="primary"
          variant="contained"
          fullWidth
          loading={inviteAccepting}
          onClick={onInviteAcceptClick}
        >
          Принять приглашение
        </LoadingButton>
      )}
    </Box>
  );
};
