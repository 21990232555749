import {api} from 'api';
import {useDispatch} from 'react-redux';
import {useCallback, useMemo} from 'react';
import {ViewsSetViewedApiArg} from 'api/generated/users-api';

import isUndefined from 'lodash/isUndefined';
import useEffectOnce from 'react-use/lib/useEffectOnce';

import {useAtom} from 'jotai';
import {focusAtom} from 'jotai/optics';

import {viewedAtom} from './atoms';

export const useIsViewed = (params: ViewsSetViewedApiArg, initialViewed?: boolean): [boolean, () => void] => {
  const dispatch = useDispatch();
  const entryStateAtom = useMemo(
    () => focusAtom(viewedAtom, o => o.prop(`${params.referenceName}.${params.referenceId}`)),
    [params.referenceId, params.referenceName]
  );
  const [isViewed, setIsViewed] = useAtom(entryStateAtom);

  const setViewed = useCallback(async () => {
    if (isViewed) return;
    await dispatch(api.endpoints.viewsSetViewed.initiate(params));
    setIsViewed(true);
  }, [params, dispatch, setIsViewed, isViewed]);

  useEffectOnce(() => {
    if (!isUndefined(initialViewed) && isUndefined(isViewed)) setIsViewed(initialViewed);
  });

  return [isViewed, setViewed];
};
