import {Theme} from '@mui/material/styles';

// ----------------------------------------------------------------------

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default function Chip(theme: Theme) {
  return {
    MuiChip: {
      defaultProps: {
        elevation: 0,
      },
      styleOverrides: {
        root: {},
        sizeSmall: {
          height: 24,
        },
      },
    },
  };
}
