import {Typography, TypographyProps} from '@mui/material';
import NumberFormat from 'react-number-format';
import {formatDate} from 'utils/dates';
import {VisualComponent} from '../../types';

export type TypographyComponentProps = Partial<Pick<TypographyProps, 'variant' | 'color' | 'fontWeight'>>;

export const TypographyComponent: VisualComponent<TypographyComponentProps> = ({value, type, options = {}}) => {
  if (type && !['number', 'string', 'text', 'date'].includes(type)) return null;

  let formattedValue: string | JSX.Element = value || '';
  if (value) {
    switch (type) {
      case 'text':
      case 'string':
        formattedValue = value || '';
        break;
      case 'number':
        formattedValue = <NumberFormat displayType="text" value={value} thousandSeparator={' '} />;
        break;
      case 'date':
        formattedValue = formatDate(value, 'PPP');
        break;
      default:
        break;
    }
  }
  return (
    <Typography variant="body1" {...options}>
      {formattedValue}
    </Typography>
  );
};
