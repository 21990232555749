import {Divider, Skeleton, Stack} from '@mui/material';

export const GroupHeaderSkeleton = () => (
  <Stack px={2} py={1} spacing={1} bgcolor="white">
    <Stack direction="row" alignItems="center" justifyContent="space-between" gap={1}>
      <Skeleton variant="circular" animation="wave" width={64} height={64} />
      <Stack
        direction="row"
        justifyContent="space-between"
        flexGrow={1}
        spacing={0.5}
        divider={
          <Divider orientation="vertical" flexItem sx={{borderColor: 'grey.25', height: 16, alignSelf: 'center'}} />
        }
      >
        <Stack width="100%" alignItems="center">
          <Skeleton variant="rectangular" animation="wave" width={85} height={35} sx={{borderRadius: 1}} />
        </Stack>
        <Stack width="100%" alignItems="center">
          <Skeleton variant="rectangular" animation="wave" width={85} height={35} sx={{borderRadius: 1}} />
        </Stack>
      </Stack>
    </Stack>
    <Stack spacing={1.25}>
      <Stack direction="row" alignItems="center" spacing={0.5}>
        <Skeleton variant="text" animation="wave" width="35%" height={18} sx={{transform: 'none'}} />
        <Skeleton variant="rectangular" animation="wave" width={40} height={20} sx={{borderRadius: 6}} />
      </Stack>

      <Skeleton variant="text" animation="wave" width="100%" height={14} sx={{transform: 'none'}} />
      <Skeleton variant="text" animation="wave" width="100%" height={14} sx={{transform: 'none'}} />
      <Skeleton variant="text" animation="wave" width="50%" height={14} sx={{transform: 'none'}} />
      <Skeleton variant="text" animation="wave" width="100%" height={32} sx={{transform: 'none'}} />
    </Stack>
  </Stack>
);
