import {api} from 'api';
import {useCallback} from 'react';

import Box from '@mui/system/Box';
import Stack from '@mui/material/Stack';

import {Groups, GroupsIndexApiArg} from 'api/generated/users-api';

import {GroupProfileImage} from 'modules/groups';
import {TeamItem} from 'modules/search/components';
import {InfiniteList} from 'components/InfiniteLoader';
import {HideScrollbar} from 'components/HideScrollbar';
import {RatingPosition} from 'components/RatingPosition';
import {RoundListContentSkeleton, GroupListSkeleton} from 'components/Skeleton';

export type GroupsListProps = {
  spacing?: number;
  actions?: (user: Groups) => JSX.Element;
  params: Omit<GroupsIndexApiArg, 'offset'>;
  showPosition?: boolean;
};

export const GroupsList = ({params, spacing, actions, showPosition}: GroupsListProps) => {
  const [getGroups, result] = api.endpoints.groupsIndex.useLazyQuery();
  const loadUsers = useCallback(
    ({limit, offset}: {limit: number; offset: number}) => getGroups({...params, limit, offset}),
    [params, getGroups]
  );

  return (
    <InfiniteList
      spacing={spacing}
      LoadingComponent={fetchItemsCount => <GroupListSkeleton limit={fetchItemsCount} />}
      fetchFn={loadUsers}
      result={result}
      limit={30}
    >
      {(group: Groups) => <TeamItem group={group} action={actions && actions(group)} showPosition={showPosition} />}
    </InfiniteList>
  );
};

export const GroupsRoundList = ({params}: Omit<GroupsListProps, 'withSearch'>) => {
  const {data, isLoading} = api.endpoints.groupsIndex.useQuery(params);
  return (
    <HideScrollbar sx={{p: 1, mt: 0, ml: -1}}>
      <Stack spacing={1} direction="row" flexWrap="nowrap" width="fit-content" sx={{pl: 2}}>
        {isLoading && <RoundListContentSkeleton count={9} />}
        {(data?.data || []).map(item => (
          <Box key={item._id} sx={{position: 'relative'}}>
            <GroupProfileImage group={item} size="large" key={item._id} />
            <Box sx={{position: 'absolute', top: 0, right: 0}}>
              <RatingPosition position={item.ratingPosition?.byNPS} size="large" />
            </Box>
          </Box>
        ))}
      </Stack>
    </HideScrollbar>
  );
};
