import {Stack, Typography} from '@mui/material';
import {CSSProperties} from 'react';

import {Users} from 'api/generated/users-api';
import {ProfileImage} from 'modules/profile/components/ProfileImage';
import {UserName} from 'modules/profile/components/UserName';

type UserSmallItemProps = {
  user: Users;
} & CSSProperties;

export const UserSmallItem = ({user, ...props}: UserSmallItemProps) => (
  <Stack direction="row" alignItems="center" width="fit-content" sx={{'&:hover .MuiTypography-root': {opacity: 0.8}}}>
    <ProfileImage user={user} size="xsmall" />
    <Typography
      component="span"
      ml={0.5}
      sx={{'& .MuiTypography-root': {color: 'grey.700', transition: 'opacity .25s', ...props}}}
    >
      <UserName user={user} showPreferName={true} noScore={true} noLeaderBadge={true} noRatingPosition={true} />
    </Typography>
  </Stack>
);
