import {Box, Typography} from '@mui/material';
import {GREY} from 'components/theme/palette';
import {styled} from '@mui/system';

const Wrap = styled(Box)(() => ({
  width: '100%',
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: '8px',
  marginBottom: '8px',
  '&:before': {
    zIndex: 1,
    bottom: 'calc(50%)',
    position: 'absolute',

    height: '1px',
    width: '100%',
    background: GREY[25],
    content: '""',
    display: 'block',
  },
  '&:first-of-type': {
    marginTop: 0,
  },
  '&:empty': {
    height: '1px',
  },
}));

const Container = styled(Box)(() => ({
  zIndex: 2,
  position: 'relative',

  marginTop: '1px',
  marginBottom: '1px',
  padding: '3px 5px',
  border: `1px solid ${GREY[25]}`,
  boxSizing: 'border-box',
  borderRadius: '10px',
  background: 'white',
}));

export const Divider = ({title}: {title?: string}) => {
  return (
    <Wrap>
      {title && (
        <Container>
          <Typography variant="subtitle2" color={GREY[200]}>
            {title}
          </Typography>
        </Container>
      )}
    </Wrap>
  );
};

export default Divider;
