import {ReactNode} from 'react';
import {useToggle} from 'react-use';

import {Stack} from '@mui/material';

import {api} from 'api';
import {QuestProp} from 'modules/oldQuests2/types';
import {QuestPointView} from 'modules/oldQuests2/components';

import {CollapseAllButton} from 'components/CollapseButton';

import {QuestActions} from '../QuestActions';
import {QuestPointContent} from '../QuestPoint';
import {selectQuestPoints} from './selectors';

export const QuestContents = ({quest, description}: QuestProp & {description?: ReactNode}) => {
  const [expandAll, toggleExpandAll] = useToggle(false);
  const {points} = api.endpoints.questPointsIndex.useQuery(
    {questId: `${quest._id}`},
    {
      refetchOnFocus: true,
      refetchOnMountOrArgChange: true,
      selectFromResult: ({data = []}) => ({
        points: selectQuestPoints(data),
      }),
    }
  );

  return (
    <>
      {description}
      <QuestActions quest={quest} />
      <Stack width="100%" alignItems="end" px={2}>
        <CollapseAllButton onClick={toggleExpandAll} />
      </Stack>
      {points.map((point, i) => (
        <QuestPointContent
          key={i}
          questPointData={point}
          Component={QuestPointView}
          index={i}
          collapsedAll={!expandAll}
        />
      ))}
    </>
  );
};
