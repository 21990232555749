import {Waypoint} from 'react-waypoint';
import {Fragment, useCallback} from 'react';
import {isFunction, min} from 'lodash';

import Box from '@mui/system/Box';
import Stack from '@mui/material/Stack';
import LoadingButton from '@mui/lab/LoadingButton';
import CircularProgress from '@mui/material/CircularProgress';
import {EmptyList} from 'components/EmptyList';

import {useInfinite} from './hook';
import {InfiniteLoaderProps} from './types';
import {DEFAULT_LIMIT, DEFAULT_SPACING} from './utils';

export function InfiniteList<T extends {_id?: string}>({
  fetchFn,
  children,
  result,
  limit = DEFAULT_LIMIT,
  spacing = DEFAULT_SPACING,
  emptyListProps,
  fetchOnMount,
  LoadingComponent,
  containerComponent,
  disableWaypoint = false,
  disableEmptyList = false,
  Header,
  Footer,
  mx,
}: InfiniteLoaderProps<T>) {
  const {loadMore, items, hasNoMoreItems, noItems, total} = useInfinite(fetchFn, result, limit, fetchOnMount);
  const Content = items.map((item: T) => <Fragment key={item._id}>{children(item)}</Fragment>);
  const onInitialLoad = useCallback(() => loadMore({startIndex: 0, stopIndex: limit}), [loadMore, limit]);
  const onLoadMore = useCallback(() => {
    if (!result.isFetching) {
      loadMore({startIndex: items.length, stopIndex: items.length + limit});
    }
  }, [loadMore, result.isFetching, items.length, limit]);

  const Loader = isFunction(LoadingComponent)
    ? LoadingComponent(result.isLoading ? limit : min([total - items.length, limit]) || 0)
    : LoadingComponent;
  return (
    <>
      {!disableEmptyList && noItems && (
        <Box my={spacing}>
          <EmptyList
            text="Записей не найдено"
            action={
              <LoadingButton
                size="small"
                variant="contained"
                disabled={result.isFetching}
                loading={result.isFetching}
                onClick={onInitialLoad}
              >
                Обновить
              </LoadingButton>
            }
            {...emptyListProps}
          />
        </Box>
      )}
      {Header && (
        <Box my={spacing}>
          <Header
            total={total}
            limit={limit}
            loadMore={onLoadMore}
            currentCount={items.length}
            isFetching={result.isFetching}
            itemsLeft={total - items.length > 0 ? total - items.length : 0}
          />
        </Box>
      )}
      {!noItems && (
        <Stack mx={mx} spacing={spacing}>
          {containerComponent ? containerComponent(Content) : Content}
        </Stack>
      )}
      {result.isFetching && !hasNoMoreItems && Loader}
      {Footer && (
        <Box my={spacing}>
          <Footer
            total={total}
            limit={limit}
            loadMore={onLoadMore}
            currentCount={items.length}
            isFetching={result.isFetching}
            itemsLeft={total - items.length > 0 ? total - items.length : 0}
          />
        </Box>
      )}
      {!disableWaypoint && (
        <Waypoint onEnter={onLoadMore}>
          <Box display="flex" justifyContent="center" alignItems="center" py={2}>
            {hasNoMoreItems && result.isFetching && <CircularProgress size={24} />}
          </Box>
        </Waypoint>
      )}
    </>
  );
}
