import {Button} from '@mui/material';
import {usePublicationCreate} from 'modules/feed/containers';

export const PublicationCreateButton = () => {
  const openCreatePublication = usePublicationCreate();
  if (!openCreatePublication) return null;
  return (
    <Button variant="contained" size="small" sx={{px: 3}} onClick={openCreatePublication}>
      Написать пост
    </Button>
  );
};
