import React, {PropsWithChildren} from 'react';
import {IconButton, Stack, Typography} from '@mui/material';

import {ReactComponent as LikeIcon} from 'svg/social/likeIcon.svg';
import {ReactComponent as RatingIcon} from 'svg/social/ratingIcon.svg';
import {ReactComponent as CommentIcon} from 'svg/social/commentIcon.svg';

const Button = ({children}: PropsWithChildren<{}>) => (
  <IconButton
    sx={{
      borderRadius: '56px',
      backgroundColor: 'grey.10',
      padding: '2px 8px 2px 2px',
      height: 'fit-content',
      // '&:hover': {
      //   backgroundColor: 'grey.25',
      // },
    }}
  >
    {children}
  </IconButton>
);

const Count = ({count}: {count: number | string}) => (
  <Typography component="span" color="grey.400" sx={{fontSize: '12px', lineHeight: '12px', ml: 1}}>
    {count}
  </Typography>
);

export const SocialButtonsGroup = ({
  likesCount = 0,
  commentsCount = 0,
  rating = 0,
}: {
  likesCount?: number;
  commentsCount?: number;
  rating?: number;
}) => {
  return (
    <Stack direction="row" spacing={0.5}>
      <Button>
        <LikeIcon />
        <Count count={likesCount} />
      </Button>

      <Button>
        <CommentIcon />
        <Count count={commentsCount} />
      </Button>

      <Button>
        <RatingIcon />
        <Count count={rating?.toFixed(1)} />
      </Button>
    </Stack>
  );
};

export default SocialButtonsGroup;
