import {Link} from 'react-router-dom';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import {chipClasses} from '@mui/material';

import plural from 'plural-ru';
import {Users} from 'api/generated/users-api';

import {getUserLink} from 'modules/profile/utils';
import {ProfileHeader} from 'components/ProfileHeader';
import {QuestProgressBadge} from 'modules/oldQuests2/components';

import {UserName} from '../UserName';
import {ProfilePageImage} from '../ProfileImage';

export type ProfileHeaderProps = {
  user: Users;
};

export const UserProfileHeader = ({user}: ProfileHeaderProps) => {
  if (!user.stats) return null;
  const {subscribesCount, subscribersCount, groups = []} = user.stats;
  const QuestProgress = <QuestProgressBadge userId={`${user._id}`} />;
  return (
    <Stack spacing={1}>
      <ProfileHeader
        image={<ProfilePageImage size="large" user={user} />}
        name={<UserName user={user} noLink />}
        counters={[
          {
            count: subscribersCount || 0,
            label: plural(subscribersCount, 'подписчик', 'подписчика', 'подписчиков'),
            link: `${getUserLink(user)}/subscribers`,
          },
          {
            count: subscribesCount || 0,
            label: plural(subscribesCount, 'подписка', 'подписки', 'подписок'),
            link: `${getUserLink(user)}/subscriptions`,
          },
          {Content: QuestProgress, label: undefined, count: undefined},
        ]}
      />
      {groups.length > 0 && (
        <Stack sx={{display: 'block'}} spacing={0.5} direction="row">
          {groups.map(item => (
            <Chip
              key={item._id}
              label={item.name}
              size="small"
              component={Link}
              to={`/teams/${item._id}`}
              sx={{
                cursor: 'pointer',
                backgroundColor: 'primary.10',
                alignItems: 'center',
                p: 0.25,
                borderRadius: 3,
                transition: 'background-color 0.3s',
                '&:hover, &:active': {
                  color: 'primary.500',
                  backgroundColor: 'primary.25',
                },
                [`&.${chipClasses.sizeSmall}`]: {height: 20},
                [`& .${chipClasses.label}`]: {
                  color: 'primary.main',
                  fontSize: 12,
                  lineHeight: '12px',
                  paddingLeft: '4px',
                  paddingRight: '6px',
                },
              }}
            />
          ))}
        </Stack>
      )}
    </Stack>
  );
};

export default UserProfileHeader;
