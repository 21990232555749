import {SxProps} from '@mui/system';
import Box from '@mui/system/Box';

export const WidgetHoverBox: React.FC<{
  showShadow?: boolean;
  px?: number;
  mx?: number;
  py?: number;
  sx?: SxProps;
  onClick?: () => void;
}> = ({children, showShadow, onClick, sx = {}, py = 1.5, mx = 0, px = 1}) => (
  <Box
    px={px}
    mx={mx}
    py={py}
    flexGrow={1}
    position="relative"
    onClick={onClick}
    sx={{
      ...sx,
      '&:hover:before': {opacity: 1},
      '&:before': {
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        zIndex: 1,
        width: 1 / 1,
        height: 1 / 1,
        borderRadius: 2,
        opacity: showShadow ? 1 : 0,
        content: '""',
        display: 'block',
        position: 'absolute',
        transition: 'opacity .3s',
        boxShadow: '0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)',
      },
    }}
  >
    <Box position="relative" zIndex={3}>
      {children}
    </Box>
  </Box>
);
