import {useEffect} from 'react';
import {useAtomValue, useUpdateAtom} from 'jotai/utils';
import {Card, CardContent, Box, CircularProgress} from '@mui/material';

import {api} from 'api';
import {useDialog} from 'components/Dialog';
import {MarkdownRender} from 'components/MarkdownRender';
import {closeHash, privacyDialogAtom} from './atoms';

export const PrivacyPolicy = () => {
  const [Dialog] = useDialog();
  const [getPolicy, {data, isSuccess, isLoading}] = api.endpoints.rootPolicy.useLazyQuery(undefined);

  const open = useAtomValue(privacyDialogAtom);
  const close = useUpdateAtom(closeHash);
  useEffect(() => {
    if (open && !isSuccess) getPolicy();
  }, [open, isSuccess, getPolicy]);

  return (
    <Dialog open={open} title="Политика конфиденциальности" onClose={() => close(privacyDialogAtom)}>
      <Card>
        <CardContent>
          {!data?.policy && isLoading && (
            <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', py: 4, px: 2}}>
              <CircularProgress />
            </Box>
          )}
          {data?.policy && <MarkdownRender content={data.policy || ''} />}
        </CardContent>
      </Card>
    </Dialog>
  );
};
