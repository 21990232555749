import {styled, Box} from '@mui/system';
import {useState, useRef, useEffect} from 'react';
import {useMeasure} from 'react-use';

type VerificationCodeInputProps = {
  length: number;
  value?: string;
  disabled: boolean;
  onComplete: (value: string) => void;
};

const CodeInput = styled('input')`
  border: none;
  outline: none;
  text-align: center;
  position: absolute;
  font-size: 24px;
  background-color: transparent;

  &:focus {
    outline: none;
  }
`;

export const VerificationCodeInput = ({length, onComplete, disabled}: VerificationCodeInputProps) => {
  const CODE_LENGTH = new Array(length).fill(0);

  const inputRef = useRef<HTMLInputElement | null>(null);
  const [conteinerRef, {width: containerWidth}] = useMeasure();
  const [focused, setFocus] = useState(false);
  const [value, setValue] = useState('');
  const values = value.split('');
  const selectedIndex = values.length < CODE_LENGTH.length ? values.length : CODE_LENGTH.length - 1;
  const hideInput = !(values.length < CODE_LENGTH.length);
  useEffect(() => {
    onComplete(hideInput ? value : '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hideInput]);

  return (
    <Box
      ref={conteinerRef}
      onClick={() => inputRef.current?.focus()}
      sx={{position: 'relative', display: 'flex', justifyContent: 'space-between'}}
    >
      {CODE_LENGTH.map((v, index) => {
        const selected = values.length === index;
        const filled = values.length === CODE_LENGTH.length && index === CODE_LENGTH.length - 1;

        return (
          <Box
            key={index}
            sx={{
              width: '100%',
              height: 48,
              borderBottomWidth: 2,
              borderBottomStyle: 'solid',
              borderBottomColor: (selected || filled) && focused ? 'primary.100' : 'grey.10',
              fontSize: 24,
              lineHeight: '32px',
              fontWeight: 500,
              textAlign: 'center',
              color: values[index] ? 'grey.700' : 'grey.200',
              py: 1,
              mx: 0.5,
            }}
          >
            {values[index] || 0}
          </Box>
        );
      })}
      <CodeInput
        value=""
        ref={inputRef}
        onBlur={() => setFocus(false)}
        onFocus={() => setFocus(true)}
        onChange={e => {
          const newValue = e.target.value;
          setValue(oldValue => {
            if (oldValue.length <= CODE_LENGTH.length) return (oldValue + newValue).slice(0, CODE_LENGTH.length);
            return oldValue;
          });
        }}
        onKeyUp={e => {
          if (e.key === 'Backspace') {
            setValue(oldValue => oldValue.slice(0, oldValue.length - 1));
          }
        }}
        disabled={disabled}
        autoComplete="one-time-code"
        sx={{
          width: 1 / length,
          top: 0,
          bottom: 0,
          opacity: hideInput ? 0 : 1,
          left: `${selectedIndex * (containerWidth / length)}px`,
        }}
      />
    </Box>
  );
};

export default VerificationCodeInput;
