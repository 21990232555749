import {useState} from 'react';
import {TextField} from '@mui/material';
import NumberFormat from 'react-number-format';

import {FieldProps} from 'formik';
import {omit} from 'lodash';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const NumberField = ({field, form: {setFieldValue}, ...fieldProps}: FieldProps & {label: string}) => {
  const [formattedValue, setFormatted] = useState(field.value);
  return (
    <NumberFormat
      allowNegative={false}
      thousandSeparator={' '}
      {...omit(field, ['value', 'onChange'])}
      value={formattedValue}
      onValueChange={({formattedValue, floatValue}) => {
        setFormatted(formattedValue);
        setFieldValue(field.name, floatValue);
      }}
      {...fieldProps}
      type="text"
      pattern="[0-9]*"
      inputMode="numeric"
      customInput={TextField}
    />
  );
};

export default NumberField;
