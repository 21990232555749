import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import CardContent from '@mui/material/CardContent';

import {api} from 'api';

import {PublicationDescription} from 'modules/feed/containers';
import {ImageGallery} from 'components/ImageGallery';

import {PublicationAttachments} from '../PublicationAttachments';

type PublicationPreviewProps = {postId: string};

export const PublicationPreview = ({postId}: PublicationPreviewProps) => {
  const {data: post} = api.endpoints.publicationIndex.useQuery({postId});

  if (!post) return null;
  return (
    <Card>
      {post.filesId && <ImageGallery filesId={post.filesId} ratio={post.filesAspectRatio} width={1200} />}
      <CardContent>
        <Stack spacing={2}>
          <PublicationAttachments data={post.attachments} />
          <PublicationDescription truncate={false} content={post.content} />
        </Stack>
      </CardContent>
    </Card>
  );
};
