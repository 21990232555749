import {styled} from '@mui/system';
import {Typography} from '@mui/material';
import {format} from 'date-fns';
import {ru} from 'date-fns/locale';

import {GREY} from 'components/theme/palette';

const Wrap = styled('div')<{showline?: number}>`
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  margin-top: ${({theme, showline}) => theme.spacing(showline ? 0 : 2)} !important;
  &:before {
    z-index: 1;
    bottom: calc(50% - 1px);
    position: absolute;

    height: 1px;
    width: 100%;
    background: ${GREY[25]};
    content: '';

    display: ${({showline}) => (showline ? 'block' : 'none')};
  }
`;

const Container = styled('div')`
  z-index: 2;
  position: relative;

  padding: 4px 6px;
  border: 1px solid ${GREY[25]};
  box-sizing: border-box;
  border-radius: 10px;
  background: white;
`;

export const GoalReportFeedDate = ({date, isFirst}: {date: Date; isFirst: boolean}) => (
  <Wrap showline={isFirst ? 1 : 0}>
    <Container>
      <Typography variant="subtitle2" color={GREY[200]}>
        {format(date, 'dd MMMM', {locale: ru})}
      </Typography>
    </Container>
  </Wrap>
);
export default GoalReportFeedDate;
