import {skipToken} from '@reduxjs/toolkit/dist/query';
import {api} from 'api';
import IsExistsContainer from 'components/IsExistsContainer';
import {TaskContext} from 'modules/tasks/context';
import {lazy} from 'react';

const TaskCard = lazy(() => import('../TaskCard'));

const useGetTaskById = (taskId?: string) => () => {
  return api.endpoints.taskIndex.useQuery(taskId ? {taskId} : skipToken);
};

export const TaskCardDialog = ({entryId}: {entryId: string}) => {
  return (
    <IsExistsContainer hideError useFetch={useGetTaskById(entryId)} context={TaskContext}>
      <TaskCard />
    </IsExistsContainer>
  );
};
