import IsExistsContainer from 'components/IsExistsContainer';

import {useGetPublicationById} from '.';
import Publication from './Publication';
import {PublicationContext} from './context';

export const PublicationDialog = ({entryId}: {entryId?: string}) => (
  <IsExistsContainer
    useFetch={useGetPublicationById(entryId)}
    context={PublicationContext}
    errorMessage={{403: 'Доступ к публикации ограничен настройками приватности'}}
  >
    <Publication />
  </IsExistsContainer>
);
