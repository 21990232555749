import {atom} from 'jotai';
import {useAtomValue, useUpdateAtom} from 'jotai/utils';
import {useCallback, useMemo, useRef} from 'react';
import {useMount} from 'react-use';
import {useIsMobile} from 'utils';
import {currentHashAtom} from './atom';

export const useSetNavigationHash = () => {
  const setHash = useUpdateAtom(currentHashAtom, Symbol.for('hash-block'));
  return setHash;
};

export const useHashPosition = (hash: string) => {
  const isMobile = useIsMobile();
  const ref = useRef<HTMLDivElement>();
  const currentHash = useMemo(
    () =>
      atom(
        get => get(currentHashAtom) === hash,
        (_, set) => hash && set(currentHashAtom, hash)
      ),
    [hash]
  );

  const isCurrentHash = useAtomValue(currentHash, Symbol.for('hash-block')) as boolean;
  const setHash = useUpdateAtom(currentHash, Symbol.for('hash-block'));
  const scrollToRef = useCallback(() => {
    if (ref.current) {
      const top = ref.current.getBoundingClientRect().top;
      const yOffset = window.pageYOffset;
      window.scrollTo({top: top + yOffset - (isMobile ? 108 : 44), behavior: 'smooth'});
    }
  }, [isMobile]);

  useMount(() => {
    if (isCurrentHash) setTimeout(scrollToRef, 500);
  });

  return {ref, scrollToRef, isCurrentHash, currentHash, setHash};
};
