import {useCallback, useContext, useEffect, useRef} from 'react';
import {UNSAFE_NavigationContext} from 'react-router';
import type {History, Blocker, Transition} from 'history';

import {useMediaQuery, useTheme} from '@mui/material';

import {api} from 'api';
import {ScoresStats} from 'api/generated/users-api';
import {useMyId} from 'modules/auth';
import {useSnackbar} from 'notistack';
import {FetchBaseQueryError} from '@reduxjs/toolkit/dist/query';
import {SerializedError} from '@reduxjs/toolkit';
import {defaultApiErrorMessage} from 'utils';

declare global {
  interface Window {
    usePreloadImagesData?: Record<string, unknown[]>;
  }
}

export const useCanScore = (score?: ScoresStats, authorId?: string) => {
  const myId = useMyId();
  if (!myId) return false;
  if (authorId === myId) return false;
  return !(score?.scoresId || []).length;
};

export const useIsMobile = () => {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.down('sm'));
};
export const useIsTablet = () => {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.down('md'));
};

export const useGetUnread = () => {
  const {data: tasksUnread} = api.endpoints.tasksUnreadedCount.useQuery({});
  const {data: questsUnread} = api.endpoints.questsUnreadedCount.useQuery({type: 'season'});
  const {data: contentsUnread} = api.endpoints.contentsUnreadedCount.useQuery({});
  const isUnreadContents = contentsUnread?.total;
  const isUnreadTasks = tasksUnread?.total || questsUnread?.total;

  return {isUnreadContents, isUnreadTasks};
};

export const useNetwork = (onReconnect?: () => void) => {
  const isOnline = useRef(window.navigator.onLine);
  const updateNetwork = useCallback(() => {
    if (!isOnline.current && window.navigator.onLine) onReconnect?.();
    isOnline.current = window.navigator.onLine;
  }, [onReconnect]);
  useEffect(() => {
    window.addEventListener('offline', updateNetwork);
    window.addEventListener('online', updateNetwork);
    return () => {
      window.removeEventListener('offline', updateNetwork);
      window.removeEventListener('online', updateNetwork);
    };
  });
  return isOnline;
};

export function useNavigationBlocker(when = true, callback?: Blocker): void {
  const navigator = useContext(UNSAFE_NavigationContext).navigator as History;

  const defaultBlocker: Blocker = useCallback(tx => {
    const confirm = window.confirm('Перейти на другую страницу?\nВозможно, внесенные изменения не сохранятся.');
    if (confirm) tx.retry();
  }, []);
  const blocker = callback || defaultBlocker;

  useEffect(() => {
    if (!when) return;

    const unblock = navigator.block((tx: Transition) => {
      const autoUnblockingTx = {
        ...tx,
        retry() {
          unblock();
          tx.retry();
        },
      };

      blocker(autoUnblockingTx);
    });

    return unblock;
  }, [navigator, blocker, when]);
}

export const useWhyDidYouUpdate = (name: string, props: Record<string, any>) => {
  // Get a mutable ref object where we can store props ...
  // ... for comparison next time this hook runs.
  const previousProps = useRef<Record<string, any>>({});
  useEffect(() => {
    if (previousProps.current) {
      // Get all keys from previous and current props
      const allKeys = Object.keys({...previousProps.current, ...props});
      // Use this object to keep track of changed props
      const changesObj: Record<string, any> = {};
      // Iterate through keys
      allKeys.forEach(key => {
        // If previous is different from current
        if (previousProps.current[key] !== props[key]) {
          // Add to changesObj
          changesObj[key] = {
            from: previousProps.current[key],
            to: props[key],
          };
        }
      });
      // If changesObj not empty then output to console
      if (Object.keys(changesObj).length) {
        // eslint-disable-next-line no-console
        console.log('[why-did-you-update]', name, changesObj);
      }
    }
    // Finally update previousProps with current props for next hook call
    previousProps.current = props;
  });
};

export const useErrorShackbar = () => {
  const {enqueueSnackbar} = useSnackbar();
  return useCallback(
    (error: FetchBaseQueryError | SerializedError, defaultMessage = 'Произошла ошибка') => {
      enqueueSnackbar(defaultApiErrorMessage(error) || defaultMessage, {variant: 'error'});
    },
    [enqueueSnackbar]
  );
};
