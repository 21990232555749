import {styled} from '@mui/system';

export const Container = styled('div')`
  width: 100%;
  height: 100%;
  position: relative;

  height: 0;
  overflow: hidden;

  padding-top: calc(1 * 100%);
  background-color: white;
`;

export const ActionContainer = styled('div')`
  top: 4px;
  right: 4px;
  z-index: 5;

  position: absolute;
`;

export const MediaContainer = styled('div')`
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: 100%;
`;
