import {api} from 'api';
import {skipToken} from '@reduxjs/toolkit/dist/query';
import {StagePointData} from 'api/generated/users-api';

import {
  ContainerNameProp,
  StageIdProp,
  StagePointIdProp,
  StagePointListProps,
  SubscriptionContainerNameProp,
  WidgetsContainerNameProp,
} from '../types';
import {
  getStagePointsByContainerName,
  getStagePointsLengthByContainerName,
  getSubscriptionsByContainerName,
  getWidgetsByContainerName,
} from '../utils';

export const useWidgetsByContainerName = ({stageId, widgetContainerName}: StageIdProp & WidgetsContainerNameProp) => {
  const {points} = api.endpoints.stagePointsIndex.useQuery(
    {stageId, partial: true},
    {
      selectFromResult: ({data}) => ({
        points: getWidgetsByContainerName(data)(widgetContainerName),
      }),
    }
  );

  return points;
};

export const useGetSubscriptionsByContainerName = ({
  stageId,
  containerName,
}: StageIdProp & SubscriptionContainerNameProp) => {
  const {points} = api.endpoints.stagePointsIndex.useQuery(
    {stageId, partial: true},
    {
      selectFromResult: ({data}) => ({
        points: getSubscriptionsByContainerName(data)(containerName),
      }),
    }
  );

  return points;
};

export const useGetStagePointsByContainerName = ({
  stageId,
  containerName,
  hideUnavailablePoints,
}: StageIdProp & ContainerNameProp & Pick<StagePointListProps, 'hideUnavailablePoints'>) => {
  const {points} = api.endpoints.stagePointsIndex.useQuery(
    {stageId, partial: true},
    {
      selectFromResult: ({data}) => ({
        points: getStagePointsByContainerName(data)(containerName).filter(item =>
          !hideUnavailablePoints ? true : item.available
        ),
      }),
    }
  );

  return points;
};

export const useGetStagePointsLengthByContainerName = ({stageId, containerName}: StageIdProp & ContainerNameProp) => {
  const {points} = api.endpoints.stagePointsIndex.useQuery(
    {stageId, partial: true},
    {
      selectFromResult: ({data}) => ({
        points: getStagePointsLengthByContainerName(data)(containerName),
      }),
    }
  );

  return points;
};

export const useGetStagePointById = ({stageId, stagePointId}: StageIdProp & StagePointIdProp): boolean => {
  const data = api.endpoints.stagePointsIndex.useQueryState(stagePointId ? {stageId, partial: true} : skipToken, {
    selectFromResult: ({data}) => ({
      pointEsists: !!(data || [])?.filter(item => item.stagePoint?._id === stagePointId)[0],
    }),
  });

  const singleData = api.endpoints.stagePointIndex.useQueryState(
    stagePointId ? {pointId: stagePointId, partial: true} : skipToken,
    {
      selectFromResult: ({data: point}) => ({pointEsists: !!point}),
    }
  );

  return data.pointEsists || singleData.pointEsists;
};

export const useGetStagePointByIdWithSelector = <R>(
  {stageId, stagePointId}: StageIdProp & StagePointIdProp,
  selector: (data: StagePointData) => R
): R => {
  const {listPointResult} = api.endpoints.stagePointsIndex.useQueryState(
    stagePointId ? {stageId, partial: true} : skipToken,
    {
      selectFromResult: ({data}) => {
        const point = (data || [])?.filter(item => item.stagePoint?._id === stagePointId)[0];
        if (!point) throw new Error('it`s pointless');
        return {listPointResult: point ? selector(point) : undefined};
      },
    }
  );

  const {singlePointResult} = api.endpoints.stagePointIndex.useQueryState(
    stagePointId ? {pointId: stagePointId, partial: true} : skipToken,
    {
      selectFromResult: ({data: point}) => ({
        singlePointResult: point ? selector(point) : undefined,
      }),
    }
  );

  if (!listPointResult && !singlePointResult) throw new Error('it`s pointless');
  return (singlePointResult || listPointResult) as R;
};
