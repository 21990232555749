import {Tabs, Tab} from '@mui/material';
import {useState} from 'react';

export const SearchTabs = ({defaultValue = 'all'}: {defaultValue?: string}) => {
  const [tab, setTab] = useState(defaultValue);
  return (
    <Tabs value={tab} onChange={(e, value) => setTab(value)} scrollButtons={false} variant="scrollable">
      <Tab label="Все" value="all" />
      <Tab label="Цели" value="goals" />
      <Tab label="Пользователи" value="users" />
      <Tab label="Команды" value="teams" />
      <Tab label="Посты" value="posts" />
    </Tabs>
  );
};

export default SearchTabs;
