import {FC} from 'react';
import {SVGComponent} from 'utils';
import Tooltip from '@mui/material/Tooltip';
import {Stages} from 'api/generated/users-api';

import {ReactComponent as newStatus} from 'svg/stageStatuses/new.svg';
import {ReactComponent as doneStatus} from 'svg/stageStatuses/done.svg';
import {ReactComponent as readyStatus} from 'svg/stageStatuses/ready.svg';
import {ReactComponent as failedStatus} from 'svg/stageStatuses/failed.svg';
import {ReactComponent as rejectedStatus} from 'svg/stageStatuses/rejected.svg';
import {ReactComponent as checkingStatus} from 'svg/stageStatuses/checking.svg';
import {ReactComponent as inProgressStatus} from 'svg/stageStatuses/in_progress.svg';

import {questStatusTexts} from 'modules/quests/utils';

const icons: {[K in Stages['status']]: SVGComponent} = {
  new: newStatus,
  ready: readyStatus,
  failed: failedStatus,
  completed: doneStatus,
  rejected: rejectedStatus,
  checking: checkingStatus,
  deadline: inProgressStatus,
  in_progress: inProgressStatus,
};

export const StageStatus: FC<Pick<Stages, 'status'>> = ({status}) => {
  const Icon = icons[status];
  return (
    <Tooltip title={questStatusTexts[status]} placement="left">
      <Icon width={24} height={24} />
    </Tooltip>
  );
};
