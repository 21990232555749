import {Stack} from '@mui/material';
import {GoalListBlockSkeleton} from '../../Goal';
import {ProfileHeaderSkeleton} from '../components';

export const ProfileGoalsPageSkeleton = () => (
  <Stack spacing={1}>
    <ProfileHeaderSkeleton short={true} />
    <GoalListBlockSkeleton />
  </Stack>
);
